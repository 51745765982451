import React, { forwardRef, useCallback } from 'react';
import type { MouseEventHandler, Ref, FocusEventHandler } from 'react';
import classnames from 'classnames';

import IconNew, { IconNameWithAdditional } from '../Icon/Icon';
import Text from '../Text/Text';

import styles from './AlterButton.pcss';

import Spinner from 'Atoms/Spinner/Spinner';

export type ButtonType = 'black' | 'white' | 'grey' | 'pink' | 'blur';
type ButtonDisableState = boolean;
type ButtonText = string | null;
export type ButtonIcon = IconNameWithAdditional | null;
type ButtonRightElement = JSX.Element | null;
type ButtonLeftElement = JSX.Element | null;
type ButtonInvertedState = boolean;
type ButtonCounterValue = number;
type ButtonLocaleMessage = string;
type ButtonFluid = boolean;
type ButtonIconPosition = 'left' | 'right';
type ButtonActiveState = boolean;
type ButtonHover = boolean;
type ButtonLoadingState = boolean;
type ButtonBordered = boolean;
type ButtonWithNewLabelState = boolean;

type Props = {
  type?: ButtonType;
  disabled?: ButtonDisableState;
  text?: ButtonText;
  icon?: ButtonIcon;
  rightElement?: ButtonRightElement;
  leftElement?: ButtonLeftElement;
  handleClick?: MouseEventHandler<HTMLButtonElement>;
  inverted?: ButtonInvertedState;
  counter?: ButtonCounterValue;
  msg?: ButtonLocaleMessage;
  fluid?: ButtonFluid;
  iconPosition?: ButtonIconPosition;
  active?: ButtonActiveState;
  className?: string;
  hover?: ButtonHover;
  ref?: Ref<HTMLButtonElement>;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onFocus?: FocusEventHandler<HTMLButtonElement>;
  onBlur?: FocusEventHandler<HTMLButtonElement>;
  loading?: ButtonLoadingState;
  bordered?: ButtonBordered;
  withNewLabel?: ButtonWithNewLabelState;
  classes?: {
    root?: string;
    buttonText?: string;
  };
  dataTest?: string;
  formatValues?: { [key: string]: string | number | JSX.Element };
  animateText?: boolean;
  textByCenter?: boolean;
  textByLeftSide?: boolean;
  fixedCounterWidth?: boolean;
};

const AlterButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {
    onClick = null,
    handleClick = null,
    text,
    icon,
    rightElement,
    leftElement,
    type = 'white',
    counter,
    msg,
    fluid = false,
    iconPosition = 'left',
    active,
    className,
    disabled,
    hover = true,
    onFocus,
    onBlur,
    loading = false,
    bordered,
    classes,
    withNewLabel,
    dataTest,
    formatValues,
    animateText,
    textByCenter,
    textByLeftSide,
    fixedCounterWidth,
  } = props;

  const classNameList = classnames(styles.root, className, classes?.root, {
    [styles.black]: type === 'black',
    [styles.grey]: type === 'grey',
    [styles.white]: type === 'white',
    [styles.pink]: type === 'pink',
    [styles.blur]: type === 'blur',
    [styles.fluid]: fluid,
    [styles.active]: active,
    [styles.noHover]: hover === false,
    [styles.bordered]: bordered,
    [styles.withTextAnimation]: animateText,
  });

  const handleButtonClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      onClick?.(e);
      handleClick?.(e);
    },
    [onClick, handleClick]
  );

  return (
    <button
      onClick={handleButtonClick}
      onFocus={onFocus}
      onBlur={onBlur}
      className={classNameList}
      ref={ref}
      disabled={disabled || loading}
      data-test={dataTest}
    >
      {leftElement}
      {icon && !loading && iconPosition === 'left' && <IconNew name={icon} />}
      {loading && <Spinner color={type === 'black' ? 'white' : undefined} size="sm" />}
      {(msg || text || counter) && (
        <div
          className={classnames(styles.textContainer, {
            [styles.buttonTextByCenter]: textByCenter,
            [styles.buttonTextByLeftSide]: textByLeftSide,
            [styles.withCounter]: fixedCounterWidth,
          })}
        >
          {(msg || text) && (
            <Text
              type="md"
              msg={msg}
              formatValues={formatValues}
              className={classnames(styles.buttonText, classes?.buttonText)}
            >
              {text}
            </Text>
          )}
          {counter !== undefined && (
            <Text
              type={'s'}
              className={classnames(styles.counter, {
                [styles.fixedCounterWidth]: fixedCounterWidth,
              })}
            >
              {counter}
            </Text>
          )}
        </div>
      )}
      {icon && !loading && iconPosition === 'right' && <IconNew name={icon} />}
      {rightElement}
      {withNewLabel && (
        <div className={styles.newBadge}>
          <Text color="white" type="label" msg="general.new" />
        </div>
      )}
    </button>
  );
});

export default AlterButton;

// types

export type { Props as AlterButtonProps };
