export enum ContentApproval_PublicationType {
  INSTAGRAM_STORY = 'INSTAGRAM_STORY',
  INSTAGRAM_FEED = 'INSTAGRAM_FEED',
  YOUTUBE_VIDEO = 'YOUTUBE_VIDEO',
  TIKTOK_VIDEO = 'TIKTOK_VIDEO',
}

export enum ContentApproval_CreativeState {
  DRAFT = 'DRAFT',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  REVISION_REQUESTED = 'REVISION_REQUESTED',
  APPROVED = 'APPROVED',
}

export enum CreatorSource {
  OUTREACH = 'OUTREACH',
}

export enum Performance_Granularity {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  WEEKLY = 'WEEKLY',
}

export enum CampaignType {
  CONTENT_CREATION = 'CONTENT_CREATION',
  INFLUENCER = 'INFLUENCER',
  LONG_TERM = 'LONG_TERM',
  PAYOUT = 'PAYOUT',
  PRODUCT_SEEDING = 'PRODUCT_SEEDING',
}

export type Currency = 'CAD' | 'RUB' | 'USD';

export enum permissionsList {
  APPROVAL_TOOL__VIEW_APPROVAL_TOOL_URL = 'APPROVAL_TOOL__VIEW_APPROVAL_TOOL_URL',
  LONGLISTS__UPDATE_LONGLISTS = 'LONGLISTS__UPDATE_LONGLISTS',
  LONGLISTS__VIEW_ACTIVE_AUDIENCE = 'LONGLISTS__VIEW_ACTIVE_AUDIENCE',
  LONGLISTS__CREATE_CAMPAIGNS = 'LONGLISTS__CREATE_CAMPAIGNS',
  LONGLISTS__VIEW_LONGLISTS = 'LONGLISTS__VIEW_LONGLISTS',
  LONGLISTS__VIEW_PRICES = 'LONGLISTS__VIEW_PRICES',
  LONGLISTS__EXPORT_LONGLISTS = 'LONGLISTS__EXPORT_LONGLISTS',
  LONGLISTS__RATE_CREATORS = 'LONGLISTS__RATE_CREATORS',
  PAYMENT__VIEW_TRANSACTIONS = 'PAYMENT__VIEW_TRANSACTIONS',
}

export enum SubscriptionInterval {
  MONTHLY = 'MONTHLY',
  TRIMONTHLY = 'TRIMONTHLY',
  YEARLY = 'YEARLY',
}

export enum ExclusionStatusList {
  ALL_STATUSES = 'all_statuses',
  HIRED = 'hired',
  NOT_HIRED = 'not_hired',
  HIRED_MORE_ONCE = 'hired_more_once',
}

export const ExclusionStatusesValue = {
  [ExclusionStatusList.ALL_STATUSES]: [
    'ACCEPTED',
    'LAUNCHED',
    'CONTENT_SUBMITTED',
    'PUBLISHING_STARTED',
    'COMPLETED',
  ],
  [ExclusionStatusList.HIRED]: ['LAUNCHED', 'CONTENT_SUBMITTED', 'PUBLISHING_STARTED', 'COMPLETED'],
  [ExclusionStatusList.NOT_HIRED]: ['ACCEPTED'],
  [ExclusionStatusList.HIRED_MORE_ONCE]: [
    'ACCEPTED',
    'LAUNCHED',
    'CONTENT_SUBMITTED',
    'PUBLISHING_STARTED',
    'COMPLETED',
  ],
};

// PROJECT EVENT TYPES

export const OFFER = 'Offer';
export const OFFER_READ_MARK = 'OfferReadMark';
export const OFFER_ACCEPTANCE = 'OfferAcceptance';
export const PROJECT_LAUNCH = 'ProjectLaunch';
export const DRAFT_DEADLINE = 'DraftDeadline';
export const PROJECT_COMPLETION = 'ProjectCompletion';
export const PRICE_CHANGE = 'PriceChange';
export const PRICE_CHANGE_REQUEST = 'PriceChangeRequest';
export const PRICE_CHANGE_REQUEST_REJECTION = 'PriceChangeRequestRejection';
export const PRICE_CHANGE_REQUEST_ACCEPTANCE = 'PriceChangeRequestAcceptance';
export const PROJECT_ARCHIVATION = 'ProjectArchivation';
export const PROJECT_ARCHIVATION_CANCELLATION = 'ProjectArchivationCancellation';
export const PRODUCT_SHIPMENT_COMPLETION = 'ProductShipmentCompletion';
export const MESSAGE = 'Message';
export const CONTENT_REVIEW_SUBMISSION = 'ContentReviewSubmission';
export const CONTENT_REVIEW_FAIL = 'ContentReviewFail';
export const CONTENT_REVIEW_PASS = 'ContentReviewPass';
export const PUBLICATION_EVENT = 'PublicationEvent';
export const POST_PUBLICATION = 'PostPublication';
export const PUBLICATION_REQUEST = 'PublicationRequest';
export const DEADLINE_CHANGE_REQUEST = 'DeadlineChangeRequest';
export const DEADLINE_CHANGE_REQUEST_ACCEPTED = 'DeadlineChangeRequestAcceptance';
export const DEADLINE_CHANGE_REQUEST_REJECTED = 'DeadlineChangeRequestRejection';
export const CONTENT_REVIEW_REQUEST = 'InsenseContentReviewRequest';
export const CONTENT_REVIEW_REQUEST_FAILED = 'InsenseContentReviewFail';
export const DEADLINE = 'Deadline';
export const PUBLICATION_DEADLINE_CHANGED = 'PublicationDeadlineChange';
export const EXTRA_PAYOUT = 'ExtraPayout';
export const REIMBURSEMENT = 'Reimbursement';
export const PRODUCT_SEEDING_CONTENT_SUBMISSION = 'ProductSeedingContentSubmission';
export const UNRESPONSIVE_CONTRACTOR_EVENT = 'UnresponsiveContractorEvent';

export enum PaidSocialLicenseState {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  FAILED_TO_EXPIRE = 'FAILED_TO_EXPIRE',
}

export enum BcaPermissionStateEnum {
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  CANCELED = 'CANCELED',
  REVOKED = 'REVOKED',
  EXPIRED = 'EXPIRED',
  INVALID = 'INVALID',
  UNKNOWN = 'UNKNOWN',
}

export enum BillingTransactionType {
  SUBSCRIPTION = 'SUBSCRIPTION',
  DEPOSIT = 'DEPOSIT',
  TRANSACTION_FEE = 'TRANSACTION_FEE',
  RESERVATION = 'RESERVATION',
  SALES_PEAK_REWARD = 'SALES_PEAK_REWARD',
  DEPOSIT_REFUND = 'DEPOSIT_REFUND',
  SUBSCRIPTION_REFUND = 'SUBSCRIPTION_REFUND',
  REFERRAL_REWARD = 'REFERRAL_REWARD',
  EXTRA_PAYOUT = 'EXTRA_PAYOUT',
  CREATOR_EXPENSE = 'CREATOR_EXPENSE',
  FULLY_MANAGED_ZEROING = 'FULLY_MANAGED_ZEROING',
  EXTRA_PAYOUT_REFUND = 'EXTRA_PAYOUT_REFUND',
  REIMBURSEMENT = 'REIMBURSEMENT',
  REIMBURSEMENT_REFUND = 'REIMBURSEMENT_REFUND',
}

export enum BillingTransactionStatus {
  ERROR = 'ERROR',
  PAID = 'PAID',
  RESERVED = 'RESERVED',
  CANCELED = 'CANCELED',
}

export enum SubscriptionStatusTypeEnum {
  DOWNGRADE = 'DOWNGRADE',
  INITIAL = 'INITIAL',
  OTHER = 'OTHER',
  RENEW = 'RENEW',
  UPGRADE = 'UPGRADE',
}

export enum CreatorBadgeEnum {
  HIGHLY_RESPONSIVE = 'HIGHLY_RESPONSIVE',
  HIGH_POTENTIAL = 'HIGH_POTENTIAL',
  TOP_RATED = 'TOP_RATED',
  UGC_EXPERT = 'UGC_EXPERT',
}

/**
 * States when Insense get taxes
 */
export enum TaxableStatesOfUnitedStates {
  NY,
  MA,
}
