/**
 * @generated SignedSource<<89890e0f2653b62bcdb2564e6b2757c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Currency = "CAD" | "RUB" | "USD";
export type CreateManualRefundInput = {
  amount: number;
  campaignId?: string | null | undefined;
  clientMutationId?: string | null | undefined;
  comment?: string | null | undefined;
  currency: Currency;
  departmentId?: string | null | undefined;
  id?: string | null | undefined;
  organizationId: string;
};
export type CreateManualRefundInputMutation$variables = {
  input: CreateManualRefundInput;
};
export type CreateManualRefundInputMutation$data = {
  readonly createManualRefund: {
    readonly organization: {
      readonly paymentAccount: {
        readonly balance: number;
        readonly currency: Currency;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type CreateManualRefundInputMutation = {
  response: CreateManualRefundInputMutation$data;
  variables: CreateManualRefundInputMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "balance",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateManualRefundInputMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateManualRefundPayload",
        "kind": "LinkedField",
        "name": "createManualRefund",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Payment_Account",
                "kind": "LinkedField",
                "name": "paymentAccount",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateManualRefundInputMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateManualRefundPayload",
        "kind": "LinkedField",
        "name": "createManualRefund",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Payment_Account",
                "kind": "LinkedField",
                "name": "paymentAccount",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ff4e9da0d55bdaa15f5dfcb5cee6446a",
    "id": null,
    "metadata": {},
    "name": "CreateManualRefundInputMutation",
    "operationKind": "mutation",
    "text": "mutation CreateManualRefundInputMutation(\n  $input: CreateManualRefundInput!\n) {\n  createManualRefund(input: $input) {\n    organization {\n      paymentAccount {\n        balance\n        currency\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "18b68977295d996a54092d0d178aad83";

export default node;
