/**
 * @generated SignedSource<<0b2d50376b7f980da65a718a58de6618>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AdvertiserProjectStage = "ACCEPTED" | "ACCEPTED_AND_NOT_LAUNCHED" | "ACCEPTED_BY_UNREVIEWED_CREATOR" | "COMPLETED" | "CONTENT_SUBMITTED" | "LAUNCHED" | "PRODUCT_SHIPMENT_COMPLETED" | "PRODUCT_SHIPMENT_STARTED" | "PUBLISHING_STARTED" | "READ" | "REJECTED" | "UNREAD" | "UNSENT";
export type CampaignStage = "ACTIVE" | "COMPLETED" | "DRAFT" | "IN_REVIEW";
export type Currency = "CAD" | "RUB" | "USD";
import { FragmentRefs } from "relay-runtime";
export type CreatorPastProjectsList_projects$data = {
  readonly projects: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly advertiserStage: AdvertiserProjectStage | null | undefined;
        readonly archivation: {
          readonly id: string;
        } | null | undefined;
        readonly archivationRequest: {
          readonly reason: string | null | undefined;
        } | null | undefined;
        readonly brief: {
          readonly productShipmentRequired?: boolean | null | undefined;
        } | null | undefined;
        readonly campaign: {
          readonly brand: {
            readonly name: string | null | undefined;
          } | null | undefined;
          readonly creator: {
            readonly email: string;
          } | null | undefined;
          readonly id: string;
          readonly name: string | null | undefined;
          readonly organization: {
            readonly id: string;
            readonly name: string;
          } | null | undefined;
          readonly stage: CampaignStage;
        };
        readonly completion: {
          readonly id: string;
        } | null | undefined;
        readonly currency: Currency | null | undefined;
        readonly id: string;
        readonly launch: {
          readonly id: string;
        } | null | undefined;
        readonly offer: {
          readonly acceptance: {
            readonly id: string;
          } | null | undefined;
          readonly createdAt: any;
          readonly readMark: {
            readonly id: string;
          } | null | undefined;
          readonly rejection: {
            readonly comment: string | null | undefined;
            readonly id: string;
          } | null | undefined;
        } | null | undefined;
        readonly price: number | null | undefined;
        readonly productShipmentCompletion: {
          readonly id: string;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly pageInfo: {
      readonly endCursor: string | null | undefined;
      readonly hasNextPage: boolean;
    };
  };
  readonly " $fragmentType": "CreatorPastProjectsList_projects";
};
export type CreatorPastProjectsList_projects$key = {
  readonly " $data"?: CreatorPastProjectsList_projects$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreatorPastProjectsList_projects">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "stages"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "projects"
        ]
      }
    ]
  },
  "name": "CreatorPastProjectsList_projects",
  "selections": [
    {
      "alias": "projects",
      "args": null,
      "concreteType": "ProjectConnection",
      "kind": "LinkedField",
      "name": "__CreatorPast_projects_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Project",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "advertiserStage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "side",
                      "value": "ADVERTISER"
                    }
                  ],
                  "concreteType": "ProjectArchivation",
                  "kind": "LinkedField",
                  "name": "archivation",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": "archivation(side:\"ADVERTISER\")"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectArchivationRequest",
                  "kind": "LinkedField",
                  "name": "archivationRequest",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "reason",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Campaign",
                  "kind": "LinkedField",
                  "name": "campaign",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Brand",
                      "kind": "LinkedField",
                      "name": "brand",
                      "plural": false,
                      "selections": [
                        (v2/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Organization",
                      "kind": "LinkedField",
                      "name": "organization",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v2/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "User",
                      "kind": "LinkedField",
                      "name": "creator",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "email",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "stage",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "price",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "currency",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "brief",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "productShipmentRequired",
                          "storageKey": null
                        }
                      ],
                      "type": "V2Brief",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProductShipmentCompletion",
                  "kind": "LinkedField",
                  "name": "productShipmentCompletion",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Offer",
                  "kind": "LinkedField",
                  "name": "offer",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "createdAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "OfferAcceptance",
                      "kind": "LinkedField",
                      "name": "acceptance",
                      "plural": false,
                      "selections": (v1/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "OfferRejection",
                      "kind": "LinkedField",
                      "name": "rejection",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "comment",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "OfferReadMark",
                      "kind": "LinkedField",
                      "name": "readMark",
                      "plural": false,
                      "selections": (v1/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectLaunch",
                  "kind": "LinkedField",
                  "name": "launch",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectCompletion",
                  "kind": "LinkedField",
                  "name": "completion",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Creator",
  "abstractKey": "__isCreator"
};
})();

(node as any).hash = "ea3537a14b6330305019da015f28da52";

export default node;
