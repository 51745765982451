import React, { Suspense, useState, useEffect } from 'react';
import { graphql, useQueryLoader } from 'react-relay';
import track from 'react-tracking';

import styles from './AdminAdvertisers.pcss';
import AdvertisersCount from './AdvertisersCount/AdvertisersCount';
import AdvertisersList from './AdvertisersList/AdvertisersList';
import AdminAdvertiserFilters from './AdminAdvertiserFilters/AdminAdvertiserFilters';

import Page from 'Templates/Page/Page';
import Text from 'Components/ui/Text/Text';
import Spinner from 'Atoms/Spinner/Spinner';
import { AdminAdvertisersQuery as QueryType } from 'GraphTypes/AdminAdvertisersQuery.graphql';
import { AdminAdvertisersContainerQuery$data } from 'GraphTypes/AdminAdvertisersContainerQuery.graphql';

export const AdminAdvertisersQuery = graphql`
  query AdminAdvertisersQuery(
    $textQuery: String
    $paying: Boolean
    $planIds: [PlanId!]
    $currencies: [Currency!]
  ) {
    ...AdvertisersList_organizations
      @arguments(textQuery: $textQuery, paying: $paying, planIds: $planIds, currencies: $currencies)
  }
`;

const defaultState = {
  paying: null,
  planIds: [],
  currencies: [],
  textQuery: '',
};

interface Props {
  planIds?: AdminAdvertisersContainerQuery$data['planIds'];
}

const AdminAdvertisers: React.FC<Props> = (props) => {
  const { planIds } = props;
  const [filters, setFilters] = useState(defaultState);

  const [queryReference, loadQuery] = useQueryLoader<QueryType>(AdminAdvertisersQuery);

  useEffect(() => {
    loadQuery(filters, {
      fetchPolicy: 'network-only',
    });
  }, [filters.textQuery, filters.paying, filters.planIds, filters.currencies]);

  const handleParamsClear = () => {
    setFilters(defaultState);
  };

  return (
    <Page className={styles.root}>
      <div className={styles.container}>
        <Text type="d2" msg="advertisers.title" className={styles.title} />
        <AdminAdvertiserFilters
          planIds={planIds}
          filters={filters}
          onFiltersChange={setFilters}
          onFiltersClear={handleParamsClear}
        />
        <Suspense fallback={<div className={styles.countStub} />}>
          <AdvertisersCount {...filters} />
        </Suspense>
        <Suspense fallback={<Spinner className={styles.preloader} />}>
          {queryReference && <AdvertisersList {...filters} queryReference={queryReference} />}
        </Suspense>
      </div>
    </Page>
  );
};

export default track(
  {
    page: 'admin_advertisers',
  },
  { dispatchOnMount: true }
)(AdminAdvertisers);
