/**
 * @generated SignedSource<<878792a282972dcdbb0feffd9498cfe6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OutreachBatchState = "ACTIVE" | "COMPLETED" | "DISCONNECTED" | "INITIAL" | "STOPPED";
export type OutreachDashboardBatchesQuery$variables = {
  campaignId: string;
  filterText?: string | null | undefined;
  states?: ReadonlyArray<OutreachBatchState> | null | undefined;
};
export type OutreachDashboardBatchesQuery$data = {
  readonly campaign: {
    readonly outreachBatches: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly sequenceBlueprint: {
            readonly name: string;
          } | null | undefined;
          readonly state: OutreachBatchState;
          readonly " $fragmentSpreads": FragmentRefs<"OutreachBatch_outreachBatch" | "OutreachSequenceList_steps">;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      readonly totalCount: number;
    };
  } | null | undefined;
};
export type OutreachDashboardBatchesQuery = {
  response: OutreachDashboardBatchesQuery$data;
  variables: OutreachDashboardBatchesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "campaignId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterText"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "states"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "campaignId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "states",
    "variableName": "states"
  },
  {
    "kind": "Variable",
    "name": "textQuery",
    "variableName": "filterText"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OutreachDashboardBatchesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "OutreachBatchConnection",
            "kind": "LinkedField",
            "name": "outreachBatches",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OutreachBatchEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OutreachBatch",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OutreachSequenceBlueprint",
                        "kind": "LinkedField",
                        "name": "sequenceBlueprint",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "OutreachBatch_outreachBatch"
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "OutreachSequenceList_steps"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OutreachDashboardBatchesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "OutreachBatchConnection",
            "kind": "LinkedField",
            "name": "outreachBatches",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OutreachBatchEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OutreachBatch",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OutreachSequenceBlueprint",
                        "kind": "LinkedField",
                        "name": "sequenceBlueprint",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OutreachStepBlueprintConnection",
                            "kind": "LinkedField",
                            "name": "steps",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "OutreachStepBlueprintEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "OutreachStepBlueprint",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v3/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "delayInDays",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "senderEmail",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GmailAuthorization",
                        "kind": "LinkedField",
                        "name": "gmailAuthorization",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "reauthenticationRequired",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CustomListConnection",
                        "kind": "LinkedField",
                        "name": "customLists",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CustomListEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "CustomList",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OutreachSequenceConnection",
                        "kind": "LinkedField",
                        "name": "sequences",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "creatorCount",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e389431612a129f4ee265bd855b046a8",
    "id": null,
    "metadata": {},
    "name": "OutreachDashboardBatchesQuery",
    "operationKind": "query",
    "text": "query OutreachDashboardBatchesQuery(\n  $campaignId: ID!\n  $filterText: String\n  $states: [OutreachBatchState!]\n) {\n  campaign(id: $campaignId) {\n    outreachBatches(textQuery: $filterText, states: $states) {\n      edges {\n        node {\n          id\n          state\n          sequenceBlueprint {\n            name\n            id\n          }\n          ...OutreachBatch_outreachBatch\n          ...OutreachSequenceList_steps\n        }\n      }\n      totalCount\n    }\n    id\n  }\n}\n\nfragment OutreachBatch_outreachBatch on OutreachBatch {\n  id\n  state\n  createdAt\n  senderEmail\n  gmailAuthorization {\n    id\n    reauthenticationRequired\n  }\n  customLists {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  sequences {\n    totalCount\n  }\n  creatorCount\n  sequenceBlueprint {\n    name\n    steps {\n      totalCount\n      edges {\n        node {\n          id\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment OutreachSequenceList_steps on OutreachBatch {\n  id\n  state\n  createdAt\n  sequenceBlueprint {\n    name\n    steps {\n      edges {\n        node {\n          id\n          delayInDays\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "85e77e6ec96c771fc598bc02dc95ea54";

export default node;
