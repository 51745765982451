import React, { useState, Suspense } from 'react';
import classnames from 'classnames';

import styles from '../CreatorFilter.pcss';
import CreatorsSearchWithParams from '../../SearchParams/CreatorsSearchWithParams/CreatorsSearchWithParams';
import { Props as CreatorFilterProps, CityRequest } from '../CreatorFilter';
import {
  convertBooleanToData,
  convertFromCitiesToSelectOptions,
  convertFromBooleanToSelectOptions,
  convertFromKitToRadioPercentageOptions,
  convertFromKitToSelectOptions,
  convertPrimitiveToData,
  isPrimitiveDirty,
  isRadioDirty,
  isSelectFilterDirty,
  convertSelectOptionsToData,
} from '../utils';
import { ParamsKitElementKeyType } from '../../SearchParams/data';

import Text from 'Components/ui/Text/Text';
import Accordion from 'Components/ui/Accordion/Accordion';
import ProgressiveInput from 'Components/ProgressiveInput/ProgressiveInput';

interface Props {
  onHandlePrimitiveValue: any;
  onRadioPercentChange: (newValue: string, filterName: ParamsKitElementKeyType) => void;
}

const AdminCreatorsFilters: React.FC<CreatorFilterProps & Props> = (props) => {
  const {
    campaign,
    platform,
    currency,
    filtersState,
    filtersKit,
    handleParamChange,
    onRadioPercentChange,
    onHandlePrimitiveValue,
  } = props;

  const [audienceCitySearch, setAudienceCitySearch] = useState<string>('');
  const [audienceCities, setAudienceCities] = useState([]);

  const campaignId = campaign?.id;
  const campaignType = campaign?.type;
  const organizationId = campaign.organization?.id;
  const isTiktok = platform === 'TIKTOK';
  const isInstagram = platform === 'INSTAGRAM';
  const isPlatformBased = campaignType !== 'CONTENT_CREATION';

  const handleSelectChange = (newValue: string, filterName: ParamsKitElementKeyType) => {
    const newData = convertSelectOptionsToData(filtersState, filterName, filtersKit, newValue);
    handleParamChange(newData);
  };

  const handleAudienceCityChange = (newValue: string, filterName: ParamsKitElementKeyType) => {
    const newData = convertSelectOptionsToData(
      filtersState,
      filterName,
      { [filterName]: audienceCities?.edges?.map((item) => item?.node) },
      newValue
    );
    handleParamChange(newData);
  };

  const experienceList = [
    {
      id: 'experienced',
      labelMsg: 'search_section.form.experienced',
      isChecked: !!filtersState?.experienced,
    },
    {
      id: 'highAverageRating',
      labelMsg: 'search_section.form.high_average_rating',
      isChecked: !!filtersState?.highAverageRating,
    },
    {
      id: 'reliable',
      labelMsg: 'search_section.form.reliable',
      isChecked: !!filtersState?.reliable,
    },
  ];

  return (
    <>
      <Suspense fallback={null}>
        <CityRequest textQuery={audienceCitySearch} setCities={setAudienceCities} />
      </Suspense>
      <Accordion
        shrinkTitle
        className={styles.accordion}
        items={[
          {
            title: 'brief_template.filters.admin',
            content: (
              <div className={styles.root}>
                <CreatorsSearchWithParams
                  organizationId={organizationId}
                  campaign={campaign}
                  platform={platform}
                  campaignId={campaignId}
                  currency={currency}
                />
                {isPlatformBased && (
                  <div>
                    <Text
                      msg="brief_template.filters.audience"
                      type="h1"
                      className={styles.title}
                      data-test="adminCreatorsFilters:text:audience"
                    />
                    <div className={styles.filterWithPercent}>
                      <ProgressiveInput
                        type="checkboxSelect"
                        titleMsg="creator_statistic.geography_tab_cities"
                        className={styles.selectCheckbox}
                        inputProps={{
                          placeholderMsg: 'search_section.form.default_value',
                          items: convertFromCitiesToSelectOptions(
                            filtersState,
                            audienceCities,
                            'audienceCities'
                          ),
                          bordered: true,
                          handleSearch: (value) => setAudienceCitySearch(value),
                          withSearch: true,
                          handleClick: (newValue) =>
                            handleAudienceCityChange(newValue, 'audienceCities'),
                        }}
                        isDirty={isSelectFilterDirty(filtersState, 'audienceCities')}
                      />
                      <ProgressiveInput
                        className={styles.percentFilter}
                        type="radio"
                        inputProps={{
                          items: convertFromKitToRadioPercentageOptions(
                            filtersState,
                            filtersKit,
                            'audienceCityPercentage'
                          ),
                          placeholder: '%',
                          className: classnames({
                            [styles.dirtyPercent]: isRadioDirty(
                              filtersState,
                              'audienceCityPercentage'
                            ),
                          }),
                          handleClick: (newValue) =>
                            onRadioPercentChange(newValue, 'audienceCityPercentage'),
                        }}
                        isDirty={isRadioDirty(filtersState, 'audienceCityPercentage')}
                      />
                    </div>
                  </div>
                )}
                <Text
                  msg="brief_template.filters.creator"
                  type="h1"
                  data-test="adminCreatorsFilters:text:creator"
                />
                <div className={styles.rowContainer}>
                  <ProgressiveInput
                    type="checkboxSelect"
                    titleMsg="creator.hair_type"
                    className={styles.selectCheckbox}
                    inputProps={{
                      placeholderMsg: 'search_section.form.default_value',
                      items: convertFromKitToSelectOptions(
                        filtersState,
                        filtersKit,
                        'creatorHairTypes'
                      ),
                      bordered: true,
                      withSearch: true,
                      handleClick: (newValue) => handleSelectChange(newValue, 'creatorHairTypes'),
                    }}
                    isDirty={isSelectFilterDirty(filtersState, 'creatorHairTypes')}
                  />
                  <ProgressiveInput
                    type="checkboxSelect"
                    titleMsg="search_section.form.body"
                    className={styles.selectCheckbox}
                    inputProps={{
                      placeholderMsg: 'search_section.form.default_value',
                      items: convertFromKitToSelectOptions(
                        filtersState,
                        filtersKit,
                        'creatorBodyTypes'
                      ),
                      bordered: true,
                      withSearch: true,
                      handleClick: (newValue) => handleSelectChange(newValue, 'creatorBodyTypes'),
                    }}
                    isDirty={isSelectFilterDirty(filtersState, 'creatorBodyTypes')}
                  />
                  <ProgressiveInput
                    type="checkboxSelect"
                    titleMsg="search_section.form.tags"
                    className={styles.selectCheckbox}
                    inputProps={{
                      placeholderMsg: 'search_section.form.default_value',
                      items: convertFromKitToSelectOptions(filtersState, filtersKit, 'tags'),
                      withSearch: true,
                      bordered: true,
                      handleClick: (newValue) => handleSelectChange(newValue, 'tags'),
                    }}
                    isDirty={isSelectFilterDirty(filtersState, 'tags')}
                  />
                </div>
                <div className={styles.rowContainer}>
                  <ProgressiveInput
                    type="range"
                    titleMsg="search_section.form.completedDeals"
                    inputProps={{
                      first: {
                        value: convertPrimitiveToData(
                          filtersState,
                          'campaignsCompletionsCountFrom'
                        ),
                        handleChange: (newValue) =>
                          onHandlePrimitiveValue(newValue, 'campaignsCompletionsCountFrom'),
                        className: styles.rangeFilterInput,
                        placeholderMsg: 'general.min',
                      },
                      second: {
                        value: convertPrimitiveToData(filtersState, 'campaignsCompletionsCountTo'),
                        handleChange: (newValue) =>
                          onHandlePrimitiveValue(newValue, 'campaignsCompletionsCountTo'),
                        className: styles.rangeFilterInput,
                        placeholderMsg: 'general.max',
                      },
                    }}
                    isDirty={
                      isPrimitiveDirty(filtersState, 'campaignsCompletionsCountFrom') ||
                      isPrimitiveDirty(filtersState, 'campaignsCompletionsCountTo')
                    }
                  />
                  <ProgressiveInput
                    type="checkboxSelect"
                    titleMsg="search_section.form.experience"
                    className={styles.selectCheckbox}
                    inputProps={{
                      groupSubtitle: 'search_section.form.experience.subtitle',
                      items: experienceList,
                      bordered: true,
                      placeholderMsg: 'search_section.form.not_selected',
                      hideByAlphabet: false,
                      handleClick: (value) => {
                        if (value && filtersState) {
                          handleParamChange({ [value]: filtersState[value] ? null : true });
                        }
                      },
                    }}
                  />
                  <ProgressiveInput
                    type="checkboxSelect"
                    titleMsg="general.other"
                    className={styles.selectCheckbox}
                    inputProps={{
                      items: convertFromBooleanToSelectOptions(
                        filtersState,
                        filtersKit,
                        'excludeBadges'
                      ),
                      bordered: true,
                      placeholderMsg: 'search_section.form.not_selected',
                      hideByAlphabet: true,
                      handleClick: () =>
                        handleParamChange(convertBooleanToData(filtersState, 'excludeBadges')),
                    }}
                    isDirty={isPrimitiveDirty(filtersState, 'excludeBadges')}
                  />
                  {isPlatformBased && isTiktok && (
                    <ProgressiveInput
                      titleMsg="search_section.form.viewsPerPost"
                      type="range"
                      inputProps={{
                        first: {
                          value: convertPrimitiveToData(filtersState, 'viewsPerPostFrom'),
                          handleChange: (newValue) =>
                            onHandlePrimitiveValue(newValue, 'viewsPerPostFrom'),
                          className: styles.rangeFilterInput,
                          placeholderMsg: 'general.min',
                        },
                        second: {
                          value: convertPrimitiveToData(filtersState, 'viewsPerPostTo'),
                          handleChange: (newValue) =>
                            onHandlePrimitiveValue(newValue, 'viewsPerPostTo'),
                          className: styles.rangeFilterInput,
                          placeholderMsg: 'general.max',
                        },
                      }}
                      isDirty={
                        isPrimitiveDirty(filtersState, 'viewsPerPostFrom') ||
                        isPrimitiveDirty(filtersState, 'viewsPerPostTo')
                      }
                    />
                  )}
                </div>
                {isPlatformBased && isInstagram && (
                  <div className={styles.rowContainer}>
                    <ProgressiveInput
                      titleMsg="search_section.form.postReach"
                      type="range"
                      inputProps={{
                        first: {
                          value: convertPrimitiveToData(filtersState, 'postsReachFrom'),
                          handleChange: (newValue) =>
                            onHandlePrimitiveValue(newValue, 'postsReachFrom'),
                          className: styles.rangeFilterInput,
                          placeholderMsg: 'general.min',
                        },
                        second: {
                          value: convertPrimitiveToData(filtersState, 'postsReachTo'),
                          handleChange: (newValue) =>
                            onHandlePrimitiveValue(newValue, 'postsReachTo'),
                          className: styles.rangeFilterInput,
                          placeholderMsg: 'general.max',
                        },
                      }}
                      isDirty={
                        isPrimitiveDirty(filtersState, 'postsReachFrom') ||
                        isPrimitiveDirty(filtersState, 'postsReachTo')
                      }
                    />
                    <ProgressiveInput
                      titleMsg="search_section.form.storiesReach"
                      type="range"
                      inputProps={{
                        first: {
                          value: convertPrimitiveToData(filtersState, 'storiesReachFrom'),
                          handleChange: (newValue) =>
                            onHandlePrimitiveValue(newValue, 'storiesReachFrom'),
                          className: styles.rangeFilterInput,
                          placeholderMsg: 'general.min',
                        },
                        second: {
                          value: convertPrimitiveToData(filtersState, 'storiesReachTo'),
                          handleChange: (newValue) =>
                            onHandlePrimitiveValue(newValue, 'storiesReachTo'),
                          className: styles.rangeFilterInput,
                          placeholderMsg: 'general.max',
                        },
                      }}
                      isDirty={
                        isPrimitiveDirty(filtersState, 'storiesReachFrom') ||
                        isPrimitiveDirty(filtersState, 'storiesReachTo')
                      }
                    />
                    <ProgressiveInput
                      titleMsg="search_section.form.medianReelsPlays"
                      type="range"
                      inputProps={{
                        first: {
                          value: convertPrimitiveToData(filtersState, 'reelsPlaysMedianFrom'),
                          handleChange: (newValue) =>
                            onHandlePrimitiveValue(newValue, 'reelsPlaysMedianFrom'),
                          className: styles.rangeFilterInput,
                          placeholderMsg: 'general.min',
                        },
                        second: {
                          value: convertPrimitiveToData(filtersState, 'reelsPlaysMedianTo'),
                          handleChange: (newValue) =>
                            onHandlePrimitiveValue(newValue, 'reelsPlaysMedianTo'),
                          className: styles.rangeFilterInput,
                          placeholderMsg: 'general.max',
                        },
                      }}
                      isDirty={
                        isPrimitiveDirty(filtersState, 'reelsPlaysMedianFrom') ||
                        isPrimitiveDirty(filtersState, 'reelsPlaysMedianTo')
                      }
                    />
                  </div>
                )}
              </div>
            ),
          },
        ]}
      />
    </>
  );
};

export default AdminCreatorsFilters;
