import React, { Suspense, useContext, useState } from 'react';
import classnames from 'classnames';
import { useIntl } from 'react-intl';
import { useLazyLoadQuery, graphql } from 'react-relay';

import styles from './ArchiveProjectRequest.pcss';
import { reasons } from './util';

import { amplitude } from 'Helpers/amplitude';
import requestProjectArchivation from 'Mutations/RequestProjectArchivation.Mutation';
import Text from 'Components/ui/Text/Text';
import Textarea from 'Components/ui/Textarea/Textarea';
import Drawer from 'Components/ui/Drawer/Drawer';
import Button from 'Components/ui/Button/Button';
import SimpleLink from 'Components/SimpleLink/SimpleLink';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import RadioButton from 'Components/ui/RadioButton/RadioButton';
import { HELLO_EMAIL } from 'Constants/general';
import { ArchiveProjectRequestQuery as QueryType } from 'GraphTypes/ArchiveProjectRequestQuery.graphql';
import Spinner from 'Atoms/Spinner/Spinner';

const OTHER = 'Other';

interface Props {
  projectId: string;
}

const ArchiveProjectRequestQuery = graphql`
  query ArchiveProjectRequestQuery($projectId: ID!) {
    project(id: $projectId) {
      creator {
        id
      }
      campaign {
        organization {
          id
        }
      }
    }
  }
`;

const ArchiveProjectRequestContent: React.FC<Props> = (props) => {
  const { projectId } = props;
  const { closeDrawer } = useContext(DrawerContext);

  const intl = useIntl();

  const data = useLazyLoadQuery<QueryType>(ArchiveProjectRequestQuery, { projectId });

  const organizationId = data?.project?.campaign.organization?.id;
  const creatorId = data?.project?.creator?.id;

  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState('');
  const [comment, setComment] = useState('');
  const [requestSent, setRequestSent] = useState(false);

  const handleRequestDone = () => {
    setLoading(false);
    setRequestSent(true);
  };

  const handleClose = () => {
    closeDrawer(`archive-project`);
  };

  const handleSendRequest = () => {
    amplitude.sendEvent<359>({
      id: '359',
      category: 'project',
      name: 'decline_request_by_advertiser',
      param: {
        project_id: projectId,
        organization_id: organizationId || '',
        creator_id: creatorId || '',
        reason,
        reason_other: comment || undefined,
      },
    });
    setLoading(true);
    requestProjectArchivation(
      { projectId, reason, comment, side: 'ADVERTISER' },
      handleRequestDone
    );
  };

  if (requestSent) {
    return (
      <>
        <div className={styles.drawerContent}>
          <Text type="d2" msg="archive_project_request_modal.request_sent.title" />
          <Text
            type="sm"
            msg="archive_project_request_modal.request_sent.descr"
            formatValues={{
              email: (
                <SimpleLink href={`mailto:${HELLO_EMAIL}`} target="_blank">
                  <Text
                    type="sm"
                    msg="archive_project_request_modal.request_sent.email"
                    className={styles.email}
                  />
                </SimpleLink>
              ),
            }}
            className={styles.descr}
          />
        </div>
        <Button
          color="black"
          msg="archive_project_request_modal.request_sent.btn"
          loading={loading}
          disabled={(!reason || loading) && !requestSent}
          onClick={handleClose}
        />
      </>
    );
  }

  const finalValue = reason === OTHER ? comment : reason;

  const handleCommentChange = (newComment: string) => {
    setComment(newComment.trim());
  };

  return (
    <>
      <Text type="d2" msg="archive_project_request_modal.title" />
      <Text type="sm" msg="archive_project_request_modal.descr" className={styles.descr} />
      <div className={styles.drawerContent}>
        <ul className={styles.reasons}>
          {reasons.map((item) => {
            const value = intl.formatMessage({ id: item.msg });
            const handleClick = () => {
              setComment('');
              setReason(value);
            };
            const isActive = reason === value;
            if (isActive && reason === OTHER) return null;
            return (
              <li
                key={value}
                className={classnames(styles.reason, { [styles.active]: isActive })}
                onClick={handleClick}
              >
                <RadioButton checked={isActive} />
                <Text type="md" msg={item.msg} />
              </li>
            );
          })}
        </ul>
        {reason === OTHER && (
          <Textarea
            placeholderMsg="archive_project_request_modal.reason.other.placeholder"
            labelMsg="archive_project_request_modal.reason.other.placeholder"
            value={comment || undefined}
            bordered={true}
            handleChange={handleCommentChange}
            className={styles.input}
          />
        )}
      </div>
      <div className={styles.btnContainer}>
        <Button
          fluid
          color="black"
          msg="archive_project_request_modal.btn"
          loading={loading}
          disabled={(!finalValue || loading) && !requestSent}
          onClick={handleSendRequest}
        />
      </div>
    </>
  );
};

const ArchiveProjectRequest: React.FC<Props> = (props) => {
  const { projectId } = props;

  return (
    <Drawer rootKey={`archive-project`} className={styles.drawer}>
      <div className={styles.drawerContainer}>
        {projectId && (
          <Suspense fallback={<Spinner className={styles.preloader} />}>
            <ArchiveProjectRequestContent {...props} />
          </Suspense>
        )}
      </div>
    </Drawer>
  );
};

export default ArchiveProjectRequest;
