/**
 * @generated SignedSource<<37b922763855e7915a207a47a249ca12>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Locale = "EN" | "RU";
export type OutreachStepBlueprint_ProjectEventType = "MESSAGE" | "OFFER";
export type UpdateOutreachSequenceBlueprintInput = {
  clientMutationId?: string | null | undefined;
  id: string;
  name?: string | null | undefined;
  steps?: ReadonlyArray<OutreachStepBlueprintInput> | null | undefined;
};
export type OutreachStepBlueprintInput = {
  delayInDays?: number | null | undefined;
  projectEventType: OutreachStepBlueprint_ProjectEventType;
  startsAtHour: any;
  template?: OutreachTemplateInput | null | undefined;
  templateId?: string | null | undefined;
};
export type OutreachTemplateInput = {
  htmlBody: string;
  locale: Locale;
  subject: string;
};
export type UpdateOutreachSequenceBlueprintMutation$variables = {
  input: UpdateOutreachSequenceBlueprintInput;
};
export type UpdateOutreachSequenceBlueprintMutation$data = {
  readonly updateOutreachSequenceBlueprint: {
    readonly __typename: "UpdateOutreachSequenceBlueprintPayload";
    readonly outreachSequenceBlueprint: {
      readonly id: string;
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
};
export type UpdateOutreachSequenceBlueprintMutation = {
  response: UpdateOutreachSequenceBlueprintMutation$data;
  variables: UpdateOutreachSequenceBlueprintMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "updateOutreachSequenceBlueprint",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OutreachSequenceBlueprint",
            "kind": "LinkedField",
            "name": "outreachSequenceBlueprint",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "UpdateOutreachSequenceBlueprintPayload",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateOutreachSequenceBlueprintMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateOutreachSequenceBlueprintMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6e7a9fe4a5e5f178f31950804c0ffcb4",
    "id": null,
    "metadata": {},
    "name": "UpdateOutreachSequenceBlueprintMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateOutreachSequenceBlueprintMutation(\n  $input: UpdateOutreachSequenceBlueprintInput!\n) {\n  updateOutreachSequenceBlueprint(input: $input) {\n    __typename\n    ... on UpdateOutreachSequenceBlueprintPayload {\n      outreachSequenceBlueprint {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3ec4ad32e873526700435389c325513a";

export default node;
