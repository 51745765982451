import React, { useContext } from 'react';

import { AttachContext, Types } from '../Attach.Context';

import styles from './AttachList.pcss';

import { FileType } from 'Hooks/useUpload';
import { cutStringWithExt } from 'Util/textFormatter';
import Text from 'Atoms/Text/Text';
import Spinner from 'Atoms/Spinner/Spinner';
import Icon from 'Components/ui/Icon/Icon';
import iconCross from 'Images/icons/deleteReference.svg';
import { IMAGE, VIDEO } from 'Constants/general';

const AttachList = () => {
  const { state, dispatch } = useContext(AttachContext);

  const files = state.filesList;

  const createPreview = (file: FileType, onClick: () => void) => {
    if (!file) return null;

    const { id, type, filename } = file;
    const collageThumbnailUrl = file.transformations?.collageThumbnailUrl;

    let content: JSX.Element;
    const style = { backgroundImage: `url(${collageThumbnailUrl})` };

    switch (type) {
      case IMAGE:
        content = <div className={styles.media} style={style} />;
        break;
      case VIDEO:
        content = <div className={`${styles.media} ${styles.video}`} style={style} />;
        break;
      default: {
        content = (
          <div className={styles.doc}>
            <Icon name="Document" className={styles.icon} />
            {filename && (
              <Text
                text={cutStringWithExt(filename, 14)}
                className={styles.fileName}
                data-test="attachList:text:fileName"
              />
            )}
          </div>
        );
        break;
      }
    }

    return (
      <div key={id} className={styles.preview}>
        {content}
        <img src={iconCross} className={styles.delete} onClick={onClick} />
      </div>
    );
  };

  const createList = () => {
    return files.map((item) => {
      if (!item) return null;
      const { id } = item;
      const onClick = () => {
        dispatch({ type: Types.DELETE_FILE, payload: { fileId: id } });
      };

      return createPreview(item, onClick);
    });
  };

  const createPendingPreview = () => {
    const list = [];
    for (let index = 0; index < state.pendingCount; index++) {
      list.push(
        <div key={index} className={styles.pendingPreview}>
          <Spinner size="sm" />
        </div>
      );
    }

    return list;
  };

  if (files.length === 0 && state.pendingCount === 0) return null;

  const list = createList();

  return (
    <div className={styles.root}>
      {list}
      {createPendingPreview()}
    </div>
  );
};

export default AttachList;
