import React, { useContext, useEffect, useMemo } from 'react';
import { scroller } from 'react-scroll';

import { BriefContext } from '../../Brief.Context';

import styles from './BriefErrorManager.pcss';

import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import AlterButton from 'Components/ui/AlterButton/AlterButton';

const BriefErrorManager = () => {
  const [briefState, briefDispatch] = useContext(BriefContext);

  const {
    scrollToErrorsIndex,
    campaignInfoErrors,
    productInfoErrors,
    creativesErrors,
    deliverablesErrors,
  } = briefState;

  const errorsList = [
    ...campaignInfoErrors,
    ...productInfoErrors,
    ...creativesErrors,
    ...deliverablesErrors,
  ];

  useEffect(() => {
    if (!scrollToErrorsIndex || errorsList.length === 0) return;

    scrollToFirstError();
  }, [scrollToErrorsIndex]);

  const scrollToFirstError = () => {
    if (!errorsList[0]) return;

    scroller.scrollTo(errorsList[0], {
      duration: 500,
      smooth: true,
      offset: -100,
    });
  };

  const handleActionClick = () => {
    briefDispatch({
      key: 'showErrors',
      value: {},
    });
  };

  if (errorsList.length === 0) return null;

  return (
    <div className={styles.root}>
      <Icon name="Support-help" className={styles.icon} />
      <Text
        type="md"
        msg="brief_template.complete.fields"
        data-test="briefErrorManager:text:fields"
      />
      <div className={styles.spacer} />
      <AlterButton
        type="white"
        msg="brief_template.complete.fields.action"
        onClick={handleActionClick}
        data-test="briefErrorManager:alterButton:action"
      />
    </div>
  );
};

export default BriefErrorManager;
