import React, { useState } from 'react';
import { BlockerFunction, Link, useBlocker } from 'react-router-dom';

import styles from './ChoosePlanBanner.pcss';

import { amplitude } from 'Helpers/amplitude';
import ls from 'Util/localStorage';
import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { PLANS_ROUTE, BOOK_A_DEMO_LINK_EN, BILLING_ROUTE } from 'Constants/general';

const LS_CHOOSE_PLAN_BANNER_ON_BRIEF_PAGE_CLOSE = 'brief.plan_banner.close';

interface Props {
  campaignId: string;
  paused: boolean;
}

const ChoosePlanBanner: React.FC<Props> = (props) => {
  const { campaignId, paused } = props;
  const closedForever = !!ls.get(LS_CHOOSE_PLAN_BANNER_ON_BRIEF_PAGE_CLOSE);

  const [visible, setVisible] = useState(false);
  const [canLeave, setCanLeave] = useState(closedForever);

  const blockerFn: BlockerFunction = () => {
    if (!canLeave) {
      setCanLeave(true);
      setVisible(true);
      return true;
    }
    return false;
  };

  useBlocker(blockerFn);

  if (closedForever || !visible) return null;

  const handleCloseButtonClick = () => {
    setVisible(false);
    setCanLeave(true);
    ls.set(LS_CHOOSE_PLAN_BANNER_ON_BRIEF_PAGE_CLOSE, true);
  };

  const handleBuyPlanClick = () => {
    amplitude.sendEvent<351>({
      id: '351',
      category: 'brief',
      name: 'buy_subscription_click',
      param: { campaign_id: campaignId },
    });
    handleCloseButtonClick();
  };

  const handleResumePlanClick = () => {
    amplitude.sendEvent<403>({
      id: '403',
      category: 'brief',
      name: 'pause_subsciption_resume_click',
    });
    handleCloseButtonClick();
  };

  return (
    <div className={styles.content}>
      <Text
        type="h1"
        msg="tooltip.brief_template.title"
        className={styles.title}
        data-test="choosePlanBanner:text:title"
      />
      <button type="button" className={styles.closeButton} onClick={handleCloseButtonClick}>
        <Icon name="Close-small" className={styles.closeButtonIcon} size={20} />
      </button>
      <Text
        type="md"
        msg={paused ? 'tooltip.brief_template.descr_pause' : 'tooltip.brief_template.descr'}
        className={styles.descr}
        data-test="choosePlanBanner:text:descr"
      />
      <Link
        to={paused ? BILLING_ROUTE : PLANS_ROUTE}
        className={styles.btn}
        onClick={paused ? handleResumePlanClick : handleBuyPlanClick}
      >
        <AlterButton
          msg={paused ? 'tooltip.brief_template.submit_pause' : 'tooltip.brief_template.submit'}
          className={styles.btn}
          data-test="choosePlanBanner:alterButton:btn"
        />
      </Link>
      {paused ? null : (
        <Text
          msg="tooltip.brief_template.disclaimer"
          type="label"
          className={styles.disclaimer}
          formatValues={{
            link: (
              <a
                href={BOOK_A_DEMO_LINK_EN}
                target="_blank"
                className={styles.demo}
                onClick={handleCloseButtonClick}
              >
                <Text type="label" data-test="choosePlanBanner:text:unknown">
                  Book a demo
                </Text>
              </a>
            ),
          }}
          data-test="choosePlanBanner:text:disclaimer"
        />
      )}
    </div>
  );
};

export default ChoosePlanBanner;
