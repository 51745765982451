import React, { useState, useContext } from 'react';

import styles from './ExtraPayment.pcss';

import { amplitude } from 'Helpers/amplitude';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { createNumber } from 'Util/numberFormatter';
import createExtraPayout from 'Mutations/CreateExtraPayout.Mutation';
import TopupBalanceContainer from 'Modal/advertiser/TopupBalance/TopupBalanceContainer/TopupBalanceContainer';
import Drawer from 'Components/ui/Drawer/Drawer';
import Text from 'Components/ui/Text/Text';
import Input from 'Components/ui/Input/Input';
import Button from 'Components/ui/Button/Button';
import { Currency } from 'GraphTypes/RequestPriceChangeMutation.graphql';

const LIMIT = 1000;

interface Props {
  currency?: Currency | null;
  projectId: string;
  projectPrice?: number | null;
  balance?: number;
}

const ExtraPayment: React.FC<Props> = (props) => {
  const { projectId, balance = 0 } = props;

  const { closeDrawer } = useContext(DrawerContext);

  const [amount, setAmount] = useState<number | undefined>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isTopupVisible, setTopupVisible] = useState(false);

  const needTopup = Number(amount) > balance;
  const amountValue = amount || 0;

  const handleChange = (newAmount: string) => {
    if (newAmount) {
      const newValue = newAmount.replace(/[^0-9]/g, '');
      const newAmountValue = parseInt(newValue, 10) || undefined;
      setAmount(newAmountValue);
    } else {
      setAmount(undefined);
    }
  };

  const handlePaymentDone = () => {
    amplitude.sendEvent({
      id: 158,
      name: 'extra_payment_transferred',
      category: 'extra_payment',
      param: {
        amount,
        projectId,
      },
    });
    handleCloseDrawer();
  };

  const handlePaymentFailed = () => {
    setError(true);
  };

  const handleMakePayment = () => {
    if (!amountValue) return;
    createExtraPayout(
      { projectId, amount: amountValue, currency: 'USD' },
      handlePaymentDone,
      handlePaymentFailed
    );
  };

  const handleSubmitClick = () => {
    setLoading(true);
    if (needTopup) {
      setTopupVisible(true);
    } else {
      handleMakePayment();
    }
  };

  const handleClose = () => {
    setAmount(undefined);
    setLoading(false);
    setTopupVisible(false);
  };

  const handleCloseDrawer = () => {
    handleClose();
    closeDrawer(`extra_payment`);
  };

  const amountText = amount ? createNumber(amount) : '';
  const btnMsg = needTopup ? 'extra_payment_modal.topup.btn' : 'extra_payment_modal.btn';

  const outOfLimit = amountValue && amountValue > LIMIT;

  return (
    <Drawer rootKey={`extra_payment`} className={styles.drawer} onClose={handleClose}>
      {isTopupVisible && (
        <TopupBalanceContainer
          amount={amountValue - balance}
          purpose="TOPUP_FOR_EXTRA_PAYMENT"
          successCallback={handleMakePayment}
        />
      )}
      {!isTopupVisible && (
        <div className={styles.drawerContainer}>
          <div className={styles.content}>
            <Text type="d2" msg="extra_payment_modal.title" className={styles.title} />
            <Text type="md" msg="extra_payment_modal.descr" className={styles.descr} />
            <Input
              placeholderMsg="extra_payment_modal.amount.descr"
              value={amountText}
              handleChange={handleChange}
              bordered
              error={!!error}
              className={styles.input}
            />
            {needTopup && !outOfLimit && (
              <Text type="sm" msg="extra_payment_modal.need_topup" className={styles.error} />
            )}
            {!!outOfLimit && (
              <Text type="sm" msg="extra_payment_modal.limit" className={styles.error} />
            )}
            {error && <Text type="sm" msg="general.error" className={styles.error} />}
          </div>
          <Button
            color="black"
            loading={loading}
            msg={btnMsg}
            onClick={handleSubmitClick}
            disabled={!!outOfLimit || !amount}
            className={styles.btn}
          />
        </div>
      )}
    </Drawer>
  );
};

export default ExtraPayment;
