import React, { useEffect, useState, useContext } from 'react';
import { useQueryLoader, graphql } from 'react-relay';
import { InviteCreators_campaign$data } from 'GraphTypes/InviteCreators_campaign.graphql';

import styles from './InviteCreators.pcss';
import InviteSuggestionList from './InviteSuggestionList/InviteSuggestionList';

import ls from 'Util/localStorage';
import { useBulkInviteSuggestedCreatorsQueryMutation } from 'Mutations/BulkInviteSuggestedCreators.Mutation';
import { amplitude } from 'Helpers/amplitude';
import { getCampaignMediaplanLink } from 'Util/links';
import Text from 'Components/ui/Text/Text';
import Drawer from 'Components/ui/Drawer/Drawer';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import Button from 'Components/ui/Button/Button';
import Icon from 'Components/ui/Icon/Icon';
import Spinner from 'Atoms/Spinner/Spinner';
import { CampaignContext } from 'AdvertiserPage/Campaign/Campaign.Context';

export const InviteCreatorsQuery = graphql`
  query InviteCreatorsQuery($inviteSuggestionListId: ID!) {
    ...InviteSuggestionList_inviteSuggestions
      @arguments(inviteSuggestionListId: $inviteSuggestionListId)
  }
`;

interface Props {
  campaignId: string;
  inviteSuggestionListCount: number;
  usedInvitationCount: number;
  maxInvitationCount: number;
  inviteSuggestionListId: string;
  searchQuery: InviteCreators_campaign$data['searchQuery'];
}

const InviteCreators: React.FC<Props> = (props) => {
  const {
    campaignId,
    searchQuery,
    inviteSuggestionListId,
    usedInvitationCount = 0,
    maxInvitationCount = 0,
    inviteSuggestionListCount = 0,
  } = props;
  const mediaplanLink = getCampaignMediaplanLink({ campaignId });

  const { onInviteSuggestionListCountDecrease, onCheckCreatorsExistence } =
    useContext(CampaignContext);

  const canShowTooltipDefault = !ls.get(`campaign_invitation_done_${campaignId}`);

  const invitationSentDefault =
    maxInvitationCount - usedInvitationCount <= 0 || inviteSuggestionListCount === 0;

  const [invitationSent, setInvitationSent] = useState(invitationSentDefault);
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [canShowTooltip, setCanShowTooltip] = useState(canShowTooltipDefault);
  const [creatorsCount, setCreatorsCount] = useState(inviteSuggestionListCount);

  const [queryReference, loadQuery] = useQueryLoader(InviteCreatorsQuery);

  const [useBulkInviteSuggestedCreatorsQuery] = useBulkInviteSuggestedCreatorsQueryMutation();

  useEffect(() => {
    loadQuery({
      inviteSuggestionListId,
    });
  }, [inviteSuggestionListId]);

  const handleInviteAllClick = () => {
    amplitude.sendEvent<390>({
      id: '390',
      category: 'campaign',
      name: 'invite_all_creators_click',
      param: { creators_count: 1 },
    });
    useBulkInviteSuggestedCreatorsQuery({
      variables: { input: { inviteSuggestionListId } },
      onCompleted: () => {
        setInvitationSent(true);
        onInviteSuggestionListCountDecrease?.(creatorsCount);
        onCheckCreatorsExistence?.();
      },
    });
  };

  const handleMarketplaceClick = () => {
    amplitude.sendEvent<392>({
      id: '392',
      category: 'campaign',
      name: 'invite_creator_marketplace_click',
      param: undefined,
    });
  };

  const emptyContent = (
    <>
      <Text type="d2" msg="invite_creators_modal.invitations_sent.title" />
      <div className={styles.content}>
        <Text
          type="md"
          msg="invite_creators_modal.invitations_sent.descr"
          className={styles.descr}
        />
      </div>
      <SmartLink path={mediaplanLink} state={{ ...searchQuery }} className={styles.marketplaceLink}>
        <Button color="black" fluid msg="invite_creators_modal.go_to_marketplace" />
      </SmartLink>
    </>
  );

  const handleCloseTooltip = () => {
    setTooltipVisible(false);
  };

  const handleInvitationDone = () => {
    ls.set(`campaign_invitation_done_${campaignId}`, true);
    setCreatorsCount(creatorsCount - 1);
    onInviteSuggestionListCountDecrease?.();
    onCheckCreatorsExistence?.();
    if (canShowTooltip) {
      setTooltipVisible(true);
      setCanShowTooltip(false);
    }
  };

  return (
    <Drawer rootKey="invite-creators" className={styles.drawer}>
      <div className={styles.drawerContainer}>
        {!queryReference && <Spinner />}
        {queryReference && invitationSent && emptyContent}
        {queryReference && !invitationSent && (
          <>
            <Text type="d2" msg="invite_creators_modal.title" />
            <Text type="md" msg="invite_creators_modal.descr" className={styles.descr} />
            <Tooltip
              showByDefault={isTooltipVisible}
              id={`invitation`}
              clickable
              place="left"
              noArrow
              className={styles.tooltipContainer}
              customTooltip={
                <div className={styles.tooltip}>
                  <Text msg="invite_creators_modal.invite_done.tooltip" type="md" />
                  <Icon
                    name={'Close-small'}
                    size={16}
                    onClick={handleCloseTooltip}
                    className={styles.closeTooltip}
                  />
                </div>
              }
            />
            <div className={styles.buttons}>
              <SmartLink path={mediaplanLink} state={{ ...searchQuery }} className={styles.button}>
                <AlterButton
                  type="grey"
                  fluid
                  textByCenter
                  msg="invite_creators_modal.go_to_marketplace"
                  onClick={handleMarketplaceClick}
                />
              </SmartLink>
              <AlterButton
                type="black"
                fluid
                textByCenter
                formatValues={{ totalCount: creatorsCount }}
                msg="invite_creators_modal.invite_all"
                onClick={handleInviteAllClick}
              />
            </div>
            {queryReference && inviteSuggestionListId && (
              <InviteSuggestionList
                queryReference={queryReference}
                emptyView={emptyContent}
                campaignId={campaignId}
                onInvitationDone={handleInvitationDone}
              />
            )}
          </>
        )}
      </div>
    </Drawer>
  );
};

export default InviteCreators;
