import React from 'react';

import styles from './NewCampaignType.pcss';

import Text from 'Components/ui/Text/Text';
import TextPreset from 'Components/ui/TextPreset/TextPreset';
import IconNew from 'Components/ui/Icon/Icon';
import Tag from 'Components/ui/Tag/Tag';

interface Props {
  onNewCampaignClick: () => void;
  onNewLongTermCampaignClick: () => void;
}

const NewCampaignType: React.FC<Props> = (props) => {
  const { onNewCampaignClick, onNewLongTermCampaignClick } = props;

  return (
    <div>
      <Text
        type="d2"
        msg="new_campaign.choose_type.title"
        className={styles.title}
        data-test="newCampaignType:text:title"
      />
      <div className={styles.items}>
        <TextPreset
          className={styles.item}
          header={
            <Text
              type="md"
              msg="new_campaign.choose_type.simple"
              data-test="newCampaignType:text:simple"
            />
          }
          subtitle={
            <Text
              type="label"
              className={styles.subtitle}
              msg="new_campaign.choose_type.simple.descr"
              data-test="newCampaignType:text:subtitle"
            />
          }
          rightComponent={<IconNew name="Arrow-small-right" />}
          handleClick={onNewCampaignClick}
        />
        <TextPreset
          className={styles.item}
          header={
            <div className={styles.itemTitle}>
              <Text
                type="md"
                msg="new_campaign.choose_type.long_term"
                data-test="newCampaignType:text:longTerm"
              />
              <Tag
                color="purple"
                msg="general.new"
                needMargin={false}
                className={styles.tag}
                data-test="newCampaignType:tag:new"
              />
            </div>
          }
          subtitle={
            <Text
              type="label"
              className={styles.subtitle}
              msg="new_campaign.choose_type.long_term.descr"
              data-test="newCampaignType:text:subtitle"
            />
          }
          rightComponent={<IconNew name="Arrow-small-right" />}
          handleClick={onNewLongTermCampaignClick}
        />
      </div>
    </div>
  );
};

export default NewCampaignType;
