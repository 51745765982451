/**
 * @generated SignedSource<<bcb539c7acf611a4d4c19a33f9f4e50b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Currency = "CAD" | "RUB" | "USD";
export type ProjectArchivationSide = "ADVERTISER" | "CONTRACTOR";
import { FragmentRefs } from "relay-runtime";
export type SystemProjectEvent_event$data = {
  readonly __typename: string;
  readonly actor: {
    readonly __typename: string;
    readonly id: string;
    readonly logo?: {
      readonly transformations?: {
        readonly brandLogoUrl: string;
      } | null | undefined;
    } | null | undefined;
    readonly profile?: {
      readonly profilePictureFile: {
        readonly transformations?: {
          readonly collageThumbnailUrl: string;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly amount?: number | null | undefined;
  readonly createdAt: any;
  readonly currency?: Currency | null | undefined;
  readonly draftDate?: any | null | undefined;
  readonly id: string;
  readonly publicationDate?: any | null | undefined;
  readonly readByContractor?: boolean;
  readonly side?: ProjectArchivationSide;
  readonly text?: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"PriceChangeEvent_event">;
  readonly " $fragmentType": "SystemProjectEvent_event";
};
export type SystemProjectEvent_event$key = {
  readonly " $data"?: SystemProjectEvent_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemProjectEvent_event">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "draftDate",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "amount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemProjectEvent_event",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "actor",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "logo",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ImageTransformation",
                      "kind": "LinkedField",
                      "name": "transformations",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "brandLogoUrl",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "Image",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "Brand",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CreatorProfile",
              "kind": "LinkedField",
              "name": "profile",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "profilePictureFile",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ImageTransformation",
                          "kind": "LinkedField",
                          "name": "transformations",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "collageThumbnailUrl",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "Image",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "TiktokCreator",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v2/*: any*/),
      "type": "Deadline",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "publicationDate",
          "storageKey": null
        }
      ],
      "type": "PublicationDeadlineChange",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "readByContractor",
          "storageKey": null
        }
      ],
      "type": "ContentReviewFail",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "side",
          "storageKey": null
        }
      ],
      "type": "ProjectArchivation",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v3/*: any*/),
      "type": "ExtraPayout",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v3/*: any*/),
      "type": "Reimbursement",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v2/*: any*/),
      "type": "DeadlineChangeRequest",
      "abstractKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PriceChangeEvent_event"
    }
  ],
  "type": "ProjectEvent",
  "abstractKey": "__isProjectEvent"
};
})();

(node as any).hash = "b30bf775623cd957ca254a14e7e21590";

export default node;
