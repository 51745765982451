import React from 'react';

import { copyText } from 'Util/copyText.js';
import Tooltip from 'Atoms/Tooltip/Tooltip';

interface Props {
  children: JSX.Element;
  value: string;
}

const CopyToClipboard: React.FC<Props> = (props) => {
  const { children, value } = props;

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    copyText(value);
  };

  return (
    <div onClick={handleClick}>
      <Tooltip id={value} place="bottom" tooltipMsg="general.copy_to_clipboard">
        {children}
      </Tooltip>
    </div>
  );
};

export default CopyToClipboard;
