import React, { Fragment, useContext } from 'react';
import Handlebars from 'handlebars';

import editorStyles from '../InviteOutreachCreators.pcss';
import { InviteOutreachCreatorsContext, SequenceStep } from '../InviteOutreachCreatorsContext';
import DelaySelector from '../SequenceSettings/SequenceItem/DelaySelector/DelaySelector';
import TimeOptionSelector from '../SequenceSettings/SequenceItem/TimeOptionSelector/TimeOptionSelector';

import styles from './TemplatePreview.pcss';

import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Accordion from 'Components/ui/Accordion/Accordion';
import Avatar from 'Atoms/Avatar/Avatar';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

interface Props {
  variables: TemplateSpecification;
  onChangeData: (data: Partial<SequenceStep>, index?: number) => void;
}

const TemplatePreview: React.FC<Props> = (props) => {
  const { variables, onChangeData } = props;
  const { sequenceList, senderName, senderEmail, handleTemplateClose, activeStep, setActiveStep } =
    useContext(InviteOutreachCreatorsContext);

  const { openDrawer } = useContext(DrawerContext);
  const handleDelayValue = (index: number, value: number) => {
    onChangeData({ delayInDays: value }, index);
  };
  const handleTimeValue = (index: number, value: number) => {
    const newDate = new Date(sequenceList[index].startsAtHour);
    newDate.setHours(value);
    onChangeData({ startsAtHour: newDate }, index);
  };
  const handleDeleteItem = (index: number) => {
    setActiveStep(index);
    openDrawer('sequence-delete-item');
  };
  const handleEditItem = (index: number) => {
    setActiveStep(index);
    handleTemplateClose();
  };

  if (!sequenceList.length) return null;

  return (
    <>
      <div className={styles.templateDrawer}>
        <div className={styles.previewContainer}>
          <div className={styles.titleContainer}>
            <div className={styles.backContainer}>
              <AlterButton
                icon="Arrow-big-left"
                className={styles.resetTemplate}
                onClick={handleTemplateClose}
                type="grey"
              />
              <Text
                type="d2"
                className={styles.title}
                msg="invite_outreach_creators_modal.preview.title"
              />
            </div>
          </div>
          <div className={styles.mailList}>
            {sequenceList.map((item, index) => {
              const body = Handlebars.compile(item.template?.htmlBody || '');
              const compliledBody = body(variables);
              const subject = Handlebars.compile(item.template?.subject || '');
              const compliledSubject = subject(variables);
              const isSendNowOptionSelected =
                item.delayInDays === null || item.delayInDays === undefined;
              return (
                <Fragment key={`${item.id}_${index}`}>
                  <div className={styles.titleContainer}>
                    <div className={styles.optionsContainer}>
                      <div className={styles.selectorContainer}>
                        <DelaySelector
                          data={item}
                          disableEmptyValue={index > 0}
                          handleDelayValue={(value) => handleDelayValue(index, value)}
                          isSendNowOptionSelected={isSendNowOptionSelected}
                        />
                      </div>
                      {!isSendNowOptionSelected && (
                        <div className={styles.selectorContainer}>
                          <TimeOptionSelector
                            data={item}
                            handleTimeValue={(value) => handleTimeValue(index, value)}
                          />
                        </div>
                      )}
                    </div>
                    <div className={styles.backContainer}>
                      <AlterButton
                        icon="Trash-delele"
                        onClick={() => handleDeleteItem(index)}
                        disabled={sequenceList.length === 1}
                        type="white"
                      />
                      <AlterButton icon="Edit" onClick={() => handleEditItem(index)} type="white" />
                    </div>
                  </div>
                  <Accordion
                    className={styles.mailPreviewContainer}
                    items={[
                      {
                        arrowPosition: 'right',
                        openedByDefault: activeStep === index,
                        title: (
                          <div className={styles.title}>
                            <Text
                              type="md"
                              className={styles.titleSubjectText}
                              msg="invite_outreach_creators_modal.preview.subject"
                            />
                            <Text
                              type="md"
                              className={styles.subjectText}
                              text={compliledSubject}
                            />
                          </div>
                        ),
                        content: (
                          <div className={styles.mailPreview}>
                            <div className={styles.userInfo}>
                              <Avatar />
                              <div className={styles.backContainer}>
                                <Text text={`${senderName || 'Rodrigo from Insense'}`} />
                                <Text
                                  type="label"
                                  className={styles.previewMailSecondaryText}
                                  text={senderEmail || 'rodrigo@insense.pro'}
                                />
                              </div>
                            </div>
                            <div
                              dangerouslySetInnerHTML={{ __html: compliledBody }}
                              className={editorStyles.templateContent}
                            />
                          </div>
                        ),
                      },
                    ]}
                  />
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplatePreview;
