import React, { useState, useRef, useEffect, useContext } from 'react';
import { graphql, useFragment } from 'react-relay';
import { Link, useNavigate } from 'react-router-dom';

import styles from './CampaignActions.pcss';
import AdditionalActions from './AdditionalActions/AdditionalActions';

import { getCampaignMediaplanLink, getCampaignBriefForViewingLink } from 'Util/links';
import { copyText } from 'Util/copyText';
import { amplitude } from 'Helpers/amplitude';
import { SubscriptionParamsType } from 'Helpers/amplitude.types';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import Text from 'Components/ui/Text/Text';
import Toggle from 'Components/ui/Toggle/Toggle';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownGroupItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import CampaignSubscription from 'Organisms/CampaignSubscription/CampaignSubscription';
import { BILLING_ROUTE } from 'Constants/general';
import { FeatureFlagsContext } from 'Containers/FeatureFlags/FeatureFlags.Context';
import { CampaignQuery$data } from 'GraphTypes/CampaignQuery.graphql';
import { CampaignActions_campaign$key } from 'GraphTypes/CampaignActions_campaign.graphql';

interface Props {
  isAdmin: boolean;
  isAdminView: boolean;
  currentUser: CampaignQuery$data['currentUser'];
  campaign: CampaignActions_campaign$key;
  onClientViewChange?: (value: boolean) => void;
}

const CampaignActions: React.FC<Props> = (props) => {
  const { campaign, currentUser, isAdmin, isAdminView, onClientViewChange } = props;

  const [showCopyTooltip, setCopyTooltipStatus] = useState(false);

  const navigate = useNavigate();

  const copyTooltipTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (copyTooltipTimeout.current) {
        clearInterval(copyTooltipTimeout.current);
        copyTooltipTimeout.current = null;
      }
    };
  }, []);

  const data = useFragment(
    graphql`
      fragment CampaignActions_campaign on Campaign {
        id
        type
        listingId
        postingType
        userSubscribed
        organization {
          id
          subscription {
            paused
          }
        }
        completion {
          id
        }
        activation {
          paused
        }
        ...AdditionalActions_campaign
      }
    `,
    campaign
  );

  const campaignId = data.id;
  const userSubscribed = data.userSubscribed;
  const organizationId = data.organization?.id || '';
  const isOrganizationMember = currentUser?.organization?.id === organizationId;

  const canBrowseCreators = !!currentUser?.organization?.subscription?.canBrowseCreators;
  const canComplete = !data.completion;

  const isPaused = !!data.activation?.paused;
  const isPlanPaused = Boolean(data?.organization?.subscription?.paused);

  const { hasFeatureFlag } = useContext(FeatureFlagsContext);
  const newCampaignTabs = hasFeatureFlag('new_campaign_tabs');

  const inviteButtonAlreadyVisible =
    (data.type === 'INFLUENCER' && data.postingType === 'ORGANIC_POSTING') ||
    data.type === 'PRODUCT_SEEDING';
  const isInviteDisable = inviteButtonAlreadyVisible && !isAdmin;

  const handleSubscriptionStateChange = (param: SubscriptionParamsType) => {
    amplitude.sendEvent<416>({
      id: '416',
      category: 'campaign',
      name: 'email_notifications_click',
      param,
    });
  };

  const handleOutreachClick = () => {
    amplitude.sendEvent<313>({
      id: '313',
      category: 'campaign',
      name: 'invite_creators_copy_invite_link',
      param: { campaign_id: campaignId },
    });
    const additionalData = JSON.stringify({
      referralUserId: currentUser?.id,
      listingId: data.listingId,
    });
    const url = new URL(`${location.origin}/campaigns/${campaignId}/invite`);
    url.searchParams.append('x', btoa(additionalData));
    copyText(url.toString());
    if (copyTooltipTimeout.current) return;

    copyTooltipTimeout.current = setTimeout(() => {
      setCopyTooltipStatus(false);
      copyTooltipTimeout.current = null;
    }, 3000);
    setCopyTooltipStatus(true);
  };

  const handleAddCreatorsClick = () => {
    amplitude.sendEvent<314>({
      id: '314',
      category: 'campaign',
      name: 'invite_creators_open_marketplace',
      param: { campaign_id: campaignId },
    });
    const path = getCampaignMediaplanLink({ campaignId });
    navigate(path);
    amplitude.sendEvent({
      id: '18',
      category: 'campaign',
      name: 'more_action_add_creators',
      param: { admin: isAdmin },
    });
  };

  const handleViewBriefClick = () => {
    amplitude.sendEvent<527>({
      id: '527',
      category: 'campaign_page',
      name: 'view_brief_click',
      param: { campaign_id: campaignId },
    });
  };

  return (
    <div className={styles.campaignActions}>
      {(canBrowseCreators || isAdminView) && canComplete && (newCampaignTabs || !isInviteDisable) && (
        <Dropdown
          showArrow={false}
          className={styles.inviteMenu}
          value={
            <Tooltip
              id={`campaign.invite_creators.tooltip.pause_plan`}
              place={'bottom'}
              clickable
              customTooltip={
                isPlanPaused ? (
                  <>
                    <Text
                      type="md"
                      msg="campaign.invite_creators.tooltip.pause_plan"
                      data-test="campaignActions:text:pausePlan"
                    />
                    <a href={BILLING_ROUTE} className={styles.resumePlanTooltipLink}>
                      <Text
                        type="md"
                        msg="campaign.invite_creators.tooltip.pause_plan.button"
                        data-test="campaignActions:text:button"
                      />
                    </a>
                  </>
                ) : null
              }
            >
              <AlterButton
                type="pink"
                msg="campaign.invite_creators"
                icon="Add-plus-circle"
                iconPosition="left"
                fluid={true}
                disabled={isPlanPaused}
                data-test="campaignActions:alterButton:inviteCreators"
              />
            </Tooltip>
          }
          tooltipData={
            showCopyTooltip
              ? {
                  tooltipMsg: 'campaign.outreach_link_copy_tip',
                  id: 'campaign.outreach_link_copy_tip',
                  place: 'bottom',
                  showByDefault: true,
                }
              : undefined
          }
        >
          <DropdownGroup align="left" className={styles.inviteMenuGroup}>
            <div>
              {isAdmin ? (
                <DropdownGroupItem>
                  {isPaused ? (
                    <Tooltip
                      id="campaign.outreach_link_disabled_tip"
                      tooltipMsg="campaign.outreach_link_disabled_tip"
                    >
                      <AlterButton
                        msg="campaign.outreach_link"
                        fluid
                        withNewLabel
                        disabled
                        data-test="campaignActions:alterButton:outreachLink"
                      />
                    </Tooltip>
                  ) : (
                    <AlterButton
                      msg="campaign.outreach_link"
                      fluid
                      withNewLabel
                      onClick={handleOutreachClick}
                      data-test="campaignActions:alterButton:outreachLink"
                    />
                  )}
                </DropdownGroupItem>
              ) : null}
              {(newCampaignTabs || !inviteButtonAlreadyVisible) && (
                <DropdownGroupItem>
                  <AlterButton
                    type="white"
                    msg="campaign.add_creators"
                    onClick={handleAddCreatorsClick}
                    fluid
                    data-test="campaignActions:alterButton:addCreators"
                  />
                </DropdownGroupItem>
              )}
            </div>
          </DropdownGroup>
        </Dropdown>
      )}
      <Link to={getCampaignBriefForViewingLink({ campaignId })}>
        <AlterButton
          type="white"
          bordered={true}
          icon="Brief"
          msg="campaign.view_brief"
          data-test="campaignActions:alterButton:viewBrief"
          onClick={handleViewBriefClick}
        />
      </Link>
      {isOrganizationMember && (
        <div className={styles.notification}>
          <Text
            type="md"
            msg="campaign.notification.toggle"
            data-test="campaignActions:text:toggle"
          />
          <CampaignSubscription
            campaignId={data.id}
            userSubscribed={userSubscribed}
            eventFn={handleSubscriptionStateChange}
            currentUserId={currentUser?.id}
          >
            <Toggle bgColor="sand" on={userSubscribed} size={20} />
          </CampaignSubscription>
        </div>
      )}
      <AdditionalActions
        campaign={data}
        campaignId={campaignId}
        isAdmin={isAdmin}
        isAdminView={isAdminView}
        canBrowseCreators={canBrowseCreators}
        onClientViewChange={onClientViewChange}
      />
    </div>
  );
};

export default CampaignActions;
