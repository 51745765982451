/**
 * @generated SignedSource<<7befbb69f6e0fd0e7bf0e555bd4c9c3a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CampaignPlatform = "FACEBOOK" | "INSTAGRAM" | "OTHER" | "SNAPCHAT" | "TIKTOK" | "YOUTUBE";
export type CampaignPostingType = "CREATOR_ADS" | "ORGANIC_POSTING";
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING";
export type CampaignUseCase = "AFFILIATE_CAMPAIGN" | "INFLUENCER_POSTS" | "META_PARTNERSHIP_ADS" | "PRODUCT_SEEDING" | "TIKTOK_SHOP" | "TIKTOK_SPARK_ADS" | "USER_GENERATED_CONTENT";
export type Performance_Granularity = "DAILY" | "MONTHLY" | "QUARTERLY" | "WEEKLY";
export type EngagementCountersChartQuery$variables = {
  brandId: string;
  campaignIds?: ReadonlyArray<string> | null | undefined;
  campaignPostingType?: CampaignPostingType | null | undefined;
  campaignType?: CampaignType | null | undefined;
  dateFrom: any;
  dateTo: any;
  granularity: Performance_Granularity;
  platform?: CampaignPlatform | null | undefined;
  useCase?: CampaignUseCase | null | undefined;
};
export type EngagementCountersChartQuery$data = {
  readonly performance: {
    readonly engagementCounters: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly commentsCount: number;
          readonly dateFrom: any;
          readonly dateTo: any;
          readonly engagementCount: number;
          readonly likesCount: number;
          readonly savedCount: number;
          readonly sharesCount: number;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      readonly totalCommentsCount: number;
      readonly totalEngagementCount: number;
      readonly totalLikesCount: number;
      readonly totalSavedCount: number;
      readonly totalSharesCount: number;
    } | null | undefined;
  } | null | undefined;
};
export type EngagementCountersChartQuery = {
  response: EngagementCountersChartQuery$data;
  variables: EngagementCountersChartQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "brandId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "campaignIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "campaignPostingType"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "campaignType"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dateFrom"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dateTo"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "granularity"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "platform"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "useCase"
},
v9 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Performance_Query",
    "kind": "LinkedField",
    "name": "performance",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "brandId",
            "variableName": "brandId"
          },
          {
            "kind": "Variable",
            "name": "campaignIds",
            "variableName": "campaignIds"
          },
          {
            "kind": "Variable",
            "name": "campaignPostingType",
            "variableName": "campaignPostingType"
          },
          {
            "kind": "Variable",
            "name": "campaignType",
            "variableName": "campaignType"
          },
          {
            "kind": "Variable",
            "name": "campaignUseCase",
            "variableName": "useCase"
          },
          {
            "kind": "Variable",
            "name": "dateFrom",
            "variableName": "dateFrom"
          },
          {
            "kind": "Variable",
            "name": "dateTo",
            "variableName": "dateTo"
          },
          {
            "kind": "Variable",
            "name": "granularity",
            "variableName": "granularity"
          },
          {
            "kind": "Variable",
            "name": "platform",
            "variableName": "platform"
          }
        ],
        "concreteType": "Performance_EngagementCounterConnection",
        "kind": "LinkedField",
        "name": "engagementCounters",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Performance_EngagementCounterEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Performance_EngagementCounter",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "engagementCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "likesCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "commentsCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sharesCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "savedCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dateFrom",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dateTo",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalEngagementCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalLikesCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCommentsCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalSharesCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalSavedCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EngagementCountersChartQuery",
    "selections": (v9/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Operation",
    "name": "EngagementCountersChartQuery",
    "selections": (v9/*: any*/)
  },
  "params": {
    "cacheID": "a9cdcf3cc515a9dfd2e7b73ea19a4794",
    "id": null,
    "metadata": {},
    "name": "EngagementCountersChartQuery",
    "operationKind": "query",
    "text": "query EngagementCountersChartQuery(\n  $brandId: ID!\n  $campaignIds: [ID!]\n  $dateFrom: Date!\n  $dateTo: Date!\n  $granularity: Performance_Granularity!\n  $platform: CampaignPlatform\n  $campaignType: CampaignType\n  $campaignPostingType: CampaignPostingType\n  $useCase: CampaignUseCase\n) {\n  performance {\n    engagementCounters(brandId: $brandId, campaignIds: $campaignIds, dateFrom: $dateFrom, dateTo: $dateTo, granularity: $granularity, platform: $platform, campaignType: $campaignType, campaignPostingType: $campaignPostingType, campaignUseCase: $useCase) {\n      edges {\n        node {\n          engagementCount\n          likesCount\n          commentsCount\n          sharesCount\n          savedCount\n          dateFrom\n          dateTo\n        }\n      }\n      totalEngagementCount\n      totalLikesCount\n      totalCommentsCount\n      totalSharesCount\n      totalSavedCount\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1a74d68725b6e347d3c4cbf4ca9365d1";

export default node;
