import React, { useEffect, useMemo } from 'react';

import styles from '../SequenceItem.pcss';
import { SequenceStep } from '../../../InviteOutreachCreatorsContext';

import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownGroupItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';

interface Props {
  data: SequenceStep;
  handleTimeValue: (value: number) => void;
}

const TimeOptionSelector: React.FC<Props> = (props) => {
  const { data, handleTimeValue } = props;

  const isSendingToday = data.delayInDays === 0;
  const tz = Intl.DateTimeFormat().resolvedOptions().locale;
  const locale = navigator.language;
  const isUserUsedEnFormat = Intl.DateTimeFormat(locale, { hour: 'numeric' }).resolvedOptions()
    .hour12;
  const formatTimeOptions = {
    timeStyle: 'short',
    hourCycle: isUserUsedEnFormat ? 'h11' : 'h23',
  } as Intl.DateTimeFormatOptions;

  const startDate = useMemo(() => {
    if (typeof data.startsAtHour === 'string') {
      return new Date(data.startsAtHour);
    }
    return data.startsAtHour;
  }, [data.startsAtHour]);

  useEffect(() => {
    const currentHour = new Date().getHours();
    if (isSendingToday && startDate?.getHours() && startDate.getHours() <= currentHour) {
      handleTimeValue(currentHour === 23 ? 0 : currentHour + 1);
    }
  }, [isSendingToday]);

  return (
    <div className={styles.mailOptions}>
      <Text type="md" className={styles.mailOptionText} text="then send at" />
      <Dropdown
        showArrow={false}
        className={styles.timeInput}
        value={
          <Text
            type="md"
            className={styles.mailOptionValue}
            text={`${startDate?.getHours() || '00'}:00`}
          />
        }
      >
        <DropdownGroup className={styles.timeValueList}>
          {[...Array(24).keys()].map((item) => (
            <DropdownGroupItem
              key={item}
              handleClick={() => {
                handleTimeValue(item);
              }}
            >
              <AlterButton
                text={new Date(0, 0, 0, item).toLocaleTimeString(tz, formatTimeOptions)}
                className={styles.delayValue}
                disabled={isSendingToday && item <= new Date().getHours()}
              />
            </DropdownGroupItem>
          ))}
        </DropdownGroup>
      </Dropdown>
    </div>
  );
};

export default TimeOptionSelector;
