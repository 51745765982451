/**
 * @generated SignedSource<<844c0ae2fb7ec435c7c5a93a005fab0d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CreatorCharacteristic = "BAD_COMMUNICATION" | "BAD_PRICE_QUALITY_RATIO" | "FAST_WORK" | "FOLLOWING_BRIEF" | "GOOD_COMMUNICATION" | "GOOD_PRICE_QUALITY_RATIO" | "HIGH_CREATIVITY" | "HIGH_TECHNICAL_QUALITY" | "LOW_CREATIVITY" | "LOW_TECHNICAL_QUALITY" | "NOT_FOLLOWING_BRIEF" | "SLOW_WORK";
export type Currency = "CAD" | "RUB" | "USD";
import { FragmentRefs } from "relay-runtime";
export type ProjectCreatorRating_project$data = {
  readonly completion: {
    readonly id: string;
  } | null | undefined;
  readonly currency: Currency | null | undefined;
  readonly id: string;
  readonly ratingByAgency: {
    readonly characteristics: ReadonlyArray<CreatorCharacteristic> | null | undefined;
    readonly comment: string | null | undefined;
    readonly score: number;
  } | null | undefined;
  readonly " $fragmentType": "ProjectCreatorRating_project";
};
export type ProjectCreatorRating_project$key = {
  readonly " $data"?: ProjectCreatorRating_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectCreatorRating_project">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectCreatorRating_project",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectCompletion",
      "kind": "LinkedField",
      "name": "completion",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": "ratingByAgency",
      "args": [
        {
          "kind": "Literal",
          "name": "side",
          "value": "AGENCY"
        }
      ],
      "concreteType": "RatingVote",
      "kind": "LinkedField",
      "name": "creatorRatingVote",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "comment",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "score",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "characteristics",
          "storageKey": null
        }
      ],
      "storageKey": "creatorRatingVote(side:\"AGENCY\")"
    }
  ],
  "type": "Project",
  "abstractKey": null
};
})();

(node as any).hash = "22a876d7e752daa047daaf12f7ce0827";

export default node;
