/**
 * @generated SignedSource<<652ca25437c128ef374ecf3c96facaf9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Currency = "CAD" | "RUB" | "USD";
import { FragmentRefs } from "relay-runtime";
export type OnboardingByContractor_project$data = {
  readonly archivationState: {
    readonly id: string;
  } | null | undefined;
  readonly brief: {
    readonly paidSocialActivationDays?: number | null | undefined;
    readonly paidSocialBca?: boolean;
    readonly priceCap?: number | null | undefined;
  } | null | undefined;
  readonly creator: {
    readonly paidSocialEnabled?: boolean;
    readonly username: string;
  } | null | undefined;
  readonly currency: Currency | null | undefined;
  readonly id: string;
  readonly paidSocial: boolean;
  readonly price: number | null | undefined;
  readonly " $fragmentType": "OnboardingByContractor_project";
};
export type OnboardingByContractor_project$key = {
  readonly " $data"?: OnboardingByContractor_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"OnboardingByContractor_project">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OnboardingByContractor_project",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paidSocial",
      "storageKey": null
    },
    {
      "alias": "archivationState",
      "args": null,
      "concreteType": "ProjectArchivation",
      "kind": "LinkedField",
      "name": "archivation",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "brief",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "priceCap",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paidSocialBca",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paidSocialActivationDays",
              "storageKey": null
            }
          ],
          "type": "V2Brief",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "creator",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paidSocialEnabled",
              "storageKey": null
            }
          ],
          "type": "InstagramCreator",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};
})();

(node as any).hash = "e69735be505b581a427e4c081af3045e";

export default node;
