/**
 * @generated SignedSource<<3909e2e89265b6330cb46384716022f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Payment_WithdrawalStatus = "CANCELED" | "CONFIRMED" | "FAILED" | "PROCESSING" | "UNSENT";
export type W9WarningLevel = "HIGH" | "LOW" | "MEDIUM" | "NONE";
export type ConfirmWithdrawalInput = {
  accountId: string;
  clientMutationId?: string | null | undefined;
  id?: string | null | undefined;
  withdrawalId: string;
};
export type ConfirmWithdrawalMutation$variables = {
  input: ConfirmWithdrawalInput;
};
export type ConfirmWithdrawalMutation$data = {
  readonly confirmWithdrawal: {
    readonly withdrawal: {
      readonly id: string;
      readonly paymentMethod: {
        readonly w9WarningLevel: W9WarningLevel;
      } | null | undefined;
      readonly status: Payment_WithdrawalStatus;
      readonly statusDate: any | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type ConfirmWithdrawalMutation = {
  response: ConfirmWithdrawalMutation$data;
  variables: ConfirmWithdrawalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "statusDate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "w9WarningLevel",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ConfirmWithdrawalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ConfirmWithdrawalPayload",
        "kind": "LinkedField",
        "name": "confirmWithdrawal",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Payment_Withdrawal",
            "kind": "LinkedField",
            "name": "withdrawal",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "paymentMethod",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ConfirmWithdrawalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ConfirmWithdrawalPayload",
        "kind": "LinkedField",
        "name": "confirmWithdrawal",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Payment_Withdrawal",
            "kind": "LinkedField",
            "name": "withdrawal",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "paymentMethod",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cf6a2369157b0aadd2106cf517e19939",
    "id": null,
    "metadata": {},
    "name": "ConfirmWithdrawalMutation",
    "operationKind": "mutation",
    "text": "mutation ConfirmWithdrawalMutation(\n  $input: ConfirmWithdrawalInput!\n) {\n  confirmWithdrawal(input: $input) {\n    withdrawal {\n      id\n      status\n      statusDate\n      paymentMethod {\n        __typename\n        w9WarningLevel\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2f78004c5a89b18195aeb5f3216614f9";

export default node;
