import React from 'react';
import { createPaginationContainer, graphql, RelayPaginationProp } from 'react-relay';

import styles from './AdvertiserCreativesList.css';

import Text from 'Atoms/Text/Text';
import Creative from 'Molecules/Creative/Creative';
import LoaderHandler from 'Organisms/LoaderHandler/LoaderHandler';
import { AdvertiserCreativesList_creatives } from 'GraphTypes/AdvertiserCreativesList_creatives.graphql';

const SCROLL_OFFSET = 300;
const COUNT = 12;

interface Props {
  admin: boolean;
  relay: RelayPaginationProp;
  creatives: AdvertiserCreativesList_creatives;
  canCreateFacebookAds: boolean;
  canChatWithCreators: boolean;
}

const AdvertiserCreativesList: React.FC<Props> = (props) => {
  const { admin, relay, creatives, canCreateFacebookAds, canChatWithCreators } = props;

  const edges = creatives.creatives?.edges || [];

  const createList = () => {
    if (edges.length === 0) {
      return (
        <Text
          color="grayDog"
          msg="creatives.empty_list"
          className={styles.descr}
          data-test="advertiserCreativesList:text:emptyList"
        />
      );
    }

    return edges.map((item) => {
      if (!item?.node) return null;
      const { id } = item.node;

      return (
        <Creative
          key={id}
          creative={item.node}
          admin={admin}
          showUser={false}
          canCreateFacebookAds={canCreateFacebookAds}
          canChatWithCreators={canChatWithCreators}
        />
      );
    });
  };

  return (
    <LoaderHandler relay={relay} count={COUNT} offset={SCROLL_OFFSET}>
      <div className={styles.content}>{createList()}</div>
    </LoaderHandler>
  );
};

export default createPaginationContainer(
  AdvertiserCreativesList,
  {
    creatives: graphql`
      fragment AdvertiserCreativesList_creatives on Query
      @argumentDefinitions(count: { type: "Int", defaultValue: 12 }, cursor: { type: "String" }) {
        creatives(first: $count, after: $cursor)
          @connection(key: "AdvertiserCreativesList_creatives", filters: []) {
          edges {
            node {
              id
              ...Creative_creative
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.creatives && props.creatives.creatives;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
      };
    },
    query: graphql`
      query AdvertiserCreativesListPaginationQuery($count: Int!, $cursor: String) {
        ...AdvertiserCreativesList_creatives @arguments(count: $count, cursor: $cursor)
      }
    `,
  }
);
