/**
 * @generated SignedSource<<2784740969109cf954cdda13d3411ab7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AdvertiserProjectStage = "ACCEPTED" | "ACCEPTED_AND_NOT_LAUNCHED" | "ACCEPTED_BY_UNREVIEWED_CREATOR" | "COMPLETED" | "CONTENT_SUBMITTED" | "LAUNCHED" | "PRODUCT_SHIPMENT_COMPLETED" | "PRODUCT_SHIPMENT_STARTED" | "PUBLISHING_STARTED" | "READ" | "REJECTED" | "UNREAD" | "UNSENT";
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING";
export type CampaignUseCase = "AFFILIATE_CAMPAIGN" | "INFLUENCER_POSTS" | "META_PARTNERSHIP_ADS" | "PRODUCT_SEEDING" | "TIKTOK_SHOP" | "TIKTOK_SPARK_ADS" | "USER_GENERATED_CONTENT";
import { FragmentRefs } from "relay-runtime";
export type AdvertiserProjectActions_project$data = {
  readonly advertiserStage: AdvertiserProjectStage | null | undefined;
  readonly archivation: {
    readonly id: string;
  } | null | undefined;
  readonly archivationByAdvertiser: {
    readonly id: string;
  } | null | undefined;
  readonly campaign: {
    readonly type: CampaignType | null | undefined;
    readonly useCase: CampaignUseCase | null | undefined;
  };
  readonly id: string;
  readonly price: number | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"AddNotes_project" | "CompletedStage_project" | "ContentCreationStage_project" | "ContentSubmittedStage_project" | "CreatorActions_project" | "FbAdsAction_project" | "HireStage_project" | "PublishingStartedStage_project">;
  readonly " $fragmentType": "AdvertiserProjectActions_project";
};
export type AdvertiserProjectActions_project$key = {
  readonly " $data"?: AdvertiserProjectActions_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdvertiserProjectActions_project">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdvertiserProjectActions_project",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "advertiserStage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectArchivation",
      "kind": "LinkedField",
      "name": "archivation",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useCase",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FbAdsAction_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreatorActions_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HireStage_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentCreationStage_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentSubmittedStage_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PublishingStartedStage_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CompletedStage_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddNotes_project"
    },
    {
      "alias": "archivationByAdvertiser",
      "args": [
        {
          "kind": "Literal",
          "name": "side",
          "value": "ADVERTISER"
        }
      ],
      "concreteType": "ProjectArchivation",
      "kind": "LinkedField",
      "name": "archivation",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "archivation(side:\"ADVERTISER\")"
    }
  ],
  "type": "Project",
  "abstractKey": null
};
})();

(node as any).hash = "f20c08705aaa1ab570b7e232fc1a92df";

export default node;
