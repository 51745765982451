import React from 'react';

import styles from './BrandCampaignsModal.pcss';

import { getCampaignLink } from 'Util/links';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import Image from 'Components/ui/Image/Image';
import Text from 'Components/ui/Text/Text';
import TextPreset from 'Components/ui/TextPreset/TextPreset';
import { Brand_brand$data, CampaignPlatform } from 'GraphTypes/Brand_brand.graphql';

type CampaignInfo = {
  platform?: CampaignPlatform | null;
  campaignId: string;
  logo?: string;
  name: string | null;
};

interface Props {
  brandName: string;
  activeCampaigns: Brand_brand$data['activeCampaigns'];
  completedCampaigns: Brand_brand$data['completedCampaigns'];
}

const BrandCampaignsModal: React.FC<Props> = (props) => {
  const { brandName, activeCampaigns, completedCampaigns } = props;

  const createCampaign = (data: CampaignInfo) => {
    const { platform, campaignId, logo, name } = data;
    const projectLink = getCampaignLink({ campaignId });

    const platformValue = platform as CampaignPlatform;

    return (
      <SmartLink key={campaignId} path={projectLink} target="_blank" className={styles.campaign}>
        <TextPreset
          header={
            <div className={styles.campaignHeader}>
              <Image src={logo} type="round" size={44} platform={platformValue || null} />
              {name && (
                <Text
                  type="h2"
                  className={styles.headerText}
                  text={name}
                  data-test="brandCampaignsModal:text:headerText"
                />
              )}
            </div>
          }
        />
      </SmartLink>
    );
  };

  return (
    <div className={styles.root}>
      <Text
        type="d2"
        msg="brand.campaigns.title"
        formatValues={{ brandName }}
        className={styles.title}
        data-test="brandCampaignsModal:text:title"
      />
      {activeCampaigns && Number(activeCampaigns.edges?.length) > 0 && (
        <>
          <Text
            type="md"
            msg="brand.campaigns.active"
            className={styles.subtitle}
            data-test="brandCampaignsModal:text:active"
          />
          {activeCampaigns.edges?.map((item) => {
            if (!item?.node) return null;

            const { id, name = '', platform, brand } = item.node;
            const logo = brand?.logo?.thumbnailUrl;
            return createCampaign({ name, campaignId: id, platform, logo });
          })}
        </>
      )}
      {completedCampaigns && Number(completedCampaigns.edges?.length) > 0 && (
        <>
          <Text
            type="md"
            msg="brand.campaigns.closed"
            className={styles.subtitle}
            data-test="brandCampaignsModal:text:closed"
          />
          {completedCampaigns.edges?.map((item) => {
            if (!item?.node) return null;

            const { id, name = '', platform, brand } = item.node;
            const logo = brand?.logo?.thumbnailUrl;
            return createCampaign({ name, campaignId: id, platform, logo });
          })}
        </>
      )}
    </div>
  );
};

export default BrandCampaignsModal;
