import React, { useState } from 'react';

import { projectForOfferLink } from 'Util/links';
import { copyText } from 'Util/copyText';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Tooltip from 'Atoms/Tooltip/Tooltip';

interface Props {
  projectId: string;
  hasOffer: boolean;
}

const PublicProjectOffer: React.FC<Props> = (props) => {
  const { hasOffer, projectId } = props;

  const [isCopied, setCopied] = useState(false);

  const handleCopyClick = () => {
    setCopied(true);

    const link = projectForOfferLink({ projectId });
    copyText(`${process.env.PUBLIC_URL}${link}`);

    setTimeout(() => {
      setCopied(false);
    }, 3500);
  };

  if (isCopied) {
    return (
      <Tooltip
        place="top"
        id={`public_link_copied_${projectId}`}
        tooltipMsg="creator_card.public_brief.copied"
      >
        <AlterButton
          type="pink"
          icon="Check"
          hover={false}
          data-test="publicProjectOffer:alterButton:unknown"
        />
      </Tooltip>
    );
  }

  return (
    <Tooltip
      place="top"
      id={`public_link_${projectId}`}
      tooltipMsg="creator_card.public_brief.tooltip"
    >
      <AlterButton
        type="grey"
        icon="Attach-link"
        disabled={!hasOffer}
        onClick={!isCopied ? handleCopyClick : undefined}
        data-test="publicProjectOffer:alterButton:unknown"
      />
    </Tooltip>
  );
};

export default PublicProjectOffer;
