import React, { useState, useEffect } from 'react';

import styles from './Number.css';

import TextButton from 'Atoms/TextButton/TextButton';
import TextSymbol from 'Atoms/TextSymbol/TextSymbol';
import icons from 'Icons';
import { NumberType } from 'Types/common';

const Number: React.FC<NumberType> = (props) => {
  const { value, name, onChange } = props;
  const [count, setCount] = useState(value);

  useEffect(() => {
    if (onChange) {
      onChange({ [name]: count });
    }
  }, [count]);

  const createMinus = () => {
    const disabled = count === 1;
    const onClick = () => setCount(count - 1);

    return <TextButton color="normal" disabled={disabled} icon={icons.minus} onClick={onClick} />;
  };

  const createPlus = () => {
    const onClick = () => setCount(count + 1);

    return <TextButton color="normal" icon={icons.plus} onClick={onClick} />;
  };

  const leftIcon = createMinus();
  const rightIcon = createPlus();

  return (
    <div className={styles.root}>
      <TextSymbol
        leftIcon={leftIcon}
        textData={{ text: count, align: 'center' }}
        rightIcon={rightIcon}
      />
    </div>
  );
};

Number.defaultProps = {
  value: 1,
} as Partial<NumberType>;

export default Number;
