/**
 * @generated SignedSource<<721c860bba17134f38178b9f112fdb30>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AuthenticateWithGmailInput = {
  clientMutationId?: string | null | undefined;
  code?: string | null | undefined;
  error?: string | null | undefined;
  id?: string | null | undefined;
};
export type AuthenticateWithGmailMutation$variables = {
  input: AuthenticateWithGmailInput;
};
export type AuthenticateWithGmailMutation$data = {
  readonly authenticateWithGmail: {
    readonly __typename: "AuthenticateWithGmailPayload";
    readonly gmailAuthorization: {
      readonly availableEmails: ReadonlyArray<string> | null | undefined;
      readonly availableNames: ReadonlyArray<string> | null | undefined;
      readonly dailySendingLimit: number;
      readonly id: string;
      readonly reauthenticationRequired: boolean;
    };
  } | {
    readonly __typename: "AuthenticateWithGmail_InvalidScopeError";
    readonly message: string;
  } | {
    readonly __typename: "NotFoundError";
    readonly message: string;
  } | {
    readonly __typename: "ValidationError";
    readonly message: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
};
export type AuthenticateWithGmailMutation = {
  response: AuthenticateWithGmailMutation$data;
  variables: AuthenticateWithGmailMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "message",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "authenticateWithGmail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GmailAuthorization",
            "kind": "LinkedField",
            "name": "gmailAuthorization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "reauthenticationRequired",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "availableEmails",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "availableNames",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dailySendingLimit",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "AuthenticateWithGmailPayload",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": (v1/*: any*/),
        "type": "NotFoundError",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": (v1/*: any*/),
        "type": "ValidationError",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": (v1/*: any*/),
        "type": "AuthenticateWithGmail_InvalidScopeError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthenticateWithGmailMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AuthenticateWithGmailMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "b142d9fa9381a1f7e0ddad84ab428da2",
    "id": null,
    "metadata": {},
    "name": "AuthenticateWithGmailMutation",
    "operationKind": "mutation",
    "text": "mutation AuthenticateWithGmailMutation(\n  $input: AuthenticateWithGmailInput!\n) {\n  authenticateWithGmail(input: $input) {\n    __typename\n    ... on AuthenticateWithGmailPayload {\n      gmailAuthorization {\n        id\n        reauthenticationRequired\n        availableEmails\n        availableNames\n        dailySendingLimit\n      }\n    }\n    ... on NotFoundError {\n      message\n    }\n    ... on ValidationError {\n      message\n    }\n    ... on AuthenticateWithGmail_InvalidScopeError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3aa181ea4dee23bac551ef63a79b2db9";

export default node;
