import React, { useState } from 'react';

import AddGmailAccountButton from '../OutreachBatchesControls/AddGmailAccountButton/AddGmailAccountButton';

import styles from './OutreachReauthBanner.pcss';

import Text from 'Components/ui/Text/Text';
import { OutreachDashboardQuery$data } from 'GraphTypes/OutreachDashboardQuery.graphql';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Icon from 'Components/ui/Icon/Icon';

interface Props {
  authorization: OutreachReauthAccountType;
}

const OutreachReauthBanner: React.FC<Props> = (props) => {
  const { authorization } = props;

  const [isShow, setShowStatus] = useState(true);

  const handleCloseBanner = () => {
    setShowStatus(false);
  };

  if (!isShow) return null;
  return (
    <div className={styles.root}>
      <Text
        type="md"
        msg="campaign.outreach.reauth.banner"
        formatValues={{ email: authorization?.node?.senderEmail || 'Your Gmail account' }}
        className={styles.text}
      />
      <AddGmailAccountButton authIdForReauth={authorization?.node?.gmailAuthorization?.id}>
        <AlterButton type="black" icon={'Google'} msg="campaign.outreach.reauth.button" />
      </AddGmailAccountButton>
      <div className={styles.close} onClick={handleCloseBanner}>
        <Icon name="Close-small" size={16} />
      </div>
    </div>
  );
};

export default OutreachReauthBanner;

export type OutreachReauthAccountType = NonNullable<
  NonNullable<NonNullable<OutreachDashboardQuery$data['campaign']>['outreachBatches']>['edges']
>[number];
