import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { amplitude } from 'Helpers/amplitude';
import { getCampaignMediaplanLink } from 'Util/links';
import AlterButton, { AlterButtonProps } from 'Components/ui/AlterButton/AlterButton';
import { CampaignContext } from 'AdvertiserPage/Campaign/Campaign.Context';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { CreatorType } from 'GraphTypes/SearchResultsContainerQuery.graphql';

interface Props {
  className?: string;
  campaignId: string;
  colorType?: AlterButtonProps['type'];
}

const NewInviteCreatorsButton: React.FC<Props> = (props) => {
  const { className, campaignId, colorType = 'pink' } = props;

  const navigate = useNavigate();

  const { openDrawer } = useContext(DrawerContext);
  const { campaignData, inviteSuggestionListId, canInviteSuggestedCreators } =
    useContext(CampaignContext);

  const campaign = campaignData?.campaign;

  let creatorTypes: CreatorType[] | undefined;

  if (campaign?.platform === 'FACEBOOK' || campaign?.platform === 'INSTAGRAM') {
    creatorTypes = ['INSTAGRAM'];
  } else if (campaign?.platform === 'TIKTOK') {
    creatorTypes = ['TIKTOK'];
  } else if (campaign?.platform === 'YOUTUBE') {
    creatorTypes = ['YOUTUBE'];
  }

  const searchQuery = { ...campaign?.searchQuery, creatorTypes, currency: ['USD'] };

  const mediaplanLink = getCampaignMediaplanLink({ campaignId });

  const handleOpenMarketplace = () => {
    amplitude.sendEvent<525>({
      id: '525',
      category: 'campaign_page',
      name: 'invite_creators_click',
      param: { result: 'marketplace', campaign_id: campaignId },
    });
    navigate(mediaplanLink, { state: { ...searchQuery } });
  };

  const handleInviteListShow = () => {
    amplitude.sendEvent<525>({
      id: '525',
      category: 'campaign_page',
      name: 'invite_creators_click',
      param: { result: 'invite-creators-drawer', campaign_id: campaignId },
    });
    openDrawer('invite-creators');
  };

  return (
    <AlterButton
      className={className}
      type={colorType}
      msg="campaign.invite_creators"
      icon="Add-plus-circle"
      onClick={
        canInviteSuggestedCreators && inviteSuggestionListId
          ? handleInviteListShow
          : handleOpenMarketplace
      }
    />
  );
};

export default NewInviteCreatorsButton;
