import React, { ChangeEvent, FC, useDeferredValue, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import styles from './OutreachBatchesControls.pcss';

import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Input from 'Components/ui/Input/Input';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownGroupItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import { OutreachBatchState } from 'GraphTypes/OutreachDashboardBatchesQuery.graphql';
import Text from 'Components/ui/Text/Text';

interface Props {
  handleInvite: () => void;
  filterText: string;
  onChangeFilterText: (value: string) => void;
  filterStates: OutreachBatchState[] | null;
  onChangeFilterStates: (value: OutreachBatchState[] | null) => void;
}

const statusList: OutreachBatchState[] = ['ACTIVE', 'STOPPED', 'COMPLETED', 'DISCONNECTED'];

const OutreachBatchesControls: FC<Props> = (props) => {
  const intl = useIntl();
  const { handleInvite, onChangeFilterText, filterText, onChangeFilterStates, filterStates } =
    props;
  const [searchText, setSearchText] = useState(filterText);
  const [selectedStatus, setSelectedStatus] = useState(filterStates);

  const searchTextValue = useDeferredValue(searchText);

  const valueProp = {
    text:
      selectedStatus === null
        ? intl.formatMessage({
            id: 'campaign.outreach.filters.all',
          })
        : `${intl.formatMessage({
            id: `${`campaign.outreach.state.${selectedStatus[0].toLowerCase()}`}`,
          })} ${selectedStatus.length > 1 ? `+${selectedStatus.length - 1}` : ''}`,
  };

  const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchText(value);
    onChangeFilterText(value);
  };

  const handleTextReset = () => {
    setSearchText('');
    onChangeFilterText('');
  };

  useEffect(() => {
    onChangeFilterStates(selectedStatus);
  }, [selectedStatus?.length]);

  const handleSelectAllStatus = () => {
    setSelectedStatus(null);
  };

  return (
    <div className={styles.root}>
      <Dropdown showArrow value={<Text type="md" {...valueProp} className={styles.statusValue} />}>
        <DropdownGroup align="left" className={styles.statusMenu}>
          <DropdownGroupItem>
            <AlterButton
              fluid
              msg="campaign.outreach.state.all"
              onClick={handleSelectAllStatus}
              iconPosition="right"
              icon={selectedStatus === null ? 'Check' : null}
            />
          </DropdownGroupItem>
          {statusList.map((status) => {
            const handleSelectStatus = () => {
              if (selectedStatus?.includes(status)) {
                setSelectedStatus((list: OutreachBatchState[]) =>
                  list.filter((item) => item !== status)
                );
              } else {
                setSelectedStatus((list: OutreachBatchState[]) => [...(list || []), status]);
              }
            };
            return (
              <DropdownGroupItem key={status}>
                <AlterButton
                  fluid
                  msg={`campaign.outreach.state.${status.toLowerCase()}`}
                  onClick={handleSelectStatus}
                  iconPosition="right"
                  icon={selectedStatus?.includes(status) ? 'Check' : null}
                />
              </DropdownGroupItem>
            );
          })}
        </DropdownGroup>
      </Dropdown>
      <div className={styles.controls}>
        <Input
          placeholderMsg="campaign.outreach.search.placeholder"
          rightIcon="Search-loop"
          value={searchTextValue}
          onChange={handleTextChange}
          onResetValue={handleTextReset}
          className={styles.input}
        />
        <AlterButton
          msg="campaign.outreach.invite"
          type="white"
          icon={'Add-plus-circle'}
          className={styles.button}
          onClick={handleInvite}
          data-test="outreachBatchesControls:alterButton:invite"
        />
      </div>
    </div>
  );
};

export default OutreachBatchesControls;
