/**
 * @generated SignedSource<<a08e5f96e6db3543df6829df882fe2c2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING";
import { FragmentRefs } from "relay-runtime";
export type ProjectDraftDate_project$data = {
  readonly archivedByAdvertiser: {
    readonly id: string;
  } | null | undefined;
  readonly campaign: {
    readonly type: CampaignType | null | undefined;
  };
  readonly completion: {
    readonly id: string;
  } | null | undefined;
  readonly deadline: {
    readonly draftDate: any | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly launch: {
    readonly id: string;
  } | null | undefined;
  readonly " $fragmentType": "ProjectDraftDate_project";
};
export type ProjectDraftDate_project$key = {
  readonly " $data"?: ProjectDraftDate_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectDraftDate_project">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectDraftDate_project",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectLaunch",
      "kind": "LinkedField",
      "name": "launch",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": "archivedByAdvertiser",
      "args": [
        {
          "kind": "Literal",
          "name": "side",
          "value": "ADVERTISER"
        }
      ],
      "concreteType": "ProjectArchivation",
      "kind": "LinkedField",
      "name": "archivation",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "archivation(side:\"ADVERTISER\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Deadline",
      "kind": "LinkedField",
      "name": "deadline",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "draftDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectCompletion",
      "kind": "LinkedField",
      "name": "completion",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};
})();

(node as any).hash = "37e1d21fbe7ffda409cc8e446f3a3fc4";

export default node;
