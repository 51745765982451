import React from 'react';

import styles from './AdvertisersBar.pcss';

import Text from 'Components/ui/Text/Text';

const AdvertisersBar = () => {
  return (
    <div className={styles.root}>
      <Text msg="advertisers.name" />
      <Text msg="advertisers.subscription" />
      <Text msg="advertisers.active_to" />
      <Text msg="advertisers.balance" />
    </div>
  );
};

export default AdvertisersBar;
