import React, { useEffect, useState, useRef } from 'react';
import track, { useTracking } from 'react-tracking';
import findIndex from 'lodash/findIndex';
import xorBy from 'lodash/xorBy';

import styles from './Autocomplete.css';
import AutocompleteMenu from './AutocompleteMenu/AutocompleteMenu';

import Input from 'Components/Field/Input';

const defaultState = {
  items: [],
  textQuery: '',
};

const Autocomplete = (props) => {
  const {
    isVisible,
    name,
    isSingle,
    value,
    onChange,
    autoClose,
    inputData,
    queryRequest,
    hasCheckbox,
    placeholderMsg,
    clearAfterSelect,
  } = props;

  const [hidden, setHidden] = useState(true);
  const [items, setItems] = useState([]);
  const [textQuery, setTextQuery] = useState('');

  const tracking = useTracking();

  const containerEl = useRef(null);

  useEffect(() => {
    document.addEventListener('click', handlePageClick);

    return () => {
      document.removeEventListener('click', handlePageClick);
    };
  }, []);

  useEffect(() => {
    if (!isVisible) {
      setItems([]);
      setTextQuery('');
    }
  }, [isVisible]);

  const handlePageClick = (event) => {
    const container = containerEl.current;
    if (!container) return;
    const isClickInside = container.contains(event.target);
    if (!isClickInside) {
      if (!autoClose) {
        setTextQuery('');
        setItems([]);
      }
      setHidden(true);
    }
  };

  const handleQueryChange = (data) => {
    const { textQuery: newTextQuery } = data;
    setTextQuery(newTextQuery);
  };

  const handleChange = (item) => {
    const isArray = Array.isArray(item.id);

    if (isArray) {
      const newValue = xorBy(item.id, value, 'id');
      onChange({ [name]: newValue });

      return;
    }

    tracking.trackEvent({ event: 'selected', payload: { value: item.name } });

    if (isSingle) {
      onChange({ [name]: item.id });
    } else {
      const index = findIndex(value, (valueItem) => {
        return valueItem.id === item.id;
      });
      const newValue = [...value];
      if (index !== -1) {
        newValue.splice(index, 1);
      } else {
        newValue.unshift(item);
      }
      onChange({ [name]: newValue });
    }
    if (autoClose) {
      setTextQuery(item.name);
      setHidden(true);
    }
  };

  const handleFocus = () => {
    setHidden(false);
  };

  return (
    <div ref={containerEl}>
      <Input
        {...inputData}
        value={textQuery}
        name="textQuery"
        placeholderMsg={placeholderMsg}
        hasChangeEvent={true}
        onFocus={handleFocus}
        onChange={handleQueryChange}
      />
      <div className={hidden ? styles.hidden : ''}>
        {React.cloneElement(queryRequest, {
          textQuery,
          menu: (
            <AutocompleteMenu
              hasCheckbox={hasCheckbox}
              value={value}
              isSingle={isSingle}
              onChange={handleChange}
            />
          ),
        })}
      </div>
    </div>
  );
};

export default track((props) => ({
  element: props.name,
}))(Autocomplete);
