import React from 'react';
import { createPaginationContainer, graphql, RelayPaginationProp } from 'react-relay';
import { isBrowser } from 'react-device-detect';

import styles from './BrandsList.pcss';
import Brand from './Brand/Brand';

import Text from 'Atoms/Text/Text';
import LoaderHandler from 'Organisms/LoaderHandler/LoaderHandler';
import Button from 'Components/ui/Button/Button';
import IconNew from 'Components/ui/Icon/Icon';
import { BrandsList_brands$data } from 'GraphTypes/BrandsList_brands.graphql';
import { BrandsQuery$data } from 'GraphTypes/BrandsQuery.graphql';

const CAMPAIGNS_COUNT = 18;
const FOOTER_OFFSET = 279;

interface Props {
  brandName: string;
  preloader: JSX.Element;
  brands: Partial<BrandsQuery$data & BrandsList_brands$data>;
  relay: RelayPaginationProp;
  canChatWithCreators?: boolean;
  isAdmin?: boolean;
  openActiveCampaignModal: () => void;
  canAddNewBrand: boolean;
  disabled: boolean;
  brandCategories: BrandsQuery$data['brandCategories'];
  onBuyAddonToActivate: (brandId: string) => void;
}

const BrandsList: React.FC<Props> = (props) => {
  const {
    relay,
    brands,
    preloader,
    isAdmin,
    openActiveCampaignModal,
    canAddNewBrand,
    disabled,
    brandCategories,
    onBuyAddonToActivate,
  } = props;
  const list = brands.brands?.edges?.map((item) => {
    if (!item?.node) return null;

    return (
      <Brand
        key={item.node.id}
        brand={item.node}
        isAdmin={!!isAdmin}
        openActiveCampaignModal={openActiveCampaignModal}
        onBuyAddonToActivate={onBuyAddonToActivate}
        canAddNewBrand={canAddNewBrand}
        disabled={disabled}
        brandCategories={brandCategories}
      />
    );
  });

  if (!list || list.length === 0) {
    return (
      <div className={styles.emptyList}>
        <IconNew name={'Planet'} />
        <Text
          color="grayDog"
          msg={'brands.dashboard.empty.list'}
          data-test="brandsList:text:unknown"
        />
      </div>
    );
  }

  const startLoadButton = (
    <Button
      color="white"
      msg="brands.dashboard.list.load"
      className={styles.loadMore}
      data-test="brandsList:button:load"
    />
  );

  return (
    <LoaderHandler
      relay={relay}
      count={CAMPAIGNS_COUNT}
      offset={FOOTER_OFFSET}
      preloader={preloader}
      startLoadButton={isBrowser ? startLoadButton : undefined}
      className={styles.root}
    >
      <div className={styles.container}>{list}</div>
    </LoaderHandler>
  );
};

export default createPaginationContainer(
  BrandsList,
  {
    brands: graphql`
      fragment BrandsList_brands on Query
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 18 }
        cursor: { type: "String" }
        brandName: { type: String }
      ) {
        brands(first: $count, after: $cursor, brandName: $brandName)
          @connection(key: "Brands_brands", filters: []) {
          edges {
            node {
              id
              ...Brand_brand
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.brands.brands;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
        brandName: props.brandName,
      };
    },
    query: graphql`
      query BrandsListPaginationQuery($count: Int!, $cursor: String, $brandName: String) {
        ...BrandsList_brands @arguments(count: $count, cursor: $cursor, brandName: $brandName)
      }
    `,
  }
);
