/**
 * @generated SignedSource<<1ee7a6cd2632ce9f9690894dee3c9f9f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Payment_PaymentServiceProvider = "QIWI" | "UNLIMINT";
export type Payment_WithdrawalStatus = "CANCELED" | "CONFIRMED" | "FAILED" | "PROCESSING" | "UNSENT";
export type W9WarningLevel = "HIGH" | "LOW" | "MEDIUM" | "NONE";
export type FailWithdrawalInput = {
  accountId: string;
  clientMutationId?: string | null | undefined;
  id?: string | null | undefined;
  withdrawalId: string;
};
export type FailWithdrawalMutation$variables = {
  input: FailWithdrawalInput;
};
export type FailWithdrawalMutation$data = {
  readonly failWithdrawal: {
    readonly withdrawal: {
      readonly id: string;
      readonly paymentMethod: {
        readonly w9WarningLevel: W9WarningLevel;
      } | null | undefined;
      readonly paymentServiceProvider: Payment_PaymentServiceProvider | null | undefined;
      readonly status: Payment_WithdrawalStatus;
      readonly statusDate: any | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type FailWithdrawalMutation = {
  response: FailWithdrawalMutation$data;
  variables: FailWithdrawalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "statusDate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paymentServiceProvider",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "w9WarningLevel",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FailWithdrawalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "FailWithdrawalPayload",
        "kind": "LinkedField",
        "name": "failWithdrawal",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Payment_Withdrawal",
            "kind": "LinkedField",
            "name": "withdrawal",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "paymentMethod",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FailWithdrawalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "FailWithdrawalPayload",
        "kind": "LinkedField",
        "name": "failWithdrawal",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Payment_Withdrawal",
            "kind": "LinkedField",
            "name": "withdrawal",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "paymentMethod",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2c8273185002c1ff3484c7af1d620155",
    "id": null,
    "metadata": {},
    "name": "FailWithdrawalMutation",
    "operationKind": "mutation",
    "text": "mutation FailWithdrawalMutation(\n  $input: FailWithdrawalInput!\n) {\n  failWithdrawal(input: $input) {\n    withdrawal {\n      id\n      status\n      statusDate\n      paymentServiceProvider\n      paymentMethod {\n        __typename\n        w9WarningLevel\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bb9063885b9456c0058e9b5c07c5038e";

export default node;
