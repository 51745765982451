import React, { Suspense, useContext } from 'react';
import track from 'react-tracking';
import classnames from 'classnames';

import styles from './Header.pcss';
import ContractorHeader from './ContractorHeader/ContractorHeader';
import AdvertiserHeader from './AdvertiserHeader/AdvertiserHeader';
import UnknownUserHeader from './UnknownUserHeader/UnknownUserHeader';

import { ADVERTISER, CONTRACTOR, UNKNOWN } from 'Constants/general';
import { Auth0Context } from 'Containers/Auth0/Auth0Context';

interface Props {
  className?: string;
  showStaticHeader?: boolean;
  classes?: {
    root?: string;
    wrap?: string;
  };
}

const Header: React.FC<Props> = (props) => {
  const { className, classes, showStaticHeader } = props;
  const { user } = useContext(Auth0Context);
  const type = user?.type;

  const headers: Record<string, React.ReactNode> = {
    STATIC: (
      <div className={styles.content}>
        <UnknownUserHeader hideUserMenu />
      </div>
    ),
    [ADVERTISER]: (
      <Suspense fallback={null}>
        <div className={styles.content}>
          <AdvertiserHeader />
        </div>
      </Suspense>
    ),
    [UNKNOWN]: (
      <div className={styles.content}>
        <UnknownUserHeader />
      </div>
    ),
    [CONTRACTOR]: (
      <div className={styles.contractorHeaderContent}>
        <ContractorHeader />
      </div>
    ),
  };

  return (
    <header
      className={classnames(styles.root, className, classes?.root, {
        [styles.contractor]: Boolean(type === CONTRACTOR),
      })}
    >
      <div className={classnames(styles.wrap, classes?.wrap)}>
        {showStaticHeader || !type ? headers.STATIC : headers[type]}
      </div>
    </header>
  );
};

export default track({
  section: 'header',
})(Header);
