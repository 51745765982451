/**
 * @generated SignedSource<<73c6cee343d7e1e640920794109f74d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CampaignPlatform = "FACEBOOK" | "INSTAGRAM" | "OTHER" | "SNAPCHAT" | "TIKTOK" | "YOUTUBE";
import { FragmentRefs } from "relay-runtime";
export type Brand_brand$data = {
  readonly active: boolean;
  readonly activeCampaigns: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly brand: {
          readonly logo: {
            readonly id: string;
            readonly thumbnailUrl: string;
            readonly transformations?: {
              readonly brandLogoUrl: string;
            } | null | undefined;
          } | null | undefined;
        } | null | undefined;
        readonly creator: {
          readonly id: string;
        } | null | undefined;
        readonly id: string;
        readonly name: string | null | undefined;
        readonly platform: CampaignPlatform | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly campaigns: {
    readonly totalCount: number;
  } | null | undefined;
  readonly category: {
    readonly id: string;
    readonly name: string;
  } | null | undefined;
  readonly completedCampaigns: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly brand: {
          readonly logo: {
            readonly thumbnailUrl: string;
            readonly transformations?: {
              readonly brandLogoUrl: string;
            } | null | undefined;
          } | null | undefined;
        } | null | undefined;
        readonly creator: {
          readonly id: string;
        } | null | undefined;
        readonly id: string;
        readonly name: string | null | undefined;
        readonly platform: CampaignPlatform | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly logo: {
    readonly id: string;
    readonly thumbnailUrl: string;
    readonly transformations?: {
      readonly brandLogoUrl: string;
    } | null | undefined;
  } | null | undefined;
  readonly name: string | null | undefined;
  readonly summary: string | null | undefined;
  readonly websiteUrl: string | null | undefined;
  readonly " $fragmentType": "Brand_brand";
};
export type Brand_brand$key = {
  readonly " $data"?: Brand_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"Brand_brand">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnailUrl",
  "storageKey": null
},
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ImageTransformation",
      "kind": "LinkedField",
      "name": "transformations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "brandLogoUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Image",
  "abstractKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "logo",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/)
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "platform",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "creator",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Brand_brand",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "active",
      "storageKey": null
    },
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "summary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "websiteUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BrandCategory",
      "kind": "LinkedField",
      "name": "category",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "stages",
          "value": [
            "ACTIVE",
            "COMPLETED"
          ]
        }
      ],
      "concreteType": "CampaignConnection",
      "kind": "LinkedField",
      "name": "campaigns",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": "campaigns(stages:[\"ACTIVE\",\"COMPLETED\"])"
    },
    {
      "alias": "activeCampaigns",
      "args": [
        {
          "kind": "Literal",
          "name": "stages",
          "value": [
            "ACTIVE"
          ]
        }
      ],
      "concreteType": "CampaignConnection",
      "kind": "LinkedField",
      "name": "campaigns",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CampaignEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Campaign",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Brand",
                  "kind": "LinkedField",
                  "name": "brand",
                  "plural": false,
                  "selections": [
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                },
                (v6/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "campaigns(stages:[\"ACTIVE\"])"
    },
    {
      "alias": "completedCampaigns",
      "args": [
        {
          "kind": "Literal",
          "name": "stages",
          "value": [
            "COMPLETED"
          ]
        }
      ],
      "concreteType": "CampaignConnection",
      "kind": "LinkedField",
      "name": "campaigns",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CampaignEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Campaign",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Brand",
                  "kind": "LinkedField",
                  "name": "brand",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "logo",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v2/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v6/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "campaigns(stages:[\"COMPLETED\"])"
    }
  ],
  "type": "Brand",
  "abstractKey": null
};
})();

(node as any).hash = "146fec6e80c0e692fd09aae78e8fdfb9";

export default node;
