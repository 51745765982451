/**
 * @generated SignedSource<<3052b6d24c7bf87caf542ef4d6eb89df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ShopifyOrderState = "ATTEMPTED_DELIVERY" | "CANCELLED" | "CONFIRMED" | "DELIVERED" | "ERROR" | "FAILURE" | "IN_TRANSIT" | "LABEL_PRINTED" | "LABEL_PURCHASED" | "OPEN" | "OUT_FOR_DELIVERY" | "PENDING" | "READY_FOR_PICKUP" | "SUCCESS";
import { FragmentRefs } from "relay-runtime";
export type ShipmentList_campaign$data = {
  readonly projects: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly creator: {
          readonly id: string;
        } | null | undefined;
        readonly id: string;
        readonly shopifyOrder: {
          readonly id: string;
          readonly state: ShopifyOrderState;
        } | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"Shipment_project">;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly pageInfo: {
      readonly endCursor: string | null | undefined;
      readonly hasNextPage: boolean;
    };
  };
  readonly " $fragmentType": "ShipmentList_campaign";
};
export type ShipmentList_campaign$key = {
  readonly " $data"?: ShipmentList_campaign$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShipmentList_campaign">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 30,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "orderState"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "textQuery"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "withoutShopifyOrder"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "projects"
        ]
      }
    ]
  },
  "name": "ShipmentList_campaign",
  "selections": [
    {
      "alias": "projects",
      "args": null,
      "concreteType": "ProjectConnection",
      "kind": "LinkedField",
      "name": "__Campaign_projects_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Project",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "creator",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "Shipment_project"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ShopifyOrder",
                  "kind": "LinkedField",
                  "name": "shopifyOrder",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "state",
                      "storageKey": null
                    },
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};
})();

(node as any).hash = "82f0a9f73b91e097a66addfb60302da9";

export default node;
