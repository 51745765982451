import React from 'react';
import { graphql, PreloadedQuery, usePaginationFragment, usePreloadedQuery } from 'react-relay';

import { AdminAdvertisersQuery } from '../AdminAdvertisers';

import styles from './AdvertisersList.pcss';
import AdvertiserItem from './AdvertiserItem/AdvertiserItem';
import AdvertisersBar from './AdvertisersBar/AdvertisersBar';

import LoaderHandlerWithHooks from 'Organisms/LoaderHandler/NewLoaderHandler';
import {
  AdminAdvertisersQuery as QueryType,
  PlanId,
  Currency,
} from 'GraphTypes/AdminAdvertisersQuery.graphql';
import { AdvertisersList_organizations$key } from 'GraphTypes/AdvertisersList_organizations.graphql';

const COUNT = 20;
const OFFSET = 70;

interface Props {
  paying: boolean | null;
  planIds: PlanId[];
  currencies: Currency[];
  queryReference: PreloadedQuery<QueryType>;
}

const AdvertisersList: React.FC<Props> = (props) => {
  const { queryReference } = props;

  const queryData = usePreloadedQuery<QueryType>(AdminAdvertisersQuery, queryReference);

  const fragmentData = usePaginationFragment<QueryType, AdvertisersList_organizations$key>(
    graphql`
      fragment AdvertisersList_organizations on Query
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 20 }
        cursor: { type: "String" }
        textQuery: { type: "String" }
        paying: { type: "Boolean" }
        planIds: { type: "[PlanId!]" }
        currencies: { type: "[Currency!]" }
      )
      @refetchable(queryName: "AdvertisersListPaginationList") {
        organizations(
          first: $count
          after: $cursor
          textQuery: $textQuery
          paying: $paying
          planIds: $planIds
          currencies: $currencies
        ) @connection(key: "Advertiser_organizations", filters: []) {
          edges {
            node {
              id
              ...AdvertiserItem_organization
            }
          }
        }
      }
    `,
    queryData
  );

  const items = Array.from(fragmentData.data.organizations?.edges || []);

  return (
    <LoaderHandlerWithHooks relay={fragmentData} offset={OFFSET} count={COUNT} items={items}>
      <div className={styles.list}>
        {items.length > 0 && <AdvertisersBar />}
        {items.map((item) => {
          if (!item?.node) return null;

          return <AdvertiserItem key={item.node.id} organization={item.node} />;
        })}
      </div>
    </LoaderHandlerWithHooks>
  );
};

export default AdvertisersList;
