import React, { Fragment } from 'react';
import { graphql, useFragment } from 'react-relay';

import styles from './OnboardingStep.pcss';
import OnboardingByAdvertiser from './OnboardingByAdvertiser/OnboardingByAdvertiser';
import OnboardingByContractor from './OnboardingByContractor/OnboardingByContractor';

import { createSum } from 'Util/numberFormatter';
import Text from 'Atoms/Text/Text';
import { ADVERTISER, CONTRACTOR, BARTER_TYPE, PRODUCT_SEEDING } from 'Constants/general';
import { UserType } from 'GraphTypes/StepperQuery.graphql';
import { OnboardingStep_project$key } from 'GraphTypes/OnboardingStep_project.graphql';
import { StepperQuery$data } from 'GraphTypes/StepperQuery.graphql';

interface Props {
  className?: string;
  isActive: boolean;
  hasShipment?: boolean;
  isPastStep: boolean;
  userType?: UserType;
  currentUser: StepperQuery$data['currentUser'];
  project: OnboardingStep_project$key;
}

const OnboardingStep: React.FC<Props> = (props) => {
  const { className, project, isActive, userType, isPastStep, hasShipment, currentUser } = props;

  const data = useFragment(
    graphql`
      fragment OnboardingStep_project on Project {
        id
        price
        currency
        paidSocial
        campaign {
          type
          paymentType
        }
        ...OnboardingByAdvertiser_project
        ...OnboardingByContractor_project
      }
    `,
    project
  );

  const currency = data.currency;
  const price = Number(data.price);

  const notProductSeeding = data.campaign.type !== PRODUCT_SEEDING;

  const isBarter = data.campaign.paymentType === BARTER_TYPE;
  const productPrice = userType === CONTRACTOR && isBarter && !price;

  const paidSocialPrefix = data.paidSocial && userType === CONTRACTOR ? '.ps' : '';
  const shipmentPrefix = hasShipment ? '_with_shipment' : '';
  const userTypeLabel = userType?.toLowerCase();

  const descr = `project.stepper.onboarding${shipmentPrefix}.${userTypeLabel}${paidSocialPrefix}.descr`;

  const finalPrice = productPrice
    ? { msg: 'project.stepper.onboarding.product_price' }
    : { text: createSum(price, currency) };

  return (
    <div className={className}>
      <div className={styles.titleWrap}>
        <Text
          weight="500"
          transform="uppercase"
          msg="project.stepper.onboarding.title"
          data-test="onboardingStep:text:title"
        />
        {isPastStep && notProductSeeding && (
          <Fragment>
            <Text weight="500" text=" — " data-test="onboardingStep:text:" />
            <Text weight="500" {...finalPrice} data-test="onboardingStep:text:unknown" />
          </Fragment>
        )}
      </div>
      {isActive && (
        <div className={styles.content}>
          <Text size="sm" msg={descr} data-test="onboardingStep:text:unknown" />
          {userType === ADVERTISER ? (
            <OnboardingByAdvertiser
              project={data}
              hasShipment={Boolean(hasShipment)}
              currentUser={currentUser}
            />
          ) : (
            <OnboardingByContractor
              notProductSeeding={notProductSeeding}
              project={data}
              productPrice={productPrice}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default OnboardingStep;
