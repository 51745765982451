import React, { useState, useMemo, useEffect } from 'react';
import { QueryRenderer } from 'react-relay';

import Skeleton from '../../components/Skeleton/Skeleton';

import styles from './PreviousCollaborations.pcss';
import Collaborations from './Collaborations/Collaborations';
import {
  PreviousCollaborationsQuery,
  PreviousCollaborationsQueryType,
} from './PreviousCollaborations.Query';

import { amplitude } from 'Helpers/amplitude';
import environment from 'Api/Environment';
import Input from 'Components/ui/Input/Input';
import CampaignsFilter from 'Organisms/CampaignsFilter/CampaignsFilter';
import BrandsFilter from 'Organisms/BrandsFilter/BrandsFilter';

const defaultFilters = {
  campaigns: undefined,
  brands: undefined,
  textQuery: undefined,
};

type Props = {
  renderStartHeaderControls?: () => React.ReactNode;
};

const PreviousCollaborations: React.FC<Props> = (props) => {
  const { renderStartHeaderControls } = props;
  const [filters, setFilters] = useState<Filters>(defaultFilters);
  const [organizationId, setOrganizationId] = useState<string>('');

  useEffect(() => {
    if (organizationId) {
      amplitude.sendEvent<262>({
        id: '262',
        category: 'pageview',
        name: 'previous_collabs_visit',
        param: undefined,
      });
    }
  }, [organizationId]);

  const brandNames = useMemo(() => {
    return (
      filters.brands?.map((item) => {
        return { [item.id]: item.name || '' };
      }) || []
    );
  }, [filters.brands]);

  const campaignIds = useMemo(() => {
    return filters.campaigns?.map((item) => item.id);
  }, [filters.campaigns]);

  const brandIds = useMemo(() => {
    return filters.brands?.map((item) => item.id);
  }, [filters.brands]);

  const textQuery = filters.textQuery;

  const handleCampaignsChange = (newCampaigns?: CampaignType[]) => {
    amplitude.sendEvent({
      id: 208,
      category: 'creators_worked',
      name: 'campaign_filter_click',
    });
    setFilters({ ...filters, campaigns: newCampaigns });
  };

  const handleBrandsChange = (id?: string, name?: string, check?: boolean) => {
    amplitude.sendEvent({
      id: 207,
      category: 'creators_worked',
      name: 'brand_filter_click',
    });
    if (!id) {
      setFilters({ ...filters, brands: undefined });
    } else if (id && name && check) {
      setFilters({ ...filters, brands: [{ id, name, check }] });
    }
  };

  const handleTextQueryChange = (text: string) => {
    setFilters({ ...filters, textQuery: text });
  };

  const handleParamsClear = () => {
    setFilters({ ...defaultFilters });
  };

  const handleTextQueryFocus = () => {
    amplitude.sendEvent({
      id: 206,
      category: 'creators_worked',
      name: 'search_click',
    });
  };

  return (
    <div className={styles.root}>
      <div className={styles.headerControls}>
        {renderStartHeaderControls && (
          <div className={styles.startHeaderControls}>{renderStartHeaderControls?.()}</div>
        )}
        <div className={styles.endHeaderControls}>
          <Input
            value={textQuery}
            placeholderMsg="campaign.creators_search"
            className={styles.textQuery}
            rightIcon="Search-loop"
            onFocus={handleTextQueryFocus}
            handleChange={handleTextQueryChange}
            data-test="previousCollaborations:input:textQuery"
          />
          <BrandsFilter selectedItems={brandNames} onAdd={handleBrandsChange} />
          <CampaignsFilter
            selectedItems={filters.campaigns}
            onCampaignSelect={handleCampaignsChange}
          />
        </div>
      </div>
      <QueryRenderer<PreviousCollaborationsQueryType>
        environment={environment}
        query={PreviousCollaborationsQuery}
        variables={{ textQuery, campaignIds, brandIds }}
        render={({ props: queryProps }) => {
          if (!queryProps?.currentUser?.organization) return <Skeleton />;

          const organization = queryProps.currentUser.organization;

          setOrganizationId(organization.id);

          return (
            <Collaborations
              organization={organization}
              textQuery={textQuery}
              campaignIds={campaignIds}
              brandIds={brandIds}
              onParamsClear={handleParamsClear}
            />
          );
        }}
      />
    </div>
  );
};

export default PreviousCollaborations;

// types

type BrandType = {
  id: string;
  name: string | null;
  check: boolean;
};

type CampaignType = {
  id: string;
  name: string | null;
};

interface Filters {
  campaigns?: CampaignType[];
  brands?: BrandType[];
  textQuery?: string;
}
