import React, { useMemo } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import classnames from 'classnames';

import styles from './Button.css';

import modsClasses from 'Util/modsClasses.js';
import Spinner from 'Atoms/Spinner/Spinner';
import Icon from 'Atoms/Icon/Icon';
import NewNotification from 'Atoms/NewNotification/NewNotification';
import colors from 'Styles/vars/colors.css';
import { buttonColor, buttonTheme, NewButtonType } from 'Types/common';

const MODS = ['size', 'color', 'theme', 'mainColor', 'width', 'radius', 'contentAlign', 'border'];

const getPreloaderColor = (color?: buttonColor, theme?: buttonTheme) => {
  switch (color) {
    case 'primary':
      return colors.colorDark100;
    case 'secondary':
      return colors.colorBase;
    case 'normal':
      return theme === 'dark' ? colors.colorWhite : colors.colorWetAsphalt;
    default:
      return colors.colorWhite;
  }
};

const Button: React.FC<NewButtonType & WrappedComponentProps> = (props) => {
  const {
    intl,
    active,
    msg,
    msgValues = undefined,
    type = 'button',
    text,
    icon,
    iconName,
    leftIcon,
    leftIconName,
    rightIcon,
    rightIconName,
    loading,
    disabled,
    style,
    notificationCount,
    className,
    onClick,
  } = props;

  const textContent = useMemo(() => {
    let textValue = '';

    if (msg) {
      textValue = intl.formatMessage({ id: msg }, msgValues);
    } else if (text) {
      textValue = text;
    }

    return textValue ? <span className={styles.text}>{textValue}</span> : null;
  }, [msgValues, msg, text]);

  const content = useMemo(() => {
    return icon || iconName ? (
      <div className={styles.icon}>{iconName ? <Icon name={iconName} /> : icon}</div>
    ) : (
      <div className={styles.content} data-test="content">
        {leftIcon}
        {leftIconName && <Icon name={leftIconName} />}
        {textContent}
        {rightIconName && <Icon name={rightIconName} />}
        {rightIcon}
        {Number.isInteger(notificationCount) && <NewNotification count={notificationCount} />}
      </div>
    );
  }, [
    icon,
    iconName,
    notificationCount,
    leftIcon,
    leftIconName,
    textContent,
    rightIconName,
    rightIcon,
  ]);

  const getPreloader = () => {
    const { color, theme } = props;
    const preloaderColor = getPreloaderColor(color, theme);

    return <Spinner className={styles.preloader} color={preloaderColor} />;
  };

  const classes = modsClasses(MODS, props, styles);
  const classNameList = classnames(classes, styles.root, className, {
    [styles.loading]: loading,
    [styles.disabled]: disabled,
    [styles.withContent]: textContent,
    [styles.active]: active,
  });

  const handleClick = disabled || loading ? undefined : onClick;

  return (
    <button
      type={type}
      style={style}
      className={classNameList}
      onClick={handleClick}
      data-test="button"
    >
      {loading && getPreloader()}
      {content}
    </button>
  );
};

Button.defaultProps = {
  theme: 'light',
  size: 'md',
  color: 'primary',
  mainColor: 'base',
} as Partial<NewButtonType>;

export default injectIntl(Button);
