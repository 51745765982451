/**
 * @generated SignedSource<<515f750368607b079368adee6ec85a04>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type AddonId = "BRANDS" | "SEATS" | "WHITELISTING_LICENSES";
export type PaymentType = "BARTER" | "MONEY";
export type PlanId = "ADVANCED" | "ADVANCED_2021_10" | "ADVANCED_2022_11" | "AGENCY_2024_03" | "BARTER" | "BASIC_OLD_PS" | "BRAND_2024_03" | "CONTENT_CREATION" | "ENTERPRISE" | "FREE" | "FREE_OLD_PS" | "FREE_TRIAL" | "FULLY_MANAGED" | "FULLY_MANAGED_1" | "FULLY_MANAGED_2" | "FULLY_MANAGED_TTCX_MMA" | "GROW" | "PAYOUT" | "PREMIUM" | "PREMIUM_PLUS" | "PUBLICIS" | "SCALE" | "SCALE_2021_06" | "SCALE_2021_10" | "SMARTLY_ENTERPRISE_2023_06" | "SMARTLY_ENTERPRISE_TRIAL_2023_06" | "TRIAL_2022_08" | "TRIAL_2024_03" | "TRIAL_MONTH" | "UGC_2022_08" | "UGC_ADS_2022_08" | "WILDJAM";
export type SubscriptionInterval = "BIANNUAL" | "CUSTOM" | "MONTHLY" | "TRIMONTHLY" | "YEARLY";
export type OrganizationSubscriptionUpdatedSubscription$variables = Record<PropertyKey, never>;
export type OrganizationSubscriptionUpdatedSubscription$data = {
  readonly organizationSubscriptionUpdated: {
    readonly newClient: boolean;
    readonly paying: boolean;
    readonly subscription: {
      readonly activeDiscount: {
        readonly name: string | null | undefined;
      } | null | undefined;
      readonly addonItems: ReadonlyArray<{
        readonly addon: {
          readonly id: AddonId;
          readonly resourcesPerUnit: number;
        };
        readonly quantity: number;
      }>;
      readonly availablePaymentTypes: ReadonlyArray<PaymentType>;
      readonly availablePlanIds: ReadonlyArray<PlanId>;
      readonly baseLimits: {
        readonly maxActiveBrands: number | null | undefined;
        readonly maxBcaConnections: number | null | undefined;
        readonly maxLicensedCreators: number | null | undefined;
        readonly maxSeats: number | null | undefined;
      };
      readonly canApplyDiscount: boolean;
      readonly canUsePaidSocial: boolean;
      readonly cancelAt: any | null | undefined;
      readonly discountOfferAcceptedAt: any | null | undefined;
      readonly effectiveLimits: {
        readonly maxActiveBrands: number | null | undefined;
        readonly maxBcaConnections: number | null | undefined;
        readonly maxLicensedCreators: number | null | undefined;
        readonly maxPaidSocialActivationDays: number | null | undefined;
        readonly maxSeats: number | null | undefined;
      };
      readonly interval: SubscriptionInterval | null | undefined;
      readonly lastSchedule: {
        readonly discount: {
          readonly percentOff: number | null | undefined;
        } | null | undefined;
        readonly startDate: any;
        readonly trialEnd: any | null | undefined;
      } | null | undefined;
      readonly pauseEvent: {
        readonly pausedAt: any;
        readonly resumesAt: any;
      } | null | undefined;
      readonly paused: boolean;
      readonly planId: PlanId;
      readonly priceAmount: number | null | undefined;
      readonly renewsAt: any | null | undefined;
    } | null | undefined;
  };
};
export type OrganizationSubscriptionUpdatedSubscription = {
  response: OrganizationSubscriptionUpdatedSubscription$data;
  variables: OrganizationSubscriptionUpdatedSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paying",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "newClient",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "availablePlanIds",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "planId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paused",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "priceAmount",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "interval",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "renewsAt",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "AddonItem",
  "kind": "LinkedField",
  "name": "addonItems",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Addon",
      "kind": "LinkedField",
      "name": "addon",
      "plural": false,
      "selections": [
        (v9/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "resourcesPerUnit",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantity",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "maxLicensedCreators",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "maxActiveBrands",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "maxBcaConnections",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "maxSeats",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "SubscriptionLimits",
  "kind": "LinkedField",
  "name": "effectiveLimits",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxPaidSocialActivationDays",
      "storageKey": null
    },
    (v11/*: any*/),
    (v12/*: any*/),
    (v13/*: any*/),
    (v14/*: any*/)
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "SubscriptionLimits",
  "kind": "LinkedField",
  "name": "baseLimits",
  "plural": false,
  "selections": [
    (v12/*: any*/),
    (v11/*: any*/),
    (v13/*: any*/),
    (v14/*: any*/)
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canApplyDiscount",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canUsePaidSocial",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "availablePaymentTypes",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cancelAt",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discountOfferAcceptedAt",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "SubscriptionPauseEvent",
  "kind": "LinkedField",
  "name": "pauseEvent",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pausedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "resumesAt",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "percentOff",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "trialEnd",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationSubscriptionUpdatedSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationSubscriptionUpdated",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AdvertiserSubscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Discount",
                "kind": "LinkedField",
                "name": "activeDiscount",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v10/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SubscriptionSchedule",
                "kind": "LinkedField",
                "name": "lastSchedule",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Discount",
                    "kind": "LinkedField",
                    "name": "discount",
                    "plural": false,
                    "selections": [
                      (v23/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v24/*: any*/),
                  (v25/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "OrganizationSubscriptionUpdatedSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationSubscriptionUpdated",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AdvertiserSubscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Discount",
                "kind": "LinkedField",
                "name": "activeDiscount",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v10/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SubscriptionSchedule",
                "kind": "LinkedField",
                "name": "lastSchedule",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Discount",
                    "kind": "LinkedField",
                    "name": "discount",
                    "plural": false,
                    "selections": [
                      (v23/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v24/*: any*/),
                  (v25/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e657fcb82b4d5269efa001875c38b054",
    "id": null,
    "metadata": {},
    "name": "OrganizationSubscriptionUpdatedSubscription",
    "operationKind": "subscription",
    "text": "subscription OrganizationSubscriptionUpdatedSubscription {\n  organizationSubscriptionUpdated {\n    paying\n    newClient\n    subscription {\n      activeDiscount {\n        name\n        id\n      }\n      availablePlanIds\n      planId\n      paused\n      priceAmount\n      interval\n      renewsAt\n      addonItems {\n        addon {\n          id\n          resourcesPerUnit\n        }\n        quantity\n      }\n      effectiveLimits {\n        maxPaidSocialActivationDays\n        maxLicensedCreators\n        maxActiveBrands\n        maxBcaConnections\n        maxSeats\n      }\n      baseLimits {\n        maxActiveBrands\n        maxLicensedCreators\n        maxBcaConnections\n        maxSeats\n      }\n      canApplyDiscount\n      canUsePaidSocial\n      availablePaymentTypes\n      cancelAt\n      discountOfferAcceptedAt\n      pauseEvent {\n        pausedAt\n        resumesAt\n      }\n      lastSchedule {\n        discount {\n          percentOff\n          id\n        }\n        trialEnd\n        startDate\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "8dca4b7e92496e027dc3a7b2002e2990";

export default node;
