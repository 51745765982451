import React, { Suspense, useContext, useEffect, useState } from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';
import { useNavigate, useSearchParams } from 'react-router-dom';
import compact from 'lodash/compact';
import { Link } from 'react-router-dom';

import styles from './InviteCreatorsButton.pcss';

import { getCampaignMediaplanLink } from 'Util/links';
import { useCreateInviteSuggestionListQueryMutation } from 'Mutations/CreateInviteSuggestionList.Mutation';
import { amplitude } from 'Helpers/amplitude';
import Tabs from 'Molecules/Tabs/Tabs';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import InviteCreatorsDrawer from 'Modal/advertiser/InviteCreators/InviteCreators';
import { CreatorType } from 'GraphTypes/SearchResultsContainerQuery.graphql';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { BILLING_ROUTE, OUTREACH } from 'Constants/general';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import Text from 'Components/ui/Text/Text';
import { FeatureFlagsContext } from 'Containers/FeatureFlags/FeatureFlags.Context';
import { InviteCreatorsButtonQuery as QueryType } from 'GraphTypes/InviteCreatorsButtonQuery.graphql';

interface Props {
  campaignId: string;
  section?: string;
  admin: boolean;
  onSectionChange?: (section: string) => void;
}

const InviteCreatorsButtonQuery = graphql`
  query InviteCreatorsButtonQuery($campaignId: ID!) {
    campaign(id: $campaignId) {
      id
      type
      postingType
      completion {
        id
      }
      organization {
        subscription {
          paused
        }
      }
      platform
      maxInvitationCount
      usedInvitationCount
      searchQuery {
        customLists {
          id
          name
        }
        id
        ageRanges {
          id
          name
        }
        audienceCities {
          id
          name
        }
        audienceGenderPercentage
        audienceAgeRangePercentage
        audienceCountryPercentage
        audienceCityPercentage
        audienceGender {
          id
          name
        }
        categories {
          id
          name
        }
        cities {
          id
          name
        }
        countries {
          id
          name
        }
        tags {
          id
          name
        }
        creatorCountries {
          id
          name
        }
        currency
        creatorEthnicities {
          id
          name
        }
        creatorAnimals {
          id
          name
        }
        audienceEthnicities {
          id
          name
        }
        creatorBirthdateFrom
        creatorBirthdateTo
        engagementRateFrom
        engagementRateTo
        followersFrom
        followersTo
        realFollowersFrom
        realFollowersTo
        viewsPerPostFrom
        viewsPerPostTo
        viewsMedianFrom
        viewsMedianTo
        storiesReachFrom
        storiesReachTo
        audienceInterestPercentage
        postsReachFrom
        postsReachTo
        reelsPlaysMedianFrom
        reelsPlaysMedianTo
        campaignsCompletionsCountFrom
        campaignsCompletionsCountTo
        onlyCreatorsWorkedWith
        creatorGender {
          id
          name
        }
        creatorLanguages {
          id
          name
        }
        creatorBodyTypes {
          id
          name
        }
        creatorHairTypes {
          id
          name
        }
        excludeBadges
        experienced
        reliable
        highAverageRating
        strictlyByPrimaryCategory
        includeAmazonStorefrontLink
        favorite
        withTiktokShop
      }
    }
  }
`;

const InviteCreatorsButton: React.FC<Props> = (props) => {
  const { campaignId, admin, section, onSectionChange } = props;

  const { openDrawer } = useContext(DrawerContext);
  const { hasFeatureFlag } = useContext(FeatureFlagsContext);

  const navigate = useNavigate();

  const [inviteSuggestionListId, setInviteSuggestionListId] = useState('');
  const [inviteSuggestionListCount, setInviteSuggestionListCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const [useCreateInviteSuggestionListQuery, listLoading] =
    useCreateInviteSuggestionListQueryMutation();

  const campaignData = useLazyLoadQuery<QueryType>(InviteCreatorsButtonQuery, { campaignId });
  const data = campaignData?.campaign;

  const searchQueryValue = data?.searchQuery;
  const isPlanPaused = Boolean(data?.organization?.subscription?.paused);

  const [searchParams, setSearchParams] = useSearchParams();
  const urlInviteSuggestionListId = searchParams.get('inviteSuggestionListId');
  useEffect(() => {
    if (urlInviteSuggestionListId !== null && !isPlanPaused) {
      handleInviteClick();
    }
  }, [urlInviteSuggestionListId]);

  const mediaplanLink = getCampaignMediaplanLink({ campaignId });

  const canUseOutreachTool = hasFeatureFlag('outreach_tool') || admin;

  const items = compact([
    canUseOutreachTool
      ? {
          textData: { msg: 'filter_mark.outreach' },
          id: OUTREACH,
          betaTag: true,
        }
      : null,
  ]);

  const handleSectionSelect = (id: string) => {
    onSectionChange?.(id);
  };

  const tabs = <Tabs currentItemId={section} items={items} onChange={handleSectionSelect} />;
  let creatorTypes: CreatorType[] | undefined;

  if (data?.platform === 'FACEBOOK' || data?.platform === 'INSTAGRAM') {
    creatorTypes = ['INSTAGRAM'];
  } else if (data?.platform === 'TIKTOK') {
    creatorTypes = ['TIKTOK'];
  } else if (data?.platform === 'YOUTUBE') {
    creatorTypes = ['YOUTUBE'];
  }

  const searchQuery = { ...searchQueryValue, creatorTypes, currency: ['USD'] };

  if ((data?.maxInvitationCount || 0) - (data?.usedInvitationCount || 0) <= 0) {
    return (
      <>
        <div className={styles.tabs}>
          <Link to={mediaplanLink} state={{ ...searchQuery }}>
            <AlterButton
              type="pink"
              hover={false}
              msg="campaign.invite_creators"
              data-test="inviteCreators:alterButton:inviteCreators"
            />
          </Link>
          {tabs}
        </div>
        <div className={styles.divider} />
      </>
    );
  }

  const handleInviteClick = () => {
    amplitude.sendEvent<388>({
      id: '388',
      category: 'campaign',
      name: 'invite_drawer_shown',
      param: { creators_count: 1 },
    });
    setLoading(true);
    useCreateInviteSuggestionListQuery({
      variables: { input: { campaignId } },
      onError: () => {
        navigate(mediaplanLink, { state: { ...searchQuery } });
      },
      onCompleted: (data) => {
        if (data.createInviteSuggestionList?.__typename === 'LimitExceededError') {
          navigate(mediaplanLink, { state: { ...searchQuery } });
          return;
        }
        if (data.createInviteSuggestionList?.__typename !== 'CreateInviteSuggestionListPayload') {
          return;
        }
        const inviteSuggestionListId = data.createInviteSuggestionList?.inviteSuggestionList?.id;
        const totalCount =
          data.createInviteSuggestionList?.inviteSuggestionList?.inviteSuggestionsCount.totalCount;
        if (inviteSuggestionListId) {
          setInviteSuggestionListCount(totalCount || 0);
          setInviteSuggestionListId(inviteSuggestionListId);
          setTimeout(() => {
            openDrawer('invite-creators');
          }, 0);
        }
        setLoading(false);
      },
    });
  };

  const inviteNotVisible = !(
    (data?.type === 'INFLUENCER' && data.postingType === 'ORGANIC_POSTING') ||
    data?.type === 'PRODUCT_SEEDING'
  );

  return (
    <div className={styles.root}>
      <div className={styles.tabs}>
        {!inviteNotVisible && (
          <Tooltip
            id={`campaign.invite_creators_new.tooltip.pause_plan`}
            place={'bottom'}
            clickable
            customTooltip={
              isPlanPaused ? (
                <>
                  <Text
                    type="md"
                    msg="campaign.invite_creators.tooltip.pause_plan"
                    data-test="inviteCreators:text:pausePlan"
                  />
                  <a href={BILLING_ROUTE} className={styles.resumePlanTooltipLink}>
                    <Text
                      type="md"
                      msg="campaign.invite_creators.tooltip.pause_plan.button"
                      data-test="inviteCreators:text:button"
                    />
                  </a>
                </>
              ) : null
            }
          >
            <AlterButton
              type="pink"
              hover={false}
              disabled={isPlanPaused}
              loading={loading || listLoading}
              msg="campaign.invite_creators"
              onClick={handleInviteClick}
              data-test="inviteCreators:alterButton:inviteCreators"
            />
          </Tooltip>
        )}
        {tabs}
      </div>
      {(!inviteNotVisible || items.length > 0) && <div className={styles.divider} />}
      <Suspense fallback={null}>
        {searchQuery && inviteSuggestionListId && (
          <InviteCreatorsDrawer
            usedInvitationCount={data?.usedInvitationCount || 0}
            maxInvitationCount={data?.maxInvitationCount || 0}
            campaignId={campaignId}
            searchQuery={searchQuery}
            inviteSuggestionListCount={inviteSuggestionListCount}
            inviteSuggestionListId={inviteSuggestionListId}
          />
        )}
      </Suspense>
    </div>
  );
};

export default InviteCreatorsButton;
