import React, { useState, useContext } from 'react';

import styles from './ReimbursementPayment.pcss';

import TopupBalanceContainer from 'Modal/advertiser/TopupBalance/TopupBalanceContainer/TopupBalanceContainer';
import createReimbursement from 'Mutations/CreateReimbursement.Mutation';
import Text from 'Components/ui/Text/Text';
import Button from 'Components/ui/Button/Button';
import Drawer from 'Components/ui/Drawer/Drawer';
import Input from 'Components/ui/Input/Input';
import { modalName } from 'Types/modals';
import { failData } from 'Constants/messageModal';
import { amplitude } from 'Helpers/amplitude';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';

const LIMIT = 1000;

interface Props {
  projectId: string;
  balance?: number;
}

const ReimbursementPayment: React.FC<Props> = (props) => {
  const { projectId, balance = 0 } = props;
  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(false);
  const [isTopupVisible, setTopupVisible] = useState(false);

  const { closeDrawer } = useContext(DrawerContext);
  const { dispatch: modalDispatch } = useContext(ModalContext);

  const needTopup = Number(amount) > balance;
  const amountValue = (amount && parseInt(amount, 10)) || 0;

  const handleAmountChange = (value: string) => {
    const newValue = value.replace(/[^0-9]/g, '');
    setAmount(newValue);
  };

  const handleMakePayment = () => {
    modalDispatch({
      type: ModalTypes.TOGGLE_MODAL,
    });
    if (!amountValue) return;
    setLoading(true);
    createReimbursement(
      { projectId, amount: amountValue, currency: 'USD' },
      handlePaymentDone,
      handlePaymentFailed
    );
  };

  const handlePaymentDone = () => {
    amplitude.sendEvent<312>({
      id: '312',
      category: 'product_reimbursement',
      name: 'product_reimbursement_transferred',
      param: { project_id: projectId, amount: amountValue },
    });
    setAmount('');
    setLoading(false);
    setTopupVisible(false);
    closeDrawer(`project-reimburse`);
  };

  const handlePaymentFailed = () => {
    modalDispatch({
      type: ModalTypes.SET_MODAL,
      payload: { name: modalName.MESSAGE, attach: { ...failData } },
    });
  };

  const handleSubmitClick = () => {
    if (needTopup) {
      setTopupVisible(true);
    } else {
      handleMakePayment();
    }
  };

  const btnMsg = needTopup
    ? 'reimbursement_payment_modal.topup.btn'
    : 'reimbursement_payment_modal.btn';
  const outOfLimit = amountValue && amountValue > LIMIT;

  return (
    <Drawer rootKey={`project-reimburse`} className={styles.drawer}>
      {isTopupVisible && (
        <TopupBalanceContainer
          amount={amountValue - balance}
          purpose="TOPUP_FOR_REIMBURSMENT_PAYMENT"
          successCallback={handleMakePayment}
        />
      )}
      {!isTopupVisible && (
        <div className={styles.drawerContainer}>
          <div className={styles.content}>
            <Text type="d2" msg="reimbursement_payment_modal.title" className={styles.title} />
            <Text type="md" msg="reimbursement_payment_modal.descr" className={styles.descr} />
            <Input
              placeholderMsg="reimbursement_payment_modal.amount.descr"
              value={amount}
              handleChange={handleAmountChange}
              bordered
              className={styles.input}
            />
            {needTopup && !outOfLimit && (
              <Text
                type="sm"
                msg="reimbursement_payment_modal.need_topup"
                className={styles.disclaimer}
              />
            )}
            {!!outOfLimit && (
              <Text
                type="sm"
                msg="reimbursement_payment_modal.limit"
                className={styles.disclaimer}
              />
            )}
          </div>
          <Button
            color="black"
            msg={btnMsg}
            loading={loading}
            disabled={!!outOfLimit || !amount}
            className={styles.btn}
            onClick={handleSubmitClick}
          />
        </div>
      )}
    </Drawer>
  );
};

export default ReimbursementPayment;
