import React from 'react';

import styles from './Param.pcss';

import Text from 'Atoms/Text/Text';
import TextButton from 'Atoms/TextButton/TextButton';
import Icon from 'Atoms/Icon/Icon';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { TextType, TooltipType } from 'Types/common';

interface Props {
  id: string;
  className?: string;
  paramName?: string;
  removeDisabled?: boolean;
  titleData: TextType;
  tooltipData?: TooltipType;
  onTextClick?: () => void;
  onRemove: (id: string, paramName?: string) => void;
}

const SearchParam: React.FC<Props> = (props) => {
  const {
    id,
    className,
    paramName,
    titleData,
    tooltipData,
    removeDisabled,
    onTextClick,
    onRemove,
  } = props;

  const handleRequestDelete = () => {
    onRemove(id, paramName);
  };

  const text = (
    <div onClick={onTextClick} className={onTextClick ? styles.copyable : undefined}>
      <Text size="sm" {...titleData} data-test="param:text:unknown" />
    </div>
  );

  return (
    <div className={`${styles.root} ${className}`}>
      {tooltipData ? (
        <Tooltip {...tooltipData} className={styles.tooltip}>
          {text}
        </Tooltip>
      ) : (
        text
      )}
      {!removeDisabled && (
        <TextButton
          color="normal"
          icon={<Icon size="sm" name="close" />}
          onClick={handleRequestDelete}
          className={styles.delete}
          data-test="param:textButton:delete"
        />
      )}
    </div>
  );
};

export default SearchParam;
