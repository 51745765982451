import React, { useCallback, useMemo } from 'react';
import find from 'lodash/find';
import cn from 'classnames';

import {
  getIntervalValue,
  getPlans,
  AvailableDiscountType,
  ExtendedPlanId,
  AddonSettings,
} from '../data';
import { AddonsSubscriptionType, BillingPlansProp, BillingPlansPropList } from '../BillingPlans';

import styles from './Plans.pcss';
import planStyles from './Plan/Plan.pcss';
import Plan from './Plan/Plan';

import { amplitude } from 'Helpers/amplitude';
import { MANAGED_SERVICE, TRIAL_2022_08_PLAN, TRIAL_MONTH_PLAN } from 'Constants/general';
import { SubscriptionInterval as SubscriptionIntervalType } from 'Types/enums';
import { Currency, PlanId } from 'GraphTypes/BillingPlansQuery.graphql';
import { getBookADemoLink } from 'Util/links';
import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';

interface Props {
  className?: string;
  rootClassName?: string;
  planId?: PlanId;
  currency?: Currency;
  admin?: boolean;
  trialMonthPlanAvailable?: boolean;
  availablePlanIds?: PlanId[];
  plansProps: BillingPlansPropList;
  changePlansProps: (planId: ExtendedPlanId, props: Partial<BillingPlansProp>) => void;
  defaultInterval: SubscriptionIntervalType;
  renewsAt?: any;
  onPlanBuyClick: (data: object) => void;
  place: 'modal' | 'plans';
  availableDiscount?: AvailableDiscountType;
  defaultAddonsValue: AddonsSubscriptionType;
}

const Plans: React.FC<Props> = (props) => {
  const {
    className,
    rootClassName,
    admin,
    planId: currentPlanId,
    currency,
    availablePlanIds,
    onPlanBuyClick,
    plansProps,
    changePlansProps,
    defaultInterval,
    trialMonthPlanAvailable,
    place,
    availableDiscount,
    defaultAddonsValue,
  } = props;

  if (!currency || !availablePlanIds) return null;

  const plans = useMemo(() => {
    return getPlans({
      currency,
      plansProps,
      planStyles,
      trialMonthPlanAvailable,
      availableDiscount,
      currentPlanId,
    });
  }, [currency, plansProps, planStyles, trialMonthPlanAvailable, availableDiscount, getPlans]);

  const bookDemoLink = getBookADemoLink();

  const handleManagedServiceBtnClick = useCallback(() => {
    amplitude.sendEvent({
      id: '135',
      category: 'billing',
      name: 'banner_managed_service',
      param: {
        place: 'billing_page_under_plans',
      },
    });
    window.open(MANAGED_SERVICE, '_blank');
  }, []);

  const handleBookDemoClick = useCallback(() => {
    amplitude.sendEvent({
      id: '131',
      category: 'billing',
      name: 'banner_book_demo_on_platform',
      param: {
        place: 'billing_page_above_plans',
      },
    });
    window.open(bookDemoLink, '_blank');
  }, []);

  const onPlanIntervalChanged = (planId: ExtendedPlanId, interval: SubscriptionIntervalType) => {
    changePlansProps(planId, { ...plansProps[planId], interval });
  };

  const onPlanAddonsChanged = (planId: ExtendedPlanId, addons: AddonSettings[]) => {
    changePlansProps(planId, { ...plansProps[planId], addons });
  };

  return (
    <div className={cn(styles.root, rootClassName)}>
      <div className={cn(className, styles.list)}>
        {availablePlanIds.map((planName) => {
          const currentPlan = find(plans, (item) => {
            return item.planName === planName;
          });

          if (!currentPlan) return null;
          const { planId, price, planName: name } = currentPlan;
          const currentPlanProps = plansProps[planId];

          if (!planId || !currentPlanProps) {
            return null;
          }

          const interval = currentPlanProps.interval;

          const isTrialMonthPlan = planId === TRIAL_MONTH_PLAN || planId === TRIAL_2022_08_PLAN;

          const isActive =
            name === currentPlanId &&
            (isTrialMonthPlan ? true : price ? defaultInterval === interval : true);

          const isUpgarde =
            name === currentPlanId &&
            !isTrialMonthPlan &&
            getIntervalValue(interval) > getIntervalValue(defaultInterval);

          return (
            <Plan
              key={planId}
              admin={admin}
              isActive={isActive}
              isUpgarde={isUpgarde}
              isCurrentPlan={currentPlanId === planId}
              {...currentPlan}
              place={place}
              interval={currentPlanProps.interval}
              addons={currentPlanProps.addons}
              onPlanIntervalChanged={onPlanIntervalChanged}
              onPlanAddonsChanged={onPlanAddonsChanged}
              currency={currency}
              onPlanBuyClick={onPlanBuyClick}
              defaultAddonsValue={defaultAddonsValue}
            />
          );
        })}
      </div>
      <div className={styles.fullyManagedServiceBanner}>
        <div className={styles.fullyManagedServiceBannerBg} />
        <div className={styles.fullyManagedServiceBannerTextContainer}>
          <Text
            type="h1"
            msg={'billing_plans.plans.fully_management_service_banner.title'}
            data-test="plans:text:unknown"
          />
          <Text
            type="md"
            msg={'billing_plans.plans.fully_management_service_banner.text'}
            data-test="plans:text:unknown"
          />
          <div className={styles.fullyManagedServiceBannerButtonContainer}>
            <AlterButton
              msg="billing_plans.plans.fully_management_service_banner.btn.book"
              type="black"
              onClick={handleBookDemoClick}
              data-test="plans:alterButton:book"
            />
            <AlterButton
              msg="billing_plans.plans.fully_management_service_banner.btn.learn"
              onClick={handleManagedServiceBtnClick}
              data-test="plans:alterButton:learn"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plans;
