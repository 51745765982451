import React, { FC } from 'react';
import { graphql, useFragment } from 'react-relay';
import xor from 'lodash/xor';

import styles from './CustomLists.pcss';

import { amplitude } from 'Helpers/amplitude';
import { getCreatorData } from 'Util/creatorData';
import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import ProgressiveInput from 'Components/ProgressiveInput/ProgressiveInput';
import { CustomLists_customLists$key } from 'GraphTypes/CustomLists_customLists.graphql';
import { CheckboxItemType } from 'Components/ui/CheckboxSelect/CheckboxSelect';
import { CreatorsDetails_campaign$data } from 'GraphTypes/CreatorsDetails_campaign.graphql';
import { CustomLists_Creator$key } from 'GraphTypes/CustomLists_Creator.graphql';

const ListPreview: React.FC<{
  creators: Array<{ id: string; avatarUrl: string }>;
  total: number;
  handleRemove: (id: string) => void;
  listName: string;
  id: string;
}> = ({ creators, total, listName, handleRemove, id }) => {
  return (
    <div className={styles.previewRoot}>
      {creators.map((creator) => (
        <img alt="" className={styles.avatar} key={creator?.id} src={creator?.avatarUrl} />
      ))}
      <Text
        msg="brief_template.field.creators.custom_lists.preview"
        type="md"
        formatValues={{ creatorsCount: total, listName: listName }}
        data-test="customLists:text:preview"
      />
      <div onClick={() => handleRemove(id)} className={styles.previewDelete}>
        <Icon name="Close-small" />
      </div>
    </div>
  );
};

interface Props {
  customListsRoot: CustomLists_customLists$key;
  favListsRoot: CustomLists_Creator$key;
  handleCampaignChange: (data: {
    inviteFavorites?: boolean;
    inviteCustomListIds?: Array<string>;
  }) => void;
  inviteFavorites: NonNullable<CreatorsDetails_campaign$data>['inviteFavorites'];
  inviteCustomLists: NonNullable<CreatorsDetails_campaign$data>['inviteCustomLists'];
}

const CustomLists: FC<Props> = ({
  customListsRoot,
  favListsRoot,
  handleCampaignChange,
  inviteCustomLists,
  inviteFavorites,
}) => {
  const customListFragment = useFragment(
    graphql`
      fragment CustomLists_customLists on CustomListConnection {
        totalCount
        edges {
          node {
            id
            name
            creators(first: 3) {
              totalCount
              edges {
                node {
                  id
                  profile {
                    profilePictureFile {
                      ... on Image {
                        transformations {
                          collageThumbnailUrl
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    customListsRoot
  );

  const favouritesFragment = useFragment(
    graphql`
      fragment CustomLists_Creator on CreatorConnection {
        edges {
          node {
            id
            profile {
              profilePictureFile {
                ... on Image {
                  transformations {
                    collageThumbnailUrl
                  }
                }
              }
            }
          }
        }
        totalCount
      }
    `,
    favListsRoot
  );

  const arrayOfSelectedId = inviteCustomLists?.map((item) => item.id) || [];

  const handleListSelectionChange = (id: string) => {
    if (id === 'Favorites') {
      amplitude.sendEvent<459>({
        id: '459',
        category: 'brief',
        name: 'invite_from_favourites_clicked',
        param: { enabled: !inviteFavorites },
      });
      handleCampaignChange({ inviteFavorites: !inviteFavorites });
    } else {
      amplitude.sendEvent<458>({
        id: '458',
        category: 'brief',
        name: 'invite_from_custom_lists_clicked',
        param: {
          enabled: !arrayOfSelectedId?.includes(id),
          listId: id,
          listName: customListFragment?.edges?.find((item) => item?.node?.id === id)?.node?.name,
        },
      });
      handleCampaignChange({
        inviteCustomListIds: xor(arrayOfSelectedId, [id]),
      });
    }
  };

  const selectItems: CheckboxItemType[] = [
    { id: 'Favorites', label: 'Favorites', isChecked: inviteFavorites, iconName: 'Heart' },
    ...customListFragment?.edges?.map((node) => ({
      id: node.node.id || '',
      label: node.node.name || '',
      isChecked: !!arrayOfSelectedId?.includes(node.node.id),
    })),
  ];

  const favouriteCreatorsPreview = favouritesFragment?.edges?.map((item) => ({
    id: item?.node?.id || '',
    avatarUrl: getCreatorData(item?.node)?.avatarUrl,
  }));

  const selectedCustomListsCreatorsPreview = customListFragment?.edges?.filter((item) =>
    arrayOfSelectedId?.includes(item?.node?.id || '')
  );

  return (
    <>
      <div className={styles.root}>
        <div>
          <Text
            msg="brief_template.field.creators.custom_lists.title"
            type="md"
            data-test="customLists:text:title"
          />
          <Text
            msg="brief_template.field.creators.custom_lists.subtitle"
            type="label"
            color="grey"
            data-test="customLists:text:subtitle"
          />
        </div>
        <ProgressiveInput
          type={'checkboxSelect'}
          inputProps={{
            bordered: true,
            placeholderMsg: 'brief_template.field.creators.custom_lists.placeholder',
            labelMsg: 'brief_template.field.creators.custom_lists.placeholder',
            className: styles.select,
            items: selectItems,
            handleClick: handleListSelectionChange,
          }}
        />
      </div>
      <div className={styles.previewContainer}>
        {inviteFavorites && (
          <ListPreview
            creators={favouriteCreatorsPreview || []}
            total={favouritesFragment?.totalCount}
            handleRemove={handleListSelectionChange}
            id="Favorites"
            listName={'Favorites'}
          />
        )}
        {selectedCustomListsCreatorsPreview?.map((list) => {
          const customListCreators = list?.node?.creators?.edges?.map((creator) => ({
            id: creator?.node?.id || '',
            avatarUrl: getCreatorData(creator?.node)?.avatarUrl,
          }));
          return (
            <ListPreview
              creators={customListCreators || []}
              total={list?.node?.creators?.totalCount || 0}
              handleRemove={handleListSelectionChange}
              id={list?.node?.id || ''}
              listName={list?.node?.name || ''}
            />
          );
        })}
      </div>
    </>
  );
};

export default CustomLists;
