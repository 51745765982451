/**
 * @generated SignedSource<<ba4a8d0b074d61ee7e67526adc7fa6f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TiktokShop_Collaboration_Status = "CREATED" | "DELETED" | "ENDED" | "EXPIRED" | "LIVE";
export type TikTokShopCollaborationQuery$variables = {
  projectId: string;
};
export type TikTokShopCollaborationQuery$data = {
  readonly project: {
    readonly id: string;
    readonly tiktokShopCollaboration: {
      readonly affiliateProductLink: string | null | undefined;
      readonly comissionRate: number;
      readonly endTime: any | null | undefined;
      readonly status: TiktokShop_Collaboration_Status;
    } | null | undefined;
  } | null | undefined;
};
export type TikTokShopCollaborationQuery = {
  response: TikTokShopCollaborationQuery$data;
  variables: TikTokShopCollaborationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "refresh",
      "value": true
    }
  ],
  "kind": "ScalarField",
  "name": "status",
  "storageKey": "status(refresh:true)"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "comissionRate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "affiliateProductLink",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endTime",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TikTokShopCollaborationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TiktokShop_Collaboration",
            "kind": "LinkedField",
            "name": "tiktokShopCollaboration",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TikTokShopCollaborationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TiktokShop_Collaboration",
            "kind": "LinkedField",
            "name": "tiktokShopCollaboration",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f5a5357347d1a89d4ce9b595bf9b980f",
    "id": null,
    "metadata": {},
    "name": "TikTokShopCollaborationQuery",
    "operationKind": "query",
    "text": "query TikTokShopCollaborationQuery(\n  $projectId: ID!\n) {\n  project(id: $projectId) {\n    id\n    tiktokShopCollaboration {\n      status(refresh: true)\n      comissionRate\n      affiliateProductLink\n      endTime\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1bff067f34f7b3fa3c76b8e7c8a41e75";

export default node;
