import React, { useState, useContext } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';

import styles from './SetPublicationDate.pcss';

import startProjectPublishing from 'Mutations/StartProjectPublishing.Mutation';
import changePublicationDeadline from 'Mutations/ChangePublicationDeadline.Mutation';
import { defineDate } from 'Util/dateCreator';
import Drawer from 'Components/ui/Drawer';
import DatePicker from 'Components/ui/DatePicker/DatePicker';
import Text from 'Components/ui/Text/Text';
import Button from 'Components/ui/Button/Button';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { SetPublicationDateQuery as QueryType } from 'GraphTypes/SetPublicationDateQuery.graphql';

const SetPublicationDateQuery = graphql`
  query SetPublicationDateQuery($id: ID!) {
    project(id: $id) {
      advertiserStage
      deadline {
        publicationDate
      }
    }
  }
`;

interface Props {
  projectId: string;
  callback?: () => void;
}

const SetPublicationDate: React.FC<Props> = (props) => {
  const { projectId, callback } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [publicationDate, setPublicationDate] = useState<Date | null>(null);

  const { closeDrawer, openDrawer } = useContext(DrawerContext);

  const data = useLazyLoadQuery<QueryType>(SetPublicationDateQuery, { id: projectId });
  const advertiserStage = data.project?.advertiserStage;

  const handleCloseModal = () => {
    setLoading(false);
    if (callback) {
      callback();
    }
    closeDrawer(`project-publication-date`);
    openDrawer(`chat-modal`);
  };

  const handleSuccess = () => {
    if (advertiserStage !== 'PUBLISHING_STARTED') {
      startProjectPublishing({ projectId }, handleCloseModal);
    } else {
      handleCloseModal();
    }
  };

  const handleSetDeadlines = () => {
    setLoading(true);
    const newDate = publicationDate ? defineDate(publicationDate) : null;
    changePublicationDeadline({ projectId, publicationDate: newDate }, handleSuccess);
  };

  const handleDateChange = (date: Date | null) => {
    setPublicationDate(date);
  };

  const defaultPublicationDate = data.project?.deadline?.publicationDate as
    | string
    | number
    | null
    | undefined;

  return (
    <Drawer rootKey={`project-publication-date`} className={styles.drawer}>
      <div className={styles.drawerContainer}>
        <div className={styles.content}>
          <Text type="d2" msg="set_publication_date_modal.title" />
          <Text type="md" msg="set_publication_date_modal.descr" className={styles.descr} />
          <DatePicker
            reactDatePickerProps={{
              inline: true,
              onChange: handleDateChange,
              minDate: new Date(),
            }}
          />
        </div>
        <Button
          color="black"
          msg="set_publication_date_modal.btn"
          disabled={!publicationDate}
          loading={loading}
          onClick={handleSetDeadlines}
        />
      </div>
    </Drawer>
  );
};

export default SetPublicationDate;
