import React, { useContext, useMemo, useState } from 'react';
import moment from 'moment';

import styles from './PauseSubscriptionDrawer.pcss';

import Text from 'Components/ui/Text/Text';
import Drawer from 'Components/ui/Drawer/Drawer';
import Button from 'Components/ui/Button/Button';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownGroupItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import { amplitude } from 'Helpers/amplitude';
import Icon from 'Components/ui/Icon/Icon';
import { usePauseSubscriptionMutation } from 'Api/mutations/PauseSubscription.Mutation';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { SubscriptionInterval } from 'GraphTypes/CurrentSubscriptionInfo_organization.graphql';

interface Props {
  subscriptionId: string;
  interval?: SubscriptionInterval | null;
}

const PauseSubscriptionDrawer: React.FC<Props> = (props) => {
  const { subscriptionId, interval } = props;
  const { openDrawer, closeDrawer } = useContext(DrawerContext);
  const [duration, setDuration] = useState<number | null>(null);
  const [pauseSubscription, loading] = usePauseSubscriptionMutation();

  const durationOptions = interval === 'MONTHLY' ? [30] : [30, 60, 90];

  const handleSuccessDrawerOpen = () => {
    openDrawer('success-pause-subscription-drawer');
  };

  const handleSuccessDrawerClose = () => {
    closeDrawer('success-pause-subscription-drawer');
  };

  const handlePauseClick = () => {
    if (!duration) return;
    amplitude.sendEvent<382>({
      id: '382',
      category: 'billing',
      name: 'confirmed_pause_duration',
      param: {
        duration,
      },
    });
    pauseSubscription({
      variables: {
        input: {
          subscriptionId,
          limitDays: duration,
        },
      },
      onCompleted: () => {
        closeDrawer('pause-subscription-drawer');
        handleSuccessDrawerOpen();
        amplitude.sendEvent<383>({
          id: '383',
          category: 'billing',
          name: 'confirmed_pause',
        });
      },
      onError: () => {
        closeDrawer('pause-subscription-drawer');
      },
    });
  };

  const handleCompleteClick = () => {
    closeDrawer('pause-subscription-drawer');
  };

  const endPauseDate = useMemo(() => {
    if (!duration) return 'XXXXX';
    return moment().add(duration, 'd').format('MMMM Do, YYYY');
  }, [duration]);

  return (
    <>
      <Drawer
        rootKey={'pause-subscription-drawer'}
        className={styles.drawerWrapper}
        containerClassName={styles.drawerContainer}
        backdropClassName={styles.drawerBackdrop}
        needCloseButton
        removeWhenClosed
      >
        <div className={styles.root}>
          <div className={styles.formContainer}>
            <Text
              type="d2"
              msg="billing.pause.drawer.title"
              className={styles.title}
              data-test="pauseSubscriptionDrawer:text:title"
            />
            <div className={styles.descriptionText}>
              <Icon name="Calendar-schedule" color="pink" />
              <Text
                type="md"
                msg="billing.pause.drawer.subtitle_1"
                className={styles.subtitle}
                data-test="pauseSubscriptionDrawer:text:subtitle1"
              />
            </div>
            <div className={styles.descriptionText}>
              <Icon name="Blocklist" color="pink" />
              <Text
                type="md"
                msg="billing.pause.drawer.subtitle_2"
                className={styles.subtitle}
                data-test="pauseSubscriptionDrawer:text:subtitle2"
              />
            </div>
            <div className={styles.descriptionText}>
              <Icon name="Campaigns" color="pink" />
              <Text
                type="md"
                msg="billing.pause.drawer.subtitle_3"
                className={styles.subtitle}
                data-test="pauseSubscriptionDrawer:text:subtitle3"
              />
            </div>
            <Dropdown
              value={
                <Text
                  type="md"
                  msg={
                    duration
                      ? 'billing.pause.drawer.select.value'
                      : 'billing.pause.drawer.select.placeholder'
                  }
                  formatValues={{ duration: duration ?? 0 }}
                  data-test="pauseSubscriptionDrawer:text:unknown"
                />
              }
              className={styles.selectorLabel}
            >
              <DropdownGroup className={styles.group}>
                <div className={styles.dropdownList}>
                  {durationOptions?.map((item) => {
                    return (
                      <DropdownGroupItem
                        key={item}
                        handleClick={() => {
                          setDuration(item);
                        }}
                      >
                        <AlterButton
                          msg={'billing.pause.drawer.select.value'}
                          formatValues={{ duration: item }}
                          fluid
                          iconPosition="right"
                          icon={item === duration ? 'Check' : null}
                          data-test="pauseSubscriptionDrawer:alterButton:unknown"
                        />
                      </DropdownGroupItem>
                    );
                  })}
                </div>
              </DropdownGroup>
            </Dropdown>
            <Text
              type="md"
              msg="billing.pause.drawer.date_description"
              className={styles.subtitle}
              data-test="pauseSubscriptionDrawer:text:dateDescription"
            />
          </div>
          <div className={styles.bottomContainer}>
            <div className={styles.buttonContainer}>
              <Button
                color="black"
                fluid
                msg={'billing.pause.drawer.button.pause'}
                onClick={handlePauseClick}
                disabled={duration === null || loading}
                loading={loading}
                data-test="pauseSubscriptionDrawer:button:unknown"
              />
              <Button
                color="white"
                fluid
                msg={'billing.pause.drawer.button.back'}
                onClick={handleCompleteClick}
                data-test="pauseSubscriptionDrawer:button:unknown"
              />
            </div>
          </div>
        </div>
      </Drawer>
      <Drawer
        rootKey={'success-pause-subscription-drawer'}
        className={styles.drawerWrapper}
        containerClassName={styles.drawerContainer}
        backdropClassName={styles.drawerBackdrop}
        needCloseButton
        removeWhenClosed
        onClose={handleSuccessDrawerClose}
      >
        <div className={styles.root}>
          <div className={styles.formContainer}>
            <Text
              type="d2"
              msg="billing.pause_success.drawer.title"
              className={styles.title}
              data-test="pauseSubscriptionDrawer:text:title"
            />
            <Text
              type="md"
              msg="billing.pause_success.drawer.subtitle"
              className={styles.subtitle}
              formatValues={{ to: endPauseDate }}
              data-test="pauseSubscriptionDrawer:text:subtitle"
            />
            <div className={styles.greenContainer}>
              <div className={styles.greenIcon} />
            </div>
          </div>
          <div className={styles.bottomContainer}>
            <Button
              color="black"
              fluid
              msg={'billing.pause_success.drawer.button.account'}
              onClick={handleSuccessDrawerClose}
              data-test="pauseSubscriptionDrawer:button:unknown"
            />
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default PauseSubscriptionDrawer;
