import React from 'react';
import { graphql, useFragment } from 'react-relay';

import styles from './SearchResultAccounts.pcss';

import Text from 'Atoms/Text/Text';
import { SearchResultAccounts_result$key } from 'GraphTypes/SearchResultAccounts_result.graphql';

interface Props {
  result: SearchResultAccounts_result$key;
}

const SearchResultAccounts: React.FC<Props> = (props) => {
  const { result } = props;

  const data = useFragment(
    graphql`
      fragment SearchResultAccounts_result on CreatorSearchResult {
        creator {
          id
          ... on InstagramCreator {
            businessAccountEnabled
            paidSocialEnabled
          }
        }
      }
    `,
    result
  );

  const businessAccountEnabled = data?.creator?.businessAccountEnabled;
  const paidSocialEnabled = data?.creator?.paidSocialEnabled;

  return (
    <div>
      <div className={`${styles.account} ${paidSocialEnabled ? '' : styles.noactive}`}>
        <Text size="sm" color="creamCan" text="FB" data-test="searchResultAccounts:text:fb" />
      </div>
      <div className={`${styles.account} ${businessAccountEnabled ? '' : styles.noactive}`}>
        <Text size="sm" text="BA" className={styles.ba} data-test="searchResultAccounts:text:ba" />
      </div>
    </div>
  );
};

export default SearchResultAccounts;
