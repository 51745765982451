import React, { useState, useCallback } from 'react';
import { graphql, PreloadedQuery, usePaginationFragment, usePreloadedQuery } from 'react-relay';

import { CampaignsQuery } from '../Campaigns';

import styles from './CampaignsList.pcss';
import { getBars } from './utils';
import Campaign from './Campaign/Campaign';
import CampaignActionsModal from './Campaign/CampaignPreviewActions/CampaignActionsModal/CampaignActionsModal';
import CampaignStub from './CampaignStub/CampaignStub';
import CampaignsEmptyState from './CampaignsEmptyState/CampaignsEmptyState';

import Drawer from 'Components/ui/Drawer/Drawer';
import Text from 'Components/ui/Text/Text';
import LoaderHandlerWithHooks from 'Organisms/LoaderHandler/NewLoaderHandler';
import DuplicateCampaign from 'Modal/advertiser/DuplicateCampaign/DuplicateCampaign';
import {
  CampaignsQuery as QueryType,
  CampaignStage,
  CampaignsQuery$data,
} from 'GraphTypes/CampaignsQuery.graphql';
import { CampaignsList_campaigns$key } from 'GraphTypes/CampaignsList_campaigns.graphql';
import { Campaign_campaign$data } from 'GraphTypes/Campaign_campaign.graphql';

export type Counters = NonNullable<
  NonNullable<CampaignsQuery$data['currentUser']>['organization']
>['counters'];

const OFFSET = 70;
const COUNT = 20;

interface Props {
  brandNames?: string[];
  stages?: CampaignStage[];
  textQuery?: string;
  onlySubscribed?: boolean | null;
  queryReference: PreloadedQuery<QueryType>;
}

export const Bars = (props: { stage?: CampaignStage; counters?: Counters | null }) => {
  const { stage, counters } = props;
  const bars = getBars(stage, counters);

  const createBar = (data: { msg: string; width: number; mark: boolean }) => {
    const { msg, width, mark } = data;
    return (
      <div key={msg} className={styles.bar} style={{ width: `${width}px` }}>
        {mark && <div className={styles.mark} />}
        <Text type="sm" msg={msg} data-test="campaignsList:text:unknown" />
      </div>
    );
  };

  return (
    <div className={styles.bars}>
      {bars.map((bar) => {
        return createBar(bar);
      })}
    </div>
  );
};

const CampaignsList: React.FC<Props> = (props) => {
  const { queryReference, stages, textQuery, onlySubscribed, brandNames } = props;

  const [activeCampaign, setActiveCampaign] = useState<Campaign_campaign$data | null>(null);
  const [activeCampaignId, setActiveCampaignId] = useState('');

  const queryData = usePreloadedQuery<QueryType>(CampaignsQuery, queryReference);

  const fragmentData = usePaginationFragment<QueryType, CampaignsList_campaigns$key>(
    graphql`
      fragment CampaignsList_campaigns on Query
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 18 }
        cursor: { type: "String" }
        brandNames: { type: "[String!]" }
        textQuery: { type: String }
        onlySubscribed: { type: Boolean }
        stages: { type: "[CampaignStage!]" }
      )
      @refetchable(queryName: "NewCampaignsPaginationList") {
        campaigns(
          first: $count
          after: $cursor
          brandNames: $brandNames
          stages: $stages
          textQuery: $textQuery
          onlySubscribed: $onlySubscribed
        )
          @connection(
            key: "CampaignList_campaigns"
            filters: ["stages", "brandNames", "textQuery", "onlySubscribed"]
          ) {
          edges {
            node {
              id
              ...Campaign_campaign
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
    queryData
  );

  const isAdmin = !!queryData.currentUser?.admin;
  const organizationId = queryData.currentUser?.organization?.id;
  const canBrowseCreators = queryData.currentUser?.organization?.subscription?.canBrowseCreators;
  const counters = queryData.currentUser?.organization?.counters;

  const items = Array.from(fragmentData.data.campaigns?.edges || []);

  const closeCampaignMenu = useCallback(() => {
    setActiveCampaign(null);
  }, []);

  const handleActiveCampaignChange = (campaignData: Campaign_campaign$data | null) => {
    if (!campaignData) return;

    setActiveCampaign(campaignData);
  };

  const stage = stages?.length ? stages[0] : undefined;
  const notEmpty = items.length !== 0;
  const withFilters = textQuery || onlySubscribed === true || Number(brandNames?.length) > 0;

  if (!notEmpty && stage) {
    return <CampaignsEmptyState stage={stage} withFilters={!!withFilters} />;
  }

  const handleCampaignMenu = (data: Campaign_campaign$data) => {
    setActiveCampaignId(data.id);
    setActiveCampaign(data);
  };

  return (
    <div className={styles.container}>
      <Bars stage={stage} counters={counters} />
      <LoaderHandlerWithHooks
        relay={fragmentData}
        offset={OFFSET}
        count={COUNT}
        items={items}
        preloader={<CampaignStub stage={stage} />}
      >
        {items.map((item) => {
          if (!item?.node?.id) return null;
          return (
            <Campaign
              key={item?.node?.id}
              isAdmin={isAdmin}
              organizationId={organizationId}
              campaign={item.node}
              handleCampaignMenu={handleCampaignMenu}
            />
          );
        })}
        <Drawer
          rootKey={'campaign-menu'}
          opened={!!activeCampaign}
          onClose={closeCampaignMenu}
          needCloseButton={false}
        >
          {activeCampaign && (
            <CampaignActionsModal
              canBrowseCreators={!!canBrowseCreators}
              campaign={activeCampaign}
              setActiveCampaign={handleActiveCampaignChange}
              handleClose={closeCampaignMenu}
              isAdmin={!!isAdmin}
            />
          )}
        </Drawer>
        <DuplicateCampaign campaignId={activeCampaignId} />
      </LoaderHandlerWithHooks>
    </div>
  );
};

export default CampaignsList;
