import React from 'react';
import { graphql, useFragment } from 'react-relay';

import styles from './CreatorContacts.pcss';

import { copyText } from 'Util/copyText';
import Text from 'Atoms/Text/Text';
import Button from 'Atoms/Button/Button';
import { CreatorContacts_project$key } from 'GraphTypes/CreatorContacts_project.graphql';

interface Props {
  project: CreatorContacts_project$key;
}

const CreatorContacts = (props: Props) => {
  const { project } = props;

  const data = useFragment(
    graphql`
      fragment CreatorContacts_project on Project {
        id
        creator {
          profile {
            contactEmail
          }
          ownership {
            owner {
              email
              contractorProfile {
                phoneNumber
              }
            }
          }
        }
      }
    `,
    project
  );

  if (!data) return null;

  const email = data.creator?.profile?.contactEmail;
  const ownerEmail = data.creator?.ownership?.owner?.email;
  const ownerPhone = data.creator?.ownership?.owner?.contractorProfile?.phoneNumber;

  const emailValue = email || ownerEmail;

  const getElement = (value: string, title: string) => {
    const handleCopy = () => {
      copyText(value);
    };

    return (
      <div className={styles.item}>
        <div className={styles.textContainer}>
          <Text weight="500" text={value} overflow data-test="creatorContacts:text:unknown" />
          <Text size="sm" color="grayDog" msg={title} data-test="creatorContacts:text:unknown" />
        </div>
        <Button
          color="normal"
          msg="general.copy"
          onClick={handleCopy}
          data-test="creatorContacts:button:copy"
        />
      </div>
    );
  };

  const emailEl = emailValue ? getElement(emailValue, 'field.email') : null;
  const phoneEl = ownerPhone ? getElement(ownerPhone, 'placeholder.phone') : null;

  if (!emailEl && !phoneEl) {
    return null;
  }

  return (
    <div className={styles.root}>
      {emailEl}
      {phoneEl}
    </div>
  );
};

export default CreatorContacts;
