import React, { PropsWithChildren } from 'react';

import styles from './CheckboxWrapper.pcss';

import Checkbox from 'Atoms/Checkbox/Checkbox';

interface Props {
  isActive?: boolean;
  onChange: (active: boolean) => void;
}

const CheckboxWrapper: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, isActive, onChange } = props;

  return (
    <div className={styles.root}>
      <Checkbox
        active={isActive}
        onChange={onChange}
        data-test="checkboxWrapper:checkbox:unknown"
      />
      <div className={styles.content}>
        {children &&
          React.cloneElement(children as React.ReactElement<any>, { isContentVisible: isActive })}
      </div>
    </div>
  );
};

export default CheckboxWrapper;
