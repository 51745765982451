import React, { useCallback, useContext } from 'react';
import classnames from 'classnames';
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from 'relay-runtime';

import styles from './CampaignActionsModal.pcss';
import CreatorsReportForAdmin from './CreatorsReportForAdmin/CreatorsReportForAdmin';
import CreatorsReport from './CreatorsReport/CreatorsReport';
import ShipmentReport from './ShipmentReport/ShipmentReport';

import activateCampaign from 'Mutations/ActivateCampaign.Mutation';
import completeCampaign from 'Mutations/CompleteCampaign.Mutation';
import pauseCampaignActivation from 'Mutations/PauseCampaignActivation.Mutation';
import resumeCampaignActivation from 'Mutations/ResumeCampaignActivation.Mutation';
import { getCampaignBriefForViewingLink } from 'Util/links';
import { modalName } from 'Types/modals';
import Text from 'Components/ui/Text/Text';
import IconNew from 'Components/ui/Icon/Icon';
import TextPreset from 'Components/ui/TextPreset/TextPreset';
import Toggle from 'Components/ui/Toggle/Toggle';
import { amplitude } from 'Helpers/amplitude';
import { CampaignActionsModalQuery as queryType } from 'GraphTypes/CampaignActionsModalQuery.graphql';
import useDashly from 'Hooks/useDashly';
import { Campaign_campaign$data } from 'GraphTypes/Campaign_campaign.graphql';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

type Props = {
  campaign: Campaign_campaign$data;
  isAdmin: boolean;
  isAdminView?: boolean;
  canBrowseCreators: boolean;
  canDownloadShipment?: boolean;
  showBrief?: boolean;
  handleClose: () => void;
  place?: 'campaign' | 'dashboard';
  setActiveCampaign?: (data: Campaign_campaign$data | null) => void;
  onClientViewChange?: (value: boolean) => void;
};

const CampaignActionsModalQuery = graphql`
  query CampaignActionsModalQuery {
    currentUser {
      admin
      organization {
        test
        subscription {
          planId
        }
      }
    }
  }
`;

const CampaignActionsModal: React.FC<Props> = (props) => {
  const {
    campaign,
    handleClose,
    isAdmin,
    isAdminView,
    setActiveCampaign,
    canBrowseCreators,
    showBrief = true,
    place,
    canDownloadShipment,
    onClientViewChange,
  } = props;

  const { track: trackEvent } = useDashly();
  const { dispatch: modalDispatch } = useContext(ModalContext);

  const { openDrawer } = useContext(DrawerContext);

  const { currentUser } = useLazyLoadQuery<queryType>(CampaignActionsModalQuery, {});
  const isTest = currentUser?.organization?.test;
  const planId = currentUser?.organization?.subscription?.planId;

  const handleResume = useCallback(() => {
    amplitude.sendEvent({
      id: '18',
      category: 'campaign',
      name: 'response_action',
      param: { actionName: 'continue' },
    });
    resumeCampaignActivation({ campaignId });
    if (setActiveCampaign && campaign.activation) {
      setActiveCampaign({ ...campaign, activation: { ...campaign.activation, paused: false } });
    }
  }, []);

  const handleStop = useCallback(() => {
    trackEvent('stop_response_in_campaign');
    amplitude.sendEvent({
      id: '18',
      category: 'campaign',
      name: 'response_action',
      param: { actionName: 'stop' },
    });
    pauseCampaignActivation({ campaignId });
    if (setActiveCampaign && campaign.activation) {
      setActiveCampaign({ ...campaign, activation: { ...campaign.activation, paused: true } });
    }
  }, []);

  if (!campaign) {
    return null;
  }

  const campaignId = campaign.id;
  const {
    briefSubmission,
    completion,
    activation,
    completionRequest,
    deletable,
    canBeCompleted,
    organization,
    canDuplicate,
  } = campaign;

  const hasAcceptance = !!briefSubmission?.acceptance?.id;

  const isPaused = activation?.paused;
  const canSendBrief = canBrowseCreators && hasAcceptance && !isPaused && !completion;

  const handleDuplicateCampaign = () => {
    const isDraft = (!briefSubmission || !briefSubmission.acceptance) && !completion;
    amplitude.sendEvent({
      id: '255',
      category: 'campaign',
      name: 'actions_dashboard_duplicate',
      param: {
        admin: isAdmin,
        campaign_id: campaignId,
        organization_id: organization?.id,
        isTestOrganization: isTest,
        planId,
      },
    });
    openDrawer('duplicate-campaign');
    handleClose();
  };

  const handleCloseCampaign = () => {
    pauseCampaignActivation({ campaignId });
    completeCampaign({ campaignId }, handleCampaigCompletionDone);
    amplitude.sendEvent({
      id: '48',
      category: 'campaign_actions',
      name: 'dashboard_close_campaign',
      param: { isAdmin },
    });
    handleClose();
  };

  const handleDeleteClick = () => {
    handleClose();
    modalDispatch({
      type: ModalTypes.SET_MODAL,
      payload: {
        name: modalName.DELETE_CAMPAIGN,
        attach: { campaignId, organizationId: organization?.id },
      },
    });
  };

  const handleCampaigCompletionDone = () => {
    modalDispatch({
      type: ModalTypes.TOGGLE_MODAL,
    });
  };

  const handleReceivingApplications = () => {
    if (isPaused) {
      handleResume();
    } else {
      handleStop();
    }
  };

  const handleClientViewClick = () => {
    amplitude.sendEvent<495>({
      id: '495',
      category: 'campaign',
      name: 'client_view_click',
      param: { campaign_id: campaignId },
    });
    onClientViewChange?.(!isAdminView);
  };

  const handleOpenBriefClick = () => {
    const path = getCampaignBriefForViewingLink({ campaignId });
    window.open(path);
    handleClose();
  };

  const handleSendBriefClick = () => {
    activateCampaign({ campaignId });
    handleClose();
  };
  const clickableItemClassList = classnames(styles.menuItem, styles.pointer);

  return (
    <div className={styles.drawerContainer}>
      <div className={styles.drawerClosable} onClick={handleClose} />
      <div>
        <div className={styles.drawerHeader}>
          <Text
            type={'h2'}
            msg={'campaign.drawer.title'}
            data-test="campaignActionsModal:text:unknown"
          />
          <IconNew name={'Close-small'} onClick={handleClose} />
        </div>
        <div className={styles.drawerMenu}>
          {place === 'campaign' && isAdmin && (
            <TextPreset
              className={styles.menuItem}
              header={
                <Text
                  className={styles.title}
                  msg="campaign.drawer.client_view"
                  data-test="campaignActionsModal:text:title"
                />
              }
              rightComponent={<Toggle on={!isAdminView} onChange={handleClientViewClick} />}
            />
          )}
          {activation?.id && !completion && !completionRequest ? (
            <TextPreset
              className={styles.menuItem}
              header={
                <Text
                  className={styles.title}
                  msg={'campaign.drawer.recieve'}
                  data-test="campaignActionsModal:text:title"
                />
              }
              rightComponent={<Toggle on={!isPaused} onChange={handleReceivingApplications} />}
            />
          ) : null}
          {canDuplicate && (
            <TextPreset
              className={clickableItemClassList}
              header={
                <Text
                  className={styles.title}
                  msg={'campaign.drawer.duplicate'}
                  data-test="campaignActionsModal:text:title"
                />
              }
              handleClick={handleDuplicateCampaign}
              rightComponent={<IconNew name={'Assets'} />}
            />
          )}
          {hasAcceptance && (
            <div className={styles.menuItemWrap}>
              {showBrief && (
                <TextPreset
                  className={clickableItemClassList}
                  header={
                    <Text
                      className={styles.title}
                      msg={'campaign.drawer.open_brief'}
                      data-test="campaignActionsModal:text:title"
                    />
                  }
                  handleClick={handleOpenBriefClick}
                  rightComponent={<IconNew name="Brief" />}
                />
              )}
              {canSendBrief && (
                <TextPreset
                  className={clickableItemClassList}
                  header={
                    <Text
                      className={styles.title}
                      msg="campaign.dropdown_send_brief"
                      data-test="campaignActionsModal:text:title"
                    />
                  }
                  handleClick={handleSendBriefClick}
                  rightComponent={<IconNew name="Group-users" />}
                />
              )}
            </div>
          )}
          <div
            className={classnames({
              [styles.buttonsRowContainer]: canDownloadShipment,
            })}
          >
            <CreatorsReport campaignId={campaignId} className={clickableItemClassList} />
            {canDownloadShipment && (
              <ShipmentReport campaignId={campaignId} className={clickableItemClassList} />
            )}
          </div>
          {isAdmin && hasAcceptance && (
            <CreatorsReportForAdmin campaignId={campaignId} className={clickableItemClassList} />
          )}
          {isAdmin || (hasAcceptance && !completion) ? (
            <TextPreset
              className={classnames(clickableItemClassList, {
                [styles.disabled]: !canBeCompleted && !isAdmin,
              })}
              header={
                <Text
                  className={styles.title}
                  msg={'campaign.drawer.close'}
                  data-test="campaignActionsModal:text:title"
                />
              }
              subtitle={
                <Text
                  className={styles.subtitle}
                  msg={'campaign.drawer.close.subtitle'}
                  data-test="campaignActionsModal:text:subtitle"
                />
              }
              handleClick={canBeCompleted || isAdmin ? handleCloseCampaign : undefined}
              rightComponent={<IconNew name={'Campaigns'} />}
            />
          ) : null}
          {deletable ? (
            <TextPreset
              className={clickableItemClassList}
              header={
                <Text
                  className={styles.title}
                  msg={'campaign.drawer.delete'}
                  data-test="campaignActionsModal:text:title"
                />
              }
              handleClick={handleDeleteClick}
              rightComponent={<IconNew name={'Trash-delele'} />}
            />
          ) : null}
        </div>
      </div>
      <div className={styles.drawerClosable} onClick={handleClose} />
    </div>
  );
};

export default CampaignActionsModal;
