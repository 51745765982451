import React, { useEffect, useContext, useState } from 'react';
import { graphql, useFragment } from 'react-relay';

import styles from './CampaignCover.pcss';

import ls from 'Util/localStorage';
import { amplitude } from 'Helpers/amplitude';
import { ImageData } from 'Api/uploadFile';
import { useCampaignActions } from 'Hooks/useCampaignActions';
import UpdateProductImage from 'Modal/advertiser/UpdateProductImage/UpdateProductImage';
import ImageCropper from 'Modal/advertiser/ImageCropper/ImageCropper';
import Image from 'Components/ui/Image/Image';
import { CampaignCover_campaign$key } from 'GraphTypes/CampaignCover_campaign.graphql';
import Icon from 'Components/ui/Icon/Icon';
import Text from 'Components/ui/Text/Text';
import TextButton from 'Components/ui/TextButton/TextButton';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import FileUploader from 'Atoms/FileUploader/FileUploader';

interface Props {
  campaign: CampaignCover_campaign$key;
}

const CampaignCover: React.FC<Props> = (props) => {
  const { campaign } = props;

  const [localfileSrc, setLocalfileSrc] = useState('');

  const { openDrawer, closeDrawer } = useContext(DrawerContext);

  const { addNewCampaignCover } = useCampaignActions();

  const data = useFragment(
    graphql`
      fragment CampaignCover_campaign on Campaign {
        id
        completion {
          id
        }
        activation {
          id
        }
        cover {
          width
          height
          thumbnailUrl
          ... on Image {
            transformations {
              collageThumbnailUrl
            }
          }
        }
      }
    `,
    campaign
  );

  const campaignId = data.id;
  const cover = data.cover;
  const completion = data.completion?.id;
  const activation = data.activation?.id;
  const avaSrc = cover?.transformations?.collageThumbnailUrl;
  const thumbnailUrl = cover?.thumbnailUrl;
  const isSquare = cover?.width === cover?.height;
  const shouldUpdate = avaSrc && !isSquare && !completion && activation;

  const updateInfoShown = ls.get('product_image_update_warning_campaign');
  const updateInfoTooltipShown = ls.get(`${campaignId}_product_image_update_tooltip`);

  const [isTooltipVisible, setTooltipVisible] = useState(
    !updateInfoTooltipShown && updateInfoShown
  );

  useEffect(() => {
    if (isTooltipVisible) {
      ls.set(`${campaignId}_product_image_update_tooltip`, true);
    }
  }, [isTooltipVisible]);

  useEffect(() => {
    if (shouldUpdate && !updateInfoShown) {
      openDrawer('update-product-image');
    }
  }, []);

  const handleLocalFileUpdate = (src: string) => {
    setLocalfileSrc(src);
  };

  const handleCropClose = () => {
    closeDrawer('image-cropper');
  };

  const handleFileUpdate = (imageData: ImageData) => {
    addNewCampaignCover({ imageData, campaignId, onSuccess: handleCropClose });
  };

  const handleUploadForm = (files: FileList | null) => {
    if (!files || files.length === 0) return;
    const src = URL.createObjectURL(files[0]);
    setLocalfileSrc(src);
    openDrawer('image-cropper');
  };

  const handleCloseTooltip = () => {
    setTooltipVisible(false);
    amplitude.sendEvent<484>({
      id: '484',
      category: 'update_product_image_tooltip',
      name: 'close',
      param: {
        campaign_id: campaignId,
      },
    });
  };

  const handleUpdateImageDrawerClose = () => {
    setTooltipVisible(true);
    amplitude.sendEvent<485>({
      id: '485',
      category: 'update_product_image_tooltip',
      name: 'show',
      param: {
        campaign_id: campaignId,
      },
    });
  };

  return (
    <div className={styles.root}>
      <Tooltip
        showByDefault={isTooltipVisible}
        id={shouldUpdate && updateInfoShown && isTooltipVisible ? 'cover_update' : undefined}
        place="right"
        clickable
        customTooltip={
          <div className={styles.tooltip}>
            <Text msg="campaign.cover_update.tooltip" data-test="campaignCover:text:tooltip" />
            <Icon
              name={'Close-small'}
              size={16}
              onClick={handleCloseTooltip}
              className={styles.closeTooltip}
            />
          </div>
        }
      >
        {avaSrc && !isSquare && (
          <div className={styles.oldSize}>
            <img src={thumbnailUrl} />
          </div>
        )}
        {avaSrc && isSquare && <Image size={80} src={avaSrc} className={styles.logo} />}
        {shouldUpdate && (
          <div className={styles.upload}>
            <TextButton
              color="pink"
              icon="Edit"
              className={styles.edit}
              data-test="campaignCover:textButton:edit"
            />
            <FileUploader
              typeList=".png, .jpg, .jpeg"
              onChange={handleUploadForm}
              className={styles.uploader}
            />
          </div>
        )}
      </Tooltip>
      <UpdateProductImage
        campaignId={campaignId}
        onImageUploaded={handleLocalFileUpdate}
        place="campaign"
        onClose={handleUpdateImageDrawerClose}
      />
      <ImageCropper localfileSrc={localfileSrc} onImageChange={handleFileUpdate} />
    </div>
  );
};

export default CampaignCover;
