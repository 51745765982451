import React from 'react';
import { graphql, useFragment } from 'react-relay';

import styles from './PaidSocialError.css';

import { createDateWithTime } from 'Util/dateCreator';
import Text from 'Atoms/Text/Text';
import { PaidSocialError_creator$key } from 'GraphTypes/PaidSocialError_creator.graphql';

interface Props {
  creator: PaidSocialError_creator$key;
}

const PaidSocialError: React.FC<Props> = (props) => {
  const { creator } = props;
  const data = useFragment(
    graphql`
      fragment PaidSocialError_creator on Creator {
        ownership {
          owner {
            email
          }
        }
        ... on InstagramCreator {
          latestPaidSocialAuthorizationError {
            createdAt
            detail
            title
          }
          paidSocialEnabled
          user {
            fullName
          }
        }
      }
    `,
    creator
  );
  const latestPaidSocialAuthorizationError = data.latestPaidSocialAuthorizationError;
  const ownership = data.ownership;
  const paidSocialEnabled = data.paidSocialEnabled;

  if (!latestPaidSocialAuthorizationError || !ownership || paidSocialEnabled) return null;

  const { createdAt, title, detail } = latestPaidSocialAuthorizationError;
  const fullName = data.user?.fullName;
  const email = ownership.owner.email;

  const date = `(${createDateWithTime(createdAt)})`;

  return (
    <div className={styles.root}>
      <Text
        weight="500"
        transform="uppercase"
        msg="creator.ps_info"
        data-test="paidSocialError:text:psInfo"
      />
      <div className={styles.container}>
        <div className={styles.row}>
          <div>
            <div className={styles.dateWrap}>
              <Text color="red" msg="creator.ps_failed" data-test="paidSocialError:text:psFailed" />
              <Text text={date} className={styles.date} data-test="paidSocialError:text:date" />
            </div>
            <Text text={title} color="grayDog" data-test="paidSocialError:text:unknown" />
          </div>
          <div className={styles.user}>
            <Text text={fullName} data-test="paidSocialError:text:unknown" />
            <Text text={email} color="grayDog" data-test="paidSocialError:text:unknown" />
          </div>
        </div>
      </div>
      <div className={styles.detail}>
        <Text msg="creator.fb_pages" color="grayDog" data-test="paidSocialError:text:fbPages" />
        <Text text={detail} data-test="paidSocialError:text:unknown" />
      </div>
    </div>
  );
};

export default PaidSocialError;
