import numeral from 'numeral';

import { getLocale } from 'Util/localizationData';
import {
  RUB,
  USD,
  CAD,
  MIN_AMOUNT_RU,
  MAX_AMOUNT_RU,
  MIN_AMOUNT_EN,
  MAX_AMOUNT_EN,
} from 'Constants/general';

numeral.register('locale', 'ru', {
  delimiters: {
    thousands: ' ',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  currency: {
    symbol: '₽',
  },
});

const locale = getLocale();
numeral.locale(locale);

export const createSum = (num, currency, place = 0, format = '') => {
  if (num === undefined || num === null) return null;

  const locale = getLocale();
  numeral.locale(locale);

  currency = currency?.toUpperCase();
  const isRub = currency === RUB;
  const isCad = currency === CAD;

  const isNegative = num < 0;
  const numFormatted = numeral(Math.abs(num)).format(format); // Math.abs to make number positive

  if ([RUB, USD, CAD].includes(currency)) {
    let resultNumber;

    const prefix = !isRub ? '$' : place === 1 ? '₽' : '';
    const postfix = isCad ? ' CAD' : isRub && place === 0 ? ' ₽' : '';

    resultNumber = prefix + numFormatted + postfix;

    if (isNegative) resultNumber = '-' + resultNumber;

    return resultNumber;
  }

  return isNegative ? '-' + numFormatted : numFormatted;
};

export const formatCurrency = (num, currency) => {
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency?.toUpperCase(),
  }).format(num);
};

export const getMinSum = (currency) => {
  return currency === RUB ? MIN_AMOUNT_RU : MIN_AMOUNT_EN;
};

export const getMaxSum = (currency) => {
  return currency === RUB ? MAX_AMOUNT_RU : MAX_AMOUNT_EN;
};

export const createMinSum = (currency) => {
  const amount = getMinSum(currency);

  return createSum(amount, currency);
};

export const createMaxSum = (currency) => {
  const amount = getMaxSum(currency);

  return createSum(amount, currency);
};

export const createShortNumber = (num, limit = 100000) => {
  if (num === undefined || num === null) return null;
  const newNum = numeral(num);

  return num < limit ? newNum.format('0,0') : newNum.format('0.0a');
};

export const createNumber = (num, format) => {
  return numeral(num).format(format || '0,0.[00]');
};

export const createNumberWithDecimal = (num) => {
  return numeral(num).format('0,0.[000000000]');
};

export const createPercent = (num, format) => {
  if (!num) return '—';
  const formatValue = format || '0,0.[00]';
  const updatedNum = num * 100;
  const newNum = updatedNum > 0 ? numeral(updatedNum).format(formatValue) : 0;

  return `${newNum}%`;
};

export const formatValue = (data) => {
  const { format, value, currency } = data;
  switch (format) {
    case 'number':
      return createNumber(value);
    case 'shortNumber':
      return createShortNumber(value);
    case 'currency':
      return createSum(value, currency, 0, '0,0.[00]');
    case 'simplePercent':
      return `${value}%`;
    case 'percent':
      return createPercent(value);
    case 'list':
      return value.join(', ');
    default:
      return value;
  }
};

export const createFileSize = (size) => {
  if (size > 1e6) {
    return `${(size / 1048576).toFixed(1) / 1} mb`;
  }

  return `${(size / 1024).toFixed(1) / 1} kb`;
};

export const createBytesSize = (size) => {
  return numeral(size).format('0 b');
};
