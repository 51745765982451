import React, { FC } from 'react';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay';
import { GraphQLTaggedNode } from 'relay-runtime';

import styles from './OutreachTable.pcss';
import OutreachBatch from './OutreachBatch/OutreachBatch';
import OutreachSequenceList from './OutreachSequenceList/OutreachSequenceList';

import Text from 'Components/ui/Text/Text';
import { OutreachDashboardBatchesQuery } from 'GraphTypes/OutreachDashboardBatchesQuery.graphql';
import Accordion from 'Components/ui/Accordion/Accordion';

interface Props {
  campaignId: string;
  queryReference: PreloadedQuery<OutreachDashboardBatchesQuery, Record<string, unknown>>;
  query: GraphQLTaggedNode;
}

const OutreachTable: FC<Props> = ({ campaignId, queryReference, query }) => {
  const data = usePreloadedQuery(query, queryReference);

  const batches = data?.campaign?.outreachBatches;

  return (
    <>
      <div className={styles.header}>
        <Text type="md" msg="campaign.outreach.column_title.outreaches" color="grey" />
        <Text type="md" msg="campaign.outreach.column_title.reached" color="grey" />
        <Text type="md" msg="campaign.outreach.column_title.clicked" color="grey" />
        <Text type="md" msg="campaign.outreach.column_title.replied" color="grey" />
        <Text type="md" msg="campaign.outreach.column_title.applied" color="grey" />
        <Text type="md" msg="campaign.outreach.column_title.hired" color="grey" />
        <Text type="md" msg="campaign.outreach.column_title.status" color="grey" />
        <Text type="md" msg="campaign.outreach.column_title.actions" color="grey" />
      </div>

      <Accordion
        withoutSeperaton
        className={styles.batchList}
        titleClassName={styles.batch}
        items={(batches?.edges || []).map((edge) => ({
          arrowPosition: 'left',
          title: <OutreachBatch key={edge?.node?.id} batch={edge?.node} campaignId={campaignId} />,
          content: <OutreachSequenceList batch={edge?.node} />,
        }))}
      />
    </>
  );
};

export default OutreachTable;
