/**
 * @generated SignedSource<<c006236b0c9d72ac4e2b63b0db712903>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AdvertiserProjectStage = "ACCEPTED" | "ACCEPTED_AND_NOT_LAUNCHED" | "ACCEPTED_BY_UNREVIEWED_CREATOR" | "COMPLETED" | "CONTENT_SUBMITTED" | "LAUNCHED" | "PRODUCT_SHIPMENT_COMPLETED" | "PRODUCT_SHIPMENT_STARTED" | "PUBLISHING_STARTED" | "READ" | "REJECTED" | "UNREAD" | "UNSENT";
export type ProjectSource = "INVITE_SUGGESTION" | "MARKETPLACE" | "OUTREACH";
export type SendOfferInput = {
  campaignId: string;
  clientMutationId?: string | null | undefined;
  creatorId: string;
  id?: string | null | undefined;
  outreach?: boolean | null | undefined;
  source?: ProjectSource | null | undefined;
};
export type SendOfferMutation$variables = {
  input: SendOfferInput;
};
export type SendOfferMutation$data = {
  readonly sendOffer: {
    readonly message?: string;
    readonly project?: {
      readonly advertiserStage: AdvertiserProjectStage | null | undefined;
      readonly campaign: {
        readonly maxInvitationCount: number;
        readonly usedInvitationCount: number;
      };
      readonly offer: {
        readonly id: string;
      } | null | undefined;
    };
  } | null | undefined;
};
export type SendOfferMutation = {
  response: SendOfferMutation$data;
  variables: SendOfferMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "advertiserStage",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Offer",
  "kind": "LinkedField",
  "name": "offer",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "maxInvitationCount",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "usedInvitationCount",
  "storageKey": null
},
v7 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "NotFoundError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SendOfferMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "sendOffer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Project",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Campaign",
                    "kind": "LinkedField",
                    "name": "campaign",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "SendOfferPayload",
            "abstractKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SendOfferMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "sendOffer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Project",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Campaign",
                    "kind": "LinkedField",
                    "name": "campaign",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "SendOfferPayload",
            "abstractKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "891797d08a94b35617f98fca647a6d05",
    "id": null,
    "metadata": {},
    "name": "SendOfferMutation",
    "operationKind": "mutation",
    "text": "mutation SendOfferMutation(\n  $input: SendOfferInput!\n) {\n  sendOffer(input: $input) {\n    __typename\n    ... on SendOfferPayload {\n      project {\n        advertiserStage\n        offer {\n          id\n        }\n        campaign {\n          maxInvitationCount\n          usedInvitationCount\n          id\n        }\n        id\n      }\n    }\n    ... on NotFoundError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f914ea7d411dcf69c573d42f62afd6db";

export default node;
