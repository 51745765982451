import React, { PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

import {
  InviteOutreachCreatorsContextType,
  InviteOutreachCreatorsContext,
  InviteOutreachTemplateData,
  InviteOutreachTemplateItemData,
  InviteOutreachCreatorsError,
  InviteOutreachCreatorsSource,
  InviteOutreachCreatorsSelectedLists,
  SequenceStep,
  InviteOutreachCampaignData,
  InviteOutreachDailyEmailLimitData,
} from './InviteOutreachCreatorsContext';
import CreatorsCountNotification from './CreatorsCountNotification/CreatorsCountNotification';

import { useCreateOutreachBatchMutation } from 'Api/mutations/CreateOutreachBatch.Mutation';
import { OutreachTransport } from 'GraphTypes/CreateOutreachBatchMutation.graphql';
import {
  Locale,
  OutreachStepBlueprint_ProjectEventType,
  OutreachStepBlueprintInput,
} from 'GraphTypes/CreateOutreachSequenceBlueprintMutation.graphql';
import { useUpdateOutreachTemplateMutation } from 'Api/mutations/UpdateOutreachTemplate.Mutation';
import ErrorHandler from 'Util/errorHandler';
import { NotificationsContext } from 'Containers/Notifications/Notifications.Context';
import { useSetGmailSendingLimitsMutation } from 'Api/mutations/SetGmailSendingLimits.Mutation';

type Props = {
  handleUploadCsv: () => void;
};

const defaultDate = new Date();
defaultDate.setHours(12);
export const sequenceDefaultEmptyStep = {
  delayInDays: null,
  projectEventType: 'MESSAGE' as OutreachStepBlueprint_ProjectEventType,
  startsAtHour: defaultDate,
  validTemplate: false,
  template: {
    id: uuid(),
    htmlBody: '',
    subject: '',
    locale: 'EN' as Locale,
  },
};

export const MIN_SUGGESTED_CREATORS_COUNT = 2000;
export const MAX_SUGGESTED_CREATORS_COUNT = 10000;
export const DEFAULT_DAILY_EMAIL_LIMIT = 2000;

const InviteOutreachCreatorsProvider: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, handleUploadCsv } = props;
  const [createOutreachBatch, createOutreachBatchLoading] = useCreateOutreachBatchMutation();
  const [updateOutreachTemplate, updatingTemplateLoading] = useUpdateOutreachTemplateMutation();
  const [setGmailSendingLimits] = useSetGmailSendingLimitsMutation();

  const { showNotification, hideNotification } = useContext(NotificationsContext);

  const [source, setSource] = useState<InviteOutreachCreatorsSource | null>(
    InviteOutreachCreatorsSource.LISTS
  );
  const [locale] = useState('EN');
  const [isSuggestedCreatorsEnabled, setSuggestedCreatorsStatus] = useState(false);
  const [campaignData, setCampaignData] = useState<InviteOutreachCampaignData>({
    platform: 'INSTAGRAM',
    useCase: null,
    suggestedCreatorsExists: false,
  });
  const [selectedLists, setSelectedLists] = useState<InviteOutreachCreatorsSelectedLists>([]);
  const [templateData, setTemplateData] = useState<InviteOutreachTemplateData>({
    id: '',
    name: '',
  });
  const [sequenceId, setSequenceId] = useState<string | null>(null);
  const [selectedPreset, setSelectedPreset] = useState<string | null>(null);
  const [templatePreview, setTemplatePreview] = useState<InviteOutreachTemplateItemData | null>(
    null
  );
  const [isTemplateChanged, setTemplateChangedStatus] = useState(false);
  const [transport, setTransportProvider] = useState<OutreachTransport>('GMAIL');
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState<InviteOutreachCreatorsError | null>(null);

  const [senderName, setSenderName] = useState('');
  const [senderActiveAuth, setSenderActiveAuth] = useState('');
  const [senderEmail, setSenderEmail] = useState('');
  const [isTemplateCreating, setCreatingTemplateStatus] = useState(false);
  const [sequenceList, setSequenceList] = useState<SequenceStep[]>([sequenceDefaultEmptyStep]);
  const [activeStep, setActiveStep] = useState(0);
  const [stepsWithError, setStepsWithErrorList] = useState<number[]>([]);
  const [timezone, setTimezone] = useState<number>((defaultDate.getTimezoneOffset() / 60) * -1);
  const [availableNames, setAvailableNameList] = useState<string[]>([]);
  const [emailDailyLimit, setEmailDailyLimit] = useState<InviteOutreachDailyEmailLimitData>({
    changed: false,
    value: DEFAULT_DAILY_EMAIL_LIMIT,
  });

  const selectedCreatorsCount = useRef(0);

  useEffect(() => {
    if (error) {
      showNotification({
        id: `sequence-error-${error}`,
        text: `invite_outreach_creators_modal.title.error.${error}`,
        position: 'center',
      });
    }
  }, [error]);

  useEffect(() => {
    const invalidSteps = checkStepsValidation();
    setStepsWithErrorList(invalidSteps.length > 0 ? invalidSteps : []);
  }, [sequenceList]);

  useEffect(() => {
    selectedCreatorsCount.current = selectedLists.reduce(
      (creators, list) => (creators += Number(list.count)),
      0
    );
    showCounterNotification(selectedCreatorsCount.current);
  }, [selectedLists.length, isSuggestedCreatorsEnabled]);

  useEffect(() => {
    if (campaignData?.suggestedCreatorsExists && !isSuggestedCreatorsEnabled) {
      setSuggestedCreatorsStatus(true);
    }
  }, [campaignData?.suggestedCreatorsExists]);

  useEffect(() => {
    checkAndLoadOutreachData();
  }, []);

  const showCounterNotification = (listCount: number) => {
    if (
      selectedCreatorsCount.current > 0 &&
      selectedCreatorsCount.current < MIN_SUGGESTED_CREATORS_COUNT &&
      !isSuggestedCreatorsEnabled
    ) {
      hideNotification('selected-creators-count');
      showNotification({
        id: 'selected-creators-count',
        position: 'center',
        component: (
          <CreatorsCountNotification
            count={selectedCreatorsCount.current < MIN_SUGGESTED_CREATORS_COUNT ? listCount : 0}
            isSuggestedCreatorsExists={Boolean(campaignData?.suggestedCreatorsExists)}
          />
        ),
      });
    }
  };

  const checkStepsValidation = () => {
    const stepsWithError: number[] = [];
    sequenceList.forEach((step, idx) => {
      if (!step.template?.subject || !step.validTemplate) {
        stepsWithError.push(idx);
      }
    });
    return stepsWithError;
  };

  const handleTemplateClose = () => {
    setTemplatePreview(null);
  };

  const handleImportOutreachBatch = (campaignId: string, sequenceId?: string) => {
    setError(null);
    setProcessing(true);
    if (source === InviteOutreachCreatorsSource.LISTS) {
      createBatch(campaignId, sequenceId);
    }
  };

  const createBatch = (campaignId: string, createdSequenceId?: string) => {
    const selectedListIds = selectedLists.map((item) => item.id);
    const filterredLists = selectedListIds.filter((item) => item !== 'favorites');
    const usedSequenceId = createdSequenceId || sequenceId;
    if (!usedSequenceId || !senderActiveAuth) {
      setError(InviteOutreachCreatorsError.GENERAL_ERROR);
      return;
    }
    if (emailDailyLimit.changed) {
      setGmailSendingLimits({
        variables: {
          input: {
            gmailAuthorizationId: senderActiveAuth,
            dailySendingLimit: emailDailyLimit.value,
          },
        },
      });
    }
    createOutreachBatch({
      variables: {
        input: {
          campaignId,
          customListIds: filterredLists,
          activateAfterCompletion: true,
          sequenceBlueprintId: usedSequenceId,
          transport,
          autofillCreators: isSuggestedCreatorsEnabled,
          ...(transport === 'GMAIL'
            ? {
                gmailAuthorizationId: senderActiveAuth,
                senderEmail,
                senderName,
              }
            : {}),
        },
      },
      onError: (error) => {
        setProcessing(false);
        setError(InviteOutreachCreatorsError.MUTATION_ERROR);
        ErrorHandler.error('createOutreachBatch mutation failed with error', { ...error });
      },
      onCompleted: (response) => {
        if (response.createOutreachBatch?.__typename === 'CreateOutreachBatchPayload') {
          handleUploadCsv();
        } else {
          setProcessing(false);
          setError(InviteOutreachCreatorsError.MUTATION_ERROR);
          ErrorHandler.error('createOutreachBatch mutation failed', { ...response });
        }
      },
    });
  };

  const saveEmailTemplates = (
    sequenceSteps: SequenceStep[],
    callback: (steps: OutreachStepBlueprintInput[]) => void
  ) => {
    const steps: OutreachStepBlueprintInput[] = [];
    sequenceSteps.forEach((step, idx) => {
      const date = new Date(step.startsAtHour);
      const dateWithTimezone =
        step.delayInDays === null
          ? new Date(
              `${date.getFullYear()}-${
                date.getMonth() + 1
              }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} GMT${
                timezone > 0 ? '+' : ''
              }${timezone}00`
            )
          : new Date(
              `${date.getFullYear()}-${
                date.getMonth() + 1
              }-${date.getDate()} ${date.getHours()}:00:00 GMT${
                timezone > 0 ? '+' : ''
              }${timezone}00`
            );
      if (step.template && isTemplateChanged && sequenceId && step.id) {
        updateOutreachTemplate({
          variables: {
            input: {
              id: step.template.id,
              htmlBody: step.template.htmlBody,
              subject: step.template.subject,
              locale: step.template.locale,
            },
          },
          onCompleted: (response) => {
            if (response.updateOutreachTemplate?.__typename === 'UpdateOutreachTemplatePayload') {
              const savedTemplates = steps.push({
                delayInDays: step.delayInDays || 0,
                projectEventType: 'MESSAGE',
                startsAtHour: dateWithTimezone,
                templateId: response.updateOutreachTemplate.outreachTemplate.id,
              });
              if (savedTemplates === sequenceSteps.length) {
                callback(
                  steps.map((step, index) => {
                    return {
                      ...step,
                      projectEventType: (index === 0
                        ? 'OFFER'
                        : 'MESSAGE') as OutreachStepBlueprint_ProjectEventType,
                    };
                  })
                );
              }
            } else {
              ErrorHandler.error('updateOutreachTemplate mutation failed', { ...response });
              setError(InviteOutreachCreatorsError.GENERAL_ERROR);
            }
          },
          onError: (error) => {
            ErrorHandler.error('updateOutreachTemplate mutation failed with error', { ...error });
            setError(InviteOutreachCreatorsError.GENERAL_ERROR);
          },
        });
      } else {
        const savedTemplates = steps.push({
          delayInDays: step.delayInDays || 0,
          projectEventType: (idx === 0
            ? 'OFFER'
            : 'MESSAGE') as OutreachStepBlueprint_ProjectEventType,
          startsAtHour: dateWithTimezone,
          template: {
            htmlBody: step.template?.htmlBody || '',
            subject: step.template?.subject || '',
            locale: step.template?.locale || 'EN',
          },
        });
        if (savedTemplates === sequenceSteps.length) {
          callback(steps);
        }
      }
    });
  };

  const checkFormValidation = () => {
    let firstStepValidation = false;
    const transportValidation =
      transport === 'SENDGRID' || (senderEmail && senderName && transport === 'GMAIL');
    if (source === InviteOutreachCreatorsSource.LISTS) {
      firstStepValidation = selectedLists.length > 0 || isSuggestedCreatorsEnabled;
    }
    const sequenceValidation = !sequenceList.find((sequence) => {
      return !sequence.validTemplate;
    });
    return Boolean(firstStepValidation && transportValidation && locale && sequenceValidation);
  };

  const isFormValid = checkFormValidation();

  const saveOutreachData = () => {
    const serializableData = {
      locale,
      templateData,
      transport,
      senderName,
      senderActiveAuth,
      senderEmail,
      templatePreview,
      isTemplateChanged,
      source,
      selectedLists,
      campaignData,
      sequenceId,
      sequenceList,
      activeStep,
      stepsWithError,
      timezone,
      availableNames,
      isSuggestedCreatorsEnabled,
      selectedPreset,
      emailDailyLimit,
    };
    sessionStorage.setItem('outreachData', JSON.stringify(serializableData));
  };

  const contextValue: InviteOutreachCreatorsContextType = {
    locale,
    templateData,
    transport,
    error,
    senderActiveAuth,
    senderName,
    senderEmail,
    templatePreview,
    isTemplateChanged,
    setTransportProvider,
    handleTemplateClose,
    handleImportOutreachBatch,
    setTemplatePreview,
    setTemplateData,
    setSenderName,
    setSenderActiveAuth,
    setSenderEmail,
    setCreatingTemplateStatus,
    setError,
    setTemplateChangedStatus,
    isFormValid,
    processing,
    isTemplateCreating,
    setSource,
    source,
    setSelectedLists,
    selectedLists,
    setCampaignData,
    sequenceId,
    setSequenceId,
    createOutreachBatchLoading,
    sequenceList,
    setSequenceList,
    activeStep,
    setActiveStep,
    stepsWithError,
    setStepsWithErrorList,
    timezone,
    setTimezone,
    saveEmailTemplates,
    updatingTemplateLoading,
    selectedCreatorsCount: selectedCreatorsCount.current,
    setSuggestedCreatorsStatus,
    isSuggestedCreatorsEnabled,
    setSelectedPreset,
    selectedPreset,
    availableNames,
    setAvailableNameList,
    checkStepsValidation,
    saveOutreachData,
    emailDailyLimit,
    setEmailDailyLimit,
    ...campaignData,
  };

  const checkAndLoadOutreachData = () => {
    const outreachData = sessionStorage.getItem('outreachData');
    if (outreachData) {
      try {
        const data = JSON.parse(outreachData);
        setSenderName(data.senderName);
        setSenderActiveAuth(data.senderActiveAuth);
        setSenderEmail(data.senderEmail);
        setTemplateData(data.templateData);
        setTransportProvider(data.transport);
        setTemplatePreview(data.templatePreview);
        setTemplateChangedStatus(data.isTemplateChanged);
        setSource(data.source);
        setSelectedLists(data.selectedLists);
        setCampaignData(data.campaignData);
        setSequenceId(data.sequenceId);
        setSequenceList(data.sequenceList);
        setActiveStep(data.activeStep);
        setStepsWithErrorList(data.stepsWithError);
        setTimezone(data.timezone);
        setAvailableNameList(data.availableNames);
        setSuggestedCreatorsStatus(data.isSuggestedCreatorsEnabled);
        setSelectedPreset(data.selectedPreset);
        setEmailDailyLimit(data.emailDailyLimit);
      } finally {
        sessionStorage.removeItem('outreachData');
      }
    }
  };

  return (
    <InviteOutreachCreatorsContext.Provider value={contextValue}>
      {children}
    </InviteOutreachCreatorsContext.Provider>
  );
};

export { InviteOutreachCreatorsProvider };
