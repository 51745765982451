import React from 'react';
import { graphql, useFragment } from 'react-relay';

import styles from './ContentCreationStep.pcss';
import ContentCreationAdvertiserSide from './ContentCreationAdvertiserSide/ContentCreationAdvertiserSide';
import ContentCreationContractorSide from './ContentCreationContractorSide/ContentCreationContractorSide';

import Text from 'Atoms/Text/Text';
import { ADVERTISER } from 'Constants/general';
import { UserType } from 'GraphTypes/StepperQuery.graphql';
import { ContentCreationStep_project$key } from 'GraphTypes/ContentCreationStep_project.graphql';

interface Props {
  className?: string;
  isActive: boolean;
  hasShipment?: boolean;
  isPastStep: boolean;
  userType?: UserType;
  project: ContentCreationStep_project$key;
}

const ContentCreationStep: React.FC<Props> = (props) => {
  const { isActive, isPastStep, userType, hasShipment, project } = props;

  const data = useFragment(
    graphql`
      fragment ContentCreationStep_project on Project {
        ...ContentCreationAdvertiserSide_project
        ...ContentCreationContractorSide_project
      }
    `,
    project
  );

  const titleColor = !isActive && !isPastStep ? 'grayDog' : undefined;

  const content =
    userType === ADVERTISER ? (
      <ContentCreationAdvertiserSide hasShipment={Boolean(hasShipment)} project={data} />
    ) : (
      <ContentCreationContractorSide hasShipment={Boolean(hasShipment)} project={data} />
    );

  return (
    <div>
      <Text
        color={titleColor}
        weight="500"
        transform="uppercase"
        msg="project.stepper.content_creation.title"
        data-test="contentCreationStep:text:title"
      />
      {isActive && <div className={styles.content}>{content}</div>}
    </div>
  );
};

export default ContentCreationStep;
