import React, { useEffect } from 'react';
import track from 'react-tracking';
import { useNavigate } from 'react-router-dom';

import { postMessage } from './utils';
import styles from './TiktokShopAuth.pcss';

import Spinner from 'Atoms/Spinner/Spinner';
import { OUTCOME_PAGE_ROUTE, TIKTOK_SHOP_OAUTH_ROUTE } from 'Constants/general';
import { TiktokShopPostMessageTypeEnum } from 'Hooks/useTikTokShopAuth';
import { ButtonHandlerTypeEnum } from 'Page/public/OutcomePage/OutcomePage';

const TiktokShopAuth = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get('code') || '';

    const hasError = !code;

    const sendPostMessage = () => {
      postMessage({
        error: hasError,
        postMessageType: TiktokShopPostMessageTypeEnum.AuthorizeTiktokShop,
        params: {
          code,
        },
      });
    };

    if (hasError) {
      const sp = new URLSearchParams();
      sp.set('status', 'error');
      sp.set('handler', ButtonHandlerTypeEnum.CloseWindow);
      sendPostMessage();
      navigate(`${OUTCOME_PAGE_ROUTE}?${sp.toString()}`);
      return;
    }

    sendPostMessage();
  }, []);

  return (
    <div className={styles.root}>
      <Spinner style={{ margin: '40px auto' }} />
    </div>
  );
};

export default track(
  {
    page: 'tiktok_auth',
  },
  { dispatchOnMount: true }
)(TiktokShopAuth);
