import React, { useContext } from 'react';

import {
  percentDiscountToIntervalMap,
  periodToSubscriptionIntervalMap,
  useApplyOfferDiscount,
} from '../useApplyOfferDiscount';
import { useCancelSubscription } from '../useCancelSubscription';

import styles from './CancelOfferSubscriptionDrawer.pcss';

import Text from 'Components/ui/Text/Text';
import Drawer from 'Components/ui/Drawer/Drawer';
import Button from 'Components/ui/Button/Button';
import TextButton from 'Components/ui/TextButton/TextButton';
import { CurrentSubscriptionInfo_organization$data } from 'GraphTypes/CurrentSubscriptionInfo_organization.graphql';
import { createNumber } from 'Util/numberFormatter';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import Spinner from 'Atoms/Spinner/Spinner';

interface Props {
  subscription: NonNullable<CurrentSubscriptionInfo_organization$data['subscription']>;
}

const CancelOfferSubscriptionDrawer: React.FC<Props> = (props) => {
  const { subscription } = props;
  const { openDrawer } = useContext(DrawerContext);

  const period = periodToSubscriptionIntervalMap[subscription?.interval || 'TRIMONTHLY'];
  const percent = percentDiscountToIntervalMap[subscription?.interval || 'TRIMONTHLY'];
  const price = subscription.priceAmount || 0;
  const priceDiff = Math.ceil((price / 100) * percent);
  const newPrice = price - priceDiff;
  const [applyOfferDiscount, discountLoading] = useApplyOfferDiscount(subscription?.id, period);
  const [cancelSubscription, cancelLoading] = useCancelSubscription(subscription?.id);

  const handleBackClick = () => {
    openDrawer('cancel-subscription-typeform-drawer');
  };

  const handleDiscountClick = () => {
    applyOfferDiscount();
  };

  const handleCancelOfferClick = () => {
    cancelSubscription();
  };

  return (
    <Drawer
      rootKey={'cancel-subscription-offer-drawer'}
      className={styles.drawerWrapper}
      containerClassName={styles.drawerContainer}
      backdropClassName={styles.drawerBackdrop}
      needCloseButton
      removeWhenClosed
    >
      <div className={styles.root}>
        <div className={styles.formContainer}>
          <Text
            type="d2"
            msg="billing.cancel.offer.drawer.title"
            className={styles.title}
            formatValues={{
              percent,
              period,
            }}
            data-test="cancelOfferSubscriptionDrawer:text:title"
          />
          <div className={styles.offerContainer}>
            <div className={styles.offerText}>
              <Text
                className={styles.fullPriceText}
                text={`$${createNumber(price)}`}
                data-test="cancelOfferSubscriptionDrawer:text:fullPriceText"
              />
              <Text
                className={styles.offerPriceText}
                text={`$${createNumber(newPrice)}`}
                data-test="cancelOfferSubscriptionDrawer:text:offerPriceText"
              />
              <div className={styles.inlineText}>
                <Text
                  msg="billing.cancel.offer.drawer.offer"
                  className={styles.offerInfoText}
                  data-test="cancelOfferSubscriptionDrawer:text:offer"
                />
                <Text
                  msg="billing.cancel.offer.drawer.save"
                  className={styles.offerSaveText}
                  formatValues={{
                    price: priceDiff,
                  }}
                  data-test="cancelOfferSubscriptionDrawer:text:save"
                />
              </div>
            </div>
            <div className={styles.offerImage} />
          </div>
        </div>
        <div className={styles.bottomContainer}>
          {cancelLoading || discountLoading ? (
            <div className={styles.loadingContainer}>
              <Spinner />
            </div>
          ) : (
            <>
              <div className={styles.buttonContainer}>
                <Button
                  color="white"
                  fluid
                  msg="billing.cancel.offer.drawer.button.back"
                  onClick={handleBackClick}
                  data-test="cancelOfferSubscriptionDrawer:button:back"
                />
                <Button
                  color="black"
                  fluid
                  msg="billing.cancel.offer.drawer.button.continue"
                  onClick={handleDiscountClick}
                  data-test="cancelOfferSubscriptionDrawer:button:continue"
                />
              </div>
              <TextButton
                msg="billing.cancel.offer.drawer.button.cancel"
                onClick={handleCancelOfferClick}
                className={styles.cancelBtn}
                textByCenter
                size="m"
                data-test="cancelOfferSubscriptionDrawer:textButton:cancel"
              />
            </>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default CancelOfferSubscriptionDrawer;
