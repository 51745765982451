import React from 'react';

import styles from './Dialog.pcss';

import Spinner from 'Atoms/Spinner/Spinner';

const DialogStub = () => {
  return (
    <div className={styles.stub}>
      <div className={styles.projectInfoStub}>
        <Spinner className={styles.stubLoader} />
      </div>
      <div className={styles.stubItem}>
        <Spinner className={styles.stubLoader} />
      </div>
    </div>
  );
};

export default DialogStub;
