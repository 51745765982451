import React, { useContext } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';

import styles from './ShopifyShopsList.pcss';

import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { List as ShopsList } from 'Page/advertiser/Campaign/Shipment/ShopsList/ShopsList';
import Drawer from 'Components/ui/Drawer/Drawer';
import { ShopifyShopsListQuery as QueryType } from 'GraphTypes/ShopifyShopsListQuery.graphql';
import Text from 'Components/ui/Text/Text';

const ShopifyShopsListQuery = graphql`
  query ShopifyShopsListQuery($id: ID!) {
    campaign(id: $id) {
      canUpdateShopifyAuthorization
      shopifyAuthorization {
        reauthenticationRequired
        shop {
          name
          url
        }
        id
      }
      organization {
        id
        shopifyAuthorizations {
          edges {
            node {
              id
              reauthenticationRequired
              shop {
                name
              }
            }
          }
        }
      }
    }
  }
`;

interface Props {
  campaignId: string;
  onNewShopConnect: () => void;
}

const ShopifyShopsList: React.FC<Props> = (props) => {
  const { campaignId, onNewShopConnect } = props;
  const { closeDrawer } = useContext(DrawerContext);

  const shopifyData = useLazyLoadQuery<QueryType>(ShopifyShopsListQuery, {
    id: campaignId,
  });

  const list = shopifyData.campaign?.organization?.shopifyAuthorizations;
  const shopifyAuthorization = shopifyData.campaign?.shopifyAuthorization;
  const canUpdateShopifyAuthorization = shopifyData.campaign?.canUpdateShopifyAuthorization;

  const handleDrawerClose = () => {
    closeDrawer(`shop-revision-${campaignId}`);
  };

  return (
    <Drawer rootKey={`shop-revision-${campaignId}`} className={styles.drawer}>
      <Text type="d2" msg="shopify_shops_list.title" />
      <Text type="sm" msg="shopify_shops_list.descr" className={styles.descr} />
      <ShopsList
        inDrawer={true}
        onNewShopConnect={onNewShopConnect}
        canUpdateShopifyAuthorization={canUpdateShopifyAuthorization}
        campaignId={campaignId}
        list={list}
        shopifyAuthorization={shopifyAuthorization}
        onConnectDone={handleDrawerClose}
      />
    </Drawer>
  );
};

export default ShopifyShopsList;
