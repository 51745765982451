import React from 'react';
import classnames from 'classnames';

import styles from './BcaPermissionState.pcss';

import Icon from 'Components/ui/Icon/Icon';
import Text from 'Components/ui/Text/Text';
import { BcaPermissionStateEnum } from 'Types/enums';

type Props = {
  className?: string;
  state: `${BcaPermissionStateEnum}`;
};

const BcaPermissionState: React.FC<Props> = (props) => {
  const { className, state } = props;

  const textMsg = {
    [BcaPermissionStateEnum.PENDING_APPROVAL]:
      'dashboard.advertiser.licenses_section.bca_permission.state.pending_approval',
    [BcaPermissionStateEnum.APPROVED]:
      'dashboard.advertiser.licenses_section.bca_permission.state.approved',
    [BcaPermissionStateEnum.CANCELED]:
      'dashboard.advertiser.licenses_section.bca_permission.state.canceled',
    [BcaPermissionStateEnum.REVOKED]:
      'dashboard.advertiser.licenses_section.bca_permission.state.revoked',
    [BcaPermissionStateEnum.EXPIRED]:
      'dashboard.advertiser.licenses_section.bca_permission.state.expired',
    [BcaPermissionStateEnum.INVALID]:
      'dashboard.advertiser.licenses_section.bca_permission.state.invalid',
    [BcaPermissionStateEnum.UNKNOWN]:
      'dashboard.advertiser.licenses_section.bca_permission.state.unknown',
  };

  const isCheckCircleIcon = new Set<`${BcaPermissionStateEnum}`>([
    BcaPermissionStateEnum.APPROVED,
    BcaPermissionStateEnum.PENDING_APPROVAL,
  ]).has(state);

  const isCrossCircleIcon = new Set<`${BcaPermissionStateEnum}`>([
    BcaPermissionStateEnum.CANCELED,
    BcaPermissionStateEnum.REVOKED,
    BcaPermissionStateEnum.EXPIRED,
    BcaPermissionStateEnum.INVALID,
    BcaPermissionStateEnum.UNKNOWN,
  ]).has(state);

  return (
    <div className={classnames(styles.root, className)}>
      {isCheckCircleIcon && (
        <Icon
          name="Check-circle"
          size={14}
          className={classnames(styles.checkCircleIcon, {
            [styles.iconGreen]: state === BcaPermissionStateEnum.APPROVED,
            [styles.iconYellow]: state === BcaPermissionStateEnum.PENDING_APPROVAL,
          })}
        />
      )}
      {isCrossCircleIcon && (
        <Icon
          name="Close-circle"
          size={14}
          className={classnames(styles.crossCircleIcon, {
            [styles.iconGreen]: state === BcaPermissionStateEnum.APPROVED,
            [styles.iconYellow]: state === BcaPermissionStateEnum.PENDING_APPROVAL,
          })}
        />
      )}
      <Text
        type="md"
        msg={textMsg[state]}
        className={classnames(styles.text, {
          [styles.redText]: state === BcaPermissionStateEnum.UNKNOWN,
        })}
        data-test="bcaPermissionState:text:unknown"
      />
    </div>
  );
};

export default BcaPermissionState;
