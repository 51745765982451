/**
 * @generated SignedSource<<81eeabbb378ed5c49da59b0ed87b84c2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ContentExportType = "FACEBOOK" | "SNAPCHAT" | "TIKTOK";
export type ExportFileStatusQuery$variables = {
  id: string;
};
export type ExportFileStatusQuery$data = {
  readonly contentExport: {
    readonly completed: boolean;
    readonly failed: boolean;
    readonly type: ContentExportType;
  } | null | undefined;
};
export type ExportFileStatusQuery = {
  response: ExportFileStatusQuery$data;
  variables: ExportFileStatusQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completed",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "failed",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExportFileStatusQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ContentExport",
        "kind": "LinkedField",
        "name": "contentExport",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExportFileStatusQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ContentExport",
        "kind": "LinkedField",
        "name": "contentExport",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bd2b6d0d8ffb02f26a9ceeced9ccd251",
    "id": null,
    "metadata": {},
    "name": "ExportFileStatusQuery",
    "operationKind": "query",
    "text": "query ExportFileStatusQuery(\n  $id: ID!\n) {\n  contentExport(id: $id) {\n    completed\n    failed\n    type\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "649a509afa0a75f3bbb9744bb6e05149";

export default node;
