import React, { Fragment, useContext, useEffect } from 'react';
import { debounce } from 'lodash';

import styles from './FilterRange.pcss';

import Input from 'Components/ui/Input/Input';
import { FilterFormItemInputRange } from 'Components/FiltersForm/FilterForm';
import {
  FiltersFormContext,
  FiltersFormContextType,
} from 'Components/FiltersForm/FiltersFormContext';

const FilterRange: React.FC<FilterFormItemInputRange> = (props) => {
  const { fields, parentKey, handleValueChange } = props;
  const { filters, visibleValues, setFiltersValue } =
    useContext<FiltersFormContextType>(FiltersFormContext);

  const defaultValues = fields.map((field) => {
    return visibleValues[field.fieldKey] || filters[field.fieldKey] || '';
  });

  const [value, changeValue] = React.useState<string[]>(defaultValues);

  const handleChangeValue = debounce((newValueArr: string[]) => {
    const visibleValue: Record<string, string[]> = {};
    const newValue = fields.reduce((tmpValue, field, index) => {
      let queryValue: string | null = newValueArr[index];
      if (queryValue !== '') {
        if (field.handleValueChange) {
          queryValue = field.handleValueChange(newValueArr[index]);
        } else if (handleValueChange) {
          queryValue = handleValueChange(newValueArr[index]);
        }
      } else {
        queryValue = null;
      }
      visibleValue[parentKey] = newValueArr;
      return {
        ...tmpValue,
        [field.fieldKey]: queryValue,
      };
    }, {});
    changeValue(newValueArr);
    setFiltersValue(newValue, visibleValue);
  }, 500);

  useEffect(() => () => handleChangeValue.cancel(), [handleChangeValue]);

  useEffect(() => {
    if (visibleValues[parentKey]) {
      changeValue(visibleValues[parentKey]);
    } else {
      changeValue(
        fields.map((field) => {
          return filters[field.fieldKey] || '';
        })
      );
    }
  }, [fields, visibleValues[parentKey]]);

  return (
    <div className={styles.root}>
      {fields.map((field, index) => {
        const { fieldKey } = field;
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          const newValue = [...value];
          newValue[index] = event.target.value;
          handleChangeValue(newValue);
        };
        return (
          <Fragment key={fieldKey}>
            {index > 0 && <div className={styles.divider} />}
            <Input
              bordered
              type="text"
              className={styles.input}
              onChange={handleChange}
              onResetValue={() =>
                handleChange({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>)
              }
              {...field}
              value={value[index]}
            />
          </Fragment>
        );
      })}
    </div>
  );
};

export default React.memo(FilterRange);
