import React, { useState } from 'react';

import styles from './UploadSurveyResults.css';

import { useUpload, FileType } from 'Hooks/useUpload';
import updateInfluencerProfilesFromFile from 'Mutations/UpdateInfluencerProfileFromFile.Mutation';
import Text from 'Atoms/Text/Text';
import Button from 'Atoms/Button/Button';
import FileUploader from 'Atoms/FileUploader/FileUploader';

const SUCCESS = 'Success!';

const UploadSurveyResults = () => {
  const [loading, setLoading] = useState<boolean | null>(null);
  const [error, setError] = useState<null | string>(null);

  const { uploadFile } = useUpload();

  const uploadFileHandler = (files: FileList | null) => {
    if (!files) return;
    uploadFile({
      params: { file: files[0], category: 'other' },
      onSuccess: sendFileToParse,
    });
  };

  const sendFileToParse = (data: { file: FileType }) => {
    const file = data.file;
    if (!file) return;
    setLoading(true);
    updateInfluencerProfilesFromFile(
      { fileSecureUrl: file.secureUrl },
      (data) => {
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        setError(
          (error as [{ message: string }]).reduce(
            (err: string, errData) => `${err}\n${errData.message}`,
            ''
          )
        );
      }
    );
  };

  return (
    <div>
      <Text
        tag="h2"
        weight="500"
        text="Upload creators survey results"
        data-test="uploadSurveyResults:text:uploadCreatorsSurveyResults"
      />
      <details>
        <summary>How to use?</summary>
        <ol>
          <li>File preparation</li>
          <ul>
            <li>Amazon influencer program</li>
            <li>Amazon storefront</li>
            <li>Car owner</li>
            <li>Have children</li>
            <li>Pregnant</li>
            <li>Employed</li>
            <li>Ethnicity</li>
            <li>Gender</li>
            <li>Animals</li>
            <li>Languages</li>
            <li>Hair_type</li>
            <li>Body_type</li>
            <li>Tags</li>
          </ul>
          <li>The principle of adding new fields:</li>
          <ul>
            <li>
              The column contains a tag, the presence / absence of which in the profile depends on
              the answer yes / no - we name the column as the tag should be called, for example New
              tag
            </li>
            <li>
              the column contains several / one new tag - take the names of the tags from the
              answers (the contents of the column) - the column is called Tags (regardless of one or
              many tags contains)
            </li>
            <li>
              The column does not contain a tag - we name the column according to the content, if
              possible, how it is stored in the database
            </li>
          </ul>
          <li>
            When adding tags that did not exist before or new profile properties (for example,
            birthdate), a task is needed to change the configuration, a list of tags (if new ones
            are added)
          </li>
          <li>Import the file into csv.</li>
        </ol>
      </details>
      <div className={styles.uploadBtn}>
        <Button
          loading={!!loading}
          text="Upload csv"
          data-test="uploadSurveyResults:button:uploadCsv"
        />
        <FileUploader typeList=".csv" className={styles.uploadInput} onChange={uploadFileHandler} />
      </div>
      {loading === false && (
        <Text
          weight="500"
          color={!error ? 'green' : 'red'}
          text={error || SUCCESS}
          className={styles.result}
          data-test="uploadSurveyResults:text:result"
        />
      )}
    </div>
  );
};

export default UploadSurveyResults;
