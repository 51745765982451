import React, { memo } from 'react';

import styles from './SequenceResetConfirmDrawer.pcss';

import Text from 'Components/ui/Text/Text';
import Drawer, { DrawerProps } from 'Components/ui/Drawer/Drawer';
import ButtonPreset from 'Components/ui/ButtonPreset/ButtonPreset';
import Button from 'Components/ui/Button/Button';

interface Props {
  rootKey: DrawerProps['rootKey'];
  onCancel: () => void;
  onConfirm: () => void;
  actionType: 'leave' | 'scratch';
}

const SequenceResetConfirmDrawer: React.FC<Props> = (props) => {
  const { onCancel, onConfirm, actionType, rootKey } = props;
  const localizationPrefix = actionType === 'leave' ? 'sequence_leave' : 'sequence_scratch';
  return (
    <Drawer className={styles.drawer} rootKey={rootKey}>
      <div className={styles.contentRoot}>
        <Text type="d2" msg={`invite_outreach_creators.${localizationPrefix}.title`} />
        <div className={styles.content}>
          <Text type="md" msg={`invite_outreach_creators.${localizationPrefix}.description`} />
        </div>
        <ButtonPreset withoutHorizontalPadding>
          <Button
            color="black"
            msg={`invite_outreach_creators.${localizationPrefix}.confirm`}
            onClick={onConfirm}
            fluid
          />
          <Button
            color="white"
            msg={`invite_outreach_creators.${localizationPrefix}.leave`}
            onClick={onCancel}
            fluid
          />
        </ButtonPreset>
      </div>
    </Drawer>
  );
};

export default memo(SequenceResetConfirmDrawer);
