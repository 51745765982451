import React from 'react';

import styles from './NoPaidStatus.pcss';

import Text from 'Atoms/Text/Text';

interface Props {
  transactionId: string;
}

const NoPaidStatus: React.FC<Props> = (props) => {
  return (
    <div className={styles.root}>
      <Text
        color="red"
        msg="admin_transactions.payment_status_no_paid"
        className={styles.noPaidText}
        data-test="noPaidStatus:text:paymentStatusNoPaid"
      />
    </div>
  );
};

export default NoPaidStatus;
