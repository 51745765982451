import React, { useCallback, useContext, useMemo } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import commonStyles from '../Common.pcss';
import { SignUpCompleteContext, StepEnum } from '../../Context';

import styles from './CompanyIndustryStep.pcss';

import type { HubSpotProperties } from 'Analytics/engines/Hubspot';
import Text from 'Components/ui/Text/Text';
import type { Option, OnSelectionChange } from 'Components/OptionsGroup';
import { OptionsGroup } from 'Components/OptionsGroup';

type Props = {
  className?: string;
};

const CompanyIndustryStep: React.FC<Props> = (props) => {
  const { className } = props;
  const { userData, setUserData } = useContext(SignUpCompleteContext);
  const intl = useIntl();

  const options = useMemo<Option<OptionData>[]>(() => {
    return Array(18)
      .fill('')
      .map((v, index) => {
        const msg = `signup_complete.advertiser.steps.company_industry.options.${index}`;
        const text = intl.formatMessage({ id: msg });
        // eslint-disable-next-line max-len
        const hsMsg = `signup_complete.advertiser.steps.company_industry.options.${index}.hubSpotValue`;
        const hubSpotValue = intl.formatMessage({ id: hsMsg }) as OptionData['hubSpotValue'];
        return {
          button: { text },
          selected: userData[StepEnum.CompanyIndustry].industry.value === text,
          data: { hubSpotValue },
        };
      });
  }, [userData[StepEnum.CompanyIndustry].industry]);

  const handleSelectionChange = useCallback<OnSelectionChange<OptionData>>(
    (selectedOptions) => {
      const entries = Object.entries(selectedOptions);
      if (!entries.length) {
        setUserData(StepEnum.CompanyIndustry, { industry: { value: '', hubSpotValue: '' } });
        return;
      }
      const optionKey = +entries[0][0];
      const value = `${options[optionKey]?.button?.text}`;
      const hubSpotValue = options[optionKey]?.data?.hubSpotValue || '';
      setUserData(StepEnum.CompanyIndustry, { industry: { value, hubSpotValue } });
    },
    [options]
  );

  return (
    <div className={cn(styles.root, className)}>
      <div className={commonStyles.header}>
        <Text
          type="h1"
          msg="signup_complete.advertiser.steps.company_industry.title"
          className={commonStyles.title}
          dataTest="text:title"
          data-test="companyIndustryStep:text:title"
        />
        <Text
          type="md"
          msg="signup_complete.advertiser.steps.company_industry.subtitle"
          className={commonStyles.subtitle}
          dataTest="text:subtitle"
          data-test="companyIndustryStep:text:subtitle"
        />
      </div>
      <div className={styles.content}>
        <OptionsGroup
          columnsMarkup="two-columns"
          multipleSelection={false}
          selectable={true}
          options={options}
          onSelectionChange={handleSelectionChange}
        />
      </div>
    </div>
  );
};

export default CompanyIndustryStep;
export { CompanyIndustryStep };

// types

type OptionData = { hubSpotValue: HubSpotProperties['industry_custom_'] };
