import React, { useContext, useEffect, useState } from 'react';

import styles from './FilterPercentSelect.pcss';

import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import Text from 'Components/ui/Text/Text';
import { FilterFormItemSelect } from 'Components/FiltersForm/FilterForm';
import {
  FiltersFormContext,
  FiltersFormContextType,
} from 'Components/FiltersForm/FiltersFormContext';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import RadioButton from 'Components/ui/RadioButton/RadioButton';
import { SearchQueryInput } from 'GraphTypes/DiscoverySearchResultPaginationList.graphql';

const FilterPercentSelect: React.FC<FilterFormItemSelect> = (props) => {
  const { percentageField } = props;
  const isDisabled = percentageField?.isDisabled;
  const fieldKey = percentageField?.fieldKey as keyof SearchQueryInput;
  const { filters, setFilterValueByKey } = useContext<FiltersFormContextType>(FiltersFormContext);
  const defaultValue = filters[fieldKey] || null;
  const [value, changeValue] = useState<number | null>(defaultValue);
  const isFieldDisabled = isDisabled && isDisabled(filters);

  useEffect(() => {
    setFilterValueByKey(fieldKey, value ? Number(value?.toFixed(2)) : undefined);
  }, [value]);

  useEffect(() => {
    changeValue(filters[fieldKey] || undefined);
  }, [filters[fieldKey]]);

  const list = [...Array(20)].map((_, index) => {
    if (index === 0) {
      return {
        id: 0.01,
        name: `> 1%`,
      };
    }
    return {
      id: Number((index * 0.05).toFixed(2)),
      name: `> ${index * 5}%`,
    };
  });

  const handleAnyValueSelect = () => {
    changeValue(null);
  };

  return (
    <div className={styles.root}>
      <Dropdown
        showArrow={false}
        bordered
        disabled={isFieldDisabled}
        value={
          <div className={styles.valueText}>
            <Text type="md" text="%" className={styles.selectorLabel} />
          </div>
        }
        className={styles.dropdown}
      >
        <DropdownGroup className={styles.group}>
          <div className={styles.dropdownList}>
            <div className={styles.item}>
              <AlterButton
                hover={false}
                fluid
                msg="filter.form.placeholder.any"
                onClick={handleAnyValueSelect}
              />
              <RadioButton checked={value === undefined} />
            </div>
            {list.map((item) => {
              const isActive = value === item.id;
              const handleSelect = () => {
                changeValue(item.id);
              };
              return (
                <div key={item.id} className={styles.item} onClick={handleSelect}>
                  <AlterButton hover={false} fluid text={item.name} />
                  <RadioButton checked={isActive} />
                </div>
              );
            })}
          </div>
        </DropdownGroup>
      </Dropdown>
    </div>
  );
};

export default FilterPercentSelect;
