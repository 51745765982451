import React from 'react';
import { createPaginationContainer, graphql, RelayPaginationProp } from 'react-relay';

import styles from './TransactionContainer.pcss';
import { headerItems } from './data';
import TransactionItem from './TransactionItem/TransactionItem';

import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import EmptySearchRequest from 'Molecules/EmptySearchRequest/EmptySearchRequest';
import LoaderHandler from 'Containers/LoaderHandler/LoaderHandler';
import {
  TransactionContainer_billingTransactions$data,
  Billing_TransactionType,
} from 'GraphTypes/TransactionContainer_billingTransactions.graphql';

const COUNT = 25;

interface Props {
  dateFrom?: Date | string | null;
  dateTo?: Date | string | null;
  types?: Billing_TransactionType[];
  campaignIds?: string[];
  brandIds?: string[];
  creatorIds?: string[];
  onParamsClear: () => void;
  relay: RelayPaginationProp;
  billingTransactions?: TransactionContainer_billingTransactions$data | null;
}

const TransactionContainer: React.FC<Props> = (props) => {
  const {
    relay,
    billingTransactions,
    onParamsClear,
    dateFrom,
    dateTo,
    types,
    brandIds,
    campaignIds,
    creatorIds,
  } = props;
  const list = billingTransactions?.billingTransactions?.edges;

  const isRequestEmpty =
    !dateFrom &&
    !dateTo &&
    !types?.length &&
    !brandIds?.length &&
    !campaignIds?.length &&
    !creatorIds?.length;

  const isListEmpty = list?.length === 0;

  if (isListEmpty && !isRequestEmpty) {
    return <EmptySearchRequest onParamsClear={onParamsClear} />;
  }

  if (isListEmpty && isRequestEmpty) {
    return (
      <div className={styles.emptyList}>
        <Icon name="Bank-account-card" className={styles.walletIcon} />
        <Text
          type="sm"
          msg="billing_history.transactions.empty_list"
          data-test="transactionContainer:text:emptyList"
        />
      </div>
    );
  }

  if (!list) return null;

  return (
    <LoaderHandler relay={relay} count={COUNT}>
      <div className={styles.container}>
        <header className={styles.header}>
          {headerItems.map((item) => {
            const { title, className } = item;
            return (
              <div key={item.title} className={`${className} ${styles.headerItem}  ${styles.item}`}>
                <Text msg={title} type="sm" data-test="transactionContainer:text:unknown" />
              </div>
            );
          })}
        </header>
        {list.map((item) => {
          if (!item?.node) return null;

          const { type, createdAt } = item.node;

          return <TransactionItem key={`${type}-${createdAt}`} transaction={item.node} />;
        })}
      </div>
    </LoaderHandler>
  );
};

export default createPaginationContainer(
  TransactionContainer,
  {
    billingTransactions: graphql`
      fragment TransactionContainer_billingTransactions on Organization
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 18 }
        cursor: { type: "String" }
        dateFrom: { type: "DateTime" }
        dateTo: { type: "DateTime" }
        types: { type: "[Billing_TransactionType!]" }
        campaignIds: { type: "[ID!]" }
        brandIds: { type: "[ID!]" }
        creatorIds: { type: "[ID!]" }
      ) {
        billingTransactions(
          first: $count
          after: $cursor
          dateFrom: $dateFrom
          dateTo: $dateTo
          types: $types
          campaignIds: $campaignIds
          brandIds: $brandIds
          creatorIds: $creatorIds
        ) @connection(key: "TransactionContainer_billingTransactions") {
          edges {
            node {
              type
              createdAt
              ...TransactionItem_transaction
            }
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props?.billingTransactions?.billingTransactions;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
        ...props,
      };
    },
    query: graphql`
      query TransactionContainerPaginationQuery(
        $count: Int!
        $cursor: String
        $dateFrom: DateTime
        $dateTo: DateTime
        $types: [Billing_TransactionType!]
        $campaignIds: [ID!]
        $brandIds: [ID!]
        $creatorIds: [ID!]
      ) {
        currentUser {
          organization {
            ...TransactionContainer_billingTransactions
              @arguments(
                count: $count
                cursor: $cursor
                dateFrom: $dateFrom
                dateTo: $dateTo
                types: $types
                campaignIds: $campaignIds
                brandIds: $brandIds
                creatorIds: $creatorIds
              )
          }
        }
      }
    `,
  }
);
