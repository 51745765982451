import React, { useEffect } from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';

import styles from './CampaignFilter.pcss';

import ProgressiveInput from 'Components/ProgressiveInput/ProgressiveInput';
import {
  CampaignFilterQuery as QueryType,
  CampaignFilterQuery$data,
} from 'GraphTypes/CampaignFilterQuery.graphql';
import Text from 'Components/ui/Text/Text';
import { CheckboxItemType } from 'Components/ui/CheckboxSelect/CheckboxSelect';

export const CampaignStub = () => {
  return (
    <div className={styles.stub}>
      <Text
        type="md"
        msg="performance.dashboard.filters.campaign"
        data-test="campaignFilter:text:campaign"
      />
    </div>
  );
};

const CampaignFilterQuery = graphql`
  query CampaignFilterQuery($brandIds: [ID!], $organizationId: ID!) {
    organization(id: $organizationId) {
      campaigns(brandIds: $brandIds) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

interface Props {
  brandId?: string;
  organizationId?: string;
  isDirty: boolean;
  campaigns: CheckboxItemType[];
  campaignListChange: (organization: CampaignFilterQuery$data['organization']) => void;
  onChange: (id: string) => void;
}

const CampaignFilter: React.FC<Props> = (props) => {
  const { brandId, campaigns, organizationId = '', isDirty, onChange, campaignListChange } = props;

  if (!brandId) return <CampaignStub />;

  const data = useLazyLoadQuery<QueryType>(CampaignFilterQuery, {
    brandIds: [brandId],
    organizationId,
  });

  useEffect(() => {
    campaignListChange(data.organization);
  }, [data.organization]);

  return (
    <ProgressiveInput
      hideTitle
      type="checkboxSelect"
      className={styles.progressiveInput}
      inputProps={{
        items: campaigns,
        placeholderMsg: 'performance.dashboard.filters.campaign',
        withSearch: true,
        withCounter: true,
        bordered: false,
        hideByAlphabet: true,
        handleClick: (id: string) => {
          onChange(id);
        },
      }}
      isDirty={isDirty}
    />
  );
};

export default CampaignFilter;
