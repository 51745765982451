import React from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';

import Section from '../Section/Section';

import styles from './CreatorShippingDetails.pcss';

import Text from 'Atoms/Text/Text';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import dummyImg from 'Images/project/dummy.svg';
import { CreatorShippingDetailsQuery as QueryType } from 'GraphTypes/CreatorShippingDetailsQuery.graphql';
import { shipmentAddressFormatter } from 'Util/shipmentAddressFormatter';
import AlterButton from 'Components/ui/AlterButton/AlterButton';

const CreatorShippingDetailsQuery = graphql`
  query CreatorShippingDetailsQuery($projectId: ID!) {
    project(id: $projectId) {
      offer {
        acceptance {
          shippingInformation
          shippingRecipientFirstName
          shippingRecipientLastName
          shippingRecipientPhone
        }
      }
      shippingAddress {
        id
        address1
        address2
        city
        country {
          name
          iso2Code
          provinces {
            code
            name
          }
        }
        firstName
        lastName
        phone
        provinceCode
        zip
      }
      launch {
        id
      }
      campaign {
        id
        shopifyAuthorization {
          id
        }
        showShipmentTab
        organization {
          paymentAccount {
            balance
            currency
          }
        }
      }
    }
  }
`;

interface Props {
  className?: string;
  projectId: string;
}

const CreatorShippingDetails: React.FC<Props> = (props) => {
  const { className, projectId } = props;
  const navigate = useNavigate();

  const data = useLazyLoadQuery<QueryType>(CreatorShippingDetailsQuery, { projectId });

  const project = data?.project;

  if (!data || !data.project) return null;

  const launch = project?.launch;
  const offer = project?.offer;
  const acceptance = offer?.acceptance;
  const shippingInformation = acceptance?.shippingInformation;
  const shippingRecipientFirstName = acceptance?.shippingRecipientFirstName;
  const shippingRecipientPhone = acceptance?.shippingRecipientPhone;
  const shippingRecipientLastName = acceptance?.shippingRecipientLastName;
  const shopifyProjectLink = `/campaigns/${project?.campaign?.id}/shipment`;

  const handleClickConnect = () => navigate(shopifyProjectLink);

  const isNewShippingFormat = Boolean(project?.shippingAddress?.id);

  const shippingAddress = project?.shippingAddress || null;

  const newShipmentText = launch && (
    <>
      <Text
        text={shipmentAddressFormatter(shippingAddress)}
        className={styles.addressText}
        data-test="creatorShippingDetails:text:addressText"
      />
    </>
  );

  const oldShipmentText = (
    <>
      {launch && (shippingRecipientFirstName || shippingRecipientLastName) && (
        <Text
          text={`${shippingRecipientFirstName} ${shippingRecipientLastName}`}
          className={styles.addressText}
          data-test="creatorShippingDetails:text:addressText"
        />
      )}
      {launch && shippingRecipientPhone && (
        <Text
          text={shippingRecipientPhone}
          className={styles.addressText}
          data-test="creatorShippingDetails:text:addressText"
        />
      )}
      {launch && (
        <Text
          text={shippingInformation}
          className={styles.addressText}
          data-test="creatorShippingDetails:text:addressText"
        />
      )}
    </>
  );

  const needToShowShippingInfo = shippingInformation || shippingAddress;

  return needToShowShippingInfo ? (
    <div className={classnames(styles.root, className)}>
      <Section title="chat.shippping_details.title">
        {isNewShippingFormat ? newShipmentText : oldShipmentText}
        {isNewShippingFormat &&
          project?.campaign?.showShipmentTab &&
          !project?.campaign?.shopifyAuthorization?.id &&
          !!launch && (
            <AlterButton
              onClick={handleClickConnect}
              msg={'chat.connect_shopify.button'}
              type={'black'}
              fluid
              className={styles.connectButton}
              data-test="creatorShippingDetails:alterButton:connectButton"
            />
          )}
        {!launch && (
          <Tooltip
            id="shippingDetails"
            tooltipMsg="chat.shippping_details.tooltip"
            place="top"
            tooltipClassName={styles.avaTooltip}
          >
            <img src={dummyImg} />
            <div className={styles.helpBlock}>
              <Text
                msg="chat.shippping_details.unhide_text"
                weight="500"
                display="inline"
                data-test="creatorShippingDetails:text:unhideText"
              />
            </div>
          </Tooltip>
        )}
      </Section>
    </div>
  ) : null;
};

export default CreatorShippingDetails;
