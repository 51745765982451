import { INSTAGRAM, TIKTOK, CONTENT } from 'Constants/general';
import { CreatorType, Currency } from 'GraphTypes/CreatorsListQuery.graphql';

export const defaultParams = {
  acceptsBarter: null,
  active: null,
  ageRangeIds: [],
  audienceAgeRangePercentage: null,
  audienceCitiesIds: [],
  audienceCityPercentage: null,
  audienceCountryPercentage: null,
  audienceEthnicityIds: [],
  audienceGenderId: null,
  audienceGenderPercentage: null,
  audienceInterestPercentage: null,
  businessAccountEnabled: null,
  campaignsCompletionsCountFrom: null,
  campaignsCompletionsCountTo: null,
  categoryIds: [],
  cityIds: [],
  contentCreator: null,
  countryIds: [],
  creatorAnimalIds: [],
  creatorBadgeIds: [],
  creatorBirthdateFrom: null,
  creatorBirthdateTo: null,
  creatorBodyTypeIds: [],
  creatorCountryIds: [],
  creatorEthnicityIds: [],
  creatorGenderId: null,
  creatorHairTypeIds: [],
  creatorIdsToExclude: [],
  creatorLanguageIds: [],
  creatorTagIds: [],
  creatorTypes: [INSTAGRAM, TIKTOK, CONTENT] as CreatorType[],
  currency: 'USD' as Currency,
  customListIds: [],
  employed: null,
  engagementRateFrom: null,
  engagementRateTo: null,
  excludeBadges: null,
  excludeCreatorsWorkedWith: null,
  excludeHiredMoreThanOnce: null,
  exclusions: [],
  experienced: null,
  favorite: null,
  followersFrom: null,
  followersTo: null,
  hashtags: [],
  highAverageRating: null,
  includeAmazonStorefrontLink: null,
  includeHidden: null,
  interestIds: [],
  limit: null,
  onlyCreatorsWorkedWith: null,
  owned: null,
  paidSocialEnabled: null,
  postPriceFrom: null,
  postPriceTo: null,
  postsReachFrom: null,
  postsReachTo: null,
  realFollowersFrom: null,
  realFollowersTo: null,
  reelsPlaysMedianFrom: null,
  reelsPlaysMedianTo: null,
  reliable: null,
  reviewedAtFrom: null,
  reviewedAtTo: null,
  storiesReachFrom: null,
  storiesReachTo: null,
  strictlyByPrimaryCategory: null,
  textQuery: null,
  viewsMedianFrom: null,
  viewsMedianTo: null,
  viewsPerPostFrom: null,
  viewsPerPostTo: null,
  withTiktokShop: null,
};
