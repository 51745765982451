/**
 * @generated SignedSource<<694faac7c13e93394e25b6cac7ccbf83>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Currency = "CAD" | "RUB" | "USD";
export type Permission = "ADMIN__MANAGE_ALL_CAMPAIGNS" | "ADMIN__MANAGE_ALL_ORGANIZATIONS" | "ADMIN__MANAGE_ALL_PROJECTS" | "APPROVAL_TOOL__VIEW_APPROVAL_TOOL_URL" | "CREATOR_PROFILES__EDIT" | "CREATOR_PROFILES__MODERATE" | "CREATOR_PROFILES__VIEW_CAMPAIGNS_HISTORY" | "LONGLISTS__COMMENTS_CREATED_HIDDEN_BY_DEFAULT" | "LONGLISTS__CREATE_CAMPAIGNS" | "LONGLISTS__EXPORT_LONGLISTS" | "LONGLISTS__LIST_CAMPAIGNS" | "LONGLISTS__RATE_CREATORS" | "LONGLISTS__UPDATE_LONGLISTS" | "LONGLISTS__VIEW_ACTIVE_AUDIENCE" | "LONGLISTS__VIEW_HIDDEN_COMMENTS" | "LONGLISTS__VIEW_LONGLISTS" | "LONGLISTS__VIEW_PRICES" | "PAYMENT__VIEW_TRANSACTIONS" | "REVENUE_TRANSACTIONS__MANAGE";
export type AdminCreativeLibraryContainerQuery$variables = Record<PropertyKey, never>;
export type AdminCreativeLibraryContainerQuery$data = {
  readonly brandCategories: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }> | null | undefined;
  readonly currentUser: {
    readonly admin: boolean;
    readonly organization: {
      readonly currency: Currency;
    } | null | undefined;
    readonly permissions: ReadonlyArray<Permission>;
  } | null | undefined;
};
export type AdminCreativeLibraryContainerQuery = {
  response: AdminCreativeLibraryContainerQuery$data;
  variables: AdminCreativeLibraryContainerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "permissions",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "admin",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "BrandCategory",
  "kind": "LinkedField",
  "name": "brandCategories",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminCreativeLibraryContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AdminCreativeLibraryContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ]
  },
  "params": {
    "cacheID": "3fdc474d0c3edbf09a57637518a7b5c0",
    "id": null,
    "metadata": {},
    "name": "AdminCreativeLibraryContainerQuery",
    "operationKind": "query",
    "text": "query AdminCreativeLibraryContainerQuery {\n  currentUser {\n    permissions\n    organization {\n      currency\n      id\n    }\n    admin\n    id\n  }\n  brandCategories {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "5cee560c38547903ce44b8949e1c1ddb";

export default node;
