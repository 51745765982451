/**
 * @generated SignedSource<<187e8407f744bba546e18b24591fa29e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type OutreachHeaderQuery$variables = {
  campaignId: string;
};
export type OutreachHeaderQuery$data = {
  readonly campaign: {
    readonly outreachBatches: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly appliedCount: {
            readonly totalCount: number;
          };
          readonly clickedCount: {
            readonly totalCount: number;
          };
          readonly hiredCount: {
            readonly totalCount: number;
          };
          readonly reachedCount: {
            readonly totalCount: number;
          };
          readonly repliedCount: {
            readonly totalCount: number;
          };
          readonly totalCreators: number;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    };
  } | null | undefined;
};
export type OutreachHeaderQuery = {
  response: OutreachHeaderQuery$data;
  variables: OutreachHeaderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "campaignId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "campaignId"
  }
],
v2 = {
  "alias": "totalCreators",
  "args": null,
  "kind": "ScalarField",
  "name": "creatorCount",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v4 = {
  "alias": "reachedCount",
  "args": [
    {
      "kind": "Literal",
      "name": "outreached",
      "value": true
    }
  ],
  "concreteType": "OutreachSequenceConnection",
  "kind": "LinkedField",
  "name": "sequences",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": "sequences(outreached:true)"
},
v5 = {
  "alias": "clickedCount",
  "args": [
    {
      "kind": "Literal",
      "name": "clicked",
      "value": true
    }
  ],
  "concreteType": "OutreachSequenceConnection",
  "kind": "LinkedField",
  "name": "sequences",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": "sequences(clicked:true)"
},
v6 = {
  "alias": "repliedCount",
  "args": [
    {
      "kind": "Literal",
      "name": "replied",
      "value": true
    }
  ],
  "concreteType": "OutreachSequenceConnection",
  "kind": "LinkedField",
  "name": "sequences",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": "sequences(replied:true)"
},
v7 = {
  "alias": "appliedCount",
  "args": [
    {
      "kind": "Literal",
      "name": "applied",
      "value": true
    }
  ],
  "concreteType": "OutreachSequenceConnection",
  "kind": "LinkedField",
  "name": "sequences",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": "sequences(applied:true)"
},
v8 = {
  "alias": "hiredCount",
  "args": [
    {
      "kind": "Literal",
      "name": "hired",
      "value": true
    }
  ],
  "concreteType": "OutreachSequenceConnection",
  "kind": "LinkedField",
  "name": "sequences",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": "sequences(hired:true)"
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OutreachHeaderQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OutreachBatchConnection",
            "kind": "LinkedField",
            "name": "outreachBatches",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OutreachBatchEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OutreachBatch",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OutreachHeaderQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OutreachBatchConnection",
            "kind": "LinkedField",
            "name": "outreachBatches",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OutreachBatchEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OutreachBatch",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "baa7cce95701696216ec88a9e8f73375",
    "id": null,
    "metadata": {},
    "name": "OutreachHeaderQuery",
    "operationKind": "query",
    "text": "query OutreachHeaderQuery(\n  $campaignId: ID!\n) {\n  campaign(id: $campaignId) {\n    outreachBatches {\n      edges {\n        node {\n          totalCreators: creatorCount\n          reachedCount: sequences(outreached: true) {\n            totalCount\n          }\n          clickedCount: sequences(clicked: true) {\n            totalCount\n          }\n          repliedCount: sequences(replied: true) {\n            totalCount\n          }\n          appliedCount: sequences(applied: true) {\n            totalCount\n          }\n          hiredCount: sequences(hired: true) {\n            totalCount\n          }\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e674144eb06f11bd1976e610fd2f2d92";

export default node;
