/**
 * @generated SignedSource<<5532a6d2037be3802c2137c9c8163823>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AdvertiserProjectStage = "ACCEPTED" | "ACCEPTED_AND_NOT_LAUNCHED" | "ACCEPTED_BY_UNREVIEWED_CREATOR" | "COMPLETED" | "CONTENT_SUBMITTED" | "LAUNCHED" | "PRODUCT_SHIPMENT_COMPLETED" | "PRODUCT_SHIPMENT_STARTED" | "PUBLISHING_STARTED" | "READ" | "REJECTED" | "UNREAD" | "UNSENT";
export type MarkContentReviewAsFailedInput = {
  clientMutationId?: string | null | undefined;
  id?: string | null | undefined;
  projectId: string;
  text: string;
};
export type MarkContentReviewAsFailedMutation$variables = {
  input: MarkContentReviewAsFailedInput;
};
export type MarkContentReviewAsFailedMutation$data = {
  readonly markContentReviewAsFailed: {
    readonly project: {
      readonly advertiserStage: AdvertiserProjectStage | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type MarkContentReviewAsFailedMutation = {
  response: MarkContentReviewAsFailedMutation$data;
  variables: MarkContentReviewAsFailedMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "advertiserStage",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MarkContentReviewAsFailedMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MarkContentReviewAsFailedPayload",
        "kind": "LinkedField",
        "name": "markContentReviewAsFailed",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MarkContentReviewAsFailedMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MarkContentReviewAsFailedPayload",
        "kind": "LinkedField",
        "name": "markContentReviewAsFailed",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bfc1c52014ab1edcc9773d1f54de311c",
    "id": null,
    "metadata": {},
    "name": "MarkContentReviewAsFailedMutation",
    "operationKind": "mutation",
    "text": "mutation MarkContentReviewAsFailedMutation(\n  $input: MarkContentReviewAsFailedInput!\n) {\n  markContentReviewAsFailed(input: $input) {\n    project {\n      advertiserStage\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ae677db64d34b397be1fd04229476209";

export default node;
