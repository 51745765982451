import { graphql } from 'relay-runtime';

import type {
  CurrentUserQuery,
  CurrentUserQuery$data,
  CurrentUserQuery$variables,
} from 'GraphTypes/CurrentUserQuery.graphql';

export default graphql`
  query CurrentUserQuery {
    currentUser {
      email
      id
      type
      createdAt
      emailVerified
      advertiserProfile {
        firstName
        lastName
      }
      contractorProfile {
        firstName
        lastName
      }
    }
  }
`;

export type { CurrentUserQuery, CurrentUserQuery$data, CurrentUserQuery$variables };
