/**
 * @generated SignedSource<<d182dd29faf832afc8d60c929734653e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Payment_PaymentMethodType = "CARD" | "CARDPAY" | "GAMEMONEY" | "PAYPAL" | "QIWI" | "WIRE" | "ZELLE";
export type Payment_WithdrawalStatus = "CANCELED" | "CONFIRMED" | "FAILED" | "PROCESSING" | "UNSENT";
import { FragmentRefs } from "relay-runtime";
export type TransferStatus_status$data = {
  readonly adminComment: string | null | undefined;
  readonly id: string;
  readonly lastError: string | null | undefined;
  readonly paymentAccount: {
    readonly id: string;
  };
  readonly paymentMethod: {
    readonly type: Payment_PaymentMethodType | null | undefined;
  } | null | undefined;
  readonly status: Payment_WithdrawalStatus;
  readonly statusDate: any | null | undefined;
  readonly " $fragmentType": "TransferStatus_status";
};
export type TransferStatus_status$key = {
  readonly " $data"?: TransferStatus_status$data;
  readonly " $fragmentSpreads": FragmentRefs<"TransferStatus_status">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TransferStatus_status",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adminComment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "statusDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "paymentMethod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Payment_Account",
      "kind": "LinkedField",
      "name": "paymentAccount",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastError",
      "storageKey": null
    }
  ],
  "type": "Payment_Withdrawal",
  "abstractKey": null
};
})();

(node as any).hash = "909ff1f0d3e5ed0216ab966241605439";

export default node;
