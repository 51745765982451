import React, { PropsWithChildren, Suspense, useContext, useState, useCallback } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { useLocation } from 'react-router-dom';

import { ChatDrawerContext } from './ChatDrawer.Context';
import ChatDrawer from './ChatDrawer';

import RequestContentRevisionDrawer from 'Modal/advertiser/RequestContentRevision/RequestContentRevision';
import ProjectNotesDrawer from 'Modal/advertiser/ProjectNotesDrawer/ProjectNotesDrawer';
import PriceChangeRequest from 'Modal/advertiser/PriceChangeRequest/PriceChangeRequest';
import SetDraftDeadline from 'Modal/advertiser/SetDraftDeadline/SetDraftDeadline';
import ArchiveProjectRequest from 'Modal/advertiser/ArchiveProjectRequest/ArchiveProjectRequest';
import SetPublicationDate from 'Modal/advertiser/SetPublicationDate/SetPublicationDate';
import ProjectCreatorRatingDrawer from 'Modal/advertiser/ProjectCreatorRating/ProjectCreatorRating';
import ReimbursementPayment from 'Modal/advertiser/ReimbursementPayment/ReimbursementPayment';
import ExtraPaymentDrawer from 'Modal/advertiser/ExtraPayment/ExtraPayment';
import TiktokShopProductsListDrawer from 'Modal/advertiser/TiktokShopProductsList/TiktokShopProductsList';
import ProductDeliveryOption from 'Modal/advertiser/ProductDeliveryOption/ProductDeliveryOption';
import BcaRequestPermissions from 'Modal/advertiser/BcaRequestPermissions/BcaRequestPermissions';
import {
  default as BcaPermissionModal,
  BcaPermissionModalProps,
} from 'Modal/advertiser/BcaPermissionModal/BcaPermissionModal';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { ChatDrawerProviderQuery as QueryType } from 'GraphTypes/ChatDrawerProviderQuery.graphql';
import { MESSAGED, RECIEVED_APPLICANTS } from 'AdvertiserPage/Campaign/NewCampaignTabs/util';

interface Props {
  campaignId: string;
}

const ChatDrawerProviderQuery = graphql`
  query ChatDrawerProviderQuery($id: ID!) {
    campaign(id: $id) {
      stage
      type
      organization {
        id
        paymentAccount {
          balance
        }
      }
    }
  }
`;

export type DrawersActionType = 'Chat' | 'Hired' | 'Completed' | 'Archived' | 'Unarchived' | 'etc';
export type DrawersCloseEvent = 'Success' | 'Error' | 'Cancel';
type DrawersCallbackType = Partial<
  Record<DrawersActionType, Partial<Record<DrawersCloseEvent, (args: unknown) => void>>>
>;

const ChatDrawerProvider: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, campaignId } = props;

  const [projectId, setProjectId] = useState('');
  const { openDrawer } = useContext(DrawerContext);
  const [drawersCallback, setDrawersCallback] = useState<DrawersCallbackType>({});
  const [hasDeliveredMessage, setHasDeliveredMessage] = useState(false);
  const [hasHired, setHasHired] = useState<boolean>(false);
  const location = useLocation();

  const data = useLazyLoadQuery<QueryType>(ChatDrawerProviderQuery, { id: campaignId });

  const activeCampaign = data.campaign?.stage === 'ACTIVE';
  const campaignType = data.campaign?.type;
  const organizationId = data.campaign?.organization?.id;
  const balance = data.campaign?.organization?.paymentAccount?.balance;

  const onProjectIdSet = (newId: string) => {
    setProjectId(newId);
  };

  const openProjectChat = (newId: string) => {
    setProjectId(newId);
    openDrawer('chat-modal');
  };

  const openArchiveProjectChat = (newId: string) => {
    setProjectId(newId);
    openDrawer('archive-project');
  };

  const handleBcaConnectionImpossible = useCallback<
    NonNullable<BcaPermissionModalProps['onBcaConnectionImpossible']>
  >(
    (reason) => {
      if (reason === 'maxLicensedCreatorsExceeded') {
        openDrawer('max-licensed-creators-exceeded');
      }
    },
    [openDrawer]
  );

  const registerCallback = (
    drawerName: DrawersActionType,
    eventName: DrawersCloseEvent,
    drawerCallback
  ) => {
    setDrawersCallback((prevState) => {
      const newState = { ...prevState };
      if (!newState[drawerName]) {
        newState[drawerName] = { [eventName]: drawerCallback };
        return newState;
      }
      newState[drawerName][eventName] = drawerCallback;
      return newState;
    });
  };

  const getCallback = (drawerName: DrawersActionType, eventName: DrawersCloseEvent) => {
    if (drawersCallback[drawerName] && drawersCallback[drawerName][eventName]) {
      return drawersCallback[drawerName][eventName];
    }
    return undefined;
  };

  const handleMessageDelivered = () => {
    if (location.pathname.split('/').at(-1) !== MESSAGED) {
      setHasDeliveredMessage(true);
    }
  };
  const handleHired = () => setHasHired(true);

  const successMessageCallback = getCallback?.('Chat', 'Success');
  const successHiredMessageCallback = getCallback?.('Hired', 'Success');

  const onChatClose = () => {
    if (hasDeliveredMessage) {
      setHasDeliveredMessage(false);
      successMessageCallback?.();
    }
    if (hasHired) {
      setHasHired(false);
      successHiredMessageCallback?.();
    }
  };

  const contextValue = {
    projectId,
    setProjectId,
    openProjectChat,
    openArchiveProjectChat,
    onProjectIdSet,
    registerCallback,
    getCallback,
    handleMessageDelivered,
    handleHired,
  };

  return (
    <ChatDrawerContext.Provider value={contextValue}>
      {children}
      <ChatDrawer
        campaignId={campaignId}
        projectId={projectId}
        activeCampaign={activeCampaign}
        organizationId={organizationId}
        onClose={onChatClose}
      />
      {projectId && (
        <Suspense fallback={null}>
          <ProjectNotesDrawer projectId={projectId} />
        </Suspense>
      )}
      {projectId && (
        <Suspense fallback={null}>
          <PriceChangeRequest projectId={projectId} />
        </Suspense>
      )}
      <RequestContentRevisionDrawer projectId={projectId} />
      {projectId && (
        <Suspense fallback={null}>
          <SetDraftDeadline projectId={projectId} campaignType={campaignType} />
        </Suspense>
      )}
      {projectId && (
        <Suspense fallback={null}>
          <SetPublicationDate projectId={projectId} />
        </Suspense>
      )}
      <ArchiveProjectRequest projectId={projectId} />
      <ProjectCreatorRatingDrawer projectId={projectId} />
      <ReimbursementPayment projectId={projectId} balance={balance} />
      <ExtraPaymentDrawer projectId={projectId} balance={balance} />
      <TiktokShopProductsListDrawer projectId={projectId} campaignId={campaignId} />
      <ProductDeliveryOption projectId={projectId} />
      <BcaRequestPermissions projectId={projectId} />
      {projectId && (
        <BcaPermissionModal
          drawerProps={{ rootKey: `bca-permission` }}
          projectId={projectId}
          onBcaConnectionImpossible={handleBcaConnectionImpossible}
        />
      )}
    </ChatDrawerContext.Provider>
  );
};

export default ChatDrawerProvider;
