/**
 * @generated SignedSource<<065caecc95fa4020c1270a3d6be54dc5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type CampaignPlatform = "FACEBOOK" | "INSTAGRAM" | "OTHER" | "SNAPCHAT" | "TIKTOK" | "YOUTUBE";
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING";
import { FragmentRefs } from "relay-runtime";
export type CampaignCreatives_campaign$data = {
  readonly campaign: {
    readonly counters: {
      readonly campaignPostingBudget: number;
      readonly publicationsEngagementSum: number;
      readonly publicationsViewsSum: number;
    } | null | undefined;
    readonly creativeInsightsLastCollectedAt: any | null | undefined;
    readonly creatives: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly postPublication: {
            readonly id: string;
          } | null | undefined;
          readonly " $fragmentSpreads": FragmentRefs<"ApprovedCreative_creative" | "Creative_creative" | "PostedCreative_creative">;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      readonly pageInfo: {
        readonly endCursor: string | null | undefined;
        readonly hasNextPage: boolean;
      };
      readonly totalCount: number;
    };
    readonly platform: CampaignPlatform | null | undefined;
    readonly publishingRequired: boolean;
    readonly type: CampaignType | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "CampaignCreatives_campaign";
};
export type CampaignCreatives_campaign$key = {
  readonly " $data"?: CampaignCreatives_campaign$data;
  readonly " $fragmentSpreads": FragmentRefs<"CampaignCreatives_campaign">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "campaign",
  "creatives"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "campaignId"
    },
    {
      "defaultValue": 12,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "publishedPosts"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./CampaignCreativesPaginationList.graphql')
    }
  },
  "name": "CampaignCreatives_campaign",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "campaignId"
        }
      ],
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        {
          "alias": "creatives",
          "args": [
            {
              "kind": "Variable",
              "name": "publishedPosts",
              "variableName": "publishedPosts"
            }
          ],
          "concreteType": "CreativeConnection",
          "kind": "LinkedField",
          "name": "__Campaign_creatives_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CreativeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Creative",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PostPublication",
                      "kind": "LinkedField",
                      "name": "postPublication",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ApprovedCreative_creative"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "PostedCreative_creative"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "Creative_creative"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCount",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "platform",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "publishingRequired",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "creativeInsightsLastCollectedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CampaignCounters",
          "kind": "LinkedField",
          "name": "counters",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "campaignPostingBudget",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "publicationsEngagementSum",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "publicationsViewsSum",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "64e6038189a4dddb68534c8c696d90e2";

export default node;
