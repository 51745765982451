import React, { RefObject } from 'react';

import styles from './PortfolioItemResume.pcss';

import HoverVideoPlayer from 'Components/HoverVideoPlayer/HoverVideoPlayer';
import Tag from 'Components/ui/Tag/Tag';

type Props = {
  src?: string;
  poster?: string;
  parentRef: RefObject<HTMLElement>;
  onClick: () => void;
};

const PortfolioItemResume: React.FC<Props> = (props) => {
  const { src, poster, parentRef, onClick } = props;
  return (
    <div onClick={onClick}>
      <div className={styles.tag}>
        <Tag
          msg="creator.portfolio"
          color="dark"
          needMargin={false}
          data-test="portfolioItemResume:tag:portfolio"
        />
      </div>
      <HoverVideoPlayer src={src} poster={poster} parentRef={parentRef} />
    </div>
  );
};

export default PortfolioItemResume;
