import React from 'react';
import { QueryRenderer, graphql } from 'react-relay';

import styles from './OrganzationsList.css';
import OrganzationsListContainer from './OrganzationsListContainer/OrganzationsListContainer';

import environment from 'Api/Environment';
import { OrganzationsListQuery as QueryType } from 'GraphTypes/OrganzationsListQuery.graphql';

const OrganzationsListQuery = graphql`
  query OrganzationsListQuery($textQuery: String) {
    ...OrganzationsListContainer_organizations @arguments(textQuery: $textQuery)
  }
`;

interface Props {
  menu?: any;
  textQuery?: string | null;
}

const OrganzationsList: React.FC<Props> = (props) => {
  const { menu, textQuery } = props;

  if (!menu || textQuery === null) return null;

  return (
    <div id="organizationsMenu" className={styles.root}>
      <QueryRenderer<QueryType>
        environment={environment}
        query={OrganzationsListQuery}
        variables={{ textQuery }}
        render={({ props: queryProps }) => {
          if (!queryProps) return null;

          return (
            <OrganzationsListContainer
              menu={menu}
              textQuery={textQuery}
              organizations={queryProps}
            />
          );
        }}
      />
    </div>
  );
};

export default OrganzationsList;
