import React, { useContext } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import styles from './List.pcss';
import SearchResult from './SearchResult/DiscoverySearchResult';
import DiscoveryGridSearchResult from './DiscoveryGridSearchResult/DiscoveryGridSearchResult';

import Text from 'Atoms/Text/Text';
import SelectCustomListForCreatorDrawer from 'Modal/advertiser/SelectCustomListForCreator/SelectCustomListForCreator';
import {
  DiscoveryCreatorsContext,
  DiscoveryListViewType,
} from 'Page/advertiser/Discovery/DiscoveryCreatorsContext';
import { CustomListCreateDrawer } from 'Modal/advertiser/CustomListCreate';
import { CreateCustomListMutation$data } from 'GraphTypes/CreateCustomListMutation.graphql';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { NotificationsContext } from 'Containers/Notifications/Notifications.Context';
import { CREATORS_LIST_ROUTE } from 'Constants/general';

interface Props {
  isAdmin: boolean;
  className: string;
  campaignId: string;
  canUsePaidSocial?: boolean;
  isBlockedView?: boolean;
  searchCreators?: SearchResults_campaign['searchCreators'] | null;
  hasContentCreation: boolean;
  organizationId?: string;
  showUnlockCreatorBanner: () => void;
}

const List: React.FC<Props> = (props) => {
  const {
    isAdmin,
    className,
    campaignId,
    searchCreators,
    hasContentCreation,
    isBlockedView,
    showUnlockCreatorBanner,
  } = props;

  const navigate = useNavigate();

  const {
    importSelectedCreatorsToCustomList,
    setOneTimeCreator,
    listId,
    organizationId,
    listView,
  } = useContext(DiscoveryCreatorsContext);
  const { showNotification } = useContext(NotificationsContext);

  const { closeDrawer } = useContext(DrawerContext);

  const handleAddCreatorsToList = (listIds: string[]) => {
    listIds.forEach((listId) => {
      importSelectedCreatorsToCustomList((data) => {
        if (data.success) {
          showNotification({
            id: 'creators-added',
            text:
              listIds.length > 1
                ? 'custom_list.notification.creators_added_to_lists'
                : 'custom_list.notification.creators_added',
            btnCaption: 'general.view',
            position: 'center',
            callback: () => {
              if (listIds.length === 1) {
                navigate(`${CREATORS_LIST_ROUTE}/${listIds[0]}`);
              } else {
                navigate(CREATORS_LIST_ROUTE);
              }
            },
          });
        }
      }, listId);
    });
  };

  const handleAddCreatorsDrawerClose = () => {
    setOneTimeCreator(null);
  };

  const onCustomListSuccessfulCreated = (response: CreateCustomListMutation$data) => {
    if (response.createCustomList?.__typename === 'CreateCustomListPayload') {
      importSelectedCreatorsToCustomList((data) => {
        if (data.success) {
          showNotification({
            id: 'creators-added',
            text: 'custom_list.notification.list_created',
            btnCaption: 'general.view',
            position: 'center',
            callback: () => {
              if (
                response.createCustomList?.__typename === 'CreateCustomListPayload' &&
                response.createCustomList?.customList?.id
              ) {
                navigate(`${CREATORS_LIST_ROUTE}/${response.createCustomList.customList?.id}`);
              }
            },
          });
        }
      }, response.createCustomList.customList?.id);
      closeDrawer('custom-list-create-for-creator');
    }
  };
  const list = searchCreators?.edges;

  return (
    <div
      className={classNames(className, styles.root, {
        [styles.grid]: listView === DiscoveryListViewType.GRID,
        [styles.smallGrid]: list.length < 3,
      })}
    >
      {!list ||
        (list.length === 0 && (
          <Text
            className={styles.noResult}
            msg="general.no_result"
            data-test="list:text:noResult"
          />
        ))}
      {list?.map((item) => {
        if (!item?.node || !item.node.creator) return null;

        const id = item?.node.creator.id;

        return listView === DiscoveryListViewType.LIST ? (
          <SearchResult
            key={id}
            isAdmin={isAdmin}
            result={item.node}
            campaignId={campaignId}
            hasContentCreation={hasContentCreation}
            className={styles.item}
            organizationId={organizationId}
            isBlockedView={isBlockedView}
            showUnlockCreatorBanner={showUnlockCreatorBanner}
          />
        ) : (
          <DiscoveryGridSearchResult
            key={id}
            isAdmin={isAdmin}
            result={item.node}
            campaignId={campaignId}
            hasContentCreation={hasContentCreation}
            className={styles.item}
            organizationId={organizationId}
            isBlockedView={isBlockedView}
            showUnlockCreatorBanner={showUnlockCreatorBanner}
          />
        );
      })}
      <SelectCustomListForCreatorDrawer
        preselectedListId={listId}
        handleAddCreatorsToList={handleAddCreatorsToList}
        drawerProps={{
          onClose: handleAddCreatorsDrawerClose,
          rootKey: 'select-custom-list-for-creator',
        }}
      />
      {organizationId && (
        <CustomListCreateDrawer
          drawerProps={{
            rootKey: 'custom-list-create-for-creator',
          }}
          organizationId={organizationId}
          onCustomListSuccessfulCreated={onCustomListSuccessfulCreated}
        />
      )}
    </div>
  );
};

export default List;
