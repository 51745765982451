import React, { useContext, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useLazyLoadQuery, graphql } from 'react-relay';

import styles from './InviteOutreachCreators.pcss';
import {
  InviteOutreachCreatorsContext,
  InviteOutreachCreatorsError,
  InviteOutreachSequenceWarning,
  InviteOutreachTemplateItemData,
  SequenceStep,
} from './InviteOutreachCreatorsContext';
import TemplatePreview from './TemplatePreview/TemplatePreview';
import OutreachSettings from './OutreachSettings/OutreachSettings';
import SequenceSettings from './SequenceSettings/SequenceSettings';
import TemplateEditor from './TemplateEditor/TemplateEditor';
import CreateSequenceDrawer from './CreateSequenceDrawer/CreateSequenceDrawer';
import SequenceSuggestionDrawer from './SequenceSuggestionDrawer/SequenceSuggestionDrawer';
import {
  MIN_SUGGESTED_CREATORS_COUNT,
  sequenceDefaultEmptyStep,
} from './InviteOutreachCreatorsProvider';
import SequenceSettingsDrawer from './SequenceSettingsDrawer/SequenceSettingsDrawer';
import SequenceSettingsMenu from './SequenceSettingsMenu/SequenceSettingsMenu';
import SequenceResetConfirmDrawer from './SequenceResetConfirmDrawer/SequenceResetConfirmDrawer';

import { FREE_PLAN, OUTREACH_LIST_ROUTE } from 'Constants/general';
import Page from 'Templates/Page/Page';
import { InviteOutreachCreatorsQuery as QueryType } from 'GraphTypes/InviteOutreachCreatorsQuery.graphql';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Text from 'Components/ui/Text/Text';
import { amplitude } from 'Helpers/amplitude';
import { CreateOutreachSequenceBlueprintMutation$data } from 'GraphTypes/CreateOutreachSequenceBlueprintMutation.graphql';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { useUpdateOutreachSequenceBlueprintMutation } from 'Api/mutations/UpdateOutreachSequenceBlueprint.Mutation';
import { OutreachStepBlueprintInput } from 'GraphTypes/UpdateOutreachSequenceBlueprintMutation.graphql';
import ErrorHandler from 'Util/errorHandler';

const InviteOutreachCreatorsQuery = graphql`
  query InviteOutreachCreatorsQuery($campaignId: ID!) {
    currentUser {
      organization {
        id
        ...SequenceSelectorForOutreach_list
      }
    }
    campaign(id: $campaignId) {
      ...GmailSettingsForOutreach_campaign
      organization {
        ...SequenceSelectorForOutreach_list
        subscription {
          planId
        }
      }
      searchQuery {
        results {
          hasItems
        }
      }
      useCase
      platform
      sampleOutreachTemplateVariables {
        name
        value
      }
    }
  }
`;

type FieldsThatCanBeOpened = 'creatorList';

const InviteOutreachCreators: React.FC = () => {
  const { id: campaignId = '' } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { openDrawer, closeDrawer } = useContext(DrawerContext);
  const sequenceSuggestions = useRef<InviteOutreachSequenceWarning[]>([]);
  const openedField = useRef<FieldsThatCanBeOpened | null>(null);

  const {
    updatingTemplateLoading,
    selectedLists,
    setActiveStep,
    isFormValid,
    isTemplateChanged,
    processing,
    templateData,
    templatePreview,
    setTemplatePreview,
    setError,
    setCampaignData,
    handleImportOutreachBatch,
    sequenceId,
    sequenceList,
    activeStep,
    setSequenceList,
    saveEmailTemplates,
    setSequenceId,
    createOutreachBatchLoading,
    setTemplateChangedStatus,
    isTemplateCreating,
    setCreatingTemplateStatus,
    stepsWithError,
    isSuggestedCreatorsEnabled,
    selectedPreset,
  } = useContext(InviteOutreachCreatorsContext);

  const queryData = useLazyLoadQuery<QueryType>(
    InviteOutreachCreatorsQuery,
    { campaignId },
    {
      fetchPolicy: 'network-only',
    }
  );
  const [updateOutreachSequenceBlueprintMutation] = useUpdateOutreachSequenceBlueprintMutation();
  const organizationId = queryData.currentUser?.organization?.id;
  const sampleOutreachTemplateVariables = queryData.campaign?.sampleOutreachTemplateVariables;

  const templateVars = useMemo(() => {
    return sampleOutreachTemplateVariables?.reduce((acc, item) => {
      return { ...acc, [item.name]: item.value };
    }, {}) as TemplateSpecification;
  }, [sampleOutreachTemplateVariables]);

  useEffect(() => {
    if (queryData.campaign?.platform) {
      setCampaignData({
        platform: queryData.campaign?.platform,
        suggestedCreatorsExists: Boolean(queryData.campaign.searchQuery?.results?.hasItems),
        useCase: queryData.campaign?.useCase || null,
      });
    }
  }, [queryData.campaign]);

  useLayoutEffect(() => {
    if (openedField.current) {
      openedField.current = null;
    }
  }, [openedField.current]);

  const handleSendClick = () => {
    amplitude.sendEvent<412>({
      id: '412',
      category: 'campaign',
      name: 'outreach_send_click',
      param: { sequence_id: sequenceId || 'New' },
    });
    sequenceSuggestions.current = [];
    const creatorsCount = selectedLists.reduce(
      (creators, list) => (creators += Number(list.count)),
      0
    );
    if (creatorsCount < MIN_SUGGESTED_CREATORS_COUNT && !isSuggestedCreatorsEnabled) {
      sequenceSuggestions.current.push(InviteOutreachSequenceWarning.LOW_CREATORS);
    }
    if (sequenceList.length < 3) {
      sequenceSuggestions.current.push(InviteOutreachSequenceWarning.LOW_SEQUENCE_STEPS);
    }
    const hasApplyLink = sequenceList.some((step) =>
      step.template?.htmlBody.includes('{{action_url}}')
    );
    if (!hasApplyLink) {
      sequenceSuggestions.current.push(InviteOutreachSequenceWarning.NO_APPLY_LINK);
    }
    if (sequenceSuggestions.current.length > 0) {
      openDrawer('sequence-warning');
      return;
    }
    sendBatch();
  };

  const handleSuggestionActionClick = () => {
    closeDrawer('sequence-warning');
    openedField.current = null;
    switch (sequenceSuggestions.current[0]) {
      case InviteOutreachSequenceWarning.LOW_CREATORS: {
        openedField.current = 'creatorList';
        break;
      }
      case InviteOutreachSequenceWarning.LOW_SEQUENCE_STEPS: {
        setActiveStep(sequenceList.length);
        setSequenceList((list: SequenceStep[]) => {
          return [...list, { ...sequenceDefaultEmptyStep, delayInDays: 1 }];
        });
        break;
      }
      case InviteOutreachSequenceWarning.NO_APPLY_LINK: {
        break;
      }
      default:
        break;
    }
  };

  const sendBatch = () => {
    if (sequenceId) {
      if (isTemplateChanged) {
        saveEmailTemplates(sequenceList, (newSteps) => {
          updateSequence(sequenceId, newSteps);
        });
      } else {
        handleImportOutreachBatch(campaignId);
      }
    } else {
      openDrawer('sequence-create');
    }
  };

  const updateSequence = (id: string, steps: OutreachStepBlueprintInput[]) => {
    updateOutreachSequenceBlueprintMutation({
      variables: {
        input: {
          id,
          steps,
        },
      },
      onCompleted: (response) => {
        if (
          response.updateOutreachSequenceBlueprint?.__typename ===
          'UpdateOutreachSequenceBlueprintPayload'
        ) {
          handleImportOutreachBatch(
            campaignId,
            response.updateOutreachSequenceBlueprint.outreachSequenceBlueprint.id
          );
        } else {
          setError(InviteOutreachCreatorsError.MUTATION_ERROR);
          ErrorHandler.error('Update outreach sequence blueprint error', { ...response });
        }
      },
      onError: (error) => {
        setError(InviteOutreachCreatorsError.MUTATION_ERROR);
        ErrorHandler.error('Update outreach sequence blueprint error response', { ...error });
      },
    });
  };

  const handlePreviewClick = () => {
    setTemplatePreview(templateData as InviteOutreachTemplateItemData);
  };

  const handleBackButtonClick = () => {
    openDrawer('sequence-leave');
  };

  const handleCloseLeaveDrawer = () => {
    closeDrawer('sequence-leave');
  };

  const handleBackToBatches = () => {
    closeDrawer('sequence-leave');
    const outreachUrl = generatePath(OUTREACH_LIST_ROUTE, { id: campaignId });
    navigate(outreachUrl);
  };

  const handleCreateSequenceComplete = (
    response: CreateOutreachSequenceBlueprintMutation$data | Error
  ) => {
    if (response instanceof Error) {
      setError(InviteOutreachCreatorsError.MUTATION_ERROR);
      ErrorHandler.error('CreateOutreachSequenceBlueprint mutation failed with error', {
        ...response,
      });
      return;
    }
    if (
      response.createOutreachSequenceBlueprint?.__typename ===
      'CreateOutreachSequenceBlueprintPayload'
    ) {
      const sequenceData = response.createOutreachSequenceBlueprint.outreachSequenceBlueprint;
      setSequenceId(sequenceData.id);
      if (!isTemplateCreating) {
        handleImportOutreachBatch(campaignId, sequenceData.id);
      }
      setCreatingTemplateStatus(false);
    } else {
      setError(InviteOutreachCreatorsError.MUTATION_ERROR);
      ErrorHandler.error('CreateOutreachSequenceBlueprint mutation failed', { ...response });
    }
  };

  const handleActiveElementChange = (data: Partial<SequenceStep>, index?: number) => {
    const usedIndex = typeof index === 'number' ? index : activeStep;
    setSequenceList((list) => {
      return list.map((item, idx) => {
        if (idx === usedIndex) {
          return { ...item, ...data };
        }
        return item;
      });
    });
    if (typeof data.validTemplate !== 'boolean') {
      if (!isTemplateChanged) {
        setTemplateChangedStatus(true);
      }
    }
  };

  if (!queryData.campaign) return null;

  return (
    <Page className={styles.root}>
      <div className={styles.content}>
        <div className={styles.titleContainer}>
          <div className={styles.backContainer}>
            <AlterButton type="grey" icon="Arrow-big-left" onClick={handleBackButtonClick} />
            <Text type="d2" msg="invite_outreach_creators_modal.title" className={styles.title} />
          </div>
          <div className={styles.backContainer}>
            <OutreachSettings
              openedField={openedField.current}
              campaignQuery={queryData.campaign}
              userQuery={queryData.currentUser?.organization}
              query={queryData.campaign.organization}
            />
            <AlterButton
              type="white"
              icon={'Eye'}
              bordered
              className={styles.previewButton}
              disabled={stepsWithError.length > 0}
              onClick={handlePreviewClick}
            />
            <SequenceSettingsMenu />
            {queryData.campaign.organization?.subscription?.planId !== FREE_PLAN && (
              <AlterButton
                type="pink"
                loading={createOutreachBatchLoading || processing || updatingTemplateLoading}
                disabled={!isFormValid}
                msg={
                  sequenceId && !isTemplateChanged
                    ? 'invite_outreach_creators_modal.send'
                    : 'invite_outreach_creators_modal.send_save'
                }
                onClick={handleSendClick}
              />
            )}
          </div>
        </div>
        <div className={styles.settingsContainer}>
          <div className={styles.settings}>
            <SequenceSettings onChangeData={handleActiveElementChange} />
          </div>
          <div className={styles.templateEditor}>
            <div className={styles.template}>
              {sequenceList.map((item, idx) => (
                <TemplateEditor
                  index={idx}
                  variables={templateVars}
                  isActive={idx === activeStep}
                  key={item.id || `${sequenceId || selectedPreset}_${item.template?.id}_${idx}`}
                  template={sequenceList[idx].template}
                  onChangeData={handleActiveElementChange}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      {templatePreview && (
        <TemplatePreview onChangeData={handleActiveElementChange} variables={templateVars} />
      )}
      <CreateSequenceDrawer
        organizationId={organizationId}
        saveEmailTemplates={saveEmailTemplates}
        onCreateCompleted={handleCreateSequenceComplete}
        sequenceSteps={sequenceList}
      />
      <SequenceSettingsDrawer />
      <SequenceSuggestionDrawer
        onActionClick={handleSuggestionActionClick}
        onSend={sendBatch}
        warnings={sequenceSuggestions.current}
      />
      <SequenceResetConfirmDrawer
        actionType="leave"
        rootKey="sequence-leave"
        onCancel={handleBackToBatches}
        onConfirm={handleCloseLeaveDrawer}
      />
    </Page>
  );
};

export default InviteOutreachCreators;
