import React, { MouseEvent, useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';

import styles from './SearchResultItem.pcss';

import { getCreatorData } from 'Util/creatorData';
import { createNumber, createPercent, createShortNumber } from 'Util/numberFormatter';
import IconNew from 'Components/ui/Icon/Icon';
import BlockedUsername from 'Molecules/BlockedUsername/BlockedUsername';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import Text from 'Components/ui/Text/Text';
import CreatorBadge from 'Molecules/CreatorBadge/CreatorBadge';
import { CreatorBadgeEnum } from 'Types/enums';
import { SearchResultItem_creator$key } from 'GraphTypes/SearchResultItem_creator.graphql';
import { getCreatorProfileLink } from 'Util/links';
import NewRegistrationMark from 'Molecules/NewRegistrationMark/NewRegistrationMark';
import Image from 'Components/ui/Image/Image';

interface Props {
  creator: SearchResultItem_creator$key;
  isBlockedView: boolean;
  showUnlockCreatorBanner: () => void;
  onSelect: () => void;
  selected: boolean;
}

const SearchResultItem: React.FC<Props> = (props) => {
  const { creator, isBlockedView, showUnlockCreatorBanner, onSelect, selected } = props;
  const data = useFragment(
    graphql`
      fragment SearchResultItem_creator on Creator {
        __typename
        id
        ratingVotes {
          totalCount
        }
        ownership {
          owner {
            id
            rating {
              averageScore
              voteCount
            }
          }
        }
        profile {
          badges {
            name
          }
          countries {
            name
          }
          categories {
            name
          }
          profilePictureFile {
            url
            ... on Image {
              transformations {
                collageThumbnailUrl
              }
            }
          }
          shippingRecipientLastName
          shippingRecipientFirstName
        }
        type
        username
        ... on InstagramCreator {
          user {
            estimatedLikes
            followedByCount
            engagementRate
            averageViews
          }
        }
        ... on YoutubeCreator {
          channel {
            id
            name
          }
        }
        ... on TiktokCreator {
          user {
            name
            engagementRate
            followedByCount
            averageViews
          }
          userInsightsData {
            engagementRate
            followersCount
          }
        }
      }
    `,
    creator
  );

  if (!data) return null;

  const { avatarUrl, username, type } = useMemo(() => {
    return getCreatorData(data);
  }, [data]);

  const link = getCreatorProfileLink({ creatorId: data.id });

  const engagementRate = data.user?.engagementRate;
  const followers = data.user?.followedByCount;
  const views = data.user?.averageViews;

  const followedByCountText = Number(followers) > 0 ? createNumber(followers) : '—';
  const erText = createPercent(engagementRate);
  const viewsText = createShortNumber(views);

  const averageScore = data?.ownership?.owner.rating?.averageScore;
  const averageScoreText = averageScore ? averageScore.toFixed(2) : '—';
  const voteCount = data?.ownership?.owner.rating?.voteCount;
  const voteCountText = voteCount ? ` (${voteCount})` : '';
  const isRegistered = !!data.ownership?.owner.id;

  const handleSaveToListClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onSelect();
  };

  const handleViewProfileClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  if (!data) return null;

  const badges = data?.profile?.badges;

  return (
    <div className={styles.root}>
      <div className={styles.creatorData}>
        <div className={styles.details}>
          <div className={styles.avatarWrap}>
            <Image size={40} src={avatarUrl} type="round" platform={type.toUpperCase()} />
          </div>
          <div className={styles.creatorName}>
            {isBlockedView && showUnlockCreatorBanner ? (
              <BlockedUsername
                creatorId={data.id}
                onLockClick={showUnlockCreatorBanner}
                textClassName={styles.blockedUsername}
              />
            ) : (
              <>
                <div className={styles.usernameContainer}>
                  <Text
                    type={'md'}
                    text={username}
                    className={styles.username}
                    data-test="searchResultItem:text:username"
                  />
                  {isRegistered && (
                    <div className={styles.registartionMark}>
                      <NewRegistrationMark
                        size={12}
                        tooltipKey={data.id}
                        className={styles.vettedIcon}
                      />
                    </div>
                  )}
                </div>

                <div className={styles.profileInfo}>
                  {Boolean(averageScoreText && isRegistered) && (
                    <div className={styles.profileData}>
                      <IconNew name="Star" color="grey" size={15} />
                      <Text
                        type="label"
                        text={averageScoreText}
                        data-test="searchResultItem:text:unknown"
                      />
                      <Text
                        type="label"
                        className={styles.voteCountText}
                        text={`${voteCountText}`}
                        data-test="searchResultItem:text:voteCountText"
                      />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        <div className={styles.followers}>
          <Text
            type="md"
            text={followedByCountText || '—'}
            data-test="searchResultItem:text:unknown"
          />
        </div>
        <div className={styles.er}>
          <Text type="md" text={erText || '—'} data-test="searchResultItem:text:unknown" />
        </div>
        <div className={styles.er}>
          <Text type="md" text={viewsText || '—'} data-test="searchResultItem:text:unknown" />
        </div>
        <div className={styles.badges}>
          {badges?.length > 0
            ? badges?.map((badge) => {
                if (!badge.name) return null;
                return (
                  <CreatorBadge
                    key={badge.name}
                    badge={badge.name as CreatorBadgeEnum}
                    withText={false}
                    withTooltip
                    tooltipId={`creator_badge_${badge.name}_${data.id}`}
                    className={styles.badge}
                    iconClassName={styles.badgeIcon}
                  />
                );
              })
            : '—'}
        </div>
        <div className={styles.actions}>
          <div className={styles.iconButton} onClick={handleSaveToListClick}>
            <IconNew name={selected ? 'Save-list-filled' : 'Save-list'} color="purple" />
          </div>
          {link && (
            <SmartLink path={link} target="_blank">
              <div className={styles.iconButton} onClick={handleViewProfileClick}>
                <IconNew name="Single-user" color="purple" />
              </div>
            </SmartLink>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(SearchResultItem);
