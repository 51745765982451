/**
 * @generated SignedSource<<10403c3eb6d9d9d8927d537df5b13516>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreatorType = "CONTENT" | "INSTAGRAM" | "TIKTOK" | "YOUTUBE";
export type FileProcessingStatus = "FAILED" | "PROCESSED" | "PROCESSING";
export type FileType = "IMAGE" | "OTHER" | "VIDEO";
export type CreateCreativeInput = {
  clientMutationId?: string | null | undefined;
  fileId?: string | null | undefined;
  id?: string | null | undefined;
  instagramUrl?: string | null | undefined;
  projectId: string;
  publicationUrl?: string | null | undefined;
};
export type CreateCreativeMutation$variables = {
  input: CreateCreativeInput;
};
export type CreateCreativeMutation$data = {
  readonly createCreative: {
    readonly creative: {
      readonly file: {
        readonly height: number | null | undefined;
        readonly id: string;
        readonly secureUrl: string;
        readonly thumbnailUrl: string;
        readonly type: FileType;
        readonly width: number | null | undefined;
      } | null | undefined;
      readonly id: string;
      readonly postPublication: {
        readonly instagramUrl: string | null | undefined;
        readonly media: {
          readonly caption: string | null | undefined;
          readonly commentCount: number;
          readonly likeCount: number;
          readonly thumbnailUrl: string;
          readonly videoUrl: string | null | undefined;
          readonly viewCount: number | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly project: {
        readonly creatives: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly file: {
                readonly id: string;
                readonly processingStatus?: FileProcessingStatus | null | undefined;
                readonly secureUrl: string;
                readonly thumbnailUrl: string;
                readonly transformations?: {
                  readonly autoUrl: string;
                  readonly collageThumbnailUrl: string;
                } | null | undefined;
                readonly type: FileType;
                readonly url: string | null | undefined;
              } | null | undefined;
              readonly id: string;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
        readonly creator: {
          readonly channel?: {
            readonly name: string | null | undefined;
          } | null | undefined;
          readonly id: string;
          readonly profile: {
            readonly profilePictureFile: {
              readonly transformations?: {
                readonly collageThumbnailUrl: string;
              } | null | undefined;
              readonly url: string | null | undefined;
            } | null | undefined;
          } | null | undefined;
          readonly type: CreatorType;
          readonly username: string;
        } | null | undefined;
      } | null | undefined;
    };
  } | null | undefined;
};
export type CreateCreativeMutation = {
  response: CreateCreativeMutation$data;
  variables: CreateCreativeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "width",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "height",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "secureUrl",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnailUrl",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "instagramUrl",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "likeCount",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "commentCount",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewCount",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "caption",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "videoUrl",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "collageThumbnailUrl",
  "storageKey": null
},
v16 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "processingStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VideoTransformation",
      "kind": "LinkedField",
      "name": "transformations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "autoUrl",
          "storageKey": null
        },
        (v15/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Video",
  "abstractKey": null
},
v17 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ImageTransformation",
      "kind": "LinkedField",
      "name": "transformations",
      "plural": false,
      "selections": [
        (v15/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Image",
  "abstractKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateCreativeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateCreativePayload",
        "kind": "LinkedField",
        "name": "createCreative",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Creative",
            "kind": "LinkedField",
            "name": "creative",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "file",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PostPublication",
                "kind": "LinkedField",
                "name": "postPublication",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Media",
                    "kind": "LinkedField",
                    "name": "media",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v7/*: any*/),
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Project",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CreativeConnection",
                    "kind": "LinkedField",
                    "name": "creatives",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CreativeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Creative",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "file",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v5/*: any*/),
                                  (v7/*: any*/),
                                  (v6/*: any*/),
                                  (v14/*: any*/),
                                  (v16/*: any*/),
                                  (v17/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "creator",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v5/*: any*/),
                      (v18/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CreatorProfile",
                        "kind": "LinkedField",
                        "name": "profile",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "profilePictureFile",
                            "plural": false,
                            "selections": [
                              (v14/*: any*/),
                              (v17/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "YoutubeChannel",
                            "kind": "LinkedField",
                            "name": "channel",
                            "plural": false,
                            "selections": [
                              (v19/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "YoutubeCreator",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateCreativeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateCreativePayload",
        "kind": "LinkedField",
        "name": "createCreative",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Creative",
            "kind": "LinkedField",
            "name": "creative",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "file",
                "plural": false,
                "selections": [
                  (v20/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PostPublication",
                "kind": "LinkedField",
                "name": "postPublication",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Media",
                    "kind": "LinkedField",
                    "name": "media",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v7/*: any*/),
                      (v13/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Project",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CreativeConnection",
                    "kind": "LinkedField",
                    "name": "creatives",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CreativeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Creative",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "file",
                                "plural": false,
                                "selections": [
                                  (v20/*: any*/),
                                  (v2/*: any*/),
                                  (v5/*: any*/),
                                  (v7/*: any*/),
                                  (v6/*: any*/),
                                  (v14/*: any*/),
                                  (v16/*: any*/),
                                  (v17/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "creator",
                    "plural": false,
                    "selections": [
                      (v20/*: any*/),
                      (v2/*: any*/),
                      (v5/*: any*/),
                      (v18/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CreatorProfile",
                        "kind": "LinkedField",
                        "name": "profile",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "profilePictureFile",
                            "plural": false,
                            "selections": [
                              (v20/*: any*/),
                              (v14/*: any*/),
                              (v17/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "YoutubeChannel",
                            "kind": "LinkedField",
                            "name": "channel",
                            "plural": false,
                            "selections": [
                              (v19/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "YoutubeCreator",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f0e6a25ab374b1afd42d069f8f8b200f",
    "id": null,
    "metadata": {},
    "name": "CreateCreativeMutation",
    "operationKind": "mutation",
    "text": "mutation CreateCreativeMutation(\n  $input: CreateCreativeInput!\n) {\n  createCreative(input: $input) {\n    creative {\n      id\n      file {\n        __typename\n        id\n        width\n        height\n        type\n        secureUrl\n        thumbnailUrl\n      }\n      postPublication {\n        instagramUrl\n        media {\n          likeCount\n          commentCount\n          viewCount\n          caption\n          thumbnailUrl\n          videoUrl\n          id\n        }\n        id\n      }\n      project {\n        creatives {\n          edges {\n            node {\n              id\n              file {\n                __typename\n                id\n                type\n                thumbnailUrl\n                secureUrl\n                url\n                ... on Video {\n                  processingStatus\n                  transformations {\n                    autoUrl\n                    collageThumbnailUrl\n                  }\n                }\n                ... on Image {\n                  transformations {\n                    collageThumbnailUrl\n                  }\n                }\n              }\n            }\n          }\n        }\n        creator {\n          __typename\n          id\n          type\n          username\n          profile {\n            profilePictureFile {\n              __typename\n              url\n              ... on Image {\n                transformations {\n                  collageThumbnailUrl\n                }\n              }\n              id\n            }\n            id\n          }\n          ... on YoutubeCreator {\n            channel {\n              name\n              id\n            }\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1e6f728650f46ca0d6098ae168015a0e";

export default node;
