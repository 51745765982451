import React, { PropsWithChildren } from 'react';

import styles from './SubSection.pcss';

import Text from 'Components/ui/Text/Text';
import InfoTooltip from 'Molecules/InfoTooltip/InfoTooltip';

interface Props {
  title: string;
  tooltipMsg?: string;
  value?: string;
  additionalValue?: string;
}

const SubSection: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, title, value, additionalValue, tooltipMsg } = props;

  const style = value ? '' : styles.container;

  return (
    <div>
      <header className={`${styles.header} ${style}`}>
        <Text
          type="s"
          color="grey"
          msg={title}
          className={styles.title}
          data-test="subSection:text:title"
        />
        {tooltipMsg && <InfoTooltip id={title} tooltipMsg={tooltipMsg} place="bottom" />}
      </header>
      <div className={styles.content}>
        {value && <Text className={styles.value} text={value} data-test="subSection:text:value" />}
        {additionalValue && (
          <Text
            color="green"
            text={additionalValue}
            className={styles.additional}
            data-test="subSection:text:additional"
          />
        )}
        {children}
      </div>
    </div>
  );
};

export default SubSection;
