/**
 * @generated SignedSource<<c86e351137f120d4a96d726734872108>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AdvertiserProjectStage = "ACCEPTED" | "ACCEPTED_AND_NOT_LAUNCHED" | "ACCEPTED_BY_UNREVIEWED_CREATOR" | "COMPLETED" | "CONTENT_SUBMITTED" | "LAUNCHED" | "PRODUCT_SHIPMENT_COMPLETED" | "PRODUCT_SHIPMENT_STARTED" | "PUBLISHING_STARTED" | "READ" | "REJECTED" | "UNREAD" | "UNSENT";
export type Currency = "CAD" | "RUB" | "USD";
export type ProductDeliveryOption = "BRAND_WILL_HANDLE_SHIPMENT_TO_CREATORS" | "BRAND_WILL_REFUND_THE_PURCHASED_PRODUCT" | "COLLABORATION_WILL_NOT_REQUIRE_PRODUCT_SHIPMENT" | "TTS_OTHER" | "TTS_SEND_SAMPLE_TO_CREATOR" | "TTS_SEND_SAMPLE_TO_CREATOR_WITHOUT_APPROVAL";
export type LaunchProjectInput = {
  clientMutationId?: string | null | undefined;
  id?: string | null | undefined;
  productDeliveryOption?: ProductDeliveryOption | null | undefined;
  projectId: string;
};
export type LaunchProjectMutation$variables = {
  input: LaunchProjectInput;
};
export type LaunchProjectMutation$data = {
  readonly launchProject: {
    readonly project: {
      readonly advertiserStage: AdvertiserProjectStage | null | undefined;
      readonly campaign: {
        readonly creator: {
          readonly organization: {
            readonly paymentAccount: {
              readonly balance: number;
              readonly currency: Currency;
            } | null | undefined;
            readonly subscription: {
              readonly minDepositAmount: number;
            } | null | undefined;
          } | null | undefined;
        } | null | undefined;
        readonly projects: {
          readonly hasItems: boolean;
        };
      };
      readonly launch: {
        readonly createdAt: any;
        readonly id: string;
      } | null | undefined;
    };
  } | null | undefined;
};
export type LaunchProjectMutation = {
  response: LaunchProjectMutation$data;
  variables: LaunchProjectMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectLaunch",
  "kind": "LinkedField",
  "name": "launch",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "advertiserStage",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "archived",
      "value": false
    },
    {
      "kind": "Literal",
      "name": "hasBrandMessageBeforeLaunch",
      "value": true
    },
    {
      "kind": "Literal",
      "name": "stages",
      "value": ([]/*: any*/)
    }
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasItems",
      "storageKey": null
    }
  ],
  "storageKey": "projects(archived:false,hasBrandMessageBeforeLaunch:true,stages:[])"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "balance",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minDepositAmount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LaunchProjectMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LaunchProjectPayload",
        "kind": "LinkedField",
        "name": "launchProject",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Campaign",
                "kind": "LinkedField",
                "name": "campaign",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "creator",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Organization",
                        "kind": "LinkedField",
                        "name": "organization",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Payment_Account",
                            "kind": "LinkedField",
                            "name": "paymentAccount",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AdvertiserSubscription",
                            "kind": "LinkedField",
                            "name": "subscription",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LaunchProjectMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LaunchProjectPayload",
        "kind": "LinkedField",
        "name": "launchProject",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Campaign",
                "kind": "LinkedField",
                "name": "campaign",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "creator",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Organization",
                        "kind": "LinkedField",
                        "name": "organization",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Payment_Account",
                            "kind": "LinkedField",
                            "name": "paymentAccount",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v7/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AdvertiserSubscription",
                            "kind": "LinkedField",
                            "name": "subscription",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "05927f9a58e354bfe4cabeb7061f73c3",
    "id": null,
    "metadata": {},
    "name": "LaunchProjectMutation",
    "operationKind": "mutation",
    "text": "mutation LaunchProjectMutation(\n  $input: LaunchProjectInput!\n) {\n  launchProject(input: $input) {\n    project {\n      launch {\n        id\n        createdAt\n      }\n      advertiserStage\n      campaign {\n        projects(stages: [], archived: false, hasBrandMessageBeforeLaunch: true) {\n          hasItems\n        }\n        creator {\n          organization {\n            paymentAccount {\n              balance\n              currency\n              id\n            }\n            subscription {\n              minDepositAmount\n              id\n            }\n            id\n          }\n          id\n        }\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "42f7a31a29e694ee56dd4bfb35f21bd1";

export default node;
