/**
 * @generated SignedSource<<e70d83b1a7a039d759ca16f4c90392c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING";
import { FragmentRefs } from "relay-runtime";
export type ProjectPublicationDate_project$data = {
  readonly archivedByAdvertiser: {
    readonly id: string;
  } | null | undefined;
  readonly campaign: {
    readonly type: CampaignType | null | undefined;
  };
  readonly completion: {
    readonly id: string;
  } | null | undefined;
  readonly deadline: {
    readonly publicationDate: any | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly launch: {
    readonly id: string;
  } | null | undefined;
  readonly " $fragmentType": "ProjectPublicationDate_project";
};
export type ProjectPublicationDate_project$key = {
  readonly " $data"?: ProjectPublicationDate_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectPublicationDate_project">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectPublicationDate_project",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectLaunch",
      "kind": "LinkedField",
      "name": "launch",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": "archivedByAdvertiser",
      "args": [
        {
          "kind": "Literal",
          "name": "side",
          "value": "ADVERTISER"
        }
      ],
      "concreteType": "ProjectArchivation",
      "kind": "LinkedField",
      "name": "archivation",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "archivation(side:\"ADVERTISER\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Deadline",
      "kind": "LinkedField",
      "name": "deadline",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "publicationDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectCompletion",
      "kind": "LinkedField",
      "name": "completion",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};
})();

(node as any).hash = "c3ec6a7f320de7789cb95abd559768fd";

export default node;
