import { createContext } from 'react';

import {
  DrawersCloseEvent,
  DrawersActionType,
} from 'AdvertiserPage/Campaign/ChatDrawer/ChatDrawer.Provider';

type ChatDrawerContextType = {
  projectId?: string;
  setProjectId: (projectId: string) => void;
  openProjectChat: (projectId: string) => void;
  onProjectIdSet: (projectId: string) => void;
  openArchiveProjectChat: (projectId: string) => void;
  registerCallback: (
    drawerName: DrawersActionType,
    eventName: DrawersCloseEvent,
    drawerCallback: (args: unknown) => void
  ) => void;
  getCallback: (
    drawerName: DrawersActionType,
    eventName: DrawersCloseEvent
  ) => (args: unknown | undefined) => void | undefined;
  handleHired: () => void;
  handleMessageDelivered: () => void;
};

const ChatDrawerContext = createContext<ChatDrawerContextType>({} as ChatDrawerContextType);

export { ChatDrawerContext };

export type { ChatDrawerContextType };
