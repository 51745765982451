/**
 * @generated SignedSource<<ab5034f520966f65d376c87398f6938c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CreatorType = "CONTENT" | "INSTAGRAM" | "TIKTOK" | "YOUTUBE";
import { FragmentRefs } from "relay-runtime";
export type DiscoverySearchResult_result$data = {
  readonly creator: {
    readonly id: string;
    readonly profile: {
      readonly profilePictureFile: {
        readonly transformations?: {
          readonly collageThumbnailUrl: string;
        } | null | undefined;
      } | null | undefined;
      readonly searchable: boolean;
    } | null | undefined;
    readonly type: CreatorType;
    readonly username: string;
    readonly " $fragmentSpreads": FragmentRefs<"SearchResultItem_creator">;
  } | null | undefined;
  readonly viewed: boolean;
  readonly " $fragmentType": "DiscoverySearchResult_result";
};
export type DiscoverySearchResult_result$key = {
  readonly " $data"?: DiscoverySearchResult_result$data;
  readonly " $fragmentSpreads": FragmentRefs<"DiscoverySearchResult_result">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DiscoverySearchResult_result",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "creator",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CreatorProfile",
          "kind": "LinkedField",
          "name": "profile",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "searchable",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "profilePictureFile",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ImageTransformation",
                      "kind": "LinkedField",
                      "name": "transformations",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "collageThumbnailUrl",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "Image",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SearchResultItem_creator"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CreatorSearchResult",
  "abstractKey": null
};

(node as any).hash = "03e7587de03e3ce89815d475816e4ebd";

export default node;
