/**
 * @generated SignedSource<<f2d2a30f059fa38179e1d2e2054a2c00>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BriefCreativeContentFormat = "HORIZONTAL_16_9" | "SQUARE" | "STATIC" | "VERTICAL_4_5" | "VERTICAL_9_16";
export type BriefCreativeContentPreparedness = "FINAL" | "RAW" | "RAW_AND_FINAL";
export type BriefCreativeContentType = "CUSTOM" | "HOWTO" | "PRODUCT_DEMO" | "PRODUCT_REVIEW" | "TESTIMONIAL" | "UNBOXING";
export type BriefCreativeMediaType = "PHOTO" | "VIDEO";
export type ContentType = "BOOMERANG" | "HORIZONTAL_VIDEO" | "IMAGE" | "SQUARE_VIDEO" | "VERTICAL_VIDEO";
export type FileType = "IMAGE" | "OTHER" | "VIDEO";
export type V2ContentPlacement = "FEED" | "MIDDLEROLL" | "NONE" | "POSTROLL" | "PREROLL" | "REEL" | "STORY";
import { FragmentRefs } from "relay-runtime";
export type CreativeItem_briefCreative$data = {
  readonly contentAmount: number | null | undefined;
  readonly contentFormat: BriefCreativeContentFormat | null | undefined;
  readonly contentMusic: boolean;
  readonly contentMusicDescription: string | null | undefined;
  readonly contentPreparedness: BriefCreativeContentPreparedness | null | undefined;
  readonly contentType: ContentType | null | undefined;
  readonly donts: string | null | undefined;
  readonly dos: string | null | undefined;
  readonly mediaType: BriefCreativeMediaType | null | undefined;
  readonly postCaption: string | null | undefined;
  readonly publishingRequired: boolean | null | undefined;
  readonly references: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly file: {
          readonly filename: string | null | undefined;
          readonly id: string;
          readonly originalFilename: string | null | undefined;
          readonly secureUrl: string;
          readonly transformations?: {
            readonly autoUrl: string;
            readonly collageThumbnailUrl: string;
          } | null | undefined;
          readonly type: FileType;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  };
  readonly technicalRequirements: string | null | undefined;
  readonly v2ContentPlacement: V2ContentPlacement | null | undefined;
  readonly v2ContentType: BriefCreativeContentType | null | undefined;
  readonly videoDurationMax: number | null | undefined;
  readonly videoDurationMin: number | null | undefined;
  readonly " $fragmentType": "CreativeItem_briefCreative";
};
export type CreativeItem_briefCreative$key = {
  readonly " $data"?: CreativeItem_briefCreative$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreativeItem_briefCreative">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "collageThumbnailUrl",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "references"
        ]
      }
    ]
  },
  "name": "CreativeItem_briefCreative",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mediaType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "v2ContentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "videoDurationMin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "videoDurationMax",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentFormat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dos",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "donts",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentMusic",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentMusicDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "technicalRequirements",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postCaption",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "v2ContentPlacement",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publishingRequired",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentPreparedness",
      "storageKey": null
    },
    {
      "alias": "references",
      "args": null,
      "concreteType": "ReferenceConnection",
      "kind": "LinkedField",
      "name": "__Creative_references_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReferenceEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Reference",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "file",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "secureUrl",
                      "storageKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "VideoTransformation",
                          "kind": "LinkedField",
                          "name": "transformations",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "autoUrl",
                              "storageKey": null
                            },
                            (v0/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "Video",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ImageTransformation",
                          "kind": "LinkedField",
                          "name": "transformations",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "Image",
                      "abstractKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "originalFilename",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "filename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BriefCreative",
  "abstractKey": null
};
})();

(node as any).hash = "49b1cb1e1cec46bc0390e27ab2a4a9ff";

export default node;
