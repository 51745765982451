import React from 'react';
import { useFragment, graphql } from 'react-relay';

import styles from './Workspace.pcss';

import Text from 'Components/ui/Text/Text';
import Input from 'Components/ui/Input/Input';
import { Workspace_user$key, Workspace_user$data } from 'GraphTypes/Workspace_user.graphql';

interface Props {
  user: Workspace_user$key;
}

const Workspace: React.FC<Props> = (props) => {
  const { user } = props;
  const data = useFragment(
    graphql`
      fragment Workspace_user on User {
        organization {
          name
          websiteUrl
          industry
        }
      }
    `,
    user as any
  ) as Workspace_user$data;

  if (!data) return null;

  const companyName = data.organization?.name;
  const websiteUrl = data.organization?.websiteUrl || '';
  const industry = data.organization?.industry || '';

  return (
    <div className={styles.root}>
      <Text
        type="d2"
        msg="advertiser.settings.workspace.title"
        className={styles.title}
        data-test="workspace:text:title"
      />
      <Input
        labelMsg="advertiser.settings.workspace.company_name"
        value={companyName}
        bordered
        hideCloseIcon
        forceLabelShow
        disabled
        className={styles.input}
        data-test="workspace:input:input"
      />
      <Input
        labelMsg="advertiser.settings.workspace.website"
        value={websiteUrl}
        bordered
        hideCloseIcon
        forceLabelShow
        disabled
        className={styles.input}
        data-test="workspace:input:input"
      />
      <Input
        labelMsg="advertiser.settings.workspace.company_category"
        value={industry}
        bordered
        hideCloseIcon
        forceLabelShow
        disabled
        className={styles.input}
        data-test="workspace:input:input"
      />
    </div>
  );
};

export default Workspace;
