import React, { useState, useContext } from 'react';
import classnames from 'classnames';

import styles from './ShopsList.pcss';

import { amplitude } from 'Helpers/amplitude';
import { useShopify } from 'Hooks/useShopify';
import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { ShipmentTabQuery$data } from 'GraphTypes/ShipmentTabQuery.graphql';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import shopifyImg from 'Images/campaign/shopify.png';
import shopify2xImg from 'Images/campaign/shopify_2x.png';

interface Props {
  className?: string;
}

interface ListProps {
  inDrawer?: boolean;
  canUpdateShopifyAuthorization?: boolean;
  campaignId: string;
  shopifyAuthorization?:
    | NonNullable<ShipmentTabQuery$data['campaign']>['shopifyAuthorization']
    | null;
  list?:
    | NonNullable<
        NonNullable<ShipmentTabQuery$data['campaign']>['organization']
      >['shopifyAuthorizations']
    | null;
  onNewShopConnect: () => void;
  onConnectDone?: () => void;
}

export const List: React.FC<ListProps> = (props) => {
  const {
    shopifyAuthorization,
    list,
    campaignId,
    inDrawer,
    canUpdateShopifyAuthorization,
    onNewShopConnect,
    onConnectDone,
  } = props;

  const { openDrawer } = useContext(DrawerContext);

  const [loading, setLoading] = useState(false);

  const { connectCampaignToShopify } = useShopify({ campaignId });

  const needToReauth = shopifyAuthorization?.reauthenticationRequired;

  const handleReconnectClick = () => {
    setLoading(true);
    if (!shopifyAuthorization?.id) return;
    localStorage.setItem('shopify-campaign-id', campaignId);
    openDrawer('shopify-auth');
  };

  return (
    <div className={classnames(styles.items, { [styles.inDrawer]: inDrawer })}>
      {shopifyAuthorization && (
        <div className={styles.shop}>
          {needToReauth ? (
            <Icon name="No-internet" className={styles.reauth} />
          ) : (
            <Icon name="Check" className={styles.check} />
          )}

          <div className={styles.details}>
            <Text
              type="md"
              text={shopifyAuthorization.shop.name}
              className={styles.title}
              data-test="shopsList:text:title"
            />
            <Text
              type="label"
              className={needToReauth ? styles.error : styles.status}
              msg={
                needToReauth ? 'shipment.status.connection_lost' : 'shipment.connected_to_campaign'
              }
              data-test="shopsList:text:unknown"
            />
          </div>
          {needToReauth && (
            <AlterButton
              type="black"
              loading={loading}
              msg="shipment.connection.connect_again"
              onClick={handleReconnectClick}
              data-test="shopsList:alterButton:connectAgain"
            />
          )}
        </div>
      )}
      {list?.edges?.map((item) => {
        if (!item?.node) return null;

        if (item.node.id === shopifyAuthorization?.id) return null;
        const shopifyAuthorizationId = item.node.id;

        const handleConnectClick = () => {
          amplitude.sendEvent<366>({
            id: '366',
            category: 'shipment',
            name: 'connect_to_this_campaign_click',
            param: {
              campaign_id: campaignId,
            },
          });
          if (item.node?.reauthenticationRequired) {
            openDrawer('shopify-auth');
            return;
          }
          setLoading(true);
          connectCampaignToShopify({ campaignId, shopifyAuthorizationId }).then((response) => {
            setLoading(false);
          });
        };

        return (
          <div
            className={classnames(styles.shop, {
              [styles.disabled]: !canUpdateShopifyAuthorization,
            })}
            key={item.node.id}
          >
            <Icon name="Product-box" className={styles.icon} />
            <Text
              type="md"
              text={item.node.shop.name}
              className={styles.title}
              data-test="shopsList:text:title"
            />
            <Tooltip
              id={`btn-${item.node.id}`}
              place="top"
              tooltipMsg={
                !canUpdateShopifyAuthorization ? 'shopify_shops_list.shop.disabled' : undefined
              }
            >
              <AlterButton
                type="black"
                msg="shipment.connect_store_to_campaign.btn"
                loading={loading}
                disabled={!canUpdateShopifyAuthorization}
                onClick={handleConnectClick}
                data-test="shopsList:alterButton:btn"
              />
            </Tooltip>
          </div>
        );
      })}
      <div
        className={classnames(styles.shop, styles.addNew, {
          [styles.disabled]: !canUpdateShopifyAuthorization,
        })}
        onClick={canUpdateShopifyAuthorization ? onNewShopConnect : undefined}
      >
        <Icon name="Add-plus-circle" />
        <Text
          type="md"
          msg="shipment.connect_store_to_campaign.btn"
          className={styles.title}
          data-test="shopsList:text:btn"
        />
        <Icon name="Arrow-small-right" />
      </div>
    </div>
  );
};

const ShopsList: React.FC<Props & ListProps> = (props) => {
  const {
    campaignId,
    className,
    shopifyAuthorization,
    list,
    onNewShopConnect,
    canUpdateShopifyAuthorization,
  } = props;

  return (
    <div className={classnames(styles.root, className)}>
      <div className={styles.container}>
        <img srcSet={`${shopifyImg} 1x, ${shopify2xImg} 2x`} className={styles.stubImg} />
        <Text
          type="h1"
          weight="bold"
          msg="shipment.connect_store.title"
          className={styles.title}
          data-test="shopsList:text:title"
        />
        <Text
          type="sm"
          color="grey"
          msg="shipment.connect_store.descr"
          className={styles.descr}
          data-test="shopsList:text:descr"
        />
        <List
          campaignId={campaignId}
          shopifyAuthorization={shopifyAuthorization}
          list={list}
          onNewShopConnect={onNewShopConnect}
          canUpdateShopifyAuthorization={canUpdateShopifyAuthorization}
        />
      </div>
    </div>
  );
};

export default ShopsList;
