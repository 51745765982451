import React from 'react';

import NoPaidStatus from './NoPaidStatus/NoPaidStatus';

import { dateStringToNewYorkDate } from 'Util/dateCreator';
import Text from 'Atoms/Text/Text';
import { NOT_REQUIRED, NOT_PAID, PAID, REFUNDED } from 'Constants/general';

interface Props {
  status: string;
  transactionId: string;
  paidAt: any;
}

const PaymentStatus: React.FC<Props> = (props) => {
  const { status, transactionId, paidAt } = props;

  const createTitle = () => {
    switch (status) {
      case PAID:
      case NOT_REQUIRED:
        return (
          <Text
            msg="admin_transactions.payment_status_paid"
            data-test="paymentStatus:text:paymentStatusPaid"
          />
        );
      case REFUNDED:
        return (
          <Text
            msg="admin_transactions.payment_status_refund"
            data-test="paymentStatus:text:paymentStatusRefund"
          />
        );
      case NOT_PAID:
        return <NoPaidStatus transactionId={transactionId} />;
      default:
        return null;
    }
  };

  const createDate = () => {
    let text;

    switch (status) {
      case NOT_PAID:
        text = 'N/A';
        break;
      default:
        text = dateStringToNewYorkDate(paidAt);
    }

    return <Text size="sm" color="grayDog" text={text} data-test="paymentStatus:text:unknown" />;
  };

  return (
    <div>
      {createTitle()}
      {createDate()}
    </div>
  );
};

export default PaymentStatus;
