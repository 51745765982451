import React, { Suspense } from 'react';
import { createPaginationContainer, graphql, RelayPaginationProp } from 'react-relay';

import styles from './SearchResults.pcss';
import List from './List/List';
import AdminAdditionalControls from './AdminAdditionalControls/AdminAdditionalControls';
import CreatorsCount from './CreatorsCount/CreatorsCount';

import LoaderHandler from 'Organisms/LoaderHandler/LoaderHandler';
import { SearchResultsContainerQuery$variables } from 'GraphTypes/SearchResultsContainerQuery.graphql';
import { SearchResults_organization$data } from 'GraphTypes/SearchResults_organization.graphql';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { amplitude } from 'Helpers/amplitude';

interface Props {
  isAdmin: boolean;
  campaignId: string;
  query: SearchResultsContainerQuery$variables['searchQueryInput'];
  hasContentCreation: boolean;
  canUsePaidSocial?: boolean;
  isBlockedView?: boolean;
  relay: RelayPaginationProp;
  organization?: SearchResults_organization$data;
  preloader: JSX.Element;
  organizationId?: string;
  showUnlockCreatorBanner: () => void;
}

const COUNT = 24;
const SCROLL_OFFSET = 600;

const SearchResults: React.FC<Props> = (props) => {
  const {
    relay,
    campaignId,
    organization,
    query,
    isAdmin,
    canUsePaidSocial,
    hasContentCreation,
    preloader,
    organizationId = '',
    isBlockedView,
    showUnlockCreatorBanner,
  } = props;

  const creatorTypes = query?.creatorTypes;
  const creatorType = creatorTypes?.length === 1 ? creatorTypes[0] : null;
  const searchCreators = organization?.searchCreators;

  const handleShowMoreClick = () => {
    showUnlockCreatorBanner();
    amplitude.sendEvent<362>({
      id: '362',
      category: 'marketplace',
      name: 'show_more_click',
      param: undefined,
    });
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Suspense fallback={null}>
          <CreatorsCount
            campaignId={campaignId}
            organizationId={organizationId}
            searchQueryInput={query}
          />
        </Suspense>
      </div>
      <LoaderHandler
        relay={relay}
        count={COUNT}
        offset={SCROLL_OFFSET}
        containerId="resultsContainer"
        preloader={preloader}
        blocked={isBlockedView}
      >
        <List
          isAdmin={isAdmin}
          searchCreators={searchCreators}
          campaignId={campaignId}
          hasContentCreation={hasContentCreation}
          className={styles.list}
          creatorType={creatorType}
          canUsePaidSocial={canUsePaidSocial}
          organizationId={organizationId}
          isBlockedView={isBlockedView}
          showUnlockCreatorBanner={showUnlockCreatorBanner}
        />
      </LoaderHandler>
      {isBlockedView && relay.hasMore() && (
        <div className={styles.showMore} onClick={handleShowMoreClick}>
          <AlterButton
            type="black"
            icon="Lock"
            iconPosition="left"
            msg="browse_creators.blocked.show_more"
            data-test="searchResults:alterButton:showMore"
          />
        </div>
      )}
    </div>
  );
};

export default createPaginationContainer(
  SearchResults,
  {
    organization: graphql`
      fragment SearchResults_organization on Organization
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 24 }
        cursor: { type: "String" }
        query: { type: "SearchQueryInput!" }
      ) {
        id
        searchCreators(first: $count, after: $cursor, query: $query)
          @connection(key: "SearchResults_searchCreators", filters: []) {
          edges {
            node {
              creator {
                id
              }
              project {
                id
              }
              ...SearchResult_result
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.organization && props.organization.searchCreators;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
        query: props.query,
        organizationId: props.organizationId,
      };
    },
    query: graphql`
      query SearchResultsPaginationQuery(
        $organizationId: ID!
        $count: Int!
        $cursor: String
        $query: SearchQueryInput!
      ) {
        organization(id: $organizationId) {
          ...SearchResults_organization @arguments(count: $count, cursor: $cursor, query: $query)
        }
      }
    `,
  }
);
