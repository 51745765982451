/**
 * @generated SignedSource<<f62f2d632462c850cf9c30cad82cc092>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UserType = "ADVERTISER" | "CONTRACTOR" | "EPHEMERAL" | "UNKNOWN";
export type UnreadProjectsCountQuery$variables = Record<PropertyKey, never>;
export type UnreadProjectsCountQuery$data = {
  readonly currentUser: {
    readonly organization: {
      readonly projects: {
        readonly totalCount: number;
      } | null | undefined;
    } | null | undefined;
    readonly type: UserType;
  } | null | undefined;
};
export type UnreadProjectsCountQuery = {
  response: UnreadProjectsCountQuery$data;
  variables: UnreadProjectsCountQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "stages",
      "value": [
        "ACCEPTED",
        "LAUNCHED",
        "CONTENT_SUBMITTED",
        "PUBLISHING_STARTED",
        "COMPLETED"
      ]
    },
    {
      "kind": "Literal",
      "name": "withUnreadEvents",
      "value": true
    }
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": "projects(stages:[\"ACCEPTED\",\"LAUNCHED\",\"CONTENT_SUBMITTED\",\"PUBLISHING_STARTED\",\"COMPLETED\"],withUnreadEvents:true)"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UnreadProjectsCountQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UnreadProjectsCountQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b3bc47376186b7d9b6ff2f3177f35397",
    "id": null,
    "metadata": {},
    "name": "UnreadProjectsCountQuery",
    "operationKind": "query",
    "text": "query UnreadProjectsCountQuery {\n  currentUser {\n    type\n    organization {\n      projects(withUnreadEvents: true, stages: [ACCEPTED, LAUNCHED, CONTENT_SUBMITTED, PUBLISHING_STARTED, COMPLETED]) {\n        totalCount\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "fec461f61eb04ce2ea95058ae8d8c148";

export default node;
