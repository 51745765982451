/**
 * @generated SignedSource<<a89201335d34a64f89df5ab6539608bb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ContentLicensing = "ALIENATION_OF_EXCLUSIVE_RIGHT" | "EXCLUSIVE_LICENSE" | "NONE" | "NON_EXCLUSIVE_LICENSE";
export type ContentLicensingPeriod = "LIMITED" | "UNLIMITED";
export type ContentLicensingTerritory = "GLOBAL" | "RUSSIA";
export type ContentLicensingUsageOption = "BROADCASTING" | "PUBLIC_DISPLAY_OF_MATERIALS" | "PUBLISHING_ON_INTERNET" | "REPRODUCTION_OF_MATERIALS";
export type ProductDeliveryOption = "BRAND_WILL_HANDLE_SHIPMENT_TO_CREATORS" | "BRAND_WILL_REFUND_THE_PURCHASED_PRODUCT" | "COLLABORATION_WILL_NOT_REQUIRE_PRODUCT_SHIPMENT" | "TTS_OTHER" | "TTS_SEND_SAMPLE_TO_CREATOR" | "TTS_SEND_SAMPLE_TO_CREATOR_WITHOUT_APPROVAL";
export type UpdateBriefInput = {
  additionalInfo?: string | null | undefined;
  brandAccountName?: string | null | undefined;
  clientMutationId?: string | null | undefined;
  contentLicensingEndDate?: any | null | undefined;
  contentLicensingPeriod?: ContentLicensingPeriod | null | undefined;
  contentLicensingStartDate?: any | null | undefined;
  contentLicensingTerritory?: ContentLicensingTerritory | null | undefined;
  contentLicensingType?: ContentLicensing | null | undefined;
  contentLicensingUsageOptions?: ReadonlyArray<ContentLicensingUsageOption> | null | undefined;
  creatorsCountFrom?: number | null | undefined;
  creatorsCountTo?: number | null | undefined;
  endDate?: any | null | undefined;
  eventAttendance?: boolean | null | undefined;
  eventDescription?: string | null | undefined;
  hashtags?: string | null | undefined;
  hiringGoal?: number | null | undefined;
  id: string;
  includeReferralFee?: boolean | null | undefined;
  paidSocial?: boolean | null | undefined;
  paidSocialActivationDays?: number | null | undefined;
  paidSocialBca?: boolean | null | undefined;
  priceCap?: number | null | undefined;
  priceLimitMax?: number | null | undefined;
  priceLimitMin?: number | null | undefined;
  productDelivery?: ProductDeliveryOption | null | undefined;
  productDescription?: string | null | undefined;
  productPrice?: number | null | undefined;
  productShipmentRequired?: boolean | null | undefined;
  productTitle?: string | null | undefined;
  productUrl?: string | null | undefined;
  publishingRequired?: boolean | null | undefined;
  referralFeeRate?: number | null | undefined;
  sampleDescription?: string | null | undefined;
  sampleProvision?: boolean | null | undefined;
  screeningQuestion?: string | null | undefined;
  sparkAds?: boolean | null | undefined;
  sparkAdsActivationDays?: number | null | undefined;
  startDate?: any | null | undefined;
  taskDescription?: string | null | undefined;
  tiktokShop_ProductId?: string | null | undefined;
  tiktokShop_ShopId?: string | null | undefined;
  unlimitedDuration?: boolean | null | undefined;
};
export type UpdateBriefMutation$variables = {
  input: UpdateBriefInput;
};
export type UpdateBriefMutation$data = {
  readonly updateBrief: {
    readonly campaign: {
      readonly brief: {
        readonly additionalInfo?: string | null | undefined;
        readonly brandAccountName?: string | null | undefined;
        readonly contentLicensingEndDate?: any | null | undefined;
        readonly contentLicensingPeriod?: ContentLicensingPeriod | null | undefined;
        readonly contentLicensingStartDate?: any | null | undefined;
        readonly contentLicensingTerritory?: ContentLicensingTerritory | null | undefined;
        readonly contentLicensingType?: ContentLicensing;
        readonly contentLicensingUsageOptions?: ReadonlyArray<ContentLicensingUsageOption> | null | undefined;
        readonly creatorsCountFrom?: number | null | undefined;
        readonly creatorsCountTo?: number | null | undefined;
        readonly endDate?: any | null | undefined;
        readonly eventAttendance?: boolean;
        readonly eventDescription?: string | null | undefined;
        readonly hashtags?: string | null | undefined;
        readonly hiringGoal?: number | null | undefined;
        readonly includeReferralFee?: boolean | null | undefined;
        readonly paidSocial?: boolean;
        readonly paidSocialActivationDays?: number | null | undefined;
        readonly paidSocialBca?: boolean;
        readonly priceCap?: number | null | undefined;
        readonly priceLimitMax?: number | null | undefined;
        readonly priceLimitMin?: number | null | undefined;
        readonly productDelivery?: ProductDeliveryOption | null | undefined;
        readonly productDescription?: string | null | undefined;
        readonly productPrice?: number | null | undefined;
        readonly productShipmentRequired?: boolean | null | undefined;
        readonly productTitle?: string | null | undefined;
        readonly productUrl?: string | null | undefined;
        readonly publishingRequired?: boolean | null | undefined;
        readonly referralFeeRate?: number | null | undefined;
        readonly sampleDescription?: string | null | undefined;
        readonly sampleProvision?: boolean;
        readonly screeningQuestion?: string | null | undefined;
        readonly sparkAds?: boolean;
        readonly sparkAdsActivationDays?: number | null | undefined;
        readonly startDate?: any | null | undefined;
        readonly taskDescription?: string | null | undefined;
        readonly tiktokShop_ProductId?: string | null | undefined;
        readonly tiktokShop_ShopId?: string | null | undefined;
        readonly unlimitedDuration?: boolean | null | undefined;
      } | null | undefined;
      readonly id: string;
    };
  } | null | undefined;
};
export type UpdateBriefMutation = {
  response: UpdateBriefMutation$data;
  variables: UpdateBriefMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publishingRequired",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "includeReferralFee",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "referralFeeRate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "priceCap",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "additionalInfo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "brandAccountName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hashtags",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unlimitedDuration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creatorsCountFrom",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creatorsCountTo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sampleProvision",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sampleDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventAttendance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paidSocial",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paidSocialActivationDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paidSocialBca",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productShipmentRequired",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productDelivery",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "screeningQuestion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sparkAds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sparkAdsActivationDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "taskDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentLicensingType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentLicensingTerritory",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentLicensingStartDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentLicensingEndDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentLicensingUsageOptions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentLicensingPeriod",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "priceLimitMin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "priceLimitMax",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hiringGoal",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tiktokShop_ShopId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tiktokShop_ProductId",
      "storageKey": null
    }
  ],
  "type": "V2Brief",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateBriefMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateBriefPayload",
        "kind": "LinkedField",
        "name": "updateBrief",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "brief",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateBriefMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateBriefPayload",
        "kind": "LinkedField",
        "name": "updateBrief",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "brief",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a26a6dbe5289b9c752d3ac330dfd6d7e",
    "id": null,
    "metadata": {},
    "name": "UpdateBriefMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateBriefMutation(\n  $input: UpdateBriefInput!\n) {\n  updateBrief(input: $input) {\n    campaign {\n      id\n      brief {\n        __typename\n        ... on V2Brief {\n          publishingRequired\n          includeReferralFee\n          referralFeeRate\n          priceCap\n          additionalInfo\n          brandAccountName\n          hashtags\n          unlimitedDuration\n          creatorsCountFrom\n          creatorsCountTo\n          sampleProvision\n          sampleDescription\n          productUrl\n          productTitle\n          productPrice\n          productDescription\n          eventAttendance\n          eventDescription\n          startDate\n          endDate\n          paidSocial\n          paidSocialActivationDays\n          paidSocialBca\n          productShipmentRequired\n          productDelivery\n          screeningQuestion\n          sparkAds\n          sparkAdsActivationDays\n          taskDescription\n          contentLicensingType\n          contentLicensingTerritory\n          contentLicensingStartDate\n          contentLicensingEndDate\n          contentLicensingUsageOptions\n          contentLicensingPeriod\n          priceLimitMin\n          priceLimitMax\n          hiringGoal\n          tiktokShop_ShopId\n          tiktokShop_ProductId\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "92ec7397e1cd6b33f8c82ee12c91248e";

export default node;
