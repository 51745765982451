import React, { useEffect, Suspense } from 'react';
import { graphql, useQueryLoader } from 'react-relay';
import track from 'react-tracking';

import { DELIVERABLES, JUST_CONTENT, POSTING_CONTENT } from '../NewCampaignTabs/util';

import styles from './Results.pcss';
import CampaignCreatives from './CampaignCreatives/CampaignCreatives';

import Spinner from 'Atoms/Spinner/Spinner';
import { ResultsQuery as QueryType } from 'GraphTypes/ResultsQuery.graphql';
import { amplitude } from 'Helpers/amplitude';
import { CampaignQuery$data } from 'GraphTypes/CampaignQuery.graphql';

export const ResultsQuery = graphql`
  query ResultsQuery($campaignId: ID!, $publishedPosts: Boolean) {
    ...CampaignCreatives_campaign
      @arguments(campaignId: $campaignId, publishedPosts: $publishedPosts)
  }
`;

interface Props {
  campaignId: string;
  section: string;
  currentUser: CampaignQuery$data['currentUser'];
  isAdminView: boolean;
  campaignPublishing: NonNullable<CampaignQuery$data['campaign']>['publishingRequired'];
  campaignType: NonNullable<CampaignQuery$data['campaign']>['type'];
}

const Results: React.FC<Props> = (props) => {
  const { campaignId, section, currentUser, isAdminView, campaignPublishing, campaignType } = props;

  const canChatWithCreators = Boolean(currentUser?.organization?.subscription?.canChatWithCreators);

  const [queryReference, loadQuery] = useQueryLoader<QueryType>(ResultsQuery);

  const sectionValue =
    section === DELIVERABLES
      ? campaignType === 'CONTENT_CREATION'
        ? JUST_CONTENT
        : POSTING_CONTENT
      : section;

  let publishedPostsValue = undefined;
  if (sectionValue === JUST_CONTENT) {
    publishedPostsValue = false;
  } else if (sectionValue === POSTING_CONTENT) {
    publishedPostsValue = true;
  }

  useEffect(() => {
    amplitude.sendEvent({
      id: 146,
      name: 'results',
      category: 'pageview',
      param: {
        campaign_id: campaignId,
        organicPosting: campaignPublishing,
      },
    });
  }, []);

  useEffect(() => {
    loadQuery({ campaignId, publishedPosts: publishedPostsValue }, { fetchPolicy: 'network-only' });
  }, [campaignId, publishedPostsValue]);

  return (
    <div className={styles.root}>
      <Suspense fallback={<Spinner className={styles.preloader} />}>
        {queryReference && (
          <div className={styles.root}>
            <div className={styles.container}>
              <CampaignCreatives
                section={sectionValue}
                campaignId={campaignId}
                publishedPosts={publishedPostsValue}
                queryReference={queryReference}
                admin={isAdminView}
                canChatWithCreators={canChatWithCreators}
              />
            </div>
          </div>
        )}
      </Suspense>
    </div>
  );
};

export default track({ page: 'results' }, { dispatchOnMount: true })(Results);
