/**
 * @generated SignedSource<<c7e342b9ddc08e0cec944a00196f5b42>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Currency = "CAD" | "RUB" | "USD";
export type CreatorShippingDetailsQuery$variables = {
  projectId: string;
};
export type CreatorShippingDetailsQuery$data = {
  readonly project: {
    readonly campaign: {
      readonly id: string;
      readonly organization: {
        readonly paymentAccount: {
          readonly balance: number;
          readonly currency: Currency;
        } | null | undefined;
      } | null | undefined;
      readonly shopifyAuthorization: {
        readonly id: string;
      } | null | undefined;
      readonly showShipmentTab: boolean;
    };
    readonly launch: {
      readonly id: string;
    } | null | undefined;
    readonly offer: {
      readonly acceptance: {
        readonly shippingInformation: string | null | undefined;
        readonly shippingRecipientFirstName: string | null | undefined;
        readonly shippingRecipientLastName: string | null | undefined;
        readonly shippingRecipientPhone: string | null | undefined;
      } | null | undefined;
    } | null | undefined;
    readonly shippingAddress: {
      readonly address1: string;
      readonly address2: string | null | undefined;
      readonly city: string;
      readonly country: {
        readonly iso2Code: string;
        readonly name: string;
        readonly provinces: ReadonlyArray<{
          readonly code: string;
          readonly name: string;
        }> | null | undefined;
      } | null | undefined;
      readonly firstName: string;
      readonly id: string;
      readonly lastName: string;
      readonly phone: string;
      readonly provinceCode: string | null | undefined;
      readonly zip: string;
    } | null | undefined;
  } | null | undefined;
};
export type CreatorShippingDetailsQuery = {
  response: CreatorShippingDetailsQuery$data;
  variables: CreatorShippingDetailsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shippingInformation",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shippingRecipientFirstName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shippingRecipientLastName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shippingRecipientPhone",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address1",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address2",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "iso2Code",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Province",
  "kind": "LinkedField",
  "name": "provinces",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    (v10/*: any*/)
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "provinceCode",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "zip",
  "storageKey": null
},
v18 = [
  (v6/*: any*/)
],
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectLaunch",
  "kind": "LinkedField",
  "name": "launch",
  "plural": false,
  "selections": (v18/*: any*/),
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "ShopifyAuthorization",
  "kind": "LinkedField",
  "name": "shopifyAuthorization",
  "plural": false,
  "selections": (v18/*: any*/),
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showShipmentTab",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "balance",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreatorShippingDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Offer",
            "kind": "LinkedField",
            "name": "offer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OfferAcceptance",
                "kind": "LinkedField",
                "name": "acceptance",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ShippingAddress",
            "kind": "LinkedField",
            "name": "shippingAddress",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Country",
                "kind": "LinkedField",
                "name": "country",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/)
            ],
            "storageKey": null
          },
          (v19/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Payment_Account",
                    "kind": "LinkedField",
                    "name": "paymentAccount",
                    "plural": false,
                    "selections": [
                      (v22/*: any*/),
                      (v23/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreatorShippingDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Offer",
            "kind": "LinkedField",
            "name": "offer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OfferAcceptance",
                "kind": "LinkedField",
                "name": "acceptance",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ShippingAddress",
            "kind": "LinkedField",
            "name": "shippingAddress",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Country",
                "kind": "LinkedField",
                "name": "country",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/)
            ],
            "storageKey": null
          },
          (v19/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Payment_Account",
                    "kind": "LinkedField",
                    "name": "paymentAccount",
                    "plural": false,
                    "selections": [
                      (v22/*: any*/),
                      (v23/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dd049319a888c04350a473b56da3bde2",
    "id": null,
    "metadata": {},
    "name": "CreatorShippingDetailsQuery",
    "operationKind": "query",
    "text": "query CreatorShippingDetailsQuery(\n  $projectId: ID!\n) {\n  project(id: $projectId) {\n    offer {\n      acceptance {\n        shippingInformation\n        shippingRecipientFirstName\n        shippingRecipientLastName\n        shippingRecipientPhone\n        id\n      }\n      id\n    }\n    shippingAddress {\n      id\n      address1\n      address2\n      city\n      country {\n        name\n        iso2Code\n        provinces {\n          code\n          name\n        }\n        id\n      }\n      firstName\n      lastName\n      phone\n      provinceCode\n      zip\n    }\n    launch {\n      id\n    }\n    campaign {\n      id\n      shopifyAuthorization {\n        id\n      }\n      showShipmentTab\n      organization {\n        paymentAccount {\n          balance\n          currency\n          id\n        }\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "40dae31e17bf041c0a18ccee8b9b45a8";

export default node;
