import { graphql, requestSubscription } from 'react-relay';

import environment from 'Api/Environment';
import { ProjectUnreadCountChangedSubscription } from 'GraphTypes/ProjectUnreadCountChangedSubscription.graphql';

const subscription = graphql`
  subscription ProjectUnreadCountChangedSubscription {
    projectUnreadCountChanged {
      id
      events {
        unreadCount
      }
      campaign {
        projectEvents {
          unreadCount
        }
        archiveEvents: projectEvents(archived: true) {
          unreadCount
        }
        acceptedProjects: projects(stages: [ACCEPTED], archived: false, withUnreadEvents: true) {
          totalCount
        }
        launchedProjects: projects(stages: [LAUNCHED], archived: false, withUnreadEvents: true) {
          totalCount
        }
        contentSubmittedProjects: projects(
          stages: [CONTENT_SUBMITTED]
          archived: false
          withUnreadEvents: true
        ) {
          totalCount
        }
        publishingStartedProjects: projects(
          stages: [PUBLISHING_STARTED]
          archived: false
          withUnreadEvents: true
        ) {
          totalCount
        }
        completedProjects: projects(stages: [COMPLETED], archived: false, withUnreadEvents: true) {
          totalCount
        }
        applicantsWithUnreadMesseges: projects(
          stages: [ACCEPTED]
          archived: false
          withUnreadEvents: true
        ) {
          hasItems
        }
        contentCreationUnreadMesseges: projects(
          stages: [LAUNCHED, CONTENT_SUBMITTED, PUBLISHING_STARTED, COMPLETED]
          archived: false
          withUnreadEvents: true
        ) {
          hasItems
        }
        acceptedCounter: projects(
          stages: [ACCEPTED]
          hasBrandMessageBeforeLaunch: false
          archived: false
          withUnreadEvents: true
        ) {
          totalCount
        }
        messagedCounter: projects(
          stages: [ACCEPTED]
          hasBrandMessageBeforeLaunch: true
          archived: false
          withUnreadEvents: true
        ) {
          totalCount
        }
        inProgressCounter: projects(
          stages: [LAUNCHED, CONTENT_SUBMITTED, PUBLISHING_STARTED]
          archived: false
          withUnreadEvents: true
        ) {
          totalCount
        }
        completedCounter: projects(stages: [COMPLETED], archived: false, withUnreadEvents: true) {
          totalCount
        }
      }
    }
  }
`;

export default () => {
  requestSubscription<ProjectUnreadCountChangedSubscription>(environment, {
    subscription,
    variables: {},
  });
};
