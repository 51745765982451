/**
 * @generated SignedSource<<e3738295988dad7e7cd401451d7e5b2c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AdvertiserProjectStage = "ACCEPTED" | "ACCEPTED_AND_NOT_LAUNCHED" | "ACCEPTED_BY_UNREVIEWED_CREATOR" | "COMPLETED" | "CONTENT_SUBMITTED" | "LAUNCHED" | "PRODUCT_SHIPMENT_COMPLETED" | "PRODUCT_SHIPMENT_STARTED" | "PUBLISHING_STARTED" | "READ" | "REJECTED" | "UNREAD" | "UNSENT";
export type CampaignPreviewCountersQuery$variables = {
  campaignId: string;
  stages?: ReadonlyArray<AdvertiserProjectStage> | null | undefined;
  withNewApplicants?: boolean | null | undefined;
  withUnreadEventsExcludingOfferAcceptance?: boolean | null | undefined;
};
export type CampaignPreviewCountersQuery$data = {
  readonly campaign: {
    readonly requestedProjects: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    };
  } | null | undefined;
};
export type CampaignPreviewCountersQuery = {
  response: CampaignPreviewCountersQuery$data;
  variables: CampaignPreviewCountersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "campaignId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "stages"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "withNewApplicants"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "withUnreadEventsExcludingOfferAcceptance"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "campaignId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": "requestedProjects",
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 1
    },
    {
      "kind": "Literal",
      "name": "orderBy",
      "value": "ACTIVITY"
    },
    {
      "kind": "Variable",
      "name": "stages",
      "variableName": "stages"
    },
    {
      "kind": "Variable",
      "name": "withNewApplicants",
      "variableName": "withNewApplicants"
    },
    {
      "kind": "Variable",
      "name": "withUnreadEventsExcludingOfferAcceptance",
      "variableName": "withUnreadEventsExcludingOfferAcceptance"
    }
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Project",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v5/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CampaignPreviewCountersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "CampaignPreviewCountersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "60e73c39465f742de9f0e2f7e4335a1b",
    "id": null,
    "metadata": {},
    "name": "CampaignPreviewCountersQuery",
    "operationKind": "query",
    "text": "query CampaignPreviewCountersQuery(\n  $campaignId: ID!\n  $withNewApplicants: Boolean\n  $withUnreadEventsExcludingOfferAcceptance: Boolean\n  $stages: [AdvertiserProjectStage!]\n) {\n  campaign(id: $campaignId) {\n    requestedProjects: projects(first: 1, withNewApplicants: $withNewApplicants, withUnreadEventsExcludingOfferAcceptance: $withUnreadEventsExcludingOfferAcceptance, orderBy: ACTIVITY, stages: $stages) {\n      edges {\n        node {\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "4b17af1d27afb6f5db24260f7a0d7a76";

export default node;
