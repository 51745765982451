import React, { useContext } from 'react';
import { graphql, useFragment } from 'react-relay';
import { Element } from 'react-scroll';

import With from '../../components/With/With';
import { BriefContext } from '../../../../Brief.Context';

import styles from './TiktokShop.pcss';

import { useTikTokShopAuth } from 'Hooks/useTikTokShopAuth';
import Button from 'Components/ui/Button/Button';
import Text from 'Components/ui/Text/Text';
import TextButton from 'Components/ui/TextButton/TextButton';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import {
  TiktokShop_campaign$data,
  TiktokShop_campaign$key,
} from 'GraphTypes/TiktokShop_campaign.graphql';

interface Props {
  campaign: TiktokShop_campaign$key;
  ttShopVisible: boolean;
  onChange: (data: Record<string, unknown>) => void;
}

const TiktokShop: React.FC<Props> = (props) => {
  const { ttShopVisible, campaign, onChange } = props;

  const { openDrawer } = useContext(DrawerContext);

  const data = useFragment(
    graphql`
      fragment TiktokShop_campaign on Campaign {
        brief {
          __typename
          ... on V2Brief {
            tiktokShop_ShopId
            tiktokShop_ProductId
          }
        }
        organization {
          tiktokShopLinked
        }
      }
    `,
    campaign
  );

  const [briefState] = useContext(BriefContext);

  const { showErrors } = briefState;

  if (data.brief?.__typename !== 'V2Brief') return null;

  const tiktokShop_ShopId = data.brief.tiktokShop_ShopId;

  const handleShowProductList = () => {
    openDrawer('tt-shop-product-list');
  };

  const handleTTShopDelete = () => {
    onChange({ tiktokShop_ProductId: null, tiktokShop_ShopId: null });
  };

  return (
    <div>
      <With condition={ttShopVisible} name="tiktokShopProduct">
        <Element name="tiktokShopProduct">
          <Button
            color="black"
            fluid
            msg="brief_template.field.product.select_tiktokshop_product.title"
            onClick={handleShowProductList}
            data-test="tiktokShop:button:title"
          />
          {showErrors && !tiktokShop_ShopId && (
            <Text
              color="red"
              type="md"
              msg="form.error_required"
              className={styles.error}
              data-test="tiktokShop:text:errorRequired"
            />
          )}
        </Element>
      </With>
      {tiktokShop_ShopId && (
        <div className={styles.ttShop}>
          <Text
            type="md"
            text={`Tiktok Shop: ${tiktokShop_ShopId}`}
            data-test="tiktokShop:text:unknown"
          />
          <TextButton
            icon="Close-small"
            onClick={handleTTShopDelete}
            data-test="tiktokShop:textButton:unknown"
          />
        </div>
      )}
    </div>
  );
};

export default TiktokShop;
