import React, { useState, useMemo, ChangeEvent } from 'react';

import styles from './InviteTeamMembers.pcss';

import Text from 'Components/ui/Text/Text';
import Drawer from 'Components/ui/Drawer/Drawer';
import { checkEmailValid } from 'Util/validate';
import { copyText } from 'Util/copyText';
import { createURLSilently } from 'Util/createURLSilently';
import { amplitude } from 'Helpers/amplitude';
import { useSendInviteTeammateLinkMutation } from 'Mutations/SendInviteTeammateLink.Mutation';
import createOrganizationInvite from 'Mutations/CreateOrganizationInvite.Mutation';
import { CreateOrganizationInviteMutation$data } from 'GraphTypes/CreateOrganizationInviteMutation.graphql';
import Button from 'Components/ui/Button/Button';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import Input from 'Components/ui/Input/Input';

interface Props {
  organizationId: string;
  organizationName?: string;
  leftSeats?: number;
  drawerName?: string;
}

const InviteTeamMembers: React.FC<Props> = (props) => {
  const { organizationId, organizationName, leftSeats, drawerName } = props;

  const [disabled, setDisabled] = useState(false);
  const [email, setEmail] = useState('');
  const [invitationSent, setInvitationSent] = useState(false);

  const [sendInviteTeammateLink, isLoading] = useSendInviteTeammateLinkMutation();

  const isValidEmail = useMemo(() => {
    return checkEmailValid(email);
  }, [email]);

  const handleLinkCopy = async () => {
    setDisabled(true);
    const result = await new Promise<CreateOrganizationInviteMutation$data>((resolve, reject) => {
      createOrganizationInvite({ organizationId }, resolve, reject);
    });
    const newLink = result.createOrganizationInvite?.organizationInvite?.signupUrl || '';
    amplitude.sendEvent<241>({
      id: '241',
      category: 'pageview',
      name: 'invite_teammates_copy_link_click',
      param: { referral_url: newLink, organization_id: organizationId },
    });
    const url = createURLSilently(newLink);
    if (url) {
      url.searchParams.set('organizationId', organizationId || '');
      url.searchParams.set('organizationName', organizationName || '');
      copyText(url.toString());
    }
    setTimeout(() => {
      setDisabled(false);
    }, 2000);
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleInputFocus = () => {
    if (invitationSent) {
      setInvitationSent(false);
    }
  };

  const handleEmailSend = () => {
    sendInviteTeammateLink({
      variables: {
        input: {
          email,
          organizationId,
        },
      },
      onCompleted: () => {
        setEmail('');
        setInvitationSent(true);
      },
    });
  };

  return (
    <Drawer rootKey={drawerName || 'invite-team-member'} className={styles.drawer}>
      <div className={styles.drawerContainer}>
        <Text type="d2" msg="invite_team_memebers_modal.title" className={styles.title} />
        <Text type="md" msg="invite_team_memebers_modal.descr" />
        <div className={styles.emailWrap}>
          <Input
            value={email}
            labelMsg="invite_team_memebers_modal.email"
            placeholderMsg="invite_team_memebers_modal.email"
            className={styles.input}
            bordered
            onFocus={handleInputFocus}
            onChange={handleEmailChange}
          />
          <Button
            msg={
              invitationSent
                ? 'invite_team_memebers_modal.invitation_sent'
                : 'invite_team_memebers_modal.send'
            }
            icon={invitationSent ? 'Check' : 'Arrow-big-right'}
            iconPosition="right"
            color={invitationSent ? 'green' : 'black'}
            loading={isLoading}
            disabled={invitationSent ? undefined : !isValidEmail}
            onClick={invitationSent ? undefined : handleEmailSend}
          />
        </div>
        <Text type="d2" msg="invite_team_memebers_modal.invite_link" className={styles.title} />
        <Tooltip
          id="link_copied"
          place="top"
          showByDefault={true}
          tooltipMsg={disabled ? 'invite_team_memebers_modal.link_copied' : undefined}
          className={styles.linkWrap}
        >
          <Button
            color="white"
            msg="general.copy_link"
            icon="Copy"
            onClick={handleLinkCopy}
            className={styles.link}
            disabled={disabled}
          />
        </Tooltip>
        {Number(leftSeats) > 0 && (
          <Text
            type="sm"
            msg="invite_team_memebers_modal.users_seats"
            formatValues={{ seats: leftSeats }}
            className={styles.seatsDesr}
          />
        )}
      </div>
    </Drawer>
  );
};

export default InviteTeamMembers;
