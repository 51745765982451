import compact from 'lodash/compact';

import { ControlItem } from '../components/SegmentedControls/SegmentedControls';

import { Deliverables_campaign$data } from 'GraphTypes/Deliverables_campaign.graphql';

export const getPostingTypesList = (data: { campaignId: string }): ControlItem[] => {
  const { campaignId } = data;

  return [
    {
      id: 'required',
      value: true,
      titleMsg: 'brief_template.product_seeding.posting.required',
      tooltipMsg: 'brief_template.creative_asset.posting.required.tooltip',
      dataTest: 'button:deliverables:posting',
      tooltipEventData: {
        id: '496',
        category: 'brief',
        name: 'deliverables_posting_tooltip_show',
        param: { info: 'posting', campaign_id: campaignId },
      },
    },
    {
      id: 'not_required',
      value: false,
      titleMsg: 'brief_template.product_seeding.posting.not_required',
      tooltipMsg: 'brief_template.creative_asset.posting.not_required.tooltip',
      dataTest: 'button:deliverables:notRequired',
      tooltipEventData: {
        id: '496',
        category: 'brief',
        name: 'deliverables_posting_tooltip_show',
        param: { info: 'no_posting', campaign_id: campaignId },
      },
    },
  ];
};

export const checkValidation = (
  campaign: Deliverables_campaign$data,
  descriptionPreset: string
): string[] => {
  const { brief } = campaign;

  const publishingRequiredFilled = typeof brief?.publishingRequired === 'boolean';
  const taskDescriptionFilled =
    brief?.taskDescription && brief.taskDescription !== descriptionPreset;

  const errors = compact([
    taskDescriptionFilled ? undefined : 'taskDescription',
    publishingRequiredFilled ? undefined : 'publishingRequired',
  ]);

  return errors;
};
