import React, { ChangeEvent, useContext, useDeferredValue, useEffect, useState } from 'react';

import { InviteOutreachCreatorsContext } from '../InviteOutreachCreatorsContext';
import { DEFAULT_DAILY_EMAIL_LIMIT } from '../InviteOutreachCreatorsProvider';

import styles from './DailyEmailLimitInput.pcss';

import Text from 'Components/ui/Text/Text';

const DailyEmailLimitInput: React.FC = () => {
  const { emailDailyLimit, setEmailDailyLimit } = useContext(InviteOutreachCreatorsContext);

  const [limitText, setLimitText] = useState<number>(emailDailyLimit.value);

  const limitTextValue = useDeferredValue(limitText);

  useEffect(() => {
    if (limitTextValue) {
      setEmailDailyLimit({
        value: limitTextValue,
        changed: true,
      });
    }
  }, [limitTextValue]);

  const handleLimitTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    if (/^\d*$/.test(value)) {
      const num = Number(value);
      if (value === '' || (num >= 1 && num <= DEFAULT_DAILY_EMAIL_LIMIT)) {
        setLimitText(num);
      }
    }
  };

  return (
    <div className={styles.root}>
      <Text type="md" msg="invite_outreach_creators_modal.email_limit.title" />
      <input
        className={styles.input}
        type="number"
        value={limitText}
        onChange={handleLimitTextChange}
      />
    </div>
  );
};

export default DailyEmailLimitInput;
