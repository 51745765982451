import React, { FC, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ResetPasswordForm } from './ResetPasswordForm';
import { SignUpForm } from './SignUpForm';
import { SignInForm } from './SignInForm';
import { ContractorSignUpForm } from './ContractorSignUpForm';

import { UserType } from 'GraphTypes/HeaderQuery.graphql';

export enum AuthFormType {
  SIGNIN,
  SIGNUP,
  FORGOT_PASSWORD,
}

interface Props {
  userType?: UserType;
}

export type FormErrorType = {
  name?: string;
  password?: string;
  email?: string;
  city?: string;
};

export const FirebaseAuth: FC<Props> = ({ userType }) => {
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get('organizationId');
  const [formType, setFormType] = useState<AuthFormType>(
    orgId ? AuthFormType.SIGNUP : AuthFormType.SIGNIN
  );

  const changeFormType = (formType: AuthFormType) => {
    setFormType(formType);
  };

  if (userType === 'CONTRACTOR') {
    return <ContractorSignUpForm changeFormType={changeFormType} />;
  }

  if (formType === AuthFormType.FORGOT_PASSWORD) {
    return <ResetPasswordForm changeFormType={changeFormType} />;
  } else if (formType === AuthFormType.SIGNUP) {
    return <SignUpForm changeFormType={changeFormType} />;
  }
  return <SignInForm changeFormType={changeFormType} />;
};
