/**
 * @generated SignedSource<<d0615451e3e549164201a07d3ebacffd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CreatorType = "CONTENT" | "INSTAGRAM" | "TIKTOK" | "YOUTUBE";
import { FragmentRefs } from "relay-runtime";
export type BlockedCreator_creator$data = {
  readonly channel?: {
    readonly id: string | null | undefined;
    readonly name: string | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly profile: {
    readonly badges: ReadonlyArray<{
      readonly name: string;
    }> | null | undefined;
    readonly countries: ReadonlyArray<{
      readonly name: string;
    }>;
    readonly profilePictureFile: {
      readonly transformations?: {
        readonly collageThumbnailUrl: string;
      } | null | undefined;
      readonly url: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly type: CreatorType;
  readonly user?: {
    readonly name: string;
  } | null | undefined;
  readonly username: string;
  readonly " $fragmentSpreads": FragmentRefs<"CreatorDetails_creator">;
  readonly " $fragmentType": "BlockedCreator_creator";
};
export type BlockedCreator_creator$key = {
  readonly " $data"?: BlockedCreator_creator$data;
  readonly " $fragmentSpreads": FragmentRefs<"BlockedCreator_creator">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BlockedCreator_creator",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CreatorProfile",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Badge",
          "kind": "LinkedField",
          "name": "badges",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Country",
          "kind": "LinkedField",
          "name": "countries",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "profilePictureFile",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ImageTransformation",
                  "kind": "LinkedField",
                  "name": "transformations",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "collageThumbnailUrl",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "Image",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreatorDetails_creator"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "YoutubeChannel",
          "kind": "LinkedField",
          "name": "channel",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "YoutubeCreator",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TiktokUser",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "type": "TiktokCreator",
      "abstractKey": null
    }
  ],
  "type": "Creator",
  "abstractKey": "__isCreator"
};
})();

(node as any).hash = "0126522b9dcb80a26dbdd50874f3840b";

export default node;
