import React, { useContext, MouseEvent } from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';
import { useNavigate } from 'react-router-dom';

import { BriefContext } from '../../../../../Brief.Context';

import styles from './BrandSelect.pcss';

import updateCampaign from 'Mutations/UpdateCampaign.Mutation';
import { BrandSelectQuery as QueryType } from 'GraphTypes/BrandSelectQuery.graphql';
import BrandModal from 'Modal/advertiser/BrandModal/BrandModal';
import { PLANS_ROUTE, BILLING_ROUTE } from 'Constants/general';
import Spinner from 'Atoms/Spinner/Spinner';
import Text from 'Components/ui/Text/Text';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownGroupItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Icon from 'Components/ui/Icon/Icon';
import Image from 'Components/ui/Image/Image';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

const BrandSelectQuery = graphql`
  query BrandSelectQuery {
    currentUser {
      admin
      organization {
        counters {
          activeBrands
        }
        subscription {
          planId
          paused
          effectiveLimits {
            maxActiveBrands
          }
        }
      }
    }
    brandCategories {
      id
      name
    }
    brands(first: 2147483647) @connection(key: "Brands_brands") {
      edges {
        node {
          id
          name
          active
          websiteUrl
          ...Brand_brand
          logo {
            id
            ... on Image {
              transformations {
                brandLogoUrl
                collageThumbnailUrl
              }
            }
          }
          summary
          category {
            id
            name
          }
        }
      }
      totalCount
    }
  }
`;

interface Props {
  name?: string | null;
  brandId?: string;
  campaignId: string;
}

const BrandSelect: React.FC<Props> = (props) => {
  const { brandId, name, campaignId } = props;
  const navigate = useNavigate();

  const { openDrawer } = useContext(DrawerContext);

  const data = useLazyLoadQuery<QueryType>(BrandSelectQuery, {});

  const [briefState, briefDispatch] = useContext(BriefContext);

  const { showErrors } = briefState;

  if (!data) return <Spinner />;

  const dropdownLabel = name ? { text: name } : { msg: 'create_campaign.add_brand' };

  const brands = data.brands?.edges || [];
  const brandCategories = data.brandCategories;

  const organization = data.currentUser?.organization;

  const maxActiveBrands = organization?.subscription?.effectiveLimits.maxActiveBrands;
  const planId = organization?.subscription?.planId;
  const activeBrands = organization?.counters?.activeBrands;
  const isPlanPaused = Boolean(organization?.subscription?.paused);
  const isMaxActiveBrandsExceeded =
    typeof maxActiveBrands === 'number' && typeof activeBrands === 'number'
      ? activeBrands >= maxActiveBrands
      : false;

  const leftCount =
    typeof maxActiveBrands === 'number' && typeof activeBrands === 'number'
      ? maxActiveBrands - activeBrands
      : undefined;

  const handleBrandUpdate = (newBrandId?: string) => {
    if (newBrandId) {
      updateCampaign({ id: campaignId, brandId: newBrandId });
    }
  };

  const openBillingPlans = () => {
    navigate(PLANS_ROUTE);
  };

  const openBillingAddons = () => {
    navigate(BILLING_ROUTE, { state: { addon: 'maxActiveBrands' } });
  };

  const handleAddClick = () => {
    if (planId === 'FREE' && !(data.brands?.totalCount === 0)) {
      openBillingPlans();
    } else if (isMaxActiveBrandsExceeded) {
      openBillingAddons();
    } else {
      openDrawer('edit-brand-modal-new');
    }
  };

  return (
    <div className={styles.root}>
      <Dropdown
        closeBySelect
        bordered
        error={showErrors && !brandId}
        className={styles.dropdown}
        value={<Text type="md" {...dropdownLabel} data-test="brandSelect:text:unknown" />}
      >
        <DropdownGroup className={styles.dropdownGroup}>
          <DropdownGroupItem key="new" className={styles.dropdownItem} handleClick={handleAddClick}>
            <AlterButton
              fluid
              type="grey"
              icon="Add-plus"
              disabled={isPlanPaused}
              msg={
                Number(leftCount) >= 0
                  ? 'create_campaign.brand_limit'
                  : 'create_campaign.brand_nolimit'
              }
              formatValues={{ count: typeof leftCount === 'number' ? leftCount : '' }}
              className={styles.addNewBtn}
              data-test="brandSelect:alterButton:addNewBtn"
            />
          </DropdownGroupItem>
          {isPlanPaused && (
            <Text
              type="md"
              msg="brands.dashboard.limits.newBrand.tooltip_pause"
              className={styles.addPauseText}
              data-test="brandSelect:text:tooltipPause"
            />
          )}
          {brands.map((item) => {
            if (!item?.node || !item.node.active) return null;

            const { logo: defaultLogo, id, name = '' } = item.node;

            const logo = defaultLogo?.transformations?.collageThumbnailUrl;

            const handleBrandClick = () => {
              briefDispatch({ key: 'briefIsSaving', value: true });
              updateCampaign({ id: campaignId, brandId: id });
            };

            const handleEditClick = (e: MouseEvent<HTMLDivElement>) => {
              e.stopPropagation();
              e.preventDefault();
              openDrawer(`edit-brand-modal-${id}`);
            };

            return (
              <DropdownGroupItem
                key={id}
                className={styles.dropdownItem}
                handleClick={handleBrandClick}
              >
                <div className={styles.item}>
                  <Image type="round" size={24} src={logo} />
                  <AlterButton
                    fluid
                    hover={false}
                    text={name}
                    className={styles.brandName}
                    data-test="brandSelect:alterButton:brandName"
                  />
                  <Icon name="Edit" onClick={handleEditClick} />
                  {brandId === id && <Icon name="Check" color="green" />}
                </div>
              </DropdownGroupItem>
            );
          })}
        </DropdownGroup>
      </Dropdown>
      {brands.map((item) => {
        if (!item?.node || !item.node.active) return null;
        const {
          logo: defaultLogo,
          id,
          name = '',
          summary = '',
          websiteUrl = '',
          category,
        } = item.node;

        const logo = defaultLogo?.transformations?.collageThumbnailUrl;
        const logoId = defaultLogo?.id;

        return (
          <BrandModal
            id={id}
            logo={logo}
            logoId={logoId}
            name={name}
            summary={summary}
            websiteUrl={websiteUrl}
            brandId={id}
            categoryId={category?.id}
            categoryName={category?.name}
            onBrandSaved={handleBrandUpdate}
            brandCategories={brandCategories}
          />
        );
      })}
      <BrandModal id="new" onBrandSaved={handleBrandUpdate} brandCategories={brandCategories} />
    </div>
  );
};

export default BrandSelect;
