import React from 'react';
import { graphql, useFragment } from 'react-relay';
import compact from 'lodash/compact';

import BarChart from '../components/BarChart/BarChart';
import SubSection from '../components/SubSection/SubSection';

import styles from './AudienceInfo.pcss';
import { generateAudienceData, generateAudienceBarChartsData } from './data';

import Text from 'Atoms/Text/Text';
import { AudienceInfo_creator$key } from 'GraphTypes/AudienceInfo_creator.graphql';

interface Props {
  creator: AudienceInfo_creator$key;
}

const AudienceInfo: React.FC<Props> = (props) => {
  const { creator } = props;

  const data = useFragment(
    graphql`
      fragment AudienceInfo_creator on Creator {
        type
        profile {
          audienceGenders {
            gender {
              name
            }
            percentage
          }
          audienceAgeRanges {
            ageRange {
              name
            }
            percentage
          }
          audienceReachability {
            easy
            hard
            medium
          }
          audienceCities {
            city {
              name
            }
            percentage
          }
          audienceCountries {
            country {
              name
            }
            percentage
          }
          audienceEthnicities {
            ethnicity {
              name
            }
            percentage
          }
          audienceInterests {
            interest {
              name
            }
            percentage
          }
        }
      }
    `,
    creator
  );

  if (!data) return null;

  const audienceDataList = generateAudienceData({ creator: data });

  const audienceDataBarChartsList = generateAudienceBarChartsData({ creator: data });

  if (!(audienceDataList.length > 0 || audienceDataBarChartsList.length > 0)) return null;

  return (
    <div>
      <header className={styles.header}>
        {(audienceDataList.length > 0 || audienceDataBarChartsList.length > 0) && (
          <Text
            tag="h1"
            weight="700"
            msg="creator_statistic.audience.title"
            data-test="audienceInfo:text:title"
          />
        )}
      </header>
      <div className={styles.graphContent}>
        {audienceDataList.map((item) => {
          return (
            <div key={item.title} className={styles.graph}>
              <SubSection {...item} />
            </div>
          );
        })}
      </div>
      <div className={styles.content}>
        {compact(
          audienceDataBarChartsList.map((item) => {
            const { title, tooltipMsg, items } = item;
            if (!items.length) return null;

            return (
              <div key={title} className={styles.bar}>
                <SubSection title={title} tooltipMsg={tooltipMsg}>
                  <BarChart items={items} />
                </SubSection>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default AudienceInfo;
