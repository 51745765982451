import React, { useContext } from 'react';
import { graphql, useFragment } from 'react-relay';
import classnames from 'classnames';

import styles from './DiscoverySearchResult.pcss';
import SearchResultItem from './SearchResultItem/SearchResultItem';

import Text from 'Atoms/Text/Text';
import { SearchResult_result$key } from 'GraphTypes/SearchResult_result.graphql';
import { Checkbox } from 'Components/ui/Checkbox';
import { DiscoveryCreatorsContext } from 'Page/advertiser/Discovery/DiscoveryCreatorsContext';
import { CreatorsSearchContext } from 'Page/advertiser/Mediaplan/SearchSection/CreatorsSearch.Context';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

interface Props {
  className?: string;
  isAdmin: boolean;
  isBlockedView?: boolean;
  hasContentCreation: boolean;
  campaignId: string;
  result: SearchResult_result$key;
  organizationId?: string;
  showUnlockCreatorBanner: () => void;
}

const DiscoverySearchResult: React.FC<Props> = (props) => {
  const { className, result, showUnlockCreatorBanner, isBlockedView } = props;

  const data = useFragment(
    graphql`
      fragment DiscoverySearchResult_result on CreatorSearchResult {
        viewed
        creator {
          type
          id
          username
          profile {
            searchable
            profilePictureFile {
              ... on Image {
                transformations {
                  collageThumbnailUrl
                }
              }
            }
          }
          ...SearchResultItem_creator
        }
      }
    `,
    result.__fragments.DiscoverySearchResult_result ? result : null
  );

  const {
    admin: isAdmin,
    selectedCreators,
    addCreatorToList,
    removeCreatorFromList,
    selectAllMode,
    setOneTimeCreator,
  } = useContext(DiscoveryCreatorsContext);

  const creator = data?.creator;
  const viewed = data?.viewed;
  const searchable = data?.creator?.profile?.searchable;
  const creatorId = creator?.id;
  const creatorName = data?.creator?.username;
  const project = data?.project;
  const archivation = project?.archivation;
  const invitedByOrganizationMember = project?.invitedByOrganizationMember;

  const checked = selectedCreators.some((creator) => creator.id === creatorId) || selectAllMode;

  const { state: creatorsSearchState } = useContext(CreatorsSearchContext);
  const { openDrawer } = useContext(DrawerContext);

  const creatorIdsForExclusion = creatorsSearchState.creatorIdsForExclusion;
  const creatorIdsForExclusionList = creatorIdsForExclusion.map((item) => item.creatorId);

  if (!creator || (creatorId && creatorIdsForExclusionList.includes(creatorId))) return null;

  const isHidden = !searchable && isAdmin;
  const isAddedToMediaplan = !!(
    project &&
    !archivation &&
    (invitedByOrganizationMember || (!invitedByOrganizationMember && isAdmin))
  );

  const handleSelectCreator = () => {
    if (!creatorId || !creatorName) return;
    if (checked) {
      removeCreatorFromList(creatorId);
    } else {
      addCreatorToList(creator);
    }
  };

  const handleAddToListClick = () => {
    openDrawer('select-custom-list-for-creator');
    setOneTimeCreator(creatorId || null);
  };

  return (
    <div
      className={classnames(className, styles.root, {
        [styles.addedToMediaplan]: isAddedToMediaplan,
        [styles.viewed]: viewed,
      })}
      onClick={handleSelectCreator}
    >
      <div className={styles.content}>
        <Checkbox
          checked={checked}
          disabled={selectAllMode}
          data-test="discoverySearchResult:checkbox:unknown"
        />
        <SearchResultItem
          creator={creator}
          isBlockedView={Boolean(isBlockedView)}
          showUnlockCreatorBanner={showUnlockCreatorBanner}
          selected={checked}
          onSelect={handleAddToListClick}
        />
        {isHidden && (
          <Text
            msg="general.hidden"
            className={styles.hiddenState}
            data-test="discoverySearchResult:text:hidden"
          />
        )}
      </div>
    </div>
  );
};

export default DiscoverySearchResult;
