/**
 * @generated SignedSource<<1087ad7dc930a80545a2d8272618d04c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ErrorType = "ALREADY_EXIST_ERROR" | "CREATOR_ALREADY_OWNED_ERROR" | "NOT_CLAIMED" | "NO_CREATOR" | "SIGNIN_ERROR" | "SIGNUP_ERROR" | "VALIDATION_ERROR";
export type UnsubscribeFromEmailNotificationsInput = {
  clientMutationId?: string | null | undefined;
  email: string;
};
export type UnsubscribeFromEmailNotificationsMutation$variables = {
  input: UnsubscribeFromEmailNotificationsInput;
};
export type UnsubscribeFromEmailNotificationsMutation$data = {
  readonly unsubscribeFromEmailNotifications: {
    readonly clientMutationId: string | null | undefined;
    readonly errors: ReadonlyArray<{
      readonly type: ErrorType;
    } | null | undefined> | null | undefined;
  } | null | undefined;
};
export type UnsubscribeFromEmailNotificationsMutation = {
  response: UnsubscribeFromEmailNotificationsMutation$data;
  variables: UnsubscribeFromEmailNotificationsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UnsubscribeFromEmailNotificationsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UnsubscribeFromEmailNotificationsPayload",
        "kind": "LinkedField",
        "name": "unsubscribeFromEmailNotifications",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UnsubscribeFromEmailNotificationsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UnsubscribeFromEmailNotificationsPayload",
        "kind": "LinkedField",
        "name": "unsubscribeFromEmailNotifications",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b0a9576dbe85c4aa11922d0f129f0acf",
    "id": null,
    "metadata": {},
    "name": "UnsubscribeFromEmailNotificationsMutation",
    "operationKind": "mutation",
    "text": "mutation UnsubscribeFromEmailNotificationsMutation(\n  $input: UnsubscribeFromEmailNotificationsInput!\n) {\n  unsubscribeFromEmailNotifications(input: $input) {\n    clientMutationId\n    errors {\n      __typename\n      type\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "205832119a5a998b346d05e3148982cd";

export default node;
