import React, { memo } from 'react';

import GmailSenderName from '../GmailSettings/GmailSenderName';
import TimezoneSelector from '../TimezoneSelector/TimezoneSelector';
import DailyEmailLimitInput from '../DailyEmailLimitInput/DailyEmailLimitInput';

import styles from './SequenceSettingsDrawer.pcss';

import Text from 'Components/ui/Text/Text';
import Drawer from 'Components/ui/Drawer/Drawer';

const SequenceSettingsDrawer: React.FC = () => {
  return (
    <Drawer removeWhenClosed className={styles.drawer} rootKey="sequence-settings">
      <div className={styles.contentRoot}>
        <Text type="d2" msg="invite_outreach_creators.sequence_settings.title" />
        <div className={styles.content}>
          <GmailSenderName />
          <TimezoneSelector />
          <DailyEmailLimitInput />
        </div>
      </div>
    </Drawer>
  );
};

export default memo(SequenceSettingsDrawer);
