import React, { PropsWithChildren, useContext } from 'react';
import classnames from 'classnames';

import { IconName } from '../types';

import styles from './Modal.pcss';

import Text from 'Components/ui/Text/Text';
import TextButton from 'Components/ui/TextButton/TextButton';
import Icon from 'Components/ui/Icon/Icon';
import AlterButton, { AlterButtonProps } from 'Components/ui/AlterButton/AlterButton';
import Drawer, { DrawerProps } from 'Components/ui/Drawer/Drawer';
import { DrawerContext, DrawerIds } from 'Containers/Drawer/DrawerContainer';

interface Props {
  titleMsg?: string;
  descrMsg?: string;
  className?: string;
  imgSrcSet?: string;
  imgSrc?: string;
  iconName?: IconName;
  loading?: boolean;
  dismissBtnProps?: AlterButtonProps;
  confirmBtnProps?: AlterButtonProps;
  onClose?: () => void;
  drawerProps: DrawerProps;
}

const Modal: React.FC<PropsWithChildren & Props> = (props) => {
  const {
    drawerProps,
    imgSrcSet,
    imgSrc,
    iconName,
    titleMsg,
    descrMsg,
    dismissBtnProps,
    confirmBtnProps,
    loading,
    className,
    onClose,
  } = props;

  const { closeDrawer } = useContext(DrawerContext);

  const handleClose = () => {
    closeDrawer(drawerProps.rootKey as DrawerIds);
    drawerProps.onClose?.();
    onClose?.();
  };

  const handleDismissClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    dismissBtnProps?.onClick?.(e);
    handleClose();
  };

  return (
    <Drawer
      {...drawerProps}
      fullView
      position="center"
      needCloseButton={false}
      className={classnames(styles.drawer, className)}
      rootKey={drawerProps.rootKey}
    >
      <div className={styles.content}>
        <div className={styles.closeWrap}>
          <TextButton icon="Close-small" onClick={handleClose} />
        </div>
        {(imgSrcSet || imgSrc) && <img srcSet={imgSrcSet} src={imgSrc} className={styles.img} />}
        {iconName && (
          <div className={styles.iconWrap}>
            <Icon size={80} name={iconName} className={styles.icon} />
          </div>
        )}
        <div className={styles.details}>
          {titleMsg && <Text weight="bold" type="d2" msg={titleMsg} className={styles.title} />}
          {descrMsg && <Text color="grey" msg={descrMsg} className={styles.descr} />}
        </div>
        <div className={styles.buttons}>
          {dismissBtnProps && (
            <AlterButton
              type="white"
              bordered
              {...dismissBtnProps}
              loading={loading}
              onClick={handleDismissClick}
            />
          )}
          {confirmBtnProps && <AlterButton type="pink" loading={loading} {...confirmBtnProps} />}
        </div>
      </div>
    </Drawer>
  );
};

export default Modal;
