/**
 * @generated SignedSource<<e39e8ecda4e70a399d7618fd1a39ce8b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BriefCreativeMediaType = "PHOTO" | "VIDEO";
export type CampaignPlatform = "FACEBOOK" | "INSTAGRAM" | "OTHER" | "SNAPCHAT" | "TIKTOK" | "YOUTUBE";
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING";
export type CampaignUseCase = "AFFILIATE_CAMPAIGN" | "INFLUENCER_POSTS" | "META_PARTNERSHIP_ADS" | "PRODUCT_SEEDING" | "TIKTOK_SHOP" | "TIKTOK_SPARK_ADS" | "USER_GENERATED_CONTENT";
export type ContentPlacement = "BOOSTED_FEED" | "BOOSTED_STORY" | "NONE" | "ORGANIC_FEED" | "ORGANIC_STORY";
export type ContentType = "BOOMERANG" | "HORIZONTAL_VIDEO" | "IMAGE" | "SQUARE_VIDEO" | "VERTICAL_VIDEO";
export type Currency = "CAD" | "RUB" | "USD";
export type V2ContentPlacement = "FEED" | "MIDDLEROLL" | "NONE" | "POSTROLL" | "PREROLL" | "REEL" | "STORY";
import { FragmentRefs } from "relay-runtime";
export type BriefPriceRange_campaign$data = {
  readonly brief: {
    readonly briefCreatives?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly contentAmount: number | null | undefined;
          readonly contentPlacement: ContentPlacement | null | undefined;
          readonly contentType: ContentType | null | undefined;
          readonly mediaType: BriefCreativeMediaType | null | undefined;
          readonly publishingRequired: boolean | null | undefined;
          readonly v2ContentPlacement: V2ContentPlacement | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    };
    readonly priceLimitMax?: number | null | undefined;
    readonly priceLimitMin?: number | null | undefined;
    readonly submitted?: boolean;
  } | null | undefined;
  readonly organization: {
    readonly currency: Currency;
  } | null | undefined;
  readonly platform: CampaignPlatform | null | undefined;
  readonly searchQuery: {
    readonly categories: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
    readonly creatorBirthdateFrom: any | null | undefined;
    readonly creatorBirthdateTo: any | null | undefined;
    readonly followersFrom: number | null | undefined;
    readonly followersTo: number | null | undefined;
    readonly viewsMedianFrom: number | null | undefined;
    readonly viewsMedianTo: number | null | undefined;
  } | null | undefined;
  readonly type: CampaignType | null | undefined;
  readonly useCase: CampaignUseCase | null | undefined;
  readonly " $fragmentType": "BriefPriceRange_campaign";
};
export type BriefPriceRange_campaign$key = {
  readonly " $data"?: BriefPriceRange_campaign$data;
  readonly " $fragmentSpreads": FragmentRefs<"BriefPriceRange_campaign">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BriefPriceRange_campaign",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "useCase",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "platform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchQuery",
      "kind": "LinkedField",
      "name": "searchQuery",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Category",
          "kind": "LinkedField",
          "name": "categories",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "creatorBirthdateFrom",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "creatorBirthdateTo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "followersFrom",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "followersTo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "viewsMedianFrom",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "viewsMedianTo",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "brief",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "submitted",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "priceLimitMin",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "priceLimitMax",
              "storageKey": null
            },
            {
              "alias": "briefCreatives",
              "args": null,
              "concreteType": "BriefCreativeConnection",
              "kind": "LinkedField",
              "name": "creatives",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BriefCreativeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "BriefCreative",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "publishingRequired",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "mediaType",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "contentType",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "contentAmount",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "contentPlacement",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "v2ContentPlacement",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "V2Brief",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};

(node as any).hash = "5ee68a39327f796dc8be764637426333";

export default node;
