import React, { useEffect, useState } from 'react';

import styles from './BuyBrandSlotsBanner.pcss';

import ls from 'Util/localStorage';
import { amplitude } from 'Helpers/amplitude';
import buyBannerImg from 'Images/general/buy_brand.png';
import buyBanner2xImg from 'Images/general/buy_brand_2x.png';
import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import TextButton from 'Components/ui/TextButton/TextButton';

const BRAND_BUY_SLOTS = 'brand_buy_slots';

interface Props {
  onAddonBuyClick: () => void;
}

const BuyBrandSlotsBanner: React.FC<Props> = (props) => {
  const { onAddonBuyClick } = props;

  const bannerVisibleDeafult = !ls.get(BRAND_BUY_SLOTS);

  const [visible, setVisible] = useState(bannerVisibleDeafult);

  useEffect(() => {
    if (visible) {
      amplitude.sendEvent<422>({
        id: '422',
        category: 'brand_addons_promo_banner',
        name: 'show',
        param: undefined,
      });
    }
  }, [visible]);

  const handleAddonBuyClick = () => {
    amplitude.sendEvent<423>({
      id: '423',
      category: 'brand_addons_promo_banner',
      name: 'buy_click',
      param: undefined,
    });
    setVisible(false);
    hideBanner();
    onAddonBuyClick();
  };

  const hideBanner = () => {
    setVisible(false);
    ls.set(BRAND_BUY_SLOTS, true);
  };

  if (!visible) return false;

  return (
    <div className={styles.root}>
      <img srcSet={`${buyBannerImg} 1x, ${buyBanner2xImg} 2x`} className={styles.img} />
      <div>
        <Text
          type="h1"
          msg="brands.dashboard.buy_slot.title"
          data-test="buyBrandSlotsBanner:text:title"
        />
        <Text
          type="md"
          msg="brands.dashboard.buy_slot.descr"
          data-test="buyBrandSlotsBanner:text:descr"
        />
      </div>
      <AlterButton
        type="black"
        msg="brands.dashboard.buy_slot.btn"
        onClick={handleAddonBuyClick}
        data-test="buyBrandSlotsBanner:alterButton:btn"
      />
      <TextButton
        icon="Close-small"
        size="xs"
        className={styles.closeBtn}
        onClick={hideBanner}
        data-test="buyBrandSlotsBanner:textButton:closeBtn"
      />
    </div>
  );
};

export default BuyBrandSlotsBanner;
