import React, { useContext } from 'react';
import classnames from 'classnames';

import { BriefContext } from '../../../../Brief.Context';
import SegmentedControls from '../../components/SegmentedControls/SegmentedControls';

import styles from './SparkAdsDetails.pcss';
import { getSparkAdsDaysList } from './util';

import updateBrief from 'Mutations/UpdateBrief.Mutation';
import Text from 'Atoms/Text/Text';
import TextButton from 'Atoms/TextButton/TextButton';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import sparkAdsImg_en from 'Images/brief/sparkAds_en.png';
import sparkAdsImg_en_2x from 'Images/brief/sparkAds_en_2x.png';
import { SPARK_ADS_TT_INFO_EN } from 'Constants/general';

interface Props {
  sparkAdsActivationDays?: number | null;
  className?: string;
  briefId: string;
  hideDisabled?: boolean;
}

const SparkAdsDetails: React.FC<Props> = (props) => {
  const { sparkAdsActivationDays = null, className, briefId, hideDisabled } = props;

  const [briefState, briefDispatch] = useContext(BriefContext);

  const { showErrors, shownElementsWithPossibleError } = briefState;

  const daysList = getSparkAdsDaysList(hideDisabled);

  const srcSet = `${sparkAdsImg_en} 1x, ${sparkAdsImg_en_2x} 2x`;

  const handleDaysChange = (data: { sparkAdsActivationDays?: number | null }) => {
    const id = data.sparkAdsActivationDays;
    briefDispatch({ key: 'briefIsSaving', value: true });
    updateBrief({ id: briefId, sparkAds: !!id, sparkAdsActivationDays: id });
  };

  return (
    <div className={classnames(className, styles.root)}>
      <div className={styles.container}>
        <div className={styles.content}>
          <Text weight="500" msg="brief.spark_ads.title" data-test="sparkAdsDetails:text:title" />
          <Text
            msg="brief.spark_ads.descr"
            display="inline"
            data-test="sparkAdsDetails:text:descr"
          />
          <SmartLink path={SPARK_ADS_TT_INFO_EN}>
            <TextButton
              msg="general.read_more"
              display="inline"
              className={styles.readMore}
              data-test="sparkAdsDetails:textButton:readMore"
            />
          </SmartLink>
        </div>
        <img srcSet={srcSet} className={styles.img} />
      </div>
      <SegmentedControls
        id="sparkAdsActivationDays"
        currentValue={sparkAdsActivationDays}
        items={daysList}
        radius="bottom"
        error={
          shownElementsWithPossibleError?.includes('creatorAdsTiktok') &&
          showErrors &&
          !sparkAdsActivationDays
        }
        onChange={handleDaysChange}
      />
    </div>
  );
};

export default SparkAdsDetails;
