/**
 * @generated SignedSource<<7deb6a1e733259ad7d3534de12096d9d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Currency = "CAD" | "RUB" | "USD";
export type ProjectArchivationSide = "ADVERTISER" | "CONTRACTOR";
export type CreatorProjectInfoQuery$variables = {
  projectId: string;
};
export type CreatorProjectInfoQuery$data = {
  readonly project: {
    readonly archivationRequest: {
      readonly acceptance: {
        readonly id: string;
      } | null | undefined;
      readonly rejection: {
        readonly id: string;
      } | null | undefined;
      readonly side: ProjectArchivationSide;
    } | null | undefined;
    readonly currency: Currency | null | undefined;
    readonly deadline: {
      readonly draftDate: any | null | undefined;
      readonly publicationDate: any | null | undefined;
    } | null | undefined;
    readonly id: string;
    readonly launch: {
      readonly id: string;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"ProjectDetails_project" | "ProjectPSStatus_project" | "ProjectStatusChat_project">;
  } | null | undefined;
};
export type CreatorProjectInfoQuery = {
  response: CreatorProjectInfoQuery$data;
  variables: CreatorProjectInfoQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectArchivationRequestAcceptance",
  "kind": "LinkedField",
  "name": "acceptance",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectArchivationRequestRejection",
  "kind": "LinkedField",
  "name": "rejection",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "side",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "draftDate",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publicationDate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectLaunch",
  "kind": "LinkedField",
  "name": "launch",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreatorProjectInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProjectPSStatus_project"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProjectDetails_project"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProjectStatusChat_project"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectArchivationRequest",
            "kind": "LinkedField",
            "name": "archivationRequest",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Deadline",
            "kind": "LinkedField",
            "name": "deadline",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreatorProjectInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "paidSocial",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "creator",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "username",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "paidSocialEnabled",
                    "storageKey": null
                  }
                ],
                "type": "InstagramCreator",
                "abstractKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "brief",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "paidSocialBca",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "paidSocialActivationDays",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "productShipmentRequired",
                    "storageKey": null
                  }
                ],
                "type": "V2Brief",
                "abstractKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Offer",
            "kind": "LinkedField",
            "name": "offer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OfferAcceptance",
                "kind": "LinkedField",
                "name": "acceptance",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectCompletion",
            "kind": "LinkedField",
            "name": "completion",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectArchivation",
            "kind": "LinkedField",
            "name": "archivation",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectArchivationRequest",
            "kind": "LinkedField",
            "name": "archivationRequest",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Brand",
            "kind": "LinkedField",
            "name": "brand",
            "plural": false,
            "selections": [
              (v12/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "logo",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ImageTransformation",
                        "kind": "LinkedField",
                        "name": "transformations",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "brandLogoUrl",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "Image",
                    "abstractKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              (v12/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductShipmentCompletion",
            "kind": "LinkedField",
            "name": "productShipmentCompletion",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductShipment",
            "kind": "LinkedField",
            "name": "productShipment",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ShipmentStatus",
                "kind": "LinkedField",
                "name": "status",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "state",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Deadline",
            "kind": "LinkedField",
            "name": "deadline",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e4c5b49b66a013d741e6ef845b98622c",
    "id": null,
    "metadata": {},
    "name": "CreatorProjectInfoQuery",
    "operationKind": "query",
    "text": "query CreatorProjectInfoQuery(\n  $projectId: ID!\n) {\n  project(id: $projectId) {\n    id\n    ...ProjectPSStatus_project\n    ...ProjectDetails_project\n    ...ProjectStatusChat_project\n    archivationRequest {\n      acceptance {\n        id\n      }\n      rejection {\n        id\n      }\n      side\n      id\n    }\n    deadline {\n      draftDate\n      publicationDate\n      id\n    }\n    launch {\n      id\n    }\n    currency\n  }\n}\n\nfragment ProjectDetails_project on Project {\n  id\n  creator {\n    __typename\n    id\n  }\n  offer {\n    acceptance {\n      id\n    }\n    id\n  }\n  launch {\n    id\n  }\n  completion {\n    id\n  }\n  archivation {\n    id\n  }\n  archivationRequest {\n    acceptance {\n      id\n    }\n    rejection {\n      id\n    }\n    side\n    id\n  }\n  brand {\n    name\n    logo {\n      __typename\n      ... on Image {\n        transformations {\n          brandLogoUrl\n        }\n      }\n      id\n    }\n    id\n  }\n  campaign {\n    name\n    organization {\n      id\n    }\n    id\n  }\n}\n\nfragment ProjectPSStatus_project on Project {\n  id\n  paidSocial\n  creator {\n    __typename\n    username\n    ... on InstagramCreator {\n      paidSocialEnabled\n    }\n    id\n  }\n  brief {\n    __typename\n    ... on V2Brief {\n      paidSocialBca\n      paidSocialActivationDays\n    }\n    id\n  }\n}\n\nfragment ProjectStatusChat_project on Project {\n  completion {\n    id\n  }\n  launch {\n    id\n  }\n  brief {\n    __typename\n    ... on V2Brief {\n      productShipmentRequired\n    }\n    id\n  }\n  productShipmentCompletion {\n    id\n  }\n  productShipment {\n    id\n    status {\n      state\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6418a324e5f05774213fcdf8e1b60ede";

export default node;
