import React, { useEffect } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { useNavigate } from 'react-router-dom';

import AdminAdvertisers from './AdminAdvertisers';

import { ERROR_ROUTE } from 'Constants/general';
import { AdminAdvertisersContainerQuery as QueryType } from 'GraphTypes/AdminAdvertisersContainerQuery.graphql';

const AdminAdvertisersContainerQuery = graphql`
  query AdminAdvertisersContainerQuery {
    planIds
    currentUser {
      permissions
    }
  }
`;

const AdminAdvertisersContainer: React.FC = () => {
  const data = useLazyLoadQuery<QueryType>(AdminAdvertisersContainerQuery, {});

  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      const permissions = data.currentUser?.permissions || [];
      if (!permissions.includes('ADMIN__MANAGE_ALL_ORGANIZATIONS')) {
        navigate(ERROR_ROUTE);
      }
    }
  }, [data]);

  return <AdminAdvertisers planIds={data.planIds} />;
};

export default AdminAdvertisersContainer;
