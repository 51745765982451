/**
 * @generated SignedSource<<046ea2a50fad3fd51425215085544938>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ShippingState = "DELIVERED" | "FAILURE" | "PRE_TRANSIT" | "RETURNED" | "TRANSIT" | "UNKNOWN";
import { FragmentRefs } from "relay-runtime";
export type ProjectStatusChat_project$data = {
  readonly brief: {
    readonly productShipmentRequired?: boolean | null | undefined;
  } | null | undefined;
  readonly completion: {
    readonly id: string;
  } | null | undefined;
  readonly launch: {
    readonly id: string;
  } | null | undefined;
  readonly productShipment: {
    readonly id: string;
    readonly status: {
      readonly state: ShippingState | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly productShipmentCompletion: {
    readonly id: string;
  } | null | undefined;
  readonly " $fragmentType": "ProjectStatusChat_project";
};
export type ProjectStatusChat_project$key = {
  readonly " $data"?: ProjectStatusChat_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectStatusChat_project">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectStatusChat_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectCompletion",
      "kind": "LinkedField",
      "name": "completion",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectLaunch",
      "kind": "LinkedField",
      "name": "launch",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "brief",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "productShipmentRequired",
              "storageKey": null
            }
          ],
          "type": "V2Brief",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductShipmentCompletion",
      "kind": "LinkedField",
      "name": "productShipmentCompletion",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductShipment",
      "kind": "LinkedField",
      "name": "productShipment",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ShipmentStatus",
          "kind": "LinkedField",
          "name": "status",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "state",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};
})();

(node as any).hash = "e746d9094e102d4da5cbc0d31f39d407";

export default node;
