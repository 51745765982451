/**
 * @generated SignedSource<<c232ecf36e896ea9cc7b69c7c1a685c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type OutreachTransport = "GMAIL" | "SENDGRID";
export type CreateOutreachBatchInput = {
  activateAfterCompletion?: boolean | null | undefined;
  autofillCreators?: boolean | null | undefined;
  campaignId: string;
  clientMutationId?: string | null | undefined;
  customListIds?: ReadonlyArray<string> | null | undefined;
  gmailAuthorizationId?: string | null | undefined;
  id?: string | null | undefined;
  senderEmail?: string | null | undefined;
  senderName?: string | null | undefined;
  sequenceBlueprintId: string;
  transport: OutreachTransport;
};
export type CreateOutreachBatchMutation$variables = {
  input: CreateOutreachBatchInput;
};
export type CreateOutreachBatchMutation$data = {
  readonly createOutreachBatch: {
    readonly __typename: "CreateOutreachBatchPayload";
    readonly batch: {
      readonly id: string;
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
};
export type CreateOutreachBatchMutation = {
  response: CreateOutreachBatchMutation$data;
  variables: CreateOutreachBatchMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "createOutreachBatch",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OutreachBatch",
            "kind": "LinkedField",
            "name": "batch",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "CreateOutreachBatchPayload",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateOutreachBatchMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateOutreachBatchMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e6203d5965135f7778e4730e3aa24fd9",
    "id": null,
    "metadata": {},
    "name": "CreateOutreachBatchMutation",
    "operationKind": "mutation",
    "text": "mutation CreateOutreachBatchMutation(\n  $input: CreateOutreachBatchInput!\n) {\n  createOutreachBatch(input: $input) {\n    __typename\n    ... on CreateOutreachBatchPayload {\n      batch {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "299dd2db51ade1a43512bc8a8419e1e9";

export default node;
