import React, { useContext, useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { useIntl } from 'react-intl';
import debounce from 'lodash/debounce';
import last from 'lodash/last';
import xor from 'lodash/xor';

import styles from './ModerationSettings.pcss';
import { getVisibleOpt, getReviewedOpt, getBadges, getEmployedOpt } from './data';

import updateCreatorProfile from 'Mutations/UpdateCreatorProfile.Mutation';
import updateContractorProfile from 'Mutations/UpdateContractorProfile.Mutation';
import markCreatorAsPassedReview from 'Mutations/MarkCreatorAsPassedReview.Mutation';
import Text from 'Atoms/Text/Text';
import Field from 'Molecules/Field/Field';
import ProgressiveInput from 'Components/ProgressiveInput/ProgressiveInput';
import { FAILED_REVIEW_REASON } from 'Constants/modals';
import { CreatorAdminPanelQuery$data } from 'GraphTypes/CreatorAdminPanelQuery.graphql';
import { ModerationSettings_creator$key } from 'GraphTypes/ModerationSettings_creator.graphql';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';

interface Props {
  creator: ModerationSettings_creator$key;
  ethnicities: NonNullable<CreatorAdminPanelQuery$data>['ethnicities'];
  tags: NonNullable<CreatorAdminPanelQuery$data>['tags'];
  animals: NonNullable<CreatorAdminPanelQuery$data>['animals'];
  hairTypes: NonNullable<CreatorAdminPanelQuery$data>['hairTypes'];
  bodyTypes: NonNullable<CreatorAdminPanelQuery$data>['bodyTypes'];
  languages: NonNullable<CreatorAdminPanelQuery$data>['languages'];
}

const ModerationSettings: React.FC<Props> = (props) => {
  const { creator } = props;

  const data = useFragment(
    graphql`
      fragment ModerationSettings_creator on Creator {
        id
        profile {
          id
          badge
          searchable
          currency
          adminComment
          employed
          animals {
            id
            name
          }
          hairTypes {
            id
            name
          }
          bodyTypes {
            id
            name
          }
          tags {
            id
            name
          }
          ethnicity {
            id
          }
          languages {
            id
            name
          }
        }
        review {
          type
          reason
          source
        }
        ownership {
          owner {
            contractorProfile {
              id
              firstName
              languages {
                id
                name
              }
            }
          }
        }
      }
    `,
    creator
  );

  const { dispatch: modalDispatch } = useContext(ModalContext);
  const intl = useIntl();

  const ethnicities = props.ethnicities;
  const tags = props.tags;
  const animals = props.animals;
  const hairTypes = props.hairTypes;
  const bodyTypes = props.bodyTypes;
  const languages = props.languages;

  const creatorId = data.id;
  const profile = data.profile;

  if (!profile) return null;

  const {
    id: profileId,
    currency,
    searchable,
    badge,
    hairTypes: creatorHairTypes,
    employed,
    animals: creatorAnimals,
    adminComment,
    ethnicity,
    tags: creatorTags,
    bodyTypes: creatorBodyTypes,
  } = profile;

  const contractorId = data.ownership?.owner?.contractorProfile?.id;
  const contractorLanguages = data?.ownership?.owner?.contractorProfile?.languages;

  const ethnicityId = ethnicity?.id;

  if (!profileId) return null;

  const reviewType = data.review?.type;
  const reviewReason = data.review?.reason;
  const reviewSource = data.review?.source;

  const debounceUpdateCreatorProfile = debounce(updateCreatorProfile, 2000);

  const handleCreatorProfileChange = (data: any) => {
    debounceUpdateCreatorProfile({ id: profileId, ...data });
  };

  const handleSearchabeChange = (searchable: string) => {
    updateCreatorProfile({ id: profileId, searchable: searchable === 'VISIBLE' });
  };

  const handleEthnicityChange = (newEthnicity: string) => {
    updateCreatorProfile({ id: profileId, creatorEthnicityId: newEthnicity });
  };

  const handleJobChange = (newEmployed: any) => {
    const newEmployedVal =
      newEmployed === 'HAS_JOB' ? true : newEmployed === 'HAS_NO_JOB' ? false : null;
    updateCreatorProfile({ id: profileId, employed: newEmployedVal });
  };

  const handleReviewChange = (newReviewedByAdmin: any) => {
    if (reviewType === newReviewedByAdmin) return;

    if (newReviewedByAdmin === 'FAILURE') {
      modalDispatch({
        type: ModalTypes.SET_MODAL,
        payload: { name: FAILED_REVIEW_REASON, attach: { creatorId } },
      });
    } else if (newReviewedByAdmin === 'SUCCESS') {
      markCreatorAsPassedReview({ creatorId });
    }
  };

  const handleBadgeChange = (newBadge: any) => {
    const newBadgeData =
      newBadge === 'ABSENT'
        ? { badge: null, badgeSetAt: null }
        : { badge: newBadge, badgeSetAt: new Date() };
    updateCreatorProfile({ id: profileId, ...newBadgeData });
  };

  const handleAnimalsChange = (newAnimal: string) => {
    const newList = xor(creatorAnimalIds, [newAnimal]);
    updateCreatorProfile({ id: profileId, animalIds: newList });
  };

  const handleAnimalsClear = () => {
    updateCreatorProfile({ id: profileId, animalIds: [] });
  };

  const creatorLanguageIds = useMemo(() => {
    return contractorLanguages?.map((item) => item.id);
  }, [contractorLanguages]);

  const handleLanguagesChange = (newLanguage: any) => {
    if (contractorId) {
      const newList = xor(creatorLanguageIds, [newLanguage]);
      updateContractorProfile({ id: contractorId, languageIds: newList });
    }
  };

  const handleLanguagesClear = () => {
    if (contractorId) {
      updateContractorProfile({ id: contractorId, languageIds: [] });
    }
  };

  const handleBodyTypeChange = (bodyTypeId: string) => {
    updateCreatorProfile({ id: profileId, bodyTypeIds: [bodyTypeId] });
  };

  const creatorHairs = useMemo(() => {
    return creatorHairTypes?.map((item) => item.id);
  }, [creatorHairTypes]);

  const creatorTagIds = useMemo(() => {
    return creatorTags?.map((item) => item.id);
  }, [creatorTags]);

  const creatorAnimalIds = useMemo(() => {
    return creatorAnimals?.map((item) => item.id);
  }, [creatorAnimals]);

  const handleHairTypesChange = (newHairType: string) => {
    const newList = xor(creatorHairs, [newHairType]);
    updateCreatorProfile({ id: profileId, hairTypeIds: newList });
  };

  const handleHairClear = () => {
    updateCreatorProfile({ id: profileId, hairTypeIds: [] });
  };

  const handleTagsChange = (newTag: any) => {
    const newList = xor(creatorTagIds, [newTag]);
    updateCreatorProfile({ id: profileId, tagIds: newList });
  };

  const handleTagsClear = () => {
    updateCreatorProfile({ id: profileId, tagIds: [] });
  };

  const ethnicitiesList = ethnicities.map((item) => {
    return { value: item.name, id: item.id, isChecked: item.id === ethnicityId };
  });

  const animalsList = animals?.map((item) => {
    return {
      id: item.id,
      label: item.name,
      isChecked: creatorAnimalIds?.includes(item.id) || false,
    };
  });

  const contractorLanguagesList = languages?.map((item) => {
    const languageId = item.id;
    return {
      id: languageId,
      label: item.name,
      isChecked: creatorLanguageIds?.includes(languageId) || false,
    };
  });

  const creatorBodyType =
    Array.isArray(creatorBodyTypes) && creatorBodyTypes.length
      ? { value: last(creatorBodyTypes).id, label: last(creatorBodyTypes).name }
      : null;

  const bodyTypesList = bodyTypes?.map((item) => {
    return {
      value: item.name,
      id: item.id,
      isChecked: creatorBodyType?.value === item.id,
    };
  });

  const hairTypesList = hairTypes?.map((item) => {
    const hairId = item.id;
    return {
      label: item.name,
      id: item.id,
      isChecked: creatorHairs?.includes(hairId) || false,
    };
  });

  const tagsList = tags.map((item) => {
    return { id: item.id, label: item.name, isChecked: creatorTagIds.includes(item.id) || false };
  });

  const visibleOpt = getVisibleOpt({ intl, checkedValue: searchable ? 'VISIBLE' : 'HIDDEN' });
  const reviewedOpt = getReviewedOpt({ intl, checkedValue: reviewType });
  const badgesOpt = getBadges({ intl, checkedValue: badge as string });
  const employedOpt = getEmployedOpt({ intl, checkedValue: employed });

  return (
    <div className={styles.root}>
      <Text
        tag="h3"
        weight="700"
        msg="creator_statistic.settings.moderation.title"
        transform="uppercase"
        className={styles.title}
        data-test="moderationSettings:text:title"
      />
      <div className={styles.row}>
        <ProgressiveInput
          type="radio"
          titleMsg="creator.searchable"
          className={styles.field}
          inputProps={{
            items: visibleOpt,
            bordered: true,
            handleClick: handleSearchabeChange,
            hideByAlphabet: true,
            withArrow: true,
          }}
          isDirty={searchable !== undefined}
        />
        <div className={styles.field}>
          <ProgressiveInput
            type="radio"
            titleMsg="creator.is_reviewed"
            inputProps={{
              items: reviewedOpt,
              bordered: true,
              handleClick: handleReviewChange,
              hideByAlphabet: true,
              withArrow: true,
            }}
            isDirty={!!currency}
          />
          <div className={styles.reason}>
            {reviewReason && (
              <Text
                size="sm"
                color="grayDog"
                msg={`failed_review_reason_modal.${reviewReason.toLowerCase()}`}
                data-test="moderationSettings:text:unknown"
              />
            )}
            {reviewSource && (
              <Text
                size="sm"
                color="grayDog"
                msg={`failed_review_reason_modal.source.${reviewSource.toLowerCase()}`}
                data-test="moderationSettings:text:unknown"
              />
            )}
          </div>
        </div>
        <ProgressiveInput
          type="radio"
          titleMsg="creator.badge"
          className={styles.field}
          inputProps={{
            items: badgesOpt,
            bordered: true,
            handleClick: handleBadgeChange,
            withArrow: true,
            place: 'right',
          }}
          isDirty={!!badge}
        />
      </div>
      <div className={styles.row}>
        <ProgressiveInput
          type="radio"
          titleMsg="creator.ethnicity"
          className={styles.field}
          inputProps={{
            items: ethnicitiesList,
            bordered: true,
            handleClick: handleEthnicityChange,
            withArrow: true,
            place: 'right',
          }}
          isDirty={!!ethnicityId}
        />
        <ProgressiveInput
          type="radio"
          titleMsg="creator.job"
          className={styles.field}
          inputProps={{
            items: employedOpt,
            bordered: true,
            handleClick: handleJobChange,
            withArrow: true,
          }}
          isDirty={typeof employed === 'boolean'}
        />
      </div>
      <div className={styles.row}>
        <ProgressiveInput
          type="checkboxSelect"
          titleMsg="creator.language"
          className={styles.field}
          inputProps={{
            limitForShow: 6,
            items: contractorLanguagesList,
            bordered: true,
            handleClick: handleLanguagesChange,
            handleClear: handleLanguagesClear,
            withArrow: true,
            withSearch: true,
          }}
          isDirty={Number(contractorLanguages?.length) > 0}
        />
      </div>
      <div className={styles.row}>
        <ProgressiveInput
          type="radio"
          titleMsg="creator.body"
          className={styles.field}
          inputProps={{
            items: bodyTypesList,
            bordered: true,
            handleClick: handleBodyTypeChange,
            withArrow: true,
          }}
          isDirty={!!creatorBodyType?.value}
        />
        <ProgressiveInput
          type="checkboxSelect"
          titleMsg="creator.hair_type"
          className={styles.field}
          inputProps={{
            limitForShow: 3,
            items: hairTypesList,
            bordered: true,
            handleClick: handleHairTypesChange,
            handleClear: handleHairClear,
            withArrow: true,
          }}
          isDirty={Number(creatorHairs?.length) > 0}
        />
      </div>
      <div className={styles.row}>
        <ProgressiveInput
          type="checkboxSelect"
          titleMsg="creator.tags"
          className={styles.field}
          inputProps={{
            limitForShow: 3,
            items: tagsList,
            bordered: true,
            handleClick: handleTagsChange,
            handleClear: handleTagsClear,
            withArrow: true,
            withSearch: true,
          }}
          isDirty={Number(creatorTagIds?.length) > 0}
        />
      </div>
      <div className={styles.row}>
        <ProgressiveInput
          type="checkboxSelect"
          titleMsg="creator.animals"
          className={styles.field}
          inputProps={{
            limitForShow: 3,
            items: animalsList,
            bordered: true,
            handleClick: handleAnimalsChange,
            handleClear: handleAnimalsClear,
            withArrow: true,
          }}
          isDirty={Number(creatorAnimalIds?.length) > 0}
        />
      </div>
      <Field
        name="adminComment"
        title="creator.admin_comments"
        element="input"
        elementData={{
          defaultValue: adminComment,
          multiple: true,
          textAreaData: { minRows: 4 },
          onChange: handleCreatorProfileChange,
        }}
      />
    </div>
  );
};

export default ModerationSettings;
