/**
 * @generated SignedSource<<803a9f918d71bfed585ce7a989f7f8a1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CreatorType = "CONTENT" | "INSTAGRAM" | "TIKTOK" | "YOUTUBE";
import { FragmentRefs } from "relay-runtime";
export type CreatorInfoStats_creator$data = {
  readonly channel?: {
    readonly subscribers: number | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly profile: {
    readonly cities: ReadonlyArray<{
      readonly name: string;
    }>;
    readonly countries: ReadonlyArray<{
      readonly name: string;
    }>;
    readonly estimatedPrice: number | null | undefined;
    readonly hashtags: ReadonlyArray<{
      readonly count: number | null | undefined;
      readonly name: string | null | undefined;
      readonly percentage: number | null | undefined;
    }>;
    readonly mentions: ReadonlyArray<{
      readonly count: number | null | undefined;
      readonly name: string | null | undefined;
      readonly percentage: number | null | undefined;
    }>;
    readonly portfolioLink: string | null | undefined;
    readonly postBrands: ReadonlyArray<{
      readonly count: number | null | undefined;
      readonly name: string | null | undefined;
      readonly percentage: number | null | undefined;
    }>;
  } | null | undefined;
  readonly tiktokUser?: {
    readonly averageComments: number | null | undefined;
    readonly averageLikes: number | null | undefined;
    readonly averageViews: number | null | undefined;
    readonly engagementRate: number | null | undefined;
    readonly followedByCount: number | null | undefined;
    readonly medianViews: number | null | undefined;
    readonly name: string;
  } | null | undefined;
  readonly type: CreatorType;
  readonly user?: {
    readonly averageComments: number | null | undefined;
    readonly averageLikes: number | null | undefined;
    readonly averageViews: number | null | undefined;
    readonly engagementRate: number | null | undefined;
    readonly followedByCount: number | null | undefined;
  } | null | undefined;
  readonly userInsightsData?: {
    readonly followers: number | null | undefined;
    readonly followersDelta: number | null | undefined;
    readonly impressions: number | null | undefined;
    readonly mediaEngagementRate: number | null | undefined;
    readonly reach: number | null | undefined;
    readonly websiteClicks: number | null | undefined;
  } | null | undefined;
  readonly username: string;
  readonly " $fragmentType": "CreatorInfoStats_creator";
};
export type CreatorInfoStats_creator$key = {
  readonly " $data"?: CreatorInfoStats_creator$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreatorInfoStats_creator">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  },
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "percentage",
    "storageKey": null
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "followedByCount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "engagementRate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "averageLikes",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "averageComments",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "averageViews",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreatorInfoStats_creator",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CreatorProfile",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Country",
          "kind": "LinkedField",
          "name": "countries",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "City",
          "kind": "LinkedField",
          "name": "cities",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "estimatedPrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Mention",
          "kind": "LinkedField",
          "name": "hashtags",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "portfolioLink",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Mention",
          "kind": "LinkedField",
          "name": "postBrands",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Mention",
          "kind": "LinkedField",
          "name": "mentions",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "InstagramUser",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "InstagramUserInsightsData",
          "kind": "LinkedField",
          "name": "userInsightsData",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "followers",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "followersDelta",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "reach",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "impressions",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "websiteClicks",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mediaEngagementRate",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "InstagramCreator",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "YoutubeChannel",
          "kind": "LinkedField",
          "name": "channel",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "subscribers",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "YoutubeCreator",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "tiktokUser",
          "args": null,
          "concreteType": "TiktokUser",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "medianViews",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "TiktokCreator",
      "abstractKey": null
    }
  ],
  "type": "Creator",
  "abstractKey": "__isCreator"
};
})();

(node as any).hash = "51b94cbd4c49dce30d481bf7b3966041";

export default node;
