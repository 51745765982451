/**
 * @generated SignedSource<<d10d506f3007a184ac496016f0550634>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProjectArchivationSide = "ADVERTISER" | "CONTRACTOR";
import { FragmentRefs } from "relay-runtime";
export type ProjectDetails_project$data = {
  readonly archivation: {
    readonly id: string;
  } | null | undefined;
  readonly archivationRequest: {
    readonly acceptance: {
      readonly id: string;
    } | null | undefined;
    readonly rejection: {
      readonly id: string;
    } | null | undefined;
    readonly side: ProjectArchivationSide;
  } | null | undefined;
  readonly brand: {
    readonly logo: {
      readonly transformations?: {
        readonly brandLogoUrl: string;
      } | null | undefined;
    } | null | undefined;
    readonly name: string | null | undefined;
  } | null | undefined;
  readonly campaign: {
    readonly name: string | null | undefined;
    readonly organization: {
      readonly id: string;
    } | null | undefined;
  };
  readonly completion: {
    readonly id: string;
  } | null | undefined;
  readonly creator: {
    readonly id: string;
  } | null | undefined;
  readonly id: string;
  readonly launch: {
    readonly id: string;
  } | null | undefined;
  readonly offer: {
    readonly acceptance: {
      readonly id: string;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ProjectDetails_project";
};
export type ProjectDetails_project$key = {
  readonly " $data"?: ProjectDetails_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectDetails_project">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectDetails_project",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "creator",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Offer",
      "kind": "LinkedField",
      "name": "offer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OfferAcceptance",
          "kind": "LinkedField",
          "name": "acceptance",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectLaunch",
      "kind": "LinkedField",
      "name": "launch",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectCompletion",
      "kind": "LinkedField",
      "name": "completion",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectArchivation",
      "kind": "LinkedField",
      "name": "archivation",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectArchivationRequest",
      "kind": "LinkedField",
      "name": "archivationRequest",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectArchivationRequestAcceptance",
          "kind": "LinkedField",
          "name": "acceptance",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectArchivationRequestRejection",
          "kind": "LinkedField",
          "name": "rejection",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "side",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Brand",
      "kind": "LinkedField",
      "name": "brand",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "logo",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ImageTransformation",
                  "kind": "LinkedField",
                  "name": "transformations",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "brandLogoUrl",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "Image",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};
})();

(node as any).hash = "5cc15bfa2ba9597bf54ab260107ada4c";

export default node;
