import React, { useContext } from 'react';
import cn from 'classnames';
import { graphql, useFragment } from 'react-relay';
import track from 'react-tracking';

import styles from './DealActions.pcss';

import Text from 'Atoms/Text/Text';
import Button from 'Atoms/Button/Button';
import { DealActions_project$key } from 'GraphTypes/DealActions_project.graphql';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { PRODUCT_SEEDING } from 'Constants/general';
import FinishDealWrapperProvider from 'Molecules/FinishDealWrapper/FinishDealWrapperContext';

interface Props {
  nextStepBtn?: JSX.Element | null;
  finishDealMsg?: string;
  finishDealCallback?: () => void;
  additionalElement?: JSX.Element;
  project: DealActions_project$key;
  selectSubmissionFiles?: boolean;
  finishDealBtnColor?: string;
}

const DealActions: React.FC<Props> = (props) => {
  const {
    project,
    nextStepBtn,
    finishDealMsg,
    finishDealCallback,
    additionalElement,
    selectSubmissionFiles,
    finishDealBtnColor: finishDealBtnColorProp,
  } = props;

  const data = useFragment(
    graphql`
      fragment DealActions_project on Project {
        id
        priceChangeRequest {
          id
        }
        advertiserStage
        price
        currency
        publicationEventExists
        campaign {
          publishingRequired
          id
          platform
          type
          paymentType
          useCase
        }
        creator {
          type
          ... on InstagramCreator {
            insightsAuthorized
            user {
              id
            }
          }
        }
      }
    `,
    project
  );

  const { openDrawer } = useContext(DrawerContext);

  const publicationEventExists = data.publicationEventExists;

  const campaignType = data.campaign.type;
  const campaignPlatform = data.campaign.platform;
  const publishingRequired = data.campaign.publishingRequired;

  const creatorType = data.creator?.type;
  const insightsAuthorized = data.creator?.insightsAuthorized;

  const paymentType = data.campaign.paymentType;
  const isBarter = paymentType === 'BARTER';
  const paymentTypePrefix = isBarter ? '.barter' : '';

  const hasPriceChangeRequest = !!data.priceChangeRequest?.id;

  const projectId = data.id;
  const price = data.price;
  const isFreeOrganic =
    (data?.campaign?.useCase === 'AFFILIATE_CAMPAIGN' ||
      data?.campaign?.useCase === 'TIKTOK_SHOP') &&
    !price;
  const advertiserStage = data.advertiserStage;

  const needRequestLink =
    (advertiserStage === 'PUBLISHING_STARTED' || campaignType === 'PRODUCT_SEEDING') &&
    !publicationEventExists &&
    publishingRequired;

  const sendRequestMsgPostfix = additionalElement ? '.short' : '';
  const sendRequestMsg =
    hasPriceChangeRequest &&
    advertiserStage !== 'CONTENT_SUBMITTED' &&
    advertiserStage !== 'PUBLISHING_STARTED'
      ? 'project.stepper.content_creation.change_price.disabled'
      : `project.stepper.content_creation.change_price${sendRequestMsgPostfix}`;

  const handlePriceChangeRequest = () => {
    openDrawer('project-price-request');
  };

  const finishDealBtnColor = finishDealBtnColorProp || needRequestLink ? 'normal' : 'primary';

  return (
    <div className={styles.root}>
      {nextStepBtn || (
        <FinishDealWrapperProvider
          projectId={projectId}
          finishDealCallback={finishDealCallback}
          needRequestLink={needRequestLink}
          campaignType={campaignType}
          campaignPlatform={campaignPlatform}
          creatorType={creatorType}
          insightsAuthorized={insightsAuthorized}
          selectSubmissionFiles={selectSubmissionFiles}
          isBarter={isBarter}
          price={price}
          isFreeOrganic={isFreeOrganic}
        >
          <Button
            width="full"
            color={finishDealBtnColor}
            msg={
              isFreeOrganic
                ? 'project.stepper.content_creation.free_organic.finish_deal'
                : finishDealMsg ||
                  `project.stepper.content_creation${paymentTypePrefix}.finish_deal`
            }
            className={cn(styles.btn, {
              [styles.noMarginBottom]: campaignType === PRODUCT_SEEDING,
            })}
            data-test="dealActions:button:unknown"
          />
        </FinishDealWrapperProvider>
      )}
      {campaignType !== PRODUCT_SEEDING && (
        <>
          <div className={styles.controls}>
            <Button
              color="normal"
              width="full"
              msg={sendRequestMsg}
              disabled={hasPriceChangeRequest}
              className={styles.control}
              onClick={handlePriceChangeRequest}
              data-test="dealActions:button:control"
            />
            {additionalElement && <div className={styles.control}>{additionalElement}</div>}
          </div>
          {!hasPriceChangeRequest && !additionalElement && (
            <Text
              size="sm"
              align="center"
              msg="project.stepper.content_creation.change_price.descr"
              className={styles.disclaimer}
              data-test="dealActions:text:descr"
            />
          )}
        </>
      )}
    </div>
  );
};

const DealActionsWrap = track()(DealActions);

export default DealActionsWrap;
