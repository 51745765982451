import React from 'react';
import classnames from 'classnames';

import styles from './PaidSocialLicenseCampaignList.pcss';

import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import type {
  PaidSocialLicenseModalQuery$data,
  CampaignPlatform,
} from 'GraphTypes/PaidSocialLicenseModalQuery.graphql';
import { getProjectsLink } from 'Util/links';
import { amplitude } from 'Helpers/amplitude';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import TextPreset from 'Components/ui/TextPreset/TextPreset';
import Image from 'Components/ui/Image/Image';

type Props = {
  className?: string;
  campaigns?: Campaigns;
};

const PaidSocialLicenseCampaignList: React.FC<Props> = (props) => {
  const { className, campaigns } = props;

  if (!campaigns || !campaigns?.length) return null;

  return (
    <div className={classnames(styles.root, className)}>
      {campaigns.map((item) => {
        const project = item?.node?.project;
        if (!project) return null;

        const {
          id: projectId,
          campaign: { name: campaignName, brand, id: campaignId, stage: campaignStage },
        } = project;

        const platform = project.campaign.platform as CampaignPlatform;

        const campaignAva = brand?.logo?.transformations?.brandLogoUrl;

        const projectLink = getProjectsLink({
          projectId,
          campaignId: campaignStage === 'ACTIVE' ? campaignId : undefined,
        });

        const handeChatClick = () => {
          amplitude.sendEvent({
            id: 130,
            category: 'creator_licensing_dashboard',
            name: 'hover_go_to_chat',
          });
        };

        return (
          <SmartLink
            key={projectId}
            path={projectLink}
            target="_blank"
            className={styles.campaign}
            onClick={handeChatClick}
          >
            <TextPreset
              header={
                <div className={styles.campaignHeader}>
                  <Image src={campaignAva} type="round" size={44} platform={platform || null} />
                  {campaignName && (
                    <Text type="h2" className={styles.headerText} text={campaignName} />
                  )}
                </div>
              }
              rightComponent={<Icon name="Chat" />}
            />
          </SmartLink>
        );
      })}
    </div>
  );
};

export default PaidSocialLicenseCampaignList;

export type Campaigns = NonNullable<
  NonNullable<PaidSocialLicenseModalQuery$data['paidSocialLicense']>['activeCampaigns']
>['edges'];
