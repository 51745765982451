import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './CustomListFavoritesDetails.pcss';

import Text from 'Components/ui/Text/Text';
import Image from 'Components/ui/Image/Image';
import HearthFilledIcon from 'Images/icons/heart-filled.svg';
import { CREATORS_FAVORITES_ROUTE } from 'Constants/general';

interface Props {
  totalCount: number;
}

const CustomListDetails: React.FC<Props> = (props) => {
  const { totalCount } = props;
  const navigate = useNavigate();

  const handleRootClick = useCallback(() => {
    navigate(CREATORS_FAVORITES_ROUTE);
  }, []);

  return (
    <>
      <div className={styles.root} onClick={handleRootClick}>
        <div className={styles.textPreset}>
          <Image src={HearthFilledIcon} size={24} />
          <Text type="h2" text={'Favorites'} data-test="customListFavoritesDetails:text:unknown" />
        </div>
        <Text
          type="label"
          className={styles.creatorsCountText}
          msg="creators.dashboard.lists.details.count"
          formatValues={{
            n: totalCount,
          }}
          data-test="customListFavoritesDetails:text:creatorsCountText"
        />
      </div>
    </>
  );
};

export default CustomListDetails;
