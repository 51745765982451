import React, { useCallback, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import styles from './AddAllByBrand.pcss';
import { createAmountsData } from './utils';

import Text from 'Components/ui/Text/Text';
import Tabs from 'Molecules/Tabs/Tabs';
import { CAMPAIGN_INVITED_ROUTE } from 'Constants/general';
import Button from 'Components/ui/Button/Button';
import Input from 'Components/ui/Input/Input';

const MAX_COUNT = 100;

type CountType = '25' | '50' | '75' | '100';

type Status = 'success' | 'fail';

interface Props {
  campaignId: string;
  creatorsCount: number;
  onAddClick: (count: number) => void;
}

const AddAllByBrand: React.FC<Props> = (props) => {
  const { campaignId, creatorsCount, onAddClick } = props;

  const [amount, setAmount] = useState<number | undefined>(undefined);
  const [selectedOption, setSelectedOption] = useState<CountType>();
  const [status, setStatus] = useState<Status>();

  const navigate = useNavigate();

  const isMaxLimitExceeded = !!(amount && amount > MAX_COUNT);

  const isMaxCreatorsExceeded = !!(amount && amount > creatorsCount);

  const handleAmountValueChange = useCallback((id: string) => {
    setSelectedOption(id as CountType);
    setAmount(Number(id));
  }, []);

  const handleAmountChange = useCallback((amount: string) => {
    if (amount) {
      const newValue = amount.replace(/[^0-9]/g, '');
      const newAmount = parseInt(newValue, 10) || undefined;
      setAmount(newAmount);
    } else {
      setAmount(undefined);
    }
  }, []);

  const amountsData = createAmountsData({ creatorsCount });

  const handleAddClick = useCallback(() => {
    if (amount) {
      onAddClick(amount);
    }
  }, [amount]);

  const handleGoToInvitedClick = useCallback(() => {
    const invitedTabUrl = generatePath(CAMPAIGN_INVITED_ROUTE, { id: campaignId });
    navigate(invitedTabUrl);
  }, [campaignId]);

  if (status === 'success') {
    return (
      <>
        <div className={styles.content}>
          <Text
            type="d2"
            msg="creators_bulk_adding_drawer.success.title"
            className={styles.title}
            data-test="addAllByBrand:text:title"
          />
          <Text
            type="md"
            msg="creators_bulk_adding_drawer.success.descr"
            className={styles.descr}
            data-test="addAllByBrand:text:descr"
          />
        </div>
        <Button
          color="black"
          onClick={handleGoToInvitedClick}
          msg="creators_bulk_adding_drawer.success.go_to"
          data-test="addAllByBrand:button:goTo"
        />
      </>
    );
  }

  const errorText = isMaxLimitExceeded ? (
    <Text
      type="md"
      msg="creators_bulk_adding_drawer.count.max_limt"
      formatValues={{ count: MAX_COUNT }}
      className={styles.error}
      data-test="addAllByBrand:text:maxLimt"
    />
  ) : isMaxCreatorsExceeded ? (
    <Text
      type="md"
      msg="creators_bulk_adding_drawer.count.max_filters_limt"
      formatValues={{ count: creatorsCount }}
      className={styles.error}
      data-test="addAllByBrand:text:maxFiltersLimt"
    />
  ) : undefined;

  return (
    <>
      <div className={styles.content}>
        <Text
          type="d2"
          msg="creators_bulk_adding_drawer.title"
          className={styles.title}
          data-test="addAllByBrand:text:title"
        />
        <Text
          type="md"
          msg="creators_bulk_adding_drawer.descr"
          className={styles.descr}
          data-test="addAllByBrand:text:descr"
        />
        <Tabs
          currentItemId={selectedOption ? (selectedOption as string) : undefined}
          items={amountsData}
          onChange={handleAmountValueChange}
          className={styles.tabs}
        />
        <Input
          bordered
          value={amount}
          placeholderMsg="creators_bulk_adding_drawer.count.placeholder"
          handleChange={handleAmountChange}
          className={styles.input}
          error={isMaxLimitExceeded || isMaxCreatorsExceeded}
          data-test="addAllByBrand:input:input"
        />
        {errorText}
      </div>
      <Button
        color="black"
        onClick={handleAddClick}
        disabled={!amount || isMaxLimitExceeded || isMaxCreatorsExceeded}
        msg="creators_bulk_adding_drawer.add"
        data-test="addAllByBrand:button:add"
      />
    </>
  );
};

export default AddAllByBrand;
