/**
 * @generated SignedSource<<65e4bc1185fb26bd8a135ac1b71e8c83>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CampaignPlatform = "FACEBOOK" | "INSTAGRAM" | "OTHER" | "SNAPCHAT" | "TIKTOK" | "YOUTUBE";
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING";
import { FragmentRefs } from "relay-runtime";
export type Actions_campaign$data = {
  readonly briefSubmission: {
    readonly acceptance: {
      readonly id: string;
    } | null | undefined;
  } | null | undefined;
  readonly creatorImport: {
    readonly completed: boolean;
    readonly id: string;
  } | null | undefined;
  readonly id: string;
  readonly mediaplanSubmission: {
    readonly id: string;
  } | null | undefined;
  readonly name: string | null | undefined;
  readonly platform: CampaignPlatform | null | undefined;
  readonly projectsCount: {
    readonly totalCount: number;
  };
  readonly type: CampaignType | null | undefined;
  readonly " $fragmentType": "Actions_campaign";
};
export type Actions_campaign$key = {
  readonly " $data"?: Actions_campaign$data;
  readonly " $fragmentSpreads": FragmentRefs<"Actions_campaign">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Actions_campaign",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "platform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MediaplanSubmission",
      "kind": "LinkedField",
      "name": "mediaplanSubmission",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BriefSubmission",
      "kind": "LinkedField",
      "name": "briefSubmission",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BriefSubmissionAcceptance",
          "kind": "LinkedField",
          "name": "acceptance",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "projectsCount",
      "args": [
        {
          "kind": "Literal",
          "name": "archived",
          "value": false
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "LAST_ADDED"
        }
      ],
      "concreteType": "ProjectConnection",
      "kind": "LinkedField",
      "name": "projects",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": "projects(archived:false,orderBy:\"LAST_ADDED\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CreatorImport",
      "kind": "LinkedField",
      "name": "creatorImport",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "completed",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};
})();

(node as any).hash = "4332e85d76f64481f3f3b2275ebc2c4c";

export default node;
