import React, { useContext, useMemo } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { SignUpCompleteContext, SignUpType, StepEnum, UserData } from '../Context';

import styles from './TermsContent.pcss';

import Text from 'Components/ui/Text/Text';
import { POLICY_PRIVACY_LINK, REFUND_POLICY_LINK, TERMS_LINK } from 'Constants/general';
import { Checkbox } from 'Components/ui/Checkbox';

type Props = {
  className?: string;
  handlePolicyClick?: (fieldName: keyof UserData[StepEnum.UserDetails], value: boolean) => void;
};

const TermsContent: React.FC<Props> = (props) => {
  const { className } = props;
  const { isFinalStep, signUpType, setUserData, userData } = useContext(SignUpCompleteContext);

  const intl = useIntl();

  const handlePolicyClick = (fieldName: keyof UserData[StepEnum.UserDetails]) => {
    setUserData(StepEnum.UserDetails, {
      [fieldName]: { value: !userData[StepEnum.UserDetails][fieldName].value },
    });
  };

  const formatValues = useMemo(() => {
    return {
      terms_of_service_link: (
        <a href={TERMS_LINK} className={styles.link} target="_blank">
          {intl.formatMessage({ id: 'signup_complete.advertiser.terms.link.terms_of_service' })}
        </a>
      ),
      privacy_policy_link: (
        <a href={POLICY_PRIVACY_LINK} className={styles.link} target="_blank">
          {intl.formatMessage({ id: 'signup_complete.advertiser.terms.link.privacy_policy' })}
        </a>
      ),
      refund_policy_link: (
        <a href={REFUND_POLICY_LINK} className={styles.link} target="_blank">
          {intl.formatMessage({ id: 'signup_complete.advertiser.terms.link.refund_policy_link' })}
        </a>
      ),
    };
  }, []);

  return (
    <div className={cn(styles.root, className)}>
      <div className={styles.checkboxContainer} onClick={() => handlePolicyClick('ppAndTos')}>
        <Checkbox
          checked={userData[StepEnum.UserDetails].ppAndTos.value}
          dataTest="checkbox:terms"
          data-test="termsContent:checkbox:unknown"
        />
        <Text
          type="sm"
          msg={
            isFinalStep && signUpType === SignUpType.Survey
              ? 'signup_complete.advertiser.terms.finish'
              : 'signup_complete.advertiser.terms.next'
          }
          className={styles.termsOfServiceText}
          formatValues={formatValues}
          data-test="termsContent:text:termsOfServiceText"
        />
      </div>
      <div className={styles.checkboxContainer} onClick={() => handlePolicyClick('refundPolicy')}>
        <Checkbox
          checked={userData[StepEnum.UserDetails].refundPolicy.value}
          dataTest="checkbox:policy"
          data-test="termsContent:checkbox:unknown"
        />
        <Text
          type="sm"
          msg={'signup_complete.advertiser.terms.link.refund_policy'}
          className={styles.termsOfServiceText}
          formatValues={formatValues}
          data-test="termsContent:text:termsOfServiceText"
        />
      </div>
    </div>
  );
};

export default TermsContent;
export { TermsContent };

// types
