import React from 'react';

import styles from './InlineTabs.pcss';
import InlineTab, { Props as TabProps } from './InlineTab/InlineTab';

interface Props {
  tabs: TabProps[];
}

const InlineTabs: React.FC<Props> = (props) => {
  const { tabs } = props;
  return (
    <div className={styles.root}>
      {tabs.map((tab) => {
        const { id } = tab;
        return <InlineTab key={id} {...tab} />;
      })}
    </div>
  );
};

export default InlineTabs;
