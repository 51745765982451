/**
 * @generated SignedSource<<ffedca28c1aefb58a68f2dd038c1ce3c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AdvertiserProjectStage = "ACCEPTED" | "ACCEPTED_AND_NOT_LAUNCHED" | "ACCEPTED_BY_UNREVIEWED_CREATOR" | "COMPLETED" | "CONTENT_SUBMITTED" | "LAUNCHED" | "PRODUCT_SHIPMENT_COMPLETED" | "PRODUCT_SHIPMENT_STARTED" | "PUBLISHING_STARTED" | "READ" | "REJECTED" | "UNREAD" | "UNSENT";
export type CreatorsQuery$variables = {
  archived?: boolean | null | undefined;
  archivedAfterLaunch?: boolean | null | undefined;
  archivedBeforeLaunch?: boolean | null | undefined;
  hasBrandMessageBeforeLaunch?: boolean | null | undefined;
  id: string;
  stages?: ReadonlyArray<AdvertiserProjectStage> | null | undefined;
};
export type CreatorsQuery$data = {
  readonly campaign: {
    readonly projects: {
      readonly hasItems: boolean;
    };
  } | null | undefined;
};
export type CreatorsQuery = {
  response: CreatorsQuery$data;
  variables: CreatorsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "archived"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "archivedAfterLaunch"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "archivedBeforeLaunch"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasBrandMessageBeforeLaunch"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "stages"
},
v6 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "archived",
      "variableName": "archived"
    },
    {
      "kind": "Variable",
      "name": "archivedAfterLaunch",
      "variableName": "archivedAfterLaunch"
    },
    {
      "kind": "Variable",
      "name": "archivedBeforeLaunch",
      "variableName": "archivedBeforeLaunch"
    },
    {
      "kind": "Variable",
      "name": "hasBrandMessageBeforeLaunch",
      "variableName": "hasBrandMessageBeforeLaunch"
    },
    {
      "kind": "Variable",
      "name": "stages",
      "variableName": "stages"
    }
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasItems",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreatorsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreatorsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a730335641bfa0ec0c89675fa78fdbe7",
    "id": null,
    "metadata": {},
    "name": "CreatorsQuery",
    "operationKind": "query",
    "text": "query CreatorsQuery(\n  $id: ID!\n  $stages: [AdvertiserProjectStage!]\n  $archived: Boolean\n  $hasBrandMessageBeforeLaunch: Boolean\n  $archivedAfterLaunch: Boolean\n  $archivedBeforeLaunch: Boolean\n) {\n  campaign(id: $id) {\n    projects(stages: $stages, archived: $archived, archivedAfterLaunch: $archivedAfterLaunch, archivedBeforeLaunch: $archivedBeforeLaunch, hasBrandMessageBeforeLaunch: $hasBrandMessageBeforeLaunch) {\n      hasItems\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a73e2f3f6ba04a91dfb5e18bee82b6c6";

export default node;
