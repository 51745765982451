import React from 'react';
import compact from 'lodash/compact';

import styles from './SearchParams.pcss';

import DropDown from 'Molecules/DropDown/DropDown';
import {
  ARRAY,
  RANGE,
  SINGLE_VALUE,
  RADIO,
  AUDIENCE_GROUP,
  CREATOR_GROUP,
  SELECT,
  AUTOCOMPLETE,
  TEXT_INPUT,
  CHECKBOX,
  BOOLEAN,
  CUSTOM,
  ORGANIC_POSTING,
} from 'Constants/general';
import CityList from 'Components/CityList/CityList';
import Checkbox from 'Atoms/Checkbox/Checkbox';
import PrimaryCategoryCheckbox from 'Molecules/PrimaryCategoryCheckbox/PrimaryCategoryCheckbox';
import { getBirthdateByAge, getAges } from 'Util/dateCreator';
import { sortCountries } from 'Util/dataFormatter.js';
import type { SearchParamsQuery$data } from 'GraphTypes/SearchParamsQuery.graphql';
import { PERCENTAGE_OPTIONS } from 'Constants/data';

const getNum = (num: number) => {
  if (num < 1000000) {
    return `${num / 1000}k`;
  }

  return '> 1m';
};

const viewsItems = [
  { id: 1000, value: 1000, name: getNum(1000) },
  { id: 5000, value: 5000, name: getNum(5000) },
  { id: 10000, value: 10000, name: getNum(10000) },
  { id: 50000, value: 50000, name: getNum(50000) },
  { id: 100000, value: 100000, name: getNum(100000) },
  { id: 500000, value: 500000, name: getNum(500000) },
  { id: 1000000, value: 1000000, name: getNum(1000000) },
];

export const getParmas = (isAdmin?: boolean) => {
  return compact([
    {
      type: ARRAY,
      key: 'countries',
      group: 'audience',
      additionalKey: 'audienceCountryPercentage',
    },
    { type: ARRAY, key: 'creatorCountries', group: 'creator' },
    { type: ARRAY, key: 'cities', group: 'creator' },
    {
      type: ARRAY,
      key: 'audienceCities',
      group: 'audience',
      additionalKey: 'audienceCityPercentage',
    },
    { type: ARRAY, key: 'categories', group: 'creator' },
    {
      type: ARRAY,
      key: 'ageRanges',
      group: 'audience',
      additionalKey: 'audienceAgeRangePercentage',
    },
    { type: RANGE, key: 'creatorBirthdate', group: 'creator', reverse: true },
    { type: ARRAY, key: 'creatorEthnicities', group: 'creator' },
    { type: ARRAY, key: 'customLists', group: 'creator' },
    { type: ARRAY, key: 'audienceEthnicities', group: 'audience' },
    {
      type: SINGLE_VALUE,
      key: 'audienceGender',
      group: 'audience',
      additionalKey: 'audienceGenderPercentage',
    },
    { type: SINGLE_VALUE, key: 'creatorGender', group: 'creator' },
    { type: RANGE, key: 'followers', group: 'creator' },
    { type: RANGE, key: 'realFollowers', group: 'creator' },
    { type: RANGE, key: 'engagementRate', group: 'creator' },
    { type: RANGE, key: 'viewsPerPost', group: 'creator' },
    { type: RANGE, key: 'viewsMedian', group: 'creator' },
    { type: ARRAY, key: 'creatorLanguages', group: 'creator' },
    { type: ARRAY, key: 'creatorAnimals', group: 'creator' },
    isAdmin ? { type: ARRAY, key: 'creatorBodyTypes', group: 'creator' } : null,
    isAdmin ? { type: ARRAY, key: 'creatorHairTypes', group: 'creator' } : null,
    isAdmin ? { type: RANGE, key: 'campaignsCompletionsCount', group: 'creator' } : null,
    isAdmin ? { type: ARRAY, key: 'tags', group: 'creator' } : null,
    isAdmin ? { type: BOOLEAN, key: 'excludeBadges', group: 'creator' } : null,
    isAdmin ? { type: BOOLEAN, key: 'experienced', group: 'creator' } : null,
    isAdmin ? { type: BOOLEAN, key: 'highAverageRating', group: 'creator' } : null,
    isAdmin ? { type: BOOLEAN, key: 'reliable', group: 'creator' } : null,
    { type: BOOLEAN, key: 'includeAmazonStorefrontLink' },
    { type: BOOLEAN, key: 'withTiktokShop' },
    { type: BOOLEAN, key: 'favorite' },
    { type: BOOLEAN, key: 'onlyCreatorsWorkedWith' },
    isAdmin ? { type: RANGE, key: 'storiesReach', group: 'creator' } : null,
    isAdmin ? { type: RANGE, key: 'postsReach', group: 'creator' } : null,
    isAdmin ? { type: RANGE, key: 'reelsPlaysMedian', group: 'creator' } : null,
    isAdmin ? { type: ARRAY, key: 'audienceInterestPercentage', group: 'audience' } : null,
  ]);
};

export const createItems = (
  data: any,
  isAdmin: boolean,
  accentConfig: { location: boolean; gender: boolean; age: boolean; categories: boolean }
) => {
  const {
    ageRanges,
    genders,
    countries,
    categories,
    ethnicities,
    followersFrom,
    followersTo,
    realFollowersTo,
    realFollowersFrom,
    creatorBirthdateFrom,
    creatorBirthdateTo,
    engagementRateFrom,
    engagementRateTo,
    languages,
    animals,
    bodyTypes,
    hairTypes,
    campaignsCompletionsCountFrom,
    campaignsCompletionsCountTo,
    tags,
    campaign: { type, platform },
    strictlyByPrimaryCategory,
  } = data;

  const countriesList = sortCountries(countries);

  const minVersion = type === 'CONTENT_CREATION';

  const languagesList = languages.map((item) => {
    return { id: item.id, name: item.name };
  });
  return [
    compact([
      {
        isDropdown: true,
        dropDownlabel: 'location',
        eventName: 'location',
        dropDownStyle: styles.geoDropdown,
        accent: accentConfig.location,
        accentMsg: 'brief_template.field.location.accent',
        contents: compact([
          {
            view: 'toggle',
            group: CREATOR_GROUP,
            items: compact([
              {
                name: 'creatorCountries',
                type: SELECT,
                group: CREATOR_GROUP,
                style: { width: '280px' },
                placeholderMsg: 'search_section.form.location.country.placeholder',
              },
              {
                name: 'cities',
                type: AUTOCOMPLETE,
                group: CREATOR_GROUP,
                inputData: {
                  style: { width: '280px' },
                  placeholderMsg: 'search_section.form.location.city.placeholder',
                },
                queryRequest: <CityList />,
              },
            ]),
          },
          !minVersion
            ? {
                view: 'toggle',
                group: AUDIENCE_GROUP,
                items: compact([
                  {
                    name: 'countries',
                    type: SELECT,
                    group: AUDIENCE_GROUP,
                    style: { width: '280px' },
                    placeholderMsg: 'search_section.form.location.country.placeholder',
                    percentageItem: {
                      name: 'audienceCountryPercentage',
                      type: SELECT,
                      isSingle: true,
                      style: { marginTop: '8px' },
                      placeholderMsg: 'search_section.form.percentage.placeholder',
                      items: { audienceCountryPercentage: PERCENTAGE_OPTIONS },
                    },
                    accentMsg: 'brief_template.field.location.accent',
                  },
                  {
                    name: 'audienceCities',
                    type: AUTOCOMPLETE,
                    group: AUDIENCE_GROUP,
                    inputData: {
                      style: { width: '280px' },
                      placeholderMsg: 'search_section.form.location.city.placeholder',
                    },
                    queryRequest: <CityList />,
                    percentageItem: {
                      name: 'audienceCityPercentage',
                      type: SELECT,
                      isSingle: true,
                      style: { marginTop: '8px' },
                      placeholderMsg: 'search_section.form.percentage.placeholder',
                      items: { audienceCityPercentage: PERCENTAGE_OPTIONS },
                    },
                    accentMsg: 'brief_template.field.location.accent',
                  },
                ]),
              }
            : null,
        ]),
        items: { countries: countriesList, creatorCountries: countriesList },
      },
      isAdmin
        ? {
            isDropdown: true,
            dropDownlabel: 'categories',
            eventName: 'categories',
            accent: accentConfig?.categories,
            accentMsg: 'brief_template.field.categories.accent',
            dropDownStyle: styles.primaryCategoryDropDown,
            contents: [
              {
                name: 'categories',
                type: SELECT,
                group: CREATOR_GROUP,
                placeholderMsg: 'search_section.form.categories.category.placeholder',
              },
              {
                name: 'strictlyByPrimaryCategory',
                type: CUSTOM,
                customElement: <PrimaryCategoryCheckbox />,
              },
            ],
            items: { categories, strictlyByPrimaryCategory },
          }
        : {
            isDropdown: true,
            dropDownlabel: 'categories',
            eventName: 'categories',
            accent: accentConfig.categories,
            contents: [
              {
                name: 'categories',
                type: SELECT,
                group: CREATOR_GROUP,
                placeholderMsg: 'search_section.form.categories.category.placeholder',
              },
            ],
            items: { categories },
            accentMsg: 'brief_template.field.categories.accent',
          },
      {
        isDropdown: true,
        dropDownlabel: 'gender',
        eventName: 'gender',
        dropDownStyle: styles.genderDropdown,
        accent: accentConfig.gender,
        accentMsg: 'brief_template.field.gender.accent',
        contents: compact([
          { name: 'creatorGender', type: RADIO, group: CREATOR_GROUP },
          !minVersion ? { name: 'audienceGender', type: RADIO, group: AUDIENCE_GROUP } : null,
          !minVersion
            ? {
                name: 'audienceGenderPercentage',
                type: SELECT,
                isSingle: true,
                style: { marginTop: '8px' },
                placeholderMsg: 'search_section.form.percentage.placeholder',
              }
            : null,
        ]),
        items: {
          audienceGender: genders,
          creatorGender: genders,
          audienceGenderPercentage: PERCENTAGE_OPTIONS,
        },
      },
      {
        isDropdown: true,
        dropDownlabel: 'age',
        eventName: 'age',
        accent: accentConfig.age,
        accentMsg: 'brief_template.field.age.accent',
        dropDownStyle: styles.ageDropDown,
        contents: [
          {
            name: 'creatorBirthdateTo',
            reverse: true,
            type: TEXT_INPUT,
            group: CREATOR_GROUP,
            placeholderMsg: 'search_section.form.creator_age.from.placeholder',
          },
          {
            name: 'creatorBirthdateFrom',
            reverse: true,
            type: TEXT_INPUT,
            placeholderMsg: 'search_section.form.creator_age.to.placeholder',
          },
          {
            name: 'ageRanges',
            type: SELECT,
            group: AUDIENCE_GROUP,
            placeholderMsg: 'search_section.form.age.placeholder',
          },
          {
            name: 'audienceAgeRangePercentage',
            type: SELECT,
            isSingle: true,
            placeholderMsg: 'search_section.form.percentage.placeholder',
          },
        ],
        items: {
          creatorBirthdateFrom,
          creatorBirthdateTo,
          ageRanges,
          audienceAgeRangePercentage: PERCENTAGE_OPTIONS,
        },
      },
      {
        isDropdown: true,
        dropDownlabel: 'ethnicity',
        eventName: 'ethnicities',

        contents: [
          {
            name: 'creatorEthnicities',
            type: SELECT,
            group: CREATOR_GROUP,
            placeholderMsg: 'search_section.form.ethnicity.ethnicity.placeholder',
          },
          {
            name: 'audienceEthnicities',
            type: SELECT,
            group: AUDIENCE_GROUP,
            placeholderMsg: 'search_section.form.ethnicity.ethnicity.placeholder',
          },
        ],
        items: { audienceEthnicities: ethnicities, creatorEthnicities: ethnicities },
      },
      {
        isDropdown: true,
        dropDownlabel: 'followers',
        eventName: 'followers',

        contents: [
          {
            name: 'followersFrom',
            type: TEXT_INPUT,
            group: CREATOR_GROUP,
            placeholderMsg: 'search_section.form.followers.from.placeholder',
          },
          {
            name: 'followersTo',
            type: TEXT_INPUT,
            placeholderMsg: 'search_section.form.followers.to.placeholder',
          },
        ],
        items: { followersFrom, followersTo },
      },
      platform === 'INSTAGRAM'
        ? {
            isDropdown: true,
            dropDownlabel: 'realFollowers',
            eventName: 'realFollowers',

            contents: [
              {
                name: 'realFollowersFrom',
                type: TEXT_INPUT,
                group: CREATOR_GROUP,
                placeholderMsg: 'search_section.form.realFollowers.from.placeholder',
              },
              {
                name: 'realFollowersTo',
                type: TEXT_INPUT,
                placeholderMsg: 'search_section.form.realFollowers.to.placeholder',
              },
            ],
            items: { realFollowersFrom, realFollowersTo },
          }
        : null,
      {
        isDropdown: true,
        dropDownlabel: 'er',
        eventName: 'er',

        contents: [
          {
            name: 'engagementRateFrom',
            type: TEXT_INPUT,
            group: CREATOR_GROUP,
            placeholderMsg: 'search_section.form.er.from.placeholder',
          },
          {
            name: 'engagementRateTo',
            type: TEXT_INPUT,
            placeholderMsg: 'search_section.form.er.to.placeholder',
          },
        ],
        items: { engagementRateFrom, engagementRateTo },
      },
      platform === 'TIKTOK'
        ? {
            isDropdown: true,
            dropDownlabel: 'viewsPerPost',
            eventName: 'viewsPerPost',

            contents: [
              {
                name: 'viewsPerPostFrom',
                type: SELECT,
                isSingle: true,
                group: CREATOR_GROUP,
                placeholderMsg: 'search_section.form.followers.from.placeholder',
              },
              {
                name: 'viewsPerPostTo',
                type: SELECT,
                isSingle: true,
                placeholderMsg: 'search_section.form.followers.to.placeholder',
              },
            ],
            items: { viewsPerPostFrom: viewsItems, viewsPerPostTo: viewsItems },
          }
        : null,
      platform === 'TIKTOK'
        ? {
            isDropdown: true,
            dropDownlabel: 'viewsMedian',
            eventName: 'viewsMedian',

            contents: [
              {
                name: 'viewsMedianFrom',
                type: SELECT,
                isSingle: true,
                group: CREATOR_GROUP,
                placeholderMsg: 'search_section.form.followers.from.placeholder',
              },
              {
                name: 'viewsMedianTo',
                type: SELECT,
                isSingle: true,
                placeholderMsg: 'search_section.form.followers.to.placeholder',
              },
            ],
            items: { viewsMedianFrom: viewsItems, viewsMedianTo: viewsItems },
          }
        : null,
      platform === 'INSTAGRAM' && isAdmin
        ? {
            isDropdown: true,
            dropDownlabel: 'postsReach',
            eventName: 'postsReach',

            contents: [
              {
                name: 'postsReachFrom',
                type: TEXT_INPUT,
                isSingle: true,
                group: CREATOR_GROUP,
                placeholderMsg: 'search_section.form.followers.from.placeholder',
              },
              {
                name: 'postsReachTo',
                type: TEXT_INPUT,
                isSingle: true,
                placeholderMsg: 'search_section.form.followers.to.placeholder',
              },
            ],
            items: { postsReachFrom: viewsItems, postsReachTo: viewsItems },
          }
        : null,
      platform === 'INSTAGRAM' && isAdmin
        ? {
            isDropdown: true,
            dropDownlabel: 'storiesReach',
            eventName: 'storiesReach',

            contents: [
              {
                name: 'storiesReachFrom',
                type: TEXT_INPUT,
                isSingle: true,
                group: CREATOR_GROUP,
                placeholderMsg: 'search_section.form.followers.from.placeholder',
              },
              {
                name: 'storiesReachTo',
                type: TEXT_INPUT,
                isSingle: true,
                placeholderMsg: 'search_section.form.followers.to.placeholder',
              },
            ],
            items: { storiesReachFrom: viewsItems, storiesReachTo: viewsItems },
          }
        : null,
      platform === 'INSTAGRAM' && isAdmin
        ? {
            isDropdown: true,
            dropDownlabel: 'reelsPlaysMedian',
            eventName: 'reelsPlaysMedian',

            contents: [
              {
                name: 'reelsPlaysMedianFrom',
                type: TEXT_INPUT,
                isSingle: true,
                group: CREATOR_GROUP,
                placeholderMsg: 'search_section.form.followers.from.placeholder',
              },
              {
                name: 'reelsPlaysMedianTo',
                type: TEXT_INPUT,
                isSingle: true,
                placeholderMsg: 'search_section.form.followers.to.placeholder',
              },
            ],
            items: { reelsPlaysMedianFrom: viewsItems, reelsPlaysMedianTo: viewsItems },
          }
        : null,
      {
        isDropdown: true,
        dropDownlabel: 'language',
        eventName: 'creatorLanguages',

        contents: [
          {
            name: 'creatorLanguages',
            type: SELECT,
            group: CREATOR_GROUP,
            placeholderMsg: 'creator.language.placeholder',
          },
        ],
        items: { creatorLanguages: languagesList },
      },
      {
        isDropdown: true,
        dropDownlabel: 'animals',
        eventName: 'animals',

        contents: [{ name: 'creatorAnimals', type: CHECKBOX, group: CREATOR_GROUP }],
        items: { creatorAnimals: animals },
      },
      isAdmin
        ? {
            isDropdown: true,
            dropDownlabel: 'body',
            eventName: 'body',

            contents: [{ name: 'creatorBodyTypes', type: CHECKBOX, group: CREATOR_GROUP }],
            items: { creatorBodyTypes: bodyTypes },
          }
        : null,
      isAdmin
        ? {
            isDropdown: true,
            dropDownlabel: 'hair',
            eventName: 'hair',

            contents: [{ name: 'creatorHairTypes', type: CHECKBOX, group: CREATOR_GROUP }],
            items: { creatorHairTypes: hairTypes },
          }
        : null,
      isAdmin
        ? {
            isDropdown: true,
            dropDownlabel: 'campaignsCompletionsCount_multiple',
            eventName: 'campaignsCompletionsCount',

            contents: [
              {
                name: 'campaignsCompletionsCountFrom',
                type: TEXT_INPUT,
                group: CREATOR_GROUP,
                placeholderMsg: 'search_section.form.campaignsCompletionsCount.from.placeholder',
              },
              {
                name: 'campaignsCompletionsCountTo',
                type: TEXT_INPUT,
                placeholderMsg: 'search_section.form.campaignsCompletionsCount.to.placeholder',
              },
            ],
            items: { campaignsCompletionsCountFrom, campaignsCompletionsCountTo },
          }
        : null,
      isAdmin
        ? {
            isDropdown: true,
            dropDownlabel: 'tags',
            eventName: 'tags',

            contents: [{ name: 'tags', type: CHECKBOX, group: CREATOR_GROUP }],
            items: { tags },
          }
        : null,
    ]),
  ];
};

export type ParamsKitElementKeyType =
  | 'ageRanges'
  | 'audienceCities'
  | 'audienceGenderPercentage'
  | 'audienceAgeRangePercentage'
  | 'audienceCountryPercentage'
  | 'audienceGender'
  | 'categories'
  | 'cities'
  | 'countries'
  | 'tags'
  | 'creatorCountries'
  | 'currency'
  | 'creatorEthnicities'
  | 'creatorAnimals'
  | 'audienceEthnicities'
  | 'creatorBirthdateFrom'
  | 'creatorBirthdateTo'
  | 'engagementRateFrom'
  | 'engagementRateTo'
  | 'followersFrom'
  | 'followersTo'
  | 'realFollowersFrom'
  | 'realFollowersTo'
  | 'viewsPerPostFrom'
  | 'viewsPerPostTo'
  | 'viewsMedianFrom'
  | 'viewsMedianTo'
  | 'storiesReachFrom'
  | 'storiesReachTo'
  | 'audienceInterestPercentage'
  | 'postsReachFrom'
  | 'postsReachTo'
  | 'reelsPlaysMedianFrom'
  | 'reelsPlaysMedianTo'
  | 'campaignsCompletionsCountFrom'
  | 'campaignsCompletionsCountTo'
  | 'creatorGender'
  | 'creatorLanguages'
  | 'creatorBodyTypes'
  | 'creatorHairTypes'
  | 'excludeBadges'
  | 'strictlyByPrimaryCategory'
  | 'includeAmazonStorefrontLink'
  | 'withTiktokShop'
  | 'favorite'
  | 'onlyCreatorsWorkedWith'
  | 'experienced'
  | 'highAverageRating'
  | 'reliable'
  | 'audienceCityPercentage'
  | 'customLists';

export type ParamsKitElementType = Partial<
  Record<ParamsKitElementKeyType, Array<unknown> | unknown>
>;
export const createParamsKit = (data: any): ParamsKitElementType => {
  const {
    ageRanges,
    genders,
    countries,
    categories,
    ethnicities,
    followersFrom,
    followersTo,
    realFollowersTo,
    realFollowersFrom,
    creatorBirthdateFrom,
    creatorBirthdateTo,
    engagementRateFrom,
    engagementRateTo,
    languages,
    animals,
    bodyTypes,
    hairTypes,
    campaignsCompletionsCountFrom,
    campaignsCompletionsCountTo,
    tags,
    strictlyByPrimaryCategory,
  } = data;

  const countriesList = sortCountries(countries);

  const languagesList = languages.map((item) => {
    return { id: item.id, name: item.name };
  });

  return {
    ageRanges,
    countries: countriesList,
    creatorCountries: countriesList,
    categories: categories,
    strictlyByPrimaryCategory,
    creatorGender: genders,
    audienceGender: genders,
    audienceGenderPercentage: PERCENTAGE_OPTIONS,
    creatorBirthdateFrom,
    creatorBirthdateTo,
    audienceAgeRangePercentage: PERCENTAGE_OPTIONS,
    audienceCountryPercentage: PERCENTAGE_OPTIONS,
    creatorEthnicities: ethnicities,
    audienceEthnicities: ethnicities,
    audienceCityPercentage: PERCENTAGE_OPTIONS,
    followersFrom,
    followersTo,
    realFollowersFrom,
    realFollowersTo,
    engagementRateFrom,
    engagementRateTo,
    viewsPerPostFrom: viewsItems,
    viewsPerPostTo: viewsItems,
    viewsMedianFrom: viewsItems,
    viewsMedianTo: viewsItems,
    postsReachFrom: viewsItems,
    postsReachTo: viewsItems,
    storiesReachFrom: viewsItems,
    storiesReachTo: viewsItems,
    reelsPlaysMedianFrom: viewsItems,
    reelsPlaysMedianTo: viewsItems,
    creatorLanguages: languagesList,
    creatorAnimals: animals,
    creatorBodyTypes: bodyTypes,
    creatorHairTypes: hairTypes,
    campaignsCompletionsCountFrom,
    campaignsCompletionsCountTo,
    tags,
    customLists: data?.campaign?.organization?.customLists?.edges?.map((item) => item?.node),
    includeAmazonStorefrontLink: getAmazonDropdownItems(data),
    withTiktokShop: getTiktokShopDropdownItems(data),
    onlyCreatorsWorkedWith: getOnlyWorkedCreatorsDropdownItems(data),
    excludeBadges: getDropdownItems(data),
    favorite: getFavoriteDropdownItems(data),
  };
};

type GetDropdownItems = (data: SearchQuery) => DropDownItem[];
export const getDropdownItems: GetDropdownItems = (data) => {
  const items: DropDownItem[] = [
    {
      id: 'excludeBadges',
      name: 'excludeBadges',
      possibleValue: true,
      textData: {
        textData: { msg: 'search_section.form.exclude_badges' },
      },
    },
  ];

  return items.map((item) => {
    const { name, possibleValue, textData } = item;
    const isChecked = Boolean(data && data[name] === possibleValue);
    const icon = <Checkbox active={isChecked} data-test="data:checkbox:unknown" />;

    return { ...item, textData: { ...textData, leftIcon: icon } };
  });
};

type GetAmazonDropdownItems = (data: SearchQuery) => DropDownItem[];
export const getAmazonDropdownItems: GetAmazonDropdownItems = (data) => {
  const items: DropDownItem[] = [
    {
      id: 'includeAmazonStorefrontLink',
      name: 'includeAmazonStorefrontLink',
      possibleValue: true,
      textData: {
        textData: { msg: 'search_section.form.include_amazon_link' },
      },
    },
  ];

  return items.map((item) => {
    const { name, possibleValue, textData } = item;
    const isChecked = Boolean(data && data[name] === possibleValue);
    const icon = <Checkbox active={isChecked} data-test="data:checkbox:unknown" />;

    return { ...item, textData: { ...textData, leftIcon: icon } };
  });
};

type GetTiktokShopDropdownItems = (data: SearchQuery) => DropDownItem[];
export const getTiktokShopDropdownItems: GetTiktokShopDropdownItems = (data) => {
  const items: DropDownItem[] = [
    {
      id: 'withTiktokShop',
      name: 'withTiktokShop',
      possibleValue: true,
      textData: {
        textData: { msg: 'search_section.form.withTiktokShop.item' },
      },
    },
  ];

  return items.map((item) => {
    const { name, possibleValue, textData } = item;
    const isChecked = Boolean(data && data[name] === possibleValue);
    const icon = <Checkbox active={isChecked} data-test="data:checkbox:unknown" />;

    return { ...item, textData: { ...textData, leftIcon: icon } };
  });
};

export const getOnlyWorkedCreatorsDropdownItems: GetAmazonDropdownItems = (data) => {
  const items: DropDownItem[] = [
    {
      id: 'onlyCreatorsWorkedWith',
      name: 'onlyCreatorsWorkedWith',
      possibleValue: true,
      textData: {
        textData: { msg: 'search_section.form.onlyCreatorsWorkedWithNew' },
      },
    },
  ];

  return items.map((item) => {
    const { name, possibleValue, textData } = item;
    const isChecked = Boolean(data && data[name] === possibleValue);
    const icon = <Checkbox active={isChecked} data-test="data:checkbox:unknown" />;

    return { ...item, textData: { ...textData, leftIcon: icon } };
  });
};

export const getFavoriteDropdownItems: GetAmazonDropdownItems = (data) => {
  const items: DropDownItem[] = [
    {
      id: 'favorite',
      name: 'favorite',
      possibleValue: true,
      textData: {
        textData: { msg: 'search_section.form.favorite' },
      },
    },
  ];

  return items.map((item) => {
    const { name, possibleValue, textData } = item;
    const isChecked = Boolean(data && data[name] === possibleValue);
    const icon = <Checkbox active={isChecked} data-test="data:checkbox:unknown" />;

    return { ...item, textData: { ...textData, leftIcon: icon } };
  });
};

type GetFavoritesDropdownItems = (data: SearchQuery) => DropDownItem[];
export const getFavoritesDropdownItems: GetFavoritesDropdownItems = (data) => {
  const items: DropDownItem[] = [
    {
      id: 'favorite',
      name: 'favorite',
      possibleValue: true,
      textData: {
        textData: { msg: 'search_section.form.creators_added_to_favorites' },
      },
    },
  ];

  return items.map((item) => {
    const { name, possibleValue, textData } = item;
    const isChecked = Boolean(data && data[name] === possibleValue);
    const icon = <Checkbox active={isChecked} data-test="data:checkbox:unknown" />;

    return { ...item, textData: { ...textData, leftIcon: icon } };
  });
};

export const createData = (data: any) => {
  const {
    ageRanges,
    audienceGender,
    creatorGender,
    categories,
    audienceEthnicities,
    creatorEthnicities,
    countries,
    creatorCountries,
    cities,
    audienceCities,
    followersFrom,
    followersTo,
    realFollowersFrom,
    realFollowersTo,
    postPriceFrom,
    postPriceTo,
    creatorBirthdateFrom,
    creatorBirthdateTo,
    engagementRateFrom,
    engagementRateTo,
    viewsPerPostFrom,
    viewsPerPostTo,
    viewsMedianFrom,
    viewsMedianTo,
    storiesReachFrom,
    storiesReachTo,
    interestIds,
    audienceInterestPercentage,
    postsReachFrom,
    postsReachTo,
    reelsPlaysMedianFrom,
    reelsPlaysMedianTo,
    creatorLanguages,
    creatorAnimals,
    creatorBodyTypes,
    creatorHairTypes,
    campaignsCompletionsCountFrom,
    campaignsCompletionsCountTo,
    tags,
    excludeBadges,
    strictlyByPrimaryCategory,
    includeAmazonStorefrontLink,
    withTiktokShop,
    favorite,
    audienceCountryPercentage,
    audienceGenderPercentage,
    audienceAgeRangePercentage,
    onlyCreatorsWorkedWith,
    audienceCityPercentage,
    customLists,
    experienced,
    highAverageRating,
    reliable,
  } = data;

  const ageRangeIds = ageRanges ? ageRanges.map((item) => item.id) : undefined;
  const audienceGenderId = Object.prototype.hasOwnProperty.call(data, 'audienceGender')
    ? audienceGender
      ? audienceGender.id
      : null
    : undefined;
  const creatorGenderId = Object.prototype.hasOwnProperty.call(data, 'creatorGender')
    ? creatorGender
      ? creatorGender.id
      : null
    : undefined;
  const categoryIds = categories ? categories.map((item) => item.id) : undefined;
  const audienceEthnicityIds = audienceEthnicities
    ? audienceEthnicities.map((item) => item.id)
    : undefined;
  const creatorEthnicityIds = creatorEthnicities
    ? creatorEthnicities.map((item) => item.id)
    : undefined;
  const countryIds = countries ? countries.map((item) => item.id) : undefined;
  const creatorCountryIds = creatorCountries ? creatorCountries.map((item) => item.id) : undefined;
  const creatorLanguageIds = creatorLanguages ? creatorLanguages.map((item) => item.id) : undefined;
  const creatorAnimalIds = creatorAnimals ? creatorAnimals.map((item) => item.id) : undefined;
  const creatorBodyTypeIds = creatorBodyTypes ? creatorBodyTypes.map((item) => item.id) : undefined;
  const creatorHairTypeIds = creatorHairTypes ? creatorHairTypes.map((item) => item.id) : undefined;
  const tagIds = tags ? tags.map((item) => item.id) : undefined;
  const cityIds = cities ? cities.map((item) => item.id) : undefined;
  const audienceCitiesIds = audienceCities ? audienceCities.map((item) => item.id) : undefined;
  const customListIds = customLists ? customLists.map((item) => item.id) : undefined;

  const realFollowersFromValue = Object.prototype.hasOwnProperty.call(data, 'realFollowersFrom')
    ? realFollowersFrom
      ? realFollowersFrom / 100
      : null
    : undefined;

  const realFollowersToValue = Object.prototype.hasOwnProperty.call(data, 'realFollowersTo')
    ? realFollowersTo
      ? realFollowersTo / 100
      : null
    : undefined;
  const creatorBirthdateFromValue = Object.prototype.hasOwnProperty.call(
    data,
    'creatorBirthdateFrom'
  )
    ? creatorBirthdateFrom
      ? getBirthdateByAge(creatorBirthdateFrom + 1)
      : null
    : undefined;
  const creatorBirthdateToValue = Object.prototype.hasOwnProperty.call(data, 'creatorBirthdateTo')
    ? creatorBirthdateTo
      ? getBirthdateByAge(creatorBirthdateTo)
      : null
    : undefined;
  const engagementRateFromValue = Object.prototype.hasOwnProperty.call(data, 'engagementRateFrom')
    ? engagementRateFrom
      ? engagementRateFrom / 100
      : null
    : undefined;
  const engagementRateToValue = Object.prototype.hasOwnProperty.call(data, 'engagementRateTo')
    ? engagementRateTo
      ? engagementRateTo / 100
      : null
    : undefined;

  const formattedData = {
    ageRangeIds,
    audienceGenderId,
    creatorGenderId,
    categoryIds,
    audienceEthnicityIds,
    creatorEthnicityIds,
    countryIds,
    creatorCountryIds,
    cityIds,
    audienceCitiesIds,
    followersFrom,
    followersTo,
    realFollowersFrom: realFollowersFromValue,
    realFollowersTo: realFollowersToValue,
    postPriceFrom,
    postPriceTo,
    creatorBirthdateFrom: creatorBirthdateFromValue,
    creatorBirthdateTo: creatorBirthdateToValue,
    engagementRateFrom: engagementRateFromValue,
    engagementRateTo: engagementRateToValue,
    viewsPerPostFrom,
    viewsPerPostTo,
    viewsMedianFrom,
    viewsMedianTo,
    storiesReachFrom,
    storiesReachTo,
    interestIds,
    audienceInterestPercentage,
    postsReachFrom,
    postsReachTo,
    reelsPlaysMedianFrom,
    reelsPlaysMedianTo,
    creatorLanguageIds,
    creatorAnimalIds,
    creatorBodyTypeIds,
    creatorHairTypeIds,
    campaignsCompletionsCountFrom,
    campaignsCompletionsCountTo,
    creatorTagIds: tagIds,
    excludeBadges,
    strictlyByPrimaryCategory,
    includeAmazonStorefrontLink,
    withTiktokShop,
    favorite,
    audienceCountryPercentage,
    audienceGenderPercentage,
    audienceAgeRangePercentage,
    onlyCreatorsWorkedWith,
    audienceCityPercentage,
    customListIds,
    experienced,
    highAverageRating,
    reliable,
  };

  return formattedData;
};

export const createSearchData = (data: any) => {
  const {
    ageRanges,
    audienceGender,
    creatorGender,
    categories,
    audienceEthnicities,
    creatorEthnicities,
    countries,
    creatorCountries,
    cities,
    audienceCities,
    followersFrom,
    followersTo,
    realFollowersFrom,
    realFollowersTo,
    postPriceFrom,
    postPriceTo,
    creatorBirthdateFrom,
    creatorBirthdateTo,
    engagementRateFrom,
    engagementRateTo,
    viewsPerPostFrom,
    viewsPerPostTo,
    viewsMedianFrom,
    viewsMedianTo,
    storiesReachFrom,
    storiesReachTo,
    interestIds,
    audienceInterestPercentage,
    postsReachFrom,
    postsReachTo,
    reelsPlaysMedianFrom,
    reelsPlaysMedianTo,
    exclusions,
    creatorLanguages,
    creatorAnimals,
    creatorBodyTypes,
    creatorHairTypes,
    campaignsCompletionsCountFrom,
    campaignsCompletionsCountTo,
    tags,
    excludeBadges,
    strictlyByPrimaryCategory,
    includeAmazonStorefrontLink,
    withTiktokShop,
    favorite,
    excludeHiredMoreThanOnce,
    audienceCountryPercentage,
    audienceGenderPercentage,
    audienceAgeRangePercentage,
    onlyCreatorsWorkedWith,
    audienceCityPercentage,
    customLists,
    experienced,
    highAverageRating,
    reliable,
  } = data;
  const ageRangeIds = ageRanges ? ageRanges.map((item) => item.id) : undefined;
  const audienceGenderId = Object.prototype.hasOwnProperty.call(data, 'audienceGender')
    ? audienceGender
      ? audienceGender.id
      : null
    : undefined;
  const creatorGenderId = Object.prototype.hasOwnProperty.call(data, 'creatorGender')
    ? creatorGender
      ? creatorGender.id
      : null
    : undefined;
  const categoryIds = categories ? categories.map((item) => item.id) : undefined;
  const audienceEthnicityIds = audienceEthnicities
    ? audienceEthnicities.map((item) => item.id)
    : undefined;
  const creatorEthnicityIds = creatorEthnicities
    ? creatorEthnicities.map((item) => item.id)
    : undefined;
  const countryIds = countries ? countries.map((item) => item.id) : undefined;
  const creatorCountryIds = creatorCountries ? creatorCountries.map((item) => item.id) : undefined;
  const creatorLanguageIds = creatorLanguages ? creatorLanguages.map((item) => item.id) : undefined;
  const creatorAnimalIds = creatorAnimals ? creatorAnimals.map((item) => item.id) : undefined;
  const creatorBodyTypeIds = creatorBodyTypes ? creatorBodyTypes.map((item) => item.id) : undefined;
  const creatorHairTypeIds = creatorHairTypes ? creatorHairTypes.map((item) => item.id) : undefined;
  const cityIds = cities ? cities.map((item) => item.id) : undefined;
  const tagsIds = tags ? tags.map((item) => item.id) : undefined;
  const audienceCitiesIds = audienceCities ? audienceCities.map((item) => item.id) : undefined;
  const creatorBirthdateFromValue = creatorBirthdateFrom || undefined;
  const creatorBirthdateToValue = creatorBirthdateTo || undefined;
  const realFollowersFromValue = Object.prototype.hasOwnProperty.call(data, 'realFollowersFrom')
    ? realFollowersFrom
    : undefined;
  const realFollowersToValue = Object.prototype.hasOwnProperty.call(data, 'realFollowersTo')
    ? realFollowersTo
    : undefined;
  const engagementRateFromValue = Object.prototype.hasOwnProperty.call(data, 'engagementRateFrom')
    ? engagementRateFrom
    : undefined;
  const engagementRateToValue = Object.prototype.hasOwnProperty.call(data, 'engagementRateTo')
    ? engagementRateTo
    : undefined;
  const exclusionItems = exclusions.map((item) => {
    return { campaignId: item.campaign.id, advertiserProjectStages: item.advertiserProjectStages };
  });
  const customListIds = customLists ? customLists.map((item) => item.id) : undefined;

  const formattedData = {
    ageRangeIds,
    audienceGenderId,
    creatorGenderId,
    categoryIds,
    audienceEthnicityIds,
    creatorEthnicityIds,
    countryIds,
    creatorCountryIds,
    creatorLanguageIds,
    creatorAnimalIds,
    creatorBodyTypeIds,
    creatorHairTypeIds,
    cityIds,
    audienceCitiesIds,
    followersFrom,
    followersTo,
    postPriceFrom,
    postPriceTo,
    creatorBirthdateFrom: creatorBirthdateFromValue,
    creatorBirthdateTo: creatorBirthdateToValue,
    engagementRateFrom: engagementRateFromValue,
    engagementRateTo: engagementRateToValue,
    realFollowersFrom: realFollowersFromValue,
    realFollowersTo: realFollowersToValue,
    viewsPerPostFrom,
    viewsPerPostTo,
    viewsMedianFrom,
    viewsMedianTo,
    storiesReachFrom,
    storiesReachTo,
    interestIds,
    audienceInterestPercentage,
    postsReachFrom,
    postsReachTo,
    reelsPlaysMedianFrom,
    reelsPlaysMedianTo,
    exclusions: exclusionItems,
    campaignsCompletionsCountFrom,
    campaignsCompletionsCountTo,
    creatorTagIds: tagsIds,
    excludeBadges,
    strictlyByPrimaryCategory,
    includeAmazonStorefrontLink,
    withTiktokShop,
    favorite,
    excludeHiredMoreThanOnce,
    audienceCountryPercentage,
    audienceGenderPercentage,
    audienceAgeRangePercentage,
    onlyCreatorsWorkedWith,
    audienceCityPercentage,
    customListIds,
    experienced,
    highAverageRating,
    reliable,
  };

  return formattedData;
};

export const formatSearchQuery = (
  data: NonNullable<NonNullable<SearchParamsQuery$data>['campaign']>['searchQuery']
) => {
  if (!data) return {};

  const {
    creatorBirthdateFrom,
    creatorBirthdateTo,
    engagementRateFrom,
    engagementRateTo,
    realFollowersFrom,
    realFollowersTo,
  } = data;

  const creatorBirthdateFromValue = creatorBirthdateFrom
    ? Number(getAges(creatorBirthdateFrom)) - 1
    : null;
  const creatorBirthdateToValue = creatorBirthdateTo ? getAges(creatorBirthdateTo) : null;
  const engagementRateFromValue = engagementRateFrom ? engagementRateFrom * 100 : null;
  const engagementRateToValue = engagementRateTo ? engagementRateTo * 100 : null;
  const realFollowersFromValue = realFollowersFrom ? realFollowersFrom * 100 : null;
  const realFollowersToValue = realFollowersTo ? realFollowersTo * 100 : null;

  return {
    ...data,
    creatorBirthdateFrom: creatorBirthdateFromValue,
    creatorBirthdateTo: creatorBirthdateToValue,
    engagementRateFrom: engagementRateFromValue,
    engagementRateTo: engagementRateToValue,
    realFollowersFrom: realFollowersFromValue,
    realFollowersTo: realFollowersToValue,
  };
};

export const checkNotEmptyData = (
  searchQuery: NonNullable<NonNullable<SearchParamsQuery$data>['campaign']>['searchQuery'],
  isAdmin?: boolean
) => {
  if (!searchQuery) return false;

  const {
    ageRanges,
    audienceCities,
    cities,
    countries,
    creatorCountries,
    audienceGender,
    creatorGender,
    categories,
    audienceEthnicities,
    creatorEthnicities,
    creatorBirthdateFrom,
    creatorBirthdateTo,
    engagementRateFrom,
    engagementRateTo,
    followersFrom,
    followersTo,
    realFollowersFrom,
    realFollowersTo,
    viewsPerPostFrom,
    viewsPerPostTo,
    viewsMedianFrom,
    viewsMedianTo,
    storiesReachFrom,
    storiesReachTo,
    interestIds,
    audienceInterestPercentage,
    postsReachFrom,
    postsReachTo,
    reelsPlaysMedianFrom,
    reelsPlaysMedianTo,
    creatorLanguages,
    creatorAnimals,
    creatorBodyTypes,
    creatorHairTypes,
    campaignsCompletionsCountFrom,
    campaignsCompletionsCountTo,
    tags,
    excludeBadges,
    strictlyByPrimaryCategory,
    includeAmazonStorefrontLink,
    onlyCreatorsWorkedWith,
    favorite,
    withTiktokShop,
    experienced,
    highAverageRating,
    reliable,
  } = searchQuery;

  return !!(
    (excludeBadges && ageRanges?.length > 0) ||
    (strictlyByPrimaryCategory && isAdmin) ||
    (tags.length > 0 && isAdmin) ||
    (creatorBodyTypes.length > 0 && isAdmin) ||
    (creatorHairTypes.length > 0 && isAdmin) ||
    creatorLanguages.length > 0 ||
    creatorAnimals.length > 0 ||
    audienceCities?.length > 0 ||
    cities?.length > 0 ||
    countries?.length > 0 ||
    creatorCountries?.length > 0 ||
    audienceEthnicities.length > 0 ||
    creatorEthnicities.length > 0 ||
    audienceGender?.id ||
    creatorGender?.id ||
    categories?.length > 0 ||
    categories?.length > 0 ||
    creatorBirthdateFrom ||
    creatorBirthdateTo ||
    engagementRateFrom ||
    engagementRateTo ||
    followersFrom ||
    followersTo ||
    realFollowersFrom ||
    realFollowersTo ||
    viewsPerPostFrom ||
    viewsPerPostTo ||
    viewsMedianFrom ||
    viewsMedianTo ||
    storiesReachFrom ||
    storiesReachTo ||
    audienceInterestPercentage ||
    interestIds ||
    postsReachFrom ||
    postsReachTo ||
    reelsPlaysMedianFrom ||
    reelsPlaysMedianTo ||
    includeAmazonStorefrontLink ||
    withTiktokShop ||
    favorite ||
    onlyCreatorsWorkedWith ||
    experienced ||
    highAverageRating ||
    reliable ||
    (isAdmin && (campaignsCompletionsCountFrom || campaignsCompletionsCountTo))
  );
};

export const getAccentConfig = (
  campaign: NonNullable<NonNullable<SearchParamsQuery$data>['campaign']>
) => {
  const { searchQuery, private: isPrivate, postingType, platform } = campaign;
  if (!searchQuery) {
    return {
      location: false,
      gender: false,
      age: false,
      categories: false,
    };
  }

  if (
    isPrivate ||
    postingType !== ORGANIC_POSTING ||
    (platform !== 'INSTAGRAM' && platform !== 'TIKTOK')
  ) {
    return {
      location: false,
      gender: false,
      age: false,
      categories: false,
    };
  }

  const {
    audienceCities,
    cities,
    countries,
    creatorCountries,
    audienceGender,
    creatorGender,
    categories,
    ageRanges,
    audienceAgeRangePercentage,
    creatorBirthdateFrom,
    creatorBirthdateTo,
  } = searchQuery;
  return {
    location: !(
      cities.length > 0 ||
      countries.length > 0 ||
      audienceCities.length > 0 ||
      creatorCountries.length > 0
    ),
    gender: !(audienceGender?.id || creatorGender?.id),
    age: !(
      ageRanges?.length > 0 ||
      audienceAgeRangePercentage ||
      creatorBirthdateFrom ||
      creatorBirthdateTo
    ),
    categories: !(categories.length > 0),
  };
};

// types

type DropdownProps = React.ComponentProps<typeof DropDown>;
type SearchQuery = NonNullable<NonNullable<SearchParamsQuery$data['campaign']>['searchQuery']>;
type DropDownItem = NonNullable<DropdownProps['list']>[0] & {
  name: keyof SearchQuery;
  possibleValue: boolean;
};

export type { DropDownItem };
