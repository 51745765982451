import React, { useContext } from 'react';
import { graphql, useFragment } from 'react-relay';
import classnames from 'classnames';

import styles from './DiscoveryGridSearchResult.pcss';
import SearchResultContent from './DiscoveryGridSearchResultContent/DiscoveryGridSearchResultContent';

import { getCreatorData } from 'Util/creatorData';
import { formatValue } from 'Util/numberFormatter';
import { createCountriesList } from 'Util/dataFormatter';
import Text from 'Atoms/Text/Text';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import BlockedUsername from 'Molecules/BlockedUsername/BlockedUsername';
import CreatorBadge from 'Molecules/CreatorBadge/CreatorBadge';
import { CreatorType } from 'GraphTypes/SearchResultsContainerQuery.graphql';
import { CreatorsSearchContext } from 'AdvertiserPage/Mediaplan/SearchSection/CreatorsSearch.Context';
import { DiscoveryGridSearchResult_result$key } from 'GraphTypes/DiscoveryGridSearchResult_result.graphql';
import { DiscoveryCreatorsContext } from 'Page/advertiser/Discovery/DiscoveryCreatorsContext';
import ls from 'Util/localStorage';
import NewRegistrationMark from 'Molecules/NewRegistrationMark/NewRegistrationMark';
import Image from 'Components/ui/Image/Image';

interface Props {
  className?: string;
  isAdmin: boolean;
  isBlockedView?: boolean;
  creatorType?: CreatorType | null;
  campaignId: string;
  result: DiscoveryGridSearchResult_result$key;
  organizationId?: string;
  showUnlockCreatorBanner: () => void;
}

const DiscoveryGridSearchResult: React.FC<Props> = (props) => {
  const { className, campaignId, result, organizationId, isBlockedView, showUnlockCreatorBanner } =
    props;

  const { selectedCreators, selectAllMode } = useContext(DiscoveryCreatorsContext);

  const isAdmin = ls.get('admin');

  const data = useFragment(
    graphql`
      fragment DiscoveryGridSearchResult_result on CreatorSearchResult {
        creator {
          id
          type
          ownership {
            owner {
              id
            }
          }
          username
          ... on InstagramCreator {
            user {
              estimatedLikes
              followedByCount
              engagementRate
            }
          }
          ... on YoutubeCreator {
            channel {
              id
              name
              subscribers
              thumbnailUrl
            }
          }
          ... on TiktokCreator {
            user {
              name
              followedByCount
              engagementRate
              averageLikes
            }
          }
          type
          profile {
            badges {
              id
              name
            }
            profilePictureFile {
              url
              ... on Image {
                transformations {
                  collageThumbnailUrl
                }
              }
            }
            searchable
            countries {
              name
            }
          }
        }
        ...DiscoveryGridSearchResultContent_result
      }
    `,
    result.__fragments.DiscoveryGridSearchResult_result ? result : null
  );

  const creator = data?.creator;
  const searchable = data?.creator?.profile?.searchable;
  const countries = data?.creator?.profile?.countries;
  const creatorId = creator?.id;
  const badges = creator?.profile?.badges;
  const followers = creator?.user?.followedByCount || 0;

  const { state: creatorsSearchState } = useContext(CreatorsSearchContext);

  const creatorIdsForExclusion = creatorsSearchState.creatorIdsForExclusion;

  const creatorIdsForExclusionList = creatorIdsForExclusion.map((item) => item.creatorId);

  const checked = selectedCreators.some((creator) => creator.id === creatorId) || selectAllMode;

  if (!creator || (creatorId && creatorIdsForExclusionList.includes(creatorId))) return null;

  const creatorData = getCreatorData(creator);
  const { username, avatarUrl, link, type } = creatorData;

  const countriesList = createCountriesList(countries);

  const isHidden = !searchable && isAdmin;
  const isRegistered = !!creator.ownership?.owner.id;

  const createfollowersCount = () => {
    const followersCount = formatValue({ value: followers, format: 'number' });
    if (followers === 0) return null;

    return (
      <div className={styles.price}>
        <Text
          weight="500"
          text={followersCount}
          data-test="discoveryGridSearchResult:text:unknown"
        />
        <Text
          size="sm"
          color="grayDog"
          msg="creator.followers"
          data-test="discoveryGridSearchResult:text:followers"
        />
      </div>
    );
  };

  return (
    <div
      className={classnames(className, styles.root, {
        [styles.checked]: checked,
      })}
    >
      <div className={styles.content}>
        <div className={styles.container}>
          <SearchResultContent
            result={data}
            checked={checked}
            isAdmin={isAdmin}
            campaignId={campaignId}
            isHidden={isHidden}
            organizationId={organizationId}
          />
        </div>
        {isHidden && (
          <Text
            msg="general.hidden"
            className={styles.hiddenState}
            data-test="discoveryGridSearchResult:text:hidden"
          />
        )}
        <div className={styles.details}>
          {
            <div className={styles.info}>
              <div className={styles.user}>
                <div className={styles.avatarWrap}>
                  <Image size={40} src={avatarUrl} type="round" platform={type.toUpperCase()} />
                </div>
                <div className={styles.names}>
                  <div className={styles.userName}>
                    <div className={styles.userLinkWrap}>
                      {isBlockedView ? (
                        <BlockedUsername
                          creatorId={creatorId}
                          onLockClick={showUnlockCreatorBanner}
                        />
                      ) : (
                        <SmartLink path={link} className={styles.userLink}>
                          <Text
                            color="purple"
                            className={`${styles.name} ${styles.link}`}
                            text={username}
                            data-test="discoveryGridSearchResult:text:unknown"
                          />
                          {isRegistered && isAdmin && (
                            <NewRegistrationMark tooltipKey={creatorId} size={16} />
                          )}
                        </SmartLink>
                      )}
                    </div>
                    <div className={styles.badges}>
                      {badges?.map((item) => {
                        if (!item || !item.name) return null;

                        return (
                          <Tooltip
                            id={`badge_${item.name}_${creatorId}`}
                            tooltipMsg={`creator.badge.${item.name.toLowerCase()}.tooltip`}
                            place="top"
                            type="light"
                            tooltipClassName={styles.tooltip}
                          >
                            <CreatorBadge
                              key={item.name}
                              badge={item.name}
                              withText={false}
                              className={styles.badge}
                              iconClassName={styles.badgeIcon}
                            />
                          </Tooltip>
                        );
                      })}
                    </div>
                  </div>
                  {countriesList && (
                    <Text
                      size="sm"
                      color="grayDog"
                      text={countriesList}
                      overflow
                      data-test="discoveryGridSearchResult:text:unknown"
                    />
                  )}
                </div>
              </div>
              {createfollowersCount()}
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default DiscoveryGridSearchResult;
