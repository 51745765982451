import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { Link } from 'react-router-dom';

import styles from './CampaignInfo.pcss';
import CampaignActions from './CampaignActions/CampaignActions';
import CampaignCover from './CampaignCover/CampaignCover';

import { getPlatformIcon } from 'Util/icons';
import { amplitude } from 'Helpers/amplitude';
import { getOrganizationLink } from 'Util/links';
import NewText from 'Components/ui/Text/Text';
import TextButton from 'Atoms/TextButton/TextButton';
import SimpleLink from 'Components/SimpleLink/SimpleLink';
import { CampaignInfo_campaign$key } from 'GraphTypes/CampaignInfo_campaign.graphql';
import { CampaignQuery$data } from 'GraphTypes/CampaignQuery.graphql';
import OldIcon from 'Atoms/Icon/Icon';
import Text from 'Components/ui/Text/Text';
import { BILLING_ROUTE } from 'Constants/general';
import Tag from 'Components/ui/Tag/Tag';
import Image from 'Components/ui/Image/Image';

interface Props {
  className?: string;
  isAdmin: boolean;
  isAdminView: boolean;
  adminView: boolean;
  hasAcceptance: boolean;
  currentUser: CampaignQuery$data['currentUser'];
  campaign: CampaignInfo_campaign$key;
  onClientViewChange?: (value: boolean) => void;
}

const CampaignInfo: React.FC<Props> = (props) => {
  const {
    className,
    isAdminView,
    isAdmin,
    adminView,
    hasAcceptance,
    currentUser,
    campaign,
    onClientViewChange,
  } = props;

  const data = useFragment(
    graphql`
      fragment CampaignInfo_campaign on Campaign {
        id
        name
        type
        listingId
        platform
        postingType
        cover {
          width
          height
          ... on Image {
            transformations {
              collageThumbnailUrl
            }
          }
        }
        brand {
          name
          logo {
            ... on Image {
              transformations {
                brandLogoUrl
              }
            }
          }
        }
        activation {
          paused
        }
        organization {
          id
          name
          subscription {
            paused
          }
        }
        ...CampaignActions_campaign
        ...CampaignCover_campaign
      }
    `,
    campaign
  );

  const avaSrc = data.cover?.transformations?.collageThumbnailUrl;
  const name = data.name;
  const campaignPlatform = data.platform;
  const brandName = data.brand?.name;
  const organizationId = data.organization?.id || '';
  const organizationName = data.organization?.name;
  const isPaused = !!data.activation?.paused;
  const isPlanPaused = Boolean(data?.organization?.subscription?.paused);

  const organizationLink = getOrganizationLink({ organizationId });
  const brandLogoUrl = data.brand?.logo?.transformations?.brandLogoUrl;

  const avaData = avaSrc ? { src: avaSrc } : { letters: name };

  const handleOrganisationClick = () => {
    amplitude.sendEvent({ id: 251, category: 'pageview', name: 'campaign_open_organization_page' });
  };

  const platformIcon = getPlatformIcon(campaignPlatform?.toLowerCase());
  const platformName = `general.${campaignPlatform?.toLowerCase()}`;

  return (
    <div className={`${styles.root} ${className}`}>
      <div className={styles.details}>
        <div className={styles.info}>
          {campaign && <CampaignCover campaign={data} />}
          <div className={styles.names}>
            <div className={styles.campaignNameWrap}>
              <div className={styles.campaignName}>
                {name && <NewText size={18} type="h2" text={name} />}
                {isPlanPaused && (
                  <Link to={BILLING_ROUTE} target="_blank">
                    <Tag
                      msg="campaign.tag.pause_plan"
                      color="sun"
                      icon="Lock"
                      data-test="campaignInfo:tag:pausePlan"
                    />
                  </Link>
                )}
              </div>
              {adminView && isPaused && (
                <Tag needMargin={false} msg="campaign.paused" color="newGrey" icon="Pause" />
              )}
            </div>
            <div className={styles.line} />
            <div className={styles.campaignInfo}>
              {campaignPlatform && (
                <div className={styles.detail}>
                  {platformIcon && <OldIcon name={platformIcon} className={styles.platform} />}
                  <Text color="grey" msg={platformName} data-test="campaignInfo:text:unknown" />
                </div>
              )}
              {brandName && (
                <div className={styles.detail}>
                  <Image size={28} src={brandLogoUrl} className={styles.brandLogo} />
                  <NewText color="grey" text={brandName} />
                </div>
              )}
              {adminView && (
                <SimpleLink
                  href={organizationLink}
                  target="_blank"
                  className={styles.detail}
                  onClick={handleOrganisationClick}
                >
                  <TextButton text={organizationName} data-test="campaignInfo:textButton:unknown" />
                </SimpleLink>
              )}
            </div>
          </div>
        </div>
        {hasAcceptance && data && (
          <CampaignActions
            campaign={data}
            currentUser={currentUser}
            isAdmin={isAdmin}
            isAdminView={isAdminView}
            onClientViewChange={onClientViewChange}
          />
        )}
      </div>
    </div>
  );
};

export default CampaignInfo;
