import React, { FC, memo, useMemo } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { Link } from 'react-router-dom';

import styles from './OutreachHeader.pcss';

import Text from 'Components/ui/Text/Text';
import { OutreachHeaderQuery as QueryType } from 'GraphTypes/OutreachHeaderQuery.graphql';
import { createNumber } from 'Util/numberFormatter';

interface Props {
  campaignId: string;
}

const emptyCounters = {
  reachedCount: 0,
  openedCount: 0,
  clickedCount: 0,
  repliedCount: 0,
  appliedCount: 0,
  hiredCount: 0,
  totalCreators: 0,
};

const OutreachHeader: FC<Props> = ({ campaignId }) => {
  const data = useLazyLoadQuery<QueryType>(
    graphql`
      query OutreachHeaderQuery($campaignId: ID!) {
        campaign(id: $campaignId) {
          outreachBatches {
            edges {
              node {
                totalCreators: creatorCount
                reachedCount: sequences(outreached: true) {
                  totalCount
                }
                clickedCount: sequences(clicked: true) {
                  totalCount
                }
                repliedCount: sequences(replied: true) {
                  totalCount
                }
                appliedCount: sequences(applied: true) {
                  totalCount
                }
                hiredCount: sequences(hired: true) {
                  totalCount
                }
              }
            }
          }
        }
      }
    `,
    { campaignId }
  );

  if (!data.campaign) return null;

  const countersData = useMemo(() => {
    if (!data.campaign?.outreachBatches.edges) {
      return emptyCounters;
    }
    return data.campaign?.outreachBatches.edges.reduce((counters, data) => {
      const counterList = { ...counters };
      return {
        reachedCount: (counterList.reachedCount += data?.node?.reachedCount.totalCount || 0),
        clickedCount: (counterList.clickedCount += data?.node?.clickedCount.totalCount || 0),
        repliedCount: (counterList.repliedCount += data?.node?.repliedCount.totalCount || 0),
        appliedCount: (counterList.appliedCount += data?.node?.appliedCount.totalCount || 0),
        hiredCount: (counterList.hiredCount += data?.node?.hiredCount.totalCount || 0),
        totalCreators: (counterList.totalCreators += data?.node?.totalCreators || 0),
      };
    }, emptyCounters);
  }, []);

  const { hiredCount, appliedCount, reachedCount, clickedCount, repliedCount } = countersData;

  const calcMetricValue = (value: number | undefined) => {
    if (value === undefined || reachedCount === 0) {
      return ' - %';
    }
    return ((value / reachedCount) * 100).toFixed(2) + '%';
  };

  return (
    <div className={styles.root}>
      <div className={styles.titleContainer}>
        <Text msg="campaign.outreach.column_title.reached" type="md" className={styles.counter} />
        <Text type="md" text={createNumber(reachedCount)} />
      </div>
      <div className={styles.titleContainer}>
        <Text msg="campaign.outreach.column_title.clicked" type="md" className={styles.counter} />
        <Text type="md" text={createNumber(clickedCount)} />
        <Text type="label" color="grey" text={`(${calcMetricValue(clickedCount)})`} />
      </div>
      <div className={styles.titleContainer}>
        <Text msg="campaign.outreach.column_title.replied" type="md" className={styles.counter} />
        <Text type="md" text={createNumber(repliedCount)} />
        <Text type="label" color="grey" text={`(${calcMetricValue(repliedCount)})`} />
      </div>
      {appliedCount > 0 ? (
        <Link to={`/campaigns/${campaignId}/applicants`}>
          <div className={styles.titleContainer}>
            <Text
              msg="campaign.outreach.column_title.applied"
              type="md"
              className={styles.counter}
            />
            <Text type="md" text={createNumber(appliedCount)} />
            <Text type="label" color="grey" text={`(${calcMetricValue(appliedCount)})`} />
          </div>
        </Link>
      ) : (
        <div className={styles.titleContainer}>
          <Text msg="campaign.outreach.column_title.applied" type="md" className={styles.counter} />
          <Text type="md" text="0" />
          <Text type="label" color="grey" text={`(${calcMetricValue(appliedCount)})`} />
        </div>
      )}

      {hiredCount > 0 ? (
        <Link to={`/campaigns/${campaignId}/creators`}>
          <div className={styles.titleContainer}>
            <Text msg="campaign.outreach.column_title.hired" type="md" className={styles.counter} />
            <Text type="md" text={createNumber(hiredCount)} />
            <Text type="label" color="grey" text={`(${calcMetricValue(hiredCount)})`} />
          </div>
        </Link>
      ) : (
        <div className={styles.titleContainer}>
          <Text msg="campaign.outreach.column_title.hired" type="md" className={styles.counter} />
          <Text type="md" text="0" />
          <Text type="label" color="grey" text={`(${calcMetricValue(hiredCount)})`} />
        </div>
      )}
    </div>
  );
};

export default memo(OutreachHeader);
