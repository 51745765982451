import React, { useMemo } from 'react';
import { Link } from 'react-scroll';
import classnames from 'classnames';

import styles from './SideBar.pcss';

import Text from 'Atoms/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { TextType } from 'Types/common';

type SideBarItem = {
  id: string;
  titleData: TextType;
  scrollContainerName?: string;
  status?: 'filled' | 'active' | 'block';
  hidden: boolean;
};

interface Props {
  items: SideBarItem[];
}

const SideBar: React.FC<Props> = (props) => {
  const items = useMemo<SideBarItem[]>(() => {
    return props.items.filter((item) => !item.hidden);
  }, [props.items]);

  return (
    <ul className={styles.root}>
      {items.map((item) => {
        const { id, titleData, status, scrollContainerName } = item;

        const isBlocked = status === 'block';
        const isActive = status === 'active';

        const container = scrollContainerName ? (
          <Link
            to={scrollContainerName}
            smooth
            duration={100}
            offset={-96}
            className={styles.link}
          />
        ) : (
          <div />
        );

        return (
          <li
            key={id}
            className={classnames(styles.item, {
              [styles.blocked]: isBlocked,
              [styles.active]: isActive,
            })}
          >
            {React.cloneElement(container, {
              children: (
                <div className={styles.itemContent}>
                  <Text
                    weight="500"
                    size="sm"
                    color={isBlocked ? 'grayDog' : isActive ? 'base' : undefined}
                    {...titleData}
                    data-test="sideBar:text:unknown"
                  />
                  {(isBlocked || !isActive) && (
                    <Tooltip
                      id={id}
                      tooltipMsg={isBlocked ? 'brief_template.sidebar.blocked' : undefined}
                      place="right"
                    >
                      <Icon size={16} name={isBlocked ? 'Lock' : 'Check'} />
                    </Tooltip>
                  )}
                </div>
              ),
            })}
          </li>
        );
      })}
    </ul>
  );
};

export default SideBar;
