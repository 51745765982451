/**
 * @generated SignedSource<<e977817b0417db01c08bb52b7881be92>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AdvertiserProjectStage = "ACCEPTED" | "ACCEPTED_AND_NOT_LAUNCHED" | "ACCEPTED_BY_UNREVIEWED_CREATOR" | "COMPLETED" | "CONTENT_SUBMITTED" | "LAUNCHED" | "PRODUCT_SHIPMENT_COMPLETED" | "PRODUCT_SHIPMENT_STARTED" | "PUBLISHING_STARTED" | "READ" | "REJECTED" | "UNREAD" | "UNSENT";
export type ProjectArchivationSide = "ADVERTISER" | "CONTRACTOR";
export type UnarchiveProjectInput = {
  clientMutationId?: string | null | undefined;
  id?: string | null | undefined;
  projectId: string;
  side?: ProjectArchivationSide | null | undefined;
};
export type UnarchiveProjectMutation$variables = {
  archivedStages?: ReadonlyArray<AdvertiserProjectStage> | null | undefined;
  createdByAdmin?: boolean | null | undefined;
  input: UnarchiveProjectInput;
};
export type UnarchiveProjectMutation$data = {
  readonly unarchiveProject: {
    readonly project: {
      readonly archivation: {
        readonly id: string;
      } | null | undefined;
      readonly archivationRequest: {
        readonly acceptance: {
          readonly id: string;
        } | null | undefined;
        readonly id: string;
        readonly rejection: {
          readonly id: string;
        } | null | undefined;
      } | null | undefined;
      readonly campaign: {
        readonly acceptedCounter: {
          readonly totalCount: number;
        };
        readonly applicantsWithUnreadMesseges: {
          readonly hasItems: boolean;
        };
        readonly archiveEvents: {
          readonly unreadCount: number;
        };
        readonly archived: {
          readonly totalCount: number;
        };
        readonly completedCounter: {
          readonly totalCount: number;
        };
        readonly contentCreationUnreadMesseges: {
          readonly hasItems: boolean;
        };
        readonly inProgressCounter: {
          readonly totalCount: number;
        };
        readonly messagedCounter: {
          readonly totalCount: number;
        };
        readonly unsentProjects: {
          readonly summary: {
            readonly totalCount: number | null | undefined;
          } | null | undefined;
        };
      };
    };
  } | null | undefined;
};
export type UnarchiveProjectMutation = {
  response: UnarchiveProjectMutation$data;
  variables: UnarchiveProjectMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "archivedStages"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "createdByAdmin"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v3 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "side",
      "value": "ADVERTISER"
    }
  ],
  "concreteType": "ProjectArchivation",
  "kind": "LinkedField",
  "name": "archivation",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": "archivation(side:\"ADVERTISER\")"
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectArchivationRequest",
  "kind": "LinkedField",
  "name": "archivationRequest",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectArchivationRequestAcceptance",
      "kind": "LinkedField",
      "name": "acceptance",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectArchivationRequestRejection",
      "kind": "LinkedField",
      "name": "rejection",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "kind": "Literal",
  "name": "archived",
  "value": false
},
v9 = {
  "kind": "Literal",
  "name": "stages",
  "value": [
    "ACCEPTED"
  ]
},
v10 = {
  "kind": "Literal",
  "name": "withUnreadEvents",
  "value": true
},
v11 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v12 = {
  "alias": "acceptedCounter",
  "args": [
    (v8/*: any*/),
    {
      "kind": "Literal",
      "name": "hasBrandMessageBeforeLaunch",
      "value": false
    },
    (v9/*: any*/),
    (v10/*: any*/)
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v11/*: any*/),
  "storageKey": "projects(archived:false,hasBrandMessageBeforeLaunch:false,stages:[\"ACCEPTED\"],withUnreadEvents:true)"
},
v13 = {
  "alias": "messagedCounter",
  "args": [
    (v8/*: any*/),
    {
      "kind": "Literal",
      "name": "hasBrandMessageBeforeLaunch",
      "value": true
    },
    (v9/*: any*/),
    (v10/*: any*/)
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v11/*: any*/),
  "storageKey": "projects(archived:false,hasBrandMessageBeforeLaunch:true,stages:[\"ACCEPTED\"],withUnreadEvents:true)"
},
v14 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "hasItems",
    "storageKey": null
  }
],
v15 = {
  "alias": "applicantsWithUnreadMesseges",
  "args": [
    (v8/*: any*/),
    (v9/*: any*/),
    (v10/*: any*/)
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v14/*: any*/),
  "storageKey": "projects(archived:false,stages:[\"ACCEPTED\"],withUnreadEvents:true)"
},
v16 = {
  "alias": "contentCreationUnreadMesseges",
  "args": [
    (v8/*: any*/),
    {
      "kind": "Literal",
      "name": "stages",
      "value": [
        "LAUNCHED",
        "CONTENT_SUBMITTED",
        "PUBLISHING_STARTED",
        "COMPLETED"
      ]
    },
    (v10/*: any*/)
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v14/*: any*/),
  "storageKey": "projects(archived:false,stages:[\"LAUNCHED\",\"CONTENT_SUBMITTED\",\"PUBLISHING_STARTED\",\"COMPLETED\"],withUnreadEvents:true)"
},
v17 = {
  "alias": "inProgressCounter",
  "args": [
    (v8/*: any*/),
    {
      "kind": "Literal",
      "name": "stages",
      "value": [
        "LAUNCHED",
        "CONTENT_SUBMITTED",
        "PUBLISHING_STARTED"
      ]
    },
    (v10/*: any*/)
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v11/*: any*/),
  "storageKey": "projects(archived:false,stages:[\"LAUNCHED\",\"CONTENT_SUBMITTED\",\"PUBLISHING_STARTED\"],withUnreadEvents:true)"
},
v18 = {
  "alias": "completedCounter",
  "args": [
    (v8/*: any*/),
    {
      "kind": "Literal",
      "name": "stages",
      "value": [
        "COMPLETED"
      ]
    },
    (v10/*: any*/)
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v11/*: any*/),
  "storageKey": "projects(archived:false,stages:[\"COMPLETED\"],withUnreadEvents:true)"
},
v19 = {
  "kind": "Literal",
  "name": "archived",
  "value": true
},
v20 = {
  "alias": "archiveEvents",
  "args": [
    (v19/*: any*/)
  ],
  "concreteType": "ProjectEventConnection",
  "kind": "LinkedField",
  "name": "projectEvents",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unreadCount",
      "storageKey": null
    }
  ],
  "storageKey": "projectEvents(archived:true)"
},
v21 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": "LAST_ADDED"
},
v22 = {
  "alias": "archived",
  "args": [
    (v19/*: any*/),
    (v21/*: any*/),
    {
      "kind": "Variable",
      "name": "stages",
      "variableName": "archivedStages"
    }
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v11/*: any*/),
  "storageKey": null
},
v23 = {
  "alias": "unsentProjects",
  "args": [
    (v8/*: any*/),
    {
      "kind": "Variable",
      "name": "createdByAdmin",
      "variableName": "createdByAdmin"
    },
    (v21/*: any*/),
    {
      "kind": "Literal",
      "name": "stages",
      "value": [
        "UNSENT",
        "UNREAD",
        "READ"
      ]
    }
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectConnectionSummary",
      "kind": "LinkedField",
      "name": "summary",
      "plural": false,
      "selections": (v11/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UnarchiveProjectMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "UnarchiveProjectPayload",
        "kind": "LinkedField",
        "name": "unarchiveProject",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Campaign",
                "kind": "LinkedField",
                "name": "campaign",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v20/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "UnarchiveProjectMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "UnarchiveProjectPayload",
        "kind": "LinkedField",
        "name": "unarchiveProject",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Campaign",
                "kind": "LinkedField",
                "name": "campaign",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v20/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8fec68cf224e4caf8a59928b49161d23",
    "id": null,
    "metadata": {},
    "name": "UnarchiveProjectMutation",
    "operationKind": "mutation",
    "text": "mutation UnarchiveProjectMutation(\n  $input: UnarchiveProjectInput!\n  $archivedStages: [AdvertiserProjectStage!]\n  $createdByAdmin: Boolean\n) {\n  unarchiveProject(input: $input) {\n    project {\n      archivation(side: ADVERTISER) {\n        id\n      }\n      archivationRequest {\n        id\n        acceptance {\n          id\n        }\n        rejection {\n          id\n        }\n      }\n      campaign {\n        acceptedCounter: projects(stages: [ACCEPTED], hasBrandMessageBeforeLaunch: false, archived: false, withUnreadEvents: true) {\n          totalCount\n        }\n        messagedCounter: projects(stages: [ACCEPTED], hasBrandMessageBeforeLaunch: true, archived: false, withUnreadEvents: true) {\n          totalCount\n        }\n        applicantsWithUnreadMesseges: projects(stages: [ACCEPTED], archived: false, withUnreadEvents: true) {\n          hasItems\n        }\n        contentCreationUnreadMesseges: projects(stages: [LAUNCHED, CONTENT_SUBMITTED, PUBLISHING_STARTED, COMPLETED], archived: false, withUnreadEvents: true) {\n          hasItems\n        }\n        inProgressCounter: projects(stages: [LAUNCHED, CONTENT_SUBMITTED, PUBLISHING_STARTED], archived: false, withUnreadEvents: true) {\n          totalCount\n        }\n        completedCounter: projects(stages: [COMPLETED], archived: false, withUnreadEvents: true) {\n          totalCount\n        }\n        archiveEvents: projectEvents(archived: true) {\n          unreadCount\n        }\n        archived: projects(stages: $archivedStages, archived: true, orderBy: LAST_ADDED) {\n          totalCount\n        }\n        unsentProjects: projects(stages: [UNSENT, UNREAD, READ], archived: false, orderBy: LAST_ADDED, createdByAdmin: $createdByAdmin) {\n          summary {\n            totalCount\n          }\n        }\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9f1ba2ce481b5d29c96e0a3dd03f3795";

export default node;
