/**
 * @generated SignedSource<<6de6aae78f0fc6553348a0dc8560adb1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Currency = "CAD" | "RUB" | "USD";
export type PlanId = "ADVANCED" | "ADVANCED_2021_10" | "ADVANCED_2022_11" | "AGENCY_2024_03" | "BARTER" | "BASIC_OLD_PS" | "BRAND_2024_03" | "CONTENT_CREATION" | "ENTERPRISE" | "FREE" | "FREE_OLD_PS" | "FREE_TRIAL" | "FULLY_MANAGED" | "FULLY_MANAGED_1" | "FULLY_MANAGED_2" | "FULLY_MANAGED_TTCX_MMA" | "GROW" | "PAYOUT" | "PREMIUM" | "PREMIUM_PLUS" | "PUBLICIS" | "SCALE" | "SCALE_2021_06" | "SCALE_2021_10" | "SMARTLY_ENTERPRISE_2023_06" | "SMARTLY_ENTERPRISE_TRIAL_2023_06" | "TRIAL_2022_08" | "TRIAL_2024_03" | "TRIAL_MONTH" | "UGC_2022_08" | "UGC_ADS_2022_08" | "WILDJAM";
export type AdvertisersCountQuery$variables = {
  currencies?: ReadonlyArray<Currency> | null | undefined;
  paying?: boolean | null | undefined;
  planIds?: ReadonlyArray<PlanId> | null | undefined;
  textQuery?: string | null | undefined;
};
export type AdvertisersCountQuery$data = {
  readonly organizations: {
    readonly totalCount: number;
  } | null | undefined;
};
export type AdvertisersCountQuery = {
  response: AdvertisersCountQuery$data;
  variables: AdvertisersCountQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "currencies"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "paying"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "planIds"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "textQuery"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "currencies",
        "variableName": "currencies"
      },
      {
        "kind": "Variable",
        "name": "paying",
        "variableName": "paying"
      },
      {
        "kind": "Variable",
        "name": "planIds",
        "variableName": "planIds"
      },
      {
        "kind": "Variable",
        "name": "textQuery",
        "variableName": "textQuery"
      }
    ],
    "concreteType": "OrganizationConnection",
    "kind": "LinkedField",
    "name": "organizations",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdvertisersCountQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AdvertisersCountQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "4efeda060a8452431ea40a5eb91d4ecf",
    "id": null,
    "metadata": {},
    "name": "AdvertisersCountQuery",
    "operationKind": "query",
    "text": "query AdvertisersCountQuery(\n  $textQuery: String\n  $paying: Boolean\n  $planIds: [PlanId!]\n  $currencies: [Currency!]\n) {\n  organizations(textQuery: $textQuery, paying: $paying, planIds: $planIds, currencies: $currencies) {\n    totalCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "f2d3fa88ba54b3f58a5beb8e8268f648";

export default node;
