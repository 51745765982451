import React, { useCallback } from 'react';
import track from 'react-tracking';
import { useLazyLoadQuery, graphql } from 'react-relay';
import classnames from 'classnames';

import { CONTENT_CREATION, DECLINED, HIRED } from '../../NewCampaignTabs/util';

import styles from './ProjectsFilters.pcss';
import StagesFilter from './StagesFilter/StagesFilter';

import { amplitude } from 'Helpers/amplitude';
import Image from 'Components/ui/Image/Image';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { ProjectsFiltersQuery as QueryType } from 'GraphTypes/ProjectsFiltersQuery.graphql';
import thumbsUpImg from 'Images/icons/thumbs-up-accept.svg';
import DueDateFilter from 'AdvertiserPage/Campaign/Creators/ProjectsFilters/DueDateFilter/DueDateFilter';
import { CREATORS } from 'Constants/general';

const ProjectsFiltersQuery = graphql`
  query ProjectsFiltersQuery($campaignId: ID!) {
    campaign(id: $campaignId) {
      ...ScreeningQuestionsFilter_campaign
    }
  }
`;

interface Props {
  publishingRequired: boolean;
  stages: string[];
  defaultStages: string[];
  badges: string[];
  projectStage?: string;
  currency?: string;
  campaignId: string;
  archived: boolean;
  isAdminView: boolean;
  isLongTermCampaign: boolean;
  shortlisted: boolean | null;
  outreach?: boolean | null;
  onFilterChange: (data: object) => void;
  section: string;
}

const ProjectsFilters: React.FC<Props> = (props) => {
  const {
    shortlisted,
    defaultStages,
    projectStage,
    campaignId,
    onFilterChange,
    isLongTermCampaign,
    section,
    stages,
    publishingRequired,
  } = props;

  const data = useLazyLoadQuery<QueryType>(ProjectsFiltersQuery, {
    campaignId,
  });

  const handleProjectStageChange = (value?: string) => {
    const newStages = value ? value : defaultStages;
    onFilterChange({ stages: newStages });
  };

  const handleShortlistedToggle = useCallback(() => {
    const newShortlisted = shortlisted ? null : true;

    amplitude.sendEvent<190>({
      id: '190',
      category: 'campaign',
      name: 'campaign_shortlist_filter',
      param: undefined,
    });

    onFilterChange({ shortlisted: newShortlisted });
  }, [shortlisted]);

  const isHired = section === CREATORS || section === CONTENT_CREATION || section === HIRED;

  if (!data) return null;

  return (
    <div className={styles.root}>
      {defaultStages.length > 1 && !isLongTermCampaign && section !== DECLINED && (
        <StagesFilter
          stages={defaultStages}
          projectStages={stages}
          projectStage={projectStage}
          onProjectStageChange={handleProjectStageChange}
        />
      )}
      <Tooltip place="top" id="shortlisted" tooltipMsg="creators.filter.shortlisted">
        <AlterButton
          rightElement={<Image src={thumbsUpImg} size={24} />}
          hover={false}
          className={classnames({
            [styles.active]: shortlisted,
          })}
          onClick={handleShortlistedToggle}
          data-test="projectsFilters:alterButton:unknown"
        />
      </Tooltip>
      {isHired && (
        <DueDateFilter
          place="campaign"
          onChange={onFilterChange}
          publishingRequired={publishingRequired}
        />
      )}
    </div>
  );
};

export default track()(ProjectsFilters);
