import { createShortNumber } from 'Util/numberFormatter';
import { Creative_creative$data } from 'GraphTypes/Creative_creative.graphql';
import { IconName } from 'Components/ui/types';

type CreativeDataType = {
  creative: Creative_creative$data;
};

type StatData = {
  icon: IconName;
  value: string;
  tooltipMsg?: string;
}[];

type CreateStatsData = (data: CreativeDataType) => StatData;
export const createStatsData: CreateStatsData = ({ creative }) => {
  const { postPublication } = creative;
  if (!postPublication) return [];

  const { instagramInsightsMedia, tiktokInsightsMedia } = postPublication;
  if (!instagramInsightsMedia && !tiktokInsightsMedia) return [];

  const { likes, comments, views, shares, saves, reach } = postPublication;

  const { placement, finalStoryInsightsCollected } = instagramInsightsMedia || {};

  const stubValue = '—';

  const playCount = views ? createShortNumber(views) : stubValue;

  if (instagramInsightsMedia) {
    if (placement === 'FEED') {
      return [
        {
          icon: 'Heart',
          value: likes ? createShortNumber(likes) : stubValue,
          tooltipMsg: 'creative.likes',
        },
        {
          icon: 'Chat',
          value: comments ? createShortNumber(comments) : stubValue,
          tooltipMsg: 'creative.comments',
        },
        {
          icon: 'Save-list',
          value: saves ? createShortNumber(saves) : stubValue,
          tooltipMsg: 'creative.saved',
        },
      ];
    } else if (placement === 'STORY') {
      if (!finalStoryInsightsCollected) return [];
      return [
        {
          icon: 'Eye',
          value: reach ? createShortNumber(reach) : stubValue,
          tooltipMsg: 'creative.reach',
        },
      ];
    } else if (placement === 'REEL') {
      return [
        {
          icon: 'Eye',
          value: playCount ? createShortNumber(playCount) : stubValue,
          tooltipMsg: 'creative.plays',
        },
        {
          icon: 'Heart',
          value: likes ? createShortNumber(likes) : stubValue,
          tooltipMsg: 'creative.likes',
        },
        {
          icon: 'Chat',
          value: comments ? createShortNumber(comments) : stubValue,
          tooltipMsg: 'creative.comments',
        },
        {
          icon: 'Send',
          value: shares ? createShortNumber(shares) : stubValue,
          tooltipMsg: 'creative.shares',
        },
      ];
    }
  } else if (tiktokInsightsMedia) {
    return [
      {
        icon: 'Eye',
        value: views ? createShortNumber(views) : stubValue,
        tooltipMsg: 'creative.views',
      },
      {
        icon: 'Heart',
        value: likes ? createShortNumber(likes) : stubValue,
        tooltipMsg: 'creative.likes',
      },
      {
        icon: 'Chat',
        value: comments ? createShortNumber(comments) : stubValue,
        tooltipMsg: 'creative.comments',
      },
      {
        icon: 'Send',
        value: shares ? createShortNumber(shares) : stubValue,
        tooltipMsg: 'creative.shares',
      },
    ];
  }

  return [];
};
