import React from 'react';

import styles from './Search.pcss';

import Input from 'Components/ui/Input/Input';

interface Props {
  value?: string | null;
  onChange: (value: string) => void;
}

const Search: React.FC<Props> = (props) => {
  const { value, onChange } = props;

  return (
    <Input
      active={!!value}
      placeholderMsg="campaign.creators_search"
      className={styles.root}
      rightIcon="Search-loop"
      handleChange={onChange}
      data-test="search:input:root"
    />
  );
};

export default Search;
