/**
 * @generated SignedSource<<e1916d9f212996d6f51730e91231f72b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type InstagramInsightsMediaPlacement = "AD" | "FEED" | "REEL" | "STORY";
export type InstagramInsightsMediaType = "CAROUSEL_ALBUM" | "IMAGE" | "VIDEO";
import { FragmentRefs } from "relay-runtime";
export type InsightsStoriesMedias_creator$data = {
  readonly stories?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly caption: string | null | undefined;
        readonly engagement: number | null | undefined;
        readonly file: {
          readonly secureUrl: string;
          readonly thumbnailUrl: string;
        } | null | undefined;
        readonly finalStoryInsightsCollected: boolean | null | undefined;
        readonly id: string;
        readonly impressions: number | null | undefined;
        readonly mediaUrl: string | null | undefined;
        readonly placement: InstagramInsightsMediaPlacement;
        readonly reach: number | null | undefined;
        readonly thumbnailUrl: string | null | undefined;
        readonly type: InstagramInsightsMediaType;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly user?: {
    readonly followedByCount: number | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "InsightsStoriesMedias_creator";
};
export type InsightsStoriesMedias_creator$key = {
  readonly " $data"?: InsightsStoriesMedias_creator$data;
  readonly " $fragmentSpreads": FragmentRefs<"InsightsStoriesMedias_creator">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnailUrl",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InsightsStoriesMedias_creator",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "InstagramUser",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "followedByCount",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": "stories",
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 30
            },
            {
              "kind": "Literal",
              "name": "placements",
              "value": [
                "STORY"
              ]
            }
          ],
          "concreteType": "InstagramInsightsMediaConnection",
          "kind": "LinkedField",
          "name": "insightsMedias",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "InstagramInsightsMediaEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "InstagramInsightsMedia",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "engagement",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "mediaUrl",
                      "storageKey": null
                    },
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "caption",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "impressions",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "reach",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "placement",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "finalStoryInsightsCollected",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "file",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "secureUrl",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "insightsMedias(first:30,placements:[\"STORY\"])"
        }
      ],
      "type": "InstagramCreator",
      "abstractKey": null
    }
  ],
  "type": "Creator",
  "abstractKey": "__isCreator"
};
})();

(node as any).hash = "3b0f08f685283ad125ebe643b93d1319";

export default node;
