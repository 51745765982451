/**
 * @generated SignedSource<<e5c4da1a5b9e1089b9762a1c5d626af7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AdvertiserProjectStage = "ACCEPTED" | "ACCEPTED_AND_NOT_LAUNCHED" | "ACCEPTED_BY_UNREVIEWED_CREATOR" | "COMPLETED" | "CONTENT_SUBMITTED" | "LAUNCHED" | "PRODUCT_SHIPMENT_COMPLETED" | "PRODUCT_SHIPMENT_STARTED" | "PUBLISHING_STARTED" | "READ" | "REJECTED" | "UNREAD" | "UNSENT";
export type Brief_ScreeningQuestion_Type = "BINARY" | "OPEN_ENDED";
export type CreatorType = "CONTENT" | "INSTAGRAM" | "TIKTOK" | "YOUTUBE";
import { FragmentRefs } from "relay-runtime";
export type CreatorCard_project$data = {
  readonly adminComment: string | null | undefined;
  readonly advertiserStage: AdvertiserProjectStage | null | undefined;
  readonly archivation: {
    readonly id: string;
  } | null | undefined;
  readonly comment: string | null | undefined;
  readonly creator: {
    readonly channel?: {
      readonly subscribers: number | null | undefined;
      readonly thumbnailUrl: string | null | undefined;
    } | null | undefined;
    readonly id: string;
    readonly tiktokUser?: {
      readonly followedByCount: number | null | undefined;
      readonly name: string;
    } | null | undefined;
    readonly type: CreatorType;
    readonly user?: {
      readonly engagementRate: number | null | undefined;
      readonly followedByCount: number | null | undefined;
    } | null | undefined;
    readonly username: string;
  } | null | undefined;
  readonly hasLaunchOrBrandMessage: boolean;
  readonly id: string;
  readonly offer: {
    readonly acceptance: {
      readonly id: string;
      readonly screeningResponses: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly booleanAnswer: boolean | null | undefined;
            readonly id: string;
            readonly preferred: boolean | null | undefined;
            readonly question: {
              readonly text: string;
              readonly type: Brief_ScreeningQuestion_Type;
            };
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
    } | null | undefined;
    readonly rejection: {
      readonly id: string;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"CreatorCardControls_project" | "CreatorDetails_project" | "CreatorInfo_project" | "CreatorPrice_project">;
  readonly " $fragmentType": "CreatorCard_project";
};
export type CreatorCard_project$key = {
  readonly " $data"?: CreatorCard_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreatorCard_project">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = [
  (v0/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "followedByCount",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreatorCard_project",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adminComment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Offer",
      "kind": "LinkedField",
      "name": "offer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OfferAcceptance",
          "kind": "LinkedField",
          "name": "acceptance",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "BriefScreeningQuestionResponseConnection",
              "kind": "LinkedField",
              "name": "screeningResponses",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BriefScreeningQuestionResponseEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "BriefScreeningQuestionResponse",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "booleanAnswer",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "preferred",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "BriefScreeningQuestion",
                          "kind": "LinkedField",
                          "name": "question",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "text",
                              "storageKey": null
                            },
                            (v1/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OfferRejection",
          "kind": "LinkedField",
          "name": "rejection",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasLaunchOrBrandMessage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "advertiserStage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "side",
          "value": "ADVERTISER"
        }
      ],
      "concreteType": "ProjectArchivation",
      "kind": "LinkedField",
      "name": "archivation",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": "archivation(side:\"ADVERTISER\")"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreatorCardControls_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreatorInfo_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreatorPrice_project"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "organizationId",
          "variableName": "organizationId"
        }
      ],
      "kind": "FragmentSpread",
      "name": "CreatorDetails_project"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "creator",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "InstagramUser",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "engagementRate",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "InstagramCreator",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "YoutubeChannel",
              "kind": "LinkedField",
              "name": "channel",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "subscribers",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "thumbnailUrl",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "YoutubeCreator",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": "tiktokUser",
              "args": null,
              "concreteType": "TiktokUser",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "type": "TiktokCreator",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};
})();

(node as any).hash = "dbf42485830ec962e31ee15da3c54356";

export default node;
