import React, { useCallback, useMemo, useContext } from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';

import styles from './OrganizationTeam.pcss';

import Page from 'Templates/Page/Page';
import { amplitude } from 'Helpers/amplitude';
import Limit from 'Molecules/Limit/Limit';
import Text from 'Components/ui/Text/Text';
import Tag from 'Components/ui/Tag/Tag';
import Avatar from 'Atoms/Avatar/Avatar';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { BILLING_ROUTE } from 'Constants/general';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { OrganizationTeamQuery as QueryType } from 'GraphTypes/OrganizationTeamQuery.graphql';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import { ExtendedPlanId, PlanType, getPlans } from 'Page/advertiser/BillingPlans/data';

const OrganizationTeamQuery = graphql`
  query OrganizationTeamQuery {
    currentUser {
      id
      organization {
        id
        name
        subscription {
          planId
          availableAddonIds
          addonItems {
            addon {
              id
              resourcesPerUnit
            }
            quantity
          }
          paused
          interval
          effectiveLimits {
            maxSeats
          }
          baseLimits {
            maxSeats
          }
        }
        members {
          edges {
            node {
              id
              email
              advertiserProfile {
                lastName
                firstName
              }
            }
          }
          totalCount
        }
      }
    }
  }
`;

const OrganizationTeam = () => {
  const { openDrawer } = useContext(DrawerContext);

  const data = useLazyLoadQuery<QueryType>(OrganizationTeamQuery, {});

  const organization = data?.currentUser?.organization;
  const organizationId = organization?.id;
  const organizationName = organization?.name;
  const members = organization?.members;
  const totalCount = members?.totalCount;
  const subscription = organization?.subscription;
  const interval = subscription?.interval;
  const availableAddonIds = subscription?.availableAddonIds || [];
  const limitOfUsers = subscription?.effectiveLimits.maxSeats || null;
  const defaultMaxSeats = subscription?.baseLimits.maxSeats;
  const currentId = data?.currentUser?.id;
  const planId = organization?.subscription?.planId;

  const isFreeUser = planId === 'FREE';
  const isTrial = planId === 'TRIAL_2022_08' || planId === 'TRIAL_2024_03';

  const handleInviteClick = useCallback(() => {
    amplitude.sendEvent({
      id: 221,
      category: 'advertiser_settings',
      name: 'add_new_member_click',
    });
    handleInvite();
  }, [organizationId, organizationName]);

  const handleInvite = () => {
    openDrawer('invite-team-member');
  };

  const handleAddMore = () => {
    openDrawer('buy-maxSeats-addon');
  };

  if (!data) return null;

  const list = useMemo(() => {
    return members?.edges?.map((item) => {
      if (!item?.node) return null;

      const { id, email, advertiserProfile } = item.node;

      const name = advertiserProfile?.firstName && advertiserProfile.lastName;

      const label = name ? `${advertiserProfile.firstName} ${advertiserProfile.lastName}` : email;

      return (
        <div key={id} className={styles.member}>
          <Avatar size="40" letters={label} className={styles.avatar} />
          <div className={styles.names}>
            <Text type="sm" text={name || ''} data-test="organizationTeam:text:unknown" />
            <Text
              type="md"
              text={email}
              className={styles.email}
              data-test="organizationTeam:text:email"
            />
          </div>
          {currentId === id && (
            <Tag
              color="purple"
              msg="advertiser.settings.team.you"
              className={styles.tag}
              data-test="organizationTeam:tag:you"
            />
          )}
        </div>
      );
    });
  }, [members?.edges]);

  const isFreePlan = isFreeUser || isTrial;

  const isUsersCountOverLimit = Boolean(
    totalCount && typeof limitOfUsers === 'number' && limitOfUsers <= totalCount
  );

  const currentPlanData = useMemo<PlanType | undefined>(() => {
    const plans = getPlans({
      currency: 'USD',
      plansProps: {
        [planId as ExtendedPlanId]: {
          interval,
          addons: [],
        },
      },
    });
    return plans.find((plan) => plan.planId === planId);
  }, [planId]);

  const isAddonAvailable = availableAddonIds.includes('SEATS');

  return (
    <Page className={styles.root}>
      <div className={styles.content}>
        <div className={styles.header}>
          <Text
            type="d2"
            msg="advertiser.settings.team.title"
            formatValues={{ count: totalCount || '' }}
            data-test="organizationTeam:text:title"
          />

          {isFreePlan && (
            <SmartLink path={BILLING_ROUTE}>
              <AlterButton
                icon="Add-plus-circle"
                type="black"
                msg="advertiser.settings.team.invite"
                data-test="organizationTeam:alterButton:invite"
              />
            </SmartLink>
          )}

          {!isFreePlan && isUsersCountOverLimit && (
            <Tooltip
              tooltipMsg="advertiser.settings.team.invite.overlimit"
              place="left"
              id={'advertiser.settings.team.invite.overlimit'}
            >
              <AlterButton
                icon="Add-plus-circle"
                onClick={handleAddMore}
                type="black"
                msg="advertiser.settings.team.invite"
                data-test="organizationTeam:alterButton:invite"
              />
            </Tooltip>
          )}

          {!isFreePlan && !isUsersCountOverLimit && (
            <AlterButton
              icon="Add-plus-circle"
              onClick={handleInviteClick}
              type="black"
              msg="advertiser.settings.team.invite"
              data-test="organizationTeam:alterButton:invite"
            />
          )}
        </div>
        <div className={styles.members}>
          <div className={styles.fields}>
            <Text
              type="sm"
              msg="advertiser.settings.team.name"
              className={styles.fieldName}
              data-test="organizationTeam:text:name"
            />
          </div>
          {list}
        </div>
      </div>
      <Limit
        limitExcceded={!!isUsersCountOverLimit}
        type="maxSeats"
        totalCount={totalCount}
        maxCount={limitOfUsers}
        addons={currentPlanData?.availableAddons || []}
        defaultLimit={defaultMaxSeats}
        disabledManage={!!isAddonAvailable || limitOfUsers === null}
        subscriptionData={subscription}
        successActionTitle="advertiser.settings.team.after_addon"
        successCallback={handleInvite}
      />
    </Page>
  );
};

export default OrganizationTeam;
