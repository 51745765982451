import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './InviteOutreachCreators.pcss';

import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { amplitude } from 'Helpers/amplitude';
import { getCampaignOutreachBatchLink } from 'Util/links';
import Text from 'Components/ui/Text/Text';
import outreachEmptyImg from 'Images/campaign/outreach_empty.png';
import outreachEmpty2xImg from 'Images/campaign/outreach_empty@2x.png';
import Tag from 'Components/ui/Tag/Tag';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import { OUTREACH_SETUP_GUIDE } from 'Constants/general';

interface Props {
  className?: string;
  isAllowToStartOutreach: boolean;
}

const InviteOutreachCreators: React.FC<Props> = (props) => {
  const { className, isAllowToStartOutreach } = props;
  const { id: campaignId = '' } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const handleInviteClick = () => {
    amplitude.sendEvent<409>({
      id: '409',
      category: 'campaign',
      name: 'outreach_button_click',
      param: undefined,
    });
    navigate(getCampaignOutreachBatchLink({ campaignId }));
  };

  return (
    <div className={className}>
      <img
        srcSet={`${outreachEmptyImg} 1x, ${outreachEmpty2xImg} 2x`}
        className={styles.emptyImage}
      />
      <Tag text="Beta" color="pink" />
      <Text
        type="h2"
        msg={
          isAllowToStartOutreach
            ? 'campaign.outreach.invite.empty_placeholder'
            : 'campaign.outreach.invite.empty_placeholder.invalid'
        }
        data-test="inviteOutreachCreators:text:title"
      />
      {isAllowToStartOutreach && (
        <>
          <div className={styles.description}>
            <Text
              type="md"
              msg={'campaign.outreach.invite.empty_placeholder.description'}
              data-test="inviteOutreachCreators:text:description"
              color="grey"
              className={styles.description}
            />
            <SmartLink path={OUTREACH_SETUP_GUIDE} target="_blank">
              <Text
                type="md"
                msg="campaign.outreach.invite.empty_placeholder.description_link"
                data-test="inviteOutreachCreators:text:link"
                color="pink"
              />
            </SmartLink>
          </div>
          <AlterButton
            msg="campaign.outreach.invite"
            type="pink"
            onClick={handleInviteClick}
            className={styles.button}
            data-test="inviteOutreachCreators:alterButton:invite"
          />
        </>
      )}
    </div>
  );
};

export default InviteOutreachCreators;
