import React from 'react';
import track from 'react-tracking';

import styles from './Error404.pcss';

import Text from 'Components/ui/Text/Text';
import Page from 'Templates/Page/Page';

const Error404 = () => {
  return (
    <Page className={styles.root}>
      <Text color="white" text="404" className={styles.errorText} data-test="error404:text:404" />
      <div className={styles.details}>
        <Text msg="error_page.title" className={styles.title} data-test="error404:text:title" />
        <Text msg="error_page.descr" className={styles.descr} data-test="error404:text:descr" />
      </div>
    </Page>
  );
};

export default track(
  {
    page: 'error_404',
  },
  { dispatchOnMount: true }
)(Error404);
