import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { graphql, useClientQuery, useQueryLoader } from 'react-relay';
import { useParams } from 'react-router-dom';

import styles from './Performance.pcss';
import PerformanceFilters, {
  PerformanceFiltersProps,
} from './PerformanceFilters/PerformanceFilters';
import PerformanceCollaborationsChart from './charts/PerformanceCollaborationsChart/PerformanceCollaborationsChart';
import TransactionsChart from './charts/TransactionsChart/TransactionsChart';
import ViewCountersChart from './charts/ViewCountersChart/ViewCountersChart';
import EngagementCountersChart from './charts/EngagementCountersChart/EngagementCountersChart';
import CpmCpeCountersChart from './charts/CpmCpeCountersChart/CpmCpeCountersChart';
import type { FilterValuesState } from './types';
import { FilterCampaignType, FilterPlatformType } from './types';
import ChartSuspense from './components/ChartSuspense/ChartSuspense';
import PerformanceStub from './PerformanceStub';

import { amplitude } from 'Helpers/amplitude';
import { compareDates, createStartOfNewYorkDay } from 'Util/dateCreator';
import Page from 'Templates/Page/Page';
import Text from 'Components/ui/Text/Text';
import { Performance_Granularity } from 'Types/enums';
import { USD } from 'Constants/general';
import type { PerformanceQuery as QueryType } from 'GraphTypes/PerformanceQuery.graphql';

const PerformanceQuery = graphql`
  query PerformanceQuery {
    campaigns {
      totalCount
    }
    currentUser {
      organization {
        id
      }
    }
  }
`;

const Performance: React.FC = () => {
  const [, loadQuery] = useQueryLoader<QueryType>(PerformanceQuery);

  const { id: organizationId } = useParams<{ id: string }>();

  useEffect(() => {
    amplitude.sendEvent<334>({
      id: '334',
      category: 'perfomance',
      name: 'pageview',
      param: undefined,
    });
    loadQuery({});
  }, []);

  const campaignsResponse = useClientQuery<QueryType>(PerformanceQuery, {});

  const [filterValues, setFilterValues] = useState<FilterValuesState>({
    brand: '',
    campaigns: undefined,
    dateRange: [
      createStartOfNewYorkDay(moment().subtract(2, 'week'), USD),
      createStartOfNewYorkDay(new Date(), USD),
    ],
    granularity: Performance_Granularity.DAILY,
    platform: FilterPlatformType.ALL_PLATFORMS,
    campaignType: FilterCampaignType.ALL_TYPES,
    useCase: 'ALL_TYPES',
  });

  const handleFiltersValueStateChange = useCallback<
    NonNullable<PerformanceFiltersProps['onFiltersValueStateChange']>
  >((filterValues) => {
    setFilterValues(filterValues);
  }, []);

  const isMissedStat = useMemo(() => {
    const pastFrom = compareDates(
      '2023-11-15T00:00:00.000-04:00',
      filterValues.dateRange[0],
      false
    );
    const pastTo = compareDates('2023-11-15T00:00:00.000-04:00', filterValues.dateRange[1], false);

    return pastFrom || pastTo;
  }, [filterValues.dateRange]);

  const isUgc = filterValues.campaignType === 'JUST_CONTENT';

  const currentOrganizationId = campaignsResponse.currentUser?.organization?.id;
  const finalOrganizationId = organizationId || currentOrganizationId;

  const filters = finalOrganizationId ? (
    <PerformanceFilters
      finalOrganizationId={finalOrganizationId}
      filterValues={filterValues}
      onFiltersValueStateChange={handleFiltersValueStateChange}
    />
  ) : null;

  if (
    !campaignsResponse ||
    !campaignsResponse.campaigns ||
    campaignsResponse.campaigns.totalCount === 0
  ) {
    return <PerformanceStub filters={filters} />;
  }

  return (
    <Page className={styles.root}>
      <div className={styles.container}>
        <Text
          type="d2"
          msg="performance.dashboard.title"
          className={styles.title}
          data-test="performance:text:title"
        />
        {filters}
        {isMissedStat && (
          <Text
            type="md"
            msg="performance.dashboard.date_limit.with_stat"
            className={styles.warning}
            data-test="performance:text:withStat"
          />
        )}
        <div className={styles.chartsContainer}>
          <ChartSuspense titleMsg="performance.dashboard.chart.collaborations.title">
            <PerformanceCollaborationsChart filterValues={filterValues} />
          </ChartSuspense>
          {isUgc && <div />}
          {!isUgc && (
            <ChartSuspense titleMsg="performance.dashboard.chart.view_counters.title">
              <ViewCountersChart filterValues={filterValues} />
            </ChartSuspense>
          )}
          {!isUgc && (
            <ChartSuspense titleMsg="performance.dashboard.chart.engagement_counters.title">
              <EngagementCountersChart filterValues={filterValues} />
            </ChartSuspense>
          )}
          {!isUgc && (
            <ChartSuspense titleMsg="performance.dashboard.chart.cpm_cpe_counters.title">
              <CpmCpeCountersChart filterValues={filterValues} />
            </ChartSuspense>
          )}
          <ChartSuspense titleMsg="performance.dashboard.chart.transactions.title">
            <TransactionsChart filterValues={filterValues} />
          </ChartSuspense>
        </div>
      </div>
    </Page>
  );
};

export default Performance;

// types
