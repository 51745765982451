/**
 * @generated SignedSource<<dd13d7ba7f5adefcb68b6602cc8e94ad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type ImportCreatorsBySearchQueryState = "FAILURE" | "PENDING" | "SUCCESS";
export type ImportCreatorsBySearchQueryOperationUpdatedSubscription$variables = Record<PropertyKey, never>;
export type ImportCreatorsBySearchQueryOperationUpdatedSubscription$data = {
  readonly importCreatorsBySearchQueryOperationUpdated: {
    readonly campaign: {
      readonly id: string;
      readonly latestImportCreatorsBySearchQueryOperation: {
        readonly state: ImportCreatorsBySearchQueryState;
      } | null | undefined;
    } | null | undefined;
  };
};
export type ImportCreatorsBySearchQueryOperationUpdatedSubscription = {
  response: ImportCreatorsBySearchQueryOperationUpdatedSubscription$data;
  variables: ImportCreatorsBySearchQueryOperationUpdatedSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ImportCreatorsBySearchQueryOperationUpdatedSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ImportCreatorsBySearchQueryOperation",
        "kind": "LinkedField",
        "name": "importCreatorsBySearchQueryOperationUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ImportCreatorsBySearchQueryOperation",
                "kind": "LinkedField",
                "name": "latestImportCreatorsBySearchQueryOperation",
                "plural": false,
                "selections": [
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ImportCreatorsBySearchQueryOperationUpdatedSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ImportCreatorsBySearchQueryOperation",
        "kind": "LinkedField",
        "name": "importCreatorsBySearchQueryOperationUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ImportCreatorsBySearchQueryOperation",
                "kind": "LinkedField",
                "name": "latestImportCreatorsBySearchQueryOperation",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b7f54e3d54b3c637d1342bc32735347f",
    "id": null,
    "metadata": {},
    "name": "ImportCreatorsBySearchQueryOperationUpdatedSubscription",
    "operationKind": "subscription",
    "text": "subscription ImportCreatorsBySearchQueryOperationUpdatedSubscription {\n  importCreatorsBySearchQueryOperationUpdated {\n    campaign {\n      id\n      latestImportCreatorsBySearchQueryOperation {\n        state\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b0ddf76f4fa69b525c99d4069c09b8bb";

export default node;
