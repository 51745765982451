import React, { useEffect, useMemo, useRef, useState } from 'react';
import { graphql, usePaginationFragment } from 'react-relay';
import last from 'lodash/last';

import styles from './ProjectEventsList.pcss';
import { visibleProjectEventsType } from './util';
import MessageEvent from './MessageEvent/MessageEvent';
import MessageAttachmentsProvider from './MessageEvent/MessageAttachments/MessageAttachmentsProvider';
import SystemProjectEvent from './SystemProjectEvent/SystemProjectEvent';
import DateSeparator from './DateSeparator/DateSeparator';
import ContentReviewSubmission from './ContentReviewSubmission/ContentReviewSubmission';
import PostPublication from './PostPublication/PostPublication';
import OfferAcceptanceEvent from './OfferAcceptanceEvent/OfferAcceptanceEvent';

import {
  CONTENT_REVIEW_SUBMISSION,
  MESSAGE,
  PUBLICATION_EVENT,
  POST_PUBLICATION,
  PRODUCT_SEEDING_CONTENT_SUBMISSION,
  OFFER_ACCEPTANCE,
} from 'Types/enums';
import { createDate } from 'Util/dateCreator';
import markProjectEventsAsRead from 'Mutations/MarkProjectEventsAsRead.Mutation';
import { UserType } from 'GraphTypes/ProjectEventsQuery.graphql';
import {
  ProjectEventsList_project$data,
  ProjectEventsList_project$key,
} from 'GraphTypes/ProjectEventsList_project.graphql';
import { CampaignType } from 'GraphTypes/BriefQuery.graphql';
import LoaderHandlerWithHooks from 'Organisms/LoaderHandler/NewLoaderHandler';
import usePrevious from 'Hooks/usePrevious';

const OFFSET = 70;
const COUNT = 20;

interface Props {
  projectId: string;
  campaignId?: string;
  currentUserId?: string;
  inPopup?: boolean;
  includeDeleted?: boolean;
  admin?: boolean;
  project: ProjectEventsList_project$key;
  userType?: UserType;
  productShipmentRequired?: boolean | null;
  screeningQuestion?: string | null;
}

const ProjectEventsList: React.FC<Props> = (props) => {
  const {
    project,
    userType,
    campaignId,
    projectId,
    currentUserId,
    productShipmentRequired,
    inPopup,
    admin,
  } = props;

  let currentDate: string | null = '';
  const lastItem = useRef<HTMLDivElement | null>(null);
  const bottomItem = useRef<HTMLDivElement | null>(null);
  const [readyToScroll, setReadyStatus] = useState(false);

  const fragmentData = usePaginationFragment(
    graphql`
      fragment ProjectEventsList_project on Query
      @argumentDefinitions(
        projectId: { type: "ID!" }
        last: { type: "Int", defaultValue: 20 }
        before: { type: "String" }
        includeDeleted: { type: "Boolean" }
      )
      @refetchable(queryName: "ProjectEventsPaginationList") {
        project(id: $projectId) {
          id
          deadline {
            draftDate
          }
          tiktokShopCollaboration {
            affiliateProductLink
          }
          campaign {
            type
          }
          events(before: $before, last: $last, includeDeleted: $includeDeleted)
            @connection(key: "ProjectEventsList_events", filters: []) {
            edges {
              node {
                id
                __typename
                createdAt
                readByAdvertiser
                ...SystemProjectEvent_event
                ...MessageEvent_event
                ...ContentReviewSubmission_event
                ...PostPublication_event
                ...OfferAcceptanceEvent_event
              }
            }
          }
        }
      }
    `,
    project
  );

  const data = fragmentData.data.project;
  const eventEdges = Array.from(data?.events?.edges || []);
  const lastMessageId = usePrevious(eventEdges[eventEdges.length - 1]?.node?.id);

  const lastPublicationEventIndex = useMemo<number | null>(() => {
    let index = -1;
    if (!eventEdges) {
      return null;
    }
    for (let i = eventEdges.length - 1; i > 0; i--) {
      if (eventEdges[i]?.node?.__typename === PUBLICATION_EVENT) {
        index = i;
        break;
      }
    }
    return index;
  }, [eventEdges]);

  if (!data?.events) {
    return null;
  }

  const markAsRead = () => {
    if (eventEdges?.length) {
      const lastEvent = last(eventEdges)?.node;
      const lastId = lastEvent?.id;
      const ignoreCounterUpdate = lastEvent?.__typename === 'ContentReviewPass';
      if (lastId) markProjectEventsAsRead({ uptoEventId: lastId, projectId, ignoreCounterUpdate });
    }
  };

  const update = () => {
    markAsRead();
  };

  const scrollDown = () => {
    if (fragmentData.isLoadingPrevious) return;
    if (lastMessageId === eventEdges[eventEdges.length - 1]?.node?.id) {
      lastItem.current?.scrollIntoView({ behavior: 'instant', block: 'start' });
    } else {
      bottomItem.current?.scrollIntoView({ behavior: 'instant', block: 'start' });
    }
    setReadyStatus(true);
  };

  useEffect(() => {
    if (readyToScroll === true) {
      update();
      scrollDown();
    }
  }, [eventEdges?.length, readyToScroll, projectId]);

  useEffect(() => {
    scrollDown();
  }, []);

  const lastItemIndex = eventEdges.length % COUNT === 0 ? COUNT - 1 : eventEdges.length % COUNT;

  const list =
    eventEdges?.map((item, index) => {
      if (!item?.node) return null;

      const { id, __typename, createdAt } = item.node;
      const separateEl = lastItemIndex === index ? <div ref={lastItem} /> : null;

      if (!visibleProjectEventsType.includes(__typename)) return null;

      let el;
      if (__typename === MESSAGE) {
        el = (
          <MessageEvent
            event={item.node}
            projectId={projectId}
            admin={admin}
            userType={userType}
            inPopup={inPopup}
          />
        );
      } else if (
        __typename === CONTENT_REVIEW_SUBMISSION ||
        __typename === PRODUCT_SEEDING_CONTENT_SUBMISSION
      ) {
        el = (
          <MessageAttachmentsProvider>
            <ContentReviewSubmission
              projectId={projectId}
              event={item.node}
              userType={userType}
              inPopup={inPopup}
            />
          </MessageAttachmentsProvider>
        );
      } else if (__typename === POST_PUBLICATION) {
        el = (
          <PostPublication
            event={item.node}
            userType={userType}
            campaignId={campaignId}
            projectId={projectId}
            currentUserId={currentUserId}
            disableGuideTour={lastPublicationEventIndex !== index}
          />
        );
      } else if (__typename === OFFER_ACCEPTANCE) {
        el = (
          <OfferAcceptanceEvent
            event={item.node}
            userType={userType}
            campaignType={data.campaign.type}
          />
        );
      } else {
        el = (
          <SystemProjectEvent
            event={item.node}
            userType={userType}
            productShipmentRequired={productShipmentRequired}
            draftDate={data.deadline?.draftDate}
            isLastEvent={index === eventEdges.length - 1}
            campaignType={data.campaign.type as CampaignType}
            tiktokShopCollaborationLink={data.tiktokShopCollaboration?.affiliateProductLink}
          />
        );
      }

      const createdAtDate = createDate(createdAt);
      let showDate = false;

      if (currentDate !== createdAtDate) {
        showDate = true;
        currentDate = createdAtDate;
      }

      return (
        <div key={id}>
          {separateEl}
          {showDate && el && <DateSeparator createdAt={createdAtDate} />}
          {el}
        </div>
      );
    }) || [];
  if (list.length === 0) {
    return <div />;
  }

  if (data?.id !== projectId) return null;

  return (
    <LoaderHandlerWithHooks
      relay={fragmentData}
      offset={OFFSET}
      count={COUNT}
      reverse={true}
      containerId="events"
      blocked={!readyToScroll}
      items={eventEdges}
    >
      <div className={styles.content}>{list}</div>
      <div ref={bottomItem} />
    </LoaderHandlerWithHooks>
  );
};

export default ProjectEventsList;

export type EventEdgesType = NonNullable<
  NonNullable<NonNullable<ProjectEventsList_project$data>['project']>['events']
>['edges'];

export type EventNodeType = NonNullable<NonNullable<EventEdgesType>[0]>['node'];
