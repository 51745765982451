import React, { PropsWithChildren, useEffect, useState, useContext, Suspense } from 'react';
import { fetchQuery, graphql, useLazyLoadQuery } from 'react-relay';

import { CampaignContext } from './Campaign.Context';
import { CreatorsQuery } from './Creators/Creators';

import {
  CreatorsQuery$variables,
  CreatorsQuery as CreatorsQueryType,
} from 'GraphTypes/CreatorsQuery.graphql';
import ErrorHandler from 'Util/errorHandler';
import environment from 'Api/Environment';
import InviteCreatorsDrawer from 'Modal/advertiser/InviteCreators/InviteCreators';
import { FeatureFlagsContext } from 'Containers/FeatureFlags/FeatureFlags.Context';
import { useCreateInviteSuggestionListQueryMutation } from 'Mutations/CreateInviteSuggestionList.Mutation';
import { CampaignProviderQuery as QueryType } from 'GraphTypes/CampaignProviderQuery.graphql';

interface Props {
  campaignId: string;
}

const CampaignProviderQuery = graphql`
  query CampaignProviderQuery($id: ID!) {
    campaign(id: $id) {
      activation {
        id
      }
      latestInviteSuggestionList {
        id
        inviteSuggestions {
          totalCount
        }
      }
      type
      platform
      postingType
      maxInvitationCount
      usedInvitationCount
      searchQuery {
        customLists {
          id
          name
        }
        id
        ageRanges {
          id
          name
        }
        audienceCities {
          id
          name
        }
        audienceGenderPercentage
        audienceAgeRangePercentage
        audienceCountryPercentage
        audienceCityPercentage
        audienceGender {
          id
          name
        }
        categories {
          id
          name
        }
        cities {
          id
          name
        }
        countries {
          id
          name
        }
        tags {
          id
          name
        }
        creatorCountries {
          id
          name
        }
        currency
        creatorEthnicities {
          id
          name
        }
        creatorAnimals {
          id
          name
        }
        audienceEthnicities {
          id
          name
        }
        creatorBirthdateFrom
        creatorBirthdateTo
        engagementRateFrom
        engagementRateTo
        followersFrom
        followersTo
        realFollowersFrom
        realFollowersTo
        viewsPerPostFrom
        viewsPerPostTo
        viewsMedianFrom
        viewsMedianTo
        storiesReachFrom
        storiesReachTo
        audienceInterestPercentage
        postsReachFrom
        postsReachTo
        reelsPlaysMedianFrom
        reelsPlaysMedianTo
        campaignsCompletionsCountFrom
        campaignsCompletionsCountTo
        onlyCreatorsWorkedWith
        creatorGender {
          id
          name
        }
        creatorLanguages {
          id
          name
        }
        creatorBodyTypes {
          id
          name
        }
        creatorHairTypes {
          id
          name
        }
        excludeBadges
        experienced
        reliable
        highAverageRating
        strictlyByPrimaryCategory
        includeAmazonStorefrontLink
        favorite
        withTiktokShop
      }
    }
  }
`;

const CampaignProvider: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, campaignId } = props;

  const [useCreateInviteSuggestionListQuery] = useCreateInviteSuggestionListQueryMutation();

  const campaignData = useLazyLoadQuery<QueryType>(CampaignProviderQuery, { id: campaignId });

  const searchQuery = campaignData.campaign?.searchQuery;

  const defaultListingId = campaignData.campaign?.latestInviteSuggestionList?.id || '';
  const defaultListingTotalCount =
    campaignData.campaign?.latestInviteSuggestionList?.inviteSuggestions.totalCount || 0;

  const [newProjectsWithBrandMessage, setNewProjectsWithBrandMessage] = useState<string[]>([]);
  const [inviteSuggestionListId, setInviteSuggestionListId] = useState<string>(defaultListingId);
  const [inviteSuggestionListCount, setInviteSuggestionListCount] =
    useState<number>(defaultListingTotalCount);
  const [creatorsListData, setCreatorsListData] = useState<CreatorsQuery$variables>();

  const { hasFeatureFlag } = useContext(FeatureFlagsContext);
  const newCampaignTabs = hasFeatureFlag('new_campaign_tabs');

  const maxInvitationCount = campaignData?.campaign?.maxInvitationCount || 0;
  const usedInvitationCount = campaignData?.campaign?.usedInvitationCount || 0;
  const activation = !!campaignData?.campaign?.activation;

  const outOffLimit = usedInvitationCount >= maxInvitationCount;

  const inviteDisabled = !(
    (campaignData?.campaign?.type === 'INFLUENCER' &&
      campaignData?.campaign.postingType === 'ORGANIC_POSTING') ||
    campaignData?.campaign?.type === 'PRODUCT_SEEDING'
  );

  const canInviteSuggestedCreators = !(outOffLimit || inviteDisabled);

  const createInviteSuggestionList = () => {
    useCreateInviteSuggestionListQuery({
      variables: { input: { campaignId } },
      onCompleted: (data) => {
        if (data.createInviteSuggestionList?.__typename !== 'CreateInviteSuggestionListPayload') {
          return;
        }
        const inviteSuggestionListId = data.createInviteSuggestionList?.inviteSuggestionList?.id;
        const totalCount =
          data.createInviteSuggestionList?.inviteSuggestionList?.inviteSuggestionsCount.totalCount;
        if (inviteSuggestionListId) {
          setInviteSuggestionListId(inviteSuggestionListId);
        }
        if (totalCount) {
          setInviteSuggestionListCount(totalCount);
        }
      },
    });
  };

  useEffect(() => {
    if (newCampaignTabs && activation && !campaignData.campaign.latestInviteSuggestionList?.id) {
      createInviteSuggestionList();
    }
  }, []);

  const onInviteSuggestionListCountDecrease = (num?: number) => {
    const newCount = inviteSuggestionListCount - (num || 1);
    setInviteSuggestionListCount(newCount < 0 ? 0 : newCount);
  };

  const onNewProjectsWithBrandMessage = (newId: string) => {
    setNewProjectsWithBrandMessage([...newProjectsWithBrandMessage, newId]);
  };

  const onCheckCreatorsExistence = () => {
    if (!creatorsListData) return;
    fetchQuery<CreatorsQueryType>(environment, CreatorsQuery, creatorsListData).subscribe({
      error: () => {
        ErrorHandler.error('Crash while getting creators existence');
      },
    });
  };

  const onCreatorsListDataUpdate = (projectsData: CreatorsQuery$variables) => {
    setCreatorsListData(projectsData);
  };

  const contextValue = {
    campaignData,
    canInviteSuggestedCreators,
    createInviteSuggestionList,
    inviteSuggestionListId,
    inviteSuggestionListCount,
    newProjectsWithBrandMessage,
    onNewProjectsWithBrandMessage,
    onInviteSuggestionListCountDecrease,
    onCheckCreatorsExistence,
    onCreatorsListDataUpdate,
  };

  return (
    <CampaignContext.Provider value={contextValue}>
      {children}
      {newCampaignTabs && (
        <Suspense fallback={null}>
          {searchQuery && inviteSuggestionListId && inviteSuggestionListId && (
            <InviteCreatorsDrawer
              usedInvitationCount={usedInvitationCount}
              maxInvitationCount={maxInvitationCount}
              campaignId={campaignId}
              searchQuery={searchQuery}
              inviteSuggestionListCount={inviteSuggestionListCount || 0}
              inviteSuggestionListId={inviteSuggestionListId}
            />
          )}
        </Suspense>
      )}
    </CampaignContext.Provider>
  );
};

export default CampaignProvider;
