/**
 * @generated SignedSource<<e44ea280f1448b38d8e0f442c5de01dd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdvertiserFilesOrderBy = "createdAt_ASC" | "createdAt_DESC";
export type FileAttachmentType = "CONTENT_REVIEW" | "CREATIVE" | "MESSAGE";
export type FileType = "IMAGE" | "OTHER" | "VIDEO";
export type PublicationSelectionAttachmentsQuery$variables = {
  before?: string | null | undefined;
  campaignId: string;
  creatorId: string;
  fileAttachmentType?: FileAttachmentType | null | undefined;
  fileTypes?: ReadonlyArray<FileType> | null | undefined;
  last?: number | null | undefined;
  orderBy?: AdvertiserFilesOrderBy | null | undefined;
  organizationId: string;
};
export type PublicationSelectionAttachmentsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ProjectAttachmentsCampaign_files">;
};
export type PublicationSelectionAttachmentsQuery = {
  response: PublicationSelectionAttachmentsQuery$data;
  variables: PublicationSelectionAttachmentsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "before"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "campaignId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "creatorId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "fileAttachmentType"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "fileTypes"
  },
  {
    "defaultValue": 20,
    "kind": "LocalArgument",
    "name": "last"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orderBy"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = {
  "kind": "Variable",
  "name": "before",
  "variableName": "before"
},
v2 = {
  "kind": "Variable",
  "name": "campaignId",
  "variableName": "campaignId"
},
v3 = {
  "kind": "Variable",
  "name": "creatorId",
  "variableName": "creatorId"
},
v4 = {
  "kind": "Variable",
  "name": "fileAttachmentType",
  "variableName": "fileAttachmentType"
},
v5 = {
  "kind": "Variable",
  "name": "fileTypes",
  "variableName": "fileTypes"
},
v6 = {
  "kind": "Variable",
  "name": "last",
  "variableName": "last"
},
v7 = {
  "kind": "Variable",
  "name": "orderBy",
  "variableName": "orderBy"
},
v8 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PublicationSelectionAttachmentsQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "kind": "Variable",
            "name": "organizationId",
            "variableName": "organizationId"
          }
        ],
        "kind": "FragmentSpread",
        "name": "ProjectAttachmentsCampaign_files"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PublicationSelectionAttachmentsQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "organizationId"
          }
        ],
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "FileConnection",
            "kind": "LinkedField",
            "name": "files",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FileEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "thumbnailUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "secureUrl",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "duration",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "VideoTransformation",
                            "kind": "LinkedField",
                            "name": "transformations",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "autoUrl",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "Video",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasPreviousPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v8/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "ProjectAttachmentsList_files",
            "kind": "LinkedHandle",
            "name": "files"
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "721e0b871530dbbc04df3521dc5138fd",
    "id": null,
    "metadata": {},
    "name": "PublicationSelectionAttachmentsQuery",
    "operationKind": "query",
    "text": "query PublicationSelectionAttachmentsQuery(\n  $before: String\n  $campaignId: ID!\n  $creatorId: ID!\n  $fileAttachmentType: FileAttachmentType\n  $fileTypes: [FileType!]\n  $last: Int = 20\n  $orderBy: AdvertiserFilesOrderBy\n  $organizationId: ID!\n) {\n  ...ProjectAttachmentsCampaign_files_36toqe\n}\n\nfragment ProjectAttachmentsCampaign_files_36toqe on Query {\n  organization(id: $organizationId) {\n    files(last: $last, before: $before, creatorId: $creatorId, campaignId: $campaignId, fileTypes: $fileTypes, fileAttachmentType: $fileAttachmentType, orderBy: $orderBy) {\n      edges {\n        node {\n          __typename\n          id\n          thumbnailUrl\n          secureUrl\n          ... on Video {\n            duration\n            transformations {\n              autoUrl\n            }\n          }\n        }\n        cursor\n      }\n      totalCount\n      pageInfo {\n        hasPreviousPage\n        startCursor\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "4a48f1d1af184586a327cfb482811e43";

export default node;
