import React, { useMemo } from 'react';
import classnames from 'classnames';
import { useIntl } from 'react-intl';

import ProjectFilterLabel from '../ProjectFilterLabel/ProjectFilterLabel';

import styles from './ProjectsStatuses.pcss';

import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownGroupItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import {
  ACCEPTED,
  STAGE_LAUNCHED,
  CONTENT_SUBMITTED,
  STAGE_COMPLETED,
  PUBLISHING_STARTED,
} from 'Constants/general';
import { AdvertiserProjectStage } from 'GraphTypes/CreatorsListQuery.graphql';

const ARCHIVED_ID = 'ARCHIVED';

interface StatusesList {
  id: ProjectStatuses;
  msg: string;
}

type ProjectStatuses = AdvertiserProjectStage | typeof ARCHIVED_ID;

interface Props {
  archived?: boolean;
  stages?: AdvertiserProjectStage[] | null;
  onChange: (newStages?: AdvertiserProjectStage[], newArchived?: boolean) => void;
}

const ProjectsStatuses: React.FC<Props> = (props) => {
  const { stages, archived: archivedValue, onChange } = props;

  const intl = useIntl();

  const statuses: StatusesList[] = [
    {
      id: ACCEPTED,
      msg: 'project.stage.accepted_new',
    },
    { id: STAGE_LAUNCHED, msg: 'project.stage.launched' },
    {
      id: CONTENT_SUBMITTED,
      msg: 'project.stage.content_submitted',
    },
    {
      id: PUBLISHING_STARTED,
      msg: 'project.stage.publishing_started',
    },
    {
      id: STAGE_COMPLETED,
      msg: 'project.stage.completed',
    },
    {
      id: ARCHIVED_ID,
      msg: 'project.stage.archived',
    },
  ];

  const handleChange = (stages: ProjectStatuses[]) => {
    let result: ProjectStatuses[] = [...(stages || [])];
    let hasArchived = false;
    if (result.includes(ARCHIVED_ID)) {
      hasArchived = true;
      result = result.filter((item) => item !== ARCHIVED_ID);
    }
    onChange(result as AdvertiserProjectStage[], hasArchived);
  };

  const menuValue = useMemo<ProjectStatuses[]>(() => {
    const result = [...(stages || [])] as ProjectStatuses[];
    if (archivedValue) {
      result.push(ARCHIVED_ID);
    }
    return result;
  }, [stages, archivedValue]);

  const labels = useMemo(() => {
    if (!menuValue.length) return { msg: 'projects.all_statuses' };
    return {
      text: menuValue
        .map((entityId) => {
          const postfixLabel = entityId === 'ACCEPTED' ? '_new' : '';
          return intl.formatMessage({
            id: `project.stage.${entityId.toLowerCase()}${postfixLabel}`,
          });
        })
        .join(', '),
    };
  }, [menuValue]);

  const handleClearFilter = () => {
    onChange([], false);
  };

  return (
    <Dropdown
      closeBySelect={false}
      value={<ProjectFilterLabel anchorProps={labels} valueLength={menuValue.length} />}
    >
      <DropdownGroup className={styles.list}>
        <div>
          {statuses?.map((item) => {
            const { id, msg } = item;

            const isActive = menuValue.includes(id);

            const handleClick = () => {
              let value = [...menuValue];
              if (id === ARCHIVED_ID) {
                value = [ARCHIVED_ID];
              } else {
                if (value.includes(ARCHIVED_ID)) {
                  value = menuValue.filter((item) => item !== ARCHIVED_ID);
                }
                if (isActive) {
                  value = menuValue.filter((item) => item !== id);
                } else {
                  value.push(id);
                }
              }
              handleChange(value);
            };

            return (
              <div key={id}>
                {id === ARCHIVED_ID && <div className={styles.divider} />}
                <DropdownGroupItem
                  key={id}
                  handleClick={handleClick}
                  className={classnames(styles.listItem, { [styles.isActive]: isActive })}
                >
                  <AlterButton
                    msg={msg}
                    fluid
                    rightElement={<div className={classnames(styles.checkbox)} />}
                    data-test="projectsStatuses:alterButton:unknown"
                  />
                </DropdownGroupItem>
              </div>
            );
          })}
        </div>
        {(stages?.length || archivedValue) && (
          <AlterButton
            msg={'projects.filter.clear'}
            className={styles.clearBtn}
            handleClick={handleClearFilter}
            data-test="projectsStatuses:alterButton:clearBtn"
          />
        )}
      </DropdownGroup>
    </Dropdown>
  );
};

export default ProjectsStatuses;
