import React from 'react';

import styles from './VettedCreatorsInfoText.pcss';

import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import Tooltip from 'Atoms/Tooltip/Tooltip';

const VettedCreatorsInfoText: React.FC = () => {
  return (
    <div className={styles.root}>
      <Text type="label" msg="filter.form.vetted_creators.info" />
      <Tooltip
        id="filter.form.vetted_creators.tooltip"
        tooltipMsg="filter.form.vetted_creators.tooltip"
      >
        <Icon name="Info" size={16} />
      </Tooltip>
    </div>
  );
};

export default VettedCreatorsInfoText;
