import React, { useCallback, useMemo, useState } from 'react';
import classnames from 'classnames';
import debounce from 'lodash/debounce';

import { Filters } from '../ProjectsSidebar';

import styles from './ProjectsFilter.pcss';
import OrganizationBrands from './OrganizationBrands/OrganizationBrands';
import OrganizationCampaigns from './OrganizationCampaigns/OrganizationCampaigns';
import ProjectsStatuses from './ProjectsStatuses/ProjectsStatuses';
import OrganizationData from './OrganizationData/OrganizationData';

import DueDateFilter, {
  DueDates,
  DueDateFilterType,
  DueDateRangeType,
  DropdownOptionType,
} from 'AdvertiserPage/Campaign/Creators/ProjectsFilters/DueDateFilter/DueDateFilter';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import TextButton from 'Components/ui/TextButton/TextButton';
import Input from 'Components/ui/Input/Input';
import { AdvertiserProjectStage } from 'GraphTypes/CreatorsListQuery.graphql';

type Section = 'filters' | 'textQuery';

interface Props {
  filters: Filters;
  onChange: (newFilters: Filters) => void;
  onClearParams: () => void;
  onOrganizationChange: (id: string) => void;
  organizationId?: string;
}

const ProjectsFilter: React.FC<Props> = (props) => {
  const { filters, onChange, onClearParams, onOrganizationChange, organizationId } = props;

  const [currentSection, setCurrentSection] = useState<Section | undefined>('filters');
  const [deadlineDate, setDeadlineDate] = useState<
    Partial<Record<DropdownOptionType<DueDateRangeType>['id'], boolean>>
  >({});
  const [defaultDeadlineInclude, setDeadlineInclude] = useState<
    DropdownOptionType<DueDateFilterType>['id'] | null
  >('draft_deadline');

  const withUnreadEvents = filters.withUnreadEvents;
  const shortlisted = filters.shortlisted;
  const textQuery = filters.textQuery;
  const brandIds = filters.brandIds || [];
  const campaignIds = filters.campaignIds || [];
  const archived = filters.archived;
  const stages = filters.stages;
  const draftDeadlineIntervals = filters.draftDeadlineIntervals;
  const publicationDeadlineIntervals = filters.publicationDeadlineIntervals;

  const handleShortlistedChange = useCallback(() => {
    onChange({ ...filters, shortlisted: shortlisted ? undefined : true });
  }, [shortlisted, onChange]);

  const handleWithUnreadEventsChange = useCallback(() => {
    onChange({ ...filters, withUnreadEvents: withUnreadEvents ? undefined : true });
  }, [withUnreadEvents, onChange]);

  const handleBrandIdsChange = useCallback(
    (newBrandIds: string[]) => {
      if (newBrandIds.length > 0) {
        onChange({ ...filters, brandIds: newBrandIds, campaignIds: [] });
        return;
      }
      onChange({ ...filters, brandIds: newBrandIds });
    },
    [onChange]
  );

  const handleCampaignIdsChange = useCallback(
    (newCampaignIds: string[]) => {
      onChange({ ...filters, campaignIds: newCampaignIds });
    },
    [onChange]
  );

  const handleTextQueryChange = useCallback(
    (newTextQuery: string) => {
      onChange({ ...filters, textQuery: newTextQuery });
    },
    [onChange]
  );

  const debounceTextQueryChange = debounce(handleTextQueryChange, 500);

  const handleStageChange = useCallback(
    (newStages?: AdvertiserProjectStage[], newArchived?: boolean) => {
      onChange({ ...filters, stages: newStages, archived: newArchived });
    },
    [onChange]
  );

  const handleTextQueryToggle = () => {
    const newSectionValue = currentSection === 'textQuery' ? undefined : 'textQuery';
    setCurrentSection(newSectionValue);
  };

  const handleDueDateChange = useCallback(
    (data: DueDates) => {
      if (data.deadlineInclude) {
        setDeadlineInclude(data.deadlineInclude);
      }
      if (data.deadlineDate) {
        setDeadlineDate(data.deadlineDate);
      }
      onChange({
        ...filters,
        draftDeadlineIntervals: data.draftDueIntervals,
        publicationDeadlineIntervals: data.publicationDueIntervals,
      });
    },
    [onChange]
  );

  const handleClearParams = () => {
    onClearParams();
    setDeadlineDate({});
    setDeadlineInclude('draft_deadline');
  };

  const filtersActiive = useMemo(() => {
    return brandIds.length > 0 || campaignIds.length > 0 || Number(stages?.length) > 0;
  }, [brandIds, campaignIds, stages]);

  const isParamsChosen =
    filtersActiive ||
    shortlisted ||
    withUnreadEvents ||
    textQuery ||
    archived ||
    Number(draftDeadlineIntervals?.length) > 0 ||
    Number(publicationDeadlineIntervals?.length) > 0;

  return (
    <div>
      <div className={styles.filters}>
        <OrganizationData
          organizationId={organizationId}
          onOrganizationChange={onOrganizationChange}
        />
        <div className={styles.controls}>
          <AlterButton
            icon="Search-loop"
            className={classnames({ [styles.activeFilter]: !!textQuery })}
            onClick={handleTextQueryToggle}
            data-test="projectsFilter:alterButton:unknown"
          />
          <AlterButton
            icon="Thumbs-up-accept"
            active={shortlisted}
            onClick={handleShortlistedChange}
            className={classnames({ [styles.activeFilter]: !!shortlisted })}
            data-test="projectsFilter:alterButton:unknown"
          />
          <AlterButton
            icon="Unread-Messages"
            active={withUnreadEvents}
            onClick={handleWithUnreadEventsChange}
            className={classnames({ [styles.activeFilter]: !!withUnreadEvents })}
            data-test="projectsFilter:alterButton:unknown"
          />
          <DueDateFilter
            publishingRequired={true}
            onChange={handleDueDateChange}
            hideArrow
            dropdownClassName={styles.dropdown}
            dropdownContainerClassName={styles.dropdownContainer}
            tooltipData={{ place: 'right' }}
            place="chat"
            defaultDeadlineInclude={defaultDeadlineInclude}
            defaultDeadlineDate={deadlineDate}
            withDefaultValues={true}
          />
        </div>
        <div
          className={classnames(styles.textQueryWrap, {
            [styles.hidden]: currentSection !== 'textQuery',
          })}
        >
          <Input
            bordered
            value={filters.textQuery}
            handleChange={debounceTextQueryChange}
            placeholderMsg="projects.text_query.placeholder"
            data-test="projectsFilter:input:unknown"
          />
        </div>
        <div>
          <OrganizationBrands
            organizationId={organizationId}
            selectedItems={brandIds}
            onChange={handleBrandIdsChange}
          />
          <OrganizationCampaigns
            organizationId={organizationId}
            selectedItems={campaignIds}
            brandIds={brandIds}
            onChange={handleCampaignIdsChange}
          />
          <ProjectsStatuses archived={archived} stages={stages} onChange={handleStageChange} />
        </div>
      </div>
      {isParamsChosen && (
        <TextButton
          msg="projects.clear_params"
          color="grey"
          onClick={handleClearParams}
          className={styles.clearParams}
          data-test="projectsFilter:textButton:clearParams"
        />
      )}
    </div>
  );
};

export default ProjectsFilter;
