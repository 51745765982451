import React, { Suspense, useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import cn from 'classnames';
import { useQueryLoader } from 'react-relay';

import styles from './Layout.pcss';
import Modal from './Modal/Modal';
import Header from './Header/Header';
import Content from './Content/Content';
import Preloader from './Preloader/Preloader';
import Notifications from './Notifications/Notifications';
import NewNotifications from './NewNotifications/NewNotifications';
import Navigation from './Navigation';
import { query } from './Navigation/Navigation.Query';
import InfoWidget from './ClientMobileInfoWidget/ClientMobileInfoWidget';

import UserProvider from 'Containers/UserProvider/UserProvider';
import DrawerContainer from 'Containers/Drawer/DrawerContainer';
import { SearchFiltersDataProvider } from 'Containers/SearchFiltersData/SearchFiltersData.Context';
import useDashly from 'Hooks/useDashly';
import { NotificationsContextProvider } from 'Containers/Notifications/Notifications.Context';
import { Auth0Context } from 'Containers/Auth0/Auth0Context';
import { ADVERTISER } from 'Constants/general';

interface Props {
  className?: string;
}

const Layout: React.FC<Props> = (props) => {
  const { className } = props;
  const { connect: connectDashly } = useDashly();
  const { user } = useContext(Auth0Context);

  const [queryReference, loadQuery] = useQueryLoader(query);

  useEffect(() => {
    loadQuery({});
  }, []);

  useEffect(() => {
    connectDashly(process.env.DASHLY_API_KEY);
  }, [connectDashly]);

  return (
    <DrawerContainer>
      <NotificationsContextProvider>
        <SearchFiltersDataProvider>
          <div className={cn(className, styles.root)} id="layout">
            <Header className={styles.header} />
            <div className={styles.content}>
              <Suspense
                fallback={
                  user?.type === ADVERTISER ? <div className={styles.navigationSkeleton} /> : null
                }
              >
                {queryReference && <Navigation queryReference={queryReference} />}
              </Suspense>
              <Content>
                <Outlet />
              </Content>
            </div>
            <Modal />
            <Preloader />
            <Notifications />
            <NewNotifications />
            <UserProvider />
            <InfoWidget />
          </div>
        </SearchFiltersDataProvider>
      </NotificationsContextProvider>
    </DrawerContainer>
  );
};

export default Layout;
