import React, { useContext } from 'react';

import CancelSubscriptionDrawer from '../CancelSubscriptionDrawer/CancelSubscriptionDrawer';
import CancelTypeformSubscriptionDrawer from '../CancelTypeformSubscriptionDrawer/CancelTypeformSubscriptionDrawer';
import CancelOfferSubscriptionDrawer from '../CancelOfferSubscriptionDrawer/CancelOfferSubscriptionDrawer';

import styles from './CancelButton.pcss';

import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { amplitude } from 'Helpers/amplitude';
import { CurrentSubscriptionInfo_organization$data } from 'GraphTypes/CurrentSubscriptionInfo_organization.graphql';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

interface Props {
  organizationId: string;
  organizationName: string;
  subscription: NonNullable<CurrentSubscriptionInfo_organization$data['subscription']>;
  isAllowToPauseAgain: boolean;
}

const CancelButton: React.FC<Props> = (props) => {
  const { organizationId, organizationName, subscription, isAllowToPauseAgain } = props;
  const { openDrawer } = useContext(DrawerContext);

  const handleCancelDrawerOpen = () => {
    openDrawer('cancel-subscription-drawer');
    amplitude.sendEvent<375>({
      id: '375',
      category: 'billing',
      name: 'initiated_cancel_plan',
    });
  };

  return (
    <>
      <AlterButton
        type={'white'}
        textByCenter
        msg="billing.current_plan.cancel_plan"
        className={styles.cancelPlanBtn}
        classes={{
          buttonText: styles.cancelPlanBtnText,
        }}
        onClick={handleCancelDrawerOpen}
        data-test="cancelButton:alterButton:cancelPlan"
      />
      <CancelSubscriptionDrawer isAllowToPauseAgain={isAllowToPauseAgain} />
      <CancelTypeformSubscriptionDrawer
        organizationId={organizationId}
        organizationName={organizationName}
        subscription={subscription}
      />
      <CancelOfferSubscriptionDrawer subscription={subscription} />
    </>
  );
};

export default CancelButton;
