import React, { useContext, useEffect, useMemo, useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import classNames from 'classnames';

import styles from '../InviteOutreachCreators.pcss';
import {
  InviteOutreachCreatorsError,
  InviteOutreachCreatorsContext,
} from '../InviteOutreachCreatorsContext';
import { DEFAULT_DAILY_EMAIL_LIMIT } from '../InviteOutreachCreatorsProvider';

import Text from 'Components/ui/Text/Text';
import DropdownItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import { GmailSettingsForOutreach_campaign$key } from 'GraphTypes/GmailSettingsForOutreach_campaign.graphql';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import AddGmailAccountButton from 'Page/advertiser/Campaign/Outreach/OutreachBatchesControls/AddGmailAccountButton/AddGmailAccountButton';
import { GmailAuthDataResponse } from 'Page/advertiser/Campaign/Outreach/OutreachBatchesControls/AddGmailAccountButton/utils';
import Icon from 'Components/ui/Icon/Icon';

interface Props {
  query: GmailSettingsForOutreach_campaign$key;
}

const GmailSettings: React.FC<Props> = (props) => {
  const { query } = props;
  const {
    senderActiveAuth,
    senderName,
    senderEmail,
    setSenderActiveAuth,
    setSenderEmail,
    setSenderName,
    setError,
    setAvailableNameList,
    setEmailDailyLimit,
  } = useContext(InviteOutreachCreatorsContext);

  const data = useFragment(
    graphql`
      fragment GmailSettingsForOutreach_campaign on Campaign {
        organization {
          gmailAuthorizations {
            edges {
              node {
                id
                reauthenticationRequired
                availableEmails
                availableNames
                dailySendingLimit
                outreachCounters {
                  todayLimit
                  todayCount
                }
              }
            }
          }
        }
      }
    `,
    query
  );

  const [name, setName] = useState(senderName);
  const [email, setEmail] = useState(senderEmail);
  const [authData, setAuthData] = useState(senderActiveAuth);

  const gmailAuthorizationList =
    data.organization?.gmailAuthorizations?.edges?.reduce((list, auth) => {
      if (auth?.node?.availableEmails && !auth?.node?.reauthenticationRequired) {
        return [...list, auth?.node];
      }
      return list;
    }, []) || [];

  const previousAvailableNames =
    data.organization?.gmailAuthorizations?.edges?.reduce((list, auth) => {
      if (!auth?.node?.reauthenticationRequired) {
        return [...list, ...(auth?.node?.availableNames || [])];
      }
      return list;
    }, []) || [];
  const [availableGmailAccounts, setAvailableGmailAccounts] = useState(gmailAuthorizationList);

  const availableEmails = useMemo(() => {
    return availableGmailAccounts.reduce((list, auth) => {
      const emails = auth?.availableEmails?.map((email) => {
        return {
          email,
          authId: auth.id,
          todayLimit: auth.outreachCounters?.todayLimit || 0,
          todayCount: Math.min(auth.dailySendingLimit, auth.outreachCounters?.todayCount || 0),
        };
      });
      if (emails?.length) {
        return [...list, ...emails];
      }
      return list;
    }, []);
  }, [availableGmailAccounts]);

  const [availableNames, setAvailableNames] = useState(previousAvailableNames);

  useEffect(() => {
    if (availableNames[0]) {
      setName(availableNames[0]);
    }
    if (gmailAuthorizationList[0]?.id) {
      setAuthData(gmailAuthorizationList[0]?.id);
    }
    if (availableEmails[0]?.email) {
      setEmail(availableEmails[0]?.email);
    }
  }, [availableNames[0], gmailAuthorizationList[0]?.id, availableEmails[0]?.email]);

  useEffect(() => {
    setSenderName(name);
  }, [name]);

  useEffect(() => {
    setSenderEmail(email);
  }, [email]);

  useEffect(() => {
    setSenderActiveAuth(authData);
  }, [authData]);

  useEffect(() => {
    setAvailableNameList(availableNames);
  }, [availableNames]);

  const handleAddNewGmailAccount = (accountData: GmailAuthDataResponse['data']) => {
    if (accountData?.__typename === 'AuthenticateWithGmailPayload') {
      if (
        Array.isArray(accountData.gmailAuthorization.availableEmails) &&
        accountData.gmailAuthorization.availableEmails?.length
      ) {
        const isPreviousEmailsListEmpty = availableEmails.length === 0;
        setAvailableGmailAccounts((authList) => {
          return [...authList, accountData.gmailAuthorization];
        });
        if (isPreviousEmailsListEmpty) {
          setEmail(accountData.gmailAuthorization.availableEmails[0]);
          setAuthData(accountData.gmailAuthorization.id);
          setEmailDailyLimit({
            changed: false,
            value: Math.min(
              accountData.gmailAuthorization.dailySendingLimit,
              DEFAULT_DAILY_EMAIL_LIMIT
            ),
          });
        }
      }
      if (
        Array.isArray(accountData.gmailAuthorization.availableNames) &&
        accountData.gmailAuthorization.availableNames?.length
      ) {
        const isPreviousNamesListEmpty = availableNames.length === 0;
        setAvailableNames((nameList) => {
          return [...nameList, ...(accountData.gmailAuthorization.availableNames as string[])];
        });
        if (isPreviousNamesListEmpty) {
          setName(accountData.gmailAuthorization.availableNames[0]);
        }
      }
    } else if (accountData?.__typename === 'AuthenticateWithGmail_InvalidScopeError') {
      setError(InviteOutreachCreatorsError.INVALID_GMAIL_SCOPE);
    } else {
      setError(InviteOutreachCreatorsError.GENERAL_ERROR);
    }
  };

  const isStepValidated = Boolean(authData);

  return (
    <div>
      <div className={styles.subtitle}>
        {isStepValidated ? (
          <Icon name="Check-circle" color="green" size={20} />
        ) : (
          <div className={styles.circle} />
        )}
        <Text type="md" msg="invite_outreach_creators_modal.step_gmail.emails" />
      </div>

      <Dropdown
        value={
          <Text
            type="md"
            text={email}
            textPriority
            className={classNames(styles.dropdownValue, { [styles.placeholder]: !email })}
            msg="invite_outreach_creators_modal.step_gmail.empty_value"
          />
        }
        className={styles.dropdownSettings}
      >
        <DropdownGroup className={styles.dropdownMenu}>
          {availableEmails.map((item, index) => {
            const isActive = item.email === email;

            const handleEmailChange = () => {
              if (isActive) return;
              setEmail(item.email);
              setAuthData(item.authId);
              setSenderName(availableNames[index]);
              setEmailDailyLimit({
                changed: false,
                value: Math.min(item.todayLimit, DEFAULT_DAILY_EMAIL_LIMIT),
              });
            };

            return (
              <DropdownItem key={`${item.authId}_${item.email}`}>
                <div className={styles.dropdownItem} onClick={handleEmailChange}>
                  <div className={styles.dropdownText}>
                    <Text type="md" text={item.email} />
                    <Text
                      type="label"
                      className={styles.dropdownSecondaryText}
                      msg={'invite_outreach_creators_modal.step_gmail.limits'}
                      formatValues={{
                        todayCount: item.todayCount,
                        todayLimit: item.todayLimit,
                      }}
                    />
                  </div>
                  <div className={styles.dropdownItemActive}>
                    {isActive && <Icon name="Check" />}
                  </div>
                </div>
              </DropdownItem>
            );
          })}
          <AddGmailAccountButton
            buttonProps={{
              className: styles.addAccountButton,
              type: 'white',
            }}
            callback={handleAddNewGmailAccount}
          />
        </DropdownGroup>
      </Dropdown>
    </div>
  );
};

export default GmailSettings;
