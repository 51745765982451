/**
 * @generated SignedSource<<3a1ccdbcec3fc29f07d9f2ad5e404645>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Currency = "CAD" | "RUB" | "USD";
export type MediaplanItemInfoQuery$variables = {
  creatorId: string;
  id: string;
};
export type MediaplanItemInfoQuery$data = {
  readonly campaign: {
    readonly mediaplanItem: {
      readonly currency: Currency | null | undefined;
      readonly estimatedPrice: number | null | undefined;
      readonly project: {
        readonly price: number | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type MediaplanItemInfoQuery = {
  response: MediaplanItemInfoQuery$data;
  variables: MediaplanItemInfoQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "creatorId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "creatorId",
    "variableName": "creatorId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "estimatedPrice",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MediaplanItemInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "MediaplanItem",
            "kind": "LinkedField",
            "name": "mediaplanItem",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Project",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "MediaplanItemInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "MediaplanItem",
            "kind": "LinkedField",
            "name": "mediaplanItem",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Project",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2723bde07b6ec46dd16427e9c08de1de",
    "id": null,
    "metadata": {},
    "name": "MediaplanItemInfoQuery",
    "operationKind": "query",
    "text": "query MediaplanItemInfoQuery(\n  $id: ID!\n  $creatorId: ID!\n) {\n  campaign(id: $id) {\n    mediaplanItem(creatorId: $creatorId) {\n      estimatedPrice\n      currency\n      project {\n        price\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "bbe5c41129008dc4d26ab834ad747e19";

export default node;
