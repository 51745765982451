/**
 * @generated SignedSource<<4fac93c04f70cc9e7cf117dae2cf960c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CampaignObjective = "APP_INSTALLS" | "BRAND_AWARENESS" | "CATALOG_SALES" | "CONVERSIONS" | "ENGAGEMENT" | "JUST_CONTENT" | "LEAD_GENERATION" | "MESSAGES" | "REACH" | "STORE_TRAFFIC" | "TRAFFIC" | "VIDEO_VIEWS";
export type CampaignPostingType = "CREATOR_ADS" | "ORGANIC_POSTING";
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING";
export type CampaignUseCase = "AFFILIATE_CAMPAIGN" | "INFLUENCER_POSTS" | "META_PARTNERSHIP_ADS" | "PRODUCT_SEEDING" | "TIKTOK_SHOP" | "TIKTOK_SPARK_ADS" | "USER_GENERATED_CONTENT";
import { FragmentRefs } from "relay-runtime";
export type UseCase_campaign$data = {
  readonly brief: {
    readonly creatives?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    };
    readonly id: string;
  } | null | undefined;
  readonly id: string;
  readonly objective: CampaignObjective | null | undefined;
  readonly organization: {
    readonly tiktokShopLinked: boolean | null | undefined;
  } | null | undefined;
  readonly postingType: CampaignPostingType | null | undefined;
  readonly type: CampaignType | null | undefined;
  readonly useCase: CampaignUseCase | null | undefined;
  readonly useCasesUnlocked: boolean;
  readonly " $fragmentType": "UseCase_campaign";
};
export type UseCase_campaign$key = {
  readonly " $data"?: UseCase_campaign$data;
  readonly " $fragmentSpreads": FragmentRefs<"UseCase_campaign">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UseCase_campaign",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postingType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "useCase",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "useCasesUnlocked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "objective",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tiktokShopLinked",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "brief",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "BriefCreativeConnection",
              "kind": "LinkedField",
              "name": "creatives",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BriefCreativeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "BriefCreative",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "V2Brief",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};
})();

(node as any).hash = "009cc0574ab7e94574ef77bf7cdc4b6c";

export default node;
