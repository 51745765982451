/**
 * @generated SignedSource<<e249a2d7f36c8faf017596592c532fe7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CampaignPlatform = "FACEBOOK" | "INSTAGRAM" | "OTHER" | "SNAPCHAT" | "TIKTOK" | "YOUTUBE";
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING";
export type CampaignOverviewQuery$variables = {
  campaignId: string;
};
export type CampaignOverviewQuery$data = {
  readonly campaign: {
    readonly counters: {
      readonly performanceSummary: {
        readonly assetsCreated: number | null | undefined;
        readonly assetsPosted: number | null | undefined;
        readonly avgCpe: number | null | undefined;
        readonly avgCpm: number | null | undefined;
        readonly avgEngagementRate: number | null | undefined;
        readonly totalComments: number | null | undefined;
        readonly totalCost: number | null | undefined;
        readonly totalEngagement: number | null | undefined;
        readonly totalHired: number | null | undefined;
        readonly totalLikes: number | null | undefined;
        readonly totalReach: number | null | undefined;
        readonly totalSaves: number | null | undefined;
        readonly totalShares: number | null | undefined;
        readonly totalViews: number | null | undefined;
      } | null | undefined;
    } | null | undefined;
    readonly id: string;
    readonly platform: CampaignPlatform | null | undefined;
    readonly postedCreatives: {
      readonly hasItems: boolean;
    };
    readonly publishingRequired: boolean;
    readonly type: CampaignType | null | undefined;
  } | null | undefined;
};
export type CampaignOverviewQuery = {
  response: CampaignOverviewQuery$data;
  variables: CampaignOverviewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "campaignId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "campaignId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "platform",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publishingRequired",
  "storageKey": null
},
v6 = {
  "alias": "postedCreatives",
  "args": [
    {
      "kind": "Literal",
      "name": "publishedPosts",
      "value": true
    }
  ],
  "concreteType": "CreativeConnection",
  "kind": "LinkedField",
  "name": "creatives",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasItems",
      "storageKey": null
    }
  ],
  "storageKey": "creatives(publishedPosts:true)"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assetsCreated",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assetsPosted",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avgCpe",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avgCpm",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avgEngagementRate",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalComments",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCost",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalEngagement",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalHired",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalLikes",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalReach",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalSaves",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalShares",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalViews",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CampaignOverviewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CampaignCounters",
            "kind": "LinkedField",
            "name": "counters",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CampaignPerformanceSummary",
                "kind": "LinkedField",
                "name": "performanceSummary",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CampaignOverviewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CampaignCounters",
            "kind": "LinkedField",
            "name": "counters",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CampaignPerformanceSummary",
                "kind": "LinkedField",
                "name": "performanceSummary",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f079b906cc0c7cfbf5dc8a18a083dea7",
    "id": null,
    "metadata": {},
    "name": "CampaignOverviewQuery",
    "operationKind": "query",
    "text": "query CampaignOverviewQuery(\n  $campaignId: ID!\n) {\n  campaign(id: $campaignId) {\n    id\n    platform\n    type\n    publishingRequired\n    postedCreatives: creatives(publishedPosts: true) {\n      hasItems\n    }\n    counters {\n      performanceSummary {\n        assetsCreated\n        assetsPosted\n        avgCpe\n        avgCpm\n        avgEngagementRate\n        totalComments\n        totalCost\n        totalEngagement\n        totalHired\n        totalLikes\n        totalReach\n        totalSaves\n        totalShares\n        totalViews\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "462f6fadfb23780dbc34ebbb189d4690";

export default node;
