import React, { Fragment, useState } from 'react';
import classnames from 'classnames';

import styles from './ProrationPreview.pcss';

import { PreviewProrationMutation$data } from 'GraphTypes/PreviewProrationMutation.graphql';
import { createDateV3 } from 'Util/dateCreator';
import { createSum } from 'Util/numberFormatter';
import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import { USD } from 'Constants/general';
import { TaxableStatesOfUnitedStates } from 'Types/enums';
import { getEnumKeys } from 'Util/getEnumKeys';
import { Stripe_CardBrand } from 'GraphTypes/ConfirmNewPlanQuery.graphql';

interface Props {
  methodLast?: string | null;
  methodBrand?: Stripe_CardBrand | null;
  prorationData: PreviewProrationMutation$data;
  onMethodEditClick: () => void;
}

const ProrationPreview: React.FC<Props> = (props) => {
  const { prorationData, methodLast, methodBrand, onMethodEditClick } = props;
  const prorationPreview = prorationData.stripe?.previewProration?.prorationPreview;

  const [showDetails, setShowDetails] = useState<boolean>(false);

  if (!prorationPreview) return null;

  const amountDue = prorationPreview?.amountDue;
  const nextInvoiceAt = prorationPreview?.nextInvoiceAt;
  const nextInvoiceAmount = prorationPreview?.nextInvoiceAmount;
  const lines = prorationPreview.lines;
  const total = prorationPreview?.total;
  const appliedBalanceCredit = prorationPreview?.appliedBalanceCredit;
  const planId = prorationPreview?.planId;
  const interval = prorationPreview?.interval;

  const nextInvoiceAmountFormatted =
    typeof nextInvoiceAmount === 'number'
      ? createSum(nextInvoiceAmount / 100, USD, 1, '0,0.00')
      : 0; // We have price in cents that's why we need to do this "price / 100"
  const amountDueFormatted =
    typeof amountDue === 'number' ? createSum(amountDue / 100, USD, 1, '0,0.00') : 0;
  const totalFormatted = typeof total === 'number' ? createSum(total / 100, USD, 1, '0,0.00') : 0;
  const balanceFormatted =
    typeof appliedBalanceCredit === 'number'
      ? createSum(appliedBalanceCredit / 100, USD, 1, '0,0.00')
      : 0;

  const subtotalFormatted =
    typeof prorationPreview?.subtotal === 'number'
      ? createSum(prorationPreview.subtotal / 100, USD, 1, '0,0.00')
      : 0;

  const handleToggleDetails = () => {
    setShowDetails(!showDetails);
  };

  return (
    <>
      <div className={styles.paymentInfo}>
        <div>
          {planId && (
            <div className={classnames(styles.planName)}>
              <Text type="h2" msg={`billing_plans.plan.${planId.toLowerCase()}.title`} />
              <Text type="h2" msg="confirm_new_plan_modal.payment.plan" />
            </div>
          )}
          {interval && nextInvoiceAt && (
            <Text
              type="label"
              msg={`confirm_new_plan_modal.payment.descr.interval.${interval.toLowerCase()}`}
              formatValues={{ startingDate: createDateV3(nextInvoiceAt) }}
              className={styles.option}
            />
          )}
        </div>
        <div className={styles.item}>
          <Text type="md" text={nextInvoiceAmountFormatted} className={styles.price} />
        </div>
      </div>
      <div className={styles.item}>
        <div>
          <Text
            type="h2"
            msg="confirm_new_plan_modal.payment.amount_due_today"
            className={styles.option}
          />
          <div className={styles.showDetailsBtn} onClick={handleToggleDetails}>
            <Text
              type="sm"
              msg={`confirm_new_plan_modal.btn.${showDetails ? 'hide' : 'view'}_details`}
            />
            <Icon size={16} name={showDetails ? 'Arrow-small-up' : 'Arrow-small-down'} />
          </div>
        </div>
        <div className={styles.amountWrap}>
          <Text type="h2" text={amountDueFormatted} className={styles.price} />
          {methodLast && (
            <div className={styles.method}>
              <Text type="sm" text={`${methodBrand} •••• ${methodLast}`} />
              <div onClick={onMethodEditClick}>
                <Icon size={16} name="Edit" className={styles.edit} />
              </div>
            </div>
          )}
        </div>
      </div>
      {showDetails && (
        <div className={styles.paymentDetailedInfo}>
          <div className={styles.proration}>
            {lines.map((line) => {
              const keys = getEnumKeys(TaxableStatesOfUnitedStates);
              const states = new Set<keyof typeof TaxableStatesOfUnitedStates | string | null>(
                keys
              );
              const isTaxableUnitedState = states.has(line.taxState || '');
              return (
                <Fragment key={line.description}>
                  <div className={styles.item} key={line.description}>
                    <div className={styles.option}>
                      {line.description && <Text type="md" text={line.description} />}
                    </div>

                    <Text
                      type="md"
                      text={
                        typeof line.amountExcludingTax === 'number'
                          ? createSum(line.amountExcludingTax / 100, USD, 1, '0,0.00')
                          : 0
                      }
                      className={styles.price}
                    />
                  </div>
                  {isTaxableUnitedState && (
                    <>
                      <div className={styles.item}>
                        <Text
                          msg="confirm_new_plan_modal.payment.subtotal"
                          type="md"
                          className={styles.option}
                        />
                        <Text text={subtotalFormatted} type="md" className={styles.price} />
                      </div>
                      <div className={styles.item}>
                        <Text
                          type="md"
                          msg="confirm_new_plan_modal.payment.prorated_charge_tax"
                          className={styles.option}
                          formatValues={{
                            state: line.taxState || '',
                            percentage: line.taxPercentage || '',
                          }}
                        />
                        <Text
                          type="md"
                          text={
                            typeof line?.taxAmount === 'number'
                              ? createSum(line.taxAmount / 100, USD, 1, '0,0.00')
                              : 0
                          }
                          className={styles.price}
                        />
                      </div>
                    </>
                  )}
                </Fragment>
              );
            })}
            <div className={styles.item}>
              <Text
                msg="confirm_new_plan_modal.payment.total"
                type="h2"
                className={styles.option}
              />
              <Text type="h2" text={totalFormatted} className={styles.price} />
            </div>
            <hr className={styles.item} />
            <div className={styles.item}>
              <Text
                type="md"
                msg={`confirm_new_plan_modal.payment.balance_${
                  appliedBalanceCredit < 0 ? 'negative' : 'positive'
                }`}
                className={styles.option}
              />
              <Text type="md" text={balanceFormatted} className={styles.price} />
            </div>
            <div className={styles.item}>
              <Text
                msg="confirm_new_plan_modal.payment.amount_due_today"
                type="h2"
                className={styles.option}
              />
              <Text text={amountDueFormatted} type="h2" className={styles.price} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProrationPreview;
