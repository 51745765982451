import React, { useState, useCallback } from 'react';
import { graphql, useFragment } from 'react-relay';

import { amplitude } from 'Helpers/amplitude';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Drawer from 'Components/ui/Drawer/Drawer';
import CampaignActionsModal from 'CommonPage/Dashboard/AdvertiserDashboard/Campaigns/CampaignsList/Campaign/CampaignPreviewActions/CampaignActionsModal/CampaignActionsModal';
import DuplicateCampaign from 'Modal/advertiser/DuplicateCampaign/DuplicateCampaign';
import { AdditionalActions_campaign$key } from 'GraphTypes/AdditionalActions_campaign.graphql';

interface Props {
  isAdmin: boolean;
  isAdminView?: boolean;
  canBrowseCreators: boolean;
  campaignId: string;
  campaign: AdditionalActions_campaign$key;
  onClientViewChange?: (value: boolean) => void;
}

const AdditionalActions: React.FC<Props> = (props) => {
  const { isAdmin, isAdminView, campaign, campaignId, canBrowseCreators, onClientViewChange } =
    props;

  const data = useFragment(
    graphql`
      fragment AdditionalActions_campaign on Campaign {
        platform
        type
        postingType
        id
        name
        stage
        canDuplicate
        brief {
          ... on V2Brief {
            productDelivery
          }
        }
        counters {
          launchedProjects
          completedProjects
        }
        activation {
          id
          paused
        }
        deletable
        canBeCompleted
        briefSubmission {
          acceptance {
            id
          }
        }
        completion {
          id
          __typename
        }
        completionRequest {
          __typename
        }
        organization {
          id
        }
        userSubscribed
      }
    `,
    campaign
  );

  const [isMenuOpened, setMenuOpened] = useState(false);

  const canDownloadShipment =
    data?.brief?.productDelivery === 'BRAND_WILL_HANDLE_SHIPMENT_TO_CREATORS';

  const closeCampaignMenu = useCallback(() => {
    setMenuOpened(false);
  }, []);

  const openCampaignMenu = () => {
    amplitude.sendEvent<528>({
      id: '528',
      category: 'campaign_page',
      name: 'menu_more_actions_click',
      param: { campaign_id: campaignId },
    });
    setMenuOpened(true);
  };

  const handleMenuClose = () => {
    setMenuOpened(false);
  };

  return (
    <>
      <AlterButton
        type="white"
        bordered={true}
        icon="Dots-menu"
        onClick={openCampaignMenu}
        data-test="additionalActions:alterButton:unknown"
      />
      <Drawer
        rootKey={'campaign-menu'}
        opened={isMenuOpened}
        onClose={handleMenuClose}
        needCloseButton={false}
      >
        <CampaignActionsModal
          place="campaign"
          campaign={data}
          handleClose={closeCampaignMenu}
          isAdmin={!!isAdmin}
          isAdminView={isAdminView}
          showBrief={false}
          canBrowseCreators={canBrowseCreators}
          canDownloadShipment={canDownloadShipment}
          onClientViewChange={onClientViewChange}
        />
      </Drawer>
      <DuplicateCampaign campaignId={data.id} />
    </>
  );
};

export default AdditionalActions;
