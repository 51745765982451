/**
 * @generated SignedSource<<32f0eed0d659ee7fca2f43fbfb8c5108>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FileType = "IMAGE" | "OTHER" | "VIDEO";
export type CreateReferenceInput = {
  briefCreativeId: string;
  clientMutationId?: string | null | undefined;
  fileId: string;
  id?: string | null | undefined;
};
export type CreateReferenceMutation$variables = {
  input: CreateReferenceInput;
};
export type CreateReferenceMutation$data = {
  readonly createReference: {
    readonly newReferenceEdge: {
      readonly node: {
        readonly file: {
          readonly thumbnailUrl: string;
          readonly type: FileType;
          readonly url: string | null | undefined;
        } | null | undefined;
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"Reference_item">;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type CreateReferenceMutation = {
  response: CreateReferenceMutation$data;
  variables: CreateReferenceMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnailUrl",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "collageThumbnailUrl",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateReferenceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateReferencePayload",
        "kind": "LinkedField",
        "name": "createReference",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ReferenceEdge",
            "kind": "LinkedField",
            "name": "newReferenceEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Reference",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "Reference_item"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "file",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateReferenceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateReferencePayload",
        "kind": "LinkedField",
        "name": "createReference",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ReferenceEdge",
            "kind": "LinkedField",
            "name": "newReferenceEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Reference",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "file",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "secureUrl",
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "originalFilename",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ImageTransformation",
                            "kind": "LinkedField",
                            "name": "transformations",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "Image",
                        "abstractKey": null
                      },
                      (v5/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "processingStatus",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "VideoTransformation",
                            "kind": "LinkedField",
                            "name": "transformations",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "autoUrl",
                                "storageKey": null
                              },
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "Video",
                        "abstractKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ec024ee1dc308d5fe2bcc04b62291a81",
    "id": null,
    "metadata": {},
    "name": "CreateReferenceMutation",
    "operationKind": "mutation",
    "text": "mutation CreateReferenceMutation(\n  $input: CreateReferenceInput!\n) {\n  createReference(input: $input) {\n    newReferenceEdge {\n      node {\n        id\n        ...Reference_item\n        file {\n          __typename\n          url\n          thumbnailUrl\n          type\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment Reference_item on Reference {\n  id\n  file {\n    __typename\n    url\n    secureUrl\n    thumbnailUrl\n    originalFilename\n    ... on Image {\n      transformations {\n        collageThumbnailUrl\n      }\n    }\n    type\n    ... on Video {\n      processingStatus\n      transformations {\n        autoUrl\n        collageThumbnailUrl\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "9bb5213619e7d8f0fbd0d1a27f896984";

export default node;
