import React, { ChangeEvent, FC, FormEvent, useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { fetchQuery } from 'relay-runtime';
import { setCookie } from 'typescript-cookie';

import styles from './FirebaseAuth.pcss';
import { AuthFormType, FormErrorType } from './FirebaseAuth';

import {
  DASHBOARD_ROUTE,
  LANDING_LINK,
  SIGNUP_COMPLETE_ADVERTISER_ROUTE,
  INSENSE_PRIVACY_POLICY,
} from 'Constants/general';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import Logo from 'Atoms/Logo/Logo';
import { CurrentUserQuery } from 'GraphTypes/CurrentUserQuery.graphql';
import environment from 'Api/Environment';
import currentUserQuery from 'Containers/Auth0/Auth0Context/CurrentUser.Query';
import Input from 'Components/ui/Input/Input';
import Button from 'Components/ui/Button/Button';
import ButtonPreset from 'Components/ui/ButtonPreset/ButtonPreset';
import Text from 'Components/ui/Text/Text';
import TextButton from 'Components/ui/TextButton/TextButton';
import { emailValidate, passwordValidate } from 'Util/validate';
import { firebaseClient } from 'Containers/Auth0/Auth0Context/utils';
import ErrorHandler from 'Util/errorHandler';

type Props = {
  changeFormType: (formType: AuthFormType) => void;
};

export const SignInForm: FC<Props> = (props) => {
  const { changeFormType } = props;
  const { state, search } = useLocation() as {
    state: {
      isInvite?: string;
      sp?: string;
    };
    search: string;
  };
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState<FormErrorType>({
    email: undefined,
    password: undefined,
  });
  const [loading, setLoadingStatus] = useState(false);

  const domain = window.location.origin.includes('localhost')
    ? 'localhost'
    : process.env.API_HOST.includes('.pro')
    ? '.insense.pro'
    : '.insense.tech';

  const handleAuth = async () => {
    if (loading) return;
    const emailErrors = emailValidate({}, email);
    const passwordErrors = passwordValidate({}, password, false);
    if (emailErrors.email) {
      setError(emailErrors);
      return;
    }
    if (passwordErrors.password) {
      setError(passwordErrors);
      return;
    }
    setLoadingStatus(true);
    try {
      const authResult = await signInWithEmailAndPassword(
        firebaseClient.authClient,
        email,
        password
      );
      const token = await authResult.user.getIdToken();
      setCookie('access-token', token, {
        domain,
        secure: true,
        sameSite: 'strict',
      });
      fetchQuery<CurrentUserQuery>(
        environment,
        currentUserQuery,
        {},
        { fetchPolicy: 'network-only' }
      ).subscribe({
        next: () => {
          let redirectUrl = DASHBOARD_ROUTE;
          const spRedirectUrl = searchParams.get('redirect_url');
          if (state?.isInvite) {
            redirectUrl = `${SIGNUP_COMPLETE_ADVERTISER_ROUTE}${state?.sp}`;
          } else if (spRedirectUrl && spRedirectUrl !== '/') {
            redirectUrl = spRedirectUrl;
          }
          try {
            const redirectUrlInstance = new URL(decodeURIComponent(redirectUrl));
            if (
              /https:\/\/(.*.|)admin.insense.(pro|tech)/.test(redirectUrlInstance.origin) ||
              redirectUrlInstance.hostname === 'localhost'
            ) {
              window.location.href = redirectUrl;
            } else {
              ErrorHandler.warn('User want to go into nothing after authorization', {
                url: window.location.href,
                redirectUrl: redirectUrl,
              });
            }
          } catch {
            navigate(redirectUrl);
          }
        },
        error: () => {
          setError({ email: 'general.error' });
        },
      });
    } catch (e) {
      if (e.code === 'auth/user-not-found' || e.code === 'auth/wrong-password') {
        setError({ password: 'form.error_login_failed' });
      } else {
        if (e.code === 'auth/invalid-email') {
          setError({ email: 'form.error_email_invalid' });
        } else {
          setError({ email: 'general.error' });
        }
      }
    } finally {
      setLoadingStatus(false);
    }
  };
  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleSignUpClick = () => {
    changeFormType(AuthFormType.SIGNUP);
  };
  const handleResetClick = () => {
    changeFormType(AuthFormType.FORGOT_PASSWORD);
  };

  const handlePolicyClick = () => {
    window.open(INSENSE_PRIVACY_POLICY);
  };

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleAuth();
  };

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <header className={styles.header}>
          <div className={styles.logo}>
            <SmartLink path={LANDING_LINK}>
              <Logo />
            </SmartLink>
          </div>
        </header>
        <Text type="h1" msg={'signin_page.title_min'} />
        <form className={styles.form} onSubmit={handleFormSubmit}>
          <Input
            bordered
            type="email"
            placeholderMsg="form.email"
            id="email"
            autoComplete="email"
            required
            className={styles.input}
            onInput={handleEmailChange}
            error={!!error?.email}
          />
          {!!error?.email && <Text type="s" msg={error.email} className={styles.errorText} />}
          <Input
            bordered
            placeholderMsg="form.password"
            type="password"
            id="current-password"
            autoComplete="current-password"
            required
            className={styles.input}
            onInput={handlePasswordChange}
            error={!!error?.password}
            maxLength={32}
          />
          {!!error?.password && <Text type="s" msg={error.password} className={styles.errorText} />}
          <ButtonPreset>
            <Button
              fluid
              color="black"
              msg={'auth.signin'}
              className={styles.submitButton}
              onClick={handleAuth}
              loading={loading}
            />
          </ButtonPreset>
          <div className={styles.additionalButtonContainer}>
            <TextButton
              onClick={handleSignUpClick}
              classes={{
                text: styles.textByCenter,
              }}
              msg={'auth.dont_have_account'}
              dataTest={'textButton:changeForm'}
            />
            <TextButton
              onClick={handleResetClick}
              classes={{
                text: styles.textByCenter,
              }}
              msg={'signin.forgot_password'}
            />
          </div>
          <TextButton
            classes={{
              text: styles.textByCenter,
            }}
            msg={'auth.privacy_policy'}
            className={styles.privacy}
            onClick={handlePolicyClick}
          />
        </form>
      </div>
    </div>
  );
};
