/**
 * @generated SignedSource<<536c35dc30cf9b727c1949dc2f96a88d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Currency = "CAD" | "RUB" | "USD";
export type Payment_PaymentMethodType = "CARD" | "CARDPAY" | "GAMEMONEY" | "PAYPAL" | "QIWI" | "WIRE" | "ZELLE";
export type PlannedPaymentType = "ENTREPRENEUR" | "OTHER_CARD" | "RUB_CARD";
export type UpdateProjectInput = {
  adminComment?: string | null | undefined;
  clientMutationId?: string | null | undefined;
  comment?: string | null | undefined;
  creativeLinks?: string | null | undefined;
  creatorDeclinedToParticipate?: boolean | null | undefined;
  id: string;
  payoutEmail?: string | null | undefined;
  payoutPaymentMethodId?: string | null | undefined;
  payoutPrice?: number | null | undefined;
  plannedCompletionDate?: any | null | undefined;
  plannedPaymentDate?: any | null | undefined;
  plannedPaymentType?: PlannedPaymentType | null | undefined;
  published?: boolean | null | undefined;
  showcasingPermitted?: boolean | null | undefined;
};
export type UpdateProjectMutation$variables = {
  input: UpdateProjectInput;
};
export type UpdateProjectMutation$data = {
  readonly updateProject: {
    readonly project: {
      readonly adminComment: string | null | undefined;
      readonly campaign: {
        readonly projectsSummary: {
          readonly summary: {
            readonly currency: Currency | null | undefined;
          } | null | undefined;
          readonly totalCount: number;
        };
      };
      readonly comment: string | null | undefined;
      readonly creativeLinks: string | null | undefined;
      readonly creatorDeclinedToParticipate: boolean;
      readonly id: string;
      readonly payoutPaymentMethod: {
        readonly accountNumber?: string | null | undefined;
        readonly cardNumber?: string | null | undefined;
        readonly email?: string | null | undefined;
        readonly id: string;
        readonly type: Payment_PaymentMethodType | null | undefined;
      } | null | undefined;
      readonly plannedCompletionDate: any | null | undefined;
      readonly plannedPaymentType: PlannedPaymentType | null | undefined;
    };
  } | null | undefined;
};
export type UpdateProjectMutation = {
  response: UpdateProjectMutation$data;
  variables: UpdateProjectMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "comment",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "adminComment",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creatorDeclinedToParticipate",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "plannedCompletionDate",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "plannedPaymentType",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creativeLinks",
  "storageKey": null
},
v9 = {
  "alias": "projectsSummary",
  "args": [
    {
      "kind": "Literal",
      "name": "archivedByAdvertiser",
      "value": false
    },
    {
      "kind": "Literal",
      "name": "orderBy",
      "value": "LAST_ADDED"
    }
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectConnectionSummary",
      "kind": "LinkedField",
      "name": "summary",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "projects(archivedByAdvertiser:false,orderBy:\"LAST_ADDED\")"
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v11 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "cardNumber",
    "storageKey": null
  }
],
v12 = {
  "kind": "InlineFragment",
  "selections": (v11/*: any*/),
  "type": "Payment_Card",
  "abstractKey": null
},
v13 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  }
],
v14 = {
  "kind": "InlineFragment",
  "selections": (v13/*: any*/),
  "type": "Payment_Paypal",
  "abstractKey": null
},
v15 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountNumber",
      "storageKey": null
    }
  ],
  "type": "Payment_Wire",
  "abstractKey": null
},
v16 = {
  "kind": "InlineFragment",
  "selections": (v11/*: any*/),
  "type": "Payment_Gamemoney",
  "abstractKey": null
},
v17 = {
  "kind": "InlineFragment",
  "selections": (v11/*: any*/),
  "type": "Payment_Cardpay",
  "abstractKey": null
},
v18 = {
  "kind": "InlineFragment",
  "selections": (v13/*: any*/),
  "type": "Payment_Zelle",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateProjectMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateProjectPayload",
        "kind": "LinkedField",
        "name": "updateProject",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Campaign",
                "kind": "LinkedField",
                "name": "campaign",
                "plural": false,
                "selections": [
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "payoutPaymentMethod",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v10/*: any*/),
                  (v12/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateProjectMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateProjectPayload",
        "kind": "LinkedField",
        "name": "updateProject",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Campaign",
                "kind": "LinkedField",
                "name": "campaign",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "payoutPaymentMethod",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  (v10/*: any*/),
                  (v12/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b3938eadb3262280606c54dc66515ed1",
    "id": null,
    "metadata": {},
    "name": "UpdateProjectMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateProjectMutation(\n  $input: UpdateProjectInput!\n) {\n  updateProject(input: $input) {\n    project {\n      id\n      comment\n      adminComment\n      creatorDeclinedToParticipate\n      plannedCompletionDate\n      plannedPaymentType\n      creativeLinks\n      campaign {\n        projectsSummary: projects(archivedByAdvertiser: false, orderBy: LAST_ADDED) {\n          totalCount\n          summary {\n            currency\n          }\n        }\n        id\n      }\n      payoutPaymentMethod {\n        __typename\n        id\n        type\n        ... on Payment_Card {\n          cardNumber\n        }\n        ... on Payment_Paypal {\n          email\n        }\n        ... on Payment_Wire {\n          accountNumber\n        }\n        ... on Payment_Gamemoney {\n          cardNumber\n        }\n        ... on Payment_Cardpay {\n          cardNumber\n        }\n        ... on Payment_Zelle {\n          email\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ea1986d4e7b6e57f5fe3fb77b21dc15a";

export default node;
