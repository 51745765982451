/**
 * @generated SignedSource<<ba5c460c974b860a73d6d91d35ca6c43>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CreatorType = "CONTENT" | "INSTAGRAM" | "TIKTOK" | "YOUTUBE";
export type Currency = "CAD" | "RUB" | "USD";
import { FragmentRefs } from "relay-runtime";
export type SearchResult_result$data = {
  readonly creator: {
    readonly channel?: {
      readonly id: string | null | undefined;
      readonly name: string | null | undefined;
      readonly subscribers: number | null | undefined;
      readonly thumbnailUrl: string | null | undefined;
    } | null | undefined;
    readonly id: string;
    readonly ownership: {
      readonly owner: {
        readonly id: string;
      };
    } | null | undefined;
    readonly profile: {
      readonly badges: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      }> | null | undefined;
      readonly countries: ReadonlyArray<{
        readonly name: string;
      }>;
      readonly profilePictureFile: {
        readonly transformations?: {
          readonly collageThumbnailUrl: string;
        } | null | undefined;
        readonly url: string | null | undefined;
      } | null | undefined;
      readonly searchable: boolean;
    } | null | undefined;
    readonly tiktokUser?: {
      readonly averageLikes: number | null | undefined;
      readonly engagementRate: number | null | undefined;
      readonly followedByCount: number | null | undefined;
      readonly name: string;
    } | null | undefined;
    readonly type: CreatorType;
    readonly user?: {
      readonly engagementRate: number | null | undefined;
      readonly estimatedLikes: number | null | undefined;
      readonly followedByCount: number | null | undefined;
    } | null | undefined;
    readonly username: string;
  } | null | undefined;
  readonly currency: Currency | null | undefined;
  readonly estimatedPrice: number | null | undefined;
  readonly project: {
    readonly archivation: {
      readonly id: string;
    } | null | undefined;
    readonly id: string;
    readonly invitedByOrganizationMember: boolean;
  } | null | undefined;
  readonly viewed: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"SearchResultContent_result">;
  readonly " $fragmentType": "SearchResult_result";
};
export type SearchResult_result$key = {
  readonly " $data"?: SearchResult_result$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchResult_result">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "followedByCount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "engagementRate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SearchResult_result",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "estimatedPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Project",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "side",
              "value": "ADVERTISER"
            }
          ],
          "concreteType": "ProjectArchivation",
          "kind": "LinkedField",
          "name": "archivation",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": "archivation(side:\"ADVERTISER\")"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invitedByOrganizationMember",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "creator",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CreatorOwnership",
          "kind": "LinkedField",
          "name": "ownership",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "owner",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "InstagramUser",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "estimatedLikes",
                  "storageKey": null
                },
                (v2/*: any*/),
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "type": "InstagramCreator",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "YoutubeChannel",
              "kind": "LinkedField",
              "name": "channel",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "subscribers",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "thumbnailUrl",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "YoutubeCreator",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": "tiktokUser",
              "args": null,
              "concreteType": "TiktokUser",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                (v4/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "averageLikes",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "TiktokCreator",
          "abstractKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CreatorProfile",
          "kind": "LinkedField",
          "name": "profile",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Badge",
              "kind": "LinkedField",
              "name": "badges",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v4/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "profilePictureFile",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ImageTransformation",
                      "kind": "LinkedField",
                      "name": "transformations",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "collageThumbnailUrl",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "Image",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "searchable",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Country",
              "kind": "LinkedField",
              "name": "countries",
              "plural": true,
              "selections": [
                (v4/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SearchResultContent_result"
    }
  ],
  "type": "CreatorSearchResult",
  "abstractKey": null
};
})();

(node as any).hash = "3762de3da194ab2c906978e5be58808f";

export default node;
