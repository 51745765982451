import React, { useContext } from 'react';
import { useTracking } from 'react-tracking';

import PortfolioItem from '../PortfolioItem/PortfolioItem';

import styles from './PortfolioGroup.pcss';

import { MEDIA } from 'Constants/modals';
import { MediaType } from 'Types/modals';
import { PortfolioItem_portfolioItem$data } from 'GraphTypes/PortfolioItem_portfolioItem.graphql';
import Text from 'Components/ui/Text/Text';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';

type Props = {
  group: {
    items: PortfolioItem_portfolioItem$data[];
    type: {
      name: string | null;
    };
  };
};

const PortfolioGroup = (props: Props) => {
  const { group } = props;

  const tracking = useTracking();
  const { dispatch: modalDispatch } = useContext(ModalContext);

  if (!group.items?.length) return null;

  const handleClickMedia = (attach: MediaType) => {
    tracking.trackEvent({
      element: 'portfolio_item_preview',
      event: 'clicked',
    });
    modalDispatch({
      type: ModalTypes.SET_MODAL,
      payload: { name: MEDIA, attach },
    });
  };
  return (
    <div className={styles.root}>
      {group.type?.name ? (
        <div className={styles.titleContainer}>
          <Text
            type="label"
            className={styles.groupLabel}
            text={group.type.name}
            data-test="portfolioGroup:text:groupLabel"
          />
        </div>
      ) : null}
      <div className={styles.portfolioContainer}>
        {group.items.map((item) => (
          <PortfolioItem key={item.id} onClick={handleClickMedia} portfolioItem={item} />
        ))}
      </div>
    </div>
  );
};

export default PortfolioGroup;
