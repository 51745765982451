import React, { useEffect, useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import compact from 'lodash/compact';

import styles from './PaymentServiceProviders.css';

import DropDown from 'Molecules/DropDown/DropDown';
import Text from 'Atoms/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import {
  PaymentServiceProviders_psp$key,
  Payment_PaymentServiceProvider,
} from 'GraphTypes/PaymentServiceProviders_psp.graphql';

const UNSENT = 'UNSENT';
const CARD = 'CARD';

export const paymentServiceProvidersList: { msg: string; id: Payment_PaymentServiceProvider }[] = [
  { msg: 'payuot_method.unlimint', id: 'UNLIMINT' },
  { msg: 'payuot_method.qiwi', id: 'QIWI' },
];

interface Props {
  currentTransactionProvider: any;
  currentTransactionId: string | undefined;
  setCurrentTransactionId: (value: string) => void;
  currentTransactionProcess: boolean;
  psp?: PaymentServiceProviders_psp$key;
}

const PaymentServiceProviders: React.FC<Props> = (props) => {
  const {
    psp,
    currentTransactionProcess,
    setCurrentTransactionId,
    currentTransactionProvider,
    currentTransactionId,
  } = props;

  const data = useFragment(
    graphql`
      fragment PaymentServiceProviders_psp on Payment_Withdrawal {
        id
        paymentMethod {
          type
        }
        status
        paymentServiceProvider
      }
    `,
    psp
  );
  const transactionId = data?.id;
  const defaultProvider = data?.paymentServiceProvider;
  const transactionStatus = data?.status;
  const paymentMethod = data?.paymentMethod;

  const [finished, setFinished] = useState<boolean>(false);
  const [currentProvider, setCurrentProvider] = useState<Payment_PaymentServiceProvider>(
    paymentServiceProvidersList[0].id
  );

  useEffect(() => {
    if (currentTransactionProcess && transactionId === currentTransactionId) setFinished(true);
  }, [currentTransactionProcess]);

  useEffect(() => {
    if (transactionId === currentTransactionId) {
      currentTransactionProvider.current = currentProvider;
    }
  }, [currentTransactionId]);

  useEffect(() => {
    if (transactionId === currentTransactionId) {
      currentTransactionProvider.current = currentProvider;
    }

    return () => (transactionId ? setCurrentTransactionId(transactionId) : undefined);
  }, [currentProvider]);

  if (paymentMethod?.type !== CARD) {
    return (
      <Text
        msg={`payuot_method.${paymentMethod?.type?.toLowerCase()}`}
        data-test="paymentServiceProviders:text:unknown"
      />
    );
  }
  if (finished || transactionStatus !== UNSENT) {
    return (
      <Text
        msg={`payuot_method.${(defaultProvider || currentProvider).toLowerCase()}`}
        data-test="paymentServiceProviders:text:unknown"
      />
    );
  }

  const handleDropdownItemClick = (provider: Payment_PaymentServiceProvider) =>
    setCurrentProvider(provider);

  const checkIcon = <Icon name="Check" />;

  const anchor = (
    <div className={styles.defaultDropdownItem}>
      <Text
        msg={`payuot_method.${currentProvider.toLowerCase()}`}
        data-test="paymentServiceProviders:text:unknown"
      />
      <Icon name="Arrow-small-down" />
    </div>
  );

  const list = compact(
    paymentServiceProvidersList?.map((item, i) => {
      if (!item) return null;

      return {
        id: item.id,
        textData: {
          textData: { msg: item.msg },
          rightIcon: currentProvider === item.id ? checkIcon : undefined,
        },
        onClick: () => handleDropdownItemClick(item.id),
      };
    })
  );

  return <DropDown anchor={anchor} list={list} position="bottomLeft" selfClose={true} />;
};

export default PaymentServiceProviders;
