import React, { useCallback } from 'react';
import xor from 'lodash/xor';
import debounce from 'lodash/debounce';

import styles from './AdminCreativesFilters.pcss';
import { getFiltersData, getPlatformList, getCampaignUsecases, getFileType } from './utils';

import ProgressiveInput from 'Components/ProgressiveInput/ProgressiveInput';
import TextButton from 'Components/ui/TextButton/TextButton';
import {
  Currency,
  CampaignPlatform,
  CampaignUseCase,
  FileType,
} from 'GraphTypes/AdminCreativeLibraryQuery.graphql';
import { AdminCreativeLibraryContainerQuery$data } from 'GraphTypes/AdminCreativeLibraryContainerQuery.graphql';

export type Filters = {
  adminFavoriteCreatives: boolean | null;
  adminFavoriteCampaigns: boolean | null;
  paidSocial: boolean | null;
  currencies: Currency[];
  brandCategoryIds: string[];
  textQuery: string;
  platform?: CampaignPlatform | null;
  campaignUseCases: CampaignUseCase[];
  fileType?: FileType | null;
};

interface Props {
  brandCategories: AdminCreativeLibraryContainerQuery$data['brandCategories'];
  filters: Filters;
  onFiltersChange: (filters: Filters) => void;
  onFiltersClear: () => void;
}

const AdminCreativesFilters: React.FC<Props> = (props) => {
  const { brandCategories, filters, onFiltersChange, onFiltersClear } = props;

  const filtersData = getFiltersData(filters);

  const handleSearchInputChange = useCallback(
    debounce((value) => {
      onFiltersChange({ ...filters, textQuery: value });
    }, 500),
    [filters]
  );

  const handleCurrencyChange = useCallback(
    (id: string) => {
      const newCurrenciesValue = xor(filters.currencies || [], [id]);
      onFiltersChange({ ...filters, currencies: newCurrenciesValue });
    },
    [filters]
  );

  const handleParamChange = useCallback(
    (id: string) => {
      const name = id as keyof Filters;
      const value = filters[name] ? null : true;
      onFiltersChange({ ...filters, [name]: value });
    },
    [filters]
  );

  const handleCurrenciesClear = useCallback(() => {
    onFiltersChange({ ...filters, currencies: [] });
  }, [filters]);

  const handleAdditionalFiltersClear = useCallback(() => {
    onFiltersChange({
      ...filters,
      adminFavoriteCampaigns: null,
      adminFavoriteCreatives: null,
      paidSocial: null,
    });
  }, [filters]);

  const handleCategoriesChange = (categoryValue: string) => {
    const newCategories = xor(filters.brandCategoryIds || [], [categoryValue]);
    onFiltersChange({ ...filters, brandCategoryIds: newCategories });
  };

  const handleUsecasesChange = (usecaseValue: string) => {
    const newUsecase = xor(filters.campaignUseCases || [], [usecaseValue as CampaignUseCase]);
    onFiltersChange({ ...filters, campaignUseCases: newUsecase });
  };

  const handleUsecasesClear = () => {
    onFiltersChange({ ...filters, campaignUseCases: [] });
  };

  const handlePlatformsChange = (platformValue: string) => {
    onFiltersChange({ ...filters, platform: platformValue as CampaignPlatform });
  };

  const handleFileTypesChange = (fileTypeValue: string) => {
    onFiltersChange({ ...filters, fileType: fileTypeValue as FileType });
  };

  const handleFileTypesClear = () => {
    onFiltersChange({ ...filters, fileType: undefined });
  };

  const handleSearchInputClear = () => {
    onFiltersChange({ ...filters, textQuery: '' });
  };

  const handleCategoriesClear = useCallback(() => {
    onFiltersChange({ ...filters, brandCategoryIds: [] });
  }, [filters]);

  const handlePlatformsClear = useCallback(() => {
    onFiltersChange({ ...filters, platform: undefined });
  }, [filters]);

  const hasCurrencies = filters.currencies.length > 0;
  const hasSelectedCategories = filters.brandCategoryIds.length > 0;
  const hasSelectedUsecases = filters.campaignUseCases?.length > 0;

  const hasAdditionalFilter = !!(
    filters.adminFavoriteCampaigns ||
    filters.adminFavoriteCreatives ||
    filters.paidSocial
  );

  const brandCategoriesList = brandCategories?.map((item) => {
    return {
      id: item.id,
      isChecked: filters.brandCategoryIds.includes(item.id),
      value: item.id,
      label: item.name,
    };
  });

  const platformList = getPlatformList(filters.platform);
  const campaignUseCasesList = getCampaignUsecases(filters.campaignUseCases);
  const fileTypes = getFileType(filters.fileType);

  return (
    <div className={styles.root}>
      <TextButton
        color="grey"
        msg="general.clear_all"
        onClick={onFiltersClear}
        className={styles.clearParams}
        data-test="adminCreativesFilters:textButton:clearAll"
      />
      <ProgressiveInput
        type="input"
        inputProps={{
          value: filters.textQuery,
          placeholderMsg: 'advertisers.creative_library.search_placeholder',
          handleChange: handleSearchInputChange,
          rightIcon: 'Search-loop',
          onResetValue: handleSearchInputClear,
        }}
        className={styles.input}
        isDirty={!!filters.textQuery}
      />
      <div className={styles.params}>
        <ProgressiveInput
          type="checkboxSelect"
          inputProps={{
            items: brandCategoriesList,
            bordered: true,
            placeholderMsg: 'search_section.form.brand_categories',
            handleClick: handleCategoriesChange,
            hideByAlphabet: true,
            handleClear: handleCategoriesClear,
          }}
          isDirty={hasSelectedCategories}
        />
        <ProgressiveInput
          type="radio"
          inputProps={{
            items: platformList,
            bordered: true,
            placeholderMsg: 'search_section.form.platform',
            handleClick: handlePlatformsChange,
            hideByAlphabet: true,
            handleClear: handlePlatformsClear,
          }}
          isDirty={!!filters.platform}
        />
        <ProgressiveInput
          type="checkboxSelect"
          inputProps={{
            items: campaignUseCasesList,
            bordered: true,
            placeholderMsg: 'search_section.form.usecases',
            handleClick: handleUsecasesChange,
            hideByAlphabet: true,
            handleClear: handleUsecasesClear,
          }}
          isDirty={hasSelectedUsecases}
        />
        <ProgressiveInput
          type="radio"
          inputProps={{
            items: fileTypes,
            bordered: true,
            placeholderMsg: 'search_section.form.file_type',
            handleClick: handleFileTypesChange,
            hideByAlphabet: true,
            handleClear: handleFileTypesClear,
          }}
          isDirty={!!filters.fileType}
        />
        <ProgressiveInput
          type="checkboxSelect"
          inputProps={{
            items: filtersData.currencies,
            bordered: true,
            placeholderMsg: 'search_section.form.currency',
            handleClick: handleCurrencyChange,
            hideByAlphabet: true,
            handleClear: handleCurrenciesClear,
          }}
          isDirty={hasCurrencies}
        />
        <ProgressiveInput
          type="checkboxSelect"
          inputProps={{
            items: filtersData.additional,
            bordered: true,
            placeholderMsg: 'search_section.form.additional',
            handleClick: handleParamChange,
            hideByAlphabet: true,
            handleClear: handleAdditionalFiltersClear,
            place: 'bottomRight',
          }}
          isDirty={hasAdditionalFilter}
        />
      </div>
    </div>
  );
};

export default AdminCreativesFilters;
