/**
 * @generated SignedSource<<69e73579b3d949e63bc40b857cd5ba47>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AdvertiserProjectStage = "ACCEPTED" | "ACCEPTED_AND_NOT_LAUNCHED" | "ACCEPTED_BY_UNREVIEWED_CREATOR" | "COMPLETED" | "CONTENT_SUBMITTED" | "LAUNCHED" | "PRODUCT_SHIPMENT_COMPLETED" | "PRODUCT_SHIPMENT_STARTED" | "PUBLISHING_STARTED" | "READ" | "REJECTED" | "UNREAD" | "UNSENT";
export type PaidSocialLicenseState = "ACTIVE" | "EXPIRED" | "FAILED_TO_EXPIRE";
import { FragmentRefs } from "relay-runtime";
export type FbAdsAction_project$data = {
  readonly advertiserStage: AdvertiserProjectStage | null | undefined;
  readonly brief: {
    readonly paidSocialBca?: boolean;
  } | null | undefined;
  readonly creator: {
    readonly id: string;
    readonly paidSocialEnabled?: boolean;
  } | null | undefined;
  readonly id: string;
  readonly paidSocial: boolean;
  readonly paidSocialActivation: {
    readonly facebookBusinessName: string;
    readonly facebookBusinessPictureUrl: string | null | undefined;
    readonly facebookPageName: string;
    readonly facebookPagePictureUrl: string | null | undefined;
    readonly id: string;
    readonly license: {
      readonly expiresAt: any | null | undefined;
      readonly id: string;
      readonly state: PaidSocialLicenseState;
    };
  } | null | undefined;
  readonly " $fragmentType": "FbAdsAction_project";
};
export type FbAdsAction_project$key = {
  readonly " $data"?: FbAdsAction_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"FbAdsAction_project">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FbAdsAction_project",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paidSocial",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "advertiserStage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "brief",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paidSocialBca",
              "storageKey": null
            }
          ],
          "type": "V2Brief",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "creator",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paidSocialEnabled",
              "storageKey": null
            }
          ],
          "type": "InstagramCreator",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PaidSocialActivation",
      "kind": "LinkedField",
      "name": "paidSocialActivation",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "facebookBusinessName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "facebookBusinessPictureUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "facebookPageName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "facebookPagePictureUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PaidSocialLicense",
          "kind": "LinkedField",
          "name": "license",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "state",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "expiresAt",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};
})();

(node as any).hash = "ef13742cbe51c05ca4dfe33dbad2fe87";

export default node;
