import React, { useState, useContext } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';

import styles from './SetDraftDeadline.pcss';

import changeDraftDeadline from 'Mutations/ChangeDraftDeadline.Mutation';
import { defineDate } from 'Util/dateCreator';
import Drawer from 'Components/ui/Drawer';
import DatePicker from 'Components/ui/DatePicker/DatePicker';
import { SetDraftDeadlineQuery as QueryType } from 'GraphTypes/SetDraftDeadlineQuery.graphql';
import Text from 'Components/ui/Text/Text';
import Button from 'Components/ui/Button/Button';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { CampaignType } from 'GraphTypes/AcceptOfferMutation.graphql';
import { PRODUCT_SEEDING } from 'Constants/general';

const SetDraftDeadlineQuery = graphql`
  query SetDraftDeadlineQuery($id: ID!) {
    project(id: $id) {
      id
      launch {
        id
      }
      deadline {
        draftDate
      }
      creator {
        username
      }
    }
  }
`;

interface Props {
  projectId: string;
  callback?: () => void;
  campaignType?: CampaignType | null;
}

const SetDraftDeadline: React.FC<Props> = (props) => {
  const { projectId, callback, campaignType } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [draftDate, setDraftDate] = useState<Date | null>(null);

  const { closeDrawer, openDrawer } = useContext(DrawerContext);

  const data = useLazyLoadQuery<QueryType>(SetDraftDeadlineQuery, { id: projectId });

  const handleCloseModal = () => {
    setLoading(false);
    if (callback) {
      callback();
    }
    closeDrawer(`project-draft-deadline`);
    openDrawer(`chat-modal`);
  };

  const handleSetDeadlines = () => {
    setLoading(true);
    const newDate = draftDate ? defineDate(draftDate) : null;
    changeDraftDeadline({ projectId, draftDate: newDate }, handleCloseModal);
  };

  const username = data.project?.creator?.username;

  const handleDateChange = (date: Date | null) => {
    setDraftDate(date);
  };

  const draftDateDefault = data.project?.deadline?.draftDate;

  let description = draftDateDefault
    ? 'set_draft_deadline_modal.change.descr'
    : 'set_draft_deadline_modal.descr';
  if (campaignType === PRODUCT_SEEDING) {
    description = 'set_draft_deadline_modal.seeding_descr';
  }

  return (
    <Drawer rootKey={`project-draft-deadline`} className={styles.drawer}>
      <div className={styles.drawerContainer}>
        <div className={styles.content}>
          <Text
            type="d2"
            msg={
              draftDateDefault
                ? 'set_draft_deadline_modal.change.title'
                : 'set_draft_deadline_modal.title'
            }
            formatValues={{ username }}
          />
          <Text type="md" msg={description} className={styles.descr} />
          <DatePicker
            reactDatePickerProps={{
              inline: true,
              onChange: handleDateChange,
              minDate: new Date(),
            }}
          />
        </div>
        <Button
          color="black"
          msg="set_draft_deadline_modal.btn"
          disabled={!draftDate}
          loading={loading}
          onClick={handleSetDeadlines}
        />
      </div>
    </Drawer>
  );
};

export default SetDraftDeadline;
