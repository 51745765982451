import { createContext } from 'react';

import { CreatorsQuery$variables } from 'GraphTypes/CreatorsQuery.graphql';
import { CampaignProviderQuery$data } from 'GraphTypes/CampaignProviderQuery.graphql';

type CampaignContextType = {
  inviteSuggestionListId?: string;
  inviteSuggestionListCount?: number;
  canInviteSuggestedCreators?: boolean;
  campaignData?: CampaignProviderQuery$data | null;
  newProjectsWithBrandMessage: string[];
  onNewProjectsWithBrandMessage: (id: string) => void;
  createInviteSuggestionList: () => void;
  onInviteSuggestionListCountDecrease: (num?: number) => void;
  onCheckCreatorsExistence: () => void;
  onCreatorsListDataUpdate: (data: CreatorsQuery$variables) => void;
};

const CampaignContext = createContext<CampaignContextType>({} as CampaignContextType);

export { CampaignContext };

export type { CampaignContextType };
