import React, { useContext, useMemo } from 'react';
import classNames from 'classnames';

import { SequenceStep } from '../../InviteOutreachCreatorsContext';

import styles from './SequenceItem.pcss';
import DelaySelector from './DelaySelector/DelaySelector';
import TimeOptionSelector from './TimeOptionSelector/TimeOptionSelector';

import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownGroupItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';

interface Props {
  data: SequenceStep;
  onSelect: (index: number) => void;
  onRemove: (index: number) => void;
  onChangeData: (data: Partial<SequenceStep>) => void;
  isActive: boolean;
  index: number;
  isLastInList: boolean;
  error: boolean;
}

const SequenceItem: React.FC<Props> = (props) => {
  const { data, index, isActive, onSelect, onRemove, isLastInList, error, onChangeData } = props;

  const { openDrawer } = useContext(DrawerContext);
  const isSendNowOptionSelected = data.delayInDays === null || data.delayInDays === undefined;

  const handleSelectClick = () => {
    onSelect(index);
  };

  const handleRemoveClick = () => {
    onSelect(index);
    openDrawer('sequence-delete-item');
  };

  const handleDelayValue = (value: number) => {
    onChangeData({ delayInDays: value });
  };

  const handleTimeValue = (value: number) => {
    const newDate = new Date(data.startsAtHour);
    newDate.setHours(value);
    onChangeData({ startsAtHour: newDate });
  };

  return (
    <>
      <div
        className={classNames(styles.root, { [styles.active]: isActive, [styles.error]: error })}
        onClick={handleSelectClick}
      >
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            <div className={styles.label}>
              <Icon name="Mail" />
            </div>
            <Text
              type="md"
              className={styles.titleText}
              msg={index === 0 ? 'campaign.outreach.steps.first' : 'campaign.outreach.steps.next'}
              formatValues={{ index }}
            />
            {!isLastInList ? (
              <Dropdown
                value={<Icon name="Dots-menu" className={styles.optionsButton} />}
                className={styles.button}
                showArrow={false}
              >
                <DropdownGroup className={styles.menu}>
                  <DropdownGroupItem>
                    <AlterButton
                      text="Delete"
                      className={styles.removeButton}
                      onClick={handleRemoveClick}
                      hover={false}
                    />
                  </DropdownGroupItem>
                </DropdownGroup>
              </Dropdown>
            ) : (
              <div className={styles.deletePlaceholder} />
            )}
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.mailInfo}>
            <DelaySelector
              data={data}
              handleDelayValue={handleDelayValue}
              disableEmptyValue={index > 0}
              isSendNowOptionSelected={isSendNowOptionSelected}
            />
            {!isSendNowOptionSelected && (
              <TimeOptionSelector data={data} handleTimeValue={handleTimeValue} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SequenceItem;
