/**
 * @generated SignedSource<<e7366595bc7bb1890025a0c727704ce1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Currency = "CAD" | "RUB" | "USD";
export type PlanId = "ADVANCED" | "ADVANCED_2021_10" | "ADVANCED_2022_11" | "AGENCY_2024_03" | "BARTER" | "BASIC_OLD_PS" | "BRAND_2024_03" | "CONTENT_CREATION" | "ENTERPRISE" | "FREE" | "FREE_OLD_PS" | "FREE_TRIAL" | "FULLY_MANAGED" | "FULLY_MANAGED_1" | "FULLY_MANAGED_2" | "FULLY_MANAGED_TTCX_MMA" | "GROW" | "PAYOUT" | "PREMIUM" | "PREMIUM_PLUS" | "PUBLICIS" | "SCALE" | "SCALE_2021_06" | "SCALE_2021_10" | "SMARTLY_ENTERPRISE_2023_06" | "SMARTLY_ENTERPRISE_TRIAL_2023_06" | "TRIAL_2022_08" | "TRIAL_2024_03" | "TRIAL_MONTH" | "UGC_2022_08" | "UGC_ADS_2022_08" | "WILDJAM";
export type AdminAdvertisersQuery$variables = {
  currencies?: ReadonlyArray<Currency> | null | undefined;
  paying?: boolean | null | undefined;
  planIds?: ReadonlyArray<PlanId> | null | undefined;
  textQuery?: string | null | undefined;
};
export type AdminAdvertisersQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AdvertisersList_organizations">;
};
export type AdminAdvertisersQuery = {
  response: AdminAdvertisersQuery$data;
  variables: AdminAdvertisersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "currencies"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "paying"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "planIds"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "textQuery"
},
v4 = {
  "kind": "Variable",
  "name": "currencies",
  "variableName": "currencies"
},
v5 = {
  "kind": "Variable",
  "name": "paying",
  "variableName": "paying"
},
v6 = {
  "kind": "Variable",
  "name": "planIds",
  "variableName": "planIds"
},
v7 = {
  "kind": "Variable",
  "name": "textQuery",
  "variableName": "textQuery"
},
v8 = [
  (v4/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  },
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminAdvertisersQuery",
    "selections": [
      {
        "args": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "AdvertisersList_organizations"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AdminAdvertisersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "OrganizationConnection",
        "kind": "LinkedField",
        "name": "organizations",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AdvertiserSubscription",
                    "kind": "LinkedField",
                    "name": "subscription",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "planId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endsAt",
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currency",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Payment_Account",
                    "kind": "LinkedField",
                    "name": "paymentAccount",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "balance",
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "Advertiser_organizations",
        "kind": "LinkedHandle",
        "name": "organizations"
      }
    ]
  },
  "params": {
    "cacheID": "9ce03d8f5173c0f6be116ec330803a51",
    "id": null,
    "metadata": {},
    "name": "AdminAdvertisersQuery",
    "operationKind": "query",
    "text": "query AdminAdvertisersQuery(\n  $textQuery: String\n  $paying: Boolean\n  $planIds: [PlanId!]\n  $currencies: [Currency!]\n) {\n  ...AdvertisersList_organizations_2YMCfp\n}\n\nfragment AdvertiserItem_organization on Organization {\n  id\n  name\n  subscription {\n    planId\n    endsAt\n    id\n  }\n  currency\n  paymentAccount {\n    balance\n    id\n  }\n}\n\nfragment AdvertisersList_organizations_2YMCfp on Query {\n  organizations(first: 20, textQuery: $textQuery, paying: $paying, planIds: $planIds, currencies: $currencies) {\n    edges {\n      node {\n        id\n        ...AdvertiserItem_organization\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1fe55b8b5a0ad3609b098cfc3221a19f";

export default node;
