import { HugeiconsIconProps } from '@hugeicons/react';
import {
  UserCheck02Icon,
  ImageDone01Icon,
  ImageUploadIcon,
  ThumbsUpDownIcon,
  FavouriteIcon,
  Comment01Icon,
  LinkForwardIcon,
  Bookmark02Icon,
  Activity01Icon,
  ViewIcon,
  Analytics03Icon,
  MoneyBag01Icon,
  CoinsDollarIcon,
} from '@hugeicons-pro/core-stroke-rounded';

import { CampaignOverviewQuery$data } from 'GraphTypes/CampaignOverviewQuery.graphql';
import { createPercent, createShortNumber } from 'Util/numberFormatter';

export type OverviewResultItemType = {
  value?: string | number;
  msg?: string;
  tooltipMsg?: string;
  icon: HugeiconsIconProps['icon'];
  tags?: string[];
};

type OverviewResultType = {
  [index: string]: OverviewResultItemType;
};

type OverviewType = {
  campaignData?: CampaignOverviewQuery$data;
};
export const getOverviewData = (data?: OverviewType | null): OverviewResultType => {
  const counters = data?.campaignData?.campaign?.counters?.performanceSummary;
  const platform = data?.campaignData?.campaign?.platform;

  const assetsCreated = counters?.assetsCreated;
  const totalHired = counters?.totalHired;
  const assetsPosted = counters?.assetsPosted;
  const totalEngagement = counters?.totalEngagement;
  const totalLikes = counters?.totalLikes;
  const totalComments = counters?.totalComments;
  const totalShares = counters?.totalShares;
  const totalSaves = counters?.totalSaves;
  const totalReach = counters?.totalReach;
  const totalViews = counters?.totalViews;
  const avgEngagementRate = counters?.avgEngagementRate;
  const totalCost = counters?.totalCost;
  const avgCpe = counters?.avgCpe;
  const avgCpm = counters?.avgCpm;

  const platformPrefix = data?.campaignData?.campaign?.platform === 'TIKTOK' ? '.tt' : '.ig';

  return {
    totalHired: {
      value: totalHired || '—',
      msg: 'campaign.overview.total_hired',
      tooltipMsg: 'campaign.overview.total_hired.tooltip',
      icon: UserCheck02Icon,
    },
    assetsCreated: {
      value: assetsCreated || '—',
      msg: 'campaign.overview.assets_created',
      tooltipMsg: 'campaign.overview.assets_created.tooltip',
      icon: ImageDone01Icon,
    },
    assetsPosted: {
      value: assetsPosted || '—',
      msg: 'campaign.overview.assets_posted',
      tooltipMsg: 'campaign.overview.assets_posted.tooltip',
      icon: ImageUploadIcon,
    },
    totalEngagement: {
      value: totalEngagement ? createShortNumber(totalEngagement) : '—',
      msg: 'campaign.overview.total_engagement',
      tooltipMsg: `campaign.overview.total_engagement${platformPrefix}.tooltip`,
      icon: ThumbsUpDownIcon,
      tags:
        platform === 'INSTAGRAM' || platform === 'FACEBOOK'
          ? [
              'campaign.overview.placement.stories',
              'campaign.overview.placement.feed',
              'campaign.overview.placement.reel',
            ]
          : [],
    },
    totalLikes: {
      value: totalLikes ? createShortNumber(totalLikes) : '—',
      msg: 'campaign.overview.total_likes',
      tooltipMsg: `campaign.overview.total_likes${platformPrefix}.tooltip`,
      icon: FavouriteIcon,
      tags:
        platform === 'INSTAGRAM' || platform === 'FACEBOOK'
          ? ['campaign.overview.placement.feed', 'campaign.overview.placement.reel']
          : [],
    },
    totalComments: {
      value: totalLikes ? createShortNumber(totalComments) : '—',
      msg: 'campaign.overview.total_comments',
      tooltipMsg: `campaign.overview.total_comments${platformPrefix}.tooltip`,
      icon: Comment01Icon,
      tags:
        platform === 'INSTAGRAM' || platform === 'FACEBOOK'
          ? ['campaign.overview.placement.feed', 'campaign.overview.placement.reel']
          : [],
    },
    totalShares: {
      value: totalShares ? createShortNumber(totalShares) : '—',
      msg: 'campaign.overview.total_shares',
      tooltipMsg: `campaign.overview.total_shares${platformPrefix}.tooltip`,
      icon: LinkForwardIcon,
      tags:
        platform === 'INSTAGRAM' || platform === 'FACEBOOK'
          ? ['campaign.overview.placement.feed', 'campaign.overview.placement.reel']
          : [],
    },
    totalSaves: {
      value: totalSaves ? createShortNumber(totalSaves) : '—',
      msg: 'campaign.overview.total_saves',
      tooltipMsg: 'campaign.overview.total_saves.tooltip',
      icon: Bookmark02Icon,
      tags:
        platform === 'INSTAGRAM' || platform === 'FACEBOOK'
          ? [
              'campaign.overview.placement.stories',
              'campaign.overview.placement.feed',
              'campaign.overview.placement.reel',
            ]
          : [],
    },
    totalReach: {
      value: totalReach ? createShortNumber(totalReach) : '—',
      msg: 'campaign.overview.total_reach',
      tooltipMsg: 'campaign.overview.ig.total_reach.tooltip',
      icon: Activity01Icon,
    },
    totalViews: {
      value: totalViews ? createShortNumber(totalViews) : '—',
      msg: 'campaign.overview.total_views',
      tooltipMsg: `campaign.overview.total_views${platformPrefix}.tooltip`,
      icon: ViewIcon,
    },
    avgEngagementRate: {
      value: avgEngagementRate ? createPercent(avgEngagementRate) : '—',
      msg: 'campaign.overview.avg_eg_rate',
      tooltipMsg: `campaign.overview${platformPrefix}.avg_eg_rate.tooltip`,
      icon: Analytics03Icon,
    },
    totalCost: {
      value: totalCost ? `$${totalCost}` : '—',
      msg: 'campaign.overview.total_cost',
      tooltipMsg: 'campaign.overview.total_cost.tooltip',
      icon: MoneyBag01Icon,
    },
    avgCpe: {
      value: avgCpe ? `$${avgCpe}` : '—',
      msg: 'campaign.overview.avg_cpe',
      tooltipMsg: 'campaign.overview.avg_cpe.tooltip',
      icon: CoinsDollarIcon,
    },
    avgCpm: {
      value: avgCpm ? `$${avgCpm}` : '—',
      msg: 'campaign.overview.avg_cpm',
      tooltipMsg: 'campaign.overview.avg_cpm.tooltip',
      icon: CoinsDollarIcon,
    },
  };
};
