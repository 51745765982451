import React, { useContext } from 'react';

import { InviteOutreachCreatorsContext } from '../InviteOutreachCreatorsContext';

import styles from './SequenceSettingsMenu.pcss';

import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownGroupItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Icon from 'Components/ui/Icon/Icon';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

const SequenceSettingsMenu: React.FC = () => {
  const { openDrawer } = useContext(DrawerContext);

  const { setStepsWithErrorList, setCreatingTemplateStatus, checkStepsValidation, stepsWithError } =
    useContext(InviteOutreachCreatorsContext);

  const handleOpenSettings = () => {
    openDrawer('sequence-settings');
  };

  const handleSaveTemplate = () => {
    const invalidSteps = checkStepsValidation();
    if (invalidSteps.length) {
      setStepsWithErrorList(invalidSteps);
      return;
    }
    setCreatingTemplateStatus(true);
    openDrawer('sequence-create');
  };

  return (
    <Dropdown value={<Icon name="Dots-menu" />} className={styles.button} showArrow={false}>
      <DropdownGroup className={styles.menu}>
        <DropdownGroupItem>
          <AlterButton
            msg="invite_outreach_creators_modal.advanced_settings"
            fluid
            iconPosition="right"
            icon="Settings"
            onClick={handleOpenSettings}
          />
        </DropdownGroupItem>
        <DropdownGroupItem>
          <AlterButton
            msg="invite_outreach_creators_modal.editor.save_sequence_template"
            fluid
            iconPosition="right"
            disabled={stepsWithError.length > 0}
            icon="Save-list"
            onClick={handleSaveTemplate}
          />
        </DropdownGroupItem>
      </DropdownGroup>
    </Dropdown>
  );
};

export default SequenceSettingsMenu;
