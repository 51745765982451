import { graphql, useMutation } from 'react-relay';

import { AuthenticateWithGmailMutation } from 'GraphTypes/AuthenticateWithGmailMutation.graphql';
import { BaseMutation, BaseMutationExecFn } from 'Types/utils';

const mutation = graphql`
  mutation AuthenticateWithGmailMutation($input: AuthenticateWithGmailInput!) {
    authenticateWithGmail(input: $input) {
      __typename
      ... on AuthenticateWithGmailPayload {
        gmailAuthorization {
          id
          reauthenticationRequired
          availableEmails
          availableNames
          dailySendingLimit
        }
      }
      ... on NotFoundError {
        message
      }
      ... on ValidationError {
        message
      }
      ... on AuthenticateWithGmail_InvalidScopeError {
        message
      }
    }
  }
`;

export const useAuthenticateWithGmailMutation: BaseMutation<AuthenticateWithGmailMutation> = (
  config
) => {
  const [authenticateWithGmail, loading] = useMutation<AuthenticateWithGmailMutation>(mutation);

  const authenticateWithGmailCaller: BaseMutationExecFn<AuthenticateWithGmailMutation> = (
    execConfig
  ) => {
    authenticateWithGmail({
      ...config,
      ...execConfig,
      updater: (store) => {
        const authId = store
          .getRootField('authenticateWithGmail')
          ?.getLinkedRecord('gmailAuthorization')
          ?.getValue('id');
        if (authId && typeof authId === 'string') {
          const auth = store.get(authId);
          if (auth) {
            auth.setValue(false, 'reauthenticationRequired');
          }
        }
      },
    });
  };
  return [authenticateWithGmailCaller, loading];
};
