import React, { PropsWithChildren } from 'react';

import styles from './PostsSection.css';

import Text from 'Atoms/Text/Text';
import Icon from 'Components/ui/Icon/Icon';

interface Props {
  title: string;
  descr?: string;
  isBlockedView?: boolean;
}

const PostsSection: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, title, descr, isBlockedView } = props;

  return (
    <div className={styles.root}>
      <header className={styles.header}>
        {isBlockedView && <Icon name="Lock" />}
        <Text tag="h2" weight="700" msg={title} data-test="postsSection:text:unknown" />
        {descr && (
          <Text
            msg={descr}
            color="grayDog"
            className={styles.descr}
            data-test="postsSection:text:descr"
          />
        )}
      </header>
      <div>{children}</div>
    </div>
  );
};

export default PostsSection;
