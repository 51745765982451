import { FC } from 'react';

import { ControlItem } from '../../components/SegmentedControls/SegmentedControls';

import { amplitude } from 'Helpers/amplitude';
import {
  CONTENT_CREATION,
  INFLUENCER,
  ORGANIC_POSTING,
  CREATOR_ADS,
  PRODUCT_SEEDING,
  CONTENT_CREATION_BRIEF_TEMPLATE,
  ORGANIC_POSTING_BRIEF_TEMPLATE,
  CREATOR_ADS_BRIEF_TEMPLATE,
  PRODUCT_SEEDING_BRIEF_TEMPLATE,
  USER_GENERATED_CONTENT_SAMPLE_LINK,
  INFLUENCER_POSTS_SAMPLE_LINK,
  PRODUCT_SEEDING_SAMPLE_LINK,
  TIKTOK_SPARK_ADS_SAMPLE_LINK,
  META_PARTNERSHIP_ADS_SAMPLE_LINK,
  AFFILIATE_CAMPAIGN_SAMPLE_LINK,
  TIKTOK_SHOP_SAMPLE_LINK,
} from 'Constants/general';
import {
  CampaignType,
  CampaignObjective,
  CampaignUseCase,
} from 'GraphTypes/UseCase_campaign.graphql';

type GetTypesList = (data: {
  campaignId: string;
  availableCampaignTypes: CampaignType[];
}) => ControlItem[];
export const getTypesList: GetTypesList = (data) => {
  const { availableCampaignTypes, campaignId } = data;

  const sendEvent = () => {
    amplitude.sendEvent<350>({
      id: '350',
      category: 'brief',
      name: 'learn_more_tooltip_click',
      param: { campaign_id: campaignId },
    });
  };

  const result = [
    {
      id: CONTENT_CREATION,
      value: CONTENT_CREATION,
      titleMsg: 'brief_template.field.campaign_type.content_creation.title',
      subtitleMsg: 'brief_template.field.campaign_type.content_creation.descr',
      disabled: !availableCampaignTypes.includes(CONTENT_CREATION),
      dataTest: 'button:campaignType:justContent',
      outerLink: CONTENT_CREATION_BRIEF_TEMPLATE,
      outerLinkMsg: 'brief_template.field.campaign_type.sample_brief',
      outerLinkCb: sendEvent,
    },
    {
      id: ORGANIC_POSTING,
      value: ORGANIC_POSTING,
      titleMsg: 'brief_template.field.campaign_type.organic_posting.title',
      subtitleMsg: 'brief_template.field.campaign_type.organic_posting.descr',
      disabled: !availableCampaignTypes.includes(INFLUENCER),
      dataTest: 'button:campaignType:organicPosting',
      outerLink: ORGANIC_POSTING_BRIEF_TEMPLATE,
      outerLinkMsg: 'brief_template.field.campaign_type.sample_brief',
      outerLinkCb: sendEvent,
    },
    {
      id: CREATOR_ADS,
      value: CREATOR_ADS,
      titleMsg: 'brief_template.field.campaign_type.creator_ads.title',
      subtitleMsg: 'brief_template.field.campaign_type.creator_ads.descr',
      disabled: !availableCampaignTypes.includes(INFLUENCER),
      dataTest: 'button:campaignType:creatorAds',
      outerLink: CREATOR_ADS_BRIEF_TEMPLATE,
      outerLinkMsg: 'brief_template.field.campaign_type.sample_brief',
      outerLinkCb: sendEvent,
    },
    {
      id: PRODUCT_SEEDING,
      value: PRODUCT_SEEDING,
      titleMsg: 'brief_template.field.campaign_type.product_seeding.title',
      subtitleMsg: 'brief_template.field.campaign_type.product_seeding.descr',
      disabled: !availableCampaignTypes.includes(PRODUCT_SEEDING),
      dataTest: 'button:campaignType:productSeeding',
      outerLink: PRODUCT_SEEDING_BRIEF_TEMPLATE,
      outerLinkMsg: 'brief_template.field.campaign_type.sample_brief',
      outerLinkCb: sendEvent,
    },
  ];
  return result;
};

export const getGoalList = (): ControlItem<CampaignObjective>[] => {
  return [
    {
      id: 'JUST_CONTENT',
      value: 'JUST_CONTENT',
      titleMsg: 'campaign.goal.option.ugc',
      tooltipMsg: 'campaign.goal.option.ugc.tooltip',
      dataTest: 'button:campaignObjective:conversions',
    },
    {
      id: 'REACH',
      value: 'REACH',
      titleMsg: 'campaign.goal.option.awarenessReach',
      tooltipMsg: 'campaign.goal.option.awarenessReach.tooltip',
    },
    {
      id: 'CONVERSIONS',
      value: 'CONVERSIONS',
      titleMsg: 'campaign.goal.option.convSales',
      tooltipMsg: 'campaign.goal.option.convSales.tooltip',
    },
  ];
};

export const getUseCaseList = (
  goalType: CampaignObjective | undefined | null,
  availableUseCases?: CampaignUseCase[],
  amplitudeCb?: (useCase: CampaignUseCase) => void,
  getTooltipContent?: FC,
  organization?: Record<string, unknown> | null
): ControlItem<CampaignUseCase>[] => {
  switch (goalType) {
    case 'JUST_CONTENT':
      return [
        {
          id: 'USER_GENERATED_CONTENT',
          value: 'USER_GENERATED_CONTENT',
          titleMsg: 'campaign.strategy.ugc.title',
          subtitleMsg: 'campaign.strategy.ugc.description',
          outerLinkMsg: 'campaign.stategy.sample',
          outerLink: USER_GENERATED_CONTENT_SAMPLE_LINK,
          outerLinkCb: () => amplitudeCb?.('USER_GENERATED_CONTENT'),
        },
      ];
    case 'REACH':
      return [
        {
          id: 'INFLUENCER_POSTS',
          value: 'INFLUENCER_POSTS',
          titleMsg: 'campaign.strategy.influencerPosts.title',
          subtitleMsg: 'campaign.strategy.influencerPosts.description',
          outerLinkMsg: 'campaign.stategy.sample',
          outerLink: INFLUENCER_POSTS_SAMPLE_LINK,
          outerLinkCb: () => amplitudeCb?.('INFLUENCER_POSTS'),
        },
        {
          id: 'PRODUCT_SEEDING',
          value: 'PRODUCT_SEEDING',
          titleMsg: 'campaign.strategy.productSeeding.title',
          subtitleMsg: 'campaign.strategy.productSeeding.description',
          outerLinkMsg: 'campaign.stategy.sample',
          tooltipGetContent: getTooltipContent,
          disabled: !availableUseCases?.includes('PRODUCT_SEEDING'),
          outerLink: PRODUCT_SEEDING_SAMPLE_LINK,
          tooltipIcon: 'Lock',
          outerLinkCb: () => amplitudeCb?.('PRODUCT_SEEDING'),
        },
        {
          id: 'TIKTOK_SPARK_ADS',
          value: 'TIKTOK_SPARK_ADS',
          titleMsg: 'campaign.strategy.sparkAds.title',
          subtitleMsg: 'campaign.strategy.sparkAds.description',
          outerLinkMsg: 'campaign.stategy.sample',
          outerLink: TIKTOK_SPARK_ADS_SAMPLE_LINK,
          outerLinkCb: () => amplitudeCb?.('TIKTOK_SPARK_ADS'),
        },
        {
          id: 'META_PARTNERSHIP_ADS',
          value: 'META_PARTNERSHIP_ADS',
          titleMsg: 'campaign.strategy.metaAds.title',
          subtitleMsg: 'campaign.strategy.metaAds.description',
          outerLinkMsg: 'campaign.stategy.sample',
          outerLink: META_PARTNERSHIP_ADS_SAMPLE_LINK,
          outerLinkCb: () => amplitudeCb?.('META_PARTNERSHIP_ADS'),
        },
      ];
    case 'CONVERSIONS':
      return [
        {
          id: 'AFFILIATE_CAMPAIGN',
          value: 'AFFILIATE_CAMPAIGN',
          titleMsg: 'campaign.strategy.affiliate.title',
          subtitleMsg: 'campaign.strategy.affiliate.description',
          outerLinkMsg: 'campaign.stategy.sample',
          outerLink: AFFILIATE_CAMPAIGN_SAMPLE_LINK,
          tooltipGetContent: getTooltipContent,
          disabled: !availableUseCases?.includes('AFFILIATE_CAMPAIGN'),
          tooltipIcon: 'Lock',
          outerLinkCb: () => amplitudeCb?.('AFFILIATE_CAMPAIGN'),
        },
        {
          id: 'TIKTOK_SHOP',
          value: 'TIKTOK_SHOP',
          scrollId: 'ttAuth',
          titleMsg: 'campaign.strategy.tiktokshop.title',
          subtitleMsg: 'campaign.strategy.tiktokshop.description',
          outerLinkMsg: 'campaign.stategy.sample',
          outerLink: TIKTOK_SHOP_SAMPLE_LINK,
          tooltipGetContent: getTooltipContent,
          disabled: !availableUseCases?.includes('TIKTOK_SHOP'),
          plane: 'Trial',
          tooltipIcon: 'Lock',
          outerLinkCb: () => amplitudeCb?.('TIKTOK_SHOP'),
          authorizationCompleted: Boolean(organization?.tiktokShopLinked),
          authorizationType: 'tiktokshop',
        },
        {
          id: 'TIKTOK_SPARK_ADS',
          value: 'TIKTOK_SPARK_ADS',
          titleMsg: 'campaign.strategy.sparkAds.title',
          subtitleMsg: 'campaign.strategy.sparkAds.description',
          outerLinkMsg: 'campaign.stategy.sample',
          outerLink: TIKTOK_SPARK_ADS_SAMPLE_LINK,
          outerLinkCb: () => amplitudeCb?.('TIKTOK_SPARK_ADS'),
        },
        {
          id: 'META_PARTNERSHIP_ADS',
          value: 'META_PARTNERSHIP_ADS',
          titleMsg: 'campaign.strategy.metaAds.title',
          subtitleMsg: 'campaign.strategy.metaAds.description',
          outerLinkMsg: 'campaign.stategy.sample',
          outerLink: META_PARTNERSHIP_ADS_SAMPLE_LINK,
          outerLinkCb: () => amplitudeCb?.('META_PARTNERSHIP_ADS'),
        },
      ];
    default:
      return [];
  }
};
