/**
 * @generated SignedSource<<276e74724c09cfdb9a3a6655e897ad89>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ContractorAcquisitionSource = "BRAND" | "FRIEND" | "INVATION" | "OTHER" | "SEARCH" | "SOCIAL" | "STORE";
export type CreatorSource = "OUTREACH";
export type CreateContractorProfileInput = {
  acquisitionSource?: ContractorAcquisitionSource | null | undefined;
  birthdate?: any | null | undefined;
  cityId?: string | null | undefined;
  cityIds?: ReadonlyArray<string> | null | undefined;
  clientMutationId?: string | null | undefined;
  countryId?: string | null | undefined;
  creatorAgent?: boolean | null | undefined;
  firstName?: string | null | undefined;
  id?: string | null | undefined;
  languageIds?: ReadonlyArray<string> | null | undefined;
  lastName?: string | null | undefined;
  phoneNumber?: string | null | undefined;
  referralCampaignId?: string | null | undefined;
  referralSource?: CreatorSource | null | undefined;
  referralUserId?: string | null | undefined;
  tosAccepted?: boolean | null | undefined;
  userId: string;
};
export type CreateContractorProfileMutation$variables = {
  input: CreateContractorProfileInput;
};
export type CreateContractorProfileMutation$data = {
  readonly createContractorProfile: {
    readonly profile: {
      readonly id: string;
    };
  } | null | undefined;
};
export type CreateContractorProfileMutation = {
  response: CreateContractorProfileMutation$data;
  variables: CreateContractorProfileMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateContractorProfilePayload",
    "kind": "LinkedField",
    "name": "createContractorProfile",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ContractorProfile",
        "kind": "LinkedField",
        "name": "profile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateContractorProfileMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateContractorProfileMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7200e88668508ed42735c82b14705659",
    "id": null,
    "metadata": {},
    "name": "CreateContractorProfileMutation",
    "operationKind": "mutation",
    "text": "mutation CreateContractorProfileMutation(\n  $input: CreateContractorProfileInput!\n) {\n  createContractorProfile(input: $input) {\n    profile {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2533a98220504bcff78267b288bf177d";

export default node;
