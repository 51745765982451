/**
 * @generated SignedSource<<f22c708b98826f37244761602313b044>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type Reporting_ReportState = "EXPIRED" | "FAILED" | "PENDING" | "READY";
export type useDownloadReportSubscription$variables = Record<PropertyKey, never>;
export type useDownloadReportSubscription$data = {
  readonly reportingReportUpdated: {
    readonly downloadUrl: string | null | undefined;
    readonly expiresAt: any | null | undefined;
    readonly filename: string | null | undefined;
    readonly state: Reporting_ReportState;
  };
};
export type useDownloadReportSubscription = {
  response: useDownloadReportSubscription$data;
  variables: useDownloadReportSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "downloadUrl",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expiresAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "filename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useDownloadReportSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "reportingReportUpdated",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useDownloadReportSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "reportingReportUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "da5017ec9a16b2d77b5d84be5d4f930d",
    "id": null,
    "metadata": {},
    "name": "useDownloadReportSubscription",
    "operationKind": "subscription",
    "text": "subscription useDownloadReportSubscription {\n  reportingReportUpdated {\n    __typename\n    state\n    downloadUrl\n    expiresAt\n    filename\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "c31590f58d934fb87d92a66b4f3e239a";

export default node;
