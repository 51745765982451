/**
 * @generated SignedSource<<8d129243b974ee929ce68a89e7e93c0e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContractorProjectStage = "ACCEPTED" | "ACCEPTED_AND_NOT_LAUNCHED" | "ACCEPTED_BY_UNREVIEWED_CREATOR" | "COMPLETED" | "CONTENT_SUBMITTED" | "LAUNCHED" | "NEW" | "PRODUCT_SHIPMENT_COMPLETED" | "PRODUCT_SHIPMENT_STARTED" | "PUBLISHING_STARTED" | "REJECTED" | "UNSENT";
export type Currency = "CAD" | "RUB" | "USD";
import { FragmentRefs } from "relay-runtime";
export type ContractorActions_project$data = {
  readonly archivation: {
    readonly id: string;
  } | null | undefined;
  readonly campaign: {
    readonly publishingRequired: boolean;
  };
  readonly contractorStage: ContractorProjectStage | null | undefined;
  readonly id: string;
  readonly priceChangeRequest: {
    readonly currency: Currency;
    readonly id: string;
    readonly price: number;
  } | null | undefined;
  readonly " $fragmentType": "ContractorActions_project";
};
export type ContractorActions_project$key = {
  readonly " $data"?: ContractorActions_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContractorActions_project">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContractorActions_project",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectArchivation",
      "kind": "LinkedField",
      "name": "archivation",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceChangeRequest",
      "kind": "LinkedField",
      "name": "priceChangeRequest",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "price",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "publishingRequired",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contractorStage",
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};
})();

(node as any).hash = "87ea2ebe7c00dc15315fc60e4de0da40";

export default node;
