import React, { useCallback } from 'react';

import { Project, Projects } from '../util';

import styles from './CreatorChats.pcss';

import { amplitude } from 'Helpers/amplitude';
import { getProjectsLink } from 'Util/links';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import Image from 'Components/ui/Image/Image';
import Text from 'Components/ui/Text/Text';
import TextPreset from 'Components/ui/TextPreset/TextPreset';
import { CampaignPlatform } from 'GraphTypes/Collaboration_collaboration.graphql';

interface Props {
  chatDetails: Projects;
}

const CreatorChats: React.FC<Props> = (props) => {
  const { chatDetails } = props;

  const completedProjectsList = chatDetails?.completedProjectsList;
  const notCompletedProjectsList = chatDetails?.notCompletedProjectsList;

  const createProject = (project: Project) => {
    const { projectId, name, logo, creatorId, campaignId, campaignStage } = project;
    const projectLink = getProjectsLink({
      projectId,
      campaignId: campaignStage === 'ACTIVE' ? campaignId : undefined,
    });

    const platform = project.platform as CampaignPlatform;

    const handleChatClick = useCallback(() => {
      amplitude.sendEvent({
        id: 211,
        category: 'creators_worked',
        name: 'open_chat_campaign_click',
        param: { creatorId, projectId, campaignId },
      });
    }, [creatorId, projectId, campaignId]);

    return (
      <SmartLink
        key={projectId}
        path={projectLink}
        target="_blank"
        className={styles.campaign}
        onClick={handleChatClick}
      >
        <TextPreset
          header={
            <div className={styles.campaignHeader}>
              <Image src={logo} type="round" size={44} platform={platform || null} />
              {name && (
                <Text
                  type="h2"
                  className={styles.headerText}
                  text={name}
                  data-test="creatorChats:text:headerText"
                />
              )}
            </div>
          }
        />
      </SmartLink>
    );
  };

  return (
    <div className={styles.root}>
      <Text
        type="d2"
        msg="creators.dashboard.previous_collaboration.chats.title"
        className={styles.title}
        data-test="creatorChats:text:title"
      />
      {completedProjectsList && completedProjectsList.length > 0 && (
        <>
          <Text
            type="md"
            msg="creators.dashboard.previous_collaboration.chats.not_completed"
            className={styles.subtitle}
            data-test="creatorChats:text:notCompleted"
          />
          {completedProjectsList?.map((item) => {
            return createProject(item);
          })}
        </>
      )}
      {notCompletedProjectsList && notCompletedProjectsList.length > 0 && (
        <>
          <Text
            type="md"
            msg="creators.dashboard.previous_collaboration.chats.completed"
            className={styles.subtitle}
            data-test="creatorChats:text:completed"
          />
          {notCompletedProjectsList?.map((item) => {
            return createProject(item);
          })}
        </>
      )}
    </div>
  );
};

export default CreatorChats;
