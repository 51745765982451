import React, { ChangeEvent, memo, Suspense, useContext, useEffect } from 'react';
import { graphql, useQueryLoader } from 'react-relay';
import classNames from 'classnames';

import styles from './DiscoverySearchResults.pcss';
import DiscoverySearchResults from './DiscoverySearchResults';

import { DiscoverySearchResultsContainerQuery as QueryType } from 'GraphTypes/DiscoverySearchResultsContainerQuery.graphql';
import {
  DiscoveryCreatorsContext,
  DiscoveryListViewType,
} from 'Page/advertiser/Discovery/DiscoveryCreatorsContext';
import Text from 'Components/ui/Text/Text';
import Spinner from 'Atoms/Spinner/Spinner';
import { Checkbox } from 'Components/ui/Checkbox';

export const DiscoverySearchResultsContainerQuery = graphql`
  query DiscoverySearchResultsContainerQuery(
    $organizationId: ID!
    $query: SearchQueryInput!
    $isGridView: Boolean!
    $isTableView: Boolean!
  ) {
    ...DiscoverySearchResults_organization
      @arguments(
        query: $query
        organizationId: $organizationId
        isGridView: $isGridView
        isTableView: $isTableView
      )
    currentUser {
      admin
      permissions
      organization {
        id
        newClient
        churned
        subscription {
          canUsePaidSocial
          paused
        }
      }
    }
  }
`;
const DiscoverySearchResultsContainer: React.FC = () => {
  const {
    queryParams: searchParams,
    organizationId,
    selectAllMode,
    setSelectAllModeStatus,
    listView,
  } = useContext(DiscoveryCreatorsContext);

  const [queryReference, loadQuery] = useQueryLoader<QueryType>(
    DiscoverySearchResultsContainerQuery
  );

  useEffect(() => {
    if (organizationId) {
      loadQuery(
        {
          query: searchParams,
          organizationId,
          isGridView: listView === DiscoveryListViewType.GRID,
          isTableView: listView === DiscoveryListViewType.LIST,
        },
        {
          fetchPolicy: 'network-only',
        }
      );
    }
  }, [organizationId, JSON.stringify(searchParams), listView]);

  const handleSelectAllChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectAllModeStatus(e.target.checked);
  };

  const preloader = (
    <div className={styles.loadingContainer}>
      <Spinner />
    </div>
  );

  if (!organizationId || !queryReference) return null;

  return (
    <>
      <div className={styles.listHeader}>
        <Checkbox
          checked={selectAllMode}
          onChange={handleSelectAllChange}
          data-test="discoverySearchResultsContainer:checkbox:unknown"
        />
        {listView === DiscoveryListViewType.LIST ? (
          <div className={styles.listHeaderTextWrapper}>
            <Text
              type="md"
              className={styles.listHeaderText}
              msg="discovery.dashboard.lists.header.username"
              data-test="discoverySearchResultsContainer:text:listHeaderText"
            />
            <Text
              type="md"
              className={styles.listHeaderText}
              msg="discovery.dashboard.lists.header.followers"
              data-test="discoverySearchResultsContainer:text:listHeaderText"
            />
            <Text
              type="md"
              className={styles.listHeaderText}
              msg="discovery.dashboard.lists.header.er"
              data-test="discoverySearchResultsContainer:text:listHeaderText"
            />
            <Text
              type="md"
              className={styles.listHeaderText}
              msg="discovery.dashboard.lists.header.avg_views"
              data-test="discoverySearchResultsContainer:text:listHeaderText"
            />
            <Text
              type="md"
              className={styles.listHeaderText}
              msg="discovery.dashboard.lists.header.badges"
              data-test="discoverySearchResultsContainer:text:listHeaderText"
            />
            <Text
              type="md"
              className={classNames(styles.listHeaderText, styles.profile)}
              msg="discovery.dashboard.lists.header.actions"
              data-test="discoverySearchResultsContainer:text:actions"
            />
            <div />
          </div>
        ) : (
          <Text
            type="md"
            className={styles.listHeaderText}
            msg="discovery.dashboard.lists.header.select_all"
            data-test="discoverySearchResultsContainer:text:listHeaderText"
          />
        )}
      </div>
      <Suspense fallback={preloader}>
        <DiscoverySearchResults queryReference={queryReference} />
      </Suspense>
    </>
  );
};

export default memo(DiscoverySearchResultsContainer);
