import React, { Component } from 'react';

import styles from './Menu.css';
import MenuItem from './MenuItem/MenuItem';

class Menu extends Component {
  createItems() {
    const {
      items,
      inGroup,
      dataConfig,
      checkActiveState,
      createIcon,
      hasActiveIcon,
      onChange,
      isSingle,
      hasCheckbox = true,
    } = this.props;

    return items.map((item) => {
      const data = dataConfig ? item[dataConfig] : item;
      const { id } = data;
      const isActive = checkActiveState ? checkActiveState(id) : false;
      const icon = createIcon ? createIcon(item.name) : undefined;

      return (
        <MenuItem
          key={id}
          item={data}
          icon={icon}
          inGroup={inGroup}
          isSingle={isSingle}
          hasActiveIcon={hasActiveIcon}
          hasCheckbox={hasCheckbox}
          isActive={isActive}
          onChange={onChange}
        />
      );
    });
  }

  render() {
    const { className, items, style, additionalEl, withLoading } = this.props;

    if (!items || items.length === 0) return null;

    const menuItems = this.createItems();

    if (withLoading) {
      return <div>{menuItems}</div>;
    }

    return (
      <div className={`${className} ${styles.root}`} id="menu" style={style}>
        {menuItems}
        {additionalEl}
      </div>
    );
  }
}

export default Menu;
