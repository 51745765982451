import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { graphql, useLazyLoadQuery, useQueryLoader } from 'react-relay';
import { useNavigate } from 'react-router-dom';

import InviteOutreachCreators from '../InviteOutreachCreators/InviteOutreachCreators';
import OutreachBatchesControls from '../OutreachBatchesControls/OutreachBatchesControls';
import OutreachTable from '../OutreachTable/OutreachTable';
import OutreachHeader from '../OutreachTable/OutreachHeader/OutreachHeader';
import OutreachTableStub from '../OutreachTableStub/OutreachTableStub';
import OutreachReauthBanner from '../OutreachReauthBanner/OutreachReauthBanner';
import {
  GmailPostMessageData,
  GmailPostMessageTypeEnum,
} from '../OutreachBatchesControls/AddGmailAccountButton/utils';

import styles from './OutreachDashboard.pcss';

import { amplitude } from 'Helpers/amplitude';
import { CampaignPlatform } from 'GraphTypes/CampaignQuery.graphql';
import { getCampaignOutreachBatchLink } from 'Util/links';
import { OutreachDashboardQuery } from 'GraphTypes/OutreachDashboardQuery.graphql';
import {
  OutreachBatchState,
  OutreachDashboardBatchesQuery,
} from 'GraphTypes/OutreachDashboardBatchesQuery.graphql';

interface Props {
  campaignId: string;
  platform?: CampaignPlatform | null;
}

const outreachBatchQuery = graphql`
  query OutreachDashboardBatchesQuery(
    $campaignId: ID!
    $filterText: String
    $states: [OutreachBatchState!]
  ) {
    campaign(id: $campaignId) {
      outreachBatches(textQuery: $filterText, states: $states) {
        edges {
          node {
            id
            state
            sequenceBlueprint {
              name
            }
            ...OutreachBatch_outreachBatch
            ...OutreachSequenceList_steps
          }
        }
        totalCount
      }
    }
  }
`;

const outreachPreviewQuery = graphql`
  query OutreachDashboardQuery($id: ID!) {
    campaign(id: $id) {
      stage
      outreachBatches {
        totalCount
        edges {
          node {
            senderEmail
            gmailAuthorization {
              id
              reauthenticationRequired
              availableEmails
            }
          }
        }
      }
    }
  }
`;

const OutreachDashboard: React.FC<Props> = (props) => {
  const { campaignId } = props;

  const [filterText, setFilterText] = useState('');
  const [filterStates, setFilterStates] = useState<OutreachBatchState[] | null>(null);
  const navigate = useNavigate();

  const outreachPreviewData = useLazyLoadQuery<OutreachDashboardQuery>(
    outreachPreviewQuery,
    {
      id: campaignId,
    },
    {
      fetchPolicy: 'network-only',
    }
  );

  const [queryReference, loadQuery] =
    useQueryLoader<OutreachDashboardBatchesQuery>(outreachBatchQuery);

  useEffect(() => {
    const gmailAuthorizationMessageHandler = (e: MessageEvent) => {
      const data: GmailPostMessageData = e.data;

      if (data.type === GmailPostMessageTypeEnum.Authenticate) {
        const params: GmailPostMessageData['params'] = e?.data?.params;

        window.removeEventListener('message', gmailAuthorizationMessageHandler, false);
        if (params.code) {
          const states = filterStates === null ? undefined : filterStates;
          loadQuery(
            { campaignId, filterText, states },
            {
              fetchPolicy: 'network-only',
            }
          );
        }
      }
    };
    window.addEventListener('message', gmailAuthorizationMessageHandler, false);
    return () => {
      window.addEventListener('message', gmailAuthorizationMessageHandler, false);
    };
  }, [filterStates?.length]);

  useEffect(() => {
    const states = filterStates === null ? undefined : filterStates;
    loadQuery(
      { campaignId, filterText, states },
      {
        fetchPolicy: 'network-only',
      }
    );
  }, [filterText, filterStates?.length]);

  const hasInvitedCreators = Number(outreachPreviewData?.campaign?.outreachBatches.totalCount) > 0;
  const isAllowToStartOutreach = outreachPreviewData?.campaign?.stage === 'ACTIVE';
  const reauthGmailAccount = useMemo(() => {
    return outreachPreviewData.campaign?.outreachBatches.edges?.find(
      (auth) => auth?.node?.gmailAuthorization?.reauthenticationRequired
    );
  }, [outreachPreviewData.campaign?.outreachBatches.edges]);

  const handleInviteClick = () => {
    amplitude.sendEvent<409>({
      id: '409',
      category: 'campaign',
      name: 'outreach_button_click',
      param: undefined,
    });
    navigate(getCampaignOutreachBatchLink({ campaignId }));
  };

  return (
    <div className={styles.root}>
      {hasInvitedCreators ? (
        <div className={styles.outreach}>
          {hasInvitedCreators && <OutreachHeader campaignId={campaignId} />}
          {isAllowToStartOutreach ? (
            <OutreachBatchesControls
              handleInvite={handleInviteClick}
              onChangeFilterText={setFilterText}
              filterText={filterText}
              filterStates={filterStates}
              onChangeFilterStates={setFilterStates}
            />
          ) : null}

          {queryReference ? (
            <Suspense fallback={<OutreachTableStub />}>
              <OutreachTable
                campaignId={campaignId}
                queryReference={queryReference}
                query={outreachBatchQuery}
              />
            </Suspense>
          ) : (
            <OutreachTableStub />
          )}
        </div>
      ) : (
        <InviteOutreachCreators
          isAllowToStartOutreach={isAllowToStartOutreach}
          className={styles.invite}
        />
      )}
      {reauthGmailAccount && <OutreachReauthBanner authorization={reauthGmailAccount} />}
    </div>
  );
};

export default OutreachDashboard;
