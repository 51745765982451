/**
 * @generated SignedSource<<bfe51ba9d5be59828b2995b004910b89>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING";
export type CampaignUseCase = "AFFILIATE_CAMPAIGN" | "INFLUENCER_POSTS" | "META_PARTNERSHIP_ADS" | "PRODUCT_SEEDING" | "TIKTOK_SHOP" | "TIKTOK_SPARK_ADS" | "USER_GENERATED_CONTENT";
export type ProductDeliveryOption = "BRAND_WILL_HANDLE_SHIPMENT_TO_CREATORS" | "BRAND_WILL_REFUND_THE_PURCHASED_PRODUCT" | "COLLABORATION_WILL_NOT_REQUIRE_PRODUCT_SHIPMENT" | "TTS_OTHER" | "TTS_SEND_SAMPLE_TO_CREATOR" | "TTS_SEND_SAMPLE_TO_CREATOR_WITHOUT_APPROVAL";
import { FragmentRefs } from "relay-runtime";
export type ProductDetails_campaign$data = {
  readonly brief: {
    readonly __typename: string;
    readonly id: string;
    readonly includeReferralFee?: boolean | null | undefined;
    readonly productDelivery?: ProductDeliveryOption | null | undefined;
    readonly productDescription?: string | null | undefined;
    readonly productPrice?: number | null | undefined;
    readonly productTitle?: string | null | undefined;
    readonly productUrl?: string | null | undefined;
    readonly referralFeeRate?: number | null | undefined;
    readonly submitted: boolean;
    readonly tiktokShop_ProductId?: string | null | undefined;
    readonly tiktokShop_ShopId?: string | null | undefined;
  } | null | undefined;
  readonly cover: {
    readonly height: number | null | undefined;
    readonly transformations?: {
      readonly collageThumbnailUrl: string;
    } | null | undefined;
    readonly width: number | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly organization: {
    readonly tiktokShopLinked: boolean | null | undefined;
  } | null | undefined;
  readonly type: CampaignType | null | undefined;
  readonly useCase: CampaignUseCase | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ComissionRateDetails_campaign" | "TiktokShop_campaign">;
  readonly " $fragmentType": "ProductDetails_campaign";
};
export type ProductDetails_campaign$key = {
  readonly " $data"?: ProductDetails_campaign$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductDetails_campaign">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductDetails_campaign",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "cover",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "width",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "height",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ImageTransformation",
              "kind": "LinkedField",
              "name": "transformations",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "collageThumbnailUrl",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "Image",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "useCase",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "brief",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "submitted",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "productUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "productDelivery",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "productTitle",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "productDescription",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "includeReferralFee",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "referralFeeRate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "productPrice",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tiktokShop_ShopId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tiktokShop_ProductId",
              "storageKey": null
            }
          ],
          "type": "V2Brief",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tiktokShopLinked",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ComissionRateDetails_campaign"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TiktokShop_campaign"
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};
})();

(node as any).hash = "c95fd36ffb4a830f2ba6e4b927678838";

export default node;
