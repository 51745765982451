import React, { Component } from 'react';
import { createPaginationContainer, graphql } from 'react-relay';

import LoaderHandler from 'Containers/LoaderHandler/LoaderHandler';

const COUNT = 10;

class CityListContainer extends Component {
  render() {
    const { relay, menu, cities } = this.props;
    if (!cities) return null;

    const items =
      cities.cities && cities.cities.edges && cities.cities.edges.length
        ? cities.cities.edges
        : null;
    if (!items) return null;

    const style = { minWidth: '228px' };

    return (
      <LoaderHandler
        relay={relay}
        count={COUNT}
        offset={50}
        hideLoader={true}
        ownContainer={true}
        containerId="menu"
      >
        {React.cloneElement(menu, { items, style })}
      </LoaderHandler>
    );
  }
}

export default createPaginationContainer(
  CityListContainer,
  {
    cities: graphql`
      fragment CityListContainer_cities on Query
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 10 }
        cursor: { type: "String" }
        query: { type: "String!" }
      ) {
        cities(first: $count, after: $cursor, query: $query)
          @connection(key: "Cities_cities", filters: []) {
          edges {
            node {
              id
              name
              fullName
            }
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.cities && props.cities.cities;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
        query: props.query,
      };
    },
    query: graphql`
      query CityListContainerPaginationQuery($count: Int!, $cursor: String, $query: String!) {
        ...CityListContainer_cities @arguments(count: $count, cursor: $cursor, query: $query)
      }
    `,
  }
);
