/**
 * @generated SignedSource<<5589164ddf1fc88d95767045855d64ad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Deliverables_campaign$data = {
  readonly brief: {
    readonly id: string;
    readonly publishingRequired?: boolean | null | undefined;
    readonly taskDescription?: string | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly " $fragmentType": "Deliverables_campaign";
};
export type Deliverables_campaign$key = {
  readonly " $data"?: Deliverables_campaign$data;
  readonly " $fragmentSpreads": FragmentRefs<"Deliverables_campaign">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Deliverables_campaign",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "brief",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "publishingRequired",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "taskDescription",
              "storageKey": null
            }
          ],
          "type": "V2Brief",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};
})();

(node as any).hash = "9d6146f5036570a3cf8281686068a174";

export default node;
