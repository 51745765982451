import React, { useMemo, useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import moment from 'moment';

import styles from './ChangeDeadlineAction.pcss';

import Button from 'Atoms/Button/Button';
import Text from 'Components/ui/Text/Text';
import TextPreset from 'Components/ui/TextPreset/TextPreset';
import ButtonPreset from 'Components/ui/ButtonPreset/ButtonPreset';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import rejectDeadlineChangeRequest from 'Api/mutations/RejectDeadlineChangeRequest.Mutation';
import acceptDeadlineChangeRequest from 'Api/mutations/AcceptDeadlineChangeRequest.Mutation';
import { ChangeDeadlineAction_project$key } from 'GraphTypes/ChangeDeadlineAction_project.graphql';

type Props = {
  isOldView: boolean;
  project: ChangeDeadlineAction_project$key;
};

const ChangeDeadlineAction: React.FC<Props> = (props) => {
  const { project, isOldView } = props;

  const data = useFragment<ChangeDeadlineAction_project$key>(
    graphql`
      fragment ChangeDeadlineAction_project on Project {
        id
        deadline {
          draftDate
        }
        archivation {
          id
        }
        deadlineChangeRequest {
          id
          draftDate
        }
      }
    `,
    project
  );

  const [processingDeadlineChange, setProcessingDeadlineChange] = useState(false);

  const oldDeadline = useMemo(() => {
    if (!data?.deadline?.draftDate) {
      return '-';
    }
    return moment(data?.deadline.draftDate, 'YYYY-MM-DD').format('MMM DD, YYYY');
  }, [data?.deadline?.draftDate]);

  const newDeadline = useMemo(() => {
    if (!data?.deadlineChangeRequest?.draftDate) {
      return '-';
    }
    return moment(data?.deadlineChangeRequest.draftDate, 'YYYY-MM-DD').format('MMM DD, YYYY');
  }, [data?.deadlineChangeRequest?.draftDate]);

  const handleDeadlineChangeReaction = () => {
    setProcessingDeadlineChange(false);
  };

  const handleRejectDeadlineChange = () => {
    if (!deadlineChangeRequest) return;
    setProcessingDeadlineChange(true);
    rejectDeadlineChangeRequest(
      { deadlineChangeRequestId: deadlineChangeRequest.id, projectId },
      handleDeadlineChangeReaction
    );
  };

  const handleAcceptDeadlineChange = () => {
    if (!deadlineChangeRequest) return;
    setProcessingDeadlineChange(true);
    acceptDeadlineChangeRequest(
      { deadlineChangeRequestId: deadlineChangeRequest.id, projectId },
      handleDeadlineChangeReaction
    );
  };

  if (!data) {
    return null;
  }

  const { id: projectId, archivation, deadlineChangeRequest } = data;

  if (archivation?.id) {
    return null;
  }

  if (isOldView) {
    return (
      <div className={styles.requestContainerOld}>
        <Text
          type="h2"
          msg="chat.contractor.deadline_change_request.title"
          formatValues={{ oldDate: oldDeadline, newDate: newDeadline }}
          data-test="changeDeadlineAction:text:title"
        />
        <Text
          type="md"
          msg="chat.contractor.deadline_change_request.descr.old"
          data-test="changeDeadlineAction:text:old"
        />
        <div className={styles.buttonContainer}>
          <Button
            color="primary"
            className={styles.button}
            msg="chat.contractor.deadline_change_request.accept"
            loading={processingDeadlineChange}
            onClick={handleAcceptDeadlineChange}
            data-test="changeDeadlineAction:button:button"
          />
          <Button
            color="normal"
            className={styles.button}
            msg="chat.contractor.deadline_change_request.reject"
            loading={processingDeadlineChange}
            onClick={handleRejectDeadlineChange}
            data-test="changeDeadlineAction:button:button"
          />
        </div>
      </div>
    );
  }
  return (
    <div className={styles.requestContainer}>
      <TextPreset
        header={
          <Text
            type="h2"
            msg="chat.contractor.deadline_change_request.title"
            formatValues={{ oldDate: oldDeadline, newDate: newDeadline }}
            data-test="changeDeadlineAction:text:title"
          />
        }
        subtitle={
          <Text
            type="md"
            className={styles.descr}
            msg="chat.contractor.deadline_change_request.descr"
            data-test="changeDeadlineAction:text:descr"
          />
        }
        className={styles.details}
      />
      <ButtonPreset type="alterbutton" className={styles.controls}>
        <AlterButton
          type="black"
          icon={'Check'}
          className={styles.button}
          msg="chat.contractor.deadline_change_request.accept"
          loading={processingDeadlineChange}
          onClick={handleAcceptDeadlineChange}
          data-test="changeDeadlineAction:alterButton:button"
        />
        <AlterButton
          type="grey"
          icon={'Close-small'}
          className={styles.button}
          msg="chat.contractor.deadline_change_request.reject"
          loading={processingDeadlineChange}
          onClick={handleRejectDeadlineChange}
          data-test="changeDeadlineAction:alterButton:button"
        />
      </ButtonPreset>
    </div>
  );
};

export default ChangeDeadlineAction;
