import React, { Suspense, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import styles from './Discovery.pcss';
import DiscoverySearchSection from './SearchSection/DiscoverySearchSection';
import { DiscoveryCreatorsProvider } from './DiscoveryCreatorsProvider';

import Page from 'Templates/Page/Page';
import { trackTime as custifyTrackTime } from 'Util/custify';
import { amplitude } from 'Helpers/amplitude';
import Spinner from 'Atoms/Spinner/Spinner';

interface Props {
  campaignId?: string;
}

const Discovery: React.FC<Props> = (props) => {
  const params = useParams<{ listId: string }>();
  const location = useLocation();

  const listId = params.listId;
  const isCreating = location?.state?.isCreating;
  const backUrl = location?.state?.backUrl;

  if (!listId) {
    return null;
  }

  useEffect(() => {
    custifyTrackTime('Discovery');
    // amplitude.sendEvent({
    //   id: '58',
    //   category: 'pageview',
    //   name: 'creator_marketplace',
    // });

    return () => {
      _ctrack.stopTrackTime();
    };
  }, []);

  return (
    <DiscoveryCreatorsProvider listId={listId} isCreating={isCreating} backUrl={backUrl}>
      <Page className={styles.root}>
        <Suspense fallback={<Spinner />}>
          <DiscoverySearchSection className={styles.searchSection} />
        </Suspense>
      </Page>
    </DiscoveryCreatorsProvider>
  );
};

export default Discovery;
