/**
 * @generated SignedSource<<7a35cd0889167e4fd611b4c09b049ef3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OutreachBatchState = "ACTIVE" | "COMPLETED" | "DISCONNECTED" | "INITIAL" | "STOPPED";
import { FragmentRefs } from "relay-runtime";
export type OutreachSequenceList_steps$data = {
  readonly createdAt: any;
  readonly id: string;
  readonly sequenceBlueprint: {
    readonly name: string;
    readonly steps: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly delayInDays: number | null | undefined;
          readonly id: string;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly state: OutreachBatchState;
  readonly " $fragmentType": "OutreachSequenceList_steps";
};
export type OutreachSequenceList_steps$key = {
  readonly " $data"?: OutreachSequenceList_steps$data;
  readonly " $fragmentSpreads": FragmentRefs<"OutreachSequenceList_steps">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OutreachSequenceList_steps",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OutreachSequenceBlueprint",
      "kind": "LinkedField",
      "name": "sequenceBlueprint",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OutreachStepBlueprintConnection",
          "kind": "LinkedField",
          "name": "steps",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OutreachStepBlueprintEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OutreachStepBlueprint",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "delayInDays",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OutreachBatch",
  "abstractKey": null
};
})();

(node as any).hash = "1f677d2f478c4756f06d13711d6281ee";

export default node;
