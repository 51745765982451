import React, { useContext } from 'react';
import { graphql, useFragment } from 'react-relay';

import DealActions from '../DealActions/DealActions';

import styles from './PostingStep.pcss';

import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { createDate } from 'Util/dateCreator';
import Text from 'Atoms/Text/Text';
import Input from 'Components/ui/Input/Input';
import Icon from 'Components/ui/Icon/Icon';
import { PostingStep_project$key } from 'GraphTypes/PostingStep_project.graphql';

interface Props {
  isActive: boolean;
  isPastStep: boolean;
  isContractor: boolean;
  project: PostingStep_project$key;
}

const PostingStep: React.FC<Props> = (props) => {
  const { openDrawer } = useContext(DrawerContext);

  const { project, isActive, isPastStep, isContractor } = props;

  const data = useFragment(
    graphql`
      fragment PostingStep_project on Project {
        deadline {
          publicationDate
        }
        price
        id
        ...DealActions_project
      }
    `,
    project
  );

  const price = data.price;
  const { deadline, id: projectId } = data;
  const titleColor = !isActive && !isPastStep ? 'grayDog' : undefined;

  const handleDateClick = () => {
    openDrawer(`project-publication-date`);
  };

  const publicationDate = deadline?.publicationDate || '';

  return (
    <div>
      <Text
        weight="500"
        color={titleColor}
        transform="uppercase"
        msg="project.stepper.posting.title"
        data-test="postingStep:text:title"
      />
      {isActive && !isContractor && (
        <div className={styles.content}>
          <Text
            size="sm"
            msg={
              price && price > 0
                ? 'project.stepper.posting.advertiser.descr'
                : 'project.stepper.posting_without_payment.advertiser.descr'
            }
            data-test="postingStep:text:descr"
          />
          <div className={styles.publicationDate} onClick={handleDateClick}>
            <Input
              value={publicationDate ? createDate(publicationDate) : ''}
              rightSymbol={<Icon name="Calendar-schedule" />}
              disabled
              bordered
              hideCloseIcon
              data-test="postingStep:input:unknown"
            />
          </div>
          <DealActions
            finishDealMsg={price && price > 0 ? undefined : 'project.stepper.posting.complete'}
            project={data}
          />
        </div>
      )}
    </div>
  );
};

export default PostingStep;
