import React, {
  ChangeEvent,
  useContext,
  useDeferredValue,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { graphql, useFragment } from 'react-relay';
import classNames from 'classnames';

import styles from '../InviteOutreachCreators.pcss';
import { InviteOutreachCreatorsContext } from '../InviteOutreachCreatorsContext';
import SequenceResetConfirmDrawer from '../SequenceResetConfirmDrawer/SequenceResetConfirmDrawer';
import { sequenceDefaultEmptyStep } from '../InviteOutreachCreatorsProvider';

import localStyles from './SequenceSelector.pcss';
import { insenseSequencePresets } from './insensePresets';

import { amplitude } from 'Helpers/amplitude';
import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import {
  SequenceSelectorForOutreach_list$data,
  SequenceSelectorForOutreach_list$key,
} from 'GraphTypes/SequenceSelectorForOutreach_list.graphql';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownGroupItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import ls from 'Util/localStorage';
import Accordion from 'Components/ui/Accordion/Accordion';
import { CampaignUseCase } from 'GraphTypes/InviteOutreachCreatorsQuery.graphql';
import Icon from 'Components/ui/Icon/Icon';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import Input from 'Components/ui/Input/Input';

interface Props {
  query: SequenceSelectorForOutreach_list$key;
  userQuery: SequenceSelectorForOutreach_list$key;
}

const fragmentQuery = graphql`
  fragment SequenceSelectorForOutreach_list on Organization {
    id
    outreachSequenceBlueprints {
      edges {
        node {
          id
          name
          steps {
            edges {
              node {
                id
                startsAtHour
                delayInDays
                projectEventType
                template {
                  id
                  subject
                  htmlBody
                }
              }
            }
          }
        }
      }
    }
  }
`;

type SequenceList = NonNullable<
  SequenceSelectorForOutreach_list$data['outreachSequenceBlueprints']
>['edges'];

const SequenceSelector: React.FC<Props> = (props) => {
  const { query, userQuery } = props;
  const { openDrawer, closeDrawer } = useContext(DrawerContext);
  const {
    sequenceId,
    setSequenceList,
    setActiveStep,
    setTemplateChangedStatus,
    setSequenceId,
    platform,
    useCase,
    selectedPreset,
    setSelectedPreset,
  } = useContext(InviteOutreachCreatorsContext);
  const data = useFragment(fragmentQuery, query);
  const userData = useFragment(fragmentQuery, userQuery);
  const [filterText, setFilterText] = useState('');

  const filterTextValue = useDeferredValue(filterText);

  const handleFilterTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterText(e.target.value.toLowerCase().trim() || '');
  };

  const isAdmin = ls.get('admin');

  const templates = data.outreachSequenceBlueprints?.edges;
  const userTemplates = userData.outreachSequenceBlueprints?.edges;
  const isAdminMakeOutreachForClient = data.id !== userData.id;
  const sequenceName = useMemo(() => {
    let name = data.outreachSequenceBlueprints?.edges?.find(
      (sequence) => sequence?.node?.id === sequenceId
    )?.node?.name;
    if (!name) {
      name = userData.outreachSequenceBlueprints?.edges?.find(
        (sequence) => sequence?.node?.id === sequenceId
      )?.node?.name;
      if (!name) {
        name = insenseSequencePresets.find((sequence) => sequence.node.id === sequenceId)?.node
          .name;
      }
    }
    return name;
  }, [sequenceId]);

  useEffect(() => {
    if (!selectedPreset && useCase) {
      const useCaseToPresetMap: CampaignUseCase[] = [
        'USER_GENERATED_CONTENT',
        'PRODUCT_SEEDING',
        platform === 'TIKTOK' ? 'TIKTOK_SPARK_ADS' : 'META_PARTNERSHIP_ADS',
        'TIKTOK_SHOP',
        'INFLUENCER_POSTS',
        'AFFILIATE_CAMPAIGN',
      ];
      const presetIdx = useCaseToPresetMap.indexOf(useCase);
      const sequence = insenseSequencePresets[presetIdx];
      if (sequence) {
        const steps = sequence.node?.steps?.edges?.map((step) => {
          return {
            ...step?.node,
            validTemplate: true,
          };
        });
        setSelectedPreset(sequence.node.name);
        setSequenceList(steps);
      }
    }
  }, [useCase, platform]);

  const handleSequenceClick = () => {
    openDrawer('sequence-scratch');
  };

  const handleCloseScratchDrawer = () => {
    closeDrawer('sequence-scratch');
  };

  const handleCreateNewTemplate = () => {
    setSequenceId(null);
    setSelectedPreset(null);
    setSequenceList([sequenceDefaultEmptyStep]);
    setActiveStep(0);
    closeDrawer('sequence-scratch');
  };

  const renderList = (source: NonNullable<SequenceList>) => {
    return source.map((item) => {
      if (!item?.node || !item.node.name.toLowerCase().includes(filterTextValue)) return null;

      const { id, name } = item.node;

      const isActive = sequenceId ? sequenceId === id : selectedPreset === name;

      const handleTemplateSelect = () => {
        amplitude.sendEvent<411>({
          id: '411',
          category: 'campaign',
          name: 'outreach_sequence_click',
          param: { sequence_name: name },
        });
        const steps = item.node?.steps?.edges?.map((step) => {
          return {
            ...step?.node,
            validTemplate: true,
          };
        });
        if (id) {
          setSequenceId(id);
          setSelectedPreset(null);
        } else {
          setSequenceId(null);
          setSelectedPreset(item?.node?.name || null);
        }
        if (steps) {
          setSequenceList(steps);
        }
        setActiveStep(0);
        setTemplateChangedStatus(false);
      };
      return (
        <DropdownGroupItem key={item.node.id}>
          <AlterButton
            text={item.node.name}
            onClick={handleTemplateSelect}
            fluid
            iconPosition="right"
            icon={isActive ? 'Check' : null}
          />
        </DropdownGroupItem>
      );
    });
  };

  const isStepValidated = selectedPreset || sequenceId;

  return (
    <div className={localStyles.root}>
      <div className={styles.subtitle}>
        {isStepValidated ? (
          <Icon name="Check-circle" color="green" size={20} />
        ) : (
          <div className={styles.circle} />
        )}
        <Text type="md" msg="invite_outreach_creators_modal.step_2" />
      </div>
      <Dropdown
        closeBySelect={false}
        value={
          <Text
            type="md"
            className={classNames(styles.dropdownValue, {
              [styles.placeholder]: !sequenceId && !selectedPreset,
            })}
            text={selectedPreset || sequenceName}
            textPriority
            msg={'invite_outreach_creators_modal.template.placeholder'}
          />
        }
        className={styles.dropdownSettings}
      >
        <DropdownGroup className={styles.dropdownMenu}>
          <div className={styles.filterContainer}>
            <Icon name="Search-loop" size={20} />
            <Input
              type="text"
              placeholderMsg="search"
              onChange={handleFilterTextChange}
              value={filterText}
              hideCloseIcon
              borderless
            />
          </div>
          <Accordion
            titleClassName={localStyles.accordionMenu}
            contentClassName={localStyles.accordionContent}
            items={[
              {
                title: (
                  <Text
                    type="label"
                    className={styles.dropdownGroupLabel}
                    msg={'invite_outreach_creators_modal.insense_templates'}
                  />
                ),
                openedByDefault: true,
                content: renderList(insenseSequencePresets),
              },
            ]}
          />
          <Accordion
            titleClassName={localStyles.accordionMenu}
            contentClassName={localStyles.accordionContent}
            items={[
              {
                title: (
                  <Text
                    type="label"
                    className={styles.dropdownGroupLabel}
                    msg={'invite_outreach_creators_modal.your_templates'}
                  />
                ),
                openedByDefault: true,
                content: renderList(userTemplates),
              },
            ]}
          />
          {Boolean(isAdmin && isAdminMakeOutreachForClient) && (
            <Accordion
              titleClassName={localStyles.accordionMenu}
              contentClassName={localStyles.accordionContent}
              items={[
                {
                  title: (
                    <Text
                      type="label"
                      className={styles.dropdownGroupLabel}
                      msg={'invite_outreach_creators_modal.admin_templates'}
                    />
                  ),
                  openedByDefault: true,
                  content: renderList(templates),
                },
              ]}
            />
          )}
          <AlterButton
            type="white"
            icon="Add-plus-circle"
            iconPosition="right"
            msg="invite_outreach_creators_modal.add_sequence"
            classes={{
              buttonText: styles.addListButton,
            }}
            onClick={handleSequenceClick}
          />
        </DropdownGroup>
      </Dropdown>
      <SequenceResetConfirmDrawer
        actionType="scratch"
        rootKey="sequence-scratch"
        onCancel={handleCloseScratchDrawer}
        onConfirm={handleCreateNewTemplate}
      />
    </div>
  );
};

export default SequenceSelector;
