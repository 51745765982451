/**
 * @generated SignedSource<<8982436afee93cde182e643dfcba0b10>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Payment_PaymentMethodType = "CARD" | "CARDPAY" | "GAMEMONEY" | "PAYPAL" | "QIWI" | "WIRE" | "ZELLE";
export type Payment_PaymentServiceProvider = "QIWI" | "UNLIMINT";
export type Payment_WithdrawalStatus = "CANCELED" | "CONFIRMED" | "FAILED" | "PROCESSING" | "UNSENT";
import { FragmentRefs } from "relay-runtime";
export type PaymentServiceProviders_psp$data = {
  readonly id: string;
  readonly paymentMethod: {
    readonly type: Payment_PaymentMethodType | null | undefined;
  } | null | undefined;
  readonly paymentServiceProvider: Payment_PaymentServiceProvider | null | undefined;
  readonly status: Payment_WithdrawalStatus;
  readonly " $fragmentType": "PaymentServiceProviders_psp";
};
export type PaymentServiceProviders_psp$key = {
  readonly " $data"?: PaymentServiceProviders_psp$data;
  readonly " $fragmentSpreads": FragmentRefs<"PaymentServiceProviders_psp">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PaymentServiceProviders_psp",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "paymentMethod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentServiceProvider",
      "storageKey": null
    }
  ],
  "type": "Payment_Withdrawal",
  "abstractKey": null
};

(node as any).hash = "241a86cae9da6fad106d2f525478d71b";

export default node;
