import React from 'react';
import classnames from 'classnames';

import styles from './TooltipForCopyText.css';

import { copyText } from 'Util/copyText';
import Text from 'Atoms/Text/Text';
import TextButton from 'Atoms/TextButton/TextButton';

type PlaceTypes = 'top' | 'bottom';

interface Props {
  items: string[];
  position?: string;
  place?: PlaceTypes;
}

const TooltipForCopyText: React.FC<Props> = (props) => {
  const { children, items, position, place } = props;

  const createTooltip = () => {
    const positionStyle = position ? styles[position] : '';
    const placeStyle = place ? styles[`place-${place}`] : styles['place-top'];

    const classes = classnames(styles.tooltip, placeStyle, positionStyle);

    return (
      <div className={classes}>
        <div>
          {items.map((item) => {
            if (!item) return null;

            const handleCopyClick = () => {
              copyText(item);
            };

            return (
              <div key={item} className={styles.textWrap}>
                <Text
                  text={item}
                  className={styles.textItem}
                  data-test="tooltipForCopyText:text:textItem"
                />
                <TextButton
                  msg="general.copy"
                  onClick={handleCopyClick}
                  data-test="tooltipForCopyText:textButton:copy"
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.root}>
      {createTooltip()}
      {children}
    </div>
  );
};

export default TooltipForCopyText;
