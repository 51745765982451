/**
 * @generated SignedSource<<33d3c40e67354b2e14a6d020167594cd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CreatorType = "CONTENT" | "INSTAGRAM" | "TIKTOK" | "YOUTUBE";
export type Currency = "CAD" | "RUB" | "USD";
export type InfluencerType = "BLOGGER" | "BRAND" | "CELEBRITY" | "COMMUNITY";
import { FragmentRefs } from "relay-runtime";
export type ProfileSettings_creator$data = {
  readonly id: string;
  readonly ownership: {
    readonly createdAt: any;
    readonly owner: {
      readonly contractorProfile: {
        readonly creatorAgent: boolean;
        readonly id: string;
        readonly phoneNumber: string | null | undefined;
      } | null | undefined;
      readonly creators: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly channel?: {
              readonly name: string | null | undefined;
            } | null | undefined;
            readonly id: string;
            readonly profile: {
              readonly profilePictureFile: {
                readonly transformations?: {
                  readonly collageThumbnailUrl: string;
                } | null | undefined;
                readonly url: string | null | undefined;
              } | null | undefined;
            } | null | undefined;
            readonly type: CreatorType;
            readonly user?: {
              readonly name: string;
            } | null | undefined;
            readonly username: string;
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
      readonly email: string;
    };
  } | null | undefined;
  readonly paidSocialEnabled?: boolean;
  readonly paidSocialRightsVerification?: {
    readonly success: boolean;
    readonly verifiedAt: any;
  } | null | undefined;
  readonly profile: {
    readonly acceptsBarter: boolean;
    readonly birthdate: any | null | undefined;
    readonly categories: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly primary: boolean | null | undefined;
    }>;
    readonly cities: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
    readonly contactEmail: string | null | undefined;
    readonly currency: Currency | null | undefined;
    readonly customPrice: number | null | undefined;
    readonly estimatedPrice: number | null | undefined;
    readonly gender: {
      readonly id: string;
    } | null | undefined;
    readonly id: string;
    readonly influencerType: InfluencerType | null | undefined;
    readonly profilePictureFile: {
      readonly transformations?: {
        readonly collageThumbnailUrl: string;
      } | null | undefined;
      readonly url: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly type: CreatorType;
  readonly username: string;
  readonly " $fragmentType": "ProfileSettings_creator";
};
export type ProfileSettings_creator$key = {
  readonly " $data"?: ProfileSettings_creator$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileSettings_creator">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "profilePictureFile",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ImageTransformation",
          "kind": "LinkedField",
          "name": "transformations",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "collageThumbnailUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "Image",
      "abstractKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  (v4/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileSettings_creator",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CreatorOwnership",
      "kind": "LinkedField",
      "name": "ownership",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "owner",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ContractorProfile",
              "kind": "LinkedField",
              "name": "contractorProfile",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "phoneNumber",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "creatorAgent",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "CreatorConnection",
              "kind": "LinkedField",
              "name": "creators",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CreatorEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "CreatorProfile",
                          "kind": "LinkedField",
                          "name": "profile",
                          "plural": false,
                          "selections": [
                            (v3/*: any*/)
                          ],
                          "storageKey": null
                        },
                        (v2/*: any*/),
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "YoutubeChannel",
                              "kind": "LinkedField",
                              "name": "channel",
                              "plural": false,
                              "selections": (v5/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "type": "YoutubeCreator",
                          "abstractKey": null
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "TiktokUser",
                              "kind": "LinkedField",
                              "name": "user",
                              "plural": false,
                              "selections": (v5/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "type": "TiktokCreator",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CreatorProfile",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "acceptsBarter",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "birthdate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Gender",
          "kind": "LinkedField",
          "name": "gender",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "influencerType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "City",
          "kind": "LinkedField",
          "name": "cities",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": null
        },
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customPrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contactEmail",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "estimatedPrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Category",
          "kind": "LinkedField",
          "name": "categories",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "primary",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paidSocialEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PaidSocialRightsVerification",
          "kind": "LinkedField",
          "name": "paidSocialRightsVerification",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "verifiedAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "success",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "InstagramCreator",
      "abstractKey": null
    }
  ],
  "type": "Creator",
  "abstractKey": "__isCreator"
};
})();

(node as any).hash = "b46185b19e632d4e746ba312b3ea4c05";

export default node;
