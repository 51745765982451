import React from 'react';
import classnames from 'classnames';

import TooltipInfo from '../TooltipInfo/TooltipInfo';

import styles from './CreatorCostList.pcss';

import Text from 'Atoms/Text/Text';
import Icon from 'Components/ui/Icon/Icon';

interface Props {
  className?: string;
  items: CreatorCostItem[];
  tooltipInfoProps?: Pick<Partial<TooltipInfoProps>, 'color'>;
  colorTheme?: 'default' | 'sky' | 'pink' | 'dark' | 'sun' | 'ocean';
}

const CreatorCostList: React.FC<Props> = (props) => {
  const { className, items, tooltipInfoProps, colorTheme = 'default' } = props;

  if (!items?.length) return null;

  return (
    <div className={classnames(styles.root, className)}>
      <Text
        msg="billing_plans.creator_cost.title"
        color="inherit"
        className={styles.title}
        data-test="creatorCostList:text:title"
      />
      <ul className={styles.list}>
        {items.map((item, index) => {
          const { el, title, values, additionalTitle } = item;

          return (
            <li key={index} className={styles.item}>
              <Icon name="Info" className={classnames(styles.infoIcon, styles[colorTheme])} />
              <div>
                {el}
                {title && (
                  <Text
                    msg={title}
                    values={values}
                    display="inline"
                    color="inherit"
                    data-test="creatorCostList:text:unknown"
                  />
                )}
                {additionalTitle && (
                  <Text
                    msg={additionalTitle}
                    display="inline"
                    data-test="creatorCostList:text:unknown"
                  />
                )}
                {(item?.tooltipProps?.tooltipMsg || item?.tooltipProps?.getContent) && (
                  <TooltipInfo
                    color={tooltipInfoProps?.color}
                    className={styles.tooltipInfo}
                    id={item?.tooltipProps?.id}
                    tooltipMsg={item?.tooltipProps?.tooltipMsg}
                    tooltipValues={item?.tooltipProps?.tooltipValues}
                    getContent={item?.tooltipProps?.getContent}
                    clickable={item?.tooltipProps?.clickable}
                    type={colorTheme === 'dark' ? 'light' : undefined}
                  />
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CreatorCostList;

// types

export type { Props as CreatorCostListProps };

type CreatorCostItem = {
  title?: string;
  values?: any;
  additionalTitle?: string;
  el?: JSX.Element;
  tooltipProps?: {
    tooltipMsg?: string;
    id?: TooltipInfoProps['id'];
    tooltipValues?: TooltipInfoProps['tooltipValues'];
    tooltipClassName?: string;
    getContent?: TooltipInfoProps['getContent'];
    clickable?: TooltipInfoProps['clickable'];
  };
};

type TooltipInfoProps = React.ComponentProps<typeof TooltipInfo>;
