import React, { useEffect, useContext } from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';
import { useParams, useNavigate } from 'react-router-dom';

import CampaignContainer from './CampaignContainer/CampaignContainer';
import ChatDrawerProvider from './ChatDrawer/ChatDrawer.Provider';
import CampaignProvider from './Campaign.Provider';
import { HIRED, RECIEVED_APPLICANTS } from './NewCampaignTabs/util';

import { amplitude } from 'Helpers/amplitude';
import { getCampaignTypeLink } from 'Util/links';
import Page from 'Templates/Page/Page';
import Spinner from 'Atoms/Spinner/Spinner';
import { BRIEF, CREATORS, APPLICANTS, COMPLETED } from 'Constants/general';
import { CampaignQuery as QueryType } from 'GraphTypes/CampaignQuery.graphql';
import { trackTime as custifyTrackTime } from 'Util/custify';
import { FeatureFlagsContext } from 'Containers/FeatureFlags/FeatureFlags.Context';

const CampaignQuery = graphql`
  query CampaignQuery($id: ID!) {
    currentUser {
      id
      admin
      permissions
      email
      organization {
        id
        paymentAccount {
          currency
        }
        subscription {
          planId
          effectiveLimits {
            maxBcaConnections
            maxHiredCreators
          }
          canCreateFacebookAds
          canBrowseCreators
          canUsePaidSocial
          canViewCreatorPrices
          canChatWithCreators
          canCreateMultipleDeals
          canFilterCreatorsByBadge
          canUseApprovalTool
          canManageLonglists
        }
      }
    }
    campaigns {
      totalCount
    }
    campaign(id: $id) {
      id
      name
      type
      platform
      preferredContentType
      preferredCreatorCategory
      publishingRequired
      briefSubmission {
        acceptance {
          id
        }
      }
      counters {
        launchedProjects
      }
      completion {
        id
      }
      organization {
        id
      }
      creator {
        id
        admin
        permissions
        organization {
          paymentAccount {
            currency
          }
          subscription {
            planId
            effectiveLimits {
              maxBcaConnections
            }
            canCreateFacebookAds
            canBrowseCreators
            canUsePaidSocial
            canViewCreatorPrices
            canChatWithCreators
            canCreateMultipleDeals
            canFilterCreatorsByBadge
            canUseApprovalTool
            canManageLonglists
          }
        }
      }
      ...CampaignInfo_campaign
    }
  }
`;

const Campaign: React.FC = () => {
  const navigate = useNavigate();
  const { id = '', type } = useParams<{ id: string; type?: string }>();

  useEffect(() => {
    custifyTrackTime('Campaigns');

    if (type) {
      amplitude.sendEvent<524>({
        id: '524',
        category: 'campaign_page',
        name: 'pageview',
        param: { section: type, campaign_id: id },
      });
    }

    return () => {
      _ctrack.stopTrackTime();
    };
  }, []);

  if (!id) return null;

  const data = useLazyLoadQuery<QueryType>(CampaignQuery, { id });

  const { hasFeatureFlag } = useContext(FeatureFlagsContext);
  const newCampaignTabs = hasFeatureFlag('new_campaign_tabs');

  useEffect(() => {
    if (!data.campaign?.briefSubmission && data.campaign?.id) {
      const link = getCampaignTypeLink({ campaignId: data.campaign?.id, path: BRIEF });
      navigate(link, { replace: true });
    }
  }, [data.campaign?.briefSubmission, data.campaign?.id]);

  if (!data) return <Spinner style={{ margin: '40px auto' }} />;

  const admin = data.currentUser?.admin;
  const campaign = data.campaign;

  const user = data.currentUser;
  const creator = admin ? data.campaign?.creator : data.currentUser;
  const campaignsCount = data.campaigns?.totalCount;
  const email = user?.email;

  const publishingRequired = campaign?.publishingRequired;
  const launchedProjects = campaign?.counters?.launchedProjects;
  const isCompleted = campaign?.completion?.id;

  const organizationId = campaign?.organization?.id;

  let defaultType;

  if (newCampaignTabs) {
    defaultType = isCompleted ? COMPLETED : launchedProjects ? HIRED : RECIEVED_APPLICANTS;
  } else {
    defaultType = isCompleted ? COMPLETED : launchedProjects ? CREATORS : APPLICANTS;
  }

  return (
    <Page>
      <CampaignProvider campaignId={id}>
        <ChatDrawerProvider campaignId={id}>
          <CampaignContainer
            campaign={campaign}
            admin={!!admin}
            user={user}
            defaultType={defaultType}
            creator={creator}
            email={email}
            campaignsCount={campaignsCount}
            publishingRequired={publishingRequired}
            organizationId={organizationId}
          />
        </ChatDrawerProvider>
      </CampaignProvider>
    </Page>
  );
};

export default Campaign;
