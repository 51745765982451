import React, { useContext } from 'react';
import { graphql, useFragment } from 'react-relay';
import { useLocation } from 'react-router-dom';

import styles from './ProjectCampaignInfo.pcss';

import { getCampaignTypeLink } from 'Util/links';
import { getPlatformIcon } from 'Util/icons';
import { getOrganizationLink } from 'Util/links';
import AvatarItem from 'Molecules/AvatarItem/AvatarItem';
import SimpleLink from 'Components/SimpleLink/SimpleLink';
import TextButton from 'Atoms/TextButton/TextButton';
import Button from 'Atoms/Button/Button';
import Text from 'Atoms/Text/Text';
import Icon from 'Atoms/Icon/Icon';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import { ProjectCampaignInfo_project$key } from 'GraphTypes/ProjectCampaignInfo_project.graphql';
import { FeatureFlagsContext } from 'Containers/FeatureFlags/FeatureFlags.Context';
import {
  ARCHIVED,
  COMPLETED,
  CONTENT_CREATION,
  DECLINED,
  INVITATION_SENT,
  MESSAGED,
  RECIEVED_APPLICANTS,
} from 'Page/advertiser/Campaign/NewCampaignTabs/util';

interface Props {
  admin: boolean;
  project: ProjectCampaignInfo_project$key;
}

const ProjectCampaignInfo = (props: Props) => {
  const { admin, project } = props;

  const { state: locationState } = useLocation();

  const { hasFeatureFlag } = useContext(FeatureFlagsContext);
  const newCampaignTabs = hasFeatureFlag('new_campaign_tabs');

  const data = useFragment(
    graphql`
      fragment ProjectCampaignInfo_project on Project {
        id
        archivation {
          type
        }
        offer {
          acceptance {
            id
          }
        }
        launch {
          id
        }
        completion {
          id
        }
        hasLaunchOrBrandMessage
        campaign {
          id
          name
          platform
          brand {
            name
            logo {
              ... on Image {
                transformations {
                  brandLogoUrl
                }
              }
            }
          }
          organization {
            id
            name
          }
        }
      }
    `,
    project
  );

  const campaign = data?.campaign;
  const organization = campaign?.organization;
  const hasAcceptance = !!data?.offer?.acceptance?.id;
  const hasLaunch = !!data?.launch?.id;
  const hasCompletion = !!data?.completion?.id;
  const hasLaunchOrBrandMessage = !!data?.hasLaunchOrBrandMessage;
  const archivationType = data.archivation?.type;

  const campaignId = campaign?.id;
  const campaignName = campaign?.name;
  const campaignPlatform = campaign?.platform;
  const brandLogo = campaign?.brand?.logo?.transformations?.brandLogoUrl;
  const brandName = campaign?.brand?.name || '';

  const organizationId = organization?.id || '';
  const organizationName = organization?.name || '';

  const orgStyles =
    brandName.length + organizationName.length > 38
      ? styles.organization
      : styles.organizationBefore;

  const organizationLink = getOrganizationLink({ organizationId });

  const subtitleEl = (
    <div className={styles.campaignInfo}>
      <Text size="sm" text={brandName} data-test="projectCampaignInfo:text:unknown" />
      {admin && (
        <SimpleLink href={organizationLink} target="_blank" className={orgStyles}>
          <TextButton
            className={styles.inlineTextBtn}
            size="sm"
            text={organizationName}
            data-test="projectCampaignInfo:textButton:inlineTextBtn"
          />
        </SimpleLink>
      )}
    </div>
  );

  const platformIcon = getPlatformIcon(campaignPlatform?.toLowerCase());

  const avaEl = (
    <div className={styles.platform}>
      {platformIcon && <Icon name={platformIcon} className={styles.platformIcon} />}
    </div>
  );

  const getBackLink = () => {
    if (newCampaignTabs) {
      if (archivationType) {
        return getCampaignTypeLink({
          campaignId,
          path: archivationType === 'AFTER_LAUNCH' ? DECLINED : ARCHIVED,
        });
      }
      if (!hasAcceptance) {
        return getCampaignTypeLink({ campaignId, path: INVITATION_SENT });
      } else if (!hasLaunch) {
        return getCampaignTypeLink({
          campaignId,
          path: hasLaunchOrBrandMessage ? MESSAGED : RECIEVED_APPLICANTS,
        });
      } else if (!hasCompletion) {
        return getCampaignTypeLink({ campaignId, path: CONTENT_CREATION });
      } else {
        return getCampaignTypeLink({ campaignId, path: COMPLETED });
      }
    }
    return locationState?.tab
      ? `/campaigns/${campaignId}/${locationState.tab}`
      : `/campaigns/${campaignId}`;
  };

  return (
    <div className={styles.root}>
      <AvatarItem
        avaData={{ src: brandLogo, size: 40 }}
        avaEl={campaignPlatform ? avaEl : undefined}
        titleData={{ text: campaignName, weight: '500', overflow: true }}
        subtitleEl={subtitleEl}
        className={styles.details}
      />
      <SmartLink path={getBackLink()}>
        <Button
          color="normal"
          msg="chat.campaign_open"
          className={styles.openBtn}
          data-test="projectCampaignInfo:button:campaignOpen"
        />
      </SmartLink>
    </div>
  );
};

export default ProjectCampaignInfo;
