import React, { useMemo, useState } from 'react';
import { fetchQuery, graphql } from 'relay-runtime';
import find from 'lodash/find';

import styles from './UserDeletion.pcss';

import environment from 'Api/Environment';
import { checkEmailValid } from 'Util/validate';
import deleteUser from 'Mutations/DeleteUser.Mutation';
import Input from 'Atoms/Input/Input';
import Button from 'Atoms/Button/Button';
import Text from 'Atoms/Text/Text';
import { UserDeletionQuery as QueryType } from 'GraphTypes/UserDeletionQuery.graphql';

const query = graphql`
  query UserDeletionQuery($textQuery: String!) {
    users(textQuery: $textQuery, types: [CONTRACTOR]) {
      edges {
        node {
          id
          email
        }
      }
    }
  }
`;

const UserDeletion = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (newEmail: string) => {
    setEmail(newEmail);
  };

  const handleClick = () => {
    const isConfirmed = confirm(`Do you want to delete user: ${email}?`);

    if (isConfirmed) {
      setLoading(true);
      fetchQuery<QueryType>(environment, query, { textQuery: email }).subscribe({
        next: (result) => {
          const resultItem = find(result?.users?.edges, (item) => item?.node?.email === email);
          if (resultItem?.node?.id) {
            deleteUser({ userId: resultItem?.node?.id }, handleComplete, handleReject);
          } else {
            setLoading(false);
            alert('User not found');
          }
        },
      });
    }
  };

  const handleComplete = () => {
    setLoading(false);
    alert('Success!');
  };

  const handleReject = (data: any) => {
    const errorText = data && data.error;
    setLoading(false);

    const text = errorText ? errorText.replace(/_:/g, ' ').trim() : 'Something went wrong!';
    alert(text);
  };

  const emailValid = useMemo(() => {
    return checkEmailValid(email);
  }, [email]);

  return (
    <div className={styles.root}>
      <Text
        tag="h2"
        weight="700"
        msg="admin.helpers.section.user_deletion.title"
        className={styles.title}
        data-test="userDeletion:text:title"
      />
      <div className={styles.content}>
        <div className={styles.input}>
          <Input placeholder="Email" onChange={handleChange} data-test="userDeletion:input:email" />
        </div>
        <Button
          text="Delete"
          loading={loading}
          disabled={!emailValid}
          className={styles.submit}
          onClick={handleClick}
          data-test="userDeletion:button:delete"
        />
      </div>
    </div>
  );
};

export default UserDeletion;
