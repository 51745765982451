import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import styles from './CreateTikTokProfile.pcss';

import Text from 'Components/ui/Text/Text';
import Spinner from 'Atoms/Spinner/Spinner';
import { PostMessageTypeEnum, useTikTokOpenApi } from 'Hooks/useTikTokOpenApi';

type PostMessage = (params: {
  creatorId?: string;
  error: boolean;
  postMessageType: PostMessageTypeEnum;
  username: string;
}) => void;
const postMessage: PostMessage = (params) => {
  const { creatorId, postMessageType, error, username } = params;
  if (window.opener) {
    window.opener.postMessage(
      {
        username,
        creatorId,
        error,
        type: postMessageType,
      },
      window.location.origin
    );
  }
  if (!error) {
    // Wait to send postMessages
    setTimeout(() => {
      window.close();
    }, 500);
  }
};

const CreateTikTokProfile = () => {
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('page.create_tik_tok_profile.error_msg');
  const [userNameToCheckAfterAuthorization, setUserNameToCheckAfterAuthorization] = useState('');
  const location = useLocation();
  const params = useParams();
  const { authorizeTiktokAds, isFullScope } = useTikTokOpenApi();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');
    const error = searchParams.get('error');
    const scope = searchParams.get('scopes');

    if (error || !isFullScope(scope || '')) {
      setHasError(true);
      postMessage({
        creatorId: '',
        error: true,
        postMessageType: PostMessageTypeEnum.CreateTikTokProfile,
        username: '',
      });
    } else {
      authorizeTiktokAds({ code })
        .then((data) => {
          setHasError(false);
          const creatorId = data?.authorizeTiktokAds?.creatorOwnership?.creator?.id || '';
          const username = data?.authorizeTiktokAds?.creatorOwnership?.creator?.username || '';
          if (params?.username && params?.username !== username) {
            setHasError(true);
            setErrorText('add_tiktok_profile_modal.username.error.usernameMismatch');
            setUserNameToCheckAfterAuthorization(params.username);
            postMessage({
              error: true,
              creatorId: '',
              username: '',
              postMessageType: PostMessageTypeEnum.CreateTikTokProfile,
            });
            return;
          }
          postMessage({
            creatorId,
            username,
            error: false,
            postMessageType: PostMessageTypeEnum.CreateTikTokProfile,
          });
        })
        .catch((error) => {
          setHasError(true);
          const errorMsg = error[0] && error[0].message;
          if (errorMsg === ':no_influencer_ownership') {
            setErrorText('add_tiktok_profile_modal.username.error.reserved');
          }
          postMessage({
            creatorId: '',
            error: true,
            postMessageType: PostMessageTypeEnum.CreateTikTokProfile,
            username: '',
          });
        });
    }
  }, []);

  if (hasError) {
    return (
      <div className={styles.root}>
        <Text
          msg={errorText}
          formatValues={{
            userName: <b className={styles.strongText}>{userNameToCheckAfterAuthorization}</b>,
          }}
          className={styles.errorText}
          data-test="createTikTokProfile:text:errorText"
        />
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <Spinner />
    </div>
  );
};

export default CreateTikTokProfile;

type EventHandler = Parameters<typeof window.addEventListener<'message'>>[1];
