/**
 * @generated SignedSource<<be6bb137216c54fd5f8510ecfc41c78d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FileBcaTaggingStatus = "FAILED" | "PROCESSED" | "PROCESSING";
export type FileProcessingStatus = "FAILED" | "PROCESSED" | "PROCESSING";
export type FileType = "IMAGE" | "OTHER" | "VIDEO";
export type CreateGcsFileInput = {
  clientMutationId?: string | null | undefined;
  duration?: number | null | undefined;
  filename: string;
  gcsBucketName?: string | null | undefined;
  height?: number | null | undefined;
  id?: string | null | undefined;
  originalFilename?: string | null | undefined;
  type: FileType;
  width?: number | null | undefined;
};
export type CreateGcsFileMutation$variables = {
  input: CreateGcsFileInput;
};
export type CreateGcsFileMutation$data = {
  readonly createGcsFile: {
    readonly file?: {
      readonly bcaTaggedUrl: string | null | undefined;
      readonly bcaTaggingStatus: FileBcaTaggingStatus | null | undefined;
      readonly bytes: number | null | undefined;
      readonly createdAt: any;
      readonly duration: number | null | undefined;
      readonly filename: string | null | undefined;
      readonly height: number | null | undefined;
      readonly id: string;
      readonly originalFilename: string | null | undefined;
      readonly processingStatus?: FileProcessingStatus | null | undefined;
      readonly publicId: string | null | undefined;
      readonly secureUrl: string;
      readonly thumbnailUrl: string;
      readonly transformations?: {
        readonly autoUrl?: string;
        readonly brandLogoUrl: string;
        readonly collageThumbnailUrl: string;
      } | null | undefined;
      readonly type: FileType;
      readonly width: number | null | undefined;
    };
  } | null | undefined;
};
export type CreateGcsFileMutation = {
  response: CreateGcsFileMutation$data;
  variables: CreateGcsFileMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "filename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "originalFilename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duration",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "secureUrl",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnailUrl",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "width",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "height",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bcaTaggedUrl",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bcaTaggingStatus",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bytes",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publicId",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "collageThumbnailUrl",
  "storageKey": null
},
v17 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ImageTransformation",
      "kind": "LinkedField",
      "name": "transformations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "brandLogoUrl",
          "storageKey": null
        },
        (v16/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Image",
  "abstractKey": null
},
v18 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "processingStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VideoTransformation",
      "kind": "LinkedField",
      "name": "transformations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "autoUrl",
          "storageKey": null
        },
        (v16/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Video",
  "abstractKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateGcsFileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createGcsFile",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "file",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "CreateGcsFilePayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateGcsFileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createGcsFile",
        "plural": false,
        "selections": [
          (v19/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "file",
                "plural": false,
                "selections": [
                  (v19/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "CreateGcsFilePayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6047598381de02bdc4075b8e9eb3027a",
    "id": null,
    "metadata": {},
    "name": "CreateGcsFileMutation",
    "operationKind": "mutation",
    "text": "mutation CreateGcsFileMutation(\n  $input: CreateGcsFileInput!\n) {\n  createGcsFile(input: $input) {\n    __typename\n    ... on CreateGcsFilePayload {\n      file {\n        __typename\n        id\n        createdAt\n        filename\n        originalFilename\n        duration\n        secureUrl\n        thumbnailUrl\n        width\n        height\n        type\n        bcaTaggedUrl\n        bcaTaggingStatus\n        bytes\n        publicId\n        ... on Image {\n          transformations {\n            brandLogoUrl\n            collageThumbnailUrl\n          }\n        }\n        ... on Video {\n          processingStatus\n          transformations {\n            autoUrl\n            collageThumbnailUrl\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3f2fc2f10e304417bf2a1790f578fed9";

export default node;
