import React, { memo } from 'react';
import { graphql, useFragment } from 'react-relay';
import moment from 'moment';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import styles from './OutreachSequenceList.pcss';

import Text from 'Components/ui/Text/Text';
import Tag from 'Components/ui/Tag/Tag';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { OutreachSequenceList_steps$key } from 'GraphTypes/OutreachSequenceList_steps.graphql';
import { Color, IconName } from 'Components/ui/types';
import {
  OutreachBatchState,
  OutreachSequenceList_steps$data,
} from 'GraphTypes/OutreachSequenceList_steps.graphql';
import { OUTREACH_BATCH_VIEW_ITEM_ROUTE } from 'Constants/general';

interface Props {
  batch?: OutreachSequenceList_steps$key | null;
}

interface StatusType {
  color: Color;
  icon: IconName;
  msg?: string;
}

type SequenceStep = NonNullable<
  NonNullable<
    NonNullable<NonNullable<OutreachSequenceList_steps$data['sequenceBlueprint']>['steps']>['edges']
  >[number]
>['node'];

type StepStatus = 'PROCESSING' | 'SCHEDULED' | Exclude<OutreachBatchState, 'ACTIVE'>;

const statusToTagMap: Record<StepStatus, StatusType> = {
  PROCESSING: {
    color: 'grass',
    icon: 'Dots-menu',
    msg: 'campaign.outreach.state.processing',
  },
  COMPLETED: {
    color: 'ocean',
    icon: 'Check',
    msg: 'campaign.outreach.state.completed',
  },
  DISCONNECTED: {
    color: 'sun',
    icon: 'Lock',
    msg: 'campaign.outreach.state.stopped',
  },
  SCHEDULED: {
    color: 'grey',
    icon: 'Timer',
  },
  STOPPED: {
    color: 'sun',
    icon: 'Lock',
    msg: 'campaign.outreach.state.stopped',
  },
  INITIAL: {
    color: 'white',
    icon: 'Timer',
    msg: 'campaign.outreach.state.initial',
  },
};

const OutreachSequenceList: React.FC<Props> = (props) => {
  const { batch } = props;
  const { id: campaignId } = useParams();
  const navigate = useNavigate();
  const fragment = useFragment(
    graphql`
      fragment OutreachSequenceList_steps on OutreachBatch {
        id
        state
        createdAt
        sequenceBlueprint {
          name
          steps {
            edges {
              node {
                id
                delayInDays
              }
            }
          }
        }
      }
    `,
    batch
  );

  const steps = fragment?.sequenceBlueprint?.steps?.edges;
  const now = moment();
  const startSequenceDate = moment(fragment?.createdAt);

  const getStepStatus = (step: SequenceStep) => {
    if (!fragment?.state || !step) return null;
    if (fragment.state === 'ACTIVE') {
      const startDay = startSequenceDate.add(step.delayInDays, 'd');
      const isStarted = startDay.isBefore(now);
      if (!isStarted) {
        return {
          ...statusToTagMap.SCHEDULED,
          text: moment(startDay, 'd').fromNow(),
        };
      }
      const isCompleted = startDay.isAfter(now);
      if (isCompleted) {
        return statusToTagMap.COMPLETED;
      }
      return statusToTagMap.PROCESSING;
    }
    if (statusToTagMap[fragment?.state]) {
      return statusToTagMap[fragment.state];
    }
    return statusToTagMap.INITIAL;
  };

  const handleOpenPreview = (index: number) => {
    const batchId = fragment?.id;
    if (!batchId || !campaignId) return;
    navigate(
      generatePath(OUTREACH_BATCH_VIEW_ITEM_ROUTE, {
        index: index.toString(),
        batchId,
        id: campaignId,
      })
    );
  };

  if (!steps || !steps.length) {
    return null;
  }

  return (
    <div className={styles.root}>
      {steps.map((step, index) => {
        if (!step?.node) return null;
        return (
          <div key={step.node.id} className={styles.row}>
            <Text
              type="md"
              msg={index === 0 ? 'campaign.outreach.steps.first' : 'campaign.outreach.steps.next'}
              formatValues={{ index }}
              className={styles.stepTitleText}
            />
            <div />
            <div />
            <div />
            <div />
            <div />
            <Tag needMargin={false} className={styles.status} {...getStepStatus(step.node)} />
            <AlterButton icon="Eye" type="grey" onClick={() => handleOpenPreview(index)} />
          </div>
        );
      })}
    </div>
  );
};

export default memo(OutreachSequenceList);
