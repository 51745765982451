import React from 'react';
import { useParams } from 'react-router-dom';

import Brief from 'Templates/Brief/Brief';
import Page from 'Templates/Page/Page';

const Listing: React.FC = () => {
  const { listingId } = useParams<{ listingId: string }>();

  return (
    <Page>
      <Brief listingId={listingId} />
    </Page>
  );
};

export default Listing;
