/**
 * @generated SignedSource<<54120773e09cdbdc9b595b0ff8692f75>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AdvertiserProjectStage = "ACCEPTED" | "ACCEPTED_AND_NOT_LAUNCHED" | "ACCEPTED_BY_UNREVIEWED_CREATOR" | "COMPLETED" | "CONTENT_SUBMITTED" | "LAUNCHED" | "PRODUCT_SHIPMENT_COMPLETED" | "PRODUCT_SHIPMENT_STARTED" | "PUBLISHING_STARTED" | "READ" | "REJECTED" | "UNREAD" | "UNSENT";
export type CampaignPlatform = "FACEBOOK" | "INSTAGRAM" | "OTHER" | "SNAPCHAT" | "TIKTOK" | "YOUTUBE";
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING";
export type CampaignUseCase = "AFFILIATE_CAMPAIGN" | "INFLUENCER_POSTS" | "META_PARTNERSHIP_ADS" | "PRODUCT_SEEDING" | "TIKTOK_SHOP" | "TIKTOK_SPARK_ADS" | "USER_GENERATED_CONTENT";
export type ContentReviewStatus = "FAILED" | "PENDING" | "SUCCESS";
export type CreatorType = "CONTENT" | "INSTAGRAM" | "TIKTOK" | "YOUTUBE";
export type PaymentType = "BARTER" | "MONEY";
export type PlanId = "ADVANCED" | "ADVANCED_2021_10" | "ADVANCED_2022_11" | "AGENCY_2024_03" | "BARTER" | "BASIC_OLD_PS" | "BRAND_2024_03" | "CONTENT_CREATION" | "ENTERPRISE" | "FREE" | "FREE_OLD_PS" | "FREE_TRIAL" | "FULLY_MANAGED" | "FULLY_MANAGED_1" | "FULLY_MANAGED_2" | "FULLY_MANAGED_TTCX_MMA" | "GROW" | "PAYOUT" | "PREMIUM" | "PREMIUM_PLUS" | "PUBLICIS" | "SCALE" | "SCALE_2021_06" | "SCALE_2021_10" | "SMARTLY_ENTERPRISE_2023_06" | "SMARTLY_ENTERPRISE_TRIAL_2023_06" | "TRIAL_2022_08" | "TRIAL_2024_03" | "TRIAL_MONTH" | "UGC_2022_08" | "UGC_ADS_2022_08" | "WILDJAM";
export type CreatorChatQuery$variables = {
  projectId: string;
};
export type CreatorChatQuery$data = {
  readonly currentUser: {
    readonly admin: boolean;
    readonly advertiserProfile: {
      readonly id: string;
    } | null | undefined;
    readonly organization: {
      readonly counters: {
        readonly bcaLicensedCreators: number;
        readonly hiredCreators: number;
        readonly psLicensedCreators: number;
      } | null | undefined;
      readonly subscription: {
        readonly effectiveLimits: {
          readonly maxBcaConnections: number | null | undefined;
          readonly maxHiredCreators: number | null | undefined;
          readonly maxLicensedCreators: number | null | undefined;
        };
        readonly planId: PlanId;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly project: {
    readonly adminComment: string | null | undefined;
    readonly advertiserStage: AdvertiserProjectStage | null | undefined;
    readonly bcaRequest: {
      readonly permission: {
        readonly id: string;
      } | null | undefined;
    } | null | undefined;
    readonly campaign: {
      readonly id: string;
      readonly paymentType: PaymentType | null | undefined;
      readonly platform: CampaignPlatform | null | undefined;
      readonly publishingRequired: boolean;
      readonly shopifyAuthorization: {
        readonly id: string;
      } | null | undefined;
      readonly showShipmentTab: boolean;
      readonly type: CampaignType | null | undefined;
      readonly useCase: CampaignUseCase | null | undefined;
    };
    readonly comment: string | null | undefined;
    readonly contentReview: {
      readonly status: ContentReviewStatus;
    } | null | undefined;
    readonly creator: {
      readonly insightsAuthorized?: boolean;
      readonly type: CreatorType;
      readonly user?: {
        readonly id: string;
      } | null | undefined;
    } | null | undefined;
    readonly launch: {
      readonly id: string;
    } | null | undefined;
    readonly price: number | null | undefined;
    readonly publicationEventExists: boolean;
  } | null | undefined;
};
export type CreatorChatQuery = {
  response: CreatorChatQuery$data;
  variables: CreatorChatQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "admin",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "AdvertiserProfile",
  "kind": "LinkedField",
  "name": "advertiserProfile",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "SubscriptionLimits",
  "kind": "LinkedField",
  "name": "effectiveLimits",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxHiredCreators",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxLicensedCreators",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxBcaConnections",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "planId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "OrganizationCounters",
  "kind": "LinkedField",
  "name": "counters",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hiredCreators",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "psLicensedCreators",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bcaLicensedCreators",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "adminComment",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "comment",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "advertiserStage",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publicationEventExists",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v16 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "insightsAuthorized",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InstagramUser",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    }
  ],
  "type": "InstagramCreator",
  "abstractKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "BcaPermission",
  "kind": "LinkedField",
  "name": "permission",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "Campaign",
  "kind": "LinkedField",
  "name": "campaign",
  "plural": false,
  "selections": [
    (v15/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "useCase",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "platform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showShipmentTab",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publishingRequired",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ShopifyAuthorization",
      "kind": "LinkedField",
      "name": "shopifyAuthorization",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectLaunch",
  "kind": "LinkedField",
  "name": "launch",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreatorChatQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvertiserSubscription",
                "kind": "LinkedField",
                "name": "subscription",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ContentReview",
            "kind": "LinkedField",
            "name": "contentReview",
            "plural": false,
            "selections": [
              (v14/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "creator",
            "plural": false,
            "selections": [
              (v15/*: any*/),
              (v16/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BcaRequest",
            "kind": "LinkedField",
            "name": "bcaRequest",
            "plural": false,
            "selections": [
              (v17/*: any*/)
            ],
            "storageKey": null
          },
          (v18/*: any*/),
          (v19/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreatorChatQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvertiserSubscription",
                "kind": "LinkedField",
                "name": "subscription",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ContentReview",
            "kind": "LinkedField",
            "name": "contentReview",
            "plural": false,
            "selections": [
              (v14/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "creator",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v15/*: any*/),
              (v16/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BcaRequest",
            "kind": "LinkedField",
            "name": "bcaRequest",
            "plural": false,
            "selections": [
              (v17/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v18/*: any*/),
          (v19/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e0df203fd136fc9f2ae980c6bb439c9d",
    "id": null,
    "metadata": {},
    "name": "CreatorChatQuery",
    "operationKind": "query",
    "text": "query CreatorChatQuery(\n  $projectId: ID!\n) {\n  currentUser {\n    admin\n    advertiserProfile {\n      id\n    }\n    organization {\n      subscription {\n        effectiveLimits {\n          maxHiredCreators\n          maxLicensedCreators\n          maxBcaConnections\n        }\n        planId\n        id\n      }\n      counters {\n        hiredCreators\n        psLicensedCreators\n        bcaLicensedCreators\n      }\n      id\n    }\n    id\n  }\n  project(id: $projectId) {\n    adminComment\n    comment\n    advertiserStage\n    publicationEventExists\n    price\n    contentReview {\n      status\n      id\n    }\n    creator {\n      __typename\n      type\n      ... on InstagramCreator {\n        insightsAuthorized\n        user {\n          id\n        }\n      }\n      id\n    }\n    bcaRequest {\n      permission {\n        id\n      }\n      id\n    }\n    campaign {\n      type\n      useCase\n      platform\n      paymentType\n      showShipmentTab\n      publishingRequired\n      id\n      shopifyAuthorization {\n        id\n      }\n    }\n    launch {\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b1543eb5b7284e637cad986cf7aa5484";

export default node;
