import React, { RefObject } from 'react';

import styles from './PortfolioItem.pcss';

import HoverVideoPlayer from 'Components/HoverVideoPlayer/HoverVideoPlayer';
import { FileType } from 'GraphTypes/PortfolioContentQuery.graphql';
import Tag from 'Components/ui/Tag/Tag';

type Props = {
  videoUrl?: string;
  thumbnailUrl?: string;
  type?: FileType;
  parentRef: RefObject<HTMLElement>;
  onClick: () => void;
};

const PortfolioItem: React.FC<Props> = (props) => {
  const { videoUrl, thumbnailUrl, type, parentRef, onClick } = props;
  return (
    <div onClick={onClick} className={styles.itemContainer}>
      <div className={styles.tag}>
        <Tag msg="creator.portfolio" color="dark" data-test="portfolioItem:tag:portfolio" />
      </div>
      {type === 'VIDEO' ? (
        <HoverVideoPlayer src={videoUrl} poster={thumbnailUrl} parentRef={parentRef} />
      ) : (
        <img src={thumbnailUrl} />
      )}
    </div>
  );
};

export default PortfolioItem;
