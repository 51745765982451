import React from 'react';
import classnames from 'classnames';

import styles from './Tabs.pcss';
import Tab, { Props as TabProps } from './Tab/Tab';

interface Props {
  className?: string;
  tabs: TabProps[];
}

const Tabs: React.FC<Props> = (props) => {
  const { tabs, className } = props;
  return (
    <div className={classnames(styles.root, className)}>
      {tabs.map((tab) => {
        const { id } = tab;
        return <Tab key={id} {...tab} />;
      })}
    </div>
  );
};

export default Tabs;
