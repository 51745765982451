import React from 'react';
import { graphql, useFragment } from 'react-relay';
import classnames from 'classnames';

import styles from './MessageMedia.pcss';
import MediaUploader from './MediaUploader/MediaUploader';

import { cutStringWithExt } from 'Util/textFormatter';
import { getReadableDuration } from 'Util/mediaTime';
import PopupLink from 'Components/PopupLink/PopupLink';
import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import { VIDEO } from 'Constants/general';

const PROCESSED = 'PROCESSED';

import { MessageMedia_file$key } from 'GraphTypes/MessageMedia_file.graphql';
import Spinner from 'Atoms/Spinner/Spinner';

interface Props {
  id: string;
  index: number;
  projectId: string;
  isRead: boolean;
  isOwner?: boolean;
  actionAble?: boolean;
  createdAt?: any;
  file: MessageMedia_file$key;
  mediasLength?: number;
  onMediaOpen: (index: number) => void;
}

const MessageMedia: React.FC<Props> = (props) => {
  const { actionAble, file, isOwner, createdAt, mediasLength, projectId, index, onMediaOpen } =
    props;

  const data = useFragment(
    graphql`
      fragment MessageMedia_file on File {
        id
        type
        width
        height
        bytes
        bcaTaggedUrl
        bcaTaggingStatus
        thumbnailUrl
        secureUrl
        originalFilename
        duration
        filename
        ... on Video {
          processingStatus
          transformations {
            autoUrl
            collageThumbnailUrl
          }
        }
        ... on Image {
          transformations {
            collageThumbnailUrl
          }
        }
      }
    `,
    file
  );

  if (!data) {
    return null;
  }
  const type = data.type;
  const filename = data.filename;
  const secureUrl = data.secureUrl;
  const bcaTaggingStatus = data.bcaTaggingStatus;
  const duration = data?.duration ? getReadableDuration(data.duration) : '';

  const fileUploaded = bcaTaggingStatus === PROCESSED;
  const isProcessing = bcaTaggingStatus === 'PROCESSING';

  const hasActions = fileUploaded && actionAble;

  const classes = classnames(styles.root, {
    [styles.actionAble]: hasActions,
    [styles.video]: type === VIDEO,
    [styles.isOwner]: isOwner,
  });

  const handleContentClick = () => {
    onMediaOpen(index);
  };

  const handleContextMenu = hasActions
    ? (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        e.preventDefault();
      }
    : undefined;

  const collageThumbnailUrl = data.transformations?.collageThumbnailUrl;

  const imgSize = Number(mediasLength) > 2 ? styles.imageSm : styles.imageMd;

  const content =
    type === 'OTHER' ? (
      <PopupLink to={secureUrl}>
        <div className={classnames(styles.fileWrap, imgSize)}>
          <Icon name="Document" size={36} className={styles.fileIcon} />
          <Text
            type="label"
            text={cutStringWithExt(filename || '', 9)}
            data-test="messageMedia:text:unknown"
          />
        </div>
      </PopupLink>
    ) : (
      <div
        style={{ backgroundImage: `url(${collageThumbnailUrl})` }}
        className={classnames(styles.image, imgSize, { [styles.isProcessing]: isProcessing })}
        onContextMenu={handleContextMenu}
        onClick={handleContentClick}
      />
    );

  return (
    <div className={classes}>
      <div className={styles.imageWrap}>
        {content}
        {!isProcessing && type === 'VIDEO' && (
          <div className={styles.duration}>
            <Icon name="Play" size={16} />
            <Text type="label" text={duration} data-test="messageMedia:text:unknown" />
          </div>
        )}
        <MediaUploader index={index} projectId={projectId} data={data} />
        {isProcessing && <Spinner className={styles.preloader} />}
      </div>
    </div>
  );
};

export default MessageMedia;
