import React, { useEffect } from 'react';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay';
import classnames from 'classnames';

import { OrganizationCampaignsQuery } from '../OrganizationCampaigns';

import styles from './OrganizationCampaignsList.pcss';

import DropdownGroupItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Text from 'Components/ui/Text/Text';
import { OrganizationCampaignsQuery as QueryType } from 'GraphTypes/OrganizationCampaignsQuery.graphql';

interface Props {
  selectedItems?: string[];
  onChange?: (item: string, label: string) => void;
  onLoadData?: (labels: Record<string, string>) => void;
  queryReference: PreloadedQuery<QueryType, Record<string, unknown>>;
}

const OrganizationCampaignsList: React.FC<Props> = (props) => {
  const { selectedItems = [], onChange, queryReference, onLoadData } = props;
  const data = usePreloadedQuery<QueryType>(OrganizationCampaignsQuery, queryReference);
  const list = data.organization?.campaigns?.edges;
  useEffect(() => {
    if (list?.length) {
      const selectedLabels = list.reduce((labelList, item) => {
        if (!item?.node) return labelList;
        const { id, name } = item.node;
        if (selectedItems.includes(id)) {
          return { ...labelList, [id]: name };
        }
        return labelList;
      }, {});
      if (onLoadData) {
        onLoadData(selectedLabels);
      }
    }
  }, [list]);

  return (
    <>
      {list?.map((item) => {
        if (!item?.node) return null;

        const { id, name } = item.node;

        const isActive = selectedItems.includes(id);

        const handleClick = () => {
          if (!onChange) return;
          onChange(id, name || '');
        };

        return (
          <DropdownGroupItem
            key={id}
            handleClick={handleClick}
            className={classnames(styles.listItem, { [styles.isActive]: isActive })}
          >
            <AlterButton
              text={name}
              fluid
              rightElement={<div className={classnames(styles.checkbox)} />}
              className={styles.listItemEl}
              classes={{
                buttonText: styles.listItemText,
              }}
              data-test="organizationCampaignsList:alterButton:listItemEl"
            />
          </DropdownGroupItem>
        );
      })}
      {list?.length === 0 && (
        <Text
          type="label"
          msg="projects.all_campaigns.empty_list"
          className={styles.emptyList}
          data-test="organizationCampaignsList:text:emptyList"
        />
      )}
    </>
  );
};

export default OrganizationCampaignsList;
