import React, { useMemo, useContext } from 'react';
import { graphql, useFragment } from 'react-relay';
import track from 'react-tracking';

import DealActions from '../../DealActions/DealActions';

import styles from './ContentCreationAdvertiserSide.pcss';

import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { createDate } from 'Util/dateCreator';
import Text from 'Atoms/Text/Text';
import { PRODUCT_SEEDING } from 'Constants/general';
import { ContentCreationAdvertiserSide_project$key } from 'GraphTypes/ContentCreationAdvertiserSide_project.graphql';
import Button from 'Atoms/Button/Button';
import Input from 'Components/ui/Input/Input';
import Icon from 'Components/ui/Icon/Icon';

interface Props {
  hasShipment: boolean;
  project: ContentCreationAdvertiserSide_project$key;
}

const ContentCreationAdvertiserSide: React.FC<Props> = (props) => {
  const { hasShipment, project } = props;

  const { openDrawer } = useContext(DrawerContext);

  const data = useFragment(
    graphql`
      fragment ContentCreationAdvertiserSide_project on Project {
        id
        ...DealActions_project
        deadline {
          draftDate
          publicationDate
        }
        campaign {
          id
          paymentType
          type
          publishingRequired
        }
      }
    `,
    project
  );

  const projectId = data.id;

  const paymentType = data.campaign.paymentType;
  const campaignType = data.campaign.type;
  const publishingRequired = data.campaign.publishingRequired;

  const draftDate = data.deadline?.draftDate;

  const shipmentPrefix = hasShipment ? '_with_shipment' : '';
  const isBarter = paymentType === 'BARTER';
  const paymentTypePrefix = isBarter ? '.barter' : '';

  const handleApproveContent = () => {
    openDrawer(`project-publication-date`);
  };

  const handleDateClick = () => {
    openDrawer(`project-draft-deadline`);
  };

  const canApproveContent = campaignType === 'INFLUENCER' && publishingRequired;
  const isSeedingCampaign = campaignType === PRODUCT_SEEDING;

  const approveContent = canApproveContent ? (
    <Button
      width="full"
      color="normal"
      msg="project.stepper.content_creation.approve"
      className={styles.btn}
      onClick={handleApproveContent}
      data-test="contentCreationAdvertiserSide:button:approve"
    />
  ) : null;

  const descr = useMemo<string>(() => {
    if (isSeedingCampaign) {
      return 'project.stepper.content_creation.product_seeding.advertiser.descr';
    }
    return canApproveContent
      ? 'project.stepper.content_creation.approve.descr'
      : `project.stepper.content_creation${shipmentPrefix}${paymentTypePrefix}.advertiser.descr`;
  }, [canApproveContent, shipmentPrefix, paymentTypePrefix, campaignType]);

  const isDatesVisible = true;

  const finishDealMsg = isSeedingCampaign
    ? 'project.stepper.content_creation.product_seeding.finish_deal'
    : '';
  const finishDealBtnColor = isSeedingCampaign ? 'white' : undefined;
  return (
    <div>
      <Text size="sm" msg={descr} data-test="contentCreationAdvertiserSide:text:unknown" />
      {isDatesVisible && (
        <div className={styles.dates}>
          <div className={styles.date}>
            {isSeedingCampaign && (
              <Text
                size="sm"
                msg="project.stepper.content_creation.deadline_seeding"
                className={styles.deadlineDescription}
                data-test="contentCreationAdvertiserSide:text:deadlineSeeding"
              />
            )}
            <div className={styles.draftDate} onClick={handleDateClick}>
              <Input
                value={draftDate ? createDate(draftDate) : ''}
                rightSymbol={<Icon name="Calendar-schedule" />}
                disabled
                bordered
                hideCloseIcon
                data-test="contentCreationAdvertiserSide:input:unknown"
              />
            </div>
          </div>
        </div>
      )}
      <DealActions
        finishDealMsg={finishDealMsg}
        nextStepBtn={approveContent}
        project={data}
        finishDealBtnColor={finishDealBtnColor}
      />
    </div>
  );
};

const ContentCreationAdvertiserSideWrap = track()(ContentCreationAdvertiserSide);

export default ContentCreationAdvertiserSideWrap;
