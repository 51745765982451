import React, { useContext } from 'react';
import { graphql, useFragment } from 'react-relay';
import classnames from 'classnames';

import styles from './Reference.pcss';
import { getFileName } from './utils';

import deleteReference from 'Mutations/DeleteReference.Mutation';
import { MEDIA } from 'Constants/modals';
import { OTHER, VIDEO } from 'Constants/general';
import Text from 'Atoms/Text/Text';
import PopupLink from 'Components/PopupLink/PopupLink';
import Spinner from 'Atoms/Spinner/Spinner';
import Icon from 'Components/ui/Icon/Icon';
import iconCross from 'Images/icons/deleteReference.svg';
import iconPlay from 'Images/icons/playBtnWhite.svg';
import { Reference_item$key } from 'GraphTypes/Reference_item.graphql';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';

interface Props {
  disabled?: boolean;
  briefCreativeId: string;
  item: Reference_item$key;
}

const Reference = (props: Props) => {
  const { disabled, item, briefCreativeId } = props;
  const data = useFragment(
    graphql`
      fragment Reference_item on Reference {
        id
        file {
          url
          secureUrl
          thumbnailUrl
          originalFilename
          ... on Image {
            transformations {
              collageThumbnailUrl
            }
          }
          type
          ... on Video {
            processingStatus
            transformations {
              autoUrl
              collageThumbnailUrl
            }
          }
        }
      }
    `,
    item
  );

  const { dispatch: modalDispatch } = useContext(ModalContext);

  if (!data.file) return null;

  const onDelete = () => {
    deleteReference({ id: data.id, briefCreativeId });
  };

  const onMediaShow = () => {
    if (!data.file) return;
    const { thumbnailUrl, transformations } = data.file;

    let videoUrl;

    if (data.file?.type === VIDEO) {
      videoUrl = transformations?.autoUrl;
    }

    modalDispatch({
      type: ModalTypes.SET_MODAL,
      payload: {
        name: MEDIA,
        attach: {
          videoUrl,
          thumbnailUrl,
        },
      },
    });
  };

  const createThumbnailMedia = (processing: boolean) => {
    const style = { backgroundImage: `url(${data.file?.transformations?.collageThumbnailUrl})` };

    let playNode;
    if (data.file?.type === VIDEO) {
      playNode = (
        <i className={styles.thumbnailIcon}>
          <img src={iconPlay} />
        </i>
      );
    }

    const onClick = processing ? undefined : onMediaShow;
    const classes = classnames(styles.thumbnail, { [styles.processing]: processing });

    return (
      <div className={classes} style={style} onClick={onClick}>
        {processing ? createPreloader : playNode}
      </div>
    );
  };

  const createThumbnailFile = (
    <PopupLink
      to={data.file?.secureUrl}
      download={data.file?.originalFilename || getFileName(data.file?.secureUrl)}
      className={classnames(styles.thumbnail, styles.thumbnailFile)}
    >
      <Icon name="Document" size={48} />
      <Text
        className={styles.thumbnailCaption}
        text={data.file?.originalFilename || getFileName(data.file?.secureUrl)}
        size="sm"
        align="center"
        data-test="reference:text:thumbnailCaption"
      />
    </PopupLink>
  );

  const createIconDelete = <img src={iconCross} className={styles.iconDelete} onClick={onDelete} />;

  const createPreloader = (
    <div className={`${styles.thumbnailIcon} ${styles.preloaderWrap}`}>
      <Spinner color="white" className={styles.preloader} />
    </div>
  );

  let iconDelete;
  if (!disabled) iconDelete = createIconDelete;

  const processing = data.file?.processingStatus === 'PROCESSING';

  const thumbnail =
    data.file?.type === OTHER ? createThumbnailFile : createThumbnailMedia(processing);

  return (
    <div className={styles.root}>
      {thumbnail}
      {!processing && iconDelete}
    </div>
  );
};

export default Reference;
