/**
 * @generated SignedSource<<075d1111fa535a3e112e3c93113e4df9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Currency = "CAD" | "RUB" | "USD";
import { FragmentRefs } from "relay-runtime";
export type TransactionSummary_summary$data = {
  readonly currency: Currency | null | undefined;
  readonly netCost: number | null | undefined;
  readonly profit: number | null | undefined;
  readonly revenue: number | null | undefined;
  readonly " $fragmentType": "TransactionSummary_summary";
};
export type TransactionSummary_summary$key = {
  readonly " $data"?: TransactionSummary_summary$data;
  readonly " $fragmentSpreads": FragmentRefs<"TransactionSummary_summary">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TransactionSummary_summary",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "netCost",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "revenue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    }
  ],
  "type": "Accounting_RevenueTransactionSummary",
  "abstractKey": null
};

(node as any).hash = "6714b56d6a6b6d6343fe96f5428c978e";

export default node;
