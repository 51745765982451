/**
 * @generated SignedSource<<99df1274d6db6153261333559397babd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FileType = "IMAGE" | "OTHER" | "VIDEO";
export type PortfolioContentQuery$variables = {
  id: string;
  portfolioCategoryName?: string | null | undefined;
  portfolioTypeName?: string | null | undefined;
};
export type PortfolioContentQuery$data = {
  readonly creator: {
    readonly medias?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly createdAt: any;
          readonly id: string;
          readonly instagramUrl: string;
          readonly shortcode: string;
          readonly standardResUrl: string;
          readonly thumbnailUrl: string;
          readonly videoUrl: string | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly portfolioItems: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly file: {
            readonly createdAt: any;
            readonly id: string;
            readonly secureUrl: string;
            readonly thumbnailUrl: string;
            readonly transformations?: {
              readonly autoUrl?: string;
              readonly collageThumbnailUrl: string;
              readonly portfolioSliderThumbnailUrl?: string;
              readonly portfolioSliderUrl?: string;
            } | null | undefined;
            readonly type: FileType;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    };
    readonly profile: {
      readonly videoResumeFile: {
        readonly createdAt: any;
        readonly secureUrl: string;
        readonly thumbnailUrl: string;
        readonly transformations?: {
          readonly autoUrl: string;
          readonly portfolioSliderThumbnailUrl: string;
          readonly portfolioSliderUrl: string;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type PortfolioContentQuery = {
  response: PortfolioContentQuery$data;
  variables: PortfolioContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "portfolioCategoryName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "portfolioTypeName"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 9
  },
  {
    "kind": "Variable",
    "name": "portfolioCategoryName",
    "variableName": "portfolioCategoryName"
  },
  {
    "kind": "Variable",
    "name": "portfolioTypeName",
    "variableName": "portfolioTypeName"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "secureUrl",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnailUrl",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "collageThumbnailUrl",
  "storageKey": null
},
v9 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ImageTransformation",
      "kind": "LinkedField",
      "name": "transformations",
      "plural": false,
      "selections": [
        (v8/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Image",
  "abstractKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "autoUrl",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "portfolioSliderUrl",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "portfolioSliderThumbnailUrl",
  "storageKey": null
},
v13 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "VideoTransformation",
      "kind": "LinkedField",
      "name": "transformations",
      "plural": false,
      "selections": [
        (v8/*: any*/),
        (v10/*: any*/),
        (v11/*: any*/),
        (v12/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Video",
  "abstractKey": null
},
v14 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "VideoTransformation",
      "kind": "LinkedField",
      "name": "transformations",
      "plural": false,
      "selections": [
        (v10/*: any*/),
        (v11/*: any*/),
        (v12/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Video",
  "abstractKey": null
},
v15 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 8
        }
      ],
      "concreteType": "InstagramMediaConnection",
      "kind": "LinkedField",
      "name": "medias",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "InstagramMediaEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "InstagramMedia",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "videoUrl",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "shortcode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "standardResUrl",
                  "storageKey": null
                },
                (v7/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "instagramUrl",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "medias(first:8)"
    }
  ],
  "type": "InstagramCreator",
  "abstractKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PortfolioContentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "creator",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "PortfolioItemConnection",
            "kind": "LinkedField",
            "name": "portfolioItems",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PortfolioItemEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PortfolioItem",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "file",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v9/*: any*/),
                          (v13/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CreatorProfile",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "videoResumeFile",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v7/*: any*/),
                  (v14/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v15/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PortfolioContentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "creator",
        "plural": false,
        "selections": [
          (v16/*: any*/),
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "PortfolioItemConnection",
            "kind": "LinkedField",
            "name": "portfolioItems",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PortfolioItemEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PortfolioItem",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "file",
                        "plural": false,
                        "selections": [
                          (v16/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v9/*: any*/),
                          (v13/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CreatorProfile",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "videoResumeFile",
                "plural": false,
                "selections": [
                  (v16/*: any*/),
                  (v4/*: any*/),
                  (v7/*: any*/),
                  (v14/*: any*/),
                  (v6/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v15/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7a9ce6919b063511d88b0acc0ebd0455",
    "id": null,
    "metadata": {},
    "name": "PortfolioContentQuery",
    "operationKind": "query",
    "text": "query PortfolioContentQuery(\n  $id: ID!\n  $portfolioCategoryName: String\n  $portfolioTypeName: String\n) {\n  creator(id: $id) {\n    __typename\n    portfolioItems(first: 9, portfolioCategoryName: $portfolioCategoryName, portfolioTypeName: $portfolioTypeName) {\n      edges {\n        node {\n          file {\n            __typename\n            id\n            createdAt\n            type\n            secureUrl\n            thumbnailUrl\n            ... on Image {\n              transformations {\n                collageThumbnailUrl\n              }\n            }\n            ... on Video {\n              transformations {\n                collageThumbnailUrl\n                autoUrl\n                portfolioSliderUrl\n                portfolioSliderThumbnailUrl\n              }\n            }\n          }\n          id\n        }\n      }\n    }\n    profile {\n      videoResumeFile {\n        __typename\n        createdAt\n        thumbnailUrl\n        ... on Video {\n          transformations {\n            autoUrl\n            portfolioSliderUrl\n            portfolioSliderThumbnailUrl\n          }\n        }\n        secureUrl\n        id\n      }\n      id\n    }\n    ... on InstagramCreator {\n      medias(first: 8) {\n        edges {\n          node {\n            id\n            createdAt\n            videoUrl\n            shortcode\n            standardResUrl\n            thumbnailUrl\n            instagramUrl\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ebbaccbca219ad449f947b44103a4fa8";

export default node;
