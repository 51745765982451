import remove from 'lodash/remove';
import React, { createContext, useReducer, PropsWithChildren, FC, Dispatch } from 'react';

interface CreatorsSearchProps {
  creatorIdsForExclusion: { creatorId: string; username: string }[];
}

const initialValue: CreatorsSearchProps = {
  creatorIdsForExclusion: [],
};

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export enum Types {
  ADD_CREATOR_FOR_EXLUSION = 'ADD_CREATOR_FOR_EXLUSION',
  REMOVE_CREATOR_FOR_EXLUSION = 'REMOVE_CREATOR_FOR_EXLUSION',
}

type CreatorsSearchPayload = {
  [Types.ADD_CREATOR_FOR_EXLUSION]: {
    creatorId: string;
    username: string;
  };
  [Types.REMOVE_CREATOR_FOR_EXLUSION]: {
    creatorId: string;
  };
};

export type CreatorsSearchActions =
  ActionMap<CreatorsSearchPayload>[keyof ActionMap<CreatorsSearchPayload>];

type Reducer = (state: CreatorsSearchProps, action: CreatorsSearchActions) => CreatorsSearchProps;
const reducer: Reducer = (state, action) => {
  switch (action.type) {
    case Types.ADD_CREATOR_FOR_EXLUSION: {
      const { creatorId, username } = action.payload;
      const newList = [...state.creatorIdsForExclusion, { creatorId, username }];

      return {
        ...state,
        creatorIdsForExclusion: newList,
      };
    }
    case Types.REMOVE_CREATOR_FOR_EXLUSION: {
      const { creatorId } = action.payload;
      const newList = state.creatorIdsForExclusion.filter((item) => item.creatorId !== creatorId);

      return {
        ...state,
        creatorIdsForExclusion: newList,
      };
    }
    default:
      return state;
  }
};

const CreatorsSearchContext = createContext<{
  state: CreatorsSearchProps;
  dispatch: Dispatch<CreatorsSearchActions>;
}>({ state: initialValue, dispatch: () => null });

type CreatorsSearchContextProviderType = FC<PropsWithChildren<{}>>;

const CreatorsSearchContextProvider: CreatorsSearchContextProviderType = (props) => {
  const { children } = props;

  const [state, dispatch] = useReducer<Reducer>(reducer, initialValue);

  return (
    <CreatorsSearchContext.Provider value={{ state, dispatch }}>
      {children}
    </CreatorsSearchContext.Provider>
  );
};

export { CreatorsSearchContext, CreatorsSearchContextProvider };
export type { CreatorsSearchContextProviderType };
