import React from 'react';

import styles from './TextQuery.css';

import SearchInput from 'Components/SearchInput';

const TextQuery = (props) => {
  const { className, queryPlaceholder, queryStyles, onChange } = props;

  const handleChange = (value) => {
    onChange({ textQuery: value });
  };

  const placeholder = queryPlaceholder
    ? queryPlaceholder
    : 'browse_creators.browse_filter_placeholder';

  return (
    <div className={`${className} ${styles.root}`} style={queryStyles}>
      <SearchInput placeholder={placeholder} onChange={handleChange} />
    </div>
  );
};

export default TextQuery;
