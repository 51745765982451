import React from 'react';

import NewCampaignButton from '../../../NewCampaignButton/NewCampaignButton';

import styles from './CampaignsEmptyState.pcss';

import { CampaignStage } from 'GraphTypes/CampaignsQuery.graphql';
import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';

interface Props {
  withFilters?: boolean;
  stage: CampaignStage;
}

const CampaignsEmptyState: React.FC<Props> = (props) => {
  const { stage, withFilters } = props;

  const titleMsg = withFilters
    ? 'campaigns.empty_state.with_filters'
    : `campaigns.empty_state.${stage.toLowerCase()}_stage`;

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <Icon name={withFilters ? 'Support-sad' : 'Campaigns'} />
        <Text type="md" msg={titleMsg} data-test="campaignsEmptyState:text:unknown" />
        {stage === 'ACTIVE' && !withFilters && (
          <NewCampaignButton colorType="pink" showIcon={false} className={styles.newCampaign} />
        )}
      </div>
    </div>
  );
};

export default CampaignsEmptyState;
