import React, { useContext, useState } from 'react';
import classnames from 'classnames';

import styles from './Message.pcss';

import { amplitude } from 'Helpers/amplitude';
import MessageDeletion, {
  MessageDeletionContent,
} from 'Modal/common/MessageDeletion/MessageDeletion';
import { timeChatFormat } from 'Util/dateCreator';
import { createTextWithLinks } from 'Util/textFormatter';
import NewText from 'Components/ui/Text/Text';
import Text from 'Atoms/Text/Text';
import Avatar from 'Atoms/Avatar/Avatar';
import Icon from 'Components/ui/Icon/Icon';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

interface Props {
  id: string;
  deleted?: boolean;
  admin?: boolean;
  text?: string | null;
  comment?: string | null;
  avaSrc?: string | null;
  isOwner?: boolean;
  isRead: boolean;
  createdAt: string;
  attachments?: JSX.Element;
  inPopup?: boolean;
  type?: 'Message';
}

const Message: React.FC<Props> = (props) => {
  const {
    id,
    text,
    comment,
    avaSrc,
    createdAt,
    isOwner,
    isRead,
    attachments,
    deleted,
    inPopup,
    admin,
    type,
  } = props;

  const [deleteWarningVisible, setDeleteWarningVisible] = useState(false);
  const [unhiddenForAdmin, setUnhiddenForAdmin] = useState(false);

  const { openDrawer } = useContext(DrawerContext);

  const handleMessageDelete = () => {
    openDrawer(`message-deletion-${id}`);
  };

  const handleWarningShow = () => {
    setDeleteWarningVisible(true);
  };

  const handleWarningClose = () => {
    setDeleteWarningVisible(false);
  };

  const handleUnhideClick = () => {
    setUnhiddenForAdmin(!unhiddenForAdmin);
  };

  const handleDropdownOpen = () => {
    amplitude.sendEvent<397>({
      id: '397',
      category: 'chat',
      name: 'message_dots_click',
      param: undefined,
    });
  };

  const handleDeleteClick = () => {
    amplitude.sendEvent<398>({
      id: '398',
      category: 'chat',
      name: 'message_delete_click',
      param: undefined,
    });
    if (inPopup) {
      handleWarningShow();
    } else {
      handleMessageDelete();
    }
  };

  const messageText = text || comment;

  const hidden = deleted && !unhiddenForAdmin;

  const getContent = (canDeletable: boolean) => {
    return (
      <div className={styles.content}>
        {!hidden && attachments}
        {messageText && !hidden && (
          <Text data-test="message:text:unknown">
            <pre
              className={styles.message}
              dangerouslySetInnerHTML={{ __html: createTextWithLinks(messageText) || '' }}
            />
          </Text>
        )}
        {hidden && (
          <Text
            msg="chat.project.message.deleted"
            className={styles.hiddenText}
            data-test="message:text:deleted"
          />
        )}
        {!isOwner && !attachments && (
          <NewText type="label" text={timeChatFormat(createdAt)} className={styles.date} />
        )}
        {isOwner && (
          <div className={styles.details}>
            <NewText type="label" text={timeChatFormat(createdAt)} className={styles.date} />
            <Icon
              size={16}
              name={isRead ? 'Check-double' : 'Check'}
              color="grey"
              className={styles.readMark}
            />
            {canDeletable && !deleted && type === 'Message' && (
              <>
                <Dropdown
                  showArrow={false}
                  value={<Icon size={16} name="Dots-menu" />}
                  className={styles.delteIcon}
                  onOpen={handleDropdownOpen}
                >
                  <DropdownGroup className={styles.dropdownGroup}>
                    <DropdownItem data-test="message:dropdownItem:unknown">
                      <AlterButton
                        icon="Trash-delele"
                        msg="general.delete"
                        onClick={handleDeleteClick}
                        data-test="message:alterButton:delete"
                      />
                    </DropdownItem>
                  </DropdownGroup>
                </Dropdown>
              </>
            )}
            {admin && deleted && (
              <div onClick={handleUnhideClick} className={styles.unhide}>
                <Icon size={16} name={unhiddenForAdmin ? 'Eye-hidden' : 'Eye'} />
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  if (!messageText && !attachments) return null;

  const getMessgageItem = (canDeletable: boolean) => {
    return (
      <div className={classnames(styles.root, { [styles.isOwner]: isOwner })}>
        <Avatar size="40" src={avaSrc} className={styles.ava} />
        <div className={styles.container}>{getContent(canDeletable)}</div>
      </div>
    );
  };

  return (
    <>
      {getMessgageItem(true)}
      {inPopup ? (
        <div className={classnames(styles.deletion, { [styles.hidden]: !deleteWarningVisible })}>
          <div className={styles.closeBtn} onClick={handleWarningClose}>
            <Icon name="Close-small" />
          </div>
          <MessageDeletionContent
            id={id}
            message={getMessgageItem(false)}
            onClose={handleWarningClose}
            className={styles.deletionContent}
          />
        </div>
      ) : (
        <MessageDeletion id={id} message={getMessgageItem(false)} />
      )}
    </>
  );
};

export default Message;
