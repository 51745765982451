/**
 * @generated SignedSource<<65aacaf0deb9cb546ad1f68df7980833>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AdvertiserProjectStage = "ACCEPTED" | "ACCEPTED_AND_NOT_LAUNCHED" | "ACCEPTED_BY_UNREVIEWED_CREATOR" | "COMPLETED" | "CONTENT_SUBMITTED" | "LAUNCHED" | "PRODUCT_SHIPMENT_COMPLETED" | "PRODUCT_SHIPMENT_STARTED" | "PUBLISHING_STARTED" | "READ" | "REJECTED" | "UNREAD" | "UNSENT";
import { FragmentRefs } from "relay-runtime";
export type CreatorsExclusion_campaign$data = {
  readonly creator: {
    readonly organization: {
      readonly id: string;
    } | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly searchQuery: {
    readonly excludeHiredMoreThanOnce: boolean | null | undefined;
    readonly exclusions: ReadonlyArray<{
      readonly advertiserProjectStages: ReadonlyArray<AdvertiserProjectStage> | null | undefined;
      readonly campaign: {
        readonly id: string;
        readonly name: string | null | undefined;
        readonly organization: {
          readonly name: string;
        } | null | undefined;
      };
    }> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "CreatorsExclusion_campaign";
};
export type CreatorsExclusion_campaign$key = {
  readonly " $data"?: CreatorsExclusion_campaign$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreatorsExclusion_campaign">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreatorsExclusion_campaign",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "creator",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchQuery",
      "kind": "LinkedField",
      "name": "searchQuery",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchQueryExclusion",
          "kind": "LinkedField",
          "name": "exclusions",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Campaign",
              "kind": "LinkedField",
              "name": "campaign",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Organization",
                  "kind": "LinkedField",
                  "name": "organization",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "advertiserProjectStages",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "excludeHiredMoreThanOnce",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};
})();

(node as any).hash = "c2bf1f0bb12321551bc5c35d84e22cf0";

export default node;
