import React, { useState, useContext } from 'react';

import styles from './RequestContentRevision.pcss';

import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import markContentReviewAsFailed from 'Mutations/MarkContentReviewAsFailed.Mutation';
import Drawer from 'Components/ui/Drawer/Drawer';
import Text from 'Components/ui/Text/Text';
import Button from 'Components/ui/Button/Button';
import Textarea from 'Components/ui/Textarea/Textarea';

interface Props {
  projectId: string;
}

const RequestContentRevision: React.FC<Props> = (props) => {
  const { projectId } = props;

  const { closeDrawer } = useContext(DrawerContext);

  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    markContentReviewAsFailed({ projectId, text: comment }, handleContentMarkAsFailedDone);
    setLoading(true);
  };

  const handleContentMarkAsFailedDone = () => {
    closeDrawer('request-revision');
  };

  return (
    <Drawer rootKey="request-revision" className={styles.drawer}>
      <div className={styles.drawerContainer}>
        <Text
          type="d2"
          msg="request_content_revision_modal.title"
          className={styles.drawerTitleText}
        />
        <Text type="md" msg="request_content_revision_modal.descr" className={styles.descr} />
        <div className={styles.inputWrap}>
          <Textarea bordered={true} handleChange={setComment} className={styles.input} />
        </div>
        <Button
          color="black"
          disabled={!comment}
          loading={loading}
          onClick={handleSubmit}
          msg="request_content_revision_modal.send_revision"
        />
      </div>
    </Drawer>
  );
};

export default RequestContentRevision;
