import React, { useCallback, useContext } from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import track from 'react-tracking';

import styles from './CreatorProfiles.pcss';
import Profile from './Profile/Profile';

import AddProfileDrawer from 'Modal/contractor/AddProfileDrawer/AddProfileDrawer';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import environment from 'Api/Environment';
import Text from 'Atoms/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import Spinner from 'Atoms/Spinner/Spinner';
import TextButton from 'Atoms/TextButton/TextButton';
import Page from 'Templates/Page/Page';
import { CreatorProfilesQuery as QueryType } from 'GraphTypes/CreatorProfilesQuery.graphql';

const CreatorProfilesQuery = graphql`
  query CreatorProfilesQuery {
    currentUser {
      creators {
        edges {
          node {
            id
            ...Profile_creator
          }
        }
      }
    }
  }
`;

const CreatorProfiles = () => {
  const { openDrawer } = useContext(DrawerContext);

  const handleShowDrawer = useCallback(() => openDrawer('add-profile'), []);

  return (
    <Page className={styles.root}>
      <div className={styles.container}>
        <Text
          tag="h1"
          weight="700"
          msg="creator_profiles.title"
          className={styles.title}
          data-test="creatorProfiles:text:title"
        />
        <div className={styles.list}>
          <div className={styles.addProfile} onClick={handleShowDrawer}>
            <TextButton
              color="secondary"
              leftIcon={<Icon name="Add-plus" />}
              msg="creator_profiles.add_profile"
              data-test="creatorProfiles:textButton:addProfile"
            />
          </div>
          <AddProfileDrawer />
          <QueryRenderer<QueryType>
            environment={environment}
            query={CreatorProfilesQuery}
            variables={{}}
            render={({ props: queryProps }) => {
              if (!queryProps) return <Spinner className={styles.spinner} />;

              const list = queryProps.currentUser?.creators?.edges;

              return list?.map((item) => {
                const node = item?.node;
                if (!node) return null;

                const { id } = node;

                return <Profile key={id} creator={node} />;
              });
            }}
          />
        </div>
      </div>
    </Page>
  );
};

export default track(
  {
    page: 'creator_profiles',
  },
  { dispatchOnMount: true }
)(CreatorProfiles);
