import React, { useState } from 'react';

import styles from './ResendNotification.css';

import resendOfferNotifications from 'Mutations/ResendOfferNotifications.Mutation';
import Text from 'Atoms/Text/Text';
import Button from 'Atoms/Button/Button';
import Input from 'Atoms/Input/Input';

const ResendNotification = () => {
  const [campaignId, setCampaignId] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<boolean | undefined>(undefined);

  const handleIdChange = (value: string) => {
    setSuccess(undefined);
    setCampaignId(value);
  };

  const handleSubmit = () => {
    setLoading(true);
    resendOfferNotifications({ campaignId }, handleDone, handleFail);
  };

  const handleDone = () => {
    setLoading(false);
    setSuccess(true);
  };

  const handleFail = () => {
    setLoading(false);
    setSuccess(false);
  };

  const errorText =
    'Failed. Please, check \n - campaign id is valid \n - campaign is not paused \n - sending brief process is not procceeding \n - campaign is not completed';

  return (
    <div>
      <Text
        tag="h2"
        weight="700"
        text="Add campaign_id for resending notification"
        data-test="resendNotification:text:addCampaignIdForResendingNotification"
      />
      <div className={styles.form}>
        <div className={styles.input}>
          <Input onChange={handleIdChange} data-test="resendNotification:input:unknown" />
        </div>
        <Button
          text="Send"
          loading={loading}
          className={styles.btn}
          onClick={handleSubmit}
          data-test="resendNotification:button:send"
        />
      </div>
      {success === true && (
        <Text
          color="green"
          text="Success"
          className={styles.result}
          data-test="resendNotification:text:success"
        />
      )}
      {success === false && (
        <Text
          color="red"
          text={errorText}
          className={styles.result}
          data-test="resendNotification:text:result"
        />
      )}
    </div>
  );
};

export default ResendNotification;
