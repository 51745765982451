/**
 * @generated SignedSource<<8eaf319f268edef41a134b2d5f77313f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING";
export type Currency = "CAD" | "RUB" | "USD";
export type PaymentType = "BARTER" | "MONEY";
import { FragmentRefs } from "relay-runtime";
export type OnboardingStep_project$data = {
  readonly campaign: {
    readonly paymentType: PaymentType | null | undefined;
    readonly type: CampaignType | null | undefined;
  };
  readonly currency: Currency | null | undefined;
  readonly id: string;
  readonly paidSocial: boolean;
  readonly price: number | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"OnboardingByAdvertiser_project" | "OnboardingByContractor_project">;
  readonly " $fragmentType": "OnboardingStep_project";
};
export type OnboardingStep_project$key = {
  readonly " $data"?: OnboardingStep_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"OnboardingStep_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OnboardingStep_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paidSocial",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentType",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OnboardingByAdvertiser_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OnboardingByContractor_project"
    }
  ],
  "type": "Project",
  "abstractKey": null
};

(node as any).hash = "1c54969ef422c4e109ba0a369d5aff02";

export default node;
