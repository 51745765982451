import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Buffer } from 'buffer';
import * as amplitudeSdk from '@amplitude/analytics-browser';

import styles from './CommunityInvite.pcss';

import { amplitude } from 'Helpers/amplitude';
import Header from 'Templates/Layout/Header/Header';
import Page from 'Templates/Page/Page';
import Text from 'Components/ui/Text/Text';
import Button from 'Components/ui/Button/Button';
import imageAppSrc1x from 'Images/creators/community_invite_app_1x.png';
import imageAppSrc2x from 'Images/creators/community_invite_app_2x.png';
import imageQRSrc1x from 'Images/creators/community_invite_QR_code_1x.png';
import imageQRSrc2x from 'Images/creators/community_invite_QR_code_2x.png';
import Error404 from 'Page/common/Error404/Error404';

const CommunityInvite: React.FC = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const [brandName, setBrandName] = useState('');
  const [token, setToken] = useState('');
  const [invalidCode, setInvalidCode] = useState(false);
  const deviceId = amplitudeSdk.getDeviceId();

  useEffect(() => {
    if (code) {
      try {
        const additionalData = Buffer.from(code, 'base64').toString();
        const parsedData: { name: string; token: string } = JSON.parse(additionalData);
        setBrandName(parsedData.name);
        setToken(parsedData.token);

        amplitude.sendEvent({
          id: '514',
          category: 'community_invite',
          name: 'pageview',
          param: { organization_name: parsedData.name, token: parsedData.token },
        });
      } catch (e) {
        setInvalidCode(true);
      }
    }
  }, [code]);

  const imageScrSet = isMobile
    ? `${imageAppSrc1x} 1x, ${imageAppSrc2x} 2x`
    : `${imageQRSrc1x} 1x, ${imageQRSrc2x} 2x`;

  const communityInviteLink = `https://link.insense.pro/community-invite?code=${code}${
    deviceId ? `&uid=${deviceId}` : ''
  }`;

  const handleDownloadAppClick = () => {
    amplitude.sendEvent({
      id: '515',
      category: 'community_invite',
      name: 'download_click',
      param: { organization_name: brandName, token: token },
    });
    const loadedAt = +new Date();
    setTimeout(() => {
      if (+new Date() - loadedAt < 2000) window.location.href = communityInviteLink;
    }, 25);

    window.open(
      `insense://community-invite?code=${code}${deviceId ? `&uid=${deviceId}` : ''}`,
      '_self'
    );
  };

  if (invalidCode) {
    return <Error404 />;
  }

  return (
    <Page>
      <Header showStaticHeader classes={{ wrap: styles.header }} />
      <div className={styles.wrapper}>
        <Text
          type="d1"
          msg="outreach.community_invite.title"
          className={styles.title}
          formatValues={{
            brandName: <Text type="d1" msg={brandName} inline className={styles.titleBrandName} />,
          }}
        />

        <div className={styles.imageWrapper}>
          <img srcSet={imageScrSet} />
        </div>

        <div className={styles.textWrapper}>
          <Text
            type="md"
            msg="outreach.community_invite.subtitle"
            className={styles.subtitle}
            formatValues={{
              brandName: (
                <Text msg={brandName} inline weight="bold" className={styles.subtitleBrandName} />
              ),
            }}
          />

          <Text
            type="h3"
            msg="outreach.community_invite.reasons_title"
            className={styles.reasonsTitle}
          />
          <Text type="md" msg="outreach.community_invite.reason_1" />
          <Text type="md" msg="outreach.community_invite.reason_2" />
          <Text type="md" msg="outreach.community_invite.reason_3" />

          <Text type="md" msg="outreach.community_invite.final" className={styles.final} />
        </div>

        {isMobile && (
          <footer className={styles.footer}>
            <Button
              onClick={handleDownloadAppClick}
              color="black"
              fluid
              msg="outreach.application_sent.btn"
              className={styles.footerBtn}
            />
          </footer>
        )}
      </div>
    </Page>
  );
};

export default CommunityInvite;
