import React, { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import styles from './FilterToggle.pcss';

import Text from 'Components/ui/Text/Text';
import Toggle from 'Components/ui/Toggle/Toggle';
import {
  FiltersFormContext,
  FiltersFormContextType,
} from 'Components/FiltersForm/FiltersFormContext';

interface Props {}

const FilterToggle: React.FC<Props> = (props) => {
  const { fieldProps, fieldKey, title, img, isDisabled } = props;
  const { filters, setFilterValueByKey } = useContext<FiltersFormContextType>(FiltersFormContext);

  const [value, changeValue] = React.useState<boolean>(filters[fieldKey] || false);
  const intl = useIntl();

  const titleValue = title || fieldProps.msg;
  const visibleValue = titleValue ? intl.formatMessage({ id: titleValue }) : '';
  const isFieldDisabled = isDisabled && isDisabled(filters);

  useEffect(() => {
    changeValue(filters[fieldKey] || '');
  }, [filters[fieldKey]]);

  const handleChangeValue = (checked: boolean) => {
    changeValue(checked);
    setFilterValueByKey(fieldKey, checked, checked ? visibleValue : undefined);
  };
  return (
    <div className={classNames(styles.root, { [styles.disabled]: isFieldDisabled })}>
      {img && <img src={img} className={styles.img} />}
      {title && <Text type="md" msg={title} className={styles.text} />}
      <Toggle {...fieldProps} disabled={isFieldDisabled} on={value} onChange={handleChangeValue} />
    </div>
  );
};

export default FilterToggle;
