import { v4 as uuid } from 'uuid';

import { identify } from 'Util/custify';
import ErrorHandler from 'Util/errorHandler';
import { getQueryLocationParams } from 'Util/encodeQueryData';
import createOrganization from 'Mutations/CreateOrganization.Mutation';
import assignUserToOrganization from 'Mutations/AssignUserToOrganization.Mutation';
import { AssignUserToOrganizationMutation$data } from 'GraphTypes/AssignUserToOrganizationMutation.graphql';
import { CreateOrganizationMutation$data } from 'GraphTypes/CreateOrganizationMutation.graphql';

interface AdvertiserSignupParams {
  email: string;
  userId: string;
  name: string;
  websiteUrl: string;
  affiliateReferralCode?: string;
  organizationId?: string;
  onOrganizationIdGenerated?: (organizationId: string) => void;
  onSuccess: () => void;
}

interface SessionActionsResult {
  advertiserSignup: (params: AdvertiserSignupParams) => void;
}

type UseSessionActions = () => SessionActionsResult;
const useSessionActions: UseSessionActions = () => {
  const advertiserSignup = async (params: AdvertiserSignupParams) => {
    const {
      name,
      email,
      organizationId,
      userId,
      websiteUrl,
      onOrganizationIdGenerated,
      affiliateReferralCode,
      onSuccess,
    } = params;

    const queries = getQueryLocationParams();
    const referralToken = queries.referralToken ? queries.referralToken.toString() : undefined;

    try {
      if (organizationId) {
        await new Promise<AssignUserToOrganizationMutation$data>((resolve, reject) => {
          assignUserToOrganization({ organizationId, userId }, resolve, reject);
        });
      } else {
        const organizationId = uuid();
        onOrganizationIdGenerated?.(organizationId);
        const referrerCode = affiliateReferralCode
          ? { tapfiliateReferralCode: affiliateReferralCode }
          : null;
        await new Promise<CreateOrganizationMutation$data>((resolve, reject) => {
          createOrganization(
            { id: organizationId, websiteUrl, name, referralToken, ...referrerCode },
            resolve,
            reject
          );
        });
      }
      identify({
        userId,
        email,
        company_id: organizationId,
      });
      onSuccess?.();
    } catch (error) {
      ErrorHandler.error('Crash while signup as advertiser', { email, error });
    }
  };

  return { advertiserSignup };
};

export { useSessionActions };
