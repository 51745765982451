import React, { useState } from 'react';
import { useFragment, graphql } from 'react-relay';
import { Element } from 'react-scroll';

import styles from './ScreeningQuestions.pcss';
import ScreeningQuestion from './ScreeningQuestion/ScreeningQuestion';

import { normalizeFragmentRef } from 'Util/normalizeFragmentRef';
import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { ScreeningQuestions_brief$key } from 'GraphTypes/ScreeningQuestions_brief.graphql';
import Tooltip from 'Atoms/Tooltip/Tooltip';

const MAX_QUESTIONS_AMOUNT = 4;

const ScreeningQuestionsFragment = graphql`
  fragment ScreeningQuestions_brief on Brief {
    ... on V2Brief {
      id
      screeningQuestions(first: 4) @connection(key: "ScreeningQuestions_screeningQuestions") {
        edges {
          node {
            id
            preferredValue
            text
            type
          }
        }
      }
    }
  }
`;

interface Props {
  screeningQuestions: ScreeningQuestions_brief$key;
}

const ScreeningQuestions: React.FC<Props> = (props) => {
  const { screeningQuestions } = props;

  const [addNew, setAddNew] = useState(false);

  const fragment = useFragment(
    ScreeningQuestionsFragment,
    normalizeFragmentRef<ScreeningQuestions_brief$key>(screeningQuestions)
  );

  const list = fragment.screeningQuestions?.edges;
  const briefId = fragment.id || '';

  const handleNewQuestionAdd = () => {
    setAddNew(false);
  };

  const handleNewQuestion = () => {
    setAddNew(true);
  };

  const newAddBlocked = Number(list?.length) === MAX_QUESTIONS_AMOUNT;

  return (
    <Element name="screeningQuestion" className={styles.root}>
      <div className={styles.title}>
        <Text
          type="h1"
          msg="brief_template.screening_questions.title"
          data-test="screeningQuestions:text:title"
        />
        <Text
          type="h1"
          msg="brief_template.field.otional"
          className={styles.optional}
          data-test="screeningQuestions:text:otional"
        />
      </div>
      <Text
        type="md"
        msg="brief_template.screening_questions.descr"
        className={styles.descr}
        data-test="screeningQuestions:text:descr"
      />
      <div className={styles.questions}>
        {list?.map((item, index) => {
          const id = item?.node?.id;
          return <ScreeningQuestion key={id} index={index + 1} {...item?.node} briefId={briefId} />;
        })}
      </div>
      <div className={styles.controls}>
        {!addNew && Number(list?.length) > 0 && (
          <Tooltip
            id="add_new_question"
            place="top"
            tooltipMsg={
              newAddBlocked ? 'brief_template.screening_questions.limit.tooltip' : undefined
            }
          >
            <AlterButton
              type="black"
              msg="brief_template.screening_questions.question.add"
              className={styles.add}
              handleClick={handleNewQuestion}
              disabled={newAddBlocked}
              data-test="screeningQuestions:alterButton:add"
            />
          </Tooltip>
        )}
      </div>
      {(addNew || list?.length === 0) && (
        <div className={addNew ? styles.addQuestion : undefined}>
          <ScreeningQuestion
            briefId={briefId}
            editMode={true}
            isNew={true}
            onQuestionUpdate={handleNewQuestionAdd}
          />
        </div>
      )}
    </Element>
  );
};

export default ScreeningQuestions;
