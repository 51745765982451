import React, { useEffect, useContext } from 'react';
import classnames from 'classnames';

import { BriefContext } from '../../../Brief.Context';

import styles from './SavingStatus.pcss';

import colors from 'Styles/vars/colors.css';
import Spinner from 'Atoms/Spinner/Spinner';
import Icon from 'Atoms/Icon/Icon';
import Text from 'Components/ui/Text/Text';
import { FREE_PLAN } from 'Constants/general';
import { PlanId } from 'GraphTypes/BriefQuery.graphql';
import { ReferencesContext } from 'AdvertiserPage/Brief/References.Context';

interface Props {
  planId?: PlanId;
}

const SavingStatus: React.FC<Props> = (props) => {
  const { planId } = props;

  const { state: referencesState } = useContext(ReferencesContext);
  const [briefState, briefDispatch] = useContext(BriefContext);

  const { briefIsSaving } = briefState;
  const referencesQueue = referencesState.queue;

  useEffect(() => {
    return () => {
      briefDispatch({ key: 'briefIsSaving', value: true });
    };
  }, []);

  useEffect(() => {
    if (briefIsSaving === true) {
      setTimeout(updateStatus, 2000);
    }
  }, [briefIsSaving]);

  if (briefIsSaving === null) return null;

  const updateStatus = () => {
    briefDispatch({ key: 'briefIsSaving', value: false });
  };

  const processing = briefIsSaving || referencesQueue.length > 0;

  const isFreePlan = planId === FREE_PLAN;

  if (processing) {
    return (
      <div className={styles.root}>
        <Spinner color={colors.colorGrayDog} size="sm" />
        <Text
          type="md"
          className={classnames(styles.processingText, styles.text)}
          msg="brief_template.status.saving"
          data-test="savingStatus:text:saving"
        />
      </div>
    );
  }

  if (isFreePlan) {
    return (
      <div className={styles.root}>
        <Text
          type="md"
          className={classnames(styles.freeSavedText, styles.text)}
          msg="brief_template.status.saved_in"
          data-test="savingStatus:text:savedIn"
        />
        <Text
          type="md"
          className={styles.draftText}
          msg="brief_template.status.drafts"
          data-test="savingStatus:text:draftText"
        />
      </div>
    );
  }

  return (
    <div className={styles.root} data-test="element:briefSaved">
      <Icon name="check" className={styles.savedIcon} />
      <Text
        type="md"
        className={classnames(styles.savedText, styles.text)}
        msg="brief_template.status.saved"
        data-test="savingStatus:text:saved"
      />
    </div>
  );
};

export default SavingStatus;
