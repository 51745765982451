import React, { ChangeEvent, useContext, useDeferredValue, useMemo, useState } from 'react';

import withQuery from '../FilterWithQuery';

import styles from './FilterSelect.pcss';
import FilterSelectItems from './FilterSelectItems';

import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import Text from 'Components/ui/Text/Text';
import { FilterFormItemSelect } from 'Components/FiltersForm/FilterForm';
import Icon from 'Components/ui/Icon/Icon';
import Input from 'Components/ui/Input/Input';
import {
  FiltersFormContext,
  FiltersFormContextType,
} from 'Components/FiltersForm/FiltersFormContext';

interface SelectProps {}

const FilterSelect: React.FC<FilterFormItemSelect & SelectProps> = (props) => {
  const {
    value,
    dropdownProps,
    dropdownGroupProps,
    isMultiselect,
    visibleValue,
    withSearch,
    itemsQuery,
    isDisabled,
  } = props;
  const [filterText, setFilterText] = useState('');
  const { filters } = useContext<FiltersFormContextType>(FiltersFormContext);
  const isFieldHaveMoreThanOneVisibleValue = Array.isArray(visibleValue) && visibleValue.length > 1;
  const isFieldDisabled = isDisabled && isDisabled(filters);
  const handleFilterTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterText(e.target.value?.toLowerCase().trim() || '');
  };

  const filterTextValue = useDeferredValue(filterText);

  const ListComponent = useMemo(() => {
    if (itemsQuery) {
      return React.memo(withQuery(FilterSelectItems));
    }
    return FilterSelectItems;
  }, [itemsQuery]);

  return (
    <div className={styles.root}>
      <Dropdown
        showArrow
        bordered={dropdownProps?.bordered ?? true}
        closeBySelect={!isMultiselect}
        active={value && value.length > 0}
        disabled={isFieldDisabled}
        value={
          <div className={styles.valueText}>
            <Text
              type="md"
              msg={dropdownProps?.placeholderMsg}
              textPriority={Boolean(value && value.length > 0)}
              text={isFieldHaveMoreThanOneVisibleValue ? visibleValue[0] : visibleValue}
              className={styles.selectorLabel}
            />
            <Text
              type="md"
              text={isFieldHaveMoreThanOneVisibleValue ? `+${value.length - 1}` : ''}
              className={styles.selectorCounter}
            />
          </div>
        }
        className={styles.dropdown}
      >
        <DropdownGroup className={styles.group} align={dropdownGroupProps?.align || 'right'}>
          {withSearch && (
            <div className={styles.filterContainer}>
              <Icon name="Search-loop" />
              <Input
                type="text"
                placeholder="Search"
                onChange={handleFilterTextChange}
                value={filterText}
                hideCloseIcon
                borderless
              />
            </div>
          )}
          <div className={styles.dropdownList}>
            <ListComponent {...props} searchQueryText={filterTextValue} />
          </div>
        </DropdownGroup>
      </Dropdown>
    </div>
  );
};

export default React.memo(FilterSelect);
