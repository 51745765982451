/**
 * @generated SignedSource<<3d84aee7c9b5ea541c440d6dba663b18>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AddonId = "BRANDS" | "SEATS" | "WHITELISTING_LICENSES";
export type PlanId = "ADVANCED" | "ADVANCED_2021_10" | "ADVANCED_2022_11" | "AGENCY_2024_03" | "BARTER" | "BASIC_OLD_PS" | "BRAND_2024_03" | "CONTENT_CREATION" | "ENTERPRISE" | "FREE" | "FREE_OLD_PS" | "FREE_TRIAL" | "FULLY_MANAGED" | "FULLY_MANAGED_1" | "FULLY_MANAGED_2" | "FULLY_MANAGED_TTCX_MMA" | "GROW" | "PAYOUT" | "PREMIUM" | "PREMIUM_PLUS" | "PUBLICIS" | "SCALE" | "SCALE_2021_06" | "SCALE_2021_10" | "SMARTLY_ENTERPRISE_2023_06" | "SMARTLY_ENTERPRISE_TRIAL_2023_06" | "TRIAL_2022_08" | "TRIAL_2024_03" | "TRIAL_MONTH" | "UGC_2022_08" | "UGC_ADS_2022_08" | "WILDJAM";
export type SubscriptionInterval = "BIANNUAL" | "CUSTOM" | "MONTHLY" | "TRIMONTHLY" | "YEARLY";
import { FragmentRefs } from "relay-runtime";
export type SubscriptionLimits_organization$data = {
  readonly counters: {
    readonly activeBrands: number;
    readonly bcaLicensedCreators: number;
    readonly psLicensedCreators: number;
  } | null | undefined;
  readonly members: {
    readonly totalCount: number;
  } | null | undefined;
  readonly subscription: {
    readonly addonItems: ReadonlyArray<{
      readonly addon: {
        readonly id: AddonId;
        readonly resourcesPerUnit: number;
      };
      readonly quantity: number;
    }>;
    readonly availableAddonIds: ReadonlyArray<AddonId>;
    readonly baseLimits: {
      readonly maxActiveBrands: number | null | undefined;
      readonly maxBcaConnections: number | null | undefined;
      readonly maxLicensedCreators: number | null | undefined;
      readonly maxSeats: number | null | undefined;
    };
    readonly effectiveLimits: {
      readonly maxActiveBrands: number | null | undefined;
      readonly maxBcaConnections: number | null | undefined;
      readonly maxLicensedCreators: number | null | undefined;
      readonly maxSeats: number | null | undefined;
    };
    readonly interval: SubscriptionInterval | null | undefined;
    readonly paused: boolean;
    readonly planId: PlanId;
  } | null | undefined;
  readonly " $fragmentType": "SubscriptionLimits_organization";
};
export type SubscriptionLimits_organization$key = {
  readonly " $data"?: SubscriptionLimits_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"SubscriptionLimits_organization">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "maxActiveBrands",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "maxLicensedCreators",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "maxBcaConnections",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "maxSeats",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SubscriptionLimits_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationCounters",
      "kind": "LinkedField",
      "name": "counters",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "activeBrands",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "psLicensedCreators",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bcaLicensedCreators",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AdvertiserSubscription",
      "kind": "LinkedField",
      "name": "subscription",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "planId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "availableAddonIds",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AddonItem",
          "kind": "LinkedField",
          "name": "addonItems",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Addon",
              "kind": "LinkedField",
              "name": "addon",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "resourcesPerUnit",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "quantity",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paused",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "interval",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SubscriptionLimits",
          "kind": "LinkedField",
          "name": "effectiveLimits",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SubscriptionLimits",
          "kind": "LinkedField",
          "name": "baseLimits",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserConnection",
      "kind": "LinkedField",
      "name": "members",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "326cd89f6e8319a05d4c025ef6670618";

export default node;
