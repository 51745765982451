import React from 'react';

import styles from './CreatorsCountNotification.pcss';

import Icon from 'Components/ui/Icon/Icon';
import Text from 'Components/ui/Text/Text';
import { formatValue } from 'Util/numberFormatter';

interface Props {
  count: number;
  isSuggestedCreatorsExists?: boolean;
}

export const CreatorsCountNotification: React.FC<Props> = (props) => {
  const { count, isSuggestedCreatorsExists } = props;

  return (
    <div className={styles.root}>
      <Icon name="Sparkle" color="purple" className={styles.icon} />
      <div className={styles.textContainer}>
        <Text
          type="md"
          msg="invite_outreach_creators_modal.banner.selected_creators_count"
          weight="bold"
          formatValues={{
            count: formatValue({
              value: count,
              format: 'number',
            }),
          }}
        />
        <Text
          type="md"
          msg={
            isSuggestedCreatorsExists
              ? 'invite_outreach_creators_modal.banner.selected_creators_count.description'
              : 'invite_outreach_creators_modal.banner.selected_creators_count.description_no_suggested'
          }
          color="grey"
        />
      </div>
    </div>
  );
};

export default CreatorsCountNotification;
