/**
 * @generated SignedSource<<f9d551ad20f7cd6558c6da6feefbb395>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING";
export type ProductDeliveryOption = "BRAND_WILL_HANDLE_SHIPMENT_TO_CREATORS" | "BRAND_WILL_REFUND_THE_PURCHASED_PRODUCT" | "COLLABORATION_WILL_NOT_REQUIRE_PRODUCT_SHIPMENT" | "TTS_OTHER" | "TTS_SEND_SAMPLE_TO_CREATOR" | "TTS_SEND_SAMPLE_TO_CREATOR_WITHOUT_APPROVAL";
export type NewCampaignTabsQuery$variables = {
  id: string;
};
export type NewCampaignTabsQuery$data = {
  readonly campaign: {
    readonly acceptedCounter: {
      readonly totalCount: number;
    };
    readonly applicantsWithUnreadMesseges: {
      readonly hasItems: boolean;
    };
    readonly approvedCreatives: {
      readonly hasItems: boolean;
    };
    readonly brief: {
      readonly productDelivery?: ProductDeliveryOption | null | undefined;
    } | null | undefined;
    readonly completedCounter: {
      readonly totalCount: number;
    };
    readonly contentCreationUnreadMesseges: {
      readonly hasItems: boolean;
    };
    readonly inProgressCounter: {
      readonly totalCount: number;
    };
    readonly messagedCounter: {
      readonly totalCount: number;
    };
    readonly publishedCreatives: {
      readonly hasItems: boolean;
    };
    readonly publishingRequired: boolean;
    readonly showShipmentTab: boolean;
    readonly type: CampaignType | null | undefined;
  } | null | undefined;
  readonly currentUser: {
    readonly admin: boolean;
  } | null | undefined;
};
export type NewCampaignTabsQuery = {
  response: NewCampaignTabsQuery$data;
  variables: NewCampaignTabsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "admin",
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showShipmentTab",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publishingRequired",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "hasItems",
    "storageKey": null
  }
],
v7 = {
  "alias": "publishedCreatives",
  "args": [
    {
      "kind": "Literal",
      "name": "publishedPosts",
      "value": true
    }
  ],
  "concreteType": "CreativeConnection",
  "kind": "LinkedField",
  "name": "creatives",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": "creatives(publishedPosts:true)"
},
v8 = {
  "alias": "approvedCreatives",
  "args": [
    {
      "kind": "Literal",
      "name": "publishedPosts",
      "value": false
    }
  ],
  "concreteType": "CreativeConnection",
  "kind": "LinkedField",
  "name": "creatives",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": "creatives(publishedPosts:false)"
},
v9 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productDelivery",
      "storageKey": null
    }
  ],
  "type": "V2Brief",
  "abstractKey": null
},
v10 = {
  "kind": "Literal",
  "name": "archived",
  "value": false
},
v11 = {
  "kind": "Literal",
  "name": "stages",
  "value": [
    "ACCEPTED"
  ]
},
v12 = {
  "kind": "Literal",
  "name": "withUnreadEvents",
  "value": true
},
v13 = {
  "alias": "applicantsWithUnreadMesseges",
  "args": [
    (v10/*: any*/),
    (v11/*: any*/),
    (v12/*: any*/)
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": "projects(archived:false,stages:[\"ACCEPTED\"],withUnreadEvents:true)"
},
v14 = {
  "alias": "contentCreationUnreadMesseges",
  "args": [
    (v10/*: any*/),
    {
      "kind": "Literal",
      "name": "stages",
      "value": [
        "LAUNCHED",
        "CONTENT_SUBMITTED",
        "PUBLISHING_STARTED",
        "COMPLETED"
      ]
    },
    (v12/*: any*/)
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": "projects(archived:false,stages:[\"LAUNCHED\",\"CONTENT_SUBMITTED\",\"PUBLISHING_STARTED\",\"COMPLETED\"],withUnreadEvents:true)"
},
v15 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v16 = {
  "alias": "acceptedCounter",
  "args": [
    (v10/*: any*/),
    {
      "kind": "Literal",
      "name": "hasBrandMessageBeforeLaunch",
      "value": false
    },
    (v11/*: any*/),
    (v12/*: any*/)
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v15/*: any*/),
  "storageKey": "projects(archived:false,hasBrandMessageBeforeLaunch:false,stages:[\"ACCEPTED\"],withUnreadEvents:true)"
},
v17 = {
  "alias": "messagedCounter",
  "args": [
    (v10/*: any*/),
    {
      "kind": "Literal",
      "name": "hasBrandMessageBeforeLaunch",
      "value": true
    },
    (v11/*: any*/),
    (v12/*: any*/)
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v15/*: any*/),
  "storageKey": "projects(archived:false,hasBrandMessageBeforeLaunch:true,stages:[\"ACCEPTED\"],withUnreadEvents:true)"
},
v18 = {
  "alias": "inProgressCounter",
  "args": [
    (v10/*: any*/),
    {
      "kind": "Literal",
      "name": "stages",
      "value": [
        "LAUNCHED",
        "CONTENT_SUBMITTED",
        "PUBLISHING_STARTED"
      ]
    },
    (v12/*: any*/)
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v15/*: any*/),
  "storageKey": "projects(archived:false,stages:[\"LAUNCHED\",\"CONTENT_SUBMITTED\",\"PUBLISHING_STARTED\"],withUnreadEvents:true)"
},
v19 = {
  "alias": "completedCounter",
  "args": [
    (v10/*: any*/),
    {
      "kind": "Literal",
      "name": "stages",
      "value": [
        "COMPLETED"
      ]
    },
    (v12/*: any*/)
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v15/*: any*/),
  "storageKey": "projects(archived:false,stages:[\"COMPLETED\"],withUnreadEvents:true)"
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NewCampaignTabsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "brief",
            "plural": false,
            "selections": [
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          (v13/*: any*/),
          (v14/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NewCampaignTabsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v20/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "brief",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v9/*: any*/),
              (v20/*: any*/)
            ],
            "storageKey": null
          },
          (v13/*: any*/),
          (v14/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ca2a84d19d140cc9b9535cea83170b9f",
    "id": null,
    "metadata": {},
    "name": "NewCampaignTabsQuery",
    "operationKind": "query",
    "text": "query NewCampaignTabsQuery(\n  $id: ID!\n) {\n  currentUser {\n    admin\n    id\n  }\n  campaign(id: $id) {\n    type\n    showShipmentTab\n    publishingRequired\n    publishedCreatives: creatives(publishedPosts: true) {\n      hasItems\n    }\n    approvedCreatives: creatives(publishedPosts: false) {\n      hasItems\n    }\n    brief {\n      __typename\n      ... on V2Brief {\n        productDelivery\n      }\n      id\n    }\n    applicantsWithUnreadMesseges: projects(stages: [ACCEPTED], archived: false, withUnreadEvents: true) {\n      hasItems\n    }\n    contentCreationUnreadMesseges: projects(stages: [LAUNCHED, CONTENT_SUBMITTED, PUBLISHING_STARTED, COMPLETED], archived: false, withUnreadEvents: true) {\n      hasItems\n    }\n    acceptedCounter: projects(stages: [ACCEPTED], hasBrandMessageBeforeLaunch: false, archived: false, withUnreadEvents: true) {\n      totalCount\n    }\n    messagedCounter: projects(stages: [ACCEPTED], hasBrandMessageBeforeLaunch: true, archived: false, withUnreadEvents: true) {\n      totalCount\n    }\n    inProgressCounter: projects(stages: [LAUNCHED, CONTENT_SUBMITTED, PUBLISHING_STARTED], archived: false, withUnreadEvents: true) {\n      totalCount\n    }\n    completedCounter: projects(stages: [COMPLETED], archived: false, withUnreadEvents: true) {\n      totalCount\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e41d1b83110f266af1a1512685fc0361";

export default node;
