import React, { useEffect } from 'react';
import { PreloadedQuery, useQueryLoader, usePreloadedQuery, graphql } from 'react-relay';

import { CampaignStage } from 'GraphTypes/CampaignsQuery.graphql';
import Tabs from 'Molecules/Tabs/Tabs';
import { CampaignStageFilterQuery as QueryType } from 'GraphTypes/CampaignStageFilterQuery.graphql';

const CampaignStageFilterQuery = graphql`
  query CampaignStageFilterQuery {
    activeCampaigns: campaigns(stages: [ACTIVE, IN_REVIEW]) {
      totalCount
    }
    draftCampaigns: campaigns(stages: [DRAFT]) {
      totalCount
    }
    completedCampaigns: campaigns(stages: [COMPLETED]) {
      totalCount
    }
  }
`;

interface Props {
  currentStage?: string;
  onStageChange: (id: CampaignStage) => void;
}

interface StagesProps {
  queryReference: PreloadedQuery<QueryType, Record<string, unknown>>;
}

interface CampaignStagesProps {
  activeCampaignsCount?: number;
  draftCampaignsCount?: number;
  completedCampaignsCount?: number;
}

export const CampaignStages = (props: CampaignStagesProps & Props) => {
  const {
    activeCampaignsCount,
    draftCampaignsCount,
    completedCampaignsCount,
    currentStage,
    onStageChange,
  } = props;
  const items = [
    {
      id: 'ACTIVE',
      textData: { msg: 'dashboard.campaigns.filter.stage.active' },
      count: activeCampaignsCount,
      isSimpleView: true,
    },
    {
      id: 'DRAFT',
      textData: { msg: 'dashboard.campaigns.filter.stage.draft' },
      count: draftCampaignsCount,
      isSimpleView: true,
    },
    {
      id: 'COMPLETED',
      textData: { msg: 'dashboard.campaigns.filter.stage.completed' },
      count: completedCampaignsCount,
      isSimpleView: true,
    },
  ];

  const selectStage = (stage: CampaignStage) => {
    onStageChange(stage);
  };

  return (
    <Tabs
      items={items}
      currentItemId={currentStage}
      filled={true}
      mute={true}
      onChange={selectStage}
    />
  );
};

const CampaignStageTabs: React.FC<StagesProps & Props> = (props) => {
  const { queryReference, currentStage, onStageChange } = props;

  const data = usePreloadedQuery(CampaignStageFilterQuery, queryReference);

  return (
    <CampaignStages
      activeCampaignsCount={data?.activeCampaigns?.totalCount}
      draftCampaignsCount={data?.draftCampaigns?.totalCount}
      completedCampaignsCount={data?.completedCampaigns?.totalCount}
      currentStage={currentStage}
      onStageChange={onStageChange}
    />
  );
};

const CampaignStageFilter: React.FC<Props> = (props) => {
  const { currentStage, onStageChange } = props;

  const [queryReference, loadQuery] = useQueryLoader<QueryType>(CampaignStageFilterQuery);

  useEffect(() => {
    loadQuery({}, { fetchPolicy: 'network-only' });
  }, []);

  if (!queryReference) {
    return <CampaignStages {...props} />;
  }

  return (
    <CampaignStageTabs
      queryReference={queryReference}
      currentStage={currentStage}
      onStageChange={onStageChange}
    />
  );
};

export default CampaignStageFilter;
