/**
 * @generated SignedSource<<218525aac6ba9ac81f231ac4f302198e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CreatorType = "CONTENT" | "INSTAGRAM" | "TIKTOK" | "YOUTUBE";
import { FragmentRefs } from "relay-runtime";
export type SearchResultContent_result$data = {
  readonly creator: {
    readonly id: string;
    readonly profile: {
      readonly acceptsBarter: boolean;
    } | null | undefined;
    readonly type: CreatorType;
    readonly " $fragmentSpreads": FragmentRefs<"SearchResultMedias_creator">;
  } | null | undefined;
  readonly viewed: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"SearchResultAccounts_result" | "SearchResultControls_result">;
  readonly " $fragmentType": "SearchResultContent_result";
};
export type SearchResultContent_result$key = {
  readonly " $data"?: SearchResultContent_result$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchResultContent_result">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SearchResultContent_result",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SearchResultAccounts_result"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SearchResultControls_result"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "creator",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CreatorProfile",
          "kind": "LinkedField",
          "name": "profile",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "acceptsBarter",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SearchResultMedias_creator"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CreatorSearchResult",
  "abstractKey": null
};

(node as any).hash = "657e44a5db800c9f60d37141c64d035f";

export default node;
