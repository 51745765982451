import React, { Suspense } from 'react';

import Creators from '../Creators/Creators';
import Outreach from '../Outreach/Outreach';
import Results from '../Results/Results';
import {
  MESSAGED,
  INVITATION_SENT,
  RECIEVED_APPLICANTS,
  HIRED,
  DECLINED,
  SHIPPING,
  DELIVERABLES,
  POSTING_CONTENT,
  JUST_CONTENT,
  CONTENT_CREATION,
  OVERVIEW,
} from '../NewCampaignTabs/util';
import CampaignOverview from '../CampaignOverview/CampaignOverview';

import {
  CampaignQuery$data,
  CampaignType,
  CampaignPlatform,
} from 'GraphTypes/CampaignQuery.graphql';
import {
  CREATORS,
  AWAITING_LIST,
  ARCHIVED,
  RESULTS,
  APPLICANTS,
  SHIPMENT,
  OUTREACH,
  COMPLETED,
} from 'Constants/general';
import ShipmentTab from 'Page/advertiser/Campaign/Shipment/ShipmentTab';
import styles from 'AdvertiserPage/Brands/Brands.pcss';
import Spinner from 'Atoms/Spinner/Spinner';
import colors from 'Styles/vars/colors.css';

interface Props {
  className?: string;
  section: string;
  campaignId: string;
  email?: string;
  organizationId?: string;
  campaignType?: CampaignType | null;
  campaignPlatform?: CampaignPlatform | null;
  currentUser: CampaignQuery$data['currentUser'];
  isAdminView: boolean;
  publishingRequired: boolean;
  campaign: CampaignQuery$data['campaign'];
  handleBulkMessage: (message: string) => void;
}

const CampaignContent: React.FC<Props> = (props) => {
  const {
    isAdminView,
    campaign,
    campaignId,
    section,
    campaignType,
    campaignPlatform,
    currentUser,
    publishingRequired,
    organizationId,
    email,
    handleBulkMessage,
  } = props;

  if (
    section === CREATORS ||
    section === AWAITING_LIST ||
    section === APPLICANTS ||
    section === COMPLETED ||
    section === MESSAGED ||
    section === INVITATION_SENT ||
    section === RECIEVED_APPLICANTS ||
    section === HIRED ||
    section === DECLINED ||
    section === CONTENT_CREATION ||
    section === ARCHIVED
  ) {
    return (
      <Suspense fallback={null}>
        <Creators
          key={section}
          campaignId={campaignId}
          section={section}
          isAdminView={isAdminView}
          campaignType={campaignType}
          campaignPlatform={campaignPlatform}
          currentUser={currentUser}
          campaign={campaign}
          publishingRequired={publishingRequired}
          organizationId={organizationId}
          handleBulkMessage={handleBulkMessage}
        />
      </Suspense>
    );
  } else if (section === SHIPMENT || section === SHIPPING) {
    return (
      <Suspense fallback={null}>
        <ShipmentTab campaignId={campaignId} />
      </Suspense>
    );
  } else if (section === OUTREACH) {
    return (
      <Suspense
        fallback={
          <div className={styles.preloaderContainer}>
            <Spinner color={colors.colorDark} className={styles.preloader} />
          </div>
        }
      >
        <Outreach campaignId={campaignId} platform={campaignPlatform} />{' '}
      </Suspense>
    );
  } else if (section === RESULTS || section === POSTING_CONTENT || section === JUST_CONTENT) {
    return (
      <Results
        section={section}
        campaignId={campaignId}
        campaignPublishing={!!campaign?.publishingRequired}
        isAdminView={isAdminView}
        currentUser={currentUser}
        campaignType={campaignType}
      />
    );
  } else if (section === DELIVERABLES || section === OVERVIEW) {
    return (
      <Suspense fallback={null}>
        <CampaignOverview campaignId={campaignId} />
      </Suspense>
    );
  }
  return null;
};

export default CampaignContent;
