import React from 'react';

import styles from './Outreach.pcss';
import OutreachDashboard from './OutreachDashboard/OutreachDashboard';

import { CampaignPlatform } from 'GraphTypes/CampaignQuery.graphql';

interface Props {
  campaignId: string;
  platform?: CampaignPlatform | null;
}

const Outreach: React.FC<Props> = (props) => {
  const { campaignId } = props;

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <OutreachDashboard campaignId={campaignId} />
      </div>
    </div>
  );
};

export default Outreach;
