import React, { memo, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { DiscoveryCreatorsContext } from '../../DiscoveryCreatorsContext';

import styles from './DiscoveryHeader.pcss';

import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';

const Header = () => {
  const navigate = useNavigate();
  const { listData, counter, backUrl, listId } = useContext(DiscoveryCreatorsContext);

  const handleBackClick = () => {
    const navState = {
      state: {
        fromDiscovery: true,
        listId,
      },
    };
    if (backUrl) {
      navigate(backUrl, navState);
    } else {
      navigate(-1);
    }
  };

  if (counter.total === null) {
    return null;
  }

  return (
    <div className={styles.root}>
      <AlterButton
        type="grey"
        icon="Arrow-big-left"
        onClick={handleBackClick}
        data-test="discoveryHeader:alterButton:unknown"
      />
      <Text
        type="d2"
        textPriority={Boolean(listData?.name)}
        text={listData?.name || ''}
        msg="discovery.dashboard.lists.header.create_list"
        data-test="discoveryHeader:text:unknown"
      />
    </div>
  );
};

export default memo(Header);
