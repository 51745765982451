import React, { useMemo, MouseEventHandler } from 'react';
import classNames from 'classnames';

import { IconName } from '../types';

import styles from './Icon.pcss';

import googleIcon from 'Images/icons/google.svg';

const additionalIcons = {
  Google: googleIcon,
};

type AdditionalIconsType = keyof typeof additionalIcons;
export type IconNameWithAdditional = IconName | AdditionalIconsType;
type Props = {
  name: IconNameWithAdditional;
  size?: number;
  className?: string;
  color?: 'green' | 'red' | 'sun' | 'white' | 'grey' | 'pink' | 'purple';
  onClick?: MouseEventHandler<HTMLButtonElement>;
};
const Icon: React.FC<Props> = (props) => {
  const { name, size = 24, color = '', className, onClick } = props;

  const additionalStyles = useMemo(
    () => ({
      ...(size ? { fontSize: size } : {}),
    }),
    [size]
  );

  const classList = classNames(
    styles[`icon-${name}`],
    styles[color || ''],
    { [styles.withPointer]: !!onClick },
    className
  );

  if (additionalIcons[name as AdditionalIconsType]) {
    return (
      <img
        src={additionalIcons[name as AdditionalIconsType]}
        className={classList}
        style={{ ...additionalStyles, width: size, height: size }}
      />
    );
  }

  return <i className={classList} onClick={onClick} style={additionalStyles} />;
};

export default Icon;
export type { Props };
