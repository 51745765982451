import React from 'react';
import { graphql } from 'relay-runtime';
import { useLazyLoadQuery } from 'react-relay';

import styles from './AdvertisersCount.pcss';

import Text from 'Components/ui/Text/Text';
import {
  PlanId,
  Currency,
  AdvertisersCountQuery as QueryType,
} from 'GraphTypes/AdvertisersCountQuery.graphql';

const AdvertisersCountQuery = graphql`
  query AdvertisersCountQuery(
    $textQuery: String
    $paying: Boolean
    $planIds: [PlanId!]
    $currencies: [Currency!]
  ) {
    organizations(
      textQuery: $textQuery
      paying: $paying
      planIds: $planIds
      currencies: $currencies
    ) {
      totalCount
    }
  }
`;

interface Props {
  paying: boolean | null;
  planIds: PlanId[];
  currencies: Currency[];
  textQuery: string;
}

const AdvertisersCount: React.FC<Props> = (props) => {
  const { paying, planIds, currencies, textQuery } = props;
  const data = useLazyLoadQuery<QueryType>(AdvertisersCountQuery, {
    paying,
    planIds,
    currencies,
    textQuery,
  });

  const totalCount = data.organizations?.totalCount;

  if (totalCount === undefined) return <div className={styles.stub} />;

  return (
    <Text msg="advertisers.count" formatValues={{ count: totalCount }} className={styles.root} />
  );
};

export default AdvertisersCount;
