import React, { memo } from 'react';

import styles from './SequenceItem.pcss';

import AlterButton from 'Components/ui/AlterButton/AlterButton';

interface Props {
  onClick: () => void;
}

const SequenceNewItem: React.FC<Props> = (props) => {
  const { onClick } = props;
  return (
    <AlterButton
      type="white"
      bordered={false}
      fluid
      hover={false}
      onClick={onClick}
      icon="Add-plus"
      text="Add follow up"
      className={styles.addButton}
    />
  );
};

export default memo(SequenceNewItem);
