import React, { Fragment, memo, useMemo } from 'react';
import Handlebars from 'handlebars';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './OutreachPreview.pcss';

import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Accordion from 'Components/ui/Accordion/Accordion';
import Avatar from 'Atoms/Avatar/Avatar';
import { OutreachPreviewQuery } from 'GraphTypes/OutreachPreviewQuery.graphql';

const outreachPreviewQuery = graphql`
  query OutreachPreviewQuery($id: ID!, $batchId: ID!) {
    campaign(id: $id) {
      sampleOutreachTemplateVariables {
        name
        value
      }
    }
    outreachBatch(id: $batchId) {
      id
      senderEmail
      senderName
      sequenceBlueprint {
        name
        steps {
          edges {
            node {
              id
              template {
                htmlBody
                subject
              }
            }
          }
        }
      }
    }
  }
`;

const OutreachPreview: React.FC = () => {
  const navigate = useNavigate();
  const {
    id: campaignId,
    batchId,
    index,
  } = useParams<{ id: string; batchId: string; index?: string }>();

  const data = useLazyLoadQuery<OutreachPreviewQuery>(outreachPreviewQuery, {
    id: campaignId as string,
    batchId: batchId as string,
  });

  let sequenceList = data.outreachBatch?.sequenceBlueprint?.steps?.edges || [];

  if (index !== undefined && !Number.isNaN(index)) {
    sequenceList = [sequenceList[Number(index)]];
  }

  const variables = useMemo(() => {
    return data.campaign?.sampleOutreachTemplateVariables?.reduce((acc, item) => {
      return { ...acc, [item.name]: item.value };
    }, {}) as TemplateSpecification;
  }, [data.campaign?.sampleOutreachTemplateVariables]);

  const senderName = data.outreachBatch?.senderName;
  const senderEmail = data.outreachBatch?.senderEmail;

  const handleBackClick = () => {
    navigate(-1);
  };

  if (!sequenceList.length) return null;

  return (
    <div className={styles.root}>
      <div className={styles.previewContainer}>
        <div className={styles.titleContainer}>
          <div className={styles.backContainer}>
            <AlterButton
              icon="Arrow-big-left"
              className={styles.resetTemplate}
              onClick={handleBackClick}
              type="grey"
            />
            <Text
              type="d2"
              className={styles.title}
              text={data.outreachBatch?.sequenceBlueprint?.name || 'Preview'}
            />
          </div>
        </div>
        <div className={styles.mailList}>
          {sequenceList.map((step, index) => {
            const item = step?.node;
            if (!item) return null;
            const body = Handlebars.compile(item.template?.htmlBody || '');
            const compliledBody = body(variables);
            const subject = Handlebars.compile(item.template?.subject || '');
            const compliledSubject = subject(variables);
            return (
              <Fragment key={`${item.id}_${index}`}>
                <Accordion
                  className={styles.mailPreviewContainer}
                  items={[
                    {
                      arrowPosition: 'right',
                      openedByDefault: true,
                      title: (
                        <div className={styles.title}>
                          <Text
                            type="md"
                            className={styles.titleSubjectText}
                            msg="invite_outreach_creators_modal.preview.subject"
                          />
                          <Text type="md" className={styles.subjectText} text={compliledSubject} />
                        </div>
                      ),
                      content: (
                        <div className={styles.mailPreview}>
                          <div className={styles.userInfo}>
                            <Avatar />
                            <div className={styles.backContainer}>
                              <Text text={`${senderName || 'Rodrigo from Insense'}`} />
                              <Text
                                type="label"
                                className={styles.previewMailSecondaryText}
                                text={senderEmail || 'rodrigo@insense.pro'}
                              />
                            </div>
                          </div>
                          <div
                            dangerouslySetInnerHTML={{ __html: compliledBody }}
                            className={styles.templateContent}
                          />
                        </div>
                      ),
                    },
                  ]}
                />
              </Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default memo(OutreachPreview);
