import React, { useEffect, useState } from 'react';

import styles from './ResearchBanner.pcss';

import { amplitude } from 'Helpers/amplitude';
import ls from 'Util/localStorage';
import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import TextButton from 'Components/ui/TextButton/TextButton';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import researchImg from 'Images/general/research_banner.png';
import research2xImg from 'Images/general/research_banner_2x.png';

const TYPEFORM_LINK = 'https://insense.typeform.com/research-group';

interface Props {
  place: 'home' | 'dashboard';
}

const ResearchBanner: React.FC<Props> = (props) => {
  const { place } = props;
  const researchBannerShown = ls.get('research_banner');

  const [isVisible, setVisibile] = useState(!researchBannerShown);

  const handleHide = () => {
    ls.set('research_banner', true);
    setVisibile(false);
    amplitude.sendEvent<511>({
      id: '511',
      category: 'research_banner',
      name: 'close',
      param: { place },
    });
  };

  useEffect(() => {
    if (isVisible) {
      amplitude.sendEvent<509>({
        id: '509',
        category: 'research_banner',
        name: 'show',
        param: { place },
      });
    }
  }, []);

  const handleJoinClick = () => {
    amplitude.sendEvent<510>({
      id: '510',
      category: 'research_banner',
      name: 'cta_click',
      param: { place },
    });
  };

  if (!isVisible) return null;

  return (
    <div className={styles.root}>
      <div>
        <Text type="label" msg="client_research_banner.title_1" />
        <div className={styles.title}>
          <Text type="h3" msg="client_research_banner.title_2" />
          <Text type="h3" color="pink" msg="client_research_banner.title_3" />
        </div>
      </div>
      <div className={styles.spacer} />
      <img srcSet={`${researchImg} 1x, ${research2xImg} 2x`} className={styles.image} />
      <SmartLink path={TYPEFORM_LINK} onClick={handleJoinClick}>
        <AlterButton type="white" msg="client_research_banner.join" className={styles.joinBtn} />
      </SmartLink>
      <TextButton color="grey" size="xs" icon="Close-small" onClick={handleHide} />
    </div>
  );
};

export default ResearchBanner;
