import React, { useMemo, useEffect, Suspense } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './Creators.pcss';
import Blocklist from './tabs/Blocklist/Blocklist';
import PreviousCollaborations from './tabs/PreviousCollaborations/PreviousCollaborations';
import Favorites from './tabs/Favorites/Favorites';
import Lists from './tabs/Lists/Lists';
import CustomList from './tabs/CustomList/CustomList';
import { tabItems } from './utils';

import Spinner from 'Atoms/Spinner/Spinner';
import Tabs from 'Molecules/Tabs/Tabs';
import Text from 'Components/ui/Text/Text';
import Page from 'Templates/Page/Page';
import { amplitude } from 'Helpers/amplitude';
import {
  CREATORS_BLOCKLIST_ROUTE,
  CREATORS_LIST_ROUTE,
  CREATORS_PREVIOUS_COLLABS_ROUTE,
} from 'Constants/general';

type Props = {
  section: SectionType;
};

const Creators: React.FC<Props> = (props) => {
  const { section } = props;
  const navigate = useNavigate();
  const params = useParams<Params>();

  useEffect(() => {
    amplitude.sendEvent({
      id: 233,
      category: 'pageview',
      name: 'creators_list',
    });
  }, []);

  const handleSectionSelect = (id: SectionType) => {
    switch (id) {
      case 'LISTS': {
        navigate(CREATORS_LIST_ROUTE);
        break;
      }
      case 'BLOCKLIST': {
        navigate(CREATORS_BLOCKLIST_ROUTE);
        break;
      }
      case 'PREVIOUS_COLLABORATION': {
        navigate(CREATORS_PREVIOUS_COLLABS_ROUTE);
        break;
      }
      default: {
        break;
      }
    }
  };

  const tabs = (
    <Tabs currentItemId={section} items={tabItems} filled={true} onChange={handleSectionSelect} />
  );

  const isTitleVisible = useMemo<boolean>(() => {
    return new Set<SectionType>(['LISTS', 'BLOCKLIST', 'PREVIOUS_COLLABORATION']).has(section);
  }, [section]);

  return (
    <Page className={styles.root}>
      <Suspense fallback={<Spinner className={styles.spinner} />}>
        <div className={styles.container}>
          {isTitleVisible && (
            <Text
              type="d2"
              msg="creators.dashboard.title"
              className={styles.title}
              data-test="creators:text:title"
            />
          )}
          <div className={styles.content}>
            {section === 'PREVIOUS_COLLABORATION' && (
              <PreviousCollaborations renderStartHeaderControls={() => tabs} />
            )}
            {section === 'BLOCKLIST' && <Blocklist renderStartHeaderControls={() => tabs} />}
            {section === 'LISTS' && <Lists renderStartHeaderControls={() => tabs} />}
            {section === 'FAVORITES' && <Favorites />}
            {section === 'CUSTOM_LIST' && <CustomList customListId={params?.customListId || ''} />}
          </div>
        </div>
      </Suspense>
    </Page>
  );
};

export default Creators;

// types

type SectionType = 'FAVORITES' | 'BLOCKLIST' | 'PREVIOUS_COLLABORATION' | 'LISTS' | 'CUSTOM_LIST';
type Params = {
  customListId?: string;
};
