import React, { useEffect, useState, useContext, useMemo } from 'react';
import {
  graphql,
  PreloadedQuery,
  useLazyLoadQuery,
  usePaginationFragment,
  usePreloadedQuery,
} from 'react-relay';
import { motion, AnimatePresence } from 'motion/react';
import xor from 'lodash/xor';
import compact from 'lodash/compact';
import classnames from 'classnames';

import CreatorsStub from '../CreatorsStub/CreatorsStub';
import { CreatorsListQuery } from '../CreatorsList';

import styles from './CreatorsListContainer.pcss';
import CreatorCard from './CreatorCard/CreatorCard';
import CreatorsBulkActions from './CreatorsBulkActions/CreatorsBulkActions';

import LoaderHandlerWithHooks from 'Organisms/LoaderHandler/NewLoaderHandler';
import Text from 'Components/ui/Text/Text';
import { CreatorsListContainer_campaign$key } from 'GraphTypes/CreatorsListContainer_campaign.graphql';
import {
  ProjectOrder,
  AdvertiserProjectStage,
  CreatorsListQuery as QueryType,
  DateInterval,
} from 'GraphTypes/CreatorsListQuery.graphql';
import { Checkbox } from 'Components/ui/Checkbox';
import { CreatorsListContainerQuery } from 'GraphTypes/CreatorsListContainerQuery.graphql';
import { CampaignContext } from 'AdvertiserPage/Campaign/Campaign.Context';
import { ChatDrawerContext } from 'AdvertiserPage/Campaign/ChatDrawer/ChatDrawer.Context';
import { FeatureFlagsContext } from 'Containers/FeatureFlags/FeatureFlags.Context';
import { amplitude } from 'Helpers/amplitude';
import { APPLICANTS } from 'Constants/general';
import {
  MESSAGED,
  RECIEVED_APPLICANTS,
  HIRED,
  CONTENT_CREATION,
  CREATORS,
} from 'Page/advertiser/Campaign/NewCampaignTabs/util';
import Button from 'Components/ui/Button/Button';

const COUNT = 10;
const OFFSET = 70;

interface Props {
  campaignId: string;
  section?: string;
  organizationId?: string;
  isAdminView: boolean;
  shortlisted?: boolean | null;
  isBulkAction: boolean;
  invitedByOrganizationMember?: boolean;
  outreach?: boolean;
  isLongTermCampaign?: boolean;
  orderBy?: ProjectOrder;
  stages?: AdvertiserProjectStage[];
  textQuery?: string;
  archived: boolean;
  canCreateMultipleDeals: boolean;
  createdByAdmin?: boolean | null;
  creatorsStages?: AdvertiserProjectStage[];
  defaultStages?: AdvertiserProjectStage[];
  archivedStages?: AdvertiserProjectStage[];
  portfolioTypeName: string;
  portfolioCategoryName: string;
  screeningQuestionIds?: string[];
  hasBrandMessageBeforeLaunch?: boolean;
  archivedAfterLaunch?: boolean;
  archivedBeforeLaunch?: boolean;
  searchQuery?: any;
  queryReference: PreloadedQuery<QueryType>;
  draftDeadlineIntervals: DateInterval[];
  publicationDeadlineIntervals: DateInterval[];
  handleBulkMessage: (message: string | number) => void;
  handleArchived?: () => void;
}

const CreatorsListContainer: React.FC<Props> = (props) => {
  const {
    organizationId,
    campaignId,
    isAdminView,
    creatorsStages,
    defaultStages,
    archivedStages,
    portfolioTypeName,
    portfolioCategoryName,
    canCreateMultipleDeals,
    isLongTermCampaign,
    queryReference,
    isBulkAction,
    shortlisted,
    textQuery,
    stages,
    archived,
    createdByAdmin,
    outreach,
    invitedByOrganizationMember,
    draftDeadlineIntervals,
    publicationDeadlineIntervals,
    screeningQuestionIds,
    searchQuery,
    hasBrandMessageBeforeLaunch,
    archivedAfterLaunch,
    archivedBeforeLaunch,
    section,
    handleBulkMessage,
  } = props;
  const [tempIdsForBulkAction, setIdsForBulkAction] = useState<string[]>([]);

  const { hasFeatureFlag } = useContext(FeatureFlagsContext);
  const { projectId: projectIdForDarwer, onProjectIdSet } = useContext(ChatDrawerContext);

  const hasNewCampaignTabs = hasFeatureFlag('new_campaign_tabs');

  const queryData = usePreloadedQuery<QueryType>(CreatorsListQuery, queryReference);

  const bulkData = useLazyLoadQuery<CreatorsListContainerQuery>(
    graphql`
      query CreatorsListContainerQuery(
        $campaignId: ID!
        $shortlisted: Boolean
        $textQuery: String
        $stages: [AdvertiserProjectStage!]
        $archived: Boolean
        $createdByAdmin: Boolean
        $outreach: Boolean
        $invitedByOrganizationMember: Boolean
        $hasBrandMessageBeforeLaunch: Boolean
        $archivedAfterLaunch: Boolean
        $archivedBeforeLaunch: Boolean
        $draftDeadlineIntervals: [DateInterval!]
        $publicationDeadlineIntervals: [DateInterval!]
        $screeningQuestionIds: [ID!]
        $searchQuery: SearchQueryInput
      ) {
        campaign(id: $campaignId) {
          projects(
            shortlisted: $shortlisted
            textQuery: $textQuery
            stages: $stages
            archived: $archived
            createdByAdmin: $createdByAdmin
            invitedByOrganizationMember: $invitedByOrganizationMember
            outreach: $outreach
            draftDeadlineIntervals: $draftDeadlineIntervals
            publicationDeadlineIntervals: $publicationDeadlineIntervals
            screeningQuestionIds: $screeningQuestionIds
            searchQuery: $searchQuery
            hasBrandMessageBeforeLaunch: $hasBrandMessageBeforeLaunch
            archivedAfterLaunch: $archivedAfterLaunch
            archivedBeforeLaunch: $archivedBeforeLaunch
            orderBy: LAST_ADDED
          ) {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    `,
    {
      campaignId,
      shortlisted,
      textQuery,
      stages,
      archived,
      createdByAdmin,
      outreach,
      invitedByOrganizationMember,
      draftDeadlineIntervals,
      publicationDeadlineIntervals,
      screeningQuestionIds,
      searchQuery,
      hasBrandMessageBeforeLaunch,
      archivedAfterLaunch,
      archivedBeforeLaunch,
    },
    { fetchPolicy: 'network-only' }
  );

  useEffect(() => {
    setIdsForBulkAction([]);
  }, [
    shortlisted,
    textQuery,
    stages,
    archived,
    createdByAdmin,
    outreach,
    invitedByOrganizationMember,
    draftDeadlineIntervals,
    publicationDeadlineIntervals,
    screeningQuestionIds,
    searchQuery,
    hasBrandMessageBeforeLaunch,
    archivedAfterLaunch,
    archivedBeforeLaunch,
  ]);

  const fragmentData = usePaginationFragment<QueryType, CreatorsListContainer_campaign$key>(
    graphql`
      fragment CreatorsListContainer_campaign on Query
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 10 }
        cursor: { type: "String" }
        orderBy: { type: "ProjectOrder" }
        organizationId: { type: "ID!" }
        campaignId: { type: "ID!" }
        stages: { type: "[AdvertiserProjectStage!]" }
        shortlisted: { type: "Boolean" }
        textQuery: { type: "String" }
        archived: { type: "Boolean", defaultValue: false }
        createdByAdmin: { type: "Boolean" }
        invitedByOrganizationMember: { type: "Boolean" }
        outreach: { type: "Boolean" }
        screeningQuestionIds: { type: "[ID!]" }
        hasBrandMessageBeforeLaunch: { type: "Boolean" }
        archivedAfterLaunch: { type: "Boolean" }
        archivedBeforeLaunch: { type: "Boolean" }
        searchQuery: { type: "SearchQueryInput" }
        draftDeadlineIntervals: { type: "[DateInterval!]", defaultValue: [] }
        publicationDeadlineIntervals: { type: "[DateInterval!]", defaultValue: [] }
      )
      @refetchable(queryName: "CreatorsListContainerPaginationList") {
        campaign(id: $campaignId) {
          activation {
            paused
          }
          projects(
            first: $count
            after: $cursor
            orderBy: $orderBy
            shortlisted: $shortlisted
            textQuery: $textQuery
            stages: $stages
            archived: $archived
            createdByAdmin: $createdByAdmin
            invitedByOrganizationMember: $invitedByOrganizationMember
            outreach: $outreach
            draftDeadlineIntervals: $draftDeadlineIntervals
            publicationDeadlineIntervals: $publicationDeadlineIntervals
            screeningQuestionIds: $screeningQuestionIds
            searchQuery: $searchQuery
            hasBrandMessageBeforeLaunch: $hasBrandMessageBeforeLaunch
            archivedAfterLaunch: $archivedAfterLaunch
            archivedBeforeLaunch: $archivedBeforeLaunch
          ) @connection(key: "Projects_projects", filters: []) {
            edges {
              node {
                id
                launch {
                  id
                }
                completion {
                  id
                }
                creator {
                  id
                }
                hasLaunchOrBrandMessage
                ...CreatorCard_project @arguments(organizationId: $organizationId)
              }
            }
            pageInfo {
              hasNextPage
              endCursor
            }
          }
        }
      }
    `,
    queryData
  );

  const items = Array.from(fragmentData?.data.campaign?.projects.edges || []);

  const exceptionList = useMemo(() => {
    return compact(
      items.map((item) => {
        if (!item?.node?.id) return null;

        const { id, hasLaunchOrBrandMessage, launch, completion } = item.node;

        if (
          hasNewCampaignTabs &&
          (section === APPLICANTS || section === RECIEVED_APPLICANTS) &&
          hasLaunchOrBrandMessage
        ) {
          return id;
        }

        if (
          hasNewCampaignTabs &&
          (section === APPLICANTS || section === RECIEVED_APPLICANTS || section === MESSAGED) &&
          launch
        ) {
          return id;
        }

        if (
          hasNewCampaignTabs &&
          (section === APPLICANTS ||
            section === RECIEVED_APPLICANTS ||
            section === MESSAGED ||
            section === CREATORS ||
            section === CONTENT_CREATION ||
            section === HIRED) &&
          completion
        ) {
          return id;
        }
        return null;
      })
    );
  }, [items]);

  const tempIdsForBulkSelectAll: Array<string> =
    bulkData?.campaign?.projects?.edges?.map((edge) => edge?.node?.id || '') || [];

  const idsForBulkSelectAll = useMemo(() => {
    return tempIdsForBulkSelectAll.reduce((acc, currentItem) => {
      if (exceptionList.includes(currentItem)) {
        return xor(acc, currentItem);
      }
      return [...acc, currentItem];
    }, []);
  }, [tempIdsForBulkSelectAll, exceptionList]);

  const idsForBulkAction = useMemo(() => {
    return tempIdsForBulkAction.reduce((acc, currentItem) => {
      if (exceptionList.includes(currentItem)) {
        return xor(acc, currentItem);
      }
      return [...acc, currentItem];
    }, []);
  }, [tempIdsForBulkAction, exceptionList]);

  const totalCount = idsForBulkSelectAll.length || 0;

  useEffect(() => {
    if (!projectIdForDarwer && idsForBulkSelectAll[0]) {
      onProjectIdSet(idsForBulkSelectAll[0]);
    }
  }, [idsForBulkSelectAll]);

  if (items?.length === 0) {
    return (
      <Text msg="new_campaign.no_creators" data-test="creatorsListContainer:text:noCreators" />
    );
  }

  const handleIdsClear = () => {
    setIdsForBulkAction([]);
  };

  const handleBulkMessageSuccess = () => {
    handleBulkMessage(`+${idsForBulkAction.length}`);
    handleIdsClear();
  };

  const handleSelectAll = () => {
    amplitude.sendEvent<486>({
      id: '486',
      category: 'campaign',
      name: 'bulk_action_select_all',
      param: {
        campaign_id: campaignId,
        mode: idsForBulkAction.length === idsForBulkSelectAll.length ? 'deselect' : 'select',
        selection_size: idsForBulkAction.length,
      },
    });

    if (idsForBulkAction.length > 0) {
      handleIdsClear();
    } else {
      setIdsForBulkAction(idsForBulkSelectAll);
    }
  };

  const cards = items?.map((item) => {
    if (!item?.node) return null;

    const { id, hasLaunchOrBrandMessage, launch, completion } = item.node;
    const creatorId = item?.node?.creator?.id || '';

    const isSelected = idsForBulkAction.includes(id);

    const handleProjectSelect = (newId: string) => {
      const prevCount = idsForBulkAction.length;
      const newList = xor(idsForBulkAction, [newId]);
      const newCount = newList.length;
      amplitude.sendEvent<491>({
        name: 'bulk_creator_selected',
        category: 'campaign',
        id: '491',
        param: {
          project_id: item?.node?.id,
          mode: newCount > prevCount ? 'select' : 'deselect',
        },
      });
      setIdsForBulkAction(newList);
    };

    if (
      hasNewCampaignTabs &&
      (section === APPLICANTS || section === RECIEVED_APPLICANTS) &&
      hasLaunchOrBrandMessage
    ) {
      return null;
    }

    if (
      hasNewCampaignTabs &&
      (section === APPLICANTS || section === RECIEVED_APPLICANTS || section === MESSAGED) &&
      launch
    ) {
      return null;
    }

    if (
      hasNewCampaignTabs &&
      (section === APPLICANTS ||
        section === RECIEVED_APPLICANTS ||
        section === MESSAGED ||
        section === CREATORS ||
        section === CONTENT_CREATION ||
        section === HIRED) &&
      completion
    ) {
      return null;
    }

    return (
      // <motion.li key={id} exit={{ height: 0, opacity: 0 }}>
      <CreatorCard
        isBulkAction={isBulkAction}
        project={item.node}
        isAdminView={isAdminView}
        organizationId={organizationId}
        campaignId={campaignId}
        key={id}
        isSelected={isSelected}
        creatorId={creatorId}
        creatorsStages={creatorsStages}
        defaultStages={defaultStages}
        archivedStages={archivedStages}
        portfolioTypeName={portfolioTypeName}
        portfolioCategoryName={portfolioCategoryName}
        canCreateMultipleDeals={canCreateMultipleDeals}
        isLongTermCampaign={isLongTermCampaign}
        onSelect={handleProjectSelect}
      />
      // </motion.li>
    );
  });

  return (
    <div className={styles.creatorsContainerRoot}>
      {!!totalCount && (
        <div
          className={classnames(styles.creatorsCount, { [styles.pointer]: isBulkAction })}
          onClick={isBulkAction ? handleSelectAll : undefined}
        >
          {isBulkAction && (
            <Checkbox
              variant={idsForBulkAction.length !== totalCount ? 'indeterminate' : 'normal'}
              checked={idsForBulkAction.length > 0}
              className={styles.checkbox}
              data-test="creatorsListContainer:checkbox:checkbox"
            />
          )}
          <Text
            type="md"
            color="dark"
            formatValues={{ count: totalCount || '-' }}
            msg={isBulkAction ? 'campaign.creators_bulk_select' : 'campaign.creators_count'}
            data-test="creatorsListContainer:text:unknown"
          />
        </div>
      )}
      <LoaderHandlerWithHooks
        relay={fragmentData}
        offset={OFFSET}
        count={COUNT}
        items={items}
        preloader={<CreatorsStub />}
      >
        <div className={styles.cards}>
          {/* <AnimatePresence>{cards}</AnimatePresence> */}
          {cards}
        </div>
      </LoaderHandlerWithHooks>
      {!!idsForBulkAction.length && isBulkAction && (
        <CreatorsBulkActions
          idsForBulkAction={idsForBulkAction}
          onSuccess={handleBulkMessageSuccess}
          allSelected={idsForBulkAction.length === idsForBulkSelectAll.length}
          campaignId={campaignId}
        />
      )}
    </div>
  );
};

export default CreatorsListContainer;
