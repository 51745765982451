/**
 * @generated SignedSource<<5efec48fa902e4f22a6112e06bdd7c8e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Stripe_CardBrand = "AMEX" | "DINERS" | "DISCOVER" | "JCB" | "MASTERCARD" | "UNIONPAY" | "UNKNOWN" | "VISA";
export type Stripe_PaymentMethodType = "CARD";
export type Stripe_AttachPaymentMethodToSubscriptionInput = {
  clientMutationId?: string | null | undefined;
  stripePaymentMethodId: string;
};
export type AttachPaymentMethodToSubscriptionMutation$variables = {
  input: Stripe_AttachPaymentMethodToSubscriptionInput;
};
export type AttachPaymentMethodToSubscriptionMutation$data = {
  readonly stripe: {
    readonly attachPaymentMethodToSubscription: {
      readonly clientMutationId: string | null | undefined;
      readonly paymentMethod: {
        readonly brand?: Stripe_CardBrand | null | undefined;
        readonly createdAt?: any;
        readonly expMonth?: number | null | undefined;
        readonly expYear?: number | null | undefined;
        readonly id?: string;
        readonly last4?: string | null | undefined;
        readonly stripeId?: string;
        readonly type?: Stripe_PaymentMethodType;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type AttachPaymentMethodToSubscriptionMutation = {
  response: AttachPaymentMethodToSubscriptionMutation$data;
  variables: AttachPaymentMethodToSubscriptionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stripeId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "brand",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expYear",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expMonth",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "last4",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AttachPaymentMethodToSubscriptionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Stripe_Mutation",
        "kind": "LinkedField",
        "name": "stripe",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Stripe_AttachPaymentMethodToSubscriptionPayload",
            "kind": "LinkedField",
            "name": "attachPaymentMethodToSubscription",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "paymentMethod",
                "plural": false,
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/)
                    ],
                    "type": "Stripe_Card",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AttachPaymentMethodToSubscriptionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Stripe_Mutation",
        "kind": "LinkedField",
        "name": "stripe",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Stripe_AttachPaymentMethodToSubscriptionPayload",
            "kind": "LinkedField",
            "name": "attachPaymentMethodToSubscription",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "paymentMethod",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/)
                    ],
                    "type": "Stripe_Card",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d55a91038fe36374fc41dfed5e5bdacc",
    "id": null,
    "metadata": {},
    "name": "AttachPaymentMethodToSubscriptionMutation",
    "operationKind": "mutation",
    "text": "mutation AttachPaymentMethodToSubscriptionMutation(\n  $input: Stripe_AttachPaymentMethodToSubscriptionInput!\n) {\n  stripe {\n    attachPaymentMethodToSubscription(input: $input) {\n      clientMutationId\n      paymentMethod {\n        __typename\n        ... on Stripe_Card {\n          id\n          stripeId\n          brand\n          createdAt\n          expYear\n          expMonth\n          last4\n          type\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "19a646dd29ac3cce7e8a268e38fb213e";

export default node;
