/**
 * @generated SignedSource<<81b4ba2db20bbd5005a30a34587b46cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreatorImportPlatform = "INSTAGRAM" | "TIKTOK" | "YOUTUBE";
export type ImportCreatorsInput = {
  campaignId: string;
  clientMutationId?: string | null | undefined;
  csvFilename?: string | null | undefined;
  id?: string | null | undefined;
  platform: CreatorImportPlatform;
  usernames: ReadonlyArray<string>;
};
export type ImportCreatorsMutation$variables = {
  input: ImportCreatorsInput;
};
export type ImportCreatorsMutation$data = {
  readonly importCreators: {
    readonly campaign: {
      readonly creatorImport: {
        readonly completed: boolean;
        readonly id: string;
        readonly importedCreators: number;
        readonly totalCreators: number;
      } | null | undefined;
      readonly id: string;
    } | null | undefined;
  } | null | undefined;
};
export type ImportCreatorsMutation = {
  response: ImportCreatorsMutation$data;
  variables: ImportCreatorsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ImportCreatorsPayload",
    "kind": "LinkedField",
    "name": "importCreators",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CreatorImport",
            "kind": "LinkedField",
            "name": "creatorImport",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "importedCreators",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCreators",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "completed",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ImportCreatorsMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ImportCreatorsMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "1611737f236c667e8fbd1e05a383d251",
    "id": null,
    "metadata": {},
    "name": "ImportCreatorsMutation",
    "operationKind": "mutation",
    "text": "mutation ImportCreatorsMutation(\n  $input: ImportCreatorsInput!\n) {\n  importCreators(input: $input) {\n    campaign {\n      id\n      creatorImport {\n        id\n        importedCreators\n        totalCreators\n        completed\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "75d8c80a91e0db51f07fd96169afaf77";

export default node;
