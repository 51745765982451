import React, { Component } from 'react';
import track from 'react-tracking';
import classnames from 'classnames';

import styles from './Addition.css';

import Menu from 'Components/SearchParams/Form/Menu/Menu';

@track((props) => ({
  element: props.name,
}))
class Addition extends Component {
  constructor() {
    super();

    this.state = {
      open: false,
    };

    this.onClick = this.onClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.sendEvent = this.sendEvent.bind(this);
    this.checkActiveState = this.checkActiveState.bind(this);
    this.onToggleOpenState = this.onToggleOpenState.bind(this);
  }

  checkActiveState(id) {
    const { value } = this.props;

    return !!value[id];
  }

  checkClick() {
    document.addEventListener('click', this.onClick);
  }

  onToggleOpenState() {
    const { open } = this.state;
    this.setState({ open: !open });
    if (!open) {
      this.checkClick();
    } else {
      document.removeEventListener('click', this.onClick);
    }
  }

  onClick(event) {
    const { container } = this.refs;
    const isClickInside = container.contains(event.target);
    if (!isClickInside) {
      this.onToggleOpenState();
    }
  }

  @track((props, state, [newValue]) => ({
    event: 'selected',
    payload: {
      value: newValue,
    },
  }))
  sendEvent(newValue) {}

  onChange(data) {
    const { value, onChange } = this.props;
    const { id } = data;
    const newValue = value[id] ? null : true;
    this.sendEvent(newValue);
    onChange({ [id]: newValue });
  }

  render() {
    const { open } = this.state;
    const { list } = this.props;

    return (
      <div ref="container" className={classnames(styles.root, { [styles.open]: open })}>
        <div className={styles.panel} onClick={this.onToggleOpenState}>
          <div className={styles.icon} />
        </div>
        {open && (
          <div className={styles.menu}>
            <Menu items={list} onChange={this.onChange} checkActiveState={this.checkActiveState} />
          </div>
        )}
      </div>
    );
  }
}

export default Addition;
