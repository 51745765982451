import React from 'react';
import findIndex from 'lodash/findIndex';

import Spinner from 'Atoms/Spinner/Spinner';
import Menu from 'Components/SearchParams/Form/Menu/Menu';

const AutocompleteMenu = (props) => {
  const checkActiveState = (id) => {
    const { value, isSingle } = props;
    if (isSingle) {
      return value === id;
    }
    const index = findIndex(value, (valueItem) => {
      return valueItem.id === id;
    });

    return index !== -1;
  };
  const { isLoading } = props;

  const additionalEl = isLoading ? <Spinner size="sm" style={{ margin: '0 auto' }} /> : null;

  return (
    <Menu
      dataConfig="node"
      checkActiveState={checkActiveState}
      additionalEl={additionalEl}
      {...props}
    />
  );
};

export default AutocompleteMenu;
