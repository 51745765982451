/**
 * @generated SignedSource<<43333b1a1707a1c7cbed0a58ef32e610>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type ProjectUnreadCountChangedSubscription$variables = Record<PropertyKey, never>;
export type ProjectUnreadCountChangedSubscription$data = {
  readonly projectUnreadCountChanged: {
    readonly campaign: {
      readonly acceptedCounter: {
        readonly totalCount: number;
      };
      readonly acceptedProjects: {
        readonly totalCount: number;
      };
      readonly applicantsWithUnreadMesseges: {
        readonly hasItems: boolean;
      };
      readonly archiveEvents: {
        readonly unreadCount: number;
      };
      readonly completedCounter: {
        readonly totalCount: number;
      };
      readonly completedProjects: {
        readonly totalCount: number;
      };
      readonly contentCreationUnreadMesseges: {
        readonly hasItems: boolean;
      };
      readonly contentSubmittedProjects: {
        readonly totalCount: number;
      };
      readonly inProgressCounter: {
        readonly totalCount: number;
      };
      readonly launchedProjects: {
        readonly totalCount: number;
      };
      readonly messagedCounter: {
        readonly totalCount: number;
      };
      readonly projectEvents: {
        readonly unreadCount: number;
      };
      readonly publishingStartedProjects: {
        readonly totalCount: number;
      };
    };
    readonly events: {
      readonly unreadCount: number;
    } | null | undefined;
    readonly id: string;
  };
};
export type ProjectUnreadCountChangedSubscription = {
  response: ProjectUnreadCountChangedSubscription$data;
  variables: ProjectUnreadCountChangedSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "unreadCount",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectEventConnection",
  "kind": "LinkedField",
  "name": "events",
  "plural": false,
  "selections": (v1/*: any*/),
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectEventConnection",
  "kind": "LinkedField",
  "name": "projectEvents",
  "plural": false,
  "selections": (v1/*: any*/),
  "storageKey": null
},
v4 = {
  "alias": "archiveEvents",
  "args": [
    {
      "kind": "Literal",
      "name": "archived",
      "value": true
    }
  ],
  "concreteType": "ProjectEventConnection",
  "kind": "LinkedField",
  "name": "projectEvents",
  "plural": false,
  "selections": (v1/*: any*/),
  "storageKey": "projectEvents(archived:true)"
},
v5 = {
  "kind": "Literal",
  "name": "archived",
  "value": false
},
v6 = {
  "kind": "Literal",
  "name": "stages",
  "value": [
    "ACCEPTED"
  ]
},
v7 = {
  "kind": "Literal",
  "name": "withUnreadEvents",
  "value": true
},
v8 = [
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/)
],
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v10 = {
  "alias": "acceptedProjects",
  "args": (v8/*: any*/),
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": "projects(archived:false,stages:[\"ACCEPTED\"],withUnreadEvents:true)"
},
v11 = {
  "alias": "launchedProjects",
  "args": [
    (v5/*: any*/),
    {
      "kind": "Literal",
      "name": "stages",
      "value": [
        "LAUNCHED"
      ]
    },
    (v7/*: any*/)
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": "projects(archived:false,stages:[\"LAUNCHED\"],withUnreadEvents:true)"
},
v12 = {
  "alias": "contentSubmittedProjects",
  "args": [
    (v5/*: any*/),
    {
      "kind": "Literal",
      "name": "stages",
      "value": [
        "CONTENT_SUBMITTED"
      ]
    },
    (v7/*: any*/)
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": "projects(archived:false,stages:[\"CONTENT_SUBMITTED\"],withUnreadEvents:true)"
},
v13 = {
  "alias": "publishingStartedProjects",
  "args": [
    (v5/*: any*/),
    {
      "kind": "Literal",
      "name": "stages",
      "value": [
        "PUBLISHING_STARTED"
      ]
    },
    (v7/*: any*/)
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": "projects(archived:false,stages:[\"PUBLISHING_STARTED\"],withUnreadEvents:true)"
},
v14 = [
  (v5/*: any*/),
  {
    "kind": "Literal",
    "name": "stages",
    "value": [
      "COMPLETED"
    ]
  },
  (v7/*: any*/)
],
v15 = {
  "alias": "completedProjects",
  "args": (v14/*: any*/),
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": "projects(archived:false,stages:[\"COMPLETED\"],withUnreadEvents:true)"
},
v16 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "hasItems",
    "storageKey": null
  }
],
v17 = {
  "alias": "applicantsWithUnreadMesseges",
  "args": (v8/*: any*/),
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v16/*: any*/),
  "storageKey": "projects(archived:false,stages:[\"ACCEPTED\"],withUnreadEvents:true)"
},
v18 = {
  "alias": "contentCreationUnreadMesseges",
  "args": [
    (v5/*: any*/),
    {
      "kind": "Literal",
      "name": "stages",
      "value": [
        "LAUNCHED",
        "CONTENT_SUBMITTED",
        "PUBLISHING_STARTED",
        "COMPLETED"
      ]
    },
    (v7/*: any*/)
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v16/*: any*/),
  "storageKey": "projects(archived:false,stages:[\"LAUNCHED\",\"CONTENT_SUBMITTED\",\"PUBLISHING_STARTED\",\"COMPLETED\"],withUnreadEvents:true)"
},
v19 = {
  "alias": "acceptedCounter",
  "args": [
    (v5/*: any*/),
    {
      "kind": "Literal",
      "name": "hasBrandMessageBeforeLaunch",
      "value": false
    },
    (v6/*: any*/),
    (v7/*: any*/)
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": "projects(archived:false,hasBrandMessageBeforeLaunch:false,stages:[\"ACCEPTED\"],withUnreadEvents:true)"
},
v20 = {
  "alias": "messagedCounter",
  "args": [
    (v5/*: any*/),
    {
      "kind": "Literal",
      "name": "hasBrandMessageBeforeLaunch",
      "value": true
    },
    (v6/*: any*/),
    (v7/*: any*/)
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": "projects(archived:false,hasBrandMessageBeforeLaunch:true,stages:[\"ACCEPTED\"],withUnreadEvents:true)"
},
v21 = {
  "alias": "inProgressCounter",
  "args": [
    (v5/*: any*/),
    {
      "kind": "Literal",
      "name": "stages",
      "value": [
        "LAUNCHED",
        "CONTENT_SUBMITTED",
        "PUBLISHING_STARTED"
      ]
    },
    (v7/*: any*/)
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": "projects(archived:false,stages:[\"LAUNCHED\",\"CONTENT_SUBMITTED\",\"PUBLISHING_STARTED\"],withUnreadEvents:true)"
},
v22 = {
  "alias": "completedCounter",
  "args": (v14/*: any*/),
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": "projects(archived:false,stages:[\"COMPLETED\"],withUnreadEvents:true)"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectUnreadCountChangedSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "projectUnreadCountChanged",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v15/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProjectUnreadCountChangedSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "projectUnreadCountChanged",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v15/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ce5297381f28ceeda09e03f73f494020",
    "id": null,
    "metadata": {},
    "name": "ProjectUnreadCountChangedSubscription",
    "operationKind": "subscription",
    "text": "subscription ProjectUnreadCountChangedSubscription {\n  projectUnreadCountChanged {\n    id\n    events {\n      unreadCount\n    }\n    campaign {\n      projectEvents {\n        unreadCount\n      }\n      archiveEvents: projectEvents(archived: true) {\n        unreadCount\n      }\n      acceptedProjects: projects(stages: [ACCEPTED], archived: false, withUnreadEvents: true) {\n        totalCount\n      }\n      launchedProjects: projects(stages: [LAUNCHED], archived: false, withUnreadEvents: true) {\n        totalCount\n      }\n      contentSubmittedProjects: projects(stages: [CONTENT_SUBMITTED], archived: false, withUnreadEvents: true) {\n        totalCount\n      }\n      publishingStartedProjects: projects(stages: [PUBLISHING_STARTED], archived: false, withUnreadEvents: true) {\n        totalCount\n      }\n      completedProjects: projects(stages: [COMPLETED], archived: false, withUnreadEvents: true) {\n        totalCount\n      }\n      applicantsWithUnreadMesseges: projects(stages: [ACCEPTED], archived: false, withUnreadEvents: true) {\n        hasItems\n      }\n      contentCreationUnreadMesseges: projects(stages: [LAUNCHED, CONTENT_SUBMITTED, PUBLISHING_STARTED, COMPLETED], archived: false, withUnreadEvents: true) {\n        hasItems\n      }\n      acceptedCounter: projects(stages: [ACCEPTED], hasBrandMessageBeforeLaunch: false, archived: false, withUnreadEvents: true) {\n        totalCount\n      }\n      messagedCounter: projects(stages: [ACCEPTED], hasBrandMessageBeforeLaunch: true, archived: false, withUnreadEvents: true) {\n        totalCount\n      }\n      inProgressCounter: projects(stages: [LAUNCHED, CONTENT_SUBMITTED, PUBLISHING_STARTED], archived: false, withUnreadEvents: true) {\n        totalCount\n      }\n      completedCounter: projects(stages: [COMPLETED], archived: false, withUnreadEvents: true) {\n        totalCount\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2c8489c31f857fd917163a397378fabb";

export default node;
