import React from 'react';
import { useIntl } from 'react-intl';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as ChartTooltip } from 'recharts';

import styles from './Chart.pcss';
import { colorConfig, StatItem, replaceValueWithPostfix } from './utils';

import { Props as TextProps } from 'Components/ui/Text/Text';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import { Color } from 'Components/ui/types';

const axesStyles = {
  fontFamily: 'DMSans',
  fontSize: '12px',
  color: '#888888',
};

interface Props {
  title?: TextProps;
  stats: StatItem[];
  lines: {
    [key: string]: string | number;
  }[];
  isCurrency?: boolean;
}

const Chart: React.FC<Props> = (props) => {
  const { title, stats, lines, isCurrency } = props;

  const intl = useIntl();

  const linesData = lines && lines[0] ? Object.keys(lines[0]) : [];

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        {title && <Text {...title} type="md" data-test="chart:text:unknown" />}
        <div className={styles.headerContainer}>
          {stats.map((item, index) => {
            const { description, key, color, colorOffset = 0, amount } = item;
            return (
              <div key={key} className={styles.infoContainer}>
                <Text
                  text={isCurrency ? `$${amount}` : `${amount}`}
                  type="md"
                  color={
                    (color as Color) ||
                    (index !== undefined
                      ? (colorConfig[index + colorOffset]?.lineColor as Color)
                      : undefined)
                  }
                  data-test="chart:text:unknown"
                />
                <div className={styles.infoDescription}>
                  <Text
                    msg={description?.titleMsg}
                    type="label"
                    className={styles.description}
                    data-test="chart:text:description"
                  />
                  {description?.tooltipMsg && (
                    <Tooltip
                      tooltipMsg={description?.tooltipMsg}
                      place={'top'}
                      id={`${description?.tooltipMsg} id`}
                    >
                      <Icon name={'Info'} size={16} className={styles.tooltipIcon} />
                    </Tooltip>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <LineChart
        width={556}
        height={226}
        data={lines}
        margin={{ top: 38, right: 0, left: -10, bottom: 0 }}
      >
        <XAxis dataKey="date" axisLine={false} tick={axesStyles} />
        <YAxis
          tickCount={3}
          axisLine={false}
          tick={axesStyles}
          tickFormatter={(value) => {
            return replaceValueWithPostfix(value, isCurrency);
          }}
        />
        <CartesianGrid vertical={false} />
        <ChartTooltip
          contentStyle={{
            background: '#333333',
            borderRadius: '8px',
            border: 'none',
            padding: '12px 16px',
          }}
          labelStyle={{
            fontFamily: 'DMSans, DM Sans',
            fontSize: '12px',
            color: '#ffffff',
          }}
          formatter={(value, name, props) => {
            const tooltiplpMsg = intl.formatMessage({
              id: `performance.dashboard.chart.tooltip.${name}`,
            });

            return [isCurrency ? `$${value}` : value, tooltiplpMsg];
          }}
        />
        {linesData.map((item, index) => {
          if (item === 'date') return null;
          return (
            <Line
              key={item}
              type="linear"
              dot={false}
              dataKey={item}
              stroke={colorConfig[index]?.lineColor as Color}
            />
          );
        })}
      </LineChart>
    </div>
  );
};

export default Chart;
