import React from 'react';
import { PrimitiveType } from 'react-intl';

import styles from './SimpleProjectEvent.pcss';

import { timeChatFormat } from 'Util/dateCreator';
import Text from 'Atoms/Text/Text';

interface Props {
  createdAt?: unknown;
  titleMsg?: string;
  titleValues?: Record<string, PrimitiveType>;
}

const SimpleProjectEvent: React.FC<Props> = (props) => {
  const { createdAt, titleMsg, titleValues } = props;

  const date = createdAt ? timeChatFormat(createdAt) : '';

  return (
    <div className={styles.root}>
      <Text
        size="sm"
        color="grayDog"
        align="center"
        text={date}
        data-test="simpleProjectEvent:text:unknown"
      />
      <Text
        color="grayDog"
        align="center"
        msg={titleMsg}
        values={titleValues}
        className={styles.title}
        data-test="simpleProjectEvent:text:title"
      />
    </div>
  );
};

export default SimpleProjectEvent;
