import React, { Suspense, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { graphql, QueryRenderer, useLazyLoadQuery, useRelayEnvironment } from 'react-relay';
import classnames from 'classnames';
import { useIntl } from 'react-intl';

import Skeleton from '../../Creators/components/Skeleton/Skeleton';

import Shipments from './Shipments/ShipmentList';
import ShopsList from './ShopsList/ShopsList';
import styles from './ShipmentsTab.pcss';
import { ShipmentQuery, ShipmentQueryType } from './Shipment.Query';

import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Icon from 'Components/ui/Icon/Icon';
import Text from 'Components/ui/Text/Text';
import { Checkbox } from 'Components/ui/Checkbox';
import ShopifyAuthDrawer from 'Modal/advertiser/ShopifyAuthDrawer/ShopifyAuthDrawer';
import { useShopify } from 'Hooks/useShopify';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { ShipmentTabQuery } from 'GraphTypes/ShipmentTabQuery.graphql';
import { NullableType } from 'Types/common';
import ShopifyOrderCreationDrawer from 'Modal/advertiser/ShopifyOrderCreation/ShopifyOrderCreationDrawer';
import ShopifyShopsListDrawer from 'Modal/advertiser/ShopifyShopsList/ShopifyShopsList';
import { amplitude } from 'Helpers/amplitude';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { RadioDropdown, RadioDropdownItemType } from 'Components/ui/RadioDropdown/RadioDropdown';
import Input from 'Components/ui/Input/Input';
import { ShopifyOrderState } from 'GraphTypes/Shipment_project.graphql';

export type ConnectionStatusType = 'NotConnected' | 'Connected' | 'ConnectionLost';

interface Props {
  campaignId: string;
}

type OrderStatusType = 'all' | 'created' | 'notCreated' | 'error';

const ShipmentTab: React.FC<Props> = ({ campaignId }) => {
  const [connectionState, setConnectionState] = useState<ConnectionStatusType>('Connected');
  const [selectedProjectIds, setSelectedProjectIds] = useState<Record<string, boolean>>({});
  const [readyToOrderProjectIds, setReadyToOrderProjectIds] = useState<string[]>([]);
  const [textQuery, setTextQuery] = useState<string>('');
  const [filterStatus, setFilterStatus] = useState<OrderStatusType>('all');
  const intl = useIntl();

  const filterItems = useMemo<RadioDropdownItemType[]>(() => {
    return ['all', 'created', 'notCreated', 'error'].map((value) => ({
      value: intl.formatMessage({ id: `shipment.create_order.filter.${value}` }),
      id: value,
      isChecked: filterStatus === value,
    }));
  }, [filterStatus]);

  const handleFilterClick = useCallback((clickedValue: string) => {
    setFilterStatus(clickedValue as OrderStatusType);
  }, []);

  const projectFilter = useMemo<{
    orderState: ShopifyOrderState | undefined;
    withoutShopifyOrder: boolean;
  }>(() => {
    if (filterStatus === 'all') {
      return {
        orderState: undefined,
        withoutShopifyOrder: false,
      };
    }
    if (filterStatus === 'created') {
      return {
        withoutShopifyOrder: false,
        orderState: 'SUCCESS',
      };
    }
    if (filterStatus === 'notCreated') {
      return {
        withoutShopifyOrder: true,
        orderState: undefined,
      };
    }

    return {
      withoutShopifyOrder: false,
      orderState: 'FAILURE',
    };
  }, [filterStatus]);

  const selectedIdsArray = Object.keys(selectedProjectIds).filter((key) => selectedProjectIds[key]);

  const { isReauthenticationRequired, connectCampaignToShopify } = useShopify({ campaignId });
  const shopifyData = useLazyLoadQuery<ShipmentTabQuery>(
    graphql`
      query ShipmentTabQuery($id: ID!) {
        currentUser {
          admin
        }
        campaign(id: $id) {
          canUpdateShopifyAuthorization
          shopifyAuthorization {
            reauthenticationRequired
            shop {
              name
              url
            }
            id
          }
          organization {
            id
            shopifyAuthorizations {
              edges {
                node {
                  id
                  reauthenticationRequired
                  shop {
                    name
                  }
                }
              }
            }
          }
        }
      }
    `,
    { id: campaignId }
  );

  const shopName = shopifyData?.campaign?.shopifyAuthorization?.shop?.name || '';
  const shopifyAuthorizationId = shopifyData.campaign?.shopifyAuthorization?.id || '';
  const shopifyNeedReauth = shopifyData.campaign?.shopifyAuthorization?.reauthenticationRequired;

  const organizationId = shopifyData?.campaign?.organization?.id || '';

  const handleSelectAllCreators = () =>
    setSelectedProjectIds(() => {
      const newState: Record<string, boolean> = {};
      readyToOrderProjectIds.forEach((id) => {
        newState[id] = readyToOrderProjectIds.length !== selectedIdsArray.length;
      });
      return newState;
    });

  const handleSelectProject = (clickedId: string) =>
    setSelectedProjectIds((prevState) => {
      const newState = { ...prevState };
      newState[clickedId] = !newState[clickedId];
      return newState;
    });

  const { openDrawer, closeDrawer } = useContext(DrawerContext);
  const handleConnectClick = () => {
    amplitude.sendEvent<316>({
      id: '316',
      category: 'shipment',
      name: 'connect_to_shopify_click',
      param: { campaign_id: campaignId },
    });
    localStorage.setItem('shopify-campaign-id', campaignId);
    openDrawer('shopify-auth');
  };

  const handleCreateOrder = async (projectId: string) => {
    const hadConnectionProblem = await isReauthenticationRequired();
    if (hadConnectionProblem) {
      setConnectionState('ConnectionLost');
      return;
    }
    const newProjectState = { [projectId]: true };
    setSelectedProjectIds(newProjectState);
    connectCampaignToShopify({ campaignId, shopifyAuthorizationId }).then((response) => {
      if (response.connectCampaignToShopify?.__typename === 'ConnectCampaignToShopifyPayload') {
        openDrawer('shopify-order-creation');
      } else {
        setConnectionState('ConnectionLost');
      }
    });
  };

  const handleCreateOrdersClick = async () => {
    const hadConnectionProblem = await isReauthenticationRequired();
    if (hadConnectionProblem) {
      setConnectionState('ConnectionLost');
      return;
    }
    amplitude.sendEvent<327>({
      id: '327',
      category: 'shipment',
      name: 'g_create_order_click',
      param: { campaign_id: campaignId },
    });
    connectCampaignToShopify({ campaignId, shopifyAuthorizationId }).then((response) => {
      if (response.connectCampaignToShopify?.__typename === 'ConnectCampaignToShopifyPayload') {
        openDrawer('shopify-order-creation');
      } else {
        setConnectionState('ConnectionLost');
      }
    });
  };

  useEffect(() => {
    if (shopifyNeedReauth) {
      setConnectionState('ConnectionLost');
      return;
    }

    if (shopifyAuthorizationId) {
      setConnectionState('Connected');
      return;
    }

    if (!shopifyAuthorizationId) {
      setConnectionState('NotConnected');
    }
  }, [shopifyAuthorizationId, shopifyNeedReauth]);

  const environment = useRelayEnvironment();

  const handleCreationError = useCallback(() => {
    closeDrawer('shopify-order-creation');
    setConnectionState('ConnectionLost');
  }, [closeDrawer, setConnectionState]);

  const handleResetTextQuery = useCallback(() => setTextQuery(''), []);

  const handleSettingsClick = () => {
    openDrawer(`shop-revision-${campaignId}`);
  };

  const { formatMessage } = useIntl();

  return (
    <div className={styles.root}>
      <ShopifyAuthDrawer campaignId={campaignId} />
      <ShopifyShopsListDrawer campaignId={campaignId} onNewShopConnect={handleConnectClick} />
      <Suspense fallback={<div />}>
        <ShopifyOrderCreationDrawer
          projectIDs={selectedIdsArray}
          campaignId={campaignId}
          onOrderLostConnectionError={handleCreationError}
          organizationId={organizationId}
          onOrderSuccessfullyCreated={() => {
            setSelectedProjectIds({});
            setReadyToOrderProjectIds([]);
          }}
        />
      </Suspense>
      <div className={styles.container}>
        {!shopifyAuthorizationId && (
          <ShopsList
            list={shopifyData.campaign?.organization?.shopifyAuthorizations}
            campaignId={campaignId}
            shopifyAuthorization={shopifyData.campaign?.shopifyAuthorization}
            onNewShopConnect={handleConnectClick}
            canUpdateShopifyAuthorization={shopifyData.campaign?.canUpdateShopifyAuthorization}
          />
        )}
        <div className={styles.header}>
          {connectionState !== 'NotConnected' && (
            <div className={styles.orderControls}>
              <div
                className={classnames(styles.checkboxContainer, {
                  [styles.disabled]: !(
                    readyToOrderProjectIds.length > 0 && connectionState === 'Connected'
                  ),
                })}
                onClick={
                  readyToOrderProjectIds.length > 0 && connectionState === 'Connected'
                    ? handleSelectAllCreators
                    : undefined
                }
              >
                <Checkbox
                  disabled={connectionState !== 'Connected' || readyToOrderProjectIds.length === 0}
                  checked={selectedIdsArray.length > 0}
                  variant={
                    selectedIdsArray.length === readyToOrderProjectIds.length
                      ? 'normal'
                      : 'indeterminate'
                  }
                  data-test="shipmentTab:checkbox:unknown"
                />
              </div>
              <Tooltip
                id={selectedIdsArray.length === 0 ? 'shopify_not_selected' : ''}
                tooltipMsg={'shipment.create_order.not_selected.tooltip'}
                place={'top'}
                tooltipClassName={styles.tooltip}
              >
                <AlterButton
                  icon="Delivery"
                  type={'black'}
                  text={`${formatMessage({ id: 'shipment.create_order' })} ${
                    selectedIdsArray.length || ''
                  }`}
                  onClick={handleCreateOrdersClick}
                  className={styles.createOrder}
                  disabled={connectionState !== 'Connected' || selectedIdsArray.length === 0}
                  data-test="shipmentTab:alterButton:createOrder"
                />
              </Tooltip>
              <Input
                value={textQuery}
                onChange={(event) => setTextQuery(event.target.value)}
                rightIcon={'Search-loop'}
                placeholderMsg={'shipment.create_order.search_creators'}
                className={styles.input}
                onResetValue={handleResetTextQuery}
                data-test="shipmentTab:input:input"
              />
              <RadioDropdown
                items={filterItems}
                handleClick={handleFilterClick}
                className={styles.filter}
                checkedIcon={'Check'}
                withArrow
                variantTitleMsg={'shipment.create_order.filter.status.title'}
              />
            </div>
          )}
          <div className={styles.connectionStatus}>
            {connectionState === 'Connected' && (
              <div className={styles.connectedContainer}>
                <Icon name="Check" className={styles.check} />
                <div className={styles.info}>
                  <Text text={shopName || ''} type="md" data-test="shipmentTab:text:unknown" />
                  <Text
                    msg={'shipment.connected_to_campaign'}
                    type={'label'}
                    color={'grey'}
                    data-test="shipmentTab:text:unknown"
                  />
                </div>
              </div>
            )}
            {connectionState === 'ConnectionLost' && (
              <div className={styles.connectedContainer}>
                <Icon name="No-internet" className={styles.disabled} />
                <div className={styles.info}>
                  <Text text={shopName || ''} type="md" data-test="shipmentTab:text:unknown" />
                  <Text
                    msg={'shipment.status.connection_lost'}
                    type={'label'}
                    className={styles.disabled}
                    data-test="shipmentTab:text:disabled"
                  />
                </div>
                <AlterButton
                  type="black"
                  msg="shipment.connection.connect_again"
                  onClick={handleConnectClick}
                  data-test="shipmentTab:alterButton:connectAgain"
                />
              </div>
            )}
            {connectionState !== 'NotConnected' && (
              <AlterButton
                type="white"
                icon="Settings"
                onClick={handleSettingsClick}
                data-test="shipmentTab:alterButton:unknown"
              />
            )}
          </div>
        </div>
        {shopifyAuthorizationId && (
          <QueryRenderer<ShipmentQueryType>
            environment={environment}
            query={ShipmentQuery}
            variables={{
              campaignId,
              textQuery,
              withoutShopifyOrder: projectFilter.withoutShopifyOrder,
              orderState: projectFilter.orderState,
            }}
            render={({ props: queryProps }) => {
              if (!queryProps?.campaign) {
                return <Skeleton elementClassname={styles.elementSkeleton} />;
              }

              return queryProps.campaign ? (
                <Shipments
                  campaignId={campaignId}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  campaign={queryProps.campaign}
                  connectionStatus={connectionState}
                  handleSelectProject={handleSelectProject}
                  selectedProjectIds={selectedProjectIds}
                  setAllProjectIds={setReadyToOrderProjectIds}
                  handleCreateOrder={handleCreateOrder}
                  isAdmin={!!shopifyData?.currentUser?.admin}
                />
              ) : null;
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ShipmentTab;
