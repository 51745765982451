import { graphql } from 'react-relay';

const query = graphql`
  query NavigationQuery {
    currentUser {
      organization {
        counters {
          launchedCampaigns
          completedCampaigns
        }
      }
    }
  }
`;

export { query };
