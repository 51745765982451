import React, { useCallback } from 'react';
import { useFragment, graphql } from 'react-relay';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';

import styles from './ProjectItem.pcss';

import { createProjectEventDate } from 'Util/dateCreator';
import { getCreatorData } from 'Util/creatorData';
import { getQueryLocationParams, encodeQueryData } from 'Util/encodeQueryData';
import { getSecondaryText } from 'CommonPage/Chat/Dialogs/util';
import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import NewNotification from 'Atoms/NewNotification/NewNotification';
import AvatarWithBadge from 'Molecules/AvatarWithBadge/AvatarWithBadge';
import {
  ProjectItem_project$key,
  ProjectItem_project$data,
} from 'GraphTypes/ProjectItem_project.graphql';

interface Props {
  isActive: boolean;
  project: ProjectItem_project$key;
}

const ProjectItem: React.FC<Props> = (props) => {
  const { project, isActive } = props;

  const navigate = useNavigate();

  const data = useFragment(
    graphql`
      fragment ProjectItem_project on Project {
        id
        campaign {
          id
          name
          # Organization id needable for link subscription event with left navigation events counter
          organization {
            id
          }
        }
        lastEvent: events(
          last: 1
          types: [
            MESSAGE
            OFFER_ACCEPTANCE
            PRICE_CHANGE
            PRICE_CHANGE_REQUEST
            PRICE_CHANGE_REQUEST_REJECTION
            PRICE_CHANGE_REQUEST_ACCEPTANCE
            PRODUCT_SHIPMENT_COMPLETION
            CONTENT_REVIEW_FAIL
            CONTENT_REVIEW_PASS
            PUBLICATION_EVENT
            PUBLICATION_REQUEST
            DEADLINE_CHANGE_REQUEST
            EXTRA_PAYOUT
            DEADLINE
            PUBLICATION_DEADLINE_CHANGE
            LAUNCH
            ARCHIVATION
            DEADLINE_CHANGE_REQUEST_ACCEPTANCE
            CONTENT_SUBMISSION
            CONTENT_REVIEW_FAIL
          ]
        ) {
          unreadCount
          edges {
            node {
              __typename
              createdAt
              readByContractor
              ... on Message {
                text
                attachments {
                  edges {
                    node {
                      file {
                        __typename
                        type
                        id
                      }
                      id
                      __typename
                    }
                    cursor
                  }
                  pageInfo {
                    endCursor
                    hasNextPage
                  }
                }
              }
              ... on OfferAcceptance {
                comment
              }
              actor {
                __typename
                id
              }
              id
            }
          }
        }
        shortlisted
        creator {
          id
          __typename
          type
          username
          profile {
            profilePictureFile {
              url
              ... on Image {
                transformations {
                  collageThumbnailUrl
                }
              }
            }
          }
        }
        shortlisted
      }
    `,
    project as any
  ) as ProjectItem_project$data;

  const projectId = data.id;
  const creator = data.creator;
  const shortlisted = data.shortlisted;
  const campaignName = data.campaign.name || '';
  const unreadCount = data.lastEvent?.unreadCount;
  const eventsEdges = data.lastEvent?.edges;
  const lastEvent = eventsEdges && eventsEdges.length > 0 ? eventsEdges[0]?.node : undefined;
  const date = createProjectEventDate(lastEvent?.createdAt);
  const readMark = lastEvent?.readByContractor;
  const isActorBrand = lastEvent?.actor?.__typename === 'Brand';
  const isMessage = lastEvent?.__typename === 'Message';

  const { username, avatarUrl } = getCreatorData(creator);

  const secondaryText = getSecondaryText(eventsEdges) || '';

  const urlParams = getQueryLocationParams();

  const handleProjectSelect = useCallback(() => {
    navigate(`${location.pathname}${encodeQueryData({ ...urlParams, projectId: projectId })}`);
  }, [projectId]);

  return (
    <div
      className={classnames(styles.root, { [styles.isActive]: isActive })}
      onClick={handleProjectSelect}
    >
      <div className={styles.avatar}>
        <AvatarWithBadge
          avaData={{ size: 48, src: avatarUrl }}
          className={styles.ava}
          favorite={shortlisted}
        />
      </div>
      <div className={styles.details}>
        <div className={styles.row}>
          <Text
            type="sm"
            text={username}
            className={styles.message}
            data-test="projectItem:text:message"
          />
          {isActorBrand && isMessage && (
            <Icon
              size={16}
              name="Check-double"
              className={readMark ? styles.read : styles.unread}
            />
          )}
          {date && (
            <Text
              type="label"
              text={date}
              className={styles.date}
              data-test="projectItem:text:date"
            />
          )}
        </div>
        <div className={classnames(styles.row, styles.messageWrap)}>
          <div className={styles.messageData}>
            <Text
              type="label"
              {...secondaryText}
              className={styles.message}
              data-test="projectItem:text:message"
            />
            <Text
              type="labelSm"
              text={campaignName}
              className={`${styles.campaignName} ${styles.message}`}
              data-test="projectItem:text:unknown"
            />
          </div>
          {Number(unreadCount) > 0 && (
            <NewNotification size="md" count={unreadCount} isActive={true} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectItem;
