import React, { useContext, useEffect, useMemo, useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import { v4 as uuid } from 'uuid';
import xor from 'lodash/xor';
import { Element } from 'react-scroll';

import { BriefContext } from '../../../Brief.Context';
import Section from '../components/Section/Section';
import Warning from '../Warning/Warning';
import With from '../components/With/With';

import styles from './CreativeAssets.pcss';
import CreativeAsset from './CreativeAsset/CreativeAsset';

import createBriefCreative from 'Mutations/CreateBriefCreative.Mutation';
import Text from 'Atoms/Text/Text';
import NewText from 'Components/ui/Text/Text';
import Button from 'Atoms/Button/Button';
import { CreativeAssets_campaign$key } from 'GraphTypes/CreativeAssets_campaign.graphql';

interface Props {
  campaign: CreativeAssets_campaign$key;
}

const CreativeAssets: React.FC<Props> = (props) => {
  const [newCreativeId, setNewCreativeId] = useState('');

  const [briefState, briefDispatch] = useContext(BriefContext);
  const [openedAssets, setOpenedAssets] = useState<string[]>([]);

  const { showErrors, shownElementsWithPossibleError, productInfoFilled } = briefState;

  const { campaign } = props;

  const data = useFragment(
    graphql`
      fragment CreativeAssets_campaign on Campaign {
        id
        type
        postingType
        platform
        objective
        useCase
        brief {
          id
          ... on V2Brief {
            taskDescription
            creatives {
              edges {
                node {
                  id
                  publishingRequired
                  ...CreativeAsset_briefCreative
                }
              }
              totalCount
            }
          }
        }
      }
    `,
    campaign
  );

  const { id: campaignId, brief, type, postingType, platform, objective, useCase } = data;

  const totalCount = brief?.creatives?.totalCount;
  const postingRequired =
    !!(type === 'INFLUENCER' && postingType === 'ORGANIC_POSTING') ||
    (useCase ? ['INFLUENCER_POSTS', 'AFFILIATE_CAMPAIGN', 'TIKTOK_SHOP'].includes(useCase) : false);

  const creativesWithPosting = useMemo(() => {
    if (!brief?.creatives?.edges) return 0;
    return brief?.creatives?.edges.reduce((acc, item) => {
      return item?.node?.publishingRequired ? ++acc : acc;
    }, 0);
  }, [brief?.creatives?.edges]);

  const completeTotalCount = Object.keys(briefState.creativeAssets).reduce(
    (prevItem, currentItem) => {
      return briefState.creativeAssets[currentItem] === true ? prevItem + 1 : prevItem;
    },
    0
  );

  const filled = completeTotalCount === totalCount && completeTotalCount > 0;
  const requiredPostingWarning =
    postingRequired && creativesWithPosting === 0 && Number(brief?.creatives?.totalCount) > 0;

  useEffect(() => {
    briefDispatch({
      key: 'creativesInfoFilled',
      value: completeTotalCount === totalCount && completeTotalCount > 0 && !requiredPostingWarning,
    });
    if (productInfoFilled) {
      briefDispatch({
        key: 'creativesErrors',
        value: filled ? [] : ['creativesInfo'],
      });
    }
  }, [
    briefState.creativeAssets,
    briefState.campaignType,
    requiredPostingWarning,
    productInfoFilled,
  ]);

  useEffect(() => {
    const creativeAssets = { ...briefState.creativeAssets };
    for (const key of Object.keys(creativeAssets)) {
      creativeAssets[key] = null;
    }
    briefDispatch({
      key: 'creativeAssets',
      value: creativeAssets,
    });
  }, [briefState.campaignType]);

  if (!brief || !briefState.creatorsInfoFilled || !briefState.productInfoFilled) return null;

  const { id: briefId, creatives } = brief;

  const handleNewCreativeAdd = () => {
    const newId = uuid();
    setNewCreativeId(newId);
    setOpenedAssets([...openedAssets, newId]);
    createBriefCreative({
      id: newId,
      briefId,
      contentAmount: 1,
      publishingRequired: postingRequired || undefined,
    });
  };

  const handleOpenChange = (asset: string) => {
    const newOpenedAssets = xor(openedAssets, [asset]);
    setOpenedAssets(newOpenedAssets);
  };

  const creativesError =
    showErrors && shownElementsWithPossibleError?.includes('creativesInfo') && !filled;

  return (
    <Section
      titleMsg="brief_template.section.creative_assets"
      titleValues={{ count: completeTotalCount ? `(${completeTotalCount})` : '' }}
      className={styles.section}
      titleClassName={styles.title}
    >
      <With condition={true} name="creativesInfo">
        <>
          <Text
            msg="brief_template.section.creative_assets.descr"
            className={styles.descr}
            data-test="creativeAssets:text:descr"
          />
          <Element name="creatives">
            {creatives?.edges?.map((item) => {
              if (!item?.node) return null;

              const { id } = item.node;

              return (
                <CreativeAsset
                  key={id}
                  campaignId={campaignId}
                  campaignType={type}
                  campaignPlatform={platform}
                  campaignObjective={objective}
                  briefId={briefId}
                  briefCreative={item.node}
                  defaultOpened={newCreativeId === id}
                  onOpenChange={handleOpenChange}
                  postingRequired={postingRequired}
                  creativesWithPosting={creativesWithPosting || 0}
                  showMissingValidation={creativesError}
                />
              );
            })}
          </Element>
          {requiredPostingWarning && openedAssets.length === 0 && (
            <Warning
              titleMsg="brief_template.creative_asset.posting.no_posting_required"
              error
              className={styles.noPostingWarning}
            />
          )}
          {creativesError && (
            <NewText type="md" msg="brief_template.creative_asset.error" className={styles.error} />
          )}
          <Button
            width="full"
            color="normal"
            border="dashed"
            leftIconName="plus"
            msg="brief_template.creative_asset.add_new"
            className={styles.addBtn}
            dataTest="button:addCreative"
            onClick={handleNewCreativeAdd}
            data-test="creativeAssets:button:addNew"
          />
        </>
      </With>
    </Section>
  );
};

export default CreativeAssets;
