import React from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';

import styles from './UnreadProjectsCount.pcss';

import NewNotification from 'Atoms/NewNotification/NewNotification';
import { UnreadProjectsCountQuery as QueryType } from 'GraphTypes/UnreadProjectsCountQuery.graphql';

const UnreadProjectsCountQuery = graphql`
  query UnreadProjectsCountQuery {
    currentUser {
      type
      organization {
        projects(
          withUnreadEvents: true
          stages: [ACCEPTED, LAUNCHED, CONTENT_SUBMITTED, PUBLISHING_STARTED, COMPLETED]
        ) {
          totalCount
        }
      }
    }
  }
`;

const UnreadProjectsCount = () => {
  const data = useLazyLoadQuery<QueryType>(UnreadProjectsCountQuery, {});

  const projectsWithUnreadMessageCount = data?.currentUser?.organization?.projects?.totalCount || 0;

  return projectsWithUnreadMessageCount ? (
    <NewNotification
      count={projectsWithUnreadMessageCount}
      isActive={true}
      isLimited={true}
      className={styles.unreadCount}
    />
  ) : null;
};

export default UnreadProjectsCount;
