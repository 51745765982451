import React, { PropsWithChildren, useEffect, useState } from 'react';
import { fetchQuery } from 'relay-runtime';
import { User } from 'firebase/auth';
import { removeCookie } from 'typescript-cookie';

import { FirebaseAuthContextType, Auth0Context } from './FirebaseAuth.Context';
import currentUserQuery, { CurrentUserQuery$data } from './CurrentUser.Query';
import { isAlreadyRegisteredUser, firebaseClient } from './utils';
import { Auth0PostMessageType } from './types';

import environment, { unsubscribeSocketConnection } from 'Api/Environment';
import type { CurrentUserQuery } from 'GraphTypes/CurrentUserQuery.graphql';
import projectEventCreatedSubscription from 'Subscriptions/ProjectEventCreated.Subscription';
import projectUnreadCountChanged from 'Subscriptions/ProjectUnreadCountChanged.Subscription';
import campaignSummaryUpdated from 'Subscriptions/CampaignSummaryUpdated.Subscription';
import organizationBalanceUpdated from 'Subscriptions/OrganizationBalanceUpdated.Subscription';
import fileProcessingStatusUpdated from 'Subscriptions/FileProcessingStatusUpdated.Subscription';
import fileBcaTaggingStatusUpdated from 'Subscriptions/FileBcaTaggingStatusUpdated.Subscription';
import organizationSubscriptionUpdated from 'Subscriptions/OrganizationSubscriptionUpdated.Subscription';
import shopifyOrderStateUpdatedSubscription from 'Api/subscriptions/ShopifyOrderStateUpdated.Subscription';
import inAppNotificationCreatedSubscription from 'Api/subscriptions/InAppNotificationCreated.Subscription';
import unviewedInAppNotificationCountUpdatedSubscription from 'Api/subscriptions/UnviewedInAppNotificationCountUpdated.Subscription';
import {
  ADVERTISER,
  CONTRACTOR,
  SIGNUP_ADVERTISER_ROUTE,
  SIGNUP_CREATOR_ROUTE,
} from 'Constants/general';
import { amplitude } from 'Helpers/amplitude';

const Auth0Provider: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const [isRegisteredUser, setIsRegisteredUser] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const authService = firebaseClient.authClient;
  const [currentUser, setCurrentUser] = useState<CurrentUserQuery$data['currentUser'] | null>(null);
  const [currentFirebaseUser, setCurrentFirebaseUser] = useState<User | false | null>(null);

  const checkUserData = () => {
    fetchQuery<CurrentUserQuery>(
      environment,
      currentUserQuery,
      {},
      { fetchPolicy: 'network-only' }
    ).subscribe({
      next: (data) => {
        if (!data?.currentUser) {
          setIsRegisteredUser(null);
          setCurrentFirebaseUser(false);
          return;
        }
        setCurrentUser(data?.currentUser);
        const isRegistered = isAlreadyRegisteredUser(data?.currentUser);
        setIsRegisteredUser(isRegistered);
      },
      error: () => {
        setCurrentFirebaseUser(false);
        setIsRegisteredUser(false);
      },
      complete: () => {
        setIsLoading(false);
      },
    });
  };
  useEffect(() => {
    setIsLoading(false);
    checkUserData();
  }, [authService?.currentUser?.uid]);

  useEffect(() => {
    if (currentUser?.type !== 'UNKNOWN') {
      projectEventCreatedSubscription();
      projectUnreadCountChanged();
      campaignSummaryUpdated();
      organizationBalanceUpdated();
      fileProcessingStatusUpdated();
      fileBcaTaggingStatusUpdated();
      organizationSubscriptionUpdated();
      shopifyOrderStateUpdatedSubscription();
      inAppNotificationCreatedSubscription();
      unviewedInAppNotificationCountUpdatedSubscription();
    }
  }, [currentUser?.type]);

  useEffect(() => {
    authService?.onAuthStateChanged((user) => {
      if (!user) {
        setCurrentFirebaseUser(null);
      } else {
        setCurrentFirebaseUser(user);
      }
    });
  }, [authService]);

  const logout: FirebaseAuthContextType['logout'] = async () => {
    amplitude.reset();

    if (authService) {
      await authService.signOut();
    }
    setCurrentFirebaseUser(false);
    removeCookie('access-token');
    setIsRegisteredUser(null);
    unsubscribeSocketConnection();
  };

  const signUpWithPopup: FirebaseAuthContextType['signUpWithPopup'] = (userType) => {
    const sp = new URLSearchParams({ popup: 'true' });
    const urls = {
      [CONTRACTOR]: `${window.location.origin}${SIGNUP_CREATOR_ROUTE}?${sp.toString()}`,
      [ADVERTISER]: `${window.location.origin}${SIGNUP_ADVERTISER_ROUTE}?${sp.toString()}`,
    };
    const url = urls[userType || ADVERTISER];
    window.open(url, 'Auth0', 'width=500,height=800');
    return new Promise<void>((resolve) => {
      const handler: MessageEventHandler = (e) => {
        const isResolved = new Set([
          Auth0PostMessageType.SignUpComplete,
          Auth0PostMessageType.LoggedIn,
        ]).has(e?.data?.type);
        if (isResolved) {
          resolve();
        }
      };
      window.addEventListener('message', handler, false);
    });
  };

  const setIsRegisteredUserForce: FirebaseAuthContextType['setIsRegisteredUserForce'] = (value) => {
    setIsRegisteredUser(value);
    checkUserData();
  };
  const auth0ProviderValue: FirebaseAuthContextType = {
    isAuthenticatedUser: currentFirebaseUser === null ? null : !!currentFirebaseUser,
    firebaseUser: currentFirebaseUser,
    user: currentUser,
    setIsRegisteredUserForce,
    isRegisteredUser,
    isLoading,
    signUpWithPopup,
    logout,
  };

  return <Auth0Context.Provider value={auth0ProviderValue}>{children}</Auth0Context.Provider>;
};

type WithAuth0Provider = <P extends object = object>(
  component: React.ComponentType<P>
) => React.FC<P>;
const withAuth0Provider: WithAuth0Provider = (Component) => {
  return (props) => (
    <Auth0Provider>
      <Component {...props} />
    </Auth0Provider>
  );
};

export { Auth0Provider, withAuth0Provider };

// types

type MessageEventHandler = Parameters<typeof window.addEventListener<'message'>>[1];
