import React, { useState } from 'react';

import styles from './UserHandle.css';

import changeCreatorUsername from 'Mutations/ChangeCreatorUsername.Mutation';
import Input from 'Atoms/Input/Input';
import Text from 'Atoms/Text/Text';
import Button from 'Atoms/Button/Button';
import { ChangeCreatorUsernameMutation$data } from 'GraphTypes/ChangeCreatorUsernameMutation.graphql';
import { CreatorType } from 'GraphTypes/ProfileSettings_creator.graphql';
import { TextType } from 'Types/common';

interface Props {
  creatorId: string;
  username: string;
  creatorType: CreatorType;
}

const UserHandle: React.FC<Props> = (props) => {
  const { creatorType, creatorId, username: oldUsername } = props;

  const [newUsername, setNewUsername] = useState('');
  const [resultData, setResultData] = useState<undefined | TextType>(undefined);
  const [loading, setLoading] = useState(false);

  if (!(creatorType === 'TIKTOK' || creatorType === 'INSTAGRAM')) return null;

  const handleIGUsernameUpdate = () => {
    setLoading(true);
    changeCreatorUsername({ creatorType, newUsername, oldUsername, creatorId }, handleChangingDone);
  };

  const handleChangingDone = (result: ChangeCreatorUsernameMutation$data) => {
    const resultValue = result.changeCreatorUsername?.result;

    if (!resultValue) return;

    setLoading(false);
    setResultData({
      msg: `creator.update_handle.result.${resultValue.toLowerCase()}`,
      color: resultValue === 'SUCCESS' ? 'green' : 'red',
    });
  };

  const handleSetNewUsername = (value: string) => {
    setResultData(undefined);
    setLoading(false);
    setNewUsername(value);
  };

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.input}>
          <Input
            placeholderMsg="creator.update_handle.placeholder"
            onChange={handleSetNewUsername}
            data-test="userHandle:input:unknown"
          />
          {!resultData && creatorType === 'TIKTOK' && (
            <Text
              size="sm"
              color="grayDog"
              msg="creator.update_handle.result.warning"
              className={styles.warning}
              data-test="userHandle:text:warning"
            />
          )}
        </div>
        <Button
          disabled={!newUsername}
          loading={loading}
          msg="creator.update_handle"
          onClick={handleIGUsernameUpdate}
          data-test="userHandle:button:updateHandle"
        />
      </div>
      {resultData && (
        <Text className={styles.result} {...resultData} data-test="userHandle:text:result" />
      )}
    </div>
  );
};

export default UserHandle;
