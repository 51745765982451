import React, { useContext } from 'react';
import { graphql, useFragment } from 'react-relay';
import { useNavigate } from 'react-router-dom';

import styles from './ProjectDetails.pcss';

import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import ArchiveProjectRequest from 'Modal/contractor/ArchiveProjectRequest/ArchiveProjectRequest';
import { getProjectBriefLink } from 'Util/links';
import AvatarItem from 'Molecules/AvatarItem/AvatarItem';
import Button from 'Atoms/Button/Button';
import TextButton from 'Atoms/TextButton/TextButton';
import { DASHBOARD_ROUTE } from 'Constants/general';
import { ProjectDetails_project$key } from 'GraphTypes/ProjectDetails_project.graphql';

interface Props {
  project: ProjectDetails_project$key;
}

const ProjectDetails: React.FC<Props> = (props) => {
  const { openDrawer } = useContext(DrawerContext);

  const { project } = props;

  const data = useFragment(
    graphql`
      fragment ProjectDetails_project on Project {
        id
        creator {
          id
        }
        offer {
          acceptance {
            id
          }
        }
        launch {
          id
        }
        completion {
          id
        }
        archivation {
          id
        }
        archivationRequest {
          acceptance {
            id
          }
          rejection {
            id
          }
          side
        }
        brand {
          name
          logo {
            ... on Image {
              transformations {
                brandLogoUrl
              }
            }
          }
        }
        campaign {
          name
          organization {
            id
          }
        }
      }
    `,
    project
  );

  const projectId = data.id;
  const brand = data.brand;
  const brandLogo = brand?.logo?.transformations?.brandLogoUrl;
  const brandName = brand?.name;
  const campaignName = brand?.name;

  const acceptance = data.offer?.acceptance?.id;
  const completion = data.completion?.id;
  const archivation = data.archivation?.id;

  const creatorId = data.creator?.id;
  const organizationId = data.campaign?.organization?.id;

  const hasArchivationRequest =
    data.archivationRequest &&
    !data.archivationRequest.acceptance &&
    !data.archivationRequest.rejection;

  const navigate = useNavigate();

  const handleViewBriefClick = () => {
    const briefLink = getProjectBriefLink({ projectId });
    window.open(briefLink);
  };

  const handleDeclineDealClick = () => {
    openDrawer(`archive-project-by-contractor-${projectId}`);
  };

  const handleArchivationDone = () => {
    navigate(DASHBOARD_ROUTE);
  };

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <AvatarItem
          avaData={{
            size: '40',
            src: brandLogo,
          }}
          titleData={{
            weight: '500',
            text: campaignName,
          }}
          subtitleData={{
            text: brandName,
          }}
        />
        <div className={styles.controls}>
          <Button
            width="full"
            color="normal"
            msg="chat_creator.view_brief"
            onClick={handleViewBriefClick}
            data-test="projectDetails:button:viewBrief"
          />
        </div>
      </div>
      {acceptance && !completion && !archivation && !hasArchivationRequest && (
        <>
          <div className={styles.decline}>
            <TextButton
              mainColor="red"
              msg="chat_creator.decline_a_deal"
              onClick={handleDeclineDealClick}
              className={styles.declineBtn}
              data-test="projectDetails:textButton:declineADeal"
            />
          </div>
          <ArchiveProjectRequest projectId={projectId} />
        </>
      )}
    </div>
  );
};

export default ProjectDetails;
