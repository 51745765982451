import { graphql, useMutation } from 'react-relay';

import { SetGmailSendingLimitsMutation as MutationType } from 'GraphTypes/SetGmailSendingLimitsMutation.graphql';
import type { BaseMutation, BaseMutationExecFn } from 'Types/utils';

const SetGmailSendingLimitsMutation = graphql`
  mutation SetGmailSendingLimitsMutation($input: SetGmailSendingLimitsInput!) {
    setGmailSendingLimits(input: $input) {
      __typename
      ... on SetGmailSendingLimitsPayload {
        gmailAuthorization {
          id
          dailySendingLimit
        }
      }
    }
  }
`;

export const useSetGmailSendingLimitsMutation: BaseMutation<MutationType> = (config) => {
  const [setGmailSendingLimits, loading] = useMutation<MutationType>(SetGmailSendingLimitsMutation);

  const setGmailSendingLimitsCaller: BaseMutationExecFn<MutationType> = (execConfig) => {
    if (!execConfig) return;
    setGmailSendingLimits({
      ...config,
      ...execConfig,
    });
  };
  return [setGmailSendingLimitsCaller, loading];
};
