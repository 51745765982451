import React, { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';

import styles from './ChangePasswordButton.pcss';

import emailVerificationBannerImg from 'Images/general/email_verification_banner_big.jpg';
import { PersonalInfo_user$data } from 'GraphTypes/PersonalInfo_user.graphql';
import Button from 'Components/ui/Button/Button';
import ErrorHandler from 'Util/errorHandler';
import Drawer from 'Components/ui/Drawer/Drawer';
import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { firebaseClient } from 'Containers/Auth0/Auth0Context/utils';

interface Props {
  email: PersonalInfo_user$data['email'];
}

const ChangePasswordButton: React.FC<Props> = (props) => {
  const { email } = props;
  const [loading, setLoading] = useState(false);
  const [openedDrawer, setOpenDrawerStatus] = useState(false);
  const [resendBtnDisabled, setResendButtonDisabledStatus] = useState(false);

  const handleChangePassword = async () => {
    setLoading(true);
    try {
      await sendPasswordResetEmail(firebaseClient.authClient, email);
      setOpenDrawerStatus(true);
    } catch (e) {
      ErrorHandler.error('Failed to send change password request', { ...e });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseDrawer = () => {
    setOpenDrawerStatus(false);
  };

  const resendButtonHandler = () => {
    setResendButtonDisabledStatus(true);
    handleChangePassword();
  };

  return (
    <>
      <AlterButton
        msg="advertiser.settings.button.change_password"
        dataTest="button:sendChangePasswordRequest"
        onClick={handleChangePassword}
        disabled={resendBtnDisabled}
        loading={loading}
        className={styles.mainButton}
        icon={'Key-password'}
        data-test="changePasswordButton:alterButton:changePassword"
      />
      <Drawer
        opened={openedDrawer}
        needCloseButton
        onClose={handleCloseDrawer}
        className={styles.root}
        rootKey="change_password_success"
      >
        <div className={styles.container}>
          <div className={styles.textContainer}>
            <Text
              type="d2"
              msg="change_password_drawer.title"
              data-test="changePasswordButton:text:title"
            />
            <Text
              type="md"
              msg="change_password_drawer.description"
              formatValues={{ email }}
              data-test="changePasswordButton:text:description"
            />
          </div>
          <div className={styles.imageContainer}>
            <img src={emailVerificationBannerImg} className={styles.image} />
          </div>
          <Button
            msg="change_password_drawer.resend_btn"
            disabled={resendBtnDisabled}
            onClick={resendButtonHandler}
            loading={loading}
            dataTest="button:resendChangePasswordRequest"
            data-test="changePasswordButton:button:resendBtn"
          />
        </div>
      </Drawer>
    </>
  );
};

export default ChangePasswordButton;
