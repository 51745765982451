import React, { useContext } from 'react';
import { graphql, useFragment } from 'react-relay';

import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { BARTER_TYPE, STAGE_LAUNCHED } from 'Constants/general';
import { FinishDealWrapperContext } from 'Molecules/FinishDealWrapper/FinishDealWrapperContext';
import { FinishDealAction_project$key } from 'GraphTypes/FinishDealAction_project.graphql';
import { ProjectActionsQuery$data } from 'GraphTypes/ProjectActionsQuery.graphql';

interface Props {
  finishDealMsg?: string;
  project: FinishDealAction_project$key;
}

const FinishDealAction: React.FC<Props> = (props) => {
  const { project, finishDealMsg } = props;

  const finishDealContext = useContext(FinishDealWrapperContext);
  const handleFlowFinishDeal = finishDealContext?.handleFlowFinishDeal;

  const data = useFragment(
    graphql`
      fragment FinishDealAction_project on Project {
        id
        price
        currency
        advertiserStage
        publicationEventExists
        campaign {
          useCase
          paymentType
          platform
          type
          publishingRequired
        }
        contentReview {
          status
        }
        creator {
          type
          ... on InstagramCreator {
            insightsAuthorized
            user {
              id
            }
          }
        }
      }
    `,
    project
  );

  if (!data) return null;

  const { campaign, advertiserStage } = data;

  const paymentType = campaign.paymentType;
  const campaignType = campaign.type;

  const isBarter = paymentType === BARTER_TYPE;
  const paymentTypePrefix = isBarter ? '.barter' : '';
  const productSeedingPrefix = campaignType === 'PRODUCT_SEEDING' ? '.product_seeding' : '';
  const isFreeOrganic =
    (!data?.price && data?.campaign?.useCase === 'AFFILIATE_CAMPAIGN') ||
    data?.campaign?.useCase === 'TIKTOK_SHOP';

  return (
    <AlterButton
      icon="Arrow-big-right"
      type={
        advertiserStage === STAGE_LAUNCHED || advertiserStage === 'PUBLISHING_STARTED'
          ? 'grey'
          : 'black'
      }
      msg={
        isFreeOrganic
          ? 'project.stepper.content_creation.free_organic.finish_deal'
          : finishDealMsg ||
            `project.stepper.content_creation${paymentTypePrefix}${productSeedingPrefix}.finish_deal`
      }
      onClick={handleFlowFinishDeal}
      data-test="finishDealAction:alterButton:unknown"
    />
  );
};

export default FinishDealAction;
