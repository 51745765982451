/**
 * @generated SignedSource<<3e6459f5abcbe576d43b1dfd3a01d9bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Currency = "CAD" | "RUB" | "USD";
export type RequestPriceChangeInput = {
  clientMutationId?: string | null | undefined;
  currency: Currency;
  id?: string | null | undefined;
  price: number;
  projectId: string;
};
export type RequestPriceChangeMutation$variables = {
  input: RequestPriceChangeInput;
};
export type RequestPriceChangeMutation$data = {
  readonly requestPriceChange: {
    readonly project: {
      readonly priceChangeRequest: {
        readonly id: string;
        readonly price: number;
      } | null | undefined;
    };
  } | null | undefined;
};
export type RequestPriceChangeMutation = {
  response: RequestPriceChangeMutation$data;
  variables: RequestPriceChangeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "PriceChangeRequest",
  "kind": "LinkedField",
  "name": "priceChangeRequest",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RequestPriceChangeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RequestPriceChangePayload",
        "kind": "LinkedField",
        "name": "requestPriceChange",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RequestPriceChangeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RequestPriceChangePayload",
        "kind": "LinkedField",
        "name": "requestPriceChange",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "627a59b0d3b08d5203c0782e197a425a",
    "id": null,
    "metadata": {},
    "name": "RequestPriceChangeMutation",
    "operationKind": "mutation",
    "text": "mutation RequestPriceChangeMutation(\n  $input: RequestPriceChangeInput!\n) {\n  requestPriceChange(input: $input) {\n    project {\n      priceChangeRequest {\n        id\n        price\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b5494c4a60bb85d47493b26fa9b3acb0";

export default node;
