import React, { ChangeEvent, useContext, useEffect, useState } from 'react';

import styles from '../InviteOutreachCreators.pcss';
import { InviteOutreachCreatorsContext } from '../InviteOutreachCreatorsContext';

import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import DropdownItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import Input from 'Components/ui/Input/Input';

const GmailSenderName: React.FC = () => {
  const { senderName, senderEmail, setSenderName, availableNames } = useContext(
    InviteOutreachCreatorsContext
  );

  const [name, setName] = useState(senderName);

  useEffect(() => {
    if (availableNames[0] && !senderName) {
      setName(availableNames[0]);
    }
  }, [availableNames[0], senderName]);

  useEffect(() => {
    setSenderName(name);
  }, [name]);

  const handleChangeSenderName = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleResetSenderName = () => {
    setName('');
  };

  return (
    <div>
      <Text
        type="md"
        msg="invite_outreach_creators_modal.step_gmail.names"
        className={styles.subtitle}
      />
      <Dropdown
        showArrow={false}
        value={
          <Input
            type="text"
            placeholderMsg={
              senderEmail
                ? 'invite_outreach_creators_modal.step_gmail.names.placeholder'
                : 'invite_outreach_creators_modal.step_gmail.names.empty_placeholder'
            }
            value={name}
            onChange={handleChangeSenderName}
            onResetValue={handleResetSenderName}
            borderless
            hideCloseIcon
            disabled={!senderEmail}
            inputClassName={styles.dropdownInputValue}
          />
        }
        disabled={!senderEmail}
        className={styles.dropdownName}
      >
        <DropdownGroup className={styles.dropdownMenu}>
          {[...new Set(availableNames)].map((item) => {
            const isActive = item === name;

            const handleNameChange = () => {
              if (isActive) return;
              setName(item);
            };

            return (
              <DropdownItem key={item}>
                <AlterButton
                  text={item}
                  onClick={handleNameChange}
                  fluid
                  iconPosition="right"
                  icon={isActive ? 'Check' : null}
                />
              </DropdownItem>
            );
          })}
        </DropdownGroup>
      </Dropdown>
    </div>
  );
};

export default GmailSenderName;
