import React, { Suspense, useState } from 'react';
import classnames from 'classnames';

import styles from './BcaPermissionModal.pcss';

import Spinner from 'Atoms/Spinner/Spinner';
import Drawer from 'Components/ui/Drawer/Drawer';
import BcaPermissionModalContent from 'Modal/advertiser/BcaPermissionModal/BcaPermissionModalContent/BcaPermissionModalContent';

interface Props {
  projectId: string;
  drawerProps?: Partial<Omit<DrawerProps, 'children'>>;
  onBcaConnectionImpossible?: BcaModalContentProps['onBcaConnectionImpossible'];
}

const BcaPermissionModal: React.FC<Props> = (props) => {
  const { drawerProps, projectId, onBcaConnectionImpossible } = props;
  const [loading, setLoading] = useState(true);

  return (
    <Drawer rootKey="bca-license" {...drawerProps} className={styles.drawer}>
      <Suspense
        fallback={
          <Spinner className={classnames(styles.preloader, { [styles.hidden]: !loading })} />
        }
      >
        <BcaPermissionModalContent
          projectId={projectId}
          className={classnames({ [styles.hidden]: loading })}
          drawerProps={drawerProps}
          onLoad={() => setLoading(false)}
          onBcaConnectionImpossible={onBcaConnectionImpossible}
        />
      </Suspense>
    </Drawer>
  );
};

export default BcaPermissionModal;
export type { Props as BcaPermissionModalProps };

type DrawerProps = React.ComponentProps<typeof Drawer>;
type BcaModalContentProps = React.ComponentProps<typeof BcaPermissionModalContent>;
