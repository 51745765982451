import { createContext } from 'react';

import { CampaignPlatform } from 'GraphTypes/CampaignInfo_campaign.graphql';
import { OutreachTransport } from 'GraphTypes/CreateOutreachBatchMutation.graphql';
import { OutreachStepBlueprint_ProjectEventType } from 'GraphTypes/CreateOutreachSequenceBlueprintMutation.graphql';
import { Locale } from 'GraphTypes/CreateOutreachTemplateMutation.graphql';
import { OutreachStepBlueprintInput } from 'GraphTypes/UpdateOutreachSequenceBlueprintMutation.graphql';
import { CampaignUseCase } from 'GraphTypes/InviteOutreachCreatorsQuery.graphql';

type InviteOutreachCreatorsContextType = {
  source: InviteOutreachCreatorsSource | null;
  locale: string;
  selectedLists: InviteOutreachCreatorsSelectedLists;
  templateData: InviteOutreachTemplateData;
  transport: OutreachTransport;
  senderActiveAuth: string;
  senderName: string;
  senderEmail: string;
  error: InviteOutreachCreatorsError | null;
  templatePreview: InviteOutreachTemplateItemData | null;
  isTemplateChanged: boolean;
  platform: CampaignPlatform;
  useCase: CampaignUseCase | null;
  suggestedCreatorsExists: boolean;
  handleTemplateClose: () => void;
  handleImportOutreachBatch: (campaignId: string, sequenceId?: string) => void;
  isFormValid: boolean;
  createOutreachBatchLoading: boolean;
  processing: boolean;
  isTemplateCreating: boolean;
  setTemplateData: (data: InviteOutreachTemplateData) => void;
  setTemplatePreview: (data: InviteOutreachTemplateItemData | null) => void;
  setTransportProvider: (provider: OutreachTransport) => void;
  setSenderName: (name: string) => void;
  setSenderActiveAuth: (authId: string) => void;
  setSenderEmail: (email: string) => void;
  setError: (error: InviteOutreachCreatorsError | null) => void;
  setCreatingTemplateStatus: (status: boolean) => void;
  setTemplateChangedStatus: (status: boolean) => void;
  setSource: (source: InviteOutreachCreatorsSource) => void;
  setCampaignData: (data: InviteOutreachCampaignData) => void;
  setSelectedLists: (lists: InviteOutreachCreatorsSelectedLists) => void;
  sequenceId: string | null;
  setSequenceId: (id: string | null) => void;
  sequenceList: SequenceStep[];
  setSequenceList: (list: SequenceStep[]) => void;
  activeStep: number;
  setActiveStep: (index: number) => void;
  stepsWithError: number[];
  setStepsWithErrorList: (list: number[]) => void;
  timezone: number;
  updatingTemplateLoading: boolean;
  setTimezone: (offset: number) => void;
  saveEmailTemplates: (
    sequenceSteps: SequenceStep[],
    callback: (steps: OutreachStepBlueprintInput[]) => void
  ) => void;
  selectedCreatorsCount: number;
  setSuggestedCreatorsStatus: (status: boolean) => void;
  isSuggestedCreatorsEnabled: boolean;
  selectedPreset: string | null;
  setSelectedPreset: (preset: string | null) => void;
  availableNames: string[];
  setAvailableNameList: (list: string[]) => void;
  checkStepsValidation: () => number[];
  saveOutreachData: () => void;
  emailDailyLimit: InviteOutreachDailyEmailLimitData;
  setEmailDailyLimit: (data: InviteOutreachDailyEmailLimitData) => void;
};

export type InviteOutreachCampaignData = {
  platform: CampaignPlatform;
  suggestedCreatorsExists: boolean;
  useCase: CampaignUseCase | null;
};

export type InviteOutreachTemplateData = {
  id: string;
  name: string;
} & Partial<InviteOutreachTemplateItemData>;

export type InviteOutreachTemplateItemData = {
  subject: string;
  body: string;
  name: string;
};

export type InviteOutreachDailyEmailLimitData = {
  changed: boolean;
  value: number;
};

export type InviteOutreachCreatorsTemplate =
  | {
      htmlBody: string;
      id: string;
      locale: Locale;
      subject: string;
      htmlText?: string;
    }
  | undefined
  | null;

export enum InviteOutreachCreatorsError {
  NONE,
  GENERAL_ERROR,
  INVALID_GMAIL_SCOPE,
  FAILED_TO_CREATE_LIST,
  MUTATION_ERROR,
  NO_SEQUENCE,
}

export enum InviteOutreachSequenceWarning {
  NONE,
  LOW_CREATORS,
  LOW_SEQUENCE_STEPS,
  NO_APPLY_LINK,
}

export enum InviteOutreachCreatorsSource {
  DISCOVERY = 'Discovery',
  LISTS = 'Lists',
  CSV_FILE = 'CSV',
  MANUALLY = 'Manually',
}

export type InviteOutreachCreatorsSelectedLists = {
  id: string;
  count: number;
}[];

const InviteOutreachCreatorsContext = createContext<InviteOutreachCreatorsContextType>(
  {} as InviteOutreachCreatorsContextType
);

export interface SequenceStep {
  id?: string;
  delayInDays?: number | null;
  projectEventType: OutreachStepBlueprint_ProjectEventType;
  startsAtHour: Date;
  validTemplate: boolean;
  template: InviteOutreachCreatorsTemplate;
  templateId?: string;
}

export { InviteOutreachCreatorsContext };
export type { InviteOutreachCreatorsContextType };
