/**
 * @generated SignedSource<<6039fce673ce3697f587b2c676889f37>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Currency = "CAD" | "RUB" | "USD";
import { FragmentRefs } from "relay-runtime";
export type CreatorBirthdate_birthdate$data = {
  readonly birthdate: any | null | undefined;
  readonly currency: Currency | null | undefined;
  readonly " $fragmentType": "CreatorBirthdate_birthdate";
};
export type CreatorBirthdate_birthdate$key = {
  readonly " $data"?: CreatorBirthdate_birthdate$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreatorBirthdate_birthdate">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreatorBirthdate_birthdate",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "birthdate",
      "storageKey": null
    }
  ],
  "type": "CreatorProfile",
  "abstractKey": null
};

(node as any).hash = "f180b43029d2a29f5b5ca0ab96a94857";

export default node;
