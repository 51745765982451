import React, { Suspense, useEffect, useState } from 'react';
import classnames from 'classnames';

import styles from './AdminHelpers.css';
import FailedModeration from './FailedModeration/FailedModeration';
import MailingDeletion from './MailingDeletion/MailingDeletion';
import ResendNotification from './ResendNotification/ResendNotification';
import BulkProjectsArchivation from './BulkProjectsArchivation/BulkProjectsArchivation';
import UploadSurveyResults from './UploadSurveyResults/UploadSurveyResults';
import UserDeletion from './UserDeletion/UserDeletion';

import { amplitude } from 'Helpers/amplitude';
import Text from 'Atoms/Text/Text';
import TextButton from 'Atoms/TextButton/TextButton';
import Page from 'Templates/Page/Page';

const FAILED_MODERATION = 'FAILED_MODERATION';
const MAIL_DELETION = 'MAIL_DELETION';
const RESEND_NOTIFICATION = 'RESEND_NOTIFICATION';
const BULK_PROJECTS_ARCHIVATION = 'BULK_PROJECTS_ARCHIVATION';
const UPLOAD_SURVEY_RESULT = 'UPLOAD_SURVEY_RESULT';
const USER_DELETION = 'USER_DELETION';

const elements = [
  FAILED_MODERATION,
  MAIL_DELETION,
  RESEND_NOTIFICATION,
  BULK_PROJECTS_ARCHIVATION,
  UPLOAD_SURVEY_RESULT,
  USER_DELETION,
];

const AdminHelpers = () => {
  const [currentElement, selectCurrentElement] = useState<undefined | string>(undefined);

  useEffect(() => {
    amplitude.sendEvent<385>({
      id: '385',
      category: 'admin_helpers',
      name: 'pageview',
      param: undefined,
    });
  }, []);

  const getContent = () => {
    switch (currentElement) {
      case FAILED_MODERATION:
        return <FailedModeration />;
      case MAIL_DELETION:
        return <MailingDeletion />;
      case RESEND_NOTIFICATION:
        return <ResendNotification />;
      case BULK_PROJECTS_ARCHIVATION:
        return <BulkProjectsArchivation />;
      case UPLOAD_SURVEY_RESULT:
        return <UploadSurveyResults />;
      case USER_DELETION:
        return <UserDeletion />;
      default:
        break;
    }

    return (
      <div>
        <Text
          tag="h2"
          weight="700"
          text="Please, choose helper from the list"
          data-test="adminHelpers:text:pleaseChooseHelperFromTheList"
        />
      </div>
    );
  };

  return (
    <Page>
      <Suspense fallback={<div />}>
        <div className={styles.root}>
          <div className={styles.sidebar}>
            <div className={styles.list}>
              {elements.map((element) => {
                const onClick = () => {
                  selectCurrentElement(element);
                };

                return (
                  <div
                    key={element}
                    className={classnames(styles.itemWrap, {
                      [styles.active]: element === currentElement,
                    })}
                  >
                    <TextButton
                      msg={`admin.helpers.section.${element.toLowerCase()}.title`}
                      onClick={onClick}
                      className={styles.item}
                      data-test="adminHelpers:textButton:item"
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.content}>{getContent()}</div>
        </div>
      </Suspense>
    </Page>
  );
};

export default AdminHelpers;
