import React, { useState, useEffect, useMemo, useContext } from 'react';
import xor from 'lodash/xor';
import findIndex from 'lodash/findIndex';

import styles from './ProjectCreatorRating.pcss';
import { textualRating, getCharacteristic } from './data';

import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import rateCreator from 'Mutations/RateCreator.Mutation';
import Text from 'Components/ui/Text/Text';
import Button from 'Components/ui/Button/Button';
import Drawer from 'Components/ui/Drawer';
import Tag from 'Components/ui/Tag/Tag';
import Textarea from 'Components/ui/Textarea/Textarea';
import Icon from 'Components/ui/Icon/Icon';
import { CreatorCharacteristic } from 'GraphTypes/RateCreatorMutation.graphql';
import { ChatDrawerContext } from 'AdvertiserPage/Campaign/ChatDrawer/ChatDrawer.Context';

const HIGH_SCORE_MIN = 4;

interface Props {
  projectId: string;
}

const ProjectCreatorRating: React.FC<Props> = (props) => {
  const { closeDrawer } = useContext(DrawerContext);
  const { getCallback } = useContext(ChatDrawerContext);
  const successCompletedCallback = getCallback?.('Completed', 'Success');

  const { projectId } = props;

  const [currentScore, setCurrentScore] = useState<undefined | number>(undefined);
  const [comment, setComment] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isHignScore, setIsHighScore] = useState(false);
  const [reviewSent, setReviewSent] = useState(false);
  const [currentCarechteristics, setCarechteristics] = useState<CreatorCharacteristic[]>([]);

  useEffect(() => {
    const newIsHigh = currentScore && currentScore >= HIGH_SCORE_MIN;

    if (newIsHigh !== isHignScore) {
      setIsHighScore(!!newIsHigh);
      setCarechteristics([]);
    }
  }, [currentScore]);

  useEffect(() => {
    setError(false);
  }, [currentScore, comment, currentCarechteristics]);

  const handleClick = () => {
    if (currentScore) {
      setLoading(true);
      rateCreator(
        {
          projectId,
          score: currentScore,
          comment,
          side: 'AGENCY',
          characteristics: currentCarechteristics,
        },
        handleRatingDone
      );
    }
  };

  const handleCommentChange = (text: string) => {
    setComment(text);
  };

  const handleRatingDone = () => {
    setReviewSent(true);
  };

  const handleClose = () => {
    successCompletedCallback?.();
    closeDrawer('project-creator-rating');
  };

  const carechteristicList = getCharacteristic(isHignScore) as CreatorCharacteristic[];

  const isValid =
    (isHignScore && currentScore) ||
    (currentScore && !isHignScore && (currentCarechteristics.length > 0 || comment));

  const handleSubmitClick = () => {
    if (currentScore && !isHignScore && currentCarechteristics.length === 0 && !comment) {
      setError(true);
    }
  };

  const stars = (
    <div className={styles.ratingWrapper}>
      <div className={styles.textualRating}>
        {textualRating.map((item) => {
          const { score } = item;

          const onClick = () => {
            setCurrentScore(score);
          };

          const deactive = score !== currentScore;

          return (
            <div key={score} onClick={onClick}>
              <Tag
                needMargin={false}
                color={deactive ? 'grey' : 'stroke'}
                msg={`project_creator_rating_modal.textual_rating.${score}`}
              />
            </div>
          );
        })}
      </div>
    </div>
  );

  const wouldCreatorRecommendedText = useMemo(() => {
    if (error) return null;
    switch (currentScore) {
      case 1: {
        return (
          <Text
            type="md"
            msg="project_creator_rating_modal.creator_would_not_be_recommended"
            className={`${styles.wouldCreatorRecommended} ${styles.error}`}
          />
        );
      }
      case 5: {
        return (
          <Text
            type="md"
            msg="project_creator_rating_modal.creator_would_be_recommended"
            className={`${styles.wouldCreatorRecommended} ${styles.recommendation}`}
          />
        );
      }
      default: {
        return null;
      }
    }
  }, [error, currentScore]);

  if (reviewSent) {
    return (
      <Drawer rootKey="project-creator-rating" className={styles.drawer}>
        <div className={styles.container}>
          <Text
            type="d2"
            msg="project_creator_rating_modal.review_sent.title"
            className={styles.title}
          />
          <Text type="md" msg="project_creator_rating_modal.review_sent.descr" />
          <div className={styles.resultContent}>
            <div className={styles.result}>
              <Icon name="Check" size={120} className={styles.resultIcon} />
            </div>
          </div>
          <Button
            color="black"
            msg="project_creator_rating_modal.review_sent.close"
            onClick={handleClose}
          />
        </div>
      </Drawer>
    );
  }

  return (
    <Drawer rootKey="project-creator-rating" needCloseButton={false} className={styles.drawer}>
      <div className={styles.container}>
        <Text
          type="d2"
          msg="project_creator_rating_modal.deal_rating_text.title"
          className={styles.title}
        />
        <Text type="md" msg="project_creator_rating_modal.deal_rating_text.descr" />
        <div className={styles.content}>
          {stars}
          {currentScore && (
            <>
              <Text
                type="h2"
                msg={
                  isHignScore
                    ? 'project_creator_rating_modal.what_was_good'
                    : 'project_creator_rating_modal.what_was_bad'
                }
              />
              <div className={styles.carechteristics}>
                {carechteristicList.map((item) => {
                  const handleCarechteristicClick = () => {
                    const newList = xor(currentCarechteristics, [item]);
                    setCarechteristics(newList);
                  };

                  const isActive =
                    findIndex(currentCarechteristics, (value) => value === item) !== -1;

                  return (
                    <div key={item} onClick={handleCarechteristicClick}>
                      <Tag
                        needMargin={false}
                        color={isActive ? 'stroke' : 'grey'}
                        msg={`rating.characteristic.${item.toLowerCase()}`}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          )}
          {currentScore && (
            <>
              <Text
                type="h2"
                msg="project_creator_rating_modal.write_review"
                className={styles.subtitle}
              />
              <Textarea
                bordered
                placeholderMsg="project_creator_rating_modal.comment.placeholder"
                handleChange={handleCommentChange}
              />
            </>
          )}
        </div>
        {error && <Text msg="project_creator_rating_modal.error" className={styles.error} />}
        {wouldCreatorRecommendedText}
        <div onClick={handleSubmitClick}>
          <Button
            color="black"
            disabled={!isValid}
            loading={loading}
            msg="project_creator_rating_modal.submit"
            onClick={handleClick}
            className={styles.submit}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default ProjectCreatorRating;
