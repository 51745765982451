import React, { FC, PropsWithChildren } from 'react';

import { GmailAuthBackendError, GmailAuthError } from './utils';

import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { useGmail } from 'Hooks/useGmail';

interface Props {
  callback?: (authData: unknown) => void;
  buttonProps?: Record<string, unknown>;
  authIdForReauth?: string;
}

const AddGmailAccountButton: FC<PropsWithChildren<Props>> = (props) => {
  const { callback, buttonProps, authIdForReauth, children } = props;
  const { authorize } = useGmail();
  const handleAddGoogleAccount = async () => {
    try {
      const authData = await authorize(authIdForReauth);
      if (authData?.error) {
        throw new GmailAuthError();
      } else {
        if (authData?.data?.__typename === 'AuthenticateWithGmailPayload') {
          if (callback) {
            callback(authData?.data);
          }
        } else {
          throw new GmailAuthBackendError();
        }
      }
    } catch (e) {
      if (e instanceof GmailAuthError) {
        //
      } else if (e instanceof GmailAuthBackendError) {
        //
      }
    }
  };
  if (children) {
    return <div onClick={handleAddGoogleAccount}>{children}</div>;
  }

  return (
    <AlterButton
      msg="campaign.outreach.add_gmail"
      type="black"
      icon={'Add-plus'}
      {...buttonProps}
      onClick={handleAddGoogleAccount}
      data-test="addGmailAccountButton:alterButton:addGmail"
    />
  );
};

export default AddGmailAccountButton;
