import React from 'react';

import GmailSettings from '../GmailSettings/GmailSettings';
import CreatorsListSelector from '../CreatorsListSelector/CreatorsListSelector';
import SequenceSelector from '../SequenceSelector/SequenceSelector';

import styles from './OutreachSettings.pcss';

import { SequenceSelectorForOutreach_list$key } from 'GraphTypes/SequenceSelectorForOutreach_list.graphql';
import { GmailSettingsForOutreach_campaign$key } from 'GraphTypes/GmailSettingsForOutreach_campaign.graphql';

interface Props {
  campaignQuery: GmailSettingsForOutreach_campaign$key;
  query: SequenceSelectorForOutreach_list$key;
  userQuery: SequenceSelectorForOutreach_list$key;
  openedField?: string | null;
}

const OutreachSettings: React.FC<Props> = (props) => {
  const { query, userQuery, campaignQuery, openedField } = props;
  return (
    <div className={styles.root}>
      <SequenceSelector
        query={query as SequenceSelectorForOutreach_list$key}
        userQuery={userQuery}
      />
      <CreatorsListSelector isOpened={openedField === 'creatorList'} />
      <GmailSettings query={campaignQuery} />
    </div>
  );
};

export default OutreachSettings;
