import React, { ChangeEvent, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useQueryLoader, GraphQLTaggedNode, PreloadedQuery } from 'react-relay';
import { OperationType } from 'relay-runtime';
import xor from 'lodash/xor';
import classNames from 'classnames';

import ProjectsFilterLoader from '../ProjectsFilterLoader/ProjectsFilterLoader';
import ProjectFilterLabel from '../ProjectFilterLabel/ProjectFilterLabel';

import styles from './ProjectFilterContainer.pcss';

import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import { Props as TextProps } from 'Components/ui/Text/Text';
import Input from 'Components/ui/Input/Input';
import AlterButton from 'Components/ui/AlterButton/AlterButton';

interface Props {
  selectedItems: string[];
  onChange: (items: string[]) => void;
  query: GraphQLTaggedNode;
  anchorMsg: string;
  queryParams?: any;
  listComponent: ReactElement<ListComponentProps>;
  className?: string;
}

interface ListComponentProps {
  selectedItems: string[];
  onChange: (item: string, label: string) => void;
  queryReference: PreloadedQuery<OperationType, Record<string, unknown>>;
}

const ProjectFilterContainer: React.FC<Props> = (props) => {
  const {
    selectedItems = [],
    onChange,
    listComponent,
    query,
    queryParams,
    anchorMsg,
    className,
  } = props;
  const controller = useRef<AbortController>();
  const [entityFilter, setEntityFilter] = useState('');
  const [labelList, setLabelList] = useState<Record<string, string>>({});
  const [queryReference, loadEntityQuery] = useQueryLoader(query);

  const handleSelect = (entityId: string, brandLabel: string) => {
    setLabelList((prevLabelList) => ({ ...prevLabelList, [entityId]: brandLabel }));
    const entityIds = xor(selectedItems, [entityId]);
    onChange(entityIds);
  };

  const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEntityFilter(e.target.value);
    if (controller.current) {
      controller.current.abort();
    }
    controller.current = new AbortController();
  };

  const handleFilterValueReset = () => {
    setEntityFilter('');
  };

  useEffect(() => {
    loadEntityQuery({ textQuery: '', ...queryParams }, { fetchPolicy: 'store-and-network' });
  }, []);

  useEffect(() => {
    loadEntityQuery(
      { textQuery: entityFilter, ...queryParams },
      {
        fetchPolicy: 'store-and-network',
        networkCacheConfig: {
          metadata: {
            signal: controller?.current?.signal,
          },
        },
      }
    );
  }, [entityFilter, controller?.current, queryParams]);

  const labels = useMemo(() => {
    if (!Object.keys(labelList).length) return null;
    return selectedItems.map((entityId) => labelList[entityId]).join(', ');
  }, [selectedItems, labelList]);

  const handleLoadLabels = (labelList: Record<string, string>) => {
    setLabelList(labelList);
  };

  const handleClearFilter = () => {
    onChange([]);
  };

  const anchorProps: Partial<TextProps> = labels ? { text: labels } : { msg: anchorMsg };

  return (
    <Dropdown
      closeBySelect={false}
      mountedByDefault
      value={<ProjectFilterLabel valueLength={selectedItems.length} anchorProps={anchorProps} />}
    >
      <DropdownGroup className={classNames(styles.list, className)}>
        <div className={styles.searchContainer}>
          <Input
            borderless
            type="text"
            leftIcon="Search-loop"
            placeholderMsg="projects.filter_input.placeholder"
            value={entityFilter}
            onChange={handleFilterChange}
            onResetValue={handleFilterValueReset}
            data-test="projectFilterContainer:input:unknown"
          />
        </div>
        <div className={styles.listContainer}>
          <React.Suspense fallback={<ProjectsFilterLoader />}>
            {queryReference &&
              React.cloneElement(listComponent, {
                onChange: handleSelect,
                queryReference,
                selectedItems,
                onLoadData: handleLoadLabels,
              })}
          </React.Suspense>
        </div>
        {selectedItems.length > 0 && (
          <AlterButton
            msg={'projects.filter.clear'}
            className={styles.clearBtn}
            handleClick={handleClearFilter}
            data-test="projectFilterContainer:alterButton:clearBtn"
          />
        )}
      </DropdownGroup>
    </Dropdown>
  );
};

export default ProjectFilterContainer;
