import React, { useContext } from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';

import styles from './CreatorChat.pcss';

import {
  default as BcaPermissionModal,
  BcaPermissionModalProps,
} from 'Modal/advertiser/BcaPermissionModal/BcaPermissionModal';
import BcaRequestPermissions from 'Modal/advertiser/BcaRequestPermissions/BcaRequestPermissions';
import ProjectNotesDrawer from 'Modal/advertiser/ProjectNotesDrawer/ProjectNotesDrawer';
import FinishDealWrapperProvider from 'Molecules/FinishDealWrapper/FinishDealWrapperContext';
import { ProjectInfoContextProvider } from 'CommonPage/Chat/Dialog/ProjectInfo/ProjectInfo.Context';
import { CreatorChatQuery as QueryType } from 'GraphTypes/CreatorChatQuery.graphql';
import { PlanId } from 'GraphTypes/BriefQuery.graphql';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { ShopifyChatTooltip } from 'Components/ShopifyChatTooltip/ShopifyChatTooltip';

const CreatorChatQuery = graphql`
  query CreatorChatQuery($projectId: ID!) {
    currentUser {
      admin
      advertiserProfile {
        id
      }
      organization {
        subscription {
          effectiveLimits {
            maxHiredCreators
            maxLicensedCreators
            maxBcaConnections
          }
          planId
        }
        counters {
          hiredCreators
          psLicensedCreators
          bcaLicensedCreators
        }
      }
    }
    project(id: $projectId) {
      adminComment
      comment
      advertiserStage
      publicationEventExists
      price
      contentReview {
        status
      }
      creator {
        type
        ... on InstagramCreator {
          insightsAuthorized
          user {
            id
          }
        }
      }
      bcaRequest {
        permission {
          id
        }
      }
      campaign {
        type
        useCase
        platform
        paymentType
        showShipmentTab
        publishingRequired
        id
        shopifyAuthorization {
          id
        }
      }
      launch {
        id
      }
    }
  }
`;

interface Props {
  projectId: string;
  chat?: JSX.Element;
}

const CreatorChat: React.FC<Props> = (props) => {
  const { projectId, chat } = props;

  const data = useLazyLoadQuery<QueryType>(CreatorChatQuery, { projectId });

  if (!data) return null;

  const subscription = data.currentUser?.organization?.subscription;
  const maxLicensedCreators = subscription?.effectiveLimits.maxLicensedCreators;
  const maxHiredCreators = subscription?.effectiveLimits.maxHiredCreators;
  const maxBcaConnections = subscription?.effectiveLimits.maxBcaConnections;
  const planId = subscription?.planId as PlanId;
  const counters = data.currentUser?.organization?.counters;
  const hiredCreators = counters?.hiredCreators;
  const psLicensedCreators = counters?.psLicensedCreators;
  const bcaLicensedCreators = counters?.bcaLicensedCreators;
  const isAdvertiser = !!data?.currentUser?.advertiserProfile;
  const shipmentEnabled = data?.project?.campaign?.showShipmentTab;
  const campaignId = data?.project?.campaign?.id;
  const campaignType = data?.project?.campaign?.type;
  const platform = data?.project?.campaign?.platform;
  const publishingRequired = data?.project?.campaign?.publishingRequired;
  const isBarter = data?.project?.campaign?.paymentType === 'BARTER';
  const advertiserStage = data?.project?.advertiserStage;
  const creatorType = data?.project?.creator?.type;
  const insightsAuthorized = data?.project?.creator?.insightsAuthorized;
  const publicationEventExists = data?.project?.publicationEventExists;
  const launched = !!data?.project?.launch?.id;
  const price = data?.project?.price;
  const hasSuccessReview = data.project?.contentReview?.status === 'SUCCESS';
  const isFreeOrganic =
    !price &&
    (data?.project?.campaign?.useCase === 'AFFILIATE_CAMPAIGN' ||
      data?.project?.campaign?.useCase === 'TIKTOK_SHOP');
  const bcaPermissionId = data?.project?.bcaRequest?.permission?.id;

  const { openDrawer, closeDrawer } = useContext(DrawerContext);

  const needRequestLink = advertiserStage === 'PUBLISHING_STARTED' && !publicationEventExists;

  const handleBcaConnectionImpossible = (reason: string) => {
    if (reason === 'maxLicensedCreatorsExceeded') {
      openDrawer('max-licensed-creators-exceeded');
    }
  };

  return (
    <ProjectInfoContextProvider
      subscription={{ maxHiredCreators, maxLicensedCreators, maxBcaConnections, planId }}
      counters={{ hiredCreators, psLicensedCreators, bcaLicensedCreators }}
    >
      <FinishDealWrapperProvider
        projectId={projectId}
        needRequestLink={needRequestLink}
        campaignType={campaignType}
        campaignPlatform={platform}
        creatorType={creatorType}
        insightsAuthorized={insightsAuthorized}
        selectSubmissionFiles={publishingRequired || hasSuccessReview}
        isBarter={isBarter}
        price={price}
        isFreeOrganic={isFreeOrganic}
        needClickHandler={false}
      >
        {chat}
      </FinishDealWrapperProvider>
    </ProjectInfoContextProvider>
  );
};

export default CreatorChat;
