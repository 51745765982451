import React from 'react';
import classNames from 'classnames';

import styles from '../SequenceItem.pcss';
import { SequenceStep } from '../../../InviteOutreachCreatorsContext';

import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownGroupItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';

interface Props {
  disableEmptyValue: boolean;
  data: SequenceStep;
  handleDelayValue: (value: number | null) => void;
  isSendNowOptionSelected?: boolean;
}

const DelaySelector: React.FC<Props> = (props) => {
  const { data, disableEmptyValue, handleDelayValue, isSendNowOptionSelected } = props;

  return (
    <div className={styles.mailOptions}>
      {!isSendNowOptionSelected && (
        <Text
          type="md"
          className={styles.mailOptionText}
          msg="invite_outreach_creators_modal.sequence.delay"
        />
      )}

      <Dropdown
        showArrow={isSendNowOptionSelected}
        className={classNames(styles.delayInput, { [styles.withArrow]: isSendNowOptionSelected })}
        value={
          <Text
            type="md"
            className={styles.mailOptionValue}
            text={isSendNowOptionSelected ? undefined : data?.delayInDays || '0'}
            textPriority={!isSendNowOptionSelected}
            msg="invite_outreach_creators_modal.sequence.send_now"
          />
        }
      >
        <DropdownGroup align="left" className={styles.delayValueList}>
          {disableEmptyValue ? null : (
            <>
              {!isSendNowOptionSelected && (
                <DropdownGroupItem
                  handleClick={() => {
                    handleDelayValue(null);
                  }}
                >
                  <AlterButton
                    msg="invite_outreach_creators_modal.sequence.send_now"
                    className={styles.delayValue}
                  />
                </DropdownGroupItem>
              )}

              <DropdownGroupItem
                handleClick={() => {
                  handleDelayValue(0);
                }}
              >
                <AlterButton
                  msg="invite_outreach_creators_modal.sequence.days_count"
                  formatValues={{ count: 0 }}
                  className={styles.delayValue}
                />
              </DropdownGroupItem>
            </>
          )}

          {[...Array(30).keys()].map((item) => (
            <DropdownGroupItem
              key={item}
              handleClick={() => {
                handleDelayValue(item + 1);
              }}
            >
              <AlterButton
                msg="invite_outreach_creators_modal.sequence.days_count"
                formatValues={{ count: item + 1 }}
                className={styles.delayValue}
              />
            </DropdownGroupItem>
          ))}
        </DropdownGroup>
      </Dropdown>
      {!isSendNowOptionSelected && (
        <Text
          type="md"
          className={styles.mailOptionText}
          msg="invite_outreach_creators_modal.sequence.days"
          formatValues={{ count: data?.delayInDays || 0 }}
        />
      )}
    </div>
  );
};

export default DelaySelector;
