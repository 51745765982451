import React from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import * as Scroll from 'react-scroll';

import environment from 'Api/Environment';
const Element = Scroll.Element;

import styles from './CreatorReviews.css';

import Text from 'Atoms/Text/Text';
import Spinner from 'Atoms/Spinner/Spinner';

import CreatorReviewsList from './CreatorReviewsList/CreatorReviewsList';

import { CreatorReviewsQuery as QueryType } from 'GraphTypes/CreatorReviewsQuery.graphql';

const CreatorReviewsQuery = graphql`
  query CreatorReviewsQuery($creatorId: ID!) {
    creator(id: $creatorId) {
      ...CreatorReviewsList_ratingVotes
      ratingCount: ratingVotes(side: AGENCY) {
        totalCount
      }
    }
  }
`;

interface Props {
  creatorId: string;
  admin?: boolean;
}

const CreatorReviews: React.FC<Props> = (props) => {
  const { creatorId, admin } = props;

  return (
    <QueryRenderer<QueryType>
      environment={environment}
      query={CreatorReviewsQuery}
      variables={{ creatorId }}
      render={({ props: queryProps }) => {
        if (!queryProps) return <Spinner className={styles.preloader} />;

        const creator = queryProps.creator;

        if (!creator) return null;

        const ratingCount = creator.ratingCount;

        if (ratingCount.totalCount === 0) return null;

        return (
          <Element name="reviews">
            <div className={styles.root}>
              <Text
                tag="h1"
                weight="700"
                msg="creator_statistic.reviews.title"
                className={styles.title}
                data-test="creatorReviews:text:title"
              />
              <CreatorReviewsList admin={admin} creatorId={creatorId} ratingVotes={creator} />
            </div>
          </Element>
        );
      }}
    />
  );
};

export default CreatorReviews;
