import React, { useContext, useEffect } from 'react';

import NewInviteCreatorsButton from '../NewInviteCreatorsButton/NewInviteCreatorsButton';
import {
  MESSAGED,
  INVITATION_SENT,
  RECIEVED_APPLICANTS,
  HIRED,
  DECLINED,
  DELIVERABLES,
  POSTING_CONTENT,
  JUST_CONTENT,
  CONTENT_CREATION,
  COMPLETED,
} from '../NewCampaignTabs/util';

import styles from './SectionEmptyState.pcss';

import { getCampaignTypeLink } from 'Util/links';
import recievedApplicantsImg from 'Images/campaign/recieved_applicants.png';
import recievedApplicants2xImg from 'Images/campaign/recieved_applicants_2x.png';
import messagedImg from 'Images/campaign/messaged.png';
import messaged2xImg from 'Images/campaign/messaged_2x.png';
import invitationImg from 'Images/campaign/invitation_sent.png';
import invitation2xImg from 'Images/campaign/invitation_sent_2x.png';
import archivedImg from 'Images/campaign/archived.png';
import archived2xImg from 'Images/campaign/archived_2x.png';
import completedImg from 'Images/campaign/completed.png';
import completed2xImg from 'Images/campaign/completed_2x.png';
import hiredImg from 'Images/campaign/hired.png';
import hired2xImg from 'Images/campaign/hired_2x.png';
import declinedImg from 'Images/campaign/declined.png';
import declined2xImg from 'Images/campaign/declined_2x.png';
import contentImg from 'Images/campaign/content.png';
import content2xImg from 'Images/campaign/content_2x.png';
import Text from 'Components/ui/Text/Text';
import { APPLICANTS, ARCHIVED, AWAITING_LIST, CREATORS, RESULTS } from 'Constants/general';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import { CampaignContext } from 'AdvertiserPage/Campaign/Campaign.Context';
import { amplitude } from 'Helpers/amplitude';

interface Props {
  section?: string;
  campaignId: string;
  publishedPosts?: boolean;
}

const SectionEmptyState: React.FC<Props> = (props) => {
  const { section = '', campaignId, publishedPosts } = props;

  useEffect(() => {
    amplitude.sendEvent<530>({
      id: '530',
      category: 'campaign_page',
      name: 'empty_state_show',
      param: { campaign_id: campaignId, section },
    });
  }, []);

  const { inviteSuggestionListCount, canInviteSuggestedCreators } = useContext(CampaignContext);

  const getContent = () => {
    switch (section) {
      case RECIEVED_APPLICANTS:
      case APPLICANTS:
        return (
          <div>
            <img
              srcSet={`${recievedApplicantsImg} 1x, ${recievedApplicants2xImg} 2x`}
              className={styles.img}
            />
            <Text
              type="h1"
              weight="bold"
              msg={
                inviteSuggestionListCount && canInviteSuggestedCreators
                  ? 'campaign.recieved_applicants.empty_with_list.title'
                  : 'campaign.recieved_applicants.empty.title'
              }
              formatValues={{ count: inviteSuggestionListCount }}
              className={styles.title}
            />
            <Text
              type="sm"
              color="grey"
              msg={
                inviteSuggestionListCount && canInviteSuggestedCreators
                  ? 'campaign.recieved_applicants.empty_with_list.descr'
                  : 'campaign.recieved_applicants.empty.descr'
              }
            />
            <NewInviteCreatorsButton
              colorType="white"
              campaignId={campaignId}
              className={styles.inviteBtn}
            />
          </div>
        );
      case MESSAGED:
        return (
          <div>
            <img srcSet={`${messagedImg} 1x, ${messaged2xImg} 2x`} className={styles.img} />
            <Text
              type="h1"
              weight="bold"
              msg="campaign.messaged.empty.title"
              className={styles.title}
            />
            <Text type="sm" color="grey" msg="campaign.messaged.empty.descr" />
            <SmartLink
              path={getCampaignTypeLink({ campaignId, path: RECIEVED_APPLICANTS })}
              className={styles.btn}
            >
              <AlterButton type="white" bordered msg="campaign.messaged.empty.btn" />
            </SmartLink>
          </div>
        );
      case ARCHIVED:
        return (
          <div>
            <img srcSet={`${archivedImg} 1x, ${archived2xImg} 2x`} className={styles.img} />
            <Text
              type="h1"
              weight="bold"
              msg="campaign.archived.empty.title"
              className={styles.title}
            />
            <Text type="sm" color="grey" msg="campaign.archived.empty.descr" />
          </div>
        );
      case INVITATION_SENT:
      case AWAITING_LIST:
        return (
          <div>
            <img srcSet={`${invitationImg} 1x, ${invitation2xImg} 2x`} className={styles.img} />
            <Text
              type="h1"
              weight="bold"
              msg={
                inviteSuggestionListCount && canInviteSuggestedCreators
                  ? 'campaign.invitation_sent.empty_with_list.title'
                  : 'campaign.invitation_sent.empty.title'
              }
              formatValues={{ count: inviteSuggestionListCount }}
              className={styles.title}
            />
            <Text
              type="sm"
              color="grey"
              msg={
                inviteSuggestionListCount && canInviteSuggestedCreators
                  ? 'campaign.invitation_sent.empty_with_list.descr'
                  : 'campaign.invitation_sent.empty.descr'
              }
            />
            <NewInviteCreatorsButton
              colorType="white"
              campaignId={campaignId}
              className={styles.inviteBtn}
            />
          </div>
        );
      case HIRED:
      case CREATORS:
      case CONTENT_CREATION:
        return (
          <div>
            <img srcSet={`${hiredImg} 1x, ${hired2xImg} 2x`} className={styles.img} />
            <Text
              type="h1"
              weight="bold"
              msg="campaign.hired.empty.title"
              className={styles.title}
            />
            <Text type="sm" color="grey" msg="campaign.hired.empty.descr" />
            <SmartLink
              path={getCampaignTypeLink({ campaignId, path: RECIEVED_APPLICANTS })}
              className={styles.btn}
            >
              <AlterButton type="white" bordered msg="campaign.messaged.empty.btn" />
            </SmartLink>
          </div>
        );
      case COMPLETED:
        return (
          <div>
            <img srcSet={`${completedImg} 1x, ${completed2xImg} 2x`} className={styles.img} />
            <Text
              type="h1"
              weight="bold"
              msg="campaign.completed.empty.title"
              className={styles.title}
            />
            <Text type="sm" color="grey" msg="campaign.completed.empty.descr" />
          </div>
        );
      case DECLINED:
        return (
          <div>
            <img srcSet={`${declinedImg} 1x, ${declined2xImg} 2x`} className={styles.img} />
            <Text
              type="h1"
              weight="bold"
              msg="campaign.declined.empty.title"
              className={styles.title}
            />
            <Text type="sm" color="grey" msg="campaign.declined.empty.descr" />
          </div>
        );

      case DELIVERABLES:
      case RESULTS:
        return (
          <div>
            <img srcSet={`${contentImg} 1x, ${content2xImg} 2x`} className={styles.img} />
            <Text
              type="h1"
              weight="bold"
              msg={
                publishedPosts
                  ? 'campaign.content.posting.empty.title'
                  : 'campaign.content.no_posting.empty.title'
              }
              className={styles.title}
            />
            <Text
              type="sm"
              color="grey"
              msg={
                publishedPosts
                  ? 'campaign.content.posting.empty.descr'
                  : 'campaign.content.no_posting.empty.descr'
              }
            />
          </div>
        );
      case JUST_CONTENT:
        return (
          <div>
            <img srcSet={`${contentImg} 1x, ${content2xImg} 2x`} className={styles.img} />
            <Text
              type="h1"
              weight="bold"
              msg="campaign.content.no_posting.empty.title"
              className={styles.title}
            />
            <Text type="sm" color="grey" msg="campaign.content.no_posting.empty.descr" />
          </div>
        );
      case POSTING_CONTENT:
        return (
          <div>
            <img srcSet={`${contentImg} 1x, ${content2xImg} 2x`} className={styles.img} />
            <Text
              type="h1"
              weight="bold"
              msg="campaign.content.posting.empty.title"
              className={styles.title}
            />
            <Text type="sm" color="grey" msg="campaign.content.posting.empty.descr" />
          </div>
        );

      default:
        return <div />;
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.content}>{getContent()}</div>
    </div>
  );
};

export default SectionEmptyState;
