import React from 'react';
import classnames from 'classnames';
import { AnimatePresence, motion } from 'motion/react';

import styles from './InlineTab.pcss';

import Text from 'Components/ui/Text/Text';
import poly from 'Images/icons/poly.svg';

export interface Props {
  id: string;
  msg: string;
  active?: boolean;
  hasUnread?: boolean;
  onClick: (id: string) => void;
  tabMessage?: { targetTab: string; value: number | string };
  tabKey?: string;
  notificationEnabled?: boolean;
  hasNotification?: boolean;
}

const InlineTab: React.FC<Props> = (props) => {
  const { id, msg, active, hasUnread, onClick, notificationEnabled, hasNotification, tabMessage } =
    props;

  const handleClick = () => {
    onClick(id);
  };

  return (
    <div onClick={handleClick} className={classnames(styles.item, { [styles.active]: !!active })}>
      <Text
        color={active ? 'pink' : undefined}
        weight="medium"
        msg={msg}
        className={styles.title}
      />
      {hasUnread && <div className={styles.unread} />}
      {notificationEnabled && (
        <AnimatePresence>
          {hasNotification && (
            <motion.div
              className={styles.notification}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              <img src={poly} alt="" className={styles.poly} />
              <Text msg={tabMessage?.value || ''} color="white" type="sm" />
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </div>
  );
};

export default InlineTab;
