import React, { FC, memo, MouseEvent } from 'react';
import { graphql, useFragment, useLazyLoadQuery } from 'react-relay';
import { generatePath, Link, useLocation, useNavigate } from 'react-router-dom';

import AddGmailAccountButton from '../../OutreachBatchesControls/AddGmailAccountButton/AddGmailAccountButton';

import styles from './OutreachBatch.pcss';

import Text from 'Components/ui/Text/Text';
import Icon, { IconNameWithAdditional } from 'Components/ui/Icon/Icon';
import {
  OutreachBatch_outreachBatch$key,
  OutreachBatchState,
} from 'GraphTypes/OutreachBatch_outreachBatch.graphql';
import { OutreachBatchQuery } from 'GraphTypes/OutreachBatchQuery.graphql';
import Tag from 'Components/ui/Tag/Tag';
import { Color } from 'Components/ui/types';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownGroupItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { createNumber } from 'Util/numberFormatter';
import { CREATORS_LIST_ROUTE, OUTREACH_BATCH_VIEW_ROUTE } from 'Constants/general';
import Tooltip from 'Atoms/Tooltip/Tooltip';

interface Props {
  batch?: OutreachBatch_outreachBatch$key | null;
  campaignId: string;
}

interface StatusType {
  color: Color;
  icon: IconNameWithAdditional;
  msg: string;
}

type OutreachBatchStateType = Exclude<OutreachBatchState, '%future added value'>;

const statusToTagMap: Record<OutreachBatchStateType, StatusType> = {
  ACTIVE: {
    color: 'grass',
    icon: 'Arrow-up-right',
    msg: 'campaign.outreach.state.active',
  },
  COMPLETED: {
    color: 'ocean',
    icon: 'Check',
    msg: 'campaign.outreach.state.completed',
  },
  DISCONNECTED: {
    color: 'sun',
    icon: 'Google',
    msg: 'campaign.outreach.state.disconnected',
  },
  INITIAL: {
    color: 'grey',
    icon: 'Timer',
    msg: 'campaign.outreach.state.initial',
  },
  STOPPED: {
    color: 'grey',
    icon: 'Pause',
    msg: 'campaign.outreach.state.stopped',
  },
};

const OutreachBatch: FC<Props> = ({ batch, campaignId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const fragment = useFragment(
    graphql`
      fragment OutreachBatch_outreachBatch on OutreachBatch {
        id
        state
        createdAt
        senderEmail
        gmailAuthorization {
          id
          reauthenticationRequired
        }
        customLists {
          edges {
            node {
              id
              name
            }
          }
        }
        sequences {
          totalCount
        }
        creatorCount
        sequenceBlueprint {
          name
          steps {
            totalCount
            edges {
              node {
                id
              }
            }
          }
        }
      }
    `,
    batch
  );

  if (!fragment) return null;

  const batchId = fragment.id;
  const usedLists = fragment?.customLists.edges;

  const data = useLazyLoadQuery<OutreachBatchQuery>(
    graphql`
      query OutreachBatchQuery($batchId: ID!) {
        outreachBatch(id: $batchId) {
          reachedCount: sequences(outreached: true) {
            totalCount
          }
          clickedCount: sequences(clicked: true) {
            totalCount
          }
          repliedCount: sequences(replied: true) {
            totalCount
          }
          appliedCount: sequences(applied: true) {
            totalCount
          }
          hiredCount: sequences(hired: true) {
            totalCount
          }
        }
      }
    `,
    { batchId: batchId || '' }
  );

  const formatCounter = (value?: number) => {
    const valueText = createNumber(value);
    return valueText === '0' ? '-' : valueText;
  };

  const hasReplied =
    data?.outreachBatch?.repliedCount.totalCount &&
    data?.outreachBatch?.repliedCount.totalCount > 0;
  const hasApplied =
    data?.outreachBatch?.appliedCount.totalCount &&
    data?.outreachBatch?.appliedCount.totalCount > 0;
  const hasHired =
    data?.outreachBatch?.hiredCount.totalCount && data?.outreachBatch?.hiredCount.totalCount > 0;

  const reachedCount = formatCounter(data?.outreachBatch?.reachedCount.totalCount);
  const clickedCount = formatCounter(data?.outreachBatch?.clickedCount.totalCount);
  const hiredCount = formatCounter(data?.outreachBatch?.hiredCount.totalCount);
  const appliedCount = formatCounter(data?.outreachBatch?.appliedCount.totalCount);
  const repliedCount = formatCounter(data?.outreachBatch?.repliedCount.totalCount);

  const handleOpenPreview = () => {
    const batchId = fragment?.id;
    if (!batchId || !campaignId) return;
    navigate(
      generatePath(OUTREACH_BATCH_VIEW_ROUTE, {
        batchId,
        id: campaignId,
      })
    );
  };

  const handleSavePathToRedirect = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    localStorage.setItem('outreachPath', location.pathname);
  };

  return (
    <div className={styles.root}>
      <div className={styles.dataContainer}>
        <div className={styles.titleContainer}>
          <Text type="md" text={fragment.sequenceBlueprint?.name || '—'} />
          <div className={styles.infoContainer}>
            <div className={styles.infoItem}>
              <Icon name="Mail" color="grey" size={16} />
              <Text type="label" text={fragment.senderEmail || '—'} />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.countersContainer}>
        <div className={styles.subtitleContainer}>
          <Text type="md" text={reachedCount} />
        </div>
        <div className={styles.subtitleContainer}>
          <Text type="md" text={clickedCount} />
        </div>
        <div className={styles.subtitleContainer}>
          {hasReplied ? (
            <Link to={`/campaigns/${campaignId}/awaiting-list`}>
              <Text type="md" text={repliedCount} />
            </Link>
          ) : (
            <Text type="md" text={repliedCount} />
          )}
        </div>
        <div className={styles.subtitleContainer}>
          {hasApplied ? (
            <Link to={`/campaigns/${campaignId}/applicants`}>
              <Text type="md" text={appliedCount} className={styles.purple} />
            </Link>
          ) : (
            <Text type="md" text={appliedCount} />
          )}
        </div>
        <div className={styles.subtitleContainer}>
          {hasHired ? (
            <Link to={`/campaigns/${campaignId}/creators`}>
              <Text type="md" text={hiredCount} className={styles.purple} />
            </Link>
          ) : (
            <Text type="md" text={hiredCount} />
          )}
        </div>
        <div className={styles.subtitleContainer}>
          {fragment.state === 'DISCONNECTED' ? (
            <Tooltip
              id={batchId}
              tooltipMsg="campaign.outreach.batch.disconnected.tooltip"
              tooltipValues={{ email: fragment.senderEmail }}
            >
              <AddGmailAccountButton authIdForReauth={fragment.gmailAuthorization?.id}>
                <Tag
                  {...statusToTagMap[fragment.state as OutreachBatchStateType]}
                  className={styles.stateTag}
                  needMargin={false}
                />
              </AddGmailAccountButton>
            </Tooltip>
          ) : (
            <Tag {...statusToTagMap[fragment.state as OutreachBatchStateType]} needMargin={false} />
          )}
        </div>
      </div>

      <div className={styles.actionContainer} onClick={(e) => e.preventDefault()}>
        <Dropdown showArrow={false} value={<Icon name="Dots-menu" />}>
          <DropdownGroup className={styles.actionsMenu}>
            <DropdownGroupItem>
              <AlterButton
                icon="Eye"
                textByLeftSide
                fluid
                msg="campaign.outreach.batch.action.preview"
                onClick={handleOpenPreview}
              />
            </DropdownGroupItem>
            {Boolean(usedLists?.length && usedLists?.length > 0) && (
              <>
                <Text type="label" text="Creator lists" color="grey" className={styles.listLabel} />
                {usedLists?.map((list) => (
                  <Link
                    key={list?.node?.id}
                    to={`${CREATORS_LIST_ROUTE}/${list?.node?.id}`}
                    target="_blank"
                  >
                    <DropdownGroupItem>
                      <AlterButton
                        onClick={handleSavePathToRedirect}
                        icon="Save-list"
                        textByLeftSide
                        fluid
                        text={list?.node?.name}
                      />
                    </DropdownGroupItem>
                  </Link>
                ))}
              </>
            )}
          </DropdownGroup>
        </Dropdown>
      </div>
    </div>
  );
};

export default memo(OutreachBatch);
