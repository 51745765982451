/**
 * @generated SignedSource<<6b1a5b5cde1035084392610850842b5c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OutreachBatchState = "ACTIVE" | "COMPLETED" | "DISCONNECTED" | "INITIAL" | "STOPPED";
import { FragmentRefs } from "relay-runtime";
export type OutreachBatch_outreachBatch$data = {
  readonly createdAt: any;
  readonly creatorCount: number;
  readonly customLists: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  };
  readonly gmailAuthorization: {
    readonly id: string;
    readonly reauthenticationRequired: boolean;
  } | null | undefined;
  readonly id: string;
  readonly senderEmail: string | null | undefined;
  readonly sequenceBlueprint: {
    readonly name: string;
    readonly steps: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      readonly totalCount: number;
    } | null | undefined;
  } | null | undefined;
  readonly sequences: {
    readonly totalCount: number;
  };
  readonly state: OutreachBatchState;
  readonly " $fragmentType": "OutreachBatch_outreachBatch";
};
export type OutreachBatch_outreachBatch$key = {
  readonly " $data"?: OutreachBatch_outreachBatch$data;
  readonly " $fragmentSpreads": FragmentRefs<"OutreachBatch_outreachBatch">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OutreachBatch_outreachBatch",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "senderEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GmailAuthorization",
      "kind": "LinkedField",
      "name": "gmailAuthorization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reauthenticationRequired",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomListConnection",
      "kind": "LinkedField",
      "name": "customLists",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomListEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CustomList",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OutreachSequenceConnection",
      "kind": "LinkedField",
      "name": "sequences",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creatorCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OutreachSequenceBlueprint",
      "kind": "LinkedField",
      "name": "sequenceBlueprint",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "OutreachStepBlueprintConnection",
          "kind": "LinkedField",
          "name": "steps",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "OutreachStepBlueprintEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OutreachStepBlueprint",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OutreachBatch",
  "abstractKey": null
};
})();

(node as any).hash = "d1ecb5d99758decb0c4e3875666aab0c";

export default node;
