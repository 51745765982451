/**
 * @generated SignedSource<<7aaebcc5792e0bb788d679b238ec77ed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CampaignPostingType = "CREATOR_ADS" | "ORGANIC_POSTING";
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING";
import { FragmentRefs } from "relay-runtime";
export type CampaignActions_campaign$data = {
  readonly activation: {
    readonly paused: boolean;
  } | null | undefined;
  readonly completion: {
    readonly id: string;
  } | null | undefined;
  readonly id: string;
  readonly listingId: string;
  readonly organization: {
    readonly id: string;
    readonly subscription: {
      readonly paused: boolean;
    } | null | undefined;
  } | null | undefined;
  readonly postingType: CampaignPostingType | null | undefined;
  readonly type: CampaignType | null | undefined;
  readonly userSubscribed: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"AdditionalActions_campaign">;
  readonly " $fragmentType": "CampaignActions_campaign";
};
export type CampaignActions_campaign$key = {
  readonly " $data"?: CampaignActions_campaign$data;
  readonly " $fragmentSpreads": FragmentRefs<"CampaignActions_campaign">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "paused",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CampaignActions_campaign",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "listingId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postingType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userSubscribed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "AdvertiserSubscription",
          "kind": "LinkedField",
          "name": "subscription",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CampaignCompletion",
      "kind": "LinkedField",
      "name": "completion",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CampaignActivation",
      "kind": "LinkedField",
      "name": "activation",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AdditionalActions_campaign"
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};
})();

(node as any).hash = "75228ab905b43ebd2f003ef7bd387569";

export default node;
