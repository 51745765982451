import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';

import { SignUpCompleteContext, SignUpType, StepEnum } from '../Context';
import { TermsContent } from '../TermsContent';

import styles from './Steps.pcss';
import { UserDetailsStep } from './UserDetailsStep';
import { CompanyTypeStep } from './CompanyTypeStep';
import { CompanyIndustryStep } from './CompanyIndustryStep';
import { AnnualRevenueStep } from './AnnualRevenueStep';
import { HowDidYouKnowStep } from './HowDidYouKnowStep';

import Text from 'Components/ui/Text/Text';
import Button from 'Components/ui/Button/Button';

type Props = {
  className?: string;
};

const Steps: React.FC<Props> = (props) => {
  const { className } = props;
  const [validateUserDetailsFormKey, setValidateUserDetailsFormKey] = useState<number>(0);
  const [isErrorVisible, setIsErrorVisible] = useState<boolean>(false);
  const [appearanceDirection, setAppearanceDirection] = useState<AppearanceDirection | null>(null);

  const {
    isFinalStep,
    activeStep,
    activeStepId,
    activeStepIndex,
    signUpType,
    goToNextStep,
    goToPrevStep,
    handleFinishSignUp,
    isCurrentStepValid,
    isSubmitLoading,
  } = useContext(SignUpCompleteContext);

  useEffect(() => {
    setIsErrorVisible(false);
  }, [isCurrentStepValid]);

  const steps = useMemo<StepComponents>(() => {
    return {
      [StepEnum.UserDetails]: <UserDetailsStep validateKey={validateUserDetailsFormKey} />,
      [StepEnum.CompanyType]: <CompanyTypeStep />,
      [StepEnum.CompanyIndustry]: <CompanyIndustryStep />,
      [StepEnum.AnnualRevenue]: <AnnualRevenueStep />,
      [StepEnum.HowDidYouKnow]: <HowDidYouKnowStep />,
    };
  }, [validateUserDetailsFormKey]);

  const isBackButtonVisible = useMemo<boolean>(() => {
    return activeStepIndex > 0;
  }, [activeStepIndex]);

  const handleNextButtonClick = useCallback(() => {
    if (isSubmitLoading) {
      return;
    }
    if (!isCurrentStepValid) {
      setIsErrorVisible(true);
      if (activeStepId === StepEnum.UserDetails) {
        setValidateUserDetailsFormKey(Date.now());
      }
      return;
    }
    if (isFinalStep) {
      handleFinishSignUp();
      return;
    }
    goToNextStep();
    setAppearanceDirection('right-to-left');
  }, [
    goToNextStep,
    handleFinishSignUp,
    isFinalStep,
    isCurrentStepValid,
    isSubmitLoading,
    activeStepId,
  ]);

  const handlePrevButtonClick = useCallback(() => {
    if (isSubmitLoading) {
      return;
    }
    goToPrevStep();
    setAppearanceDirection('left-to-right');
  }, [goToPrevStep, isSubmitLoading]);

  const isUserDetailsStep = activeStepId === 'UserDetails';

  return (
    <div className={cn(styles.root, className)}>
      <div
        className={cn(styles.stepWrapper, {
          [styles.appearanceLeftToRight]: appearanceDirection === 'left-to-right',
          [styles.appearanceRightToLeft]: appearanceDirection === 'right-to-left',
        })}
        key={activeStepId}
      >
        {steps[activeStepId]}
      </div>
      <div
        className={cn(styles.footer, {
          [styles.themePink]: activeStep.theme === 'pink',
          [styles.themeSun]: activeStep.theme === 'sun',
          [styles.themePurple]: activeStep.theme === 'purple',
          [styles.themeSky]: activeStep.theme === 'sky',
        })}
      >
        <div className={cn(styles.footerInner)}>
          <Text
            type="label"
            msg="signup_complete.advertiser.steps.error.fill_fields"
            className={cn(styles.errorMessage, { [styles.visible]: isErrorVisible })}
            data-test="steps:text:fillFields"
          />
          {isUserDetailsStep && <TermsContent />}
          <div
            className={cn(styles.buttonsGroup, {
              [styles.oneColumn]: !isBackButtonVisible,
            })}
          >
            <Button
              color="white"
              icon="Arrow-small-left"
              onClick={handlePrevButtonClick}
              className={cn(styles.prevStepButton, {
                [styles.hidden]: !isBackButtonVisible,
              })}
              data-test="steps:button:unknown"
            />
            <div className={styles.nextStepButtonWrapper}>
              <div className={styles.clicker} onClick={handleNextButtonClick} />
              <Button
                fluid
                color="black"
                dataTest="button:nextStep"
                className={styles.nextStepButton}
                disabled={!isCurrentStepValid}
                loading={isSubmitLoading}
                msg={
                  isFinalStep && signUpType === SignUpType.Survey
                    ? 'signup_complete.advertiser.steps.button.finish'
                    : 'signup_complete.advertiser.steps.button.next'
                }
                data-test="steps:button:nextStepButton"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Steps;
export { Steps };

// types

type StepComponents = Record<StepEnum, JSX.Element>;
type AppearanceDirection = 'right-to-left' | 'left-to-right';

export { StepEnum };
