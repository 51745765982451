import React from 'react';
import { graphql, PreloadedQuery, usePaginationFragment, usePreloadedQuery } from 'react-relay';

import { AdminCreativeLibraryQuery } from '../AdminCreativeLibrary';

import styles from './Creatives.pcss';

import Text from 'Components/ui/Text/Text';
import Creative from 'Molecules/Creative/Creative';
import LoaderHandlerWithHooks from 'Organisms/LoaderHandler/NewLoaderHandler';
import { AdminCreativeLibraryQuery as QueryType } from 'GraphTypes/AdminCreativeLibraryQuery.graphql';

const OFFSET = 279;
const COUNT = 12;

import { Creatives_adminCreatives$key } from 'GraphTypes/Creatives_adminCreatives.graphql';
import { CreativesFilter } from 'GraphTypes/AdminCreativeLibraryQuery.graphql';

interface Props {
  admin: boolean;
  filter: CreativesFilter;
  queryReference: PreloadedQuery<QueryType>;
}

const Creatives: React.FC<Props> = (props) => {
  const { queryReference } = props;
  const queryData = usePreloadedQuery<QueryType>(AdminCreativeLibraryQuery, queryReference);

  const fragmentData = usePaginationFragment<QueryType, Creatives_adminCreatives$key>(
    graphql`
      fragment Creatives_adminCreatives on Query
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 12 }
        cursor: { type: "String" }
        filter: { type: "CreativesFilter!" }
      )
      @refetchable(queryName: "CreativesListPaginationList") {
        adminCreatives(first: $count, after: $cursor, filter: $filter)
          @connection(key: "Creatives_adminCreatives", filters: ["filter"]) {
          edges {
            node {
              id
              project {
                id
              }
              ...Creative_creative
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
          totalCount
        }
      }
    `,
    queryData
  );

  const { admin } = props;

  const items = Array.from(fragmentData.data.adminCreatives?.edges || []);

  if (items.length === 0) {
    return (
      <Text
        type="md"
        msg="general.no_result"
        className={styles.emptyList}
        data-test="creatives:text:noResult"
      />
    );
  }

  return (
    <div>
      <LoaderHandlerWithHooks relay={fragmentData} offset={OFFSET} count={COUNT} items={items}>
        <div className={styles.root}>
          {items.map((item) => {
            const id = item?.node?.id;
            if (!id) return;
            return (
              <Creative key={id} admin={admin} canChatWithCreators={true} creative={item.node} />
            );
          })}
        </div>
      </LoaderHandlerWithHooks>
    </div>
  );
};

export default Creatives;
