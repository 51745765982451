import { useNavigate } from 'react-router-dom';

import ErrorHandler from 'Util/errorHandler';
import { useUpload, FileType } from 'Hooks/useUpload';
import { getCampaignTypeLink, getCampaignBriefLink, getCampaignCreatorsLink } from 'Util/links';
import createCampaignRequest from 'Mutations/CreateCampaign.Mutation';
import cloneCampaign from 'Mutations/CloneCampaign.Mutation';
import createProject from 'Mutations/CreateProject.Mutation';
import createReference from 'Mutations/CreateReference.Mutation';
import updateCampaign from 'Mutations/UpdateCampaign.Mutation';
import sendOffer from 'Mutations/SendOffer.Mutation';
import submitBriefRequest from 'Mutations/SubmitBrief.Mutation';
import {
  CreateCampaignMutation$data,
  CreateCampaignInput,
} from 'GraphTypes/CreateCampaignMutation.graphql';
import { CloneCampaignMutation$data } from 'GraphTypes/CloneCampaignMutation.graphql';
import { UpdateCampaignMutation$data } from 'GraphTypes/UpdateCampaignMutation.graphql';
import { SendOfferMutation$data } from 'GraphTypes/SendOfferMutation.graphql';
import { SubmitBriefMutation$data } from 'GraphTypes/SubmitBriefMutation.graphql';
import { CreateReferenceMutation$data } from 'GraphTypes/CreateReferenceMutation.graphql';
import { ImageData } from 'Api/uploadFile';
import { MEDIAPLAN, BRIEF } from 'Constants/general';

interface NewCampaignParams {
  linkPath?: 'creators' | 'brief' | 'mediaplan';
  showMediaplan?: boolean;
  onSuccess?: (data?: { campaignId: string }) => void;
  campaignData?: CreateCampaignInput;
}

interface NewCampaignWithCreatorParams {
  campaignId: string;
  creatorId: string;
  onFail?: () => void;
  onSuccess?: (data?: { campaignId: string }) => void;
}

interface NewDealWithCreatorInExistingCampaign {
  campaignId: string;
  creatorId: string;
  onFail?: () => void;
  onSuccess?: () => void;
}

interface SubmitBriefParams {
  campaignId: string;
  briefId: string;
}

interface AddNewCampaignCoverParams {
  campaignId: string;
  imageData: ImageData;
  onSuccess?: () => void;
}

interface AddNewReferenceParams {
  file: File;
  briefCreativeId: string;
  onSuccess?: () => void;
}

interface CampaignActionsResult {
  addNewReference: (params?: AddNewReferenceParams) => void;
  addNewCampaignCover: (params?: AddNewCampaignCoverParams) => void;
  submitBrief: (params?: SubmitBriefParams) => void;
  createNewCampaign: (params?: NewCampaignParams) => void;
  createNewCampaignWithCreator: (params: NewCampaignWithCreatorParams) => void;
  createNewDealWithCreatorInExistingCampaign: (
    params: NewDealWithCreatorInExistingCampaign
  ) => void;
}

type UseCampaignActions = () => CampaignActionsResult;
const useCampaignActions: UseCampaignActions = () => {
  const navigate = useNavigate();

  const { uploadFile } = useUpload();

  const createNewCampaign = async (params?: NewCampaignParams) => {
    const onSuccess = params?.onSuccess;
    const showMediaplan = params?.showMediaplan;
    const campaignData = params?.campaignData;
    const linkPath = params?.linkPath;
    try {
      const newCampaign = await new Promise<CreateCampaignMutation$data>((resolve, reject) => {
        createCampaignRequest({ ...campaignData }, resolve, reject);
      });
      const newCampaignId = newCampaign.createCampaign?.campaign.id;
      if (newCampaignId) {
        const linkPathValue = linkPath || (showMediaplan ? MEDIAPLAN : BRIEF);
        const link = getCampaignTypeLink({ campaignId: newCampaignId, path: linkPathValue });
        const linkParams = showMediaplan ? { hideBack: true } : undefined;
        navigate(link, { state: linkParams });
        onSuccess?.({ campaignId: newCampaignId });
      }
    } catch (error) {
      ErrorHandler.error('Crash while add new campaign', { error });
    }
  };

  const createNewCampaignWithCreator = async (params: NewCampaignWithCreatorParams) => {
    const { campaignId, creatorId, onSuccess, onFail } = params;
    try {
      const newCampaign = await new Promise<CloneCampaignMutation$data>((resolve, reject) => {
        cloneCampaign({ campaignId: campaignId }, resolve, reject);
      });
      const newCampaignId = newCampaign.cloneCampaign?.campaign.id || '';
      await new Promise<UpdateCampaignMutation$data>((resolve, reject) => {
        updateCampaign({ id: newCampaignId, private: true }, resolve, reject);
      });
      if (newCampaignId) {
        new Promise((resolve, reject) => {
          createProject({ campaignId: newCampaignId, creatorId }, resolve, reject);
        });
        const briefLink = getCampaignBriefLink({ campaignId: newCampaignId });
        navigate(briefLink);
        onSuccess?.({ campaignId: newCampaignId });
      }
    } catch (error) {
      onFail?.();
      ErrorHandler.error('Crash while start new deal with creator', { error });
    }
  };

  const createNewDealWithCreatorInExistingCampaign = async (
    params: NewDealWithCreatorInExistingCampaign
  ) => {
    const { creatorId, campaignId, onSuccess, onFail } = params;
    try {
      await new Promise<SendOfferMutation$data>((resolve, reject) => {
        sendOffer({ campaignId, creatorId }, resolve, reject);
      });
      const link = `/campaigns/${campaignId}`;
      navigate(link);
      onSuccess?.();
    } catch (error) {
      onFail?.();
      ErrorHandler.error('Crash while startnew deal in existing campaign', { error });
    }
  };

  const submitBrief = async (params: SubmitBriefParams) => {
    const { campaignId, briefId } = params;
    try {
      await new Promise<SubmitBriefMutation$data>((resolve, reject) => {
        submitBriefRequest({ campaignId, briefId }, resolve, reject);
      });
      const link = getCampaignCreatorsLink({ campaignId });
      navigate(link);
    } catch (error) {
      ErrorHandler.error('Crash while submitting brief', { error });
    }
  };

  const addNewCampaignCover = (params: AddNewCampaignCoverParams) => {
    const { imageData, campaignId, onSuccess } = params;
    try {
      const handleFileAdded = async (data: { file: FileType }) => {
        const fileId = data.file?.id;
        await new Promise<UpdateCampaignMutation$data>((resolve, reject) => {
          updateCampaign({ id: campaignId, coverId: fileId }, resolve, reject);
        });
        onSuccess?.();
      };

      uploadFile({
        params: { imageData, category: 'campaign_cover' },
        onSuccess: handleFileAdded,
      });
    } catch (error) {
      ErrorHandler.error('Crash while adding new campaign cover', { error });
    }
  };

  const addNewReference = (params: AddNewReferenceParams) => {
    const { file, briefCreativeId, onSuccess } = params;

    try {
      const handleFileAdded = async (data: { file: FileType }) => {
        const fileId = data.file?.id;
        if (!fileId) return;
        await new Promise<CreateReferenceMutation$data>((resolve, reject) => {
          createReference({ briefCreativeId, fileId }, resolve, reject);
        });
        onSuccess?.();
      };

      uploadFile({
        params: { file, category: 'reference' },
        onSuccess: handleFileAdded,
      });
    } catch (error) {
      ErrorHandler.error('Crash while adding new creative reference', { error });
    }
  };

  return {
    addNewReference,
    addNewCampaignCover,
    submitBrief,
    createNewCampaign,
    createNewCampaignWithCreator,
    createNewDealWithCreatorInExistingCampaign,
  };
};

export { useCampaignActions };
