import React, { Fragment, useContext } from 'react';

import { InviteOutreachCreatorsContext, SequenceStep } from '../InviteOutreachCreatorsContext';
import { sequenceDefaultEmptyStep } from '../InviteOutreachCreatorsProvider';
import DeleteSequenceItemDrawer from '../DeleteSequenceItemDrawer/DeleteSequenceItemDrawer';

import styles from './SequenceSettings.pcss';
import SequenceItem from './SequenceItem/SequenceItem';
import SequenceNewItem from './SequenceItem/SequenceNewItem';

interface Props {
  onChangeData: (data: Partial<SequenceStep>) => void;
}

const SequenceSettings: React.FC<Props> = (props) => {
  const { onChangeData } = props;
  const { setActiveStep, activeStep, stepsWithError, sequenceList, setSequenceList } = useContext(
    InviteOutreachCreatorsContext
  );

  const addToSequenceNewElement = () => {
    setActiveStep(sequenceList.length);
    setSequenceList((list) => {
      return [...list, { ...sequenceDefaultEmptyStep, delayInDays: 1 }];
    });
  };

  const handleAddEmailClick = () => {
    addToSequenceNewElement();
  };

  const handleStepSelect = (index: number) => {
    setActiveStep(index);
  };

  const handleStepRemove = (index: number) => {
    setSequenceList((list) => {
      return list.filter((_, idx) => idx !== activeStep);
    });
    if (index === 0) {
      setActiveStep(0);
    } else {
      setActiveStep(index - 1);
    }
  };

  const handleRemoveActiveStep = () => {
    handleStepRemove(activeStep);
  };

  return (
    <div className={styles.root}>
      <div className={styles.list}>
        {sequenceList.map((item, idx) => (
          <Fragment key={item.id || `${item.template?.id}_${idx}`}>
            <SequenceItem
              data={item}
              index={idx}
              error={stepsWithError.includes(idx)}
              isActive={idx === activeStep}
              isLastInList={sequenceList.length === 1}
              onSelect={handleStepSelect}
              onRemove={handleStepRemove}
              onChangeData={onChangeData}
            />
          </Fragment>
        ))}
      </div>
      <div className={styles.actions}>
        <SequenceNewItem onClick={handleAddEmailClick} />
      </div>
      <DeleteSequenceItemDrawer onConfirm={handleRemoveActiveStep} />
    </div>
  );
};

export default SequenceSettings;
