/**
 * @generated SignedSource<<eeb417d4679fae19955dbe31e537f90b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type FileProcessingStatus = "FAILED" | "PROCESSED" | "PROCESSING";
export type FileProcessingStatusUpdatedSubscription$variables = Record<PropertyKey, never>;
export type FileProcessingStatusUpdatedSubscription$data = {
  readonly fileProcessingStatusUpdated: {
    readonly id: string;
    readonly processingStatus?: FileProcessingStatus | null | undefined;
  };
};
export type FileProcessingStatusUpdatedSubscription = {
  response: FileProcessingStatusUpdatedSubscription$data;
  variables: FileProcessingStatusUpdatedSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "processingStatus",
      "storageKey": null
    }
  ],
  "type": "Video",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FileProcessingStatusUpdatedSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "fileProcessingStatusUpdated",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FileProcessingStatusUpdatedSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "fileProcessingStatusUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "252c91585a0e4c51ebfcb1565e2ca369",
    "id": null,
    "metadata": {},
    "name": "FileProcessingStatusUpdatedSubscription",
    "operationKind": "subscription",
    "text": "subscription FileProcessingStatusUpdatedSubscription {\n  fileProcessingStatusUpdated {\n    __typename\n    id\n    ... on Video {\n      processingStatus\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d364c863be87b34bd2d8d96d5600365b";

export default node;
