import React, { FC, useState } from 'react';

import { newCases } from '../data';
import VideoItem from '../VideoItem/VideoItem';

import styles from './NewCases.pcss';

import { useCampaignActions } from 'Hooks/useCampaignActions';
import { amplitude } from 'Helpers/amplitude';
import { CreateCampaignInput } from 'GraphTypes/CreateCampaignMutation.graphql';
import Text from 'Components/ui/Text/Text';
import Tabs from 'Molecules/Tabs/Tabs';
import { CampaignObjective } from 'GraphTypes/CreateCampaignMutation.graphql';
import { HomeQuery$data } from 'GraphTypes/HomeQuery.graphql';

interface Props {
  subscription:
    | NonNullable<NonNullable<HomeQuery$data['currentUser']>['organization']>['subscription']
    | null
    | undefined;
}

const NewCases: FC<Props> = ({ subscription }) => {
  const [objective, setObjective] = useState<CampaignObjective>('JUST_CONTENT');
  const [loading, setLoading] = useState(false);
  const availableCases = subscription?.availableCampaignUseCases || [];

  const { createNewCampaign } = useCampaignActions();

  const handleCreateBriefClick = (campaignData: CreateCampaignInput) => {
    setLoading(true);

    createNewCampaign({
      campaignData: {
        ...campaignData,
        useCase:
          campaignData.useCase && availableCases.includes(campaignData.useCase)
            ? campaignData.useCase
            : undefined,
      },
    });
  };

  const objectives: Array<{ id: CampaignObjective; textData: any }> = [
    {
      id: 'JUST_CONTENT',
      textData: {
        msg: 'home.use_case.multi_channel',
      },
    },
    {
      id: 'REACH',
      textData: {
        msg: 'home.use_case.awareness',
      },
    },
    {
      id: 'CONVERSIONS',
      textData: {
        msg: 'home.use_case.conversions',
      },
    },
  ];

  const handleObjectiveChange = (id: CampaignObjective) => {
    amplitude.sendEvent<396>({
      id: '396',
      category: 'onboarding',
      name: 'dashboard_onboarding_goal_click',
      param: { goal_type: id },
    });
    setObjective(id);
  };

  const videoList = newCases[objective] || [];

  return (
    <div className={styles.root}>
      <div className={styles.row}>
        <Text
          type="d2"
          msg="home.goals.title"
          className={styles.title}
          data-test="newCases:text:title"
        />
        <Tabs
          items={objectives}
          currentItemId={objective}
          filled
          onChange={handleObjectiveChange}
          className={styles.tabs}
        />
      </div>
      <div className={styles.row}>
        <Text
          type="d2"
          msg="home.strategy.title"
          className={styles.title}
          data-test="newCases:text:title"
        />
        <div className={styles.videos}>
          {videoList.map((item) => {
            return (
              <VideoItem
                key={item.id}
                video={item}
                loading={loading}
                onCreateBriefClick={handleCreateBriefClick}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default NewCases;
