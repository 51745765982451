import React, { useState, useEffect, Suspense, useContext } from 'react';
import track from 'react-tracking';
import { graphql, useLazyLoadQuery } from 'react-relay';
import debounce from 'lodash/debounce';
import classnames from 'classnames';

import {
  getStagesList,
  getHasBrandMessageBeforeLaunch,
  getArchivedBeforeLaunch,
  getArchivedAfterLaunch,
} from '../utils';
import SectionEmptyState from '../SectionEmptyState/SectionEmptyState';
import { DECLINED, MESSAGED, RECIEVED_APPLICANTS } from '../NewCampaignTabs/util';
import { CampaignContext } from '../Campaign.Context';

import { defaultParams } from './util';
import styles from './Creators.pcss';
import CreatorsList from './CreatorsList/CreatorsList';
import Search from './Search/Search';
import OrderFilter from './OrderFilter/OrderFilter';
import ProjectsFilters from './ProjectsFilters/ProjectsFilters';
import NewProjectsFilters from './NewProjectsFilters/NewProjectsFilters';

import MaxHiredCreatorsExceededDrawer from 'Modal/advertiser/MaxHiredCreatorsExceeded/MaxHiredCreatorsExceeded';
import { amplitude } from 'Helpers/amplitude';
import {
  CREATORS,
  AWAITING_LIST,
  ARCHIVED,
  LONG_TERM,
  APPLICANTS,
  COMPLETED,
  DEBOUNCE_WAIT_TIMEOUT,
} from 'Constants/general';
import Spinner from 'Atoms/Spinner/Spinner';
import {
  CampaignQuery$data,
  CampaignPlatform,
  CampaignType,
} from 'GraphTypes/CampaignQuery.graphql';
import { ProjectOrder, SearchQueryInput } from 'GraphTypes/CreatorsListQuery.graphql';
import { FeatureFlagsContext } from 'Containers/FeatureFlags/FeatureFlags.Context';
import { CreatorsQuery as QueryType } from 'GraphTypes/CreatorsQuery.graphql';

export type FiltersData = {
  searchQuery?: SearchQueryInput;
  shortlisted?: boolean | null;
  invitedByOrganizationMember?: boolean | null;
  outreach?: boolean | null;
  screeningQuestionIds?: string[];
};

interface Props {
  campaign: CampaignQuery$data['campaign'];
  currentUser: CampaignQuery$data['currentUser'];
  campaignPlatform?: CampaignPlatform | null;
  isAdminView: boolean;
  section: string;
  campaignId: string;
  campaignType?: CampaignType | null;
  publishingRequired: boolean;
  organizationId?: string;
  handleBulkMessage: (message: { value: string; targetTab: string }) => void;
}

export const CreatorsQuery = graphql`
  query CreatorsQuery(
    $id: ID!
    $stages: [AdvertiserProjectStage!]
    $archived: Boolean
    $hasBrandMessageBeforeLaunch: Boolean
    $archivedAfterLaunch: Boolean
    $archivedBeforeLaunch: Boolean
  ) {
    campaign(id: $id) {
      projects(
        stages: $stages
        archived: $archived
        archivedAfterLaunch: $archivedAfterLaunch
        archivedBeforeLaunch: $archivedBeforeLaunch
        hasBrandMessageBeforeLaunch: $hasBrandMessageBeforeLaunch
      ) {
        hasItems
      }
    }
  }
`;

const Creators: React.FC<Props> = (props) => {
  const {
    isAdminView,
    section,
    currentUser,
    campaignPlatform,
    campaignId,
    campaignType,
    publishingRequired,
    campaign,
    organizationId,
    handleBulkMessage,
  } = props;

  const canBrowseCreators = !!currentUser?.organization?.subscription?.canBrowseCreators;
  const planId = currentUser?.organization?.subscription?.planId;
  const maxHiredCreators =
    currentUser?.organization?.subscription?.effectiveLimits.maxHiredCreators;
  const canCreateMultipleDeals = !!currentUser?.organization?.subscription?.canCreateMultipleDeals;
  const canFilterCreatorsByBadge =
    !!currentUser?.organization?.subscription?.canFilterCreatorsByBadge;

  const currency = currentUser?.organization?.paymentAccount?.currency;

  const preferredContentType = campaign?.preferredContentType;
  const preferredCreatorCategory = campaign?.preferredCreatorCategory;

  const isLongTermCampaign = campaignType === LONG_TERM;

  const { hasFeatureFlag } = useContext(FeatureFlagsContext);
  const { onCreatorsListDataUpdate } = useContext(CampaignContext);

  const newCampaignTabs = hasFeatureFlag('new_campaign_tabs');

  const hasBrandMessageBeforeLaunch = getHasBrandMessageBeforeLaunch(section, !!newCampaignTabs);
  const archivedAfterLaunch = getArchivedAfterLaunch(section, !!newCampaignTabs);
  const archivedBeforeLaunch = getArchivedBeforeLaunch(section, !!newCampaignTabs);

  const defaultStages = getStagesList(
    section,
    isAdminView,
    canBrowseCreators,
    publishingRequired,
    newCampaignTabs
  );
  const creatorsStages = getStagesList(
    CREATORS,
    isAdminView,
    canBrowseCreators,
    publishingRequired,
    newCampaignTabs
  );

  const [stages, setStages] = useState(defaultStages);

  const archived = section === ARCHIVED || section === DECLINED ? true : false;

  const data = useLazyLoadQuery<QueryType>(
    CreatorsQuery,
    {
      id: campaignId,
      stages: defaultStages,
      archived,
      hasBrandMessageBeforeLaunch,
      archivedBeforeLaunch,
      archivedAfterLaunch,
    },
    { fetchPolicy: 'network-only' }
  );

  useEffect(() => {
    onCreatorsListDataUpdate({
      id: campaignId,
      stages: defaultStages,
      archived,
      hasBrandMessageBeforeLaunch,
      archivedBeforeLaunch,
      archivedAfterLaunch,
    });
  }, []);

  const defaultOrder: ProjectOrder = section === CREATORS ? 'ACTIVITY' : 'RATING';

  const [textQuery, setTextQuery] = useState('');
  const [shortlisted, setShortlisted] = useState<boolean | null>(null);
  const [withPreviousCollaborations, setWithPreviousCollaborations] = useState(null);
  const [orderBy, setOrderBy] = useState<ProjectOrder | undefined | null>(defaultOrder);
  const [badges, setBadges] = useState([]);
  const [screeningQuestionIds, setScreeningQuestionIds] = useState(undefined);
  const [invitedByOrganizationMember, setInvitedByOrganizationMember] = useState(null);
  const [outreach, setOutreach] = useState(null);
  const [draftDueIntervals, setDraftDueIntervals] = useState([]);
  const [publicationDueIntervals, setPublicationDueIntervals] = useState([]);
  const [filters, setFilters] = useState<FiltersData>({ searchQuery: defaultParams });

  useEffect(() => {
    if (section === CREATORS) {
      amplitude.sendEvent<55>({
        id: '55',
        category: 'pageview',
        name: 'campaign_hired_list',
        param: undefined,
      });
    } else if (section === AWAITING_LIST) {
      amplitude.sendEvent<107>({
        id: '107',
        category: 'pageview',
        name: 'campaign_waiting_list',
        param: undefined,
      });
    } else if (section === APPLICANTS) {
      amplitude.sendEvent<446>({
        id: '446',
        category: 'pageview',
        name: 'applicants_list',
        param: undefined,
      });
    } else if (section === COMPLETED) {
      amplitude.sendEvent<461>({
        id: '461',
        category: 'pageview',
        name: 'campaign_completed_projects',
        param: undefined,
      });
    }
  }, []);

  useEffect(() => {
    setDefaultStages();
  }, [isAdminView]);

  const setDefaultStages = () => {
    const defaultStages = getStagesList(
      section,
      isAdminView,
      canBrowseCreators,
      publishingRequired,
      newCampaignTabs
    );
    setStages(defaultStages);
  };

  if (!data.campaign?.projects.hasItems && newCampaignTabs) {
    return <SectionEmptyState campaignId={campaignId} section={section} />;
  }

  const handleQueryChange = debounce((textQuery: string) => {
    setFilters({ ...filters, searchQuery: { ...filters?.searchQuery, textQuery } });
  }, DEBOUNCE_WAIT_TIMEOUT);

  const handleFilterChange = (data) => {
    const keys = Object.keys(data);
    if (keys.includes('shortlisted')) {
      setShortlisted(data.shortlisted);
    }
    if (keys.includes('outreach')) {
      setOutreach(data.outreach);
    }
    if (keys.includes('invitedByOrganizationMember')) {
      setInvitedByOrganizationMember(data.invitedByOrganizationMember);
    }
    if (keys.includes('screeningQuestionIds')) {
      setScreeningQuestionIds(data.screeningQuestionIds);
    }
    if (keys.includes('withPreviousCollaborations')) {
      setWithPreviousCollaborations(data.withPreviousCollaborations);
    } else if (keys.includes('stages')) {
      if (data.stages.length === 0) {
        setDefaultStages();
      } else {
        setStages(data.stages);
      }
    }
    if (keys.includes('badges')) {
      setBadges(data.badges);
    }
    if (keys.includes('draftDueIntervals')) {
      setDraftDueIntervals(data.draftDueIntervals);
      setPublicationDueIntervals(data.publicationDueIntervals);
    }
  };

  const handleSortTypeChange = (sortType: ProjectOrder) => {
    setOrderBy(sortType);
  };

  const projectStage = stages.length > 2 ? null : stages[0];

  const handleFiltersChange = (data: FiltersData) => {
    setFilters({ ...filters, ...data });
  };

  const orderByEl = (
    <div className={styles.filters}>
      <OrderFilter
        defaultOrder={defaultOrder}
        isAdminView={isAdminView}
        campaignId={campaignId}
        platform={campaignPlatform}
        currentOrder={orderBy}
        onOrderChange={handleSortTypeChange}
      />
    </div>
  );

  const canShowList = section === APPLICANTS ? !!filters : true;

  return (
    <Suspense fallback={<Spinner className={styles.preloader} />}>
      <div className={classnames(styles.root, { [styles.withNewTabs]: !!newCampaignTabs })}>
        <div className={styles.header}>
          <div className={styles.filtersContainer}>
            {section === APPLICANTS || section === RECIEVED_APPLICANTS || section === MESSAGED ? (
              <NewProjectsFilters
                platform={campaignPlatform}
                campaignId={campaignId}
                shortlisted={shortlisted}
                orderByEl={orderByEl}
                {...filters}
                isAdmin={isAdminView}
                organizationId={organizationId}
                onFiltersChange={handleFiltersChange}
              />
            ) : (
              <div className={styles.filtersWrap}>
                <Suspense fallback={null}>
                  <ProjectsFilters
                    shortlisted={shortlisted}
                    badges={badges}
                    invitedByOrganizationMember={invitedByOrganizationMember}
                    outreach={outreach}
                    currency={currency}
                    campaignId={campaignId}
                    archived={archived}
                    withPreviousCollaborations={withPreviousCollaborations}
                    defaultStages={defaultStages}
                    stages={stages}
                    projectStage={projectStage}
                    screeningQuestionIds={screeningQuestionIds}
                    isLongTermCampaign={isLongTermCampaign}
                    publishingRequired={publishingRequired}
                    canFilterCreatorsByBadge={canFilterCreatorsByBadge}
                    onFilterChange={handleFilterChange}
                    section={section}
                    isAdminView={isAdminView}
                  />
                </Suspense>
                <div className={styles.filters}>
                  <Search value={filters?.searchQuery?.textQuery} onChange={handleQueryChange} />
                  {orderByEl}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.contentWrap}>
            {canShowList && (
              <CreatorsList
                isBulkAction={
                  section === APPLICANTS || section === RECIEVED_APPLICANTS || section === MESSAGED
                }
                campaignId={campaignId}
                isAdminView={isAdminView}
                creatorsStages={creatorsStages}
                defaultStages={defaultStages}
                withPreviousCollaborations={withPreviousCollaborations}
                orderBy={orderBy as ProjectOrder}
                stages={stages}
                badges={badges}
                textQuery={textQuery}
                archived={archived}
                section={section}
                hasBrandMessageBeforeLaunch={hasBrandMessageBeforeLaunch}
                archivedAfterLaunch={archivedAfterLaunch}
                archivedBeforeLaunch={archivedBeforeLaunch}
                isLongTermCampaign={isLongTermCampaign}
                portfolioTypeName={preferredContentType}
                portfolioCategoryName={preferredCreatorCategory}
                invitedByOrganizationMember={invitedByOrganizationMember}
                outreach={outreach}
                organizationId={organizationId}
                publicationDeadlineIntervals={publicationDueIntervals}
                draftDeadlineIntervals={draftDueIntervals}
                screeningQuestionIds={screeningQuestionIds}
                canCreateMultipleDeals={canCreateMultipleDeals}
                canBrowseCreators={canBrowseCreators}
                shortlisted={shortlisted}
                handleBulkMessage={handleBulkMessage}
                {...filters}
              />
            )}
          </div>
        </div>
      </div>
      <MaxHiredCreatorsExceededDrawer
        attach={{
          planId,
          maxHiredCreators,
        }}
      />
    </Suspense>
  );
};

export default track(
  {
    page: 'creators',
  },
  { dispatchOnMount: true }
)(Creators);
