import React, { useState, useEffect, Suspense, useMemo } from 'react';
import { graphql, useQueryLoader } from 'react-relay';
import xor from 'lodash/xor';

import styles from './NotificationCenter.pcss';
import NotificationsList from './NotificationsList/NotificationsList';
import { getTypes } from './util';

import { amplitude } from 'Helpers/amplitude';
import { useMarkAllInAppNotificationsAsReadMutation } from 'Mutations/MarkAllInAppNotificationsAsRead.Mutation';
import { useMarkAllInAppNotificationsAsViewedMutation } from 'Mutations/MarkAllInAppNotificationsAsViewed.Mutation';
import Page from 'Templates/Page/Page';
import Text from 'Components/ui/Text/Text';
import Toggle from 'Components/ui/Toggle/Toggle';
import Spinner from 'Atoms/Spinner/Spinner';
import ProgressiveInput from 'Components/ProgressiveInput/ProgressiveInput';
import TextButton from 'Components/ui/TextButton/TextButton';
import {
  NotificationCenterQuery as QueryType,
  InAppNotifications_Type,
} from 'GraphTypes/NotificationCenterQuery.graphql';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import { DASHBOARD_ROUTE } from 'Constants/general';

export const NotificationCenterQuery = graphql`
  query NotificationCenterQuery($read: Boolean, $types: [InAppNotifications_Type!]) {
    ...NotificationsList_inAppNotifications @arguments(read: $read, types: $types)
  }
`;

const NotificationCenter = () => {
  const [read, setRead] = useState<boolean | undefined>();
  const [types, setTypes] = useState<InAppNotifications_Type[]>([]);

  const [queryReference, loadQuery] = useQueryLoader<QueryType>(NotificationCenterQuery);

  const [markAllInAppNotificationsAsRead] = useMarkAllInAppNotificationsAsReadMutation();

  const [markAllInAppNotificationsAsViewed] = useMarkAllInAppNotificationsAsViewedMutation();

  useEffect(() => {
    loadQuery({ read, types }, { fetchPolicy: 'network-only' });
    markAllInAppNotificationsAsViewed({ variables: { input: { upToTimestamp: new Date() } } });
  }, [read, types]);

  useEffect(() => {
    amplitude.sendEvent<445>({
      id: '445',
      category: 'notification_centre',
      name: 'pageview',
    });
  }, []);

  const handleReadChange = () => {
    amplitude.sendEvent<429>({
      id: '429',
      category: 'notification_centre',
      name: 'filter_unread_click',
      param: { turned_on: !read },
    });
    setRead(read === false ? undefined : false);
  };

  const handleTypesChange = (id: string) => {
    amplitude.sendEvent<428>({
      id: '428',
      category: 'notification_centre',
      name: 'notification_type_select',
      param: { notification_type: id, is_selected: !types.includes(id as InAppNotifications_Type) },
    });
    const newTypesValue = xor(types, [id]) as InAppNotifications_Type[];
    setTypes(newTypesValue);
  };

  const handleTypesClear = () => {
    setTypes([]);
  };

  const handleMarkAsAllRead = () => {
    amplitude.sendEvent<430>({
      id: '430',
      category: 'notification_centre',
      name: 'mark_all_as_read_click',
      param: undefined,
    });
    markAllInAppNotificationsAsRead({
      variables: {
        input: { upToTimestamp: new Date() },
      },
    });
  };

  const typesList = useMemo(() => {
    return getTypes(types);
  }, [types]);

  const handleTypesFilterOpen = () => {
    amplitude.sendEvent<427>({
      id: '427',
      category: 'notification_centre',
      name: 'types_filter_click',
      param: undefined,
    });
  };

  return (
    <Page className={styles.root}>
      <div className={styles.content}>
        <div className={styles.container}>
          <div className={styles.columns}>
            <div className={styles.list}>
              <Text
                type="d2"
                msg="notification_center.title"
                className={styles.title}
                data-test="notificationCenter:text:title"
              />
              <div className={styles.filters}>
                <ProgressiveInput
                  type="checkboxSelect"
                  inputProps={{
                    items: typesList,
                    placeholderMsg: 'notification_center.filter.types',
                    handleClick: handleTypesChange,
                    hideByAlphabet: true,
                    handleClear: handleTypesClear,
                    className: styles.selectCheckbox,
                    handleOpenStateChange: handleTypesFilterOpen,
                  }}
                  isDirty={types.length > 0}
                />
                <Toggle
                  on={read === false}
                  msg="notification_center.filter.unread"
                  onChange={handleReadChange}
                />
                <TextButton
                  color="purple"
                  msg="notification_center.mark_all_read"
                  onClick={handleMarkAsAllRead}
                  data-test="notificationCenter:textButton:markAllRead"
                />
              </div>
              <Suspense fallback={<Spinner className={styles.preloader} />}>
                {queryReference && (
                  <NotificationsList types={types} read={read} queryReference={queryReference} />
                )}
              </Suspense>
            </div>
            <div>
              <Text
                type="d2"
                msg="notification_center.sidebar.subscriptions.title"
                className={styles.subscriptionTitle}
                data-test="notificationCenter:text:title"
              />
              <Text
                type="md"
                msg="notification_center.sidebar.subscriptions.descr"
                className={styles.subscriptionDescr}
                data-test="notificationCenter:text:descr"
              />
              <SmartLink path={DASHBOARD_ROUTE} state={{ onlySubscribed: true }}>
                <TextButton
                  icon="Arrow-big-right"
                  msg="notification_center.sidebar.subscriptions.go_to_sudbscription"
                  data-test="notificationCenter:textButton:goToSudbscription"
                />
              </SmartLink>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default NotificationCenter;
