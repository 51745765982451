import React from 'react';

import styles from './RatingStars.css';

import { getRateColor } from 'Util/colorizeValue';
import Icon from 'Atoms/Icon/Icon';

const scores = [1, 2, 3, 4, 5];

interface Props {
  score?: number;
  defaultColor?: string;
}

const RatingStars: React.FC<Props> = (props) => {
  const { score, defaultColor } = props;

  const color = defaultColor || getRateColor(score);

  return (
    <div className={styles.root}>
      <div className={styles.stars}>
        {scores.map((item, index) => {
          const isActive = score && item <= score;

          return <Icon key={index} size="sm" name="starFull" color={isActive ? color : 'alto'} />;
        })}
      </div>
    </div>
  );
};

export default RatingStars;
