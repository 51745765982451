import React, { useEffect, useState, useMemo } from 'react';
import { fetchQuery, graphql } from 'relay-runtime';
import compact from 'lodash/compact';

import { CreatorType } from '../../data';

import styles from './UsernameFilter.pcss';

import environment from 'Api/Environment';
import Input from 'Components/ui/Input/Input';
import Icon from 'Components/ui/Icon/Icon';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownGroupItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import { UsernameFilterQuery as QueryType } from 'GraphTypes/UsernameFilterQuery.graphql';

const UsernameFilterQuery = graphql`
  query UsernameFilterQuery($textQuery: String) {
    currentUser {
      organization {
        creatorsWithReservation(textQuery: $textQuery) {
          edges {
            node {
              id
              username
            }
          }
        }
      }
    }
  }
`;

interface Props {
  creator?: CreatorType;
  onCreatorSelect: (creator?: CreatorType) => void;
}

const UsernameFilter: React.FC<Props> = (props) => {
  const { creator: selectedCreator, onCreatorSelect } = props;
  const [inputVisible, setInputVisible] = useState<boolean>(false);
  const [textQuery, setTextQuery] = useState<string>('');
  const [creatorsList, setCreators] = useState<{ id: string; username: string }[] | undefined>();

  useEffect(() => {
    if (textQuery) {
      fetchQuery<QueryType>(environment, UsernameFilterQuery, { textQuery }).subscribe({
        next: (result) => {
          const creators = result?.currentUser?.organization?.creatorsWithReservation?.edges;
          if (creators && creators.length > 0) {
            const list = compact(
              creators.map((item) => {
                if (!item?.node) return null;
                return { id: item?.node?.id, username: item?.node?.username };
              })
            );
            setCreators(list);
          }
        },
      });
    } else if (creatorsList && creatorsList.length > 0) {
      setCreators([]);
    }
  }, [textQuery]);

  const handleSearchClick = () => {
    setInputVisible(true);
  };

  const handleTextQueryChange = (text: string) => {
    setTextQuery(text);
  };

  const handleInputReset = () => {
    setInputVisible(false);
    onCreatorSelect();
  };

  const handleInputBlur = () => {
    if (!textQuery) {
      setCreators([]);
      setInputVisible(false);
    }
  };

  const handleInputFocus = () => {
    if (selectedCreator?.username) {
      setTextQuery(selectedCreator.username);
    }
  };

  const inputEl = useMemo(() => {
    return (
      <div className={styles.inputWrap}>
        <Icon name="Search-loop" className={styles.inputIcon} />
        <Input
          value={textQuery}
          autoFocus={true}
          placeholderMsg="billing_history.transactions.username.placeholder"
          handleChange={handleTextQueryChange}
          onBlur={handleInputBlur}
          onFocus={handleInputFocus}
          onResetValue={handleInputReset}
          className={styles.input}
          data-test="usernameFilter:input:input"
        />
      </div>
    );
  }, [textQuery]);

  if (inputVisible) {
    return (
      <Dropdown
        value={inputEl}
        className={styles.selector}
        showArrow={false}
        openedByDefault={true}
      >
        {creatorsList && creatorsList?.length > 0 && (
          <DropdownGroup className={styles.group}>
            <div className={styles.dropdownList}>
              {creatorsList?.map((item) => {
                if (!item || !item.id || !item.username) return null;

                const isActive = selectedCreator?.id === item.id ? 'Check' : null;

                const handleCreatorChange = () => {
                  onCreatorSelect(item);
                  setTextQuery(item.username);
                };

                return (
                  <DropdownGroupItem key={item.id} handleClick={handleCreatorChange}>
                    <AlterButton
                      text={item.username}
                      fluid
                      iconPosition="right"
                      icon={isActive ? 'Check' : null}
                      data-test="usernameFilter:alterButton:unknown"
                    />
                  </DropdownGroupItem>
                );
              })}
            </div>
          </DropdownGroup>
        )}
      </Dropdown>
    );
  }

  return (
    <AlterButton
      icon="Search-loop"
      hover={false}
      onClick={handleSearchClick}
      data-test="usernameFilter:alterButton:unknown"
    />
  );
};

export default UsernameFilter;
