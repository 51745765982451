import React from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';
import moment from 'moment';

import styles from './TikTokShopCollaboration.pcss';

import { TikTokShopCollaborationQuery as QueryType } from 'GraphTypes/TikTokShopCollaborationQuery.graphql';
import Tag from 'Components/ui/Tag/Tag';
import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { copyText } from 'Util/copyText';

const TikTokShopCollaborationQuery = graphql`
  query TikTokShopCollaborationQuery($projectId: ID!) {
    project(id: $projectId) {
      id
      tiktokShopCollaboration {
        status(refresh: true)
        comissionRate
        affiliateProductLink
        endTime
      }
    }
  }
`;

interface Props {
  className?: string;
  projectId: string;
}

const TikTokShopCollaboration: React.FC<Props> = (props) => {
  const { projectId, className } = props;

  const data = useLazyLoadQuery<QueryType>(TikTokShopCollaborationQuery, { projectId });

  if (!data || !data.project?.tiktokShopCollaboration) return null;

  const project = data.project;
  const status = project.tiktokShopCollaboration?.status;
  const endTime = project.tiktokShopCollaboration?.endTime;
  const affiliateProductLink = project.tiktokShopCollaboration?.affiliateProductLink;
  const statusToColor = {
    CREATED: 'ocean',
    LIVE: 'grass',
    EXPIRED: 'sun',
    ENDED: 'stroke',
    DELETED: 'pink',
  };

  const deadlineTime = moment().isAfter(moment(endTime))
    ? moment(endTime).toNow()
    : moment(endTime).fromNow();

  const handleCopyLink = () => {
    copyText(affiliateProductLink);
  };
  return (
    <div className={className}>
      <header className={styles.header}>
        <Text
          msg="project.tiktokshop_collaboration.title"
          className={styles.title}
          data-test="tikTokShopCollaboration:text:title"
        />
        <Tag
          msg={`project.tiktokshop_collaboration.status.${status?.toLowerCase()}`}
          color={statusToColor[status]}
          needMargin={false}
          data-test="tikTokShopCollaboration:tag:unknown"
        />
      </header>
      <Text
        type="md"
        msg="project.tiktokshop_collaboration.deadline"
        formatValues={{
          date: deadlineTime,
        }}
        className={styles.deadlineText}
        data-test="tikTokShopCollaboration:text:deadline"
      />
      <AlterButton
        icon="Attach-link"
        msg="project.tiktokshop_collaboration.copylink"
        onClick={handleCopyLink}
        type="grey"
        data-test="tikTokShopCollaboration:alterButton:copylink"
      />
    </div>
  );
};

export default TikTokShopCollaboration;
