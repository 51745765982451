import React from 'react';

import styles from './ResumeSubscriptionDrawer.pcss';

import Text from 'Components/ui/Text/Text';
import Drawer from 'Components/ui/Drawer/Drawer';
import Button from 'Components/ui/Button/Button';
import { useResumeSubscriptionMutation } from 'Api/mutations/ResumeSubscription.Mutation';
import { amplitude } from 'Helpers/amplitude';

interface Props {
  opened: boolean;
  onClose: () => void;
  subscriptionId: string;
}

const ResumeSubscriptionDrawer: React.FC<Props> = (props) => {
  const { opened, onClose, subscriptionId } = props;
  const [resumeSubscription, loading] = useResumeSubscriptionMutation();

  const handleResumeClick = () => {
    resumeSubscription({
      variables: {
        input: {
          subscriptionId,
        },
      },
      onCompleted: () => {
        onClose();
        amplitude.sendEvent<399>({
          id: '399',
          category: 'billing',
          name: 'pause_subsciption_resume_confirmed',
        });
      },
      onError: () => {
        onClose();
      },
    });
  };

  const handleCloseDrawer = () => {
    amplitude.sendEvent<401>({
      id: '401',
      category: 'billing',
      name: 'pause_subsciption_resume_confirm_closed',
    });
    onClose();
  };

  const handleCancelResumeClick = () => {
    amplitude.sendEvent<400>({
      id: '400',
      category: 'billing',
      name: 'pause_subsciption_resume_cancelled',
    });
    onClose();
  };

  return (
    <Drawer
      rootKey={'resume_subscription_drawer'}
      className={styles.drawerWrapper}
      containerClassName={styles.drawerContainer}
      backdropClassName={styles.drawerBackdrop}
      opened={opened}
      needCloseButton
      removeWhenClosed
      onClose={handleCloseDrawer}
    >
      <div className={styles.root}>
        <div className={styles.formContainer}>
          <Text
            type="d2"
            msg="billing.resume.drawer.title"
            className={styles.title}
            data-test="resumeSubscriptionDrawer:text:title"
          />
          <Text
            type="md"
            msg="billing.resume.drawer.subtitle"
            className={styles.subtitle}
            data-test="resumeSubscriptionDrawer:text:subtitle"
          />
        </div>
        <div className={styles.bottomContainer}>
          <div className={styles.buttonContainer}>
            <Button
              color="black"
              fluid
              msg={'billing.resume.drawer.button.resume'}
              onClick={handleResumeClick}
              loading={loading}
              data-test="resumeSubscriptionDrawer:button:unknown"
            />
            <Button
              color="white"
              fluid
              msg={'billing.resume.drawer.button.remain'}
              onClick={handleCancelResumeClick}
              data-test="resumeSubscriptionDrawer:button:unknown"
            />
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default ResumeSubscriptionDrawer;
