import React, { useEffect, useContext } from 'react';
import { graphql, useFragment } from 'react-relay';

import FinishDealAction from '../FinishDealAction/FinishDealAction';
import FbAdsAction from '../FbAdsAction/FbAdsAction';

import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { PublishingStartedStage_project$key } from 'GraphTypes/PublishingStartedStage_project.graphql';

interface Props {
  project: PublishingStartedStage_project$key;
  canUsePaidSocial?: boolean;
}

const PublishingStartedStage: React.FC<Props> = (props) => {
  const { project, canUsePaidSocial } = props;

  const { openDrawer } = useContext(DrawerContext);

  const data = useFragment<PublishingStartedStage_project$key>(
    graphql`
      fragment PublishingStartedStage_project on Project {
        id
        deadline {
          publicationDate
        }
        ...FinishDealAction_project
        ...FbAdsAction_project
      }
    `,
    project
  );

  useEffect(() => {
    if (!(data?.deadline && data.deadline.publicationDate)) {
      openDrawer(`project-publication-date`);
    }
  }, []);

  if (!data) {
    return null;
  }

  return (
    <>
      <FinishDealAction project={data} />
      {canUsePaidSocial && <FbAdsAction project={data} />}
    </>
  );
};

export default PublishingStartedStage;
