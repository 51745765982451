import React from 'react';

import styles from './SectionHeader.pcss';

import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Text from 'Components/ui/Text/Text';
import TextPreset from 'Components/ui/TextPreset/TextPreset';

interface Props {
  headerTextProps: Pick<TextProps, 'msg' | 'text'>;
  creatorsCount: number;
  onBackButtonClick?: () => void;
}

const SectionHeader: React.FC<Props> = (props) => {
  const { headerTextProps, creatorsCount, onBackButtonClick } = props;

  return (
    <div className={styles.root}>
      <AlterButton
        icon="Arrow-big-left"
        className={styles.backButton}
        onClick={onBackButtonClick}
        data-test="sectionHeader:alterButton:backButton"
      />
      <TextPreset
        header={<Text type="d2" {...headerTextProps} data-test="sectionHeader:text:unknown" />}
        subtitle={
          <Text
            type="md"
            className={styles.subtitle}
            msg="creators.dashboard.lists.details.count.list"
            formatValues={{
              n: creatorsCount,
            }}
            data-test="sectionHeader:text:subtitle"
          />
        }
      />
    </div>
  );
};

export default SectionHeader;

// types

type TextProps = React.ComponentProps<typeof Text>;
