import React, { Suspense, useContext, useState } from 'react';

import styles from './ChatDrawer.pcss';
import CreatorChat from './CreatorChat/CreatorChat';

import { getProjectsLink } from 'Util/links';
import { amplitude } from 'Helpers/amplitude';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import Drawer from 'Components/ui/Drawer/Drawer';
import Icon from 'Components/ui/Icon/Icon';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import DialogContent from 'CommonPage/Chat/Dialog/DialogContent';
import { ChatDrawerContext } from 'AdvertiserPage/Campaign/ChatDrawer/ChatDrawer.Context';

interface Props {
  projectId?: string;
  campaignId: string;
  activeCampaign?: boolean;
  organizationId?: string;
  onClose: () => void;
}

const ChatDrawer: React.FC<Props> = (props) => {
  const { projectId = '', campaignId, activeCampaign, organizationId = '', onClose } = props;

  const { closeDrawer } = useContext(DrawerContext);

  const projectsLink = getProjectsLink({
    projectId,
    campaignId: activeCampaign ? campaignId : undefined,
    organizationId,
  });

  const handleProjectsClick = () => {
    amplitude.sendEvent<342>({
      id: '342',
      category: 'chat',
      name: 'open_all_chats_from_popup',
      param: {
        project_id: projectId,
      },
    });
  };

  const handleChatClose = () => {
    closeDrawer('chat-modal');
  };

  const chat = (
    <Drawer rootKey="chat-modal" className={styles.drawer} onClose={onClose}>
      <div className={styles.drawerContainer}>
        <DialogContent projectId={projectId} isOldView={false} inPopup={true} isAdvertiser={true} />
      </div>
      <div onClick={handleProjectsClick}>
        <SmartLink className={styles.openChats} path={projectsLink} onClick={handleChatClose}>
          <Icon name="Open-chat" />
        </SmartLink>
      </div>
    </Drawer>
  );

  return (
    <>
      {projectId && (
        <Suspense fallback={null}>
          <CreatorChat projectId={projectId} chat={chat} />
        </Suspense>
      )}
    </>
  );
};

export default ChatDrawer;
