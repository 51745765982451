import React from 'react';

import styles from './DateSeparator.pcss';

import Text from 'Atoms/Text/Text';

interface Props {
  createdAt: string | null;
}

const DateSeparator: React.FC<Props> = (props) => {
  const { createdAt } = props;

  return (
    <div className={styles.root}>
      <Text
        size="sm"
        color="grayDog"
        weight="500"
        text={createdAt}
        className={styles.date}
        data-test="dateSeparator:text:date"
      />
    </div>
  );
};

export default DateSeparator;
