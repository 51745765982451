import React, { useEffect } from 'react';
import track from 'react-tracking';

import styles from './Creatives.pcss';
import CreativesContainer from './CreativesContainer/CreativesContainer';

import { amplitude } from 'Helpers/amplitude';
import Page from 'Templates/Page/Page';
import Text from 'Components/ui/Text/Text';
import { trackTime as custifyTrackTime } from 'Util/custify';

const Creatives = () => {
  useEffect(() => {
    custifyTrackTime('Creatives_library');

    amplitude.sendEvent({
      id: 310,
      category: 'pageview',
      name: 'creatives_library',
    });

    return () => {
      _ctrack.stopTrackTime();
    };
  }, []);

  return (
    <Page className={styles.root}>
      <div className={styles.content}>
        <div className={styles.container}>
          <Text
            type="d2"
            msg="creatives.title"
            className={styles.title}
            data-test="creatives:text:title"
          />
          <div className={styles.creatives}>
            <CreativesContainer />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default track(
  {
    page: 'creatives',
  },
  { dispatchOnMount: true }
)(Creatives);
