import { graphql, useMutation } from 'react-relay';
import { v4 as uuid } from 'uuid';

import { CreateOutreachBatchMutation as MutationType } from 'GraphTypes/CreateOutreachBatchMutation.graphql';
import type { BaseMutation, BaseMutationExecFn } from 'Types/utils';

const CreateOutreachBatchMutation = graphql`
  mutation CreateOutreachBatchMutation($input: CreateOutreachBatchInput!) {
    createOutreachBatch(input: $input) {
      __typename
      ... on CreateOutreachBatchPayload {
        batch {
          id
        }
      }
    }
  }
`;

export const useCreateOutreachBatchMutation: BaseMutation<MutationType> = (config) => {
  const [createOutreachBatch, loading] = useMutation<MutationType>(CreateOutreachBatchMutation);

  const createOutreachBatchCaller: BaseMutationExecFn<MutationType> = (execConfig) => {
    if (!execConfig) return;
    const newTemplateId = uuid();
    createOutreachBatch({
      ...config,
      ...execConfig,
      variables: {
        input: {
          id: newTemplateId,
          ...execConfig.variables.input,
        },
      },
    });
  };
  return [createOutreachBatchCaller, loading];
};
