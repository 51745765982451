import React, { useMemo, useContext } from 'react';
import { graphql, useFragment } from 'react-relay';

import styles from './WlSlotsBanner.pcss';

import { amplitude } from 'Helpers/amplitude';
import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Limit from 'Molecules/Limit/Limit';
import addPlusImg from 'Images/general/add-plus-circle-filled.svg';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { WlSlotsBanner_campaign$key } from 'GraphTypes/WlSlotsBanner_campaign.graphql';
import { ExtendedPlanId, PlanType, getPlans } from 'Page/advertiser/BillingPlans/data';

interface Props {
  className?: string;
  campaign: WlSlotsBanner_campaign$key;
}

const WlSlotsBanner: React.FC<Props> = (props) => {
  const { campaign, className } = props;

  const fragment = useFragment(
    graphql`
      fragment WlSlotsBanner_campaign on Campaign {
        organization {
          counters {
            bcaLicensedCreators
          }
          subscription {
            planId
            availableAddonIds
            addonItems {
              addon {
                id
                resourcesPerUnit
              }
              quantity
            }
            paused
            interval
            effectiveLimits {
              maxBcaConnections
            }
            baseLimits {
              maxBcaConnections
            }
          }
        }
      }
    `,
    campaign
  );

  const { openDrawer } = useContext(DrawerContext);

  const currentAmount = fragment.organization?.counters?.bcaLicensedCreators;
  const subscription = fragment.organization?.subscription;
  const totalCount = subscription?.effectiveLimits.maxBcaConnections || 0;
  const defaultMaxLicensedCreators = subscription?.baseLimits.maxBcaConnections || 0;
  const planId = subscription?.planId;
  const interval = subscription?.interval;
  const availableAddonIds = subscription?.availableAddonIds;

  const isTrial = planId === 'TRIAL_2022_08' || planId === 'TRIAL_2024_03';
  const isFreePlan = planId === 'FREE';

  const isAddonAvailable = availableAddonIds?.includes('WHITELISTING_LICENSES');

  const currentPlanData = useMemo<PlanType | undefined>(() => {
    const plans = getPlans({
      currency: 'USD',
      plansProps: {
        [planId as ExtendedPlanId]: {
          interval,
          addons: [],
        },
      },
    });
    return plans.find((plan) => plan.planId === planId);
  }, [planId, interval]);

  if (
    isTrial ||
    isFreePlan ||
    !isAddonAvailable ||
    subscription?.effectiveLimits.maxBcaConnections === null
  ) {
    return null;
  }

  const handleBuyClick = () => {
    amplitude.sendEvent<424>({
      id: '424',
      category: 'wl_addons_banner',
      name: 'buy_click',
      param: undefined,
    });
    openDrawer('buy-maxBcaConnections-addon');
  };

  const isLimitExcceded = totalCount && Number(currentAmount) >= Number(totalCount);

  return (
    <div className={className}>
      <div className={styles.root}>
        <div className={styles.imgWrap}>
          <Icon name="Profile" />
          <img src={addPlusImg} className={styles.plus} />
        </div>
        <Text
          type="md"
          msg="brief_template.field.campaign_creators_amount.wl_banner.descr"
          formatValues={{ currentAmount: totalCount - Number(currentAmount), totalCount }}
          data-test="wlSlotsBanner:text:descr"
        />
        <div className={styles.spacer} />
        <AlterButton
          type="black"
          msg="brief_template.field.campaign_creators_amount.wl_banner.buy"
          onClick={handleBuyClick}
          data-test="wlSlotsBanner:alterButton:buy"
        />
      </div>
      <Limit
        limitExcceded={!!isLimitExcceded}
        type="maxBcaConnections"
        totalCount={currentAmount}
        maxCount={totalCount}
        addons={currentPlanData?.availableAddons || []}
        defaultLimit={defaultMaxLicensedCreators}
        subscriptionData={subscription}
      />
    </div>
  );
};

export default WlSlotsBanner;
