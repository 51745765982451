import React, { useState, useEffect, useContext, Suspense, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import CampaignInfo from '../CampaignInfo/CampaignInfo';
import CampaignTabs from '../CampaignTabs/CampaignTabs';
import CampaignContent from '../CampaignContent/CampaignContent';
import InviteCreators from '../InviteCreatorsButton/InviteCreatorsButton';
import ReviewStub from '../ReviewStub/ReviewStub';
import NewCampaignTabs from '../NewCampaignTabs/NewCampaignTabs';

import styles from './CampaignContainer.pcss';

import MaxLicensedCreatorsExceededDrawer from 'Modal/advertiser/MaxLicensedCreatorsExceeded/MaxLicensedCreatorsExceeded';
import Toggle from 'Components/ui/Toggle/Toggle';
import { getCampaignTypeLink } from 'Util/links';
import { AWAITING_LIST, BRIEF, CREATORS } from 'Constants/general';
import { CampaignQuery$data } from 'GraphTypes/CampaignQuery.graphql';
import { FeatureFlagsContext } from 'Containers/FeatureFlags/FeatureFlags.Context';
import { ChatDrawerContext } from 'AdvertiserPage/Campaign/ChatDrawer/ChatDrawer.Context';
import { ARCHIVED, COMPLETED, HIRED, MESSAGED } from 'AdvertiserPage/Campaign/NewCampaignTabs/util';

interface Props {
  admin: boolean;
  email?: string;
  organizationId?: string;
  campaign: CampaignQuery$data['campaign'];
  campaignsCount?: number;
  publishingRequired?: boolean;
  defaultType?: string;
  user: CampaignQuery$data['currentUser'];
  creator: NonNullable<CampaignQuery$data['campaign']>['creator'];
}

const CampaignContainer: React.FC<Props> = (props) => {
  const {
    campaign,
    admin,
    user,
    creator,
    campaignsCount,
    publishingRequired,
    defaultType,
    email,
    organizationId,
  } = props;
  const { id: campaignId = '', type } = useParams<{ id: string; type?: string }>();
  const { registerCallback, getCallback } = useContext(ChatDrawerContext);

  const [tabNotification, setTabNotification] = useState<
    { targetTab: string; value: number | string } | undefined
  >(undefined);
  useEffect(() => {
    if (tabNotification !== undefined) {
      setTimeout(() => setTabNotification(undefined), 1000);
    }
  }, [tabNotification]);

  useEffect(() => {
    if (!getCallback('Chat', 'Success')) {
      registerCallback('Chat', 'Success', () =>
        setTabNotification({ value: '+1', targetTab: MESSAGED })
      );
    }
    if (!getCallback('Hired', 'Success')) {
      registerCallback('Hired', 'Success', () =>
        setTabNotification({ value: '+1', targetTab: HIRED })
      );
    }
    if (!getCallback('Completed', 'Success')) {
      registerCallback('Completed', 'Success', () =>
        setTabNotification({ value: '+1', targetTab: COMPLETED })
      );
    }
    if (!getCallback('Archived', 'Success')) {
      registerCallback('Archived', 'Success', () =>
        setTabNotification({ value: '+1', targetTab: ARCHIVED })
      );
    }
    if (!getCallback('Unarchived', 'Success')) {
      registerCallback('Unarchived', 'Success', (targetTab: string) =>
        setTabNotification({ value: '+1', targetTab: targetTab })
      );
    }
  }, [registerCallback, getCallback]);

  const navigate = useNavigate();

  const defaultSection = type ? type : defaultType;

  const [isAdminView, setAdminView] = useState(admin);
  const [section, setSection] = useState(defaultSection);
  const [currentUser, setCurrentUser] = useState(user);

  const hasAcceptance = !!(campaign?.briefSubmission && campaign.briefSubmission.acceptance);
  const isCompleted = !!campaign?.completion?.id;
  const campaignType = campaign?.type;
  const campaignPlatform = campaign?.platform;

  const planId = currentUser?.organization?.subscription?.planId;
  const maxBcaConnections =
    currentUser?.organization?.subscription?.effectiveLimits.maxBcaConnections;

  const isFirstCampaign = campaignsCount === 1;

  const { hasFeatureFlag } = useContext(FeatureFlagsContext);

  const newCampaignTabs = hasFeatureFlag('new_campaign_tabs');

  const handleBulkMessage = useCallback(
    (message: string) => {
      setTabNotification({ value: message, targetTab: 'messaged' });
    },
    [setTabNotification]
  );

  useEffect(() => {
    return () => {
      document.title = 'Insense';
    };
  }, []);

  useEffect(() => {
    if (!type) return;
    if (type !== section) {
      setSection(type);
    }
  }, [type]);

  useEffect(() => {
    const newCurrentUser = isAdminView ? user : creator;
    setCurrentUser(newCurrentUser);
  }, [creator]);

  const getRedirectLink = () => {
    let path = BRIEF;

    if (hasAcceptance) {
      path = CREATORS;
    } else if (currentUser?.organization?.subscription?.canBrowseCreators) {
      path = AWAITING_LIST;
    }

    return getCampaignTypeLink({ campaignId, path });
  };

  useEffect(() => {
    if (!currentUser?.organization?.subscription?.canBrowseCreators) {
      if (type === AWAITING_LIST) {
        setSection(CREATORS);
      }
    }
  }, [currentUser?.organization?.subscription?.canBrowseCreators]);

  useEffect(() => {
    const newLink = getCampaignTypeLink({ campaignId, path: section });
    navigate(newLink, { replace: true });
  }, [section]);

  if (!type) {
    const link = getRedirectLink();
    navigate(link);
    return null;
  }

  const handleAdminViewChange = () => {
    const newAdminViewValue = !isAdminView;
    setAdminView(newAdminViewValue);
    const newUserValue = newAdminViewValue ? user : creator;
    setCurrentUser(newUserValue);
  };

  const handleSectionChange = (newSection: string) => {
    setSection(newSection);
  };

  const adminView = !!currentUser?.admin;

  return (
    <div className={styles.root}>
      <Helmet>{campaign?.name && <title>{campaign.name}</title>}</Helmet>
      {campaign && (
        <div id="campaign-info-container">
          <CampaignInfo
            campaign={campaign}
            isAdminView={isAdminView}
            adminView={adminView}
            isAdmin={admin}
            currentUser={currentUser}
            hasAcceptance={hasAcceptance}
            className={styles.info}
            onClientViewChange={handleAdminViewChange}
          />
        </div>
      )}
      {newCampaignTabs ? (
        <NewCampaignTabs tabMessage={tabNotification} />
      ) : (
        <div className={styles.tabsWrap}>
          <div className={styles.tabsElements}>
            {campaignId && campaign && !campaign.completion?.id && (
              <Suspense fallback={null}>
                <InviteCreators
                  section={section}
                  campaignId={campaignId}
                  admin={admin}
                  onSectionChange={handleSectionChange}
                />
              </Suspense>
            )}
            <CampaignTabs
              section={section || ''}
              campaignId={campaignId}
              isAdminView={adminView}
              currentUser={currentUser}
              hasAcceptance={hasAcceptance}
              isCompleted={isCompleted}
              publishingRequired={!!publishingRequired}
              onSectionChange={handleSectionChange}
            />
          </div>
          {admin && (
            <div className={styles.viewToggle}>
              <Toggle
                on={!isAdminView}
                id="clientView"
                tooltipMsg="campaign.client_view"
                onChange={handleAdminViewChange}
                className={styles.toggle}
              />
            </div>
          )}
        </div>
      )}
      <div className={styles.content}>
        <CampaignContent
          campaignId={campaignId}
          currentUser={currentUser}
          isAdminView={adminView}
          section={section || ''}
          campaignType={campaignType}
          campaignPlatform={campaignPlatform}
          campaign={campaign}
          publishingRequired={!!publishingRequired}
          email={email}
          organizationId={organizationId}
          handleBulkMessage={handleBulkMessage}
        />
      </div>
      {!hasAcceptance && !adminView && <ReviewStub isFirstCampaign={isFirstCampaign} />}
      {planId && (
        <MaxLicensedCreatorsExceededDrawer
          attach={{
            planId,
            maxLicensedCreators: Number(maxBcaConnections),
          }}
        />
      )}
    </div>
  );
};

export default CampaignContainer;
