/**
 * @generated SignedSource<<510350a47350d1076bb16b07103e3a74>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type FileType = "IMAGE" | "OTHER" | "VIDEO";
import { FragmentRefs } from "relay-runtime";
export type MediaCarousel_message$data = {
  readonly attachments?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly createdAt: any;
        readonly file: {
          readonly bcaTaggedUrl: string | null | undefined;
          readonly filename: string | null | undefined;
          readonly originalFilename: string | null | undefined;
          readonly secureUrl: string;
          readonly thumbnailUrl: string;
          readonly transformations?: {
            readonly autoUrl: string;
          } | null | undefined;
          readonly type: FileType;
        };
        readonly id: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly totalCount: number;
  } | null | undefined;
  readonly creatives?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly createdAt: any;
        readonly file: {
          readonly bcaTaggedUrl: string | null | undefined;
          readonly filename: string | null | undefined;
          readonly originalFilename: string | null | undefined;
          readonly secureUrl: string;
          readonly thumbnailUrl: string;
          readonly transformations?: {
            readonly autoUrl: string;
          } | null | undefined;
          readonly type: FileType;
        } | null | undefined;
        readonly id: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly totalCount: number;
  } | null | undefined;
  readonly reviewFiles?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly createdAt: any;
        readonly file: {
          readonly bcaTaggedUrl: string | null | undefined;
          readonly filename: string | null | undefined;
          readonly originalFilename: string | null | undefined;
          readonly secureUrl: string;
          readonly thumbnailUrl: string;
          readonly transformations?: {
            readonly autoUrl: string;
          } | null | undefined;
          readonly type: FileType;
        };
        readonly id: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly totalCount: number;
  };
  readonly " $fragmentType": "MediaCarousel_message";
};
export type MediaCarousel_message$key = {
  readonly " $data"?: MediaCarousel_message$data;
  readonly " $fragmentSpreads": FragmentRefs<"MediaCarousel_message">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "createdAt",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "file",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "thumbnailUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "filename",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "secureUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "bcaTaggedUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "originalFilename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VideoTransformation",
            "kind": "LinkedField",
            "name": "transformations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "autoUrl",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "Video",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MediaCarousel_message",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CreativeConnection",
          "kind": "LinkedField",
          "name": "creatives",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "CreativeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Creative",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "ProductSeedingContentSubmission",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "reviewFiles",
          "args": null,
          "concreteType": "ContentReviewAttachmentConnection",
          "kind": "LinkedField",
          "name": "attachments",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "ContentReviewAttachmentEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ContentReviewAttachment",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "ContentReviewSubmission",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MessageAttachmentConnection",
          "kind": "LinkedField",
          "name": "attachments",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "MessageAttachmentEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "MessageAttachment",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "Message",
      "abstractKey": null
    }
  ],
  "type": "ProjectEvent",
  "abstractKey": "__isProjectEvent"
};
})();

(node as any).hash = "758d7c5c63634038b5ab977648e24f59";

export default node;
