import React from 'react';

import styles from './PlayIcon.css';

import Icon from 'Atoms/Icon/Icon';

interface Props {
  className?: string;
}

const PlayIcon: React.FC<Props> = (props) => {
  const { className } = props;

  return (
    <div className={`${styles.root} ${className}`}>
      <Icon name="play" color="white" />
    </div>
  );
};

export default PlayIcon;
