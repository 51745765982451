import React, { Suspense, useContext, useEffect, useMemo } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';

import { FinishDealWrapperContext } from '../FinishDealWrapperContext';

import ProjectAttachmentsWrapper from './ProjectAttachments/ProjectAttachmentsWrapper';
import styles from './PublicationSelection.pcss';

import { CONTENT_CREATION, PRODUCT_SEEDING, USD } from 'Constants/general';
import { PublicationSelectionDrawerQuery as QueryType } from 'GraphTypes/PublicationSelectionDrawerQuery.graphql';
import Drawer from 'Components/ui/Drawer/Drawer';
import Text from 'Components/ui/Text/Text';
import Spinner from 'Atoms/Spinner/Spinner';
import Button from 'Components/ui/Button/Button';
import TextButton from 'Components/ui/TextButton/TextButton';
import ErrorHandler from 'Util/errorHandler';

interface Props {
  projectId: string;
  selectSubmissionFiles: boolean;
}

const PublicationSelectionDrawerQuery = graphql`
  query PublicationSelectionDrawerQuery($id: ID!, $skip: Boolean!) {
    project(id: $id) @skip(if: $skip) {
      currency
      creator {
        id
      }
      campaign {
        id
        type
        counters {
          completedProjects
        }
      }
    }
    currentUser {
      admin
      organization {
        id
        currency
        subscription {
          availableCampaignTypes
        }
        counters {
          campaignsWithPosting
          productSeedingCampaign
        }
      }
    }
  }
`;

const PublicationSelectionDrawer: React.FC<Props> = (props) => {
  const { projectId, selectSubmissionFiles } = props;

  const finishDealContext = useContext(FinishDealWrapperContext);

  const opened = Boolean(finishDealContext?.selectPublicationDrawer);
  const selectedFiles = finishDealContext?.selectedFiles || {};
  const setNeedToOpenTryAnotherCampaignDrawerIfPossibleStatus =
    finishDealContext?.setNeedToOpenTryAnotherCampaignDrawerIfPossibleStatus;
  const changeDrawerVisible = finishDealContext?.setSelectPublicationDrawerStatus;
  const changeCompleteProjectDrawerVisible = finishDealContext?.setCompleteProjectDrawerStatus;
  const setCompleteProjectWithoutStatus = finishDealContext?.setCompleteProjectWithoutStatus;

  const publicationSelectionQuery$data = useLazyLoadQuery<QueryType>(
    PublicationSelectionDrawerQuery,
    { id: projectId, skip: !projectId }
  );

  const organization = publicationSelectionQuery$data?.currentUser?.organization;
  const campaign = publicationSelectionQuery$data?.project?.campaign;
  const creator = publicationSelectionQuery$data?.project?.creator;

  const availableCampaignTypes = organization?.subscription?.availableCampaignTypes || [];

  const needShowTryAnotherCampaignDrawer = useMemo<boolean>(() => {
    const isProductSeedingAvailable = availableCampaignTypes.includes(PRODUCT_SEEDING);
    const isContentCreation = campaign?.type === CONTENT_CREATION;

    const haveNoCampaigns =
      !organization?.counters?.campaignsWithPosting &&
      !organization?.counters?.productSeedingCampaign;

    return isProductSeedingAvailable && haveNoCampaigns && isContentCreation;
  }, [availableCampaignTypes, organization?.counters, campaign?.type, campaign?.counters]);

  useEffect(() => {
    if (setNeedToOpenTryAnotherCampaignDrawerIfPossibleStatus) {
      setNeedToOpenTryAnotherCampaignDrawerIfPossibleStatus(needShowTryAnotherCampaignDrawer);
    }
  }, [needShowTryAnotherCampaignDrawer]);

  const fileAttachmentType = selectSubmissionFiles ? 'CONTENT_REVIEW' : undefined;

  const handleClose = () => {
    if (!changeDrawerVisible) {
      ErrorHandler.error('No handler for manual closing selection drawer');
      return;
    }
    changeDrawerVisible(false);
  };

  const handleApproveWorkButton = () => {
    switchToCompleteProjectDrawer();
  };

  const handleWithoutApproveButton = () => {
    if (!changeDrawerVisible) {
      ErrorHandler.error('No handler for closing selection drawer');
      return;
    }
    if (!setCompleteProjectWithoutStatus) {
      ErrorHandler.error('No handler for change complete project status');
      return;
    }
    switchToCompleteProjectDrawer();
    setCompleteProjectWithoutStatus(true);
  };

  const switchToCompleteProjectDrawer = () => {
    if (!changeDrawerVisible) {
      ErrorHandler.error('No handler for closing selection drawer');
      return;
    }
    if (!changeCompleteProjectDrawerVisible) {
      ErrorHandler.error('No handler for open complete drawer');
      return;
    }
    changeDrawerVisible(false);
    setTimeout(() => {
      changeCompleteProjectDrawerVisible(true);
    }, 0);
  };

  if (!organization || !creator || !campaign) {
    return null;
  }

  return (
    <Drawer
      needCloseButton
      onClose={handleClose}
      opened={opened}
      className={styles.root}
      rootKey="publication-selection"
    >
      <div className={styles.contentContainer}>
        <div className={styles.header}>
          <Text type="d2" msg="publication_selection_modal_drawer.title" className={styles.title} />
          <Text
            type="md"
            msg="publication_selection_modal_drawer.description"
            className={styles.descr}
          />
        </div>
        <ProjectAttachmentsWrapper
          last={30}
          organizationId={organization.id}
          creatorId={creator.id}
          campaignId={campaign.id}
          fileAttachmentType={fileAttachmentType}
        />
        <div className={styles.buttonContainer}>
          <Button
            color="black"
            fluid
            disabled={!Object.keys(selectedFiles).length}
            className={styles.mainBtn}
            msg="publication_selection_modal_drawer.submit"
            onClick={handleApproveWorkButton}
          />
          <TextButton
            msg="publication_selection_modal_drawer.submit_without_publication"
            onClick={handleWithoutApproveButton}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default PublicationSelectionDrawer;
