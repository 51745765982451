import React, { useState, useMemo, useContext, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { graphql, useFragment } from 'react-relay';
import classnames from 'classnames';

import MessageMedia from '../MessageEvent/MessageAttachments/MessageMedia/MessageMedia';
import SimpleProjectEvent from '../SimpleProjectEvent/SimpleProjectEvent';
import { MessageAttachmentsContext } from '../MessageEvent/MessageAttachments/MessageAttachmentsContext';

import styles from './ContentReviewSubmission.pcss';

import { amplitude } from 'Helpers/amplitude';
import MediaCarouselContainer from 'Modal/advertiser/MediaCarousel/MediaCarouselContainer';
import MediaCarousel from 'Modal/advertiser/MediaCarousel/MediaCarousel';
import { createBytesSize } from 'Util/numberFormatter';
import TextButton from 'Components/ui/TextButton/TextButton';
import { UserType } from 'GraphTypes/ProjectEventsQuery.graphql';
import { PRODUCT_SEEDING_CONTENT_SUBMISSION } from 'Types/enums';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import Icon from 'Components/ui/Icon/Icon';
import { ContentReviewSubmission_event$key } from 'GraphTypes/ContentReviewSubmission_event.graphql';

interface Props {
  projectId: string;
  inPopup?: boolean;
  userType?: UserType;
  event: ContentReviewSubmission_event$key;
}

const ContentReviewSubmission: React.FC<Props> = (props) => {
  const { event, userType, projectId, inPopup } = props;

  const [currentMediaIndex, setCurrenMediaIndex] = useState<number | undefined>();
  const [mediaCarouselOpen, setMediaCarouselOpen] = useState(false);

  const { openDrawer } = useContext(DrawerContext);
  const { setLoadingAll, loadedCount, setLoadedCount } = useContext(MessageAttachmentsContext);

  const data = useFragment(
    graphql`
      fragment ContentReviewSubmission_event on ProjectEvent {
        ...MediaCarousel_message
        __typename
        id
        createdAt
        ... on ContentReviewSubmission {
          reviewFiles: attachments {
            edges {
              node {
                id
                file {
                  bytes
                  ...MessageMedia_file
                }
              }
            }
          }
        }
        ... on ProductSeedingContentSubmission {
          creatives {
            edges {
              node {
                id
                file {
                  bytes
                  ...MessageMedia_file
                }
              }
            }
          }
        }
      }
    `,
    event
  );

  const creativesCount = data.creatives?.edges?.length || 0;
  const reviewFilesCount = data.reviewFiles?.edges?.length || 0;

  const totalCount = creativesCount || reviewFilesCount;

  useEffect(() => {
    if (totalCount && loadedCount === totalCount) {
      setLoadedCount(0);
      setLoadingAll(false);
    }
  }, [loadedCount]);

  const nodeId = data.id;

  const createdAt = data?.createdAt;
  const isSeedingSubmission = data?.__typename === PRODUCT_SEEDING_CONTENT_SUBMISSION;
  const attachments = isSeedingSubmission ? data?.creatives?.edges : data?.reviewFiles?.edges;

  const mediasLength = attachments?.length || 0;

  const userPostfix = userType === 'CONTRACTOR' ? '_creator' : '';

  const fullSize = useMemo(() => {
    const size =
      (attachments || []).reduce((acc, current) => {
        return acc + (current?.node?.file?.bytes || 0);
      }, 0) || 0;
    return createBytesSize(size);
  }, [attachments]);

  const downloadLabel =
    mediasLength > 1
      ? 'chat.messages.message.download_original_all'
      : 'chat.messages.message.download_original';

  const handleDownload = () => {
    setLoadingAll(true);
  };

  if (!data) {
    return null;
  }

  if (mediasLength === 0) return null;

  const handleMediaOpen = (currentIndex: number) => {
    setCurrenMediaIndex(currentIndex);
    amplitude.sendEvent<438>({
      id: '438',
      category: 'attachments_drawer',
      name: 'show',
      param: { multiple: Number(totalCount) > 1 },
    });
    if (inPopup) {
      setMediaCarouselOpen(true);
    } else {
      openDrawer(`message-attachments-${nodeId}`);
    }
  };

  const handleCarouselClose = () => {
    setMediaCarouselOpen(false);
  };

  return (
    <div>
      <SimpleProjectEvent
        createdAt={createdAt}
        titleMsg={
          isSeedingSubmission
            ? `chat.project.system_event.product_seeding_content_submission${userPostfix}`
            : `chat.project.system_event.content_review_submission${userPostfix}`
        }
      />
      <div className={styles.root}>
        <div className={classnames(styles.medias)}>
          {attachments?.map((item, index) => {
            if (!item?.node || !item.node.file) return null;

            const attachmentId = item.node.id;

            return (
              <MessageMedia
                id={attachmentId}
                index={index}
                projectId={projectId}
                key={attachmentId}
                file={item.node.file}
                isRead={false}
                isOwner={false}
                createdAt={createdAt}
                actionAble={true}
                mediasLength={mediasLength}
                onMediaOpen={handleMediaOpen}
              />
            );
          })}
        </div>
        <div className={styles.footer}>
          {userType === 'ADVERTISER' && (
            <TextButton
              size="xs"
              msg={downloadLabel}
              icon="Download-upload"
              color="grey"
              msgValues={{ size: fullSize }}
              onClick={handleDownload}
              data-test="contentReviewSubmission:textButton:unknown"
            />
          )}
        </div>
      </div>
      {nodeId && !inPopup && (
        <MediaCarouselContainer id={nodeId} message={data} currentIndex={currentMediaIndex} />
      )}
      {inPopup &&
        mediaCarouselOpen &&
        createPortal(
          <div className={styles.carousel}>
            <Icon
              name="Close-small"
              className={styles.carouselCloseBtn}
              onClick={handleCarouselClose}
            />
            <MediaCarousel message={data} currentIndex={currentMediaIndex} />
          </div>,
          document.body
        )}
    </div>
  );
};

export default ContentReviewSubmission;
