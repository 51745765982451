import { useContext } from 'react';

import { useApplyOneTimeDiscountToSubscriptionMutation } from 'Api/mutations/ApplyOneTimeDiscountToSubscription.Mutation';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import ErrorHandler from 'Util/errorHandler';
import { amplitude } from 'Helpers/amplitude';

export const periodToSubscriptionIntervalMap = {
  MONTHLY: 1,
  TRIMONTHLY: 3,
  YEARLY: 12,
  CUSTOM: 3,
  BIANNUAL: 24,
};

export const percentDiscountToIntervalMap = {
  MONTHLY: 15,
  TRIMONTHLY: 35,
  YEARLY: 25,
  CUSTOM: 15,
  BIANNUAL: 15,
};

type PeriodKeys = keyof typeof periodToSubscriptionIntervalMap;

const discountIdToPeriodMap: Record<
  typeof periodToSubscriptionIntervalMap[PeriodKeys],
  string | null
> = {
  1: null,
  3: '94717679-e9c4-4856-a719-5fe838ee3220',
  12: 'db670972-96c0-4567-ba8a-5f983c71d161',
};

export const useApplyOfferDiscount = (
  subscriptionId: string,
  period: number
): [() => void, boolean] => {
  const [applyOneTimeDiscountToSubscription, discountLoading] =
    useApplyOneTimeDiscountToSubscriptionMutation();
  const { openDrawer, closeDrawer } = useContext(DrawerContext);
  const applyOfferDiscount = () => {
    try {
      const discountId = discountIdToPeriodMap[period];
      if (!discountId) {
        throw Error('Incorrect discount value');
      }
      applyOneTimeDiscountToSubscription({
        variables: {
          input: {
            discountId,
            subscriptionId,
          },
        },
        onCompleted: (response) => {
          if (response.applyOneTimeDiscountToSubscription?.message) {
            ErrorHandler.error('User cannot apply discount from cancel offer', {
              message: response.applyOneTimeDiscountToSubscription?.message,
            });
            closeDrawer('cancel-subscription-offer-drawer');
            return;
          }
          openDrawer('cancel-subscription-offer-accepted-drawer');
          amplitude.sendEvent<379>({
            id: '379',
            category: 'billing',
            name: 'accepted_discount_offer',
          });
        },
        onError: (error) => {
          ErrorHandler.error('User cannot apply discount from cancel offer', { error });
          closeDrawer('cancel-subscription-offer-drawer');
        },
      });
    } catch (error) {
      ErrorHandler.error('User cannot apply discount from cancel offer', { error });
      closeDrawer('cancel-subscription-offer-drawer');
    }
  };
  return [applyOfferDiscount, discountLoading];
};
