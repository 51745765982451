import React, { useState } from 'react';
import { QueryRenderer, graphql } from 'react-relay';

import styles from './CreatorPastProjects.pcss';
import CreatorPastProjectsList from './CreatorPastProjectsList/CreatorPastProjectsList';

import environment from 'Api/Environment';
import Text from 'Atoms/Text/Text';
import Spinner from 'Atoms/Spinner/Spinner';
import StagesFilter from 'AdvertiserPage/Campaign/Creators/ProjectsFilters/StagesFilter/StagesFilter';
import {
  CreatorPastProjectsQuery as QueryType,
  AdvertiserProjectStage,
} from 'GraphTypes/CreatorPastProjectsQuery.graphql';

const CreatorPastProjectsQuery = graphql`
  query CreatorPastProjectsQuery($creatorId: ID!, $stages: [AdvertiserProjectStage!]) {
    creator(id: $creatorId) {
      ...CreatorPastProjectsList_projects @arguments(stages: $stages)
    }
  }
`;

interface Props {
  creatorId: string;
}

const CreatorPastProjects: React.FC<Props> = (props) => {
  const { creatorId } = props;

  const defaultStages = [
    'ACCEPTED',
    'CONTENT_SUBMITTED',
    'PUBLISHING_STARTED',
    'LAUNCHED',
    'COMPLETED',
  ];

  const [projectStages, setProjectStages] = useState<undefined | AdvertiserProjectStage[]>();

  const handleProjectStage = (data: any) => {
    setProjectStages(data);
  };

  const finalStages = projectStages && projectStages.length > 0 ? projectStages : defaultStages;

  return (
    <div>
      <div className={styles.header}>
        <Text
          weight="500"
          transform="uppercase"
          msg="menu.campaigns"
          data-test="creatorPastProjects:text:campaigns"
        />
        <StagesFilter
          projectStages={projectStages}
          align="right"
          stages={['ACCEPTED', 'CONTENT_SUBMITTED', 'PUBLISHING_STARTED', 'LAUNCHED', 'COMPLETED']}
          onProjectStageChange={handleProjectStage}
        />
      </div>
      <QueryRenderer<QueryType>
        environment={environment}
        query={CreatorPastProjectsQuery}
        variables={{ creatorId, stages: finalStages as AdvertiserProjectStage[] }}
        render={({ props: queryProps }) => {
          if (!queryProps) return <Spinner className={styles.preloader} />;

          if (!queryProps.creator) return null;

          return (
            <CreatorPastProjectsList
              stages={finalStages}
              creatorId={creatorId}
              projects={queryProps.creator}
            />
          );
        }}
      />
    </div>
  );
};

export default CreatorPastProjects;
