import compact from 'lodash/compact';
import {
  Activity01Icon,
  ViewIcon,
  Analytics03Icon,
  Clock01Icon,
  FavouriteIcon,
  Comment01Icon,
  LinkForwardIcon,
  Bookmark01Icon,
  Navigation01Icon,
  Dollar02Icon,
  CoinsDollarIcon,
  PlayCircle02Icon,
} from '@hugeicons-pro/core-stroke-rounded';

import { PostedCreative_creative$data } from 'GraphTypes/PostedCreative_creative.graphql';
import { createNumber, createPercent, createShortNumber } from 'Util/numberFormatter';

type GetColumnsData = {
  creative?: PostedCreative_creative$data | null;
};

export const getColumns = (data: GetColumnsData) => {
  const postPublication = data.creative?.postPublication;

  if (!postPublication) return [];

  const campaignType = data.creative?.project?.campaign.type;
  const projectIsCompleted = !!data.creative?.project?.completion?.id;
  const placement = postPublication.placement?.toUpperCase();
  const platform = postPublication.platform?.toUpperCase();
  const comments = postPublication.comments;
  const cpe = postPublication.cpe;
  const cpm = postPublication.cpm;
  const likes = postPublication.likes;
  const awgWatchTime = postPublication.averageViewTime;
  const engagementRate = postPublication.engagementRate;
  const mediaCost = postPublication.mediaCost;
  const reach = postPublication.reach;
  const shares = postPublication.shares;
  const videoCompletionRate = postPublication.videoCompletionRate;
  const views = postPublication.views;
  const savedCount = postPublication.saves;
  const navigation = postPublication.navigation;

  const isProductSeeding = campaignType === 'PRODUCT_SEEDING';

  if (platform === 'INSTAGRAM') {
    return [
      compact([
        {
          id: 'reach',
          value: reach ? createShortNumber(reach) : '—',
          msg: 'creative.reach',
          icon: Activity01Icon,
        },
        {
          id: 'views',
          value: views ? createShortNumber(views) : '—',
          msg: 'creative.views',
          icon: ViewIcon,
        },
        placement === 'REEL'
          ? {
              id: 'awgWatchTime',
              value: awgWatchTime ? `${awgWatchTime}s` : '—',
              msg: 'creative.avg_watch_time',
              icon: Clock01Icon,
            }
          : null,
        placement && placement !== 'STORY'
          ? {
              id: 'engagementRate',
              value: engagementRate ? createPercent(engagementRate) : '—',
              msg: 'creative.er',
              icon: Analytics03Icon,
            }
          : null,
        !isProductSeeding && projectIsCompleted
          ? {
              id: 'mediaCost',
              value: mediaCost ? `$${mediaCost}` : '—',
              msg: 'creative.media_cost',
              icon: Dollar02Icon,
            }
          : null,
        !isProductSeeding && projectIsCompleted
          ? {
              id: 'cpe',
              value: cpe ? `$${cpe}` : '—',
              msg: 'creative.cpe',
              icon: CoinsDollarIcon,
            }
          : null,
        !isProductSeeding && projectIsCompleted
          ? {
              id: 'cpm',
              value: cpm ? `$${cpm}` : '—',
              msg: 'creative.cpm',
              icon: CoinsDollarIcon,
            }
          : null,
      ]),
      compact([
        placement && placement !== 'STORY'
          ? {
              id: 'likeCount',
              value: likes ? createShortNumber(likes) : '—',
              msg: 'creative.likes',
              icon: FavouriteIcon,
            }
          : null,
        placement && placement !== 'STORY'
          ? {
              id: 'commentCount',
              value: comments ? createShortNumber(comments) : '—',
              msg: 'creative.comments',
              icon: Comment01Icon,
            }
          : null,
        placement === 'STORY' || !placement
          ? {
              id: 'engagementRate',
              value: engagementRate ? createPercent(engagementRate) : '—',
              msg: 'creative.er',
              icon: Analytics03Icon,
            }
          : null,
        {
          id: 'shareCount',
          value: shares ? createShortNumber(shares) : '—',
          msg: 'creative.shares',
          icon: LinkForwardIcon,
        },
        placement && placement !== 'STORY'
          ? {
              id: 'savedCount',
              value: savedCount ? createShortNumber(savedCount) : '—',
              msg: 'creative.saved',
              icon: Bookmark01Icon,
            }
          : null,
        placement === 'STORY' || !placement
          ? {
              id: 'navigation',
              value: navigation ? createShortNumber(navigation) : '—',
              msg: 'creative.navigation',
              icon: Navigation01Icon,
            }
          : null,
      ]),
    ];
  } else if (platform === 'TIKTOK') {
    return [
      compact([
        {
          id: 'reach',
          value: reach ? createShortNumber(reach) : '—',
          msg: 'creative.reach',
          icon: Activity01Icon,
        },
        {
          id: 'views',
          value: views ? createShortNumber(views) : '—',
          msg: 'creative.views',
          icon: ViewIcon,
        },
        {
          id: 'awgWatchTime',
          value: awgWatchTime ? createNumber(awgWatchTime) : '—',
          msg: 'creative.avg_watch_time',
          icon: Clock01Icon,
        },
        !isProductSeeding && projectIsCompleted
          ? {
              id: 'mediaCost',
              value: mediaCost ? `$${mediaCost}` : '—',
              msg: 'creative.media_cost',
              icon: Dollar02Icon,
            }
          : null,
        !isProductSeeding && projectIsCompleted
          ? {
              id: 'cpe',
              value: cpe ? `$${cpe}` : '—',
              msg: 'creative.cpe',
              icon: CoinsDollarIcon,
            }
          : null,
        !isProductSeeding && projectIsCompleted
          ? {
              id: 'cpm',
              value: cpm ? `$${cpm}` : '—',
              msg: 'creative.cpm',
              icon: CoinsDollarIcon,
            }
          : null,
      ]),
      [
        {
          id: 'engagementRate',
          value: engagementRate ? createPercent(engagementRate) : '—',
          msg: 'creative.er',
          icon: Analytics03Icon,
        },
        {
          id: 'videoCompletionRate',
          value: videoCompletionRate ? createPercent(videoCompletionRate) : '—',
          msg: 'creative.video_completion_rate',
          icon: PlayCircle02Icon,
        },
        {
          id: 'likeCount',
          value: likes ? createShortNumber(likes) : '—',
          msg: 'creative.likes',
          icon: FavouriteIcon,
        },
        {
          id: 'commentCount',
          value: comments ? createShortNumber(comments) : '—',
          msg: 'creative.comments',
          icon: Comment01Icon,
        },
        {
          id: 'shareCount',
          value: shares ? createShortNumber(shares) : '—',
          msg: 'creative.shares',
          icon: LinkForwardIcon,
        },
      ],
    ];
  }

  return [];
};
