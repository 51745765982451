import React from 'react';
import { PieChart as PieChartGraph } from 'react-minimal-pie-chart';

import styles from './PieChart.css';

import Text from 'Atoms/Text/Text';

type ItemData = {
  title: string;
  value: number;
  valueText?: string;
  color: string;
};

interface Props {
  items: ItemData[];
  dataKey: string;
}

const PieChart: React.FC<Props> = (props) => {
  const { items } = props;

  if (!items) return null;

  const createList = (items: ItemData[]) => {
    return items.map((item) => {
      const { title, valueText, color } = item;

      return (
        <div key={valueText} className={styles.item}>
          <div className={styles.name}>
            <div className={styles.icon} style={{ backgroundColor: color }} />
            <Text text={title} data-test="pieChart:text:unknown" />
          </div>
          <Text
            weight="500"
            text={valueText}
            className={styles.value}
            data-test="pieChart:text:value"
          />
        </div>
      );
    });
  };

  const list = createList(items);

  const cells = items.map((item) => {
    const { title, value, color } = item;
    return {
      title,
      value,
      color,
    };
  });

  return (
    <div className={styles.root}>
      <div className={styles.chart}>
        <PieChartGraph viewBoxSize={[100, 100]} lineWidth={35} data={cells} />
      </div>
      <div className={styles.list}>{list}</div>
    </div>
  );
};

export default PieChart;
