/**
 * @generated SignedSource<<68a420d43823e75a576958f9f508bb9e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BcaPermissionState = "APPROVED" | "CANCELED" | "EXPIRED" | "INVALID" | "PENDING_APPROVAL" | "REVOKED" | "UNKNOWN";
export type RequestBcaPermissionInput = {
  brandInstagramId: string;
  clientMutationId?: string | null | undefined;
  id?: string | null | undefined;
  projectId: string;
};
export type RequestBcaPermissionMutation$variables = {
  input: RequestBcaPermissionInput;
};
export type RequestBcaPermissionMutation$data = {
  readonly requestBcaPermission: {
    readonly bcaRequest: {
      readonly permission: {
        readonly id: string;
        readonly state: BcaPermissionState;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type RequestBcaPermissionMutation = {
  response: RequestBcaPermissionMutation$data;
  variables: RequestBcaPermissionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "BcaPermission",
  "kind": "LinkedField",
  "name": "permission",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RequestBcaPermissionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RequestBcaPermissionPayload",
        "kind": "LinkedField",
        "name": "requestBcaPermission",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BcaRequest",
            "kind": "LinkedField",
            "name": "bcaRequest",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RequestBcaPermissionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RequestBcaPermissionPayload",
        "kind": "LinkedField",
        "name": "requestBcaPermission",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BcaRequest",
            "kind": "LinkedField",
            "name": "bcaRequest",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f59469e1efd0ee51d0f15e5addf18928",
    "id": null,
    "metadata": {},
    "name": "RequestBcaPermissionMutation",
    "operationKind": "mutation",
    "text": "mutation RequestBcaPermissionMutation(\n  $input: RequestBcaPermissionInput!\n) {\n  requestBcaPermission(input: $input) {\n    bcaRequest {\n      permission {\n        id\n        state\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8aef2c9232a5b782aecc49abcc4c1a0b";

export default node;
