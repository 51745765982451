import React from 'react';
import { graphql, useFragment } from 'react-relay';

import styles from './AdvertiserItem.pcss';

import { getOrganizationLink } from 'Util/links';
import { createSum } from 'Util/numberFormatter';
import { convertStringToDate } from 'Util/dateCreator';
import { AdvertiserItem_organization$key } from 'GraphTypes/AdvertiserItem_organization.graphql';
import Text from 'Components/ui/Text/Text';
import TextButton from 'Components/ui/TextButton/TextButton';
import SimpleLink from 'Components/SimpleLink';

interface Props {
  organization?: AdvertiserItem_organization$key;
}

const AdvertiserItem: React.FC<Props> = (props) => {
  const { organization } = props;

  const data = useFragment(
    graphql`
      fragment AdvertiserItem_organization on Organization {
        id
        name
        subscription {
          planId
          endsAt
        }
        currency
        paymentAccount {
          balance
        }
      }
    `,
    organization
  );

  const organizationId = data?.id || '';
  const name = data?.name;
  const planId = data?.subscription?.planId;
  const planIdText = planId ? `plan.admin_name.${planId.toLowerCase()}` : '';
  const planEndsAt = data?.subscription?.endsAt;
  const balance =
    data?.currency && data.paymentAccount?.balance
      ? createSum(data.paymentAccount?.balance, data?.currency)
      : '—';

  const link = getOrganizationLink({ organizationId });

  return (
    <div className={styles.root}>
      <SimpleLink href={link} target="_blank">
        <TextButton color="purple" text={name} />
      </SimpleLink>
      <Text msg={planIdText} />
      <Text text={planEndsAt ? convertStringToDate(planEndsAt) : '—'} />
      <Text text={balance} />
    </div>
  );
};

export default AdvertiserItem;
