import React, { Suspense, useCallback, useEffect, useState, useContext, useMemo } from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import track from 'react-tracking';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

import styles from './Brands.pcss';
import BrandsList from './BrandsList/BrandsList';
import BrandsLimit from './BrandsLimit/BrandsLimit';
import BuyBrandSlotsBanner from './BuyBrandSlotsBanner/BuyBrandSlotsBanner';

import updateBrand from 'Mutations/UpdateBrand.Mutation';
import { ExtendedPlanId, getPlans } from 'Page/advertiser/BillingPlans/data';
import colors from 'Styles/vars/colors.css';
import environment from 'Api/Environment';
import { BILLING_ROUTE, HELLO_EMAIL } from 'Constants/general';
import Limit from 'Molecules/Limit/Limit';
import Spinner from 'Atoms/Spinner/Spinner';
import {
  BrandsQuery as QueryType,
  SubscriptionInterval,
  PlanId,
  AddonId,
} from 'GraphTypes/BrandsQuery.graphql';
import Page from 'Templates/Page/Page';
import Text from 'Components/ui/Text/Text';
import Drawer from 'Components/ui/Drawer/Drawer';
import Button from 'Components/ui/Button/Button';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { amplitude } from 'Helpers/amplitude';

const BrandsQuery = graphql`
  query BrandsQuery($brandName: String) {
    ...BrandsList_brands @arguments(brandName: $brandName)
    brandCount: brands {
      totalCount
    }
    currentUser {
      admin
      organization {
        launchedCampaigns: campaigns(stages: [ACTIVE, COMPLETED]) {
          totalCount
        }
        counters {
          activeBrands
        }
        subscription {
          planId
          availableAddonIds
          addonItems {
            addon {
              id
              resourcesPerUnit
            }
            quantity
          }
          paused
          interval
          effectiveLimits {
            maxActiveBrands
          }
          baseLimits {
            maxActiveBrands
          }
        }
      }
    }
    brandCategories {
      id
      name
    }
  }
`;

const BrandsContainer: React.FC = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const [brandName, setTextQuery] = useState('');

  const [limitModal, setLimitModalStatus] = useState(false);
  const [activeCampaignsModal, setActiveCampaignsModalStatus] = useState(false);
  const [changePlanModal, setChangePlanModalStatus] = useState(false);
  const [filterLoading, setFilterLoading] = useState(true);
  const [brandForActivation, setBrandForActivation] = useState('');
  const [successActionTitle, setSuccessActionTitle] = useState('');

  const { openDrawer, closeDrawer } = useContext(DrawerContext);

  useEffect(() => {
    amplitude.sendEvent({
      id: '179',
      category: 'pageview',
      name: 'brands_dashboard',
    });
  }, []);

  const closeLimitModal = useCallback(() => {
    setLimitModalStatus(false);
  }, []);
  const openActiveCampaignModal = useCallback(() => {
    setActiveCampaignsModalStatus(true);
  }, []);
  const closeActiveCampaignModal = useCallback(() => {
    setActiveCampaignsModalStatus(false);
  }, []);
  const openChangePlanModal = useCallback(() => {
    setChangePlanModalStatus(true);
    amplitude.sendEvent({
      id: '139',
      category: 'brand_management_dashboard',
      name: 'change_plan',
    });
  }, []);
  const closeChangePlanModal = useCallback(() => {
    setChangePlanModalStatus(false);
  }, []);

  const handleFilterLoading = useCallback(() => {
    setFilterLoading(false);
  }, []);

  let brandsEl: JSX.Element;

  const preloader = (
    <div className={styles.preloaderContainer}>
      <Spinner color={colors.colorDark} className={styles.preloader} />
    </div>
  );

  const handleQueryChange = (newTextQuery: string) => {
    setTextQuery(newTextQuery);
  };

  const openDashboard = () => {
    navigate('/dashboard');
  };

  const openBillingPlans = () => {
    navigate('/billing/plans');
  };

  const openBillingAddons = () => {
    navigate(BILLING_ROUTE, { state: { addon: 'maxActiveBrands' } });
  };

  const getCurrentPlanData = (planId?: PlanId, interval?: SubscriptionInterval | null) => {
    const plans = getPlans({
      currency: 'USD',
      plansProps: {
        [planId as ExtendedPlanId]: {
          interval,
          addons: [],
        },
      },
    });
    return plans.find((plan) => plan.planId === planId);
  };

  const handleBuyAddonToAddNewBrand = () => {
    amplitude.sendEvent<420>({
      id: '420',
      category: 'brands',
      name: 'add_brand_with_addon_purchase',
    });
    setBrandForActivation('');
    setSuccessActionTitle('billing.addons.drawer.maxActiveBrands.success');
    openDrawer('buy-maxActiveBrands-addon');
  };

  const handleBuyAddonToActivateOldBrand = (brandId: string) => {
    setBrandForActivation(brandId);
    setSuccessActionTitle('brands.dashboard.limits.activate_brand');
    openDrawer('buy-maxActiveBrands-addon');
  };

  const handleActivationDone = () => {
    setBrandForActivation('');
  };

  const addonSuccessCallback = () => {
    if (brandForActivation) {
      updateBrand({ id: brandForActivation, active: true }, handleActivationDone);
      return;
    }
    openDrawer('edit-brand-modal-new');
  };

  return (
    <Page className={styles.root}>
      <div className={styles.container}>
        <header className={styles.header}>
          <Text type="d2" msg="brands.dashboard.title" data-test="brands:text:title" />
        </header>
        <aside className={styles.params}>
          <Suspense fallback={preloader}>
            <BrandsLimit
              brandName={brandName}
              onQueryChange={handleQueryChange}
              increaseLimit={openBillingAddons}
              changePlan={openChangePlanModal}
              onLoadCompleted={handleFilterLoading}
              onBuyAddonClick={handleBuyAddonToAddNewBrand}
            />
          </Suspense>
        </aside>
        <QueryRenderer<QueryType>
          environment={environment}
          query={BrandsQuery}
          variables={{ brandName }}
          render={({ props: queryProps }) => {
            if (!queryProps) return filterLoading ? null : preloader;
            const isAdmin = queryProps.currentUser?.admin;
            const organization = queryProps.currentUser?.organization;
            const activeCount = organization?.counters?.activeBrands || 0;
            const launchedCampaigns = organization?.launchedCampaigns?.totalCount || 0;
            const subscription = organization?.subscription;
            const totalCount = subscription?.effectiveLimits.maxActiveBrands || null;
            const planId = subscription?.planId || 'your';
            const interval = subscription?.interval;
            const defaultMaxActiveBrands = subscription?.baseLimits.maxActiveBrands;
            const availableAddonIds = subscription?.availableAddonIds;

            const isAddonAvailable = (addonKey: AddonId) => availableAddonIds?.includes(addonKey);

            const isLimitExcceded = totalCount && activeCount >= Number(totalCount);

            const brandCategories = queryProps.brandCategories;

            const isFreeUser = planId === 'FREE';
            const planNameVal = intl.formatMessage({
              id: `plan.name.${planId.toLowerCase()}`,
            });

            const currentPlanData = getCurrentPlanData(
              organization?.subscription?.planId,
              interval
            );

            return (
              <div className={styles.content}>
                <BrandsList
                  key={totalCount}
                  preloader={preloader}
                  brands={queryProps}
                  isAdmin={isAdmin}
                  brandName={brandName}
                  openActiveCampaignModal={openActiveCampaignModal}
                  canAddNewBrand={totalCount === null || activeCount < totalCount}
                  disabled={isFreeUser}
                  brandCategories={brandCategories}
                  onBuyAddonToActivate={handleBuyAddonToActivateOldBrand}
                />
                {launchedCampaigns > 1 && typeof defaultMaxActiveBrands === 'number' && (
                  <BuyBrandSlotsBanner onAddonBuyClick={handleBuyAddonToAddNewBrand} />
                )}
                <Drawer
                  opened={limitModal}
                  onClose={closeLimitModal}
                  rootKey="limit-modal"
                  className={styles.drawer}
                >
                  <div className={styles.drawerContainer}>
                    <div className={styles.drawerTitle}>
                      <Text
                        type="d2"
                        msg="brands.dashboard.limits.modal.title"
                        className={styles.drawerTitleText}
                        data-test="brands:text:title"
                      />
                      {totalCount !== null && (
                        <Text
                          type="md"
                          msg="brands.dashboard.limits.modal.description"
                          formatValues={{
                            email: <a href={`mailto: ${HELLO_EMAIL}`}>{HELLO_EMAIL}</a>,
                            activeCount: totalCount,
                            planId: planNameVal,
                          }}
                          data-test="brands:text:description"
                        />
                      )}
                    </div>
                    <Button
                      color="black"
                      msg="brands.dashboard.limits.modal.button"
                      onClick={openBillingPlans}
                      data-test="brands:button:button"
                    />
                  </div>
                </Drawer>
                <Limit
                  limitExcceded={!!isLimitExcceded}
                  type="maxActiveBrands"
                  totalCount={activeCount}
                  maxCount={totalCount}
                  addons={currentPlanData?.availableAddons || []}
                  defaultLimit={defaultMaxActiveBrands}
                  disabledManage={!isAddonAvailable('BRANDS') || totalCount === null}
                  subscriptionData={subscription}
                  successActionTitle={successActionTitle}
                  successCallback={addonSuccessCallback}
                />
              </div>
            );
          }}
        />
        <Drawer
          opened={activeCampaignsModal}
          onClose={closeActiveCampaignModal}
          rootKey={'active-campaign-modal'}
          className={styles.drawer}
        >
          <div className={styles.drawerContainer}>
            <div className={styles.drawerTitle}>
              <Text
                type="d2"
                msg="brands.dashboard.activate.modal.title"
                className={styles.drawerTitleText}
                data-test="brands:text:title"
              />
              <Text
                type="md"
                msg="brands.dashboard.activate.modal.description"
                data-test="brands:text:description"
              />
            </div>
            <Button
              color="black"
              msg="brands.dashboard.activate.modal.button"
              onClick={openDashboard}
              data-test="brands:button:button"
            />
          </div>
        </Drawer>
        <Drawer
          opened={changePlanModal}
          onClose={closeChangePlanModal}
          rootKey={'change-plan-modal'}
          className={styles.drawer}
        >
          <div className={styles.drawerContainer}>
            <div className={styles.drawerTitle}>
              <Text
                type="d2"
                msg="brands.dashboard.changePlan.modal.title"
                className={styles.drawerTitleText}
                data-test="brands:text:title"
              />
              <Text
                type="md"
                msg="brands.dashboard.changePlan.modal.description"
                data-test="brands:text:description"
              />
            </div>
            <Button
              color="black"
              msg="brands.dashboard.changePlan.modal.button"
              onClick={openBillingPlans}
              data-test="brands:button:button"
            />
          </div>
        </Drawer>
      </div>
    </Page>
  );
};

export default track({
  section: 'brands',
})(BrandsContainer);
