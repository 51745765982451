import React, { useState } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from 'relay-runtime';

import { amplitude } from 'Helpers/amplitude';
import ReportButton, { ReportType } from 'Atoms/ReportButton/ReportButton';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { TransactionReportQuery as QueryType } from 'GraphTypes/TransactionReportQuery.graphql';
import { IconName } from 'Components/ui/types';

const TransactionReportQuery = graphql`
  query TransactionReportQuery {
    currentUser {
      organization {
        id
      }
    }
  }
`;

const TransactionReport = () => {
  const { currentUser } = useLazyLoadQuery<QueryType>(TransactionReportQuery, {});
  const organizationId = currentUser?.organization?.id;

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');

  if (!organizationId) return null;

  const handleDownloadReportDone = () => {
    setLoading(false);
    setStatus('success');
  };
  const handleDownloadReportFailed = () => {
    setLoading(false);
    setStatus('failed');
  };

  const handleDownloadReportStart = () => {
    amplitude.sendEvent({
      id: 174,
      category: 'transaction_page',
      name: 'download_xclx_click',
    });
    setLoading(true);
  };

  let iconName = 'Download-upload';

  if (status === 'success') {
    iconName = 'Check';
  } else if (status === 'failed') {
    iconName = 'Warning-error';
  }

  return (
    <ReportButton
      type={ReportType.TRANSACTIONS}
      reportData={{ organizationId }}
      onDownload={handleDownloadReportDone}
      onDownloadError={handleDownloadReportFailed}
    >
      <AlterButton
        type="black"
        icon={iconName as IconName}
        disabled={!!status}
        msg="billing_history.transactions.download_report"
        loading={loading}
        onClick={handleDownloadReportStart}
        data-test="transactionReport:alterButton:downloadReport"
      />
    </ReportButton>
  );
};

export default TransactionReport;
