import { GMAIL_OAUTH } from 'Constants/general';
import ErrorHandler from 'Util/errorHandler';
import { useAuthenticateWithGmailMutation } from 'Api/mutations/AuthenticateWithGmail.Mutation';
import {
  GmailAuthDataResponse,
  GmailPostMessageData,
  GmailPostMessageTypeEnum,
  PostMessageEventHandler,
} from 'Page/advertiser/Campaign/Outreach/OutreachBatchesControls/AddGmailAccountButton/utils';

const gmailScope =
  'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/gmail.send';

type UseGmail = () => HookResult;
const useGmail: UseGmail = () => {
  const [authenticateWithGmail] = useAuthenticateWithGmailMutation();

  const authorize: HookResult['authorize'] = (id?: string) => {
    return new Promise<GmailAuthDataResponse | null>((resolve) => {
      const { origin } = window.location;
      const searchParams = new URLSearchParams({
        scope: gmailScope,
        redirect_uri: `${origin}${GMAIL_OAUTH}`,
        client_id: process.env.GMAIL_APP_ID || '',
        response_type: 'code',
        access_type: 'offline',
        prompt: 'consent',
      });
      const url = `https://accounts.google.com/o/oauth2/auth?${searchParams.toString()}`;
      const handler: PostMessageEventHandler = (e) => {
        const data: GmailPostMessageData = e.data;

        if (data.type === GmailPostMessageTypeEnum.Authenticate) {
          const params: GmailPostMessageData['params'] = e?.data?.params;

          const handleError = async (errorMessage?: string) => {
            ErrorHandler.error('Gmail authorize error occurred', {
              errorMessage,
              isParamExists: {
                code: Boolean(params.code),
              },
            });
          };

          window.removeEventListener('message', handler, false);
          if (e.data.error || !params.code) {
            handleError();
            resolve({ ...e.data, error: true });
          } else {
            authenticateWithGmail({
              variables: {
                input: {
                  code: params.code,
                  ...(id ? { id } : {}),
                },
              },
              onCompleted: (response) => {
                resolve({ data: response.authenticateWithGmail, error: false });
              },
              onError: () => {
                resolve({ error: true });
              },
            });
          }
        }
      };
      window.addEventListener('message', handler, false);

      const width = 800;
      const height = 600;
      const left = (window.screen.width - width) / 2;
      const top = (window.screen.height - height) / 2.5;
      const winOptions = `width=${width},height=${height},left=${left},top=${top}`;
      window.open(url, 'auth', winOptions);

      return;
    });
  };

  return {
    authorize,
  };
};

export { useGmail, GmailPostMessageTypeEnum };

// types

interface HookResult {
  authorize: (id?: string) => Promise<GmailAuthDataResponse | null>;
}
