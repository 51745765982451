import React, { useEffect, useRef, useState } from 'react';

import styles from './ProjectFilterLabel.pcss';

import { Props as TextProps } from 'Components/ui/Text/Text';
import Text from 'Components/ui/Text/Text';

interface Props {
  anchorProps: Partial<TextProps>;
  valueLength?: number;
}

const ProjectFilterLabel: React.FC<Props> = (props) => {
  const { anchorProps, valueLength = 0 } = props;

  const counterWithoutSpread = useRef(0);
  const labelContainerEl = useRef<HTMLDivElement | null>(null);
  const labelTextContainerEl = useRef<HTMLDivElement | null>(null);
  const [labelAdditionalCount, setLabelAdditionalCount] = useState(0);

  useEffect(() => {
    const labelEl = labelContainerEl.current;
    const labelTextEl = labelTextContainerEl.current;
    if (labelEl && labelTextEl) {
      const textWidth = labelTextEl.clientWidth;
      const containerWidth = labelEl.clientWidth;
      if (textWidth + 22 < containerWidth) {
        counterWithoutSpread.current = valueLength;
        setLabelAdditionalCount(0);
      } else {
        let visibleCounter = counterWithoutSpread.current;
        if (!counterWithoutSpread.current) {
          visibleCounter = 1; // just because it looks truthfully
        }
        setLabelAdditionalCount(valueLength - visibleCounter);
      }
    }
  }, [
    labelTextContainerEl.current,
    labelContainerEl.current,
    valueLength,
    counterWithoutSpread.current,
  ]);

  return (
    <div className={styles.labelValue} ref={labelContainerEl}>
      <div className={styles.labelValueText} ref={labelTextContainerEl}>
        <Text
          type="md"
          {...anchorProps}
          className={styles.label}
          data-test="projectFilterLabel:text:label"
        />
      </div>
      {labelAdditionalCount > 0 && (
        <Text
          type="md"
          text={`+${labelAdditionalCount}`}
          data-test="projectFilterLabel:text:unknown"
        />
      )}
    </div>
  );
};

export default ProjectFilterLabel;
