import React from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';

import styles from './CreatorAdminPanel.css';
import CreatorPastProjects from './CreatorPastProjects/CreatorPastProjects';
import PaidSocialError from './PaidSocialError/PaidSocialError';
import InstagramInsightsError from './InstagramInsightsError/InstagramInsightsError';
import ProfileSettings from './ProfileSettings/ProfileSettings';
import ModerationSettings from './ModerationSettings/ModerationSettings';

import Text from 'Atoms/Text/Text';
import Spinner from 'Atoms/Spinner/Spinner';
import environment from 'Api/Environment';
import { CreatorAdminPanelQuery as QueryType } from 'GraphTypes/CreatorAdminPanelQuery.graphql';
import { CreatorStatisticQuery$data } from 'GraphTypes/CreatorStatisticQuery.graphql';

const CreatorAdminPanelQuery = graphql`
  query CreatorAdminPanelQuery($creatorId: ID!, $currency: Currency) {
    creator(id: $creatorId) {
      ...PaidSocialError_creator
      ...InstagramInsightsError_creator
      ...ProfileSettings_creator
      ...ModerationSettings_creator
      ... on InstagramCreator {
        paidSocialRightsVerification {
          verifiedAt
        }
      }
    }
    ethnicities {
      id
      name
    }
    tags(currency: $currency) {
      id
      name
    }
    genders(currency: $currency) {
      id
      name
    }
    animals {
      id
      name
    }
    hairTypes {
      id
      name
    }
    bodyTypes {
      id
      name
    }
    languages {
      id
      name
    }
    categories(currency: USD) {
      id
      name
      description
    }
    settings {
      maxMandatoryCategoriesCount
    }
    currentUser {
      permissions
    }
  }
`;

interface Props {
  creatorId: string;
  currency: NonNullable<NonNullable<CreatorStatisticQuery$data['creator']>['profile']>['currency'];
}

const CreatorAdminPanel: React.FC<Props> = (props) => {
  const { creatorId, currency } = props;

  const data = useLazyLoadQuery<QueryType>(CreatorAdminPanelQuery, {
    creatorId,
    currency,
  });

  if (!data) return <Spinner className={styles.preloader} />;

  if (!data.creator) return null;

  const permissions = data.currentUser?.permissions || [];
  const canEdit = permissions?.includes('CREATOR_PROFILES__EDIT');
  const canViewCampaignsHistory = permissions?.includes('CREATOR_PROFILES__VIEW_CAMPAIGNS_HISTORY');

  const creator = data.creator;
  const ethnicities = data.ethnicities;
  const genders = data.genders;
  const tags = data.tags;
  const animals = data.animals;
  const hairTypes = data.hairTypes;
  const bodyTypes = data.bodyTypes;
  const languages = data.languages;
  const settings = data.settings;
  const categories = data.categories;

  return (
    <div>
      {canEdit && (
        <div className={styles.root}>
          <Text
            tag="h1"
            weight="700"
            msg="creator_statistic.settings.title"
            className={styles.title}
            data-test="creatorAdminPanel:text:title"
          />
          <ProfileSettings
            creator={creator}
            categories={categories}
            settings={settings}
            genders={genders}
          />
          <ModerationSettings
            creator={creator}
            ethnicities={ethnicities}
            tags={tags}
            animals={animals}
            hairTypes={hairTypes}
            bodyTypes={bodyTypes}
            languages={languages}
          />
          <PaidSocialError creator={creator} />
          <InstagramInsightsError creator={creator} />
        </div>
      )}
      {canViewCampaignsHistory && <CreatorPastProjects creatorId={creatorId} />}
    </div>
  );
};

export default CreatorAdminPanel;
