import React, { useContext } from 'react';
import classnames from 'classnames';

import { VideoType } from '../data';

import styles from './VideoItem.pcss';

import { CreateCampaignInput } from 'GraphTypes/CreateCampaignMutation.graphql';
import { amplitude } from 'Helpers/amplitude';
import MediaFile from 'Modal/advertiser/MediaFile/MediaFile';
import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import playImg from 'Images/general/play.png';
import play2xImg from 'Images/general/play2x.png';

interface Props {
  video: VideoType;
  loading: boolean;
  onCreateBriefClick: (data: CreateCampaignInput) => void;
}

const VideoItem: React.FC<Props> = (props) => {
  const { video, loading, onCreateBriefClick } = props;

  const { openDrawer } = useContext(DrawerContext);

  const handleCreateBriefClick = () => {
    amplitude.sendEvent<345>({
      id: '345',
      category: 'home',
      name: 'usecases_create_brief_click',
      param: { campaign_type: video.campaignType },
    });
    onCreateBriefClick(video.campaignData);
  };

  const handlePlayClick = () => {
    amplitude.sendEvent<346>({
      id: '346',
      category: 'home',
      name: 'usecases_video_click',
      param: { campaign_type: video.campaignType },
    });
    openDrawer(`media-file-${video.id}`);
  };

  return (
    <div key={video.id} className={classnames(styles.video, styles[video.color])}>
      <Icon name={video.icon} className={styles.icon} />
      <Text type="md" msg={video.title} data-test="videoItem:text:unknown" />
      <Text
        type="sm"
        msg={video.descr}
        className={styles.videoDescr}
        data-test="videoItem:text:videoDescr"
      />
      <div className={styles.file}>
        <img srcSet={video.poster} className={styles.poster} />
        <img
          srcSet={`${playImg} 1x, ${play2xImg} 2x`}
          onClick={handlePlayClick}
          className={styles.videoIcon}
        />
      </div>
      <AlterButton
        animateText
        msg="home.videos.create_brief"
        type="black"
        iconPosition="right"
        icon="Arrow-big-right"
        className={styles.cta}
        loading={loading}
        handleClick={handleCreateBriefClick}
        data-test="videoItem:alterButton:createBrief"
      />
      <MediaFile id={video.id} youtubeLink={video.link} />
    </div>
  );
};

export default VideoItem;
