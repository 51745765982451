import React, { useContext, useEffect, useState } from 'react';

import { FinishDealWrapperContext } from '../FinishDealWrapperContext';

import Modal from 'Components/ui/Modal/Modal';
import completeProject from 'Mutations/CompleteProject.Mutation';
import ErrorHandler from 'Util/errorHandler';
import createPublicationRequestMutation from 'Api/mutations/CreatePublicationRequest.Mutation';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { CampaignType } from 'GraphTypes/AcceptOfferMutation.graphql';
import { amplitude } from 'Helpers/amplitude';

interface Props {
  projectId: string;
  campaignType?: CampaignType | null;
}

const PublicationLinkRequestDrawer: React.FC<Props> = (props) => {
  const { projectId, campaignType } = props;

  const { openDrawer } = useContext(DrawerContext);
  const finishDealContext = useContext(FinishDealWrapperContext);

  const opened = Boolean(finishDealContext?.needPublicationDrawer);

  const changeDrawerVisible = finishDealContext?.setNeedPublicationDrawerStatus;

  const [loading, setLoading] = useState(false);
  const [hasError, setError] = useState(false);

  useEffect(() => {
    if (opened) {
      amplitude.sendEvent<498>({
        id: '498',
        category: 'publication_link_request',
        name: 'show',
        param: { project_id: projectId, campaign_type: campaignType },
      });
    }
  }, [opened]);

  const handleRequestClick = () => {
    setLoading(true);
    setError(false);
    amplitude.sendEvent<501>({
      id: '501',
      category: 'publication_link_request',
      name: 'link_click',
      param: { project_id: projectId, campaign_type: campaignType },
    });
    createPublicationRequestMutation({ projectId }, handleActionDone, handleActionFailed);
  };

  const handleActionDone = () => {
    setLoading(false);
    setError(false);
    if (!changeDrawerVisible) {
      ErrorHandler.error('No handler for closing need publication drawer');
      return;
    }
    amplitude.sendEvent<499>({
      id: '499',
      category: 'publication_link_request',
      name: 'close',
      param: { project_id: projectId, campaign_type: campaignType },
    });
    changeDrawerVisible(false);
  };

  const openRatingPopup = () => {
    setLoading(false);
    setError(false);
    openDrawer('project-creator-rating');
  };

  const handleActionFailed = () => {
    setLoading(false);
    setError(true);
  };

  const handleSendPayment = () => {
    setLoading(true);
    setError(false);

    amplitude.sendEvent<500>({
      id: '500',
      category: 'publication_link_request',
      name: 'complete_without_link_click',
      param: { project_id: projectId, campaign_type: campaignType },
    });

    completeProject({ projectId }, openRatingPopup, handleActionFailed);
  };

  return (
    <Modal
      drawerProps={{
        rootKey: `publication-link-request-${projectId}`,
        onClose: handleActionDone,
        opened,
      }}
      loading={loading}
      titleMsg="publication_link_request_drawer.title"
      descrMsg="publication_link_request_drawer.description"
      dismissBtnProps={{
        onClick: handleSendPayment,
        msg: 'publication_link_request_drawer.btn.send_payment',
      }}
      confirmBtnProps={{
        onClick: handleRequestClick,
        msg: 'publication_link_request_drawer.btn.request',
      }}
      iconName="Attach-link"
    />
  );
};

export default PublicationLinkRequestDrawer;
