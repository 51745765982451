import React, { useContext, useState } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

import styles from './PaidSocialLicensesLimit.pcss';

import Text from 'Components/ui/Text/Text';
import Button from 'Components/ui/Button/Button';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Drawer from 'Components/ui/Drawer/Drawer';
import { PaidSocialLicensesLimitQuery as QueryType } from 'GraphTypes/PaidSocialLicensesLimitQuery.graphql';
import { BILLING_ROUTE, PLANS_ROUTE } from 'Constants/general';
import { amplitude } from 'Helpers/amplitude';
import { FeatureFlagsContext } from 'Containers/FeatureFlags/FeatureFlags.Context';

const PaidSocialLicensesLimitQuery = graphql`
  query PaidSocialLicensesLimitQuery {
    currentUser {
      organization {
        counters {
          psLicensedCreators
          bcaLicensedCreators
        }
        subscription {
          planId
          effectiveLimits {
            maxLicensedCreators
            maxBcaConnections
          }
        }
      }
    }
  }
`;

const PaidSocialLicensesLimit: React.FC = () => {
  const [changePlanShown, setChangePlanShown] = useState(false);
  const { hasFeatureFlag } = useContext(FeatureFlagsContext);
  const isWhitelistingAvailable = hasFeatureFlag('whitelisting_available');

  const navigate = useNavigate();
  const intl = useIntl();

  const response = useLazyLoadQuery<QueryType>(PaidSocialLicensesLimitQuery, {});

  if (!response) return null;

  const organization = response?.currentUser?.organization;

  if (!organization) return null;

  const planId = organization.subscription?.planId;

  const psLicensedCreators = organization.counters?.psLicensedCreators;
  const maxLicensedCreators = organization.subscription?.effectiveLimits.maxLicensedCreators;

  const bcaLicensedCreators = organization.counters?.bcaLicensedCreators;
  const maxBcaConnections = organization.subscription?.effectiveLimits.maxBcaConnections;

  const changePlan = () => {
    setChangePlanShown(true);
  };

  const handleGetMoreClick = () => {
    amplitude.sendEvent({
      id: '140',
      category: 'creator_licensing_dashboard',
      name: 'get_more_license',
    });

    navigate(BILLING_ROUTE, {
      state: { addon: 'maxBcaConnections' },
    });
  };

  const closeChangePlanModal = () => {
    setChangePlanShown(false);
  };

  const openBillingPlans = () => {
    navigate(PLANS_ROUTE);
  };

  let count;

  if (maxLicensedCreators !== null) {
    count = {
      msg: 'dashboard.advertiser.licenses_section.limit.title',
      formatValues: {
        amount: psLicensedCreators,
        total: maxLicensedCreators,
      },
    };
  } else if (planId === 'FREE' || planId === 'UGC_2022_08') {
    count = { text: ' ' };
  } else {
    count = {
      msg: 'dashboard.advertiser.licenses_section.limit.unlimited',
    };
  }

  let actionButton;

  const getMoreButtonType =
    Number(bcaLicensedCreators) >= Number(maxBcaConnections) ? 'black' : 'white';

  if (planId === 'FREE' || planId === 'UGC_2022_08') {
    actionButton = (
      <AlterButton
        msg="brands.dashboard.limits.changePlan"
        type="black"
        onClick={changePlan}
        className={styles.actionBtn}
        data-test="paidSocialLicensesLimit:alterButton:changeplan"
      />
    );
  } else if (maxLicensedCreators !== null && maxBcaConnections !== null) {
    actionButton = (
      <AlterButton
        type={getMoreButtonType}
        msg="dashboard.advertiser.licenses_section.limit.get_more"
        onClick={handleGetMoreClick}
        className={styles.actionBtn}
        data-test="paidSocialLicensesLimit:alterButton:getMore"
      />
    );
  }

  const planName = intl.formatMessage({
    id: `plan.name.${planId?.toLowerCase()}`,
  });

  return (
    <div className={styles.root}>
      <div className={styles.counters}>
        {isWhitelistingAvailable && (
          <Text
            type="md"
            {...count}
            className={styles.count}
            data-test="paidSocialLicensesLimit:text:count"
          />
        )}
        <Text
          type="md"
          msg={
            maxBcaConnections === null
              ? 'dashboard.advertiser.bca_licenses_section.withoutlimit.title'
              : 'dashboard.advertiser.bca_licenses_section.limit.title'
          }
          formatValues={{ amount: String(bcaLicensedCreators), total: String(maxBcaConnections) }}
          className={styles.count}
          data-test="paidSocialLicensesLimit:text:count"
        />
      </div>
      {actionButton}
      <Drawer
        opened={changePlanShown}
        handleClose={closeChangePlanModal}
        rootKey={'change-plan-modal'}
        className={styles.drawer}
      >
        <div className={styles.drawerContainer}>
          <div className={styles.drawerTitle}>
            <Text
              type="d2"
              msg="increase_ps_licenses_modal.title"
              className={styles.drawerTitleText}
              data-test="paidSocialLicensesLimit:text:title"
            />
            <Text
              type="md"
              formatValues={{ planName }}
              msg="increase_ps_licenses_modal.description"
              data-test="paidSocialLicensesLimit:text:description"
            />
          </div>
          <Button
            color="black"
            msg="increase_ps_licenses_modal.button"
            onClick={openBillingPlans}
            data-test="paidSocialLicensesLimit:button:button"
          />
        </div>
      </Drawer>
    </div>
  );
};

export default PaidSocialLicensesLimit;
