/**
 * @generated SignedSource<<cbaffba53d1da6b3cf99c6bd0875d626>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING";
export type PaymentType = "BARTER" | "MONEY";
import { FragmentRefs } from "relay-runtime";
export type ContentCreationAdvertiserSide_project$data = {
  readonly campaign: {
    readonly id: string;
    readonly paymentType: PaymentType | null | undefined;
    readonly publishingRequired: boolean;
    readonly type: CampaignType | null | undefined;
  };
  readonly deadline: {
    readonly draftDate: any | null | undefined;
    readonly publicationDate: any | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"DealActions_project">;
  readonly " $fragmentType": "ContentCreationAdvertiserSide_project";
};
export type ContentCreationAdvertiserSide_project$key = {
  readonly " $data"?: ContentCreationAdvertiserSide_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentCreationAdvertiserSide_project">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentCreationAdvertiserSide_project",
  "selections": [
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DealActions_project"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Deadline",
      "kind": "LinkedField",
      "name": "deadline",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "draftDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "publicationDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "publishingRequired",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};
})();

(node as any).hash = "91e62d9fb8b96aa1bc2e5a51cc5bc3e9";

export default node;
