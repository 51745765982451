import React from 'react';
import classnames from 'classnames';

import styles from './MenuItem.css';

import Text from 'Atoms/Text/Text';
import Checkbox from 'Atoms/Checkbox/Checkbox';
import Avatar from 'Atoms/Avatar/Avatar';
import RadioButton from 'Atoms/RadioButton/RadioButton';

const MenuItem = (props) => {
  const {
    item: { msg, name, fullName, avaData, className, forceActiveState },
    item,
    icon,
    isActive: activeState,
    inGroup,
    isSingle,
    hasCheckbox,
    hasActiveIcon,
    onChange,
  } = props;

  const handleChange = () => {
    onChange({ ...item });
  };

  const isActive = forceActiveState !== undefined ? forceActiveState : activeState;

  const textData = msg ? { msg } : { text: name };
  const active = isActive ? styles.active : '';
  const hasSecondaryText = !!fullName;

  return (
    <div
      className={classnames(styles.root, className, active, { [styles.inGroup]: inGroup })}
      onClick={handleChange}
    >
      {hasCheckbox && (
        <div className={styles.checkbox}>
          {isSingle ? <RadioButton active={!!isActive} /> : <Checkbox active={!!isActive} />}
        </div>
      )}
      {icon && <div className={styles.icon}>{icon}</div>}
      {avaData && <Avatar {...avaData} className={styles.ava} />}
      <Text
        overflow={true}
        {...textData}
        className={classnames(styles.text, { [styles.hasSecondaryText]: hasSecondaryText })}
      />
      {hasSecondaryText && <Text color="grayDog" text={`, ${fullName}`} className={styles.text} />}
      {hasActiveIcon && <div className={styles.activeIcon} />}
    </div>
  );
};

export default MenuItem;
