import React from 'react';
import { HugeiconsIcon } from '@hugeicons/react';
import { InformationCircleIcon } from '@hugeicons-pro/core-stroke-rounded';

import styles from './PerformanceIndicator.pcss';

import { OverviewResultItemType } from '../utils';

import Text from 'Components/ui/Text/Text';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import Tag from 'Components/ui/Tag/Tag';

interface Props {
  id: string;
  campaignId: string;
  data?: OverviewResultItemType | null;
}

const PerformanceIndicator: React.FC<Props> = (props) => {
  const data = props.data;

  if (!data) return null;

  const campaignId = props.campaignId;
  const id = props.id;
  const value = data.value || '-';
  const msg = data.msg || '-';
  const tooltipMsg = data.tooltipMsg;
  const icon = data.icon;
  const tags = data.tags;

  return (
    <div className={styles.root}>
      <div>
        <Text text={value} className={styles.value} />
        <div className={styles.subtitleWrap}>
          <Text msg={msg} />
          <Tooltip
            id={id}
            tooltipMsg={tooltipMsg}
            place="top"
            eventData={{
              id: 526,
              category: 'campaign_overview',
              name: 'performance_indicator_tooltip_show',
              param: { campaign_id: campaignId, indicator: id },
            }}
          >
            <HugeiconsIcon icon={InformationCircleIcon} size={20} color="#63696A" />
          </Tooltip>
        </div>
      </div>
      {icon && <HugeiconsIcon icon={icon} size={28} color="#017989" />}
      {tags && tags?.length > 0 && (
        <div className={styles.tags}>
          {tags.map((tag) => {
            return <Tag key={tag} msg={tag} needMargin={false} className={styles.tag} />;
          })}
        </div>
      )}
    </div>
  );
};

export default PerformanceIndicator;
