import createRelaySubscriptionHandler from 'graphql-ruby-client/subscriptions/createRelaySubscriptionHandler';
import { createConsumer } from '@anycable/web';
import { LongPollingTransport } from '@anycable/long-polling';
import {
  Environment,
  Network,
  RecordSource,
  Store,
  RequestParameters,
  Variables,
  CacheConfig,
} from 'relay-runtime';

import { isCypress } from 'Util/isCypress';
import ls from 'Util/localStorage';
import ErrorHandler from 'Util/errorHandler';
import { firebaseClient } from 'Containers/Auth0/Auth0Context/utils';

const graphqlEndpoint = process.env.GRAPHQL_ENDPOINT || '';
const graphqlSubscriptionsEndpoint = process.env.GRAPHQL_SUBSCRIPTIONS_ENDPOINT || '';
const graphqlSubscriptionsLongPollingEndpoint =
  process.env.GRAPHQL_SUBSCRIPTIONS_LONG_POLLING_ENDPOINT || '';
const CLIENT_VERSION = process.env.CLIENT_VERSION;

const store = new Store(new RecordSource());
let wsConnectionState = false;

const getAccessToken = async () => {
  /* Need to remove at next future */
  const oldToken = localStorage.getItem('access-token');
  if (oldToken) {
    localStorage.removeItem('access-token');
  }
  /* */
  return await firebaseClient.getToken();
};

const getHeaders = async () => {
  const clientName = isCypress() ? 'cypress' : 'web';
  const headers: Record<string, string> = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Accept-Language': `${ls.get('locale')}`,
    'apollographql-client-name': clientName,
  };

  if (CLIENT_VERSION) {
    headers['apollographql-client-version'] = CLIENT_VERSION;
  }

  const token = await getAccessToken();

  if (token) {
    headers.authorization = `Bearer ${token}`;
  }

  return headers;
};

const fetchOperation = async (
  operation: RequestParameters,
  variables: Variables,
  cacheConfig: CacheConfig,
  retryCounter = 0
): Promise<Response> => {
  const headers = await getHeaders();
  return fetch(graphqlEndpoint, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      query: operation.text,
      variables,
      operationName: operation.name,
    }),
    signal: cacheConfig?.metadata?.signal as AbortSignal | undefined,
  })
    .then(async (response) => {
      if (response.status === 401) {
        if (retryCounter >= 3) {
          firebaseClient.authClient.signOut();
        } else {
          if (retryCounter === 0) {
            await firebaseClient.getToken(true);
          }
          const nextRetryCounter = retryCounter + 1;
          return fetchOperation(operation, variables, cacheConfig, nextRetryCounter);
        }
      } else if (response.status === 429) {
        return Promise.reject({
          status: response.status,
          message: 'Too many requests',
        });
      } else if (response.status === 500) {
        ErrorHandler.error('Server returned response with 500 error', {
          operation,
          variables,
        });
        return Promise.reject({
          status: response.status,
          message: 'Server error',
        });
      } else if (response.status === 400) {
        ErrorHandler.error('Server returned response with 400 error', {
          operation,
          variables,
        });
        return Promise.reject({
          status: response.status,
          message: 'Client error',
        });
      }
      return response;
    })
    .catch(async (e) => {
      return Promise.reject({
        ...e,
      });
    });
};

export const fetchQuery = (
  operation: RequestParameters,
  variables: Variables,
  cacheConfig: CacheConfig
) => {
  return fetchOperation(operation, variables, cacheConfig).then((response) => {
    return response.json();
  });
};

const lp = new LongPollingTransport(graphqlSubscriptionsLongPollingEndpoint);
const consumer = createConsumer(graphqlSubscriptionsEndpoint, { fallbacks: [lp] });

const relaySubscriptionHandler = createRelaySubscriptionHandler({
  cable: consumer,
});

const subscriptionHandler = (operation: RequestParameters, variables: Variables) => {
  getAccessToken().then((token) => {
    consumer.cable.transport.setParam('access_token', token as string);
  });

  consumer.cable.transport.on('open', () => {
    wsConnectionState = true;
  });

  consumer.cable.transport.on('close', () => {
    wsConnectionState = false;
  });

  consumer.cable.transport.on('error', (error: Error) => {
    if (!wsConnectionState || error.message === 'WS Error') return;
    ErrorHandler.error('Socket transport got error', { error }, { type: 'socket' });
  });
  return relaySubscriptionHandler(operation, variables);
};

const network = Network.create(fetchQuery, subscriptionHandler);

const environment = new Environment({
  network,
  store,
});

export const unsubscribeSocketConnection = () => {
  try {
    if (!wsConnectionState) return;
    consumer.cable.disconnect();
  } catch (e) {
    console.log(e);
  }
};

export default environment;
