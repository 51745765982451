import React from 'react';
import cn from 'classnames';
import compact from 'lodash/compact';

import styles from './BillingPlans.pcss';
import planElStyles from './Plans/Plan/Plan.pcss';
import TextWithTaxInfoTooltip from './TextWithTaxInfoTooltip/TextWithTaxInfoTooltip';
import type { CreatorCostListProps } from './CreatorCostList/CreatorCostList';
import { BillingPlansPropList } from './BillingPlans';

import Text, { Props as TextProps } from 'Components/ui/Text/Text';
import {
  FREE_PLAN,
  PREMIUM_PLAN,
  GROW_PLAN,
  SCALE_PLAN,
  SCALE_2021_06_PLAN,
  SCALE_2021_10_PLAN,
  TRIAL_MONTH_PLAN,
  ADVANCED_2021_10_PLAN,
  TRIAL_2022_08_PLAN,
  UGC_2022_08_PLAN,
  UGC_ADS_2022_08_PLAN,
  ADVANCED_2022_11_PLAN,
  CURRENT_PLANS,
  PLATFORM_CREATOR_LICENSING,
  TIKTOK_SPARK_ADS,
  TERMS_OF_SERVICE,
  BRAND_2024_03_PLAN,
  AGENCY_2024_03_PLAN,
} from 'Constants/general';
import { Currency, PlanId, BillingPlansQuery$data } from 'GraphTypes/BillingPlansQuery.graphql';
import { SubscriptionInterval as SubscriptionIntervalType } from 'Types/enums';
import { TooltipType, ButtonType } from 'Types/common';
import Icon from 'Atoms/Icon/Icon';
import { AddonId } from 'GraphTypes/PreviewProrationMutation.graphql';

export type AvailableDiscountType = NonNullable<
  NonNullable<NonNullable<BillingPlansQuery$data['currentUser']>['organization']>['subscription']
>['activeDiscount'];

type IsMonthlyPlan = (planId: string | undefined) => boolean;
export const isMonthlyPlan: IsMonthlyPlan = (planId) => {
  if (planId === undefined) return false;
  const monthlyPlans = new Set([TRIAL_MONTH_PLAN, TRIAL_2022_08_PLAN]);
  return monthlyPlans.has(planId);
};

export const getIntervalValue = (interval?: string | null) => {
  if (interval === SubscriptionIntervalType.YEARLY) {
    return 12;
  } else if (interval === SubscriptionIntervalType.TRIMONTHLY) {
    return 3;
  }
  return 1;
};

export const getIntervalNameValue = (interval?: number) => {
  if (interval === 12) {
    return SubscriptionIntervalType.YEARLY;
  } else if (interval === 3) {
    return SubscriptionIntervalType.TRIMONTHLY;
  }

  return SubscriptionIntervalType.MONTHLY;
};

type PlanGeneratorType = {
  currentPlan?: PlanId;
};

export type BillingDefaultPlans = {
  oldItem?: PlanId;
  plansForCompare: PlanId[];
  planslist: PlanId[];
};

export const getDefaultPlans = ({ currentPlan }: PlanGeneratorType): BillingDefaultPlans => {
  const plansForCompare: PlanId[] = [TRIAL_2022_08_PLAN, BRAND_2024_03_PLAN, AGENCY_2024_03_PLAN];
  const oldItem = currentPlan && !CURRENT_PLANS.includes(currentPlan) ? currentPlan : undefined;
  const planslist: PlanId[] = compact([
    TRIAL_2022_08_PLAN,
    BRAND_2024_03_PLAN,
    AGENCY_2024_03_PLAN,
  ]);
  return { oldItem, planslist, plansForCompare };
};

type Data = {
  currency: Currency;
  plansProps: BillingPlansPropList;
  trialMonthPlanAvailable?: boolean;
  planStyles?: { [key: string]: string };
  availableDiscount?: AvailableDiscountType;
  currentPlanId?: PlanId;
  hasNewCases?: boolean;
};

export type ExtendedPlanId = PlanId | 'ACTIVATION_FEE' | 'ADVANCED_DEMO';

export type PlanType = {
  planName?: string;
  planId: ExtendedPlanId;
  price?: number;
  title?: string;
  subtitle?: string;
  descr?: string;
  pricePer?: string;
  priceElement?: JSX.Element;
  showSummaryPrice?: boolean;
  btnColor?: string;
  btnMsg?: string;
  btnValues?: object;
  shortBenefitsList?: {
    title?: string;
    values?: any;
    additionalTitle?: string;
    el?: JSX.Element;
    tooltipProps?: {
      tooltipMsg?: string;
      id?: TooltipType['id'];
      tooltipValues?: TooltipType['tooltipValues'];
      getContent?: TooltipType['getContent'];
      clickable?: TooltipType['clickable'];
    };
  }[];
  creatorCostList?: NonNullable<CreatorCostListProps['items']>;
  features?: { title: string; textStyles?: object; tooltipMsg?: string }[];
  marketplace?: {
    title?: string;
    titleText?: string;
    tooltipMsg?: string;
    tooltipValues?: object;
  }[];
  support?: { title: string; tooltipMsg?: string }[];
  paymentMethod?: {
    title: string;
    values?: object;
    tooltipValues?: object;
    tooltipMsg?: string;
  }[];
  limits?: {
    title: string;
    values?: object;
    tooltipValues?: object;
    tooltipMsg?: string;
  }[];
  priceDescr?: JSX.Element | null;
  disabledData?: {
    isDisabled: boolean;
    tooltipData?: TooltipType;
    buttonData?: ButtonType;
  };
  colorTheme?: 'default' | 'sky' | 'pink' | 'dark' | 'sun' | 'ocean';
  planFeatures?: PlanFeatureValues;
  summaryPriceRemarkMsg?: string;
  disabledChangeInterval?: boolean;
  withReferButton?: boolean;
  availableAddons?: AddonSettings[];
};

export type AddonSettings = {
  minValue: number;
  maxValue: number;
  defaultValue: number;
  currentValue?: number;
  label: string;
  key: AvailableAddonId;
  value?: number;
  price: number;
  stepCount?: number;
};

type PlanFeatureTextValue = (msg: string, values?: TextProps['formatValues']) => React.ReactNode;
const planFeatureTextValue: PlanFeatureTextValue = (msg, values) => {
  return (
    <div className={styles.planFeatureTextValue}>
      <Text type="h2" msg={msg} formatValues={values} data-test="data:text:unknown" />
    </div>
  );
};

export const getPlans = (data: Data): PlanType[] => {
  const { plansProps, planStyles, trialMonthPlanAvailable } = data;

  const isAnnually = (planId: ExtendedPlanId) =>
    plansProps?.[planId]?.interval === SubscriptionIntervalType.YEARLY;

  return [
    {
      planName: TRIAL_2022_08_PLAN,
      planId: TRIAL_2022_08_PLAN,
      price: isAnnually(TRIAL_2022_08_PLAN) ? 550 : 550,
      priceElement: (
        <div className={styles.priceColumnWrapper}>
          <div className={styles.priceEl}>
            <Text
              type="d1"
              text="$550"
              className={cn(planStyles?.priceText, planStyles?.default)}
              data-test="data:text:550"
            />
          </div>
          <TextWithTaxInfoTooltip tooltipInfoProps={{ id: 'TRIAL_2022_08_PLAN.tax.tooltip' }}>
            <Text
              type="h2"
              msg={'billing_plans.plans.one.month'}
              className={planStyles?.periodText}
              data-test="data:text:unknown"
            />
          </TextWithTaxInfoTooltip>
        </div>
      ),
      pricePer: 'billing_plans.plan.trial_2022_08.content_descr',
      showSummaryPrice: false,
      title: 'billing_plans.plan.trial_2022_08.title',
      subtitle: 'billing_plans.plan.trial_2022_08.subtitle',
      descr: 'billing_plans.plan.trial_2022_08.descr',
      summaryPriceRemarkMsg: undefined,
      colorTheme: 'default',
      btnMsg: '',
      disabledChangeInterval: true,
      disabledData: trialMonthPlanAvailable
        ? undefined
        : {
            isDisabled: true,
            tooltipData: {
              id: 'trial_disabled',
              tooltipMsg: 'billing_plans.plan.trial_month.not_avaiable',
              place: 'bottom',
              tooltipClassName: planElStyles.planTooltip,
            },
          },
      shortBenefitsList: [
        { title: 'billing_plans.plan.trial_2022_08.benefit.1' },
        { title: 'billing_plans.plan.trial_2022_08.benefit.hire_10' },
      ],
      creatorCostList: [
        {
          title: 'billing_plans.plan.trial_2022_08.creator_cost.1',
          tooltipProps: {
            id: 'billing_plans.plan.trial_2022_08.creator_cost.1',
            tooltipMsg: 'billing_plans.plan.trial_2022_08.creator_cost.1.tooltip',
            tooltipClassName: planElStyles.planTooltip,
          },
        },
        {
          title: 'billing_plans.plan.trial_2022_08.creator_cost.2',
        },
      ],
      priceDescr: null,
      features: [],
      marketplace: [],
      support: [],
      paymentMethod: [],
      limits: [],
      availableAddons: [
        {
          minValue: 1,
          maxValue: 1,
          defaultValue: 1,
          label: 'billing_plans.addons.user_seats',
          key: 'SEATS',
          price: 30,
        },
        {
          minValue: 1,
          maxValue: 1,
          defaultValue: 1,
          label: 'billing_plans.addons.brands.onlyone',
          key: 'BRANDS',
          price: 100,
        },
        {
          minValue: 1,
          maxValue: 1,
          defaultValue: 1,
          label: 'billing_plans.addons.licenses.onlyone',
          key: 'WHITELISTING_LICENSES',
          stepCount: 5,
          price: 25,
        },
      ],
      planFeatures: {
        useCases: {
          justContent: <Icon color="dark100" name="check" />,
          organicPosting: <Icon color="dark100" name="check" />,
          partnershipAds: <Icon color="dark100" name="check" />,
          ttsparkAds: <Icon color="dark100" name="check" />,
          productSeeding: <Icon color="dark100" name="close" />,
          ugc: <Icon color="dark100" name="check" />,
          influencerPosts: <Icon color="dark100" name="check" />,
          metaAds: <Icon color="dark100" name="check" />,
          affiliate: <Icon color="dark100" name="close" />,
          tiktokShop: <Icon color="dark100" name="close" />,
        },
        limitations: {
          creators: planFeatureTextValue('billing_plans.plan_features.values.up_to', {
            integer: 10,
          }),
          userSeats: 1,
          creatorLicensing: 1,
          brandsPerAccount: 1,
        },
        integrations: {
          meta: <Icon color="dark100" name="check" />,
          tiktok: <Icon color="dark100" name="check" />,
          shopify: <Icon color="dark100" name="check" />,
          stripe: <Icon color="dark100" name="check" />,
        },
        platformFeatures: {
          creatorMarketplace: <Icon color="dark100" name="check" />,
          tikTokSparkAds: <Icon color="dark100" name="check" />,
          audienceInsights: <Icon color="dark100" name="check" />,
          directChatWithCreators: <Icon color="dark100" name="check" />,
          creativeBriefTemplates: <Icon color="dark100" name="check" />,
          contentLibrary: <Icon color="dark100" name="check" />,
          automatedContractingAndPayments: <Icon color="dark100" name="check" />,
          connectionToFacebookAndTikTokAdsManager: <Icon color="dark100" name="check" />,
          multiUserAccountPermissions: <Icon color="dark100" name="check" />,
          contentRights: <Icon color="dark100" name="check" />,
        },
        customerSupport: {
          chatSupport: <Icon color="dark100" name="check" />,
          preLaunchFeedbackAndConsultancy: null,
          customerSuccessManager: null,
        },
        marketplaceFee: {
          marketplaceFee: planFeatureTextValue('billing_plans.plan_features.values.percent', {
            percent: 20,
          }),
        },
      },
    },
    {
      planId: FREE_PLAN,
      availableAddons: [
        {
          minValue: 1,
          maxValue: 1,
          defaultValue: 1,
          label: 'billing_plans.addons.user_seats',
          key: 'SEATS',
          price: 30,
        },
        {
          minValue: 1,
          maxValue: 1,
          defaultValue: 1,
          label: 'billing_plans.addons.brands.onlyone',
          key: 'BRANDS',
          price: 100,
        },
        {
          minValue: 0,
          maxValue: 0,
          defaultValue: 0,
          label: 'billing_plans.addons.licenses.onlyone',
          key: 'WHITELISTING_LICENSES',
          stepCount: 5,
          price: 25,
        },
      ],
    },
    {
      planId: PREMIUM_PLAN,
      availableAddons: [
        {
          minValue: 1,
          maxValue: Infinity,
          defaultValue: 1,
          label: 'billing_plans.addons.user_seats',
          key: 'SEATS',
          price: 30,
        },
        {
          minValue: 15,
          maxValue: Infinity,
          defaultValue: 15,
          label: 'billing_plans.addons.brands.onlyone',
          key: 'BRANDS',
          price: 100,
        },
        {
          minValue: 1,
          maxValue: Infinity,
          defaultValue: 10,
          label: 'billing_plans.addons.licenses.onlyone',
          key: 'WHITELISTING_LICENSES',
          stepCount: 5,
          price: 25,
        },
      ],
    },
    {
      planId: GROW_PLAN,
      availableAddons: [
        {
          minValue: 1,
          maxValue: Infinity,
          defaultValue: 1,
          label: 'billing_plans.addons.user_seats',
          key: 'SEATS',
          price: 30,
        },
        {
          minValue: 1,
          maxValue: Infinity,
          defaultValue: 1,
          label: 'billing_plans.addons.brands.onlyone',
          key: 'BRANDS',
          price: 100,
        },
        {
          minValue: 1,
          maxValue: 10,
          defaultValue: 10,
          label: 'billing_plans.addons.licenses.onlyone',
          key: 'WHITELISTING_LICENSES',
          stepCount: 5,
          price: 25,
        },
      ],
    },
    {
      planId: SCALE_PLAN,
      availableAddons: [
        {
          minValue: 1,
          maxValue: Infinity,
          defaultValue: 1,
          label: 'billing_plans.addons.user_seats',
          key: 'SEATS',
          price: 30,
        },
        {
          minValue: 1,
          maxValue: Infinity,
          defaultValue: 1,
          label: 'billing_plans.addons.brands.onlyone',
          key: 'BRANDS',
          price: 100,
        },
        {
          minValue: 1,
          maxValue: 30,
          defaultValue: 30,
          label: 'billing_plans.addons.licenses.onlyone',
          key: 'WHITELISTING_LICENSES',
          stepCount: 5,
          price: 25,
        },
      ],
    },
    {
      planId: SCALE_2021_06_PLAN,
      availableAddons: [
        {
          minValue: 1,
          maxValue: 3,
          defaultValue: 3,
          label: 'billing_plans.addons.user_seats',
          key: 'SEATS',
          price: 30,
        },
        {
          minValue: 1,
          maxValue: 5,
          defaultValue: 1,
          label: 'billing_plans.addons.brands.onlyone',
          key: 'BRANDS',
          price: 100,
        },
        {
          minValue: 1,
          maxValue: 30,
          defaultValue: 30,
          label: 'billing_plans.addons.licenses.onlyone',
          key: 'WHITELISTING_LICENSES',
          stepCount: 5,
          price: 25,
        },
      ],
    },
    {
      planId: SCALE_2021_10_PLAN,
      availableAddons: [
        {
          minValue: 1,
          maxValue: Infinity,
          defaultValue: Infinity,
          label: 'billing_plans.addons.user_seats',
          key: 'SEATS',
          price: 30,
        },
        {
          minValue: 1,
          maxValue: 3,
          defaultValue: 3,
          label: 'billing_plans.addons.brands.onlyone',
          key: 'BRANDS',
          price: 100,
        },
        {
          minValue: 1,
          maxValue: Infinity,
          defaultValue: Infinity,
          label: 'billing_plans.addons.licenses.onlyone',
          key: 'WHITELISTING_LICENSES',
          stepCount: 5,
          price: 25,
        },
      ],
    },
    {
      planId: ADVANCED_2021_10_PLAN,
      availableAddons: [
        {
          minValue: 1,
          maxValue: Infinity,
          defaultValue: Infinity,
          label: 'billing_plans.addons.user_seats',
          key: 'SEATS',
          price: 30,
        },
        {
          minValue: 1,
          maxValue: Infinity,
          defaultValue: Infinity,
          label: 'billing_plans.addons.brands',
          key: 'BRANDS',
          price: 100,
        },
        {
          minValue: 1,
          maxValue: Infinity,
          defaultValue: Infinity,
          label: 'billing_plans.addons.licenses',
          key: 'WHITELISTING_LICENSES',
          stepCount: 5,
          price: 25,
        },
      ],
    },
    {
      planId: UGC_2022_08_PLAN,
      availableAddons: [
        {
          minValue: 1,
          maxValue: Infinity,
          defaultValue: 1,
          label: 'billing_plans.addons.user_seats',
          key: 'SEATS',
          price: 30,
        },
        {
          minValue: 1,
          maxValue: 3,
          defaultValue: 3,
          label: 'billing_plans.addons.brands.onlyone',
          key: 'BRANDS',
          price: 100,
        },
        {
          minValue: 0,
          maxValue: 0,
          defaultValue: 0,
          label: 'billing_plans.addons.licenses.onlyone',
          key: 'WHITELISTING_LICENSES',
          stepCount: 5,
          price: 25,
        },
      ],
    },
    {
      planId: UGC_ADS_2022_08_PLAN,
      availableAddons: [
        {
          minValue: 1,
          maxValue: Infinity,
          defaultValue: Infinity,
          label: 'billing_plans.addons.user_seats',
          key: 'SEATS',
          price: 30,
        },
        {
          minValue: 1,
          maxValue: 3,
          defaultValue: 3,
          label: 'billing_plans.addons.brands.onlyone',
          key: 'BRANDS',
          price: 100,
        },
        {
          minValue: 1,
          maxValue: 5,
          defaultValue: 5,
          label: 'billing_plans.addons.licenses.onlyone',
          key: 'WHITELISTING_LICENSES',
          stepCount: 5,
          price: 25,
        },
      ],
    },
    {
      planId: ADVANCED_2022_11_PLAN,
      availableAddons: [
        {
          minValue: 1,
          maxValue: Infinity,
          defaultValue: Infinity,
          label: 'billing_plans.addons.user_seats',
          key: 'SEATS',
          price: 30,
        },
        {
          minValue: 1,
          maxValue: Infinity,
          defaultValue: Infinity,
          label: 'billing_plans.addons.brands.onlyone',
          key: 'BRANDS',
          price: 100,
        },
        {
          minValue: 1,
          maxValue: Infinity,
          defaultValue: Infinity,
          label: 'billing_plans.addons.licenses.onlyone',
          key: 'WHITELISTING_LICENSES',
          stepCount: 5,
          price: 25,
        },
      ],
    },

    {
      planName: BRAND_2024_03_PLAN,
      planId: BRAND_2024_03_PLAN,
      price: isAnnually(BRAND_2024_03_PLAN) ? 400 : 500,
      priceElement: undefined,
      showSummaryPrice: true,
      title: 'billing_plans.plan.brand_2024_03.title',
      subtitle: 'billing_plans.plan.brand_2024_03.subtitle',
      descr: 'billing_plans.plan.brand_2024_03.descr',
      colorTheme: 'sky',
      btnMsg: '',
      shortBenefitsList: [
        { title: 'billing_plans.plan.brand_2024_03.benefit.1' },
        { title: 'billing_plans.plan.brand_2024_03.benefit.2' },
        { title: 'billing_plans.plan.brand_2024_03.benefit.3' },
      ],
      creatorCostList: [
        {
          title: 'billing_plans.plan.brand_2024_03.creator_cost.1',
          tooltipProps: {
            id: 'billing_plans.plan.brand_2024_03.creator_cost.1',
            tooltipMsg: 'billing_plans.plan.brand_2024_03.creator_cost.1.tooltip',
            tooltipClassName: planElStyles.planTooltip,
          },
        },
        { title: 'billing_plans.plan.brand_2024_03.creator_cost.2' },
      ],
      priceDescr: null,
      features: [],
      marketplace: [],
      support: [],
      paymentMethod: [],
      limits: [],
      availableAddons: [
        {
          minValue: 2,
          maxValue: Infinity,
          defaultValue: 2,
          label: 'billing_plans.addons.user_seats',
          key: 'SEATS',
          price: 30,
        },
        {
          minValue: 1,
          maxValue: Infinity,
          defaultValue: 1,
          label: 'billing_plans.addons.brands',
          key: 'BRANDS',
          price: 100,
        },
        {
          minValue: 5,
          maxValue: Infinity,
          defaultValue: 5,
          stepCount: 5,
          label: 'billing_plans.addons.licenses',
          key: 'WHITELISTING_LICENSES',
          price: 25,
        },
      ],
      planFeatures: {
        useCases: {
          justContent: <Icon color="dark100" name="check" />,
          organicPosting: <Icon color="dark100" name="check" />,
          partnershipAds: <Icon color="dark100" name="check" />,
          ttsparkAds: <Icon color="dark100" name="check" />,
          productSeeding: <Icon color="dark100" name="check" />,
          ugc: <Icon color="dark100" name="check" />,
          influencerPosts: <Icon color="dark100" name="check" />,
          metaAds: <Icon color="dark100" name="check" />,
          affiliate: <Icon color="dark100" name="check" />,
          tiktokShop: <Icon color="dark100" name="check" />,
        },
        limitations: {
          creators: planFeatureTextValue('billing_plans.plan_features.values.unlimited'),
          userSeats: 2,
          creatorLicensing: 5,
          brandsPerAccount: 1,
        },
        platformFeatures: {
          creatorMarketplace: <Icon color="dark100" name="check" />,
          tikTokSparkAds: <Icon color="dark100" name="check" />,
          audienceInsights: <Icon color="dark100" name="check" />,
          directChatWithCreators: <Icon color="dark100" name="check" />,
          creativeBriefTemplates: <Icon color="dark100" name="check" />,
          contentLibrary: <Icon color="dark100" name="check" />,
          automatedContractingAndPayments: <Icon color="dark100" name="check" />,
          connectionToFacebookAndTikTokAdsManager: <Icon color="dark100" name="check" />,
          multiUserAccountPermissions: <Icon color="dark100" name="check" />,
          contentRights: <Icon color="dark100" name="check" />,
        },
        integrations: {
          meta: <Icon color="dark100" name="check" />,
          tiktok: <Icon color="dark100" name="check" />,
          shopify: <Icon color="dark100" name="check" />,
          stripe: <Icon color="dark100" name="check" />,
        },
        customerSupport: {
          chatSupport: <Icon color="dark100" name="check" />,
          preLaunchFeedbackAndConsultancy: <Icon color="dark100" name="check" />,
          customerSuccessManager: <Icon color="dark100" name="check" />,
        },
        marketplaceFee: {
          marketplaceFee: planFeatureTextValue('billing_plans.plan_features.values.percent', {
            percent: 10,
          }),
        },
      },
    },
    {
      planName: AGENCY_2024_03_PLAN,
      planId: AGENCY_2024_03_PLAN,
      price: isAnnually(AGENCY_2024_03_PLAN) ? 640 : 800,
      priceElement: undefined,
      showSummaryPrice: true,
      title: 'billing_plans.plan.agency_2024_03.title',
      subtitle: 'billing_plans.plan.agency_2024_03.subtitle',
      descr: 'billing_plans.plan.agency_2024_03.descr',
      colorTheme: 'pink',
      btnMsg: '',
      withReferButton: true,
      shortBenefitsList: [
        { title: 'billing_plans.plan.agency_2024_03.benefit.1' },
        { title: 'billing_plans.plan.agency_2024_03.benefit.2' },
        { title: 'billing_plans.plan.agency_2024_03.benefit.3' },
      ],
      creatorCostList: [
        {
          title: 'billing_plans.plan.agency_2024_03.creator_cost.1',
          tooltipProps: {
            id: 'billing_plans.plan.agency_2024_03.creator_cost.1',
            tooltipMsg: 'billing_plans.plan.agency_2024_03.creator_cost.1.tooltip',
            tooltipClassName: planElStyles.planTooltip,
          },
        },
        { title: 'billing_plans.plan.agency_2024_03.creator_cost.2' },
      ],
      priceDescr: null,
      features: [],
      marketplace: [],
      support: [],
      paymentMethod: [],
      limits: [],
      availableAddons: [
        {
          minValue: 4,
          maxValue: Infinity,
          defaultValue: 4,
          label: 'billing_plans.addons.user_seats',
          key: 'SEATS',
          price: 30,
        },
        {
          minValue: 5,
          maxValue: Infinity,
          defaultValue: 5,
          label: 'billing_plans.addons.brands',
          key: 'BRANDS',
          price: 100,
        },
        {
          minValue: Infinity,
          maxValue: Infinity,
          defaultValue: Infinity,
          label: 'billing_plans.addons.licenses.infinity',
          key: 'WHITELISTING_LICENSES',
          stepCount: 5,
          price: 25,
        },
      ],
      planFeatures: {
        useCases: {
          justContent: <Icon color="dark100" name="check" />,
          organicPosting: <Icon color="dark100" name="check" />,
          partnershipAds: <Icon color="dark100" name="check" />,
          ttsparkAds: <Icon color="dark100" name="check" />,
          productSeeding: <Icon color="dark100" name="check" />,
          ugc: <Icon color="dark100" name="check" />,
          influencerPosts: <Icon color="dark100" name="check" />,
          metaAds: <Icon color="dark100" name="check" />,
          affiliate: <Icon color="dark100" name="check" />,
          tiktokShop: <Icon color="dark100" name="check" />,
        },
        limitations: {
          creators: planFeatureTextValue('billing_plans.plan_features.values.unlimited'),
          userSeats: 4,
          creatorLicensing: planFeatureTextValue('billing_plans.plan_features.values.unlimited'),
          brandsPerAccount: 5,
        },
        platformFeatures: {
          creatorMarketplace: <Icon color="dark100" name="check" />,
          tikTokSparkAds: <Icon color="dark100" name="check" />,
          audienceInsights: <Icon color="dark100" name="check" />,
          directChatWithCreators: <Icon color="dark100" name="check" />,
          creativeBriefTemplates: <Icon color="dark100" name="check" />,
          contentLibrary: <Icon color="dark100" name="check" />,
          automatedContractingAndPayments: <Icon color="dark100" name="check" />,
          connectionToFacebookAndTikTokAdsManager: <Icon color="dark100" name="check" />,
          multiUserAccountPermissions: <Icon color="dark100" name="check" />,
          contentRights: <Icon color="dark100" name="check" />,
        },
        integrations: {
          meta: <Icon color="dark100" name="check" />,
          tiktok: <Icon color="dark100" name="check" />,
          shopify: <Icon color="dark100" name="check" />,
          stripe: <Icon color="dark100" name="check" />,
        },
        customerSupport: {
          chatSupport: <Icon color="dark100" name="check" />,
          preLaunchFeedbackAndConsultancy: <Icon color="dark100" name="check" />,
          customerSuccessManager: <Icon color="dark100" name="check" />,
        },
        marketplaceFee: {
          marketplaceFee: planFeatureTextValue('billing_plans.plan_features.values.percent', {
            percent: 7,
          }),
        },
      },
    },
  ];
};

type PlanFeaturesType = (hasNewCases?: boolean) => PlanFeatures;
export const getPlanFeatures: PlanFeaturesType = (hasNewCases) => {
  return {
    limitations: {
      title: 'billing_plans.plan_features.limitations',
      tooltipMsg: '',
      features: {
        creators: {
          title: 'billing_plans.plan_features.creators',
        },
        userSeats: {
          title: 'billing_plans.plan_features.user_seats',
          additionalInfo: 'billing_plans.plan_features.user_seats.info',
          tooltipProps: {
            id: 'billing_plans.plan_features.user_seats.tooltip',
            tooltipMsg: 'billing_plans.plan_features.user_seats.tooltip',
          },
        },
        brandsPerAccount: {
          title: 'billing_plans.plan_features.brands_per_account',
          additionalInfo: 'billing_plans.plan_features.brands_per_account.info',
          tooltipProps: {
            id: 'billing_plans.plan_features.brands_per_account.tooltip',
            tooltipMsg: 'billing_plans.plan_features.brands_per_account.tooltip',
          },
        },
        creatorLicensing: {
          title: 'billing_plans.plan_features.creator_licensing',
          additionalInfo: 'billing_plans.plan_features.creator_licensing.info',
          tooltipProps: {
            clickable: true,
            id: 'billing_plans.plan_features.creator_licensing.tooltip',
            getContent: () => {
              return (
                <Text
                  msg="billing_plans.plan_features.creator_licensing.tooltip"
                  className={styles.tooltip}
                  formatValues={{
                    link: (
                      <a href={PLATFORM_CREATOR_LICENSING} target="_blank">
                        Learn more about our whitelisting feature.
                      </a>
                    ),
                  }}
                  data-test="data:text:tooltip"
                />
              );
            },
          },
        },
      },
    },
    marketplaceFee: {
      title: 'billing_plans.plan_features.marketplace_fee',
      tooltipMsg: '',
      features: {
        marketplaceFee: {
          title: 'billing_plans.plan_features.marketplace_fee.title',
          tooltipProps: {
            id: 'billing_plans.plan_features.marketplace_fee',
            tooltipMsg: 'billing_plans.plan_features.marketplace_fee.tooltip',
          },
        },
      },
    },
    useCases: {
      title: 'billing_plans.plan_features.use_cases',
      tooltipMsg: '',
      features: !hasNewCases
        ? {
            justContent: {
              title: 'billing_plans.plan_features.just_content',
              tooltipProps: {
                id: 'billing_plans.plan_features.just_content',
                tooltipMsg: 'billing_plans.plan_features.just_content.tooltip',
              },
            },
            organicPosting: {
              title: 'billing_plans.plan_features.organic_posting',
              tooltipProps: {
                id: 'billing_plans.plan_features.organic_posting.tooltip',
                tooltipMsg: 'billing_plans.plan_features.organic_posting.tooltip',
              },
            },
            partnershipAds: {
              title: 'billing_plans.plan_features.partnership_ads',
              tooltipProps: {
                id: 'billing_plans.plan_features.partnership_ads.tooltip',
                tooltipMsg: 'billing_plans.plan_features.partnership_ads.tooltip',
              },
            },
            ttsparkAds: {
              title: 'billing_plans.plan_features.tik_tok_spark_ads',
              tooltipProps: {
                clickable: true,
                id: 'billing_plans.plan_features.tik_tok_spark_ads.tooltip',
                getContent: () => {
                  return (
                    <Text
                      msg="billing_plans.plan_features.tik_tok_spark_ads.tooltip"
                      className={styles.tooltip}
                      formatValues={{
                        link: (
                          <a href={TIKTOK_SPARK_ADS} target="_blank">
                            Learn more about TikTok Spark Ads.
                          </a>
                        ),
                      }}
                      data-test="data:text:tooltip"
                    />
                  );
                },
              },
            },
            productSeeding: {
              title: 'billing_plans.plan_features.product_seeding',
              tooltipProps: {
                id: 'billing_plans.plan_features.product_seeding.tooltip',
                tooltipMsg: 'billing_plans.plan_features.product_seeding.tooltip',
              },
            },
          }
        : {
            ugc: {
              title: 'billing_plans.plan_features.ugc',
              tooltipProps: {
                id: 'billing_plans.plan_features.ugc',
                tooltipMsg: 'billing_plans.plan_features.ugc.tooltip',
              },
            },
            influencerPosts: {
              title: 'billing_plans.plan_features.incluencer_posts',
              tooltipProps: {
                id: 'billing_plans.plan_features.incluencer_posts.tooltip',
                tooltipMsg: 'billing_plans.plan_features.incluencer_posts.tooltip',
              },
            },
            productSeeding: {
              title: 'billing_plans.plan_features.updated.product_seeding',
              tooltipProps: {
                id: 'billing_plans.plan_features.updated.product_seeding.tooltip',
                tooltipMsg: 'billing_plans.plan_features.updated.product_seeding.tooltip',
              },
            },
            ttsparkAds: {
              title: 'billing_plans.plan_features.updated.tik_tok_spark_ads',
              tooltipProps: {
                clickable: true,
                id: 'billing_plans.plan_features.updated.tik_tok_spark_ads',
                tooltipMsg: 'billing_plans.plan_features.tik_tok_spark_ads.updated.tooltip',
              },
            },
            metaAds: {
              title: 'billing_plans.plan_features.meta_ads',
              tooltipProps: {
                id: 'billing_plans.plan_features.meta_ads.tooltip',
                tooltipMsg: 'billing_plans.plan_features.meta_ads.tooltip',
              },
            },
            affiliate: {
              title: 'billing_plans.plan_features.affiliate',
              tooltipProps: {
                id: 'billing_plans.plan_features.affiliate.tooltip',
                tooltipMsg: 'billing_plans.plan_features.affiliate.tooltip',
              },
            },
            tiktokShop: {
              title: 'billing_plans.plan_features.tiktok_shop',
              tooltipProps: {
                id: 'billing_plans.plan_features.tiktok_shop',
                tooltipMsg: 'billing_plans.plan_features.tiktok_shop.tooltip',
              },
            },
          },
    },
    platformFeatures: {
      title: 'billing_plans.plan_features.platform_features',
      tooltipMsg: '',
      features: {
        creatorMarketplace: {
          title: 'billing_plans.plan_features.creator_marketplace',
          tooltipProps: {
            id: 'billing_plans.plan_features.creator_marketplace.tooltip',
            tooltipMsg: 'billing_plans.plan_features.creator_marketplace.tooltip',
          },
        },
        directChatWithCreators: {
          title: 'billing_plans.plan_features.direct_chat_with_creators',
          tooltipProps: {
            id: 'billing_plans.plan_features.direct_chat_with_creators.tooltip',
            tooltipMsg: 'billing_plans.plan_features.direct_chat_with_creators.tooltip',
          },
        },
        creativeBriefTemplates: {
          title: 'billing_plans.plan_features.creative_brief_templates',
          tooltipProps: {
            id: 'billing_plans.plan_features.creative_brief_templates.tooltip',
            tooltipMsg: 'billing_plans.plan_features.creative_brief_templates.tooltip',
          },
        },
        automatedContractingAndPayments: {
          title: 'billing_plans.plan_features.automated_contracting_and_payments',
          tooltipProps: {
            id: 'billing_plans.plan_features.automated_contracting_and_payments.tooltip',
            tooltipMsg: 'billing_plans.plan_features.automated_contracting_and_payments.tooltip',
          },
        },
        multiUserAccountPermissions: {
          title: 'billing_plans.plan_features.multi_user_account_permissions',
          tooltipProps: {
            id: 'billing_plans.plan_features.multi_user_account_permissions.tooltip',
            tooltipMsg: 'billing_plans.plan_features.multi_user_account_permissions.tooltip',
          },
        },
        contentRights: {
          title: 'billing_plans.plan_features.content_rights',
          tooltipProps: {
            clickable: true,
            id: 'billing_plans.plan_features.content_rights.tooltip',
            getContent: () => {
              return (
                <Text
                  msg="billing_plans.plan_features.content_rights.tooltip"
                  className={styles.tooltip}
                  formatValues={{
                    link: (
                      <a href={TERMS_OF_SERVICE} target="_blank">
                        terms of service
                      </a>
                    ),
                  }}
                  data-test="data:text:tooltip"
                />
              );
            },
          },
        },
        contentLibrary: {
          title: 'billing_plans.plan_features.content_library',
          tooltipProps: {
            id: 'billing_plans.plan_features.content_library.tooltip',
            tooltipMsg: 'billing_plans.plan_features.content_library.tooltip',
          },
        },
        audienceInsights: {
          title: 'billing_plans.plan_features.audience_insights',
          tooltipProps: {
            id: 'billing_plans.plan_features.audience_insights.tooltip',
            tooltipMsg: 'billing_plans.plan_features.audience_insights.tooltip',
          },
        },
        connectionToFacebookAndTikTokAdsManager: {
          title: 'billing_plans.plan_features.connection_to_facebook_and_tik_tok_ads_manager',
          tooltipProps: {
            // eslint-disable-next-line max-len
            id: 'billing_plans.plan_features.connection_to_facebook_and_tik_tok_ads_manager.tooltip',
            tooltipMsg:
              'billing_plans.plan_features.connection_to_facebook_and_tik_tok_ads_manager.tooltip',
          },
        },
        tikTokSparkAds: {
          title: 'billing_plans.plan_features.tik_tok_spark_ads_code',
          tooltipProps: {
            id: 'billing_plans.plan_features.tik_tok_spark_ads_code.tooltip',
            tooltipMsg: 'billing_plans.plan_features.tik_tok_spark_ads_code.tooltip',
          },
        },
      },
    },
    integrations: {
      title: 'billing_plans.plan_features.integrations',
      tooltipMsg: '',
      features: {
        meta: {
          title: 'billing_plans.plan_features.integrations.meta',
        },
        tiktok: {
          title: 'billing_plans.plan_features.integrations.tiktok',
        },
        shopify: {
          title: 'billing_plans.plan_features.integrations.shopify',
        },
        stripe: {
          title: 'billing_plans.plan_features.integrations.stripe',
        },
      },
    },
    customerSupport: {
      title: 'billing_plans.plan_features.customer_support',
      tooltipMsg: '',
      features: {
        chatSupport: {
          title: 'billing_plans.plan_features.chat_support',
          tooltipProps: {
            id: 'billing_plans.plan_features.chat_support.tooltip',
            tooltipMsg: 'billing_plans.plan_features.chat_support.tooltip',
          },
        },
        preLaunchFeedbackAndConsultancy: {
          title: 'billing_plans.plan_features.pre_launch_feedback_and_consultancy',
          tooltipProps: {
            id: 'billing_plans.plan_features.pre_launch_feedback_and_consultancy.tooltip',
            tooltipMsg: 'billing_plans.plan_features.pre_launch_feedback_and_consultancy.tooltip',
          },
        },
        customerSuccessManager: {
          title: 'billing_plans.plan_features.customer_success_manager',
          tooltipProps: {
            id: 'billing_plans.plan_features.customer_success_manager.tooltip',
            tooltipMsg: 'billing_plans.plan_features.customer_success_manager.tooltip',
          },
        },
      },
    },
  };
};

type PlanFeature<T extends string | null = null> = {
  title: string;
  tooltipMsg: string;
  features: T extends null
    ? null
    : T extends string
    ? {
        [K in T]: {
          title: string;
          additionalInfo?: string;
          tooltipProps?: {
            tooltipMsg?: string;
            id?: TooltipType['id'];
            tooltipValues?: TooltipType['tooltipValues'];
            getContent?: TooltipType['getContent'];
            clickable?: TooltipType['clickable'];
          };
        };
      }
    : never;
};

export type PlanFeatureKeys = {
  useCases:
    | 'justContent'
    | 'influencerPartnerships'
    | 'creatorAds'
    | 'organicPosting'
    | 'partnershipAds'
    | 'ttsparkAds'
    | 'productSeeding'
    | 'ugc'
    | 'metaAds'
    | 'influencerPosts'
    | 'affiliate'
    | 'tiktokShop';
  limitations:
    | 'campaignsLaunchedPerMonth'
    | 'creatorsToHire'
    | 'creatorLicensing'
    | 'brandsPerAccount'
    | 'postProduction'
    | 'creators'
    | 'userSeats';
  platformFeatures:
    | 'creatorMarketplace'
    | 'tikTokSparkAds'
    | 'audienceInsights'
    | 'directChatWithCreators'
    | 'creativeBriefTemplates'
    | 'contentLibrary'
    | 'contentRights'
    | 'automatedContractingAndPayments'
    | 'connectionToFacebookAndTikTokAdsManager'
    | 'multiUserAccountPermissions';
  customerSupport: 'chatSupport' | 'preLaunchFeedbackAndConsultancy' | 'customerSuccessManager';
  marketplaceFee: 'marketplaceFee';
  integrations: 'meta' | 'tiktok' | 'shopify' | 'stripe';
};

export type PlanFeatureValues = {
  [K in keyof PlanFeatureKeys]: {
    [K1 in PlanFeatureKeys[K]]?: string | React.ReactNode | null;
  };
};

export type PlanFeatures = {
  useCases: PlanFeature<PlanFeatureKeys['useCases']>;
  limitations: PlanFeature<PlanFeatureKeys['limitations']>;
  platformFeatures: PlanFeature<PlanFeatureKeys['platformFeatures']>;
  customerSupport: PlanFeature<PlanFeatureKeys['customerSupport']>;
  marketplaceFee: PlanFeature<PlanFeatureKeys['marketplaceFee']>;
  integrations?: PlanFeature<PlanFeatureKeys['integrations']>;
};

export type AvailableAddonId = AddonId;

export const addonsKeyMap: Record<AvailableAddonId, string> = {
  SEATS: 'maxSeats',
  BRANDS: 'maxActiveBrands',
  WHITELISTING_LICENSES: 'maxBcaConnections',
};
