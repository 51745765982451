import React from 'react';

import styles from './Section.pcss';

import Text from 'Atoms/Text/Text';

interface Props {
  title?: string;
}

const Section: React.SFC<Props> = (props) => {
  const { title, children } = props;

  return (
    <div className={styles.root}>
      <header className={styles.header}>
        {title && (
          <Text weight="500" transform="uppercase" msg={title} data-test="section:text:unknown" />
        )}
      </header>
      {children}
    </div>
  );
};

export default Section;
