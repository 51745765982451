/**
 * @generated SignedSource<<4046881ba3c94c77111b4157c2b5e8f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Currency = "CAD" | "RUB" | "USD";
export type PlanId = "ADVANCED" | "ADVANCED_2021_10" | "ADVANCED_2022_11" | "AGENCY_2024_03" | "BARTER" | "BASIC_OLD_PS" | "BRAND_2024_03" | "CONTENT_CREATION" | "ENTERPRISE" | "FREE" | "FREE_OLD_PS" | "FREE_TRIAL" | "FULLY_MANAGED" | "FULLY_MANAGED_1" | "FULLY_MANAGED_2" | "FULLY_MANAGED_TTCX_MMA" | "GROW" | "PAYOUT" | "PREMIUM" | "PREMIUM_PLUS" | "PUBLICIS" | "SCALE" | "SCALE_2021_06" | "SCALE_2021_10" | "SMARTLY_ENTERPRISE_2023_06" | "SMARTLY_ENTERPRISE_TRIAL_2023_06" | "TRIAL_2022_08" | "TRIAL_2024_03" | "TRIAL_MONTH" | "UGC_2022_08" | "UGC_ADS_2022_08" | "WILDJAM";
export type SubscriptionInterval = "BIANNUAL" | "CUSTOM" | "MONTHLY" | "TRIMONTHLY" | "YEARLY";
export type BillingPlansQuery$variables = Record<PropertyKey, never>;
export type BillingPlansQuery$data = {
  readonly currentUser: {
    readonly admin: boolean;
    readonly organization: {
      readonly newClient: boolean;
      readonly paymentAccount: {
        readonly currency: Currency;
      } | null | undefined;
      readonly subscription: {
        readonly activeDiscount: {
          readonly name: string | null | undefined;
        } | null | undefined;
        readonly availablePlanIds: ReadonlyArray<PlanId>;
        readonly effectiveLimits: {
          readonly maxActiveBrands: number | null | undefined;
          readonly maxBcaConnections: number | null | undefined;
          readonly maxLicensedCreators: number | null | undefined;
          readonly maxSeats: number | null | undefined;
        };
        readonly interval: SubscriptionInterval | null | undefined;
        readonly planId: PlanId;
        readonly renewsAt: any | null | undefined;
      } | null | undefined;
      readonly trialMonthPlanAvailable: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type BillingPlansQuery = {
  response: BillingPlansQuery$data;
  variables: BillingPlansQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "admin",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "newClient",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "trialMonthPlanAvailable",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "availablePlanIds",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "planId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "interval",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "renewsAt",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "SubscriptionLimits",
  "kind": "LinkedField",
  "name": "effectiveLimits",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxLicensedCreators",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxActiveBrands",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxBcaConnections",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxSeats",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BillingPlansQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvertiserSubscription",
                "kind": "LinkedField",
                "name": "subscription",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Discount",
                    "kind": "LinkedField",
                    "name": "activeDiscount",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Payment_Account",
                "kind": "LinkedField",
                "name": "paymentAccount",
                "plural": false,
                "selections": [
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BillingPlansQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvertiserSubscription",
                "kind": "LinkedField",
                "name": "subscription",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Discount",
                    "kind": "LinkedField",
                    "name": "activeDiscount",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Payment_Account",
                "kind": "LinkedField",
                "name": "paymentAccount",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "610c21ed4a0e2f05c994d52faa2e10cb",
    "id": null,
    "metadata": {},
    "name": "BillingPlansQuery",
    "operationKind": "query",
    "text": "query BillingPlansQuery {\n  currentUser {\n    admin\n    organization {\n      newClient\n      trialMonthPlanAvailable\n      subscription {\n        activeDiscount {\n          name\n          id\n        }\n        availablePlanIds\n        planId\n        interval\n        renewsAt\n        effectiveLimits {\n          maxLicensedCreators\n          maxActiveBrands\n          maxBcaConnections\n          maxSeats\n        }\n        id\n      }\n      paymentAccount {\n        currency\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "790ca6a175735425cb4cc5fff8e024fb";

export default node;
