import React, { useState, useCallback, useContext } from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';
import { useIntl } from 'react-intl';
import find from 'lodash/find';

import styles from './ManualTransactions.pcss';
import { transactionTypes, plans, TRANSACTION_TYPE } from './data';

import createOrganizationDeposit from 'Mutations/CreateOrganizationDeposit.Mutation';
import createManualSalesPeakReward from 'Mutations/CreateManualSalesPeakReward.Mutation';
import createManualTransactionForSubscription from 'Mutations/accounting/CreateManualTransactionForSubscription.Mutation';
import createManualRefund from 'Mutations/CreateManualRefund.Mutation';
import createManualCreatorExpense from 'Mutations/CreateManualCreatorExpense.Mutation';
import { createNumber } from 'Util/numberFormatter';
import { createStartOfNewYorkDay } from 'Util/dateCreator';
import Text from 'Components/ui/Text/Text';
import Spinner from 'Atoms/Spinner/Spinner';
import { PlanId } from 'GraphTypes/CreateManualTransactionForSubscriptionMutation.graphql';
import { ManualTransactionsQuery as QueryType } from 'GraphTypes/ManualTransactionsQuery.graphql';
import { Currency } from 'GraphTypes/CreateOrganizationDepositMutation.graphql';
import Drawer from 'Components/ui/Drawer/Drawer';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import Input from 'Components/ui/Input/Input';
import Textarea from 'Components/ui/Textarea/Textarea';
import DatePickerRange from 'Components/ui/DatePicker/DatePickerRange';
import Button from 'Components/ui/Button/Button';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

const ManualTransactionsQuery = graphql`
  query ManualTransactionsQuery {
    departments {
      id
      name
    }
  }
`;

interface Props {
  organizationId: string;
}

const ManualTransactions: React.FC<Props> = (props) => {
  const { organizationId } = props;

  const intl = useIntl();

  const { closeDrawer } = useContext(DrawerContext);

  const [department, setDepartment] = useState('');
  const [transactionType, setTransactionType] = useState<TRANSACTION_TYPE>();
  const [plan, setPlan] = useState<PlanId>();
  const [dateFrom, setDateFrom] = useState<Date>();
  const [dateTo, setDateTo] = useState<Date>();
  const [amount, setAmount] = useState<number | undefined>(undefined);
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);

  const data = useLazyLoadQuery<QueryType>(ManualTransactionsQuery, {});

  if (!data) return <Spinner />;

  const isValid = () => {
    if (!transactionType || !department || !amount) return false;

    if (transactionType === TRANSACTION_TYPE.SUBSCRIPTION && !(plan && dateFrom && dateTo)) {
      return false;
    }

    return true;
  };

  const departments = data.departments;

  const dropdownDepartmentLabel = department
    ? { text: find(departments, (item) => item.id === department)?.name }
    : { msg: 'manual_transactions_modal.team.placeholder' };

  const departmentsDropdown = (
    <Dropdown
      bordered
      value={<Text type="md" {...dropdownDepartmentLabel} />}
      className={styles.dropdown}
    >
      <DropdownGroup className={styles.dropdownGroup}>
        <div>
          {departments?.map((item) => {
            if (!item) return null;

            const isActive = item.id === department ? 'Check' : null;

            const handleDepartmentClick = () => {
              setDepartment(item.id);
            };

            return (
              <DropdownItem key={item.id} handleClick={handleDepartmentClick}>
                <AlterButton
                  msg={item.name}
                  fluid
                  iconPosition="right"
                  icon={isActive ? 'Check' : null}
                />
              </DropdownItem>
            );
          })}
        </div>
      </DropdownGroup>
    </Dropdown>
  );

  const dropdownTypeLabel = transactionType
    ? {
        text: intl.formatMessage({
          id: find(transactionTypes, (item) => item.id === transactionType)?.msg,
        }),
      }
    : { msg: 'manual_transactions_modal.type' };

  const transactionTypeDropdown = (
    <Dropdown
      bordered
      value={<Text type="md" {...dropdownTypeLabel} />}
      className={styles.dropdown}
    >
      <DropdownGroup className={styles.dropdownGroup}>
        <div>
          {transactionTypes?.map((item) => {
            if (!item) return null;

            const isActive = item.id === transactionType ? 'Check' : null;

            const handleTypeClick = () => {
              setTransactionType(item.id);
            };

            return (
              <DropdownItem key={item.id} handleClick={handleTypeClick}>
                <AlterButton
                  msg={item.msg}
                  fluid
                  iconPosition="right"
                  icon={isActive ? 'Check' : null}
                />
              </DropdownItem>
            );
          })}
        </div>
      </DropdownGroup>
    </Dropdown>
  );

  const dropdownPlanLabel = plan
    ? {
        msg: `plan.admin_name.${plan.toLowerCase()}`,
      }
    : { msg: 'manual_transactions_modal.plan.placeholder' };

  const plansDropdown = (
    <Dropdown
      bordered
      value={<Text type="md" {...dropdownPlanLabel} />}
      className={styles.dropdown}
    >
      <DropdownGroup className={styles.dropdownGroup}>
        <div>
          {plans?.map((item) => {
            if (!item) return null;

            const isActive = item === plan ? 'Check' : null;

            const handlePlanClick = () => {
              setPlan(item);
            };

            return (
              <DropdownItem key={item} handleClick={handlePlanClick}>
                <AlterButton
                  msg={`plan.admin_name.${item.toLowerCase()}`}
                  fluid
                  iconPosition="right"
                  icon={isActive ? 'Check' : null}
                />
              </DropdownItem>
            );
          })}
        </div>
      </DropdownGroup>
    </Dropdown>
  );

  const handleRangeChange = useCallback<NonNullable<ReactDatePickerProps['onChange']>>((date) => {
    if (!date?.[0] || !date?.[1]) return;
    setDateFrom(date?.[0]);
    setDateTo(date?.[1]);
  }, []);

  const handleAmountChange = (newAmount: string) => {
    if (newAmount) {
      const newValue = newAmount.replace(/[^0-9]/g, '');
      const newPrice = parseInt(newValue, 10) || undefined;
      setAmount(newPrice);
    } else {
      setAmount(undefined);
    }
  };

  const amountValue = amount ? createNumber(amount) : '';

  const handleSubmit = () => {
    setLoading(true);

    const dataForInput = {
      organizationId,
      departmentId: department,
      amount: amount || 0,
      comment,
      currency: 'USD' as Currency,
      planId: plan,
    };

    if (transactionType === TRANSACTION_TYPE.TOPUP) {
      createOrganizationDeposit(dataForInput, handleManualTransactionDone);
    } else if (transactionType === TRANSACTION_TYPE.BONUS) {
      createManualSalesPeakReward(dataForInput, handleManualTransactionDone);
    } else if (transactionType === TRANSACTION_TYPE.SUBSCRIPTION) {
      createManualTransactionForSubscription(
        {
          ...dataForInput,
          billingPeriodEndsAt: createStartOfNewYorkDay(dateTo, 'USD'),
          billingPeriodStartsAt: createStartOfNewYorkDay(dateFrom, 'USD'),
        },
        handleManualTransactionDone
      );
    } else if (transactionType === TRANSACTION_TYPE.REFUND) {
      createManualRefund(dataForInput, handleManualTransactionDone);
    } else if (transactionType === TRANSACTION_TYPE.CREATOR_EXPENSE) {
      createManualCreatorExpense(dataForInput, handleManualTransactionDone);
    }
  };

  const handleParamsClear = () => {
    setLoading(false);
    setDepartment('');
    setTransactionType(undefined);
    setPlan(undefined);
    setDateFrom(undefined);
    setDateTo(undefined);
    setAmount(undefined);
    setComment('');
  };

  const handleManualTransactionDone = () => {
    handleParamsClear();
    closeDrawer('manual-transactions');
  };

  return (
    <Drawer rootKey="manual-transactions" className={styles.drawer} onClose={handleParamsClear}>
      <div className={styles.drawerContainer}>
        <Text type="d2" msg="manual_transactions_modal.title" className={styles.title} />
        {departmentsDropdown}
        {transactionTypeDropdown}
        {transactionType === TRANSACTION_TYPE.SUBSCRIPTION && plansDropdown}
        {transactionType === TRANSACTION_TYPE.SUBSCRIPTION && (
          <DatePickerRange
            customInputProps={{
              placeholderMsg: 'manual_transactions_modal.date',
              hideCloseIcon: true,
              className: styles.dateRangeInput,
            }}
            reactDatePickerProps={{
              onChange: handleRangeChange,
              startDate: dateFrom,
              endDate: dateTo,
            }}
          />
        )}
        <Input
          placeholderMsg="manual_transactions_modal.amount.placeholder"
          value={amountValue}
          handleChange={handleAmountChange}
          bordered
          rightSymbol={<Text type="sm" text="$" />}
          className={styles.amount}
        />
        <Textarea
          placeholderMsg="manual_transactions_modal.comment.placeholder"
          value={comment}
          handleChange={setComment}
          bordered
          className={styles.comment}
        />
        <Button
          color="black"
          disabled={!isValid()}
          loading={loading}
          msg="manual_transactions_modal.submit"
          onClick={handleSubmit}
        />
      </div>
    </Drawer>
  );
};

export default ManualTransactions;

//types

type DatePickerRangeProps = React.ComponentProps<typeof DatePickerRange>;
type ReactDatePickerProps = NonNullable<DatePickerRangeProps['reactDatePickerProps']>;
