import React, { useContext, useCallback } from 'react';
import { useFragment } from 'react-relay';
import { KeyType } from 'react-relay/relay-hooks/helpers';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import styles from './CustomListDetails.pcss';
import { CustomListDetailsFragment } from './CustomListDetails.Query';

import { normalizeFragmentRef } from 'Util/normalizeFragmentRef';
import {
  CustomListDetails_item$key,
  CustomListDetails_item$data,
} from 'GraphTypes/CustomListDetails_item.graphql';
import Text from 'Components/ui/Text/Text';
import TextPreset from 'Components/ui/TextPreset/TextPreset';
import ButtonPreset from 'Components/ui/ButtonPreset/ButtonPreset';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { CustomListEditDrawer } from 'Modal/advertiser/CustomListEdit';
import {
  CustomListEditDrawerFragment,
  CustomListEditDrawer_customList$key,
} from 'Modal/advertiser/CustomListEdit/CustomListEditDrawer.Query';
import { CREATORS_LIST_ROUTE } from 'Constants/general';
import { amplitude } from 'Helpers/amplitude';
import { getTimeFromNow } from 'Util/dateCreator';

interface Props {
  item: CustomListDetails_item$key;
}

const CustomListDetails: React.FC<Props> = (props) => {
  const { item } = props;
  const { openDrawer } = useContext(DrawerContext);
  const navigate = useNavigate();

  const fragment = useFragment<KeyType<CustomListDetails_item$data>>(
    CustomListDetailsFragment,
    normalizeFragmentRef<CustomListDetails_item$key>(item)
  );

  const data = useFragment(
    CustomListEditDrawerFragment,
    normalizeFragmentRef<CustomListEditDrawer_customList$key>(fragment)
  );

  const handleEditCustomList = useCallback<HandleEditCustomList>(
    (e) => {
      e.stopPropagation();
      openDrawer(`custom-list-edit-${data?.id || ''}`);
      amplitude.sendEvent<284>({
        id: '284',
        category: 'creator_lists',
        name: 'list_edit_click',
        param: undefined,
      });
    },
    [data?.id]
  );

  const handleRootClick = useCallback(() => {
    navigate(`${CREATORS_LIST_ROUTE}/${data?.id}`);
  }, [data?.id]);

  const handleCustomListSuccessfulEdited = useCallback(() => {
    amplitude.sendEvent<285>({
      id: '285',
      category: 'creator_lists',
      name: 'list_edited',
      param: undefined,
    });
  }, []);

  const handleCustomListSuccessfulRemoved = useCallback(() => {
    const listName = data?.name || '';
    amplitude.sendEvent<286>({
      id: '286',
      category: 'creator_lists',
      name: 'list_deleted',
      param: {
        list_name: listName,
      },
    });
  }, [data?.name]);

  if (!fragment || !data) return null;

  const totalCount = data.creators?.totalCount || 0;
  const customListName = data.name || '';
  const createdText = getTimeFromNow(data.createdAt);

  return (
    <>
      <div className={styles.root} onClick={handleRootClick}>
        <Text type="h2" text={customListName} data-test="customListDetails:text:unknown" />
        <Text
          type="label"
          className={classNames(styles.creatorsCountText, styles.left)}
          msg={'creators.dashboard.lists.details.count'}
          formatValues={{
            n: totalCount,
          }}
          data-test="customListDetails:text:unknown"
        />
        <Text
          type="label"
          className={classNames(styles.creatorsCountText, styles.left)}
          text={data.description || '-'}
          data-test="customListDetails:text:unknown"
        />
        <Text
          type="label"
          className={classNames(styles.creatorsCountText, styles.left)}
          text={data.authorName || '-'}
          data-test="customListDetails:text:unknown"
        />
        <Text
          type="label"
          className={classNames(styles.creatorsCountText, styles.left)}
          text={createdText || '-'}
          data-test="customListDetails:text:unknown"
        />
        <ButtonPreset>
          <AlterButton
            icon="Edit"
            onClick={handleEditCustomList}
            data-test="customListDetails:alterButton:unknown"
          />
        </ButtonPreset>
      </div>
      <CustomListEditDrawer
        customListItemFragment={fragment}
        drawerProps={{ removeWhenClosed: true, rootKey: `custom-list-edit-${data?.id || ''}` }}
        onCustomListSuccessfulEdited={handleCustomListSuccessfulEdited}
        onCustomListSuccessfulRemoved={handleCustomListSuccessfulRemoved}
      />
    </>
  );
};

export default CustomListDetails;

// types

type AlterButtonProps = React.ComponentProps<typeof AlterButton>;
type HandleEditCustomList = NonNullable<AlterButtonProps['onClick']>;
