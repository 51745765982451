import React, { useState, useContext } from 'react';

import styles from './Post.css';

import { createShortNumber, formatValue } from 'Util/numberFormatter';
import Text from 'Atoms/Text/Text';
import PlayIcon from 'Atoms/PlayIcon/PlayIcon';
import ImagesWithFallback from 'Components/ImagesWithFallback/ImagesWithFallback';
import { MEDIA } from 'Constants/modals';
import { VIDEO } from 'Constants/general';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';

interface Props {
  id: string;
  mediaType: 'VIDEO' | 'IMAGE' | null;
  thumbnailUrl?: string | null;
  link?: string | null;
  videoUrl?: string | null;
  finalVideoUrl?: string | null;
  caption?: string | null;
  instagramUrl?: string | null;
  engagement?: number | null;
  description?: string | null;
  likeCount?: number | null;
  commentCount?: number | null;
  reach?: number | null;
  impressions?: number | null;
  viewCount?: number | null;
  isYoutube?: boolean;
  isTiktok?: boolean;
  isStories?: boolean;
  isStatBlocked?: boolean;
  onPostClick?: () => void;
  onLoadError?: (id: string) => void;
}

const Post: React.FC<Props> = (props) => {
  const {
    id,
    link,
    mediaType,
    instagramUrl,
    thumbnailUrl,
    likeCount,
    viewCount,
    caption,
    description,
    videoUrl,
    finalVideoUrl,
    commentCount,
    reach,
    impressions,
    engagement,
    isYoutube,
    isTiktok,
    isStories,
    isStatBlocked,
    onPostClick,
  } = props;

  const [finalThumbnailUrl, setFinalThumbnailUrl] = useState(thumbnailUrl);
  const [failedState, setFailedState] = useState(false);

  const engagementVal = engagement ? formatValue({ value: engagement, format: 'percent' }) : null;

  if (failedState) {
    return null;
  }

  const { dispatch: modalDispatch } = useContext(ModalContext);

  const createCount = (name: string, count?: number | null) => {
    if (!count) return null;
    const countText = isStatBlocked ? '-' : createShortNumber(count);

    return (
      <div className={styles.count}>
        <Text color="grayDog" msg={name} data-test="post:text:unknown" />
        <Text weight="700" text={countText} data-test="post:text:unknown" />
      </div>
    );
  };

  const createFormattedCount = (name: string, count?: string | null) => {
    return (
      <div className={styles.count}>
        <Text color="grayDog" msg={name} data-test="post:text:unknown" />
        <Text weight="700" text={isStatBlocked ? '-' : count} data-test="post:text:unknown" />
      </div>
    );
  };

  const handleImageFail = () => {
    setFailedState(true);
  };

  const handlePostClick = () => {
    if (isTiktok && link) {
      window.open(link);
      return;
    }
    if (onPostClick) {
      onPostClick();
      return;
    }

    const attach = {
      videoUrl,
      viewCount,
      likeCount,
      finalVideoUrl,
      commentCount,
      thumbnailUrl: finalThumbnailUrl,
      instagramUrl,
      caption: caption || description,
      isYoutube,
      isTiktok,
      impressions: isStatBlocked ? -1 : impressions,
      reach: isStatBlocked ? -1 : reach,
      engagement: engagementVal,
    };

    modalDispatch({
      type: ModalTypes.SET_MODAL,
      payload: { name: MEDIA, attach },
    });
  };

  const handleUpdateItemThumbnailUrl = (url: string) => {
    setFinalThumbnailUrl(url);
  };

  return (
    <div key={id} className={styles.root} onClick={handlePostClick}>
      <div className={styles.content}>
        <div className={styles.imageWrap}>
          <ImagesWithFallback
            className={styles.image}
            updateItemThumbnailUrl={handleUpdateItemThumbnailUrl}
            isStories={isStories}
            failCallback={isStories ? handleImageFail : undefined}
            items={[
              {
                id,
                url: thumbnailUrl,
                thumbnailUrl,
                videoUrl,
                instagramUrl,
              },
            ]}
          />
        </div>
        {mediaType === VIDEO && (
          <div className={styles.playIcon}>
            <PlayIcon />
          </div>
        )}
      </div>
      <div className={styles.detailsWrap}>
        <div className={styles.details}>
          {reach && reach !== -1 && createCount('summary.reach', reach < 0 ? undefined : reach)}
          {impressions &&
            impressions !== -1 &&
            createCount('summary.impressions_short', impressions < 0 ? undefined : impressions)}
          {engagementVal &&
            engagementVal !== -1 &&
            createFormattedCount('summary.er_short', engagementVal)}
          {viewCount && createCount('summary.views', viewCount)}
          {!viewCount && createCount('summary.likes', likeCount)}
          {createCount('summary.comments', commentCount)}
        </div>
      </div>
    </div>
  );
};

export default Post;
