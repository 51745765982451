/**
 * @generated SignedSource<<4f7cecfeeec49dfb178abd0eb3a7fe9d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Reporting_CampaignCreatorsReportType = "ADMIN" | "CLIENT" | "CONTENT_CREATION" | "DEFAULT" | "FINANCE";
export type Reporting_ReportState = "EXPIRED" | "FAILED" | "PENDING" | "READY";
export type Reporting_CreateCampaignCreatorsReportInput = {
  campaignId: string;
  clientMutationId?: string | null | undefined;
  id?: string | null | undefined;
  subtype: Reporting_CampaignCreatorsReportType;
};
export type CreateCampaignCreatorsReportMutation$variables = {
  input: Reporting_CreateCampaignCreatorsReportInput;
};
export type CreateCampaignCreatorsReportMutation$data = {
  readonly reporting: {
    readonly createCampaignCreatorsReport: {
      readonly report: {
        readonly id: string;
        readonly state: Reporting_ReportState;
      };
    } | null | undefined;
  } | null | undefined;
};
export type CreateCampaignCreatorsReportMutation = {
  response: CreateCampaignCreatorsReportMutation$data;
  variables: CreateCampaignCreatorsReportMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Reporting_Mutation",
    "kind": "LinkedField",
    "name": "reporting",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "Reporting_CreateCampaignCreatorsReportPayload",
        "kind": "LinkedField",
        "name": "createCampaignCreatorsReport",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Reporting_CampaignCreatorsReport",
            "kind": "LinkedField",
            "name": "report",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "state",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateCampaignCreatorsReportMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateCampaignCreatorsReportMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "57c04ae2450dd1811b985301df447fc1",
    "id": null,
    "metadata": {},
    "name": "CreateCampaignCreatorsReportMutation",
    "operationKind": "mutation",
    "text": "mutation CreateCampaignCreatorsReportMutation(\n  $input: Reporting_CreateCampaignCreatorsReportInput!\n) {\n  reporting {\n    createCampaignCreatorsReport(input: $input) {\n      report {\n        id\n        state\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6ebfb3bd269f06ff8b727ab308711aa8";

export default node;
