import compact from 'lodash/compact';

import {
  DECLINED,
  HIRED,
  INVITATION_SENT,
  MESSAGED,
  RECIEVED_APPLICANTS,
  CONTENT_CREATION,
} from './NewCampaignTabs/util';

import { CREATORS, AWAITING_LIST, ARCHIVED, APPLICANTS, COMPLETED } from 'Constants/general';
import { AdvertiserProjectStage } from 'GraphTypes/CreatorCardControls_project.graphql';

const getCreatorsSectionStages = (publishingRequired: boolean): AdvertiserProjectStage[] => {
  return compact([
    'LAUNCHED',
    'CONTENT_SUBMITTED',
    publishingRequired ? 'PUBLISHING_STARTED' : null,
  ]);
};

export const getNewArchivedStages = (section: string): AdvertiserProjectStage[] => {
  if (section === DECLINED) {
    return ['ACCEPTED', 'LAUNCHED', 'CONTENT_SUBMITTED', 'PUBLISHING_STARTED', 'COMPLETED'];
  }
  return ['UNSENT', 'UNREAD', 'READ', 'ACCEPTED', 'REJECTED'];
};

export const getArchivedStagesList = (
  isAdminView: boolean,
  canBrowseCreators: boolean
): AdvertiserProjectStage[] => {
  const acceptedOnly = !(canBrowseCreators || isAdminView);

  return acceptedOnly
    ? ['ACCEPTED', 'LAUNCHED', 'CONTENT_SUBMITTED', 'PUBLISHING_STARTED', 'COMPLETED']
    : [
        'UNSENT',
        'UNREAD',
        'READ',
        'ACCEPTED',
        'LAUNCHED',
        'CONTENT_SUBMITTED',
        'PUBLISHING_STARTED',
        'COMPLETED',
        'REJECTED',
      ];
};

export const getHasBrandMessageBeforeLaunch = (section: string, hasNewCampaignTabs: boolean) => {
  if (!hasNewCampaignTabs) return undefined;

  if (section === APPLICANTS || section === RECIEVED_APPLICANTS) {
    return false;
  } else if (section === MESSAGED) {
    return true;
  }
  return undefined;
};

export const getArchivedAfterLaunch = (section: string, hasNewCampaignTabs: boolean) => {
  if (!hasNewCampaignTabs) return undefined;

  if (section === DECLINED) {
    return true;
  }
  return undefined;
};

export const getArchivedBeforeLaunch = (section: string, hasNewCampaignTabs: boolean) => {
  if (!hasNewCampaignTabs) return undefined;

  if (section === ARCHIVED) {
    return true;
  }
  return undefined;
};

export const getStagesList = (
  section: string,
  isAdminView: boolean,
  canBrowseCreators: boolean,
  publishingRequired: boolean,
  newCampaignTabs: boolean
): AdvertiserProjectStage[] => {
  if (section === CREATORS || section === HIRED || section === CONTENT_CREATION) {
    return getCreatorsSectionStages(publishingRequired);
  } else if (section === AWAITING_LIST || section === INVITATION_SENT) {
    return compact(['UNSENT', 'UNREAD', 'READ', 'REJECTED']);
  } else if (section === APPLICANTS || section === RECIEVED_APPLICANTS || section === MESSAGED) {
    return ['ACCEPTED'];
  } else if (section === ARCHIVED || section === DECLINED) {
    if (newCampaignTabs) {
      return getNewArchivedStages(section);
    }
    return getArchivedStagesList(isAdminView, canBrowseCreators);
  } else if (section === COMPLETED) {
    return ['COMPLETED'];
  }

  return [];
};

type IsWaitingListCreatedByAdmin = (listType: string, isAdmin: boolean) => boolean | null;
export const isWaitingListCreatedByAdmin: IsWaitingListCreatedByAdmin = (listType, isAdmin) => {
  const isAwaitingListSection = listType === AWAITING_LIST;

  return isAwaitingListSection && !isAdmin ? false : null;
};
