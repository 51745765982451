// routes

export const BASIC_ROUTE = '/';
export const ERROR_ROUTE = '/error404';
export const DOWNLOAD_APP_ROUTE = '/download-app';
export const OUTCOME_PAGE_ROUTE = '/outcome';
export const ORGANIZATION_INVITE_ROUTE = '/signup/organization-invite';
export const VERIFICATION_ROUTE = '/auth/action';
export const HOME_ROUTE = '/home';
export const DASHBOARD_ROUTE = '/dashboard';

export const SIGNIN_ADVERTISER_ROUTE = '/signin';
export const SIGNIN_CREATOR_ROUTE = '/signin/creator';
export const SIGNUP_ADVERTISER_ROUTE = '/signup';
export const SIGNUP_CREATOR_ROUTE = '/signup/creator';
export const SIGNUP_COMPLETE_ADVERTISER_ROUTE = '/signup/complete';
export const SIGNUP_COMPLETE_ADVERTISER_SURVEY_ROUTE = '/signup/complete/survey';
export const SIGNUP_COMPLETE_CREATOR_ROUTE = '/signup/creator/complete';

export const RESET_PASSWORD_ROUTE = '/reset-password';
export const PROJECT_ROUTE = '/projects/:id';
export const PROJECT_BRIEF_ROUTE = '/projects/:id/brief';
export const GAME_MONEY_ROUTE = '/gamemoney/start';
export const GRAPHIQL_ROUTE = '/graphiql';

// advertiser routes
export const BRANDS_ROUTE = '/brands';
export const PS_LICENSES_ROUTE = '/licenses';
export const CREATORS_ROUTE = '/creators';
export const CREATORS_LIST_ROUTE = '/creators/lists';
export const CREATORS_FAVORITES_ROUTE = '/creators/favorites';
export const CREATORS_BLOCKLIST_ROUTE = '/creators/blocklist';
export const CREATORS_PREVIOUS_COLLABS_ROUTE = '/creators/previous-collaboration';
export const NOTIFICATIONS_CENTER = '/notifications';

export const CREATORS_SHIPMENT_ROUTE = '/shipment';

export const PLANS_ROUTE = '/billing/plans';
export const BILLINGHISTORY_ROUTE = '/billing/history';
export const BILLING_ROUTE = '/billing';
export const MEDIAPLAN_ROUTE = '/campaigns/:id/mediaplan';
export const MARKETPLACE_ROUTE = '/marketplace';
export const ORGANIZATION_PROJECTS_ROUTE = '/projects';
export const DISCOVERY_ROUTE = '/discovery/:listId';

export const USER_SETTINGS = '/user/settings';
export const USER_TEAM = '/user/team';

export const BRIEF_ROUTE = '/campaigns/:id/brief';
export const CREATOR_STATISTIC_ROUTE = '/creators/:creatorId';
export const CREATOR_ROUTE = '/campaigns/:id/creator/:creatorId';
export const CREATOR_SECTION_ROUTE = '/campaigns/:id/creator/:creatorId/:section';
export const CAMPAIGN_TYPE_ROUTE = '/campaigns/:id/:type';
export const CAMPAIGN_ROUTE = '/campaigns/:id';
export const CAMPAIGN_BRIEF_ROUTE = '/campaigns/:id/brief-for-viewing';
export const CAMPAIGN_INVITED_ROUTE = '/campaigns/:id/awaiting-list';
export const CREATIVES_ROUTE = '/creatives';
export const OUTREACH_LIST_ROUTE = '/campaigns/:id/outreach';
export const OUTREACH_BATCH_ROUTE = '/campaigns/:id/outreach/batch';
export const OUTREACH_BATCH_VIEW_ROUTE = '/campaigns/:id/outreach/:batchId';
export const OUTREACH_BATCH_VIEW_ITEM_ROUTE = '/campaigns/:id/outreach/:batchId/:index';

export const PERFORMANCE_ROUTE = '/performance';
export const ORGANIZATION_PERFORMANCE_ROUTE = '/performance/:id';
export const YOUTUBE_OAUTH_ROUTE = '/oauth/youtube/start';
export const FB_PS_ROUTE = '/oauth/facebook/paid-social';
export const SNAPCHAT_OAUTH_ROUTE = '/oauth/snapchat/start';
export const TIKTOK_OAUTH_ROUTE = '/oauth/tiktok';
export const TIKTOK_SHOP_OAUTH_ROUTE = '/oauth/tiktok/authShop';

// public routes
export const CAMPAIGN_INVITE = '/invite';
export const COMMUNITY_INVITE = '/community-invite';

export const PROJECT_FOR_OFFER = '/project-offers/:id';
export const CAMPAIGN_OUTREACH = '/campaigns/:campaignId/invite';
export const INSTAGRAM_INSIGHTS = '/instagram-insights/connect';
export const PAYMENT_METHOD_VERIFICATION =
  '/payment-method-verify/:payment_method_id/:verification_code';

// contractor routes

export const CONTRACTOR_PROFILE_ROUTE = '/creator/profiles/:id';
export const CONTRACTOR_PROFILES_ROUTE = '/creator/profiles';
export const TRANSACTIONS = '/transactions';
export const LISTING_ROUTE = '/listings/:listingId';
export const BADGE_INFO = '/badges-info';
export const CONTRACTOR_RATING_INFO = '/rating-info';
export const CONTRACTOR_PROJECTS = '/dashboard/:tab';
export const CONTRACTOR_PROJECT_OFFER_ROUTE = '/project-offer';

export const PAYOUT_PROJECT = '/payout-projects/:id';

export const ADMIN_ORGANIZATION_ROUTE = '/admin/organizations/:id';
export const ADMIN_ORGANIZATIONS_ROUTE = '/admin/organizations';
export const ADMIN_CREATIVES_ROUTE = '/admin/creatives';
export const ADMIN_TRANSACTIONS_ROUTE = '/admin/transactions';
export const ADMIN_WHITHDRAWLS_ROUTE = '/admin/withdrawals';
export const ADMIN_HELPERS = '/admin/helpers';

export const GUIDELINE = '/guideline';

export const INTERNAL = '/internal';

// brief status

export const ACCEPTED = 'ACCEPTED';
export const MODERATION = 'MODERATION';
export const HIRING = 'HIRING';
export const STATS = 'STATS';

// SEARCH PARAMS

export const DEBOUNCE_WAIT_TIMEOUT = 500;

// COUNTERS

export const MAX_CATEGORIES_AMOUNT = 2;

// LINKS

export const INSTAGRAM_LINK = 'https://www.instagram.com/';
export const LANDING_LINK = 'https://insense.pro/';
export const TERMS_LINK = 'https://insense.pro/terms-of-use';
export const FOR_CREATORS_LINK = 'https://insense.pro/for-creators';
export const CONTRACTOR_TERMS_LINK = 'https://insense.pro/terms-of-service-for-creators';
export const POLICY_PRIVACY_LINK = 'https://insense.pro/privacy';
export const REFUND_POLICY_LINK = 'https://insense.pro/refund-policy';
export const FAQ = 'https://insense.pro/faq/clients';
export const CREATORS_FAQ = 'https://help-creators.insense.pro/';
export const VIDEO_EDITOR = 'https://insense.pro/stories-video-editor?ref=insense-dashboard';
export const REPORT_FORM_RU_LINK =
  'https://docs.google.com/forms/d/e/1FAIpQLSeOKmEt5LcMnHXNprVEyRwbvbZHvec3IzAO9AY5MP_pcRJDTA/viewform';
export const REPORT_FORM_EN_LINK =
  'https://docs.google.com/forms/d/e/1FAIpQLSdJm6duD_GjGGy_2gAsWT_QFB8pf2GEvphspz7_T6megKxVHw/viewform';
export const FB_BUSINESS_LINK = 'https://business.facebook.com/';
export const YOUTUBE_LINK = 'https://www.youtube.com/channel/';
export const TIKTOK_LINK = 'https://www.tiktok.com/';
export const FB_CAMPAIGN_OBJECT =
  'https://www.facebook.com/business/help/1438417719786914?id=802745156580214';
export const BOOK_A_DEMO_LINK_EN = 'https://insense.pro/demo/';
export const BOOK_A_DEMO_LINK_RU = 'https://insense.pro/schedule-demo-ru';
export const FMP_LINK =
  'https://www.facebook.com/business/partner-directory/details?id=258665458628556';
export const TIKTOK_PARTNER_LINK =
  'https://ads.tiktok.com/marketing-partners/partner-details/6886909906276843521';
export const FB_APP = 'fb://';
export const WHITELISTENING_FEATURE = 'https://insense.pro/creator-licensing-feature';
export const SPARK_ADS_TT_INFO_EN = 'https://insense.pro/tiktok-spark-ads-feature';
export const PRICE_RANGE_FAQ =
  'https://insense.notion.site/Pricing-guideline-d8910d0b90d04c2aa98ac9a7e18b25e3';
export const CREATIVE_CONTENT_TYPE_FAQ =
  'https://insense.notion.site/Content-bd8c742336ed484c99e1dfd9e548ee7a';
export const BCA_FAQ =
  'https://www.notion.so/insense/Branded-Content-Ads-BCA-c999ea94ca984d1fa8a5eaff57d2cfe6';
export const BCA_INFO =
  'https://www.notion.so/How-to-set-up-a-BCA-campaign-on-Insense-a05d9829d5b14e2c818820f0598e023e';
export const BCA_INFO_FOR_CREATORS = 'https://help-creators.insense.pro/article/4564';
export const GUIDES_AND_FAQ_URL_BRANDS = 'https://help.insense.pro/';
export const GUIDES_AND_FAQ_URL_CREATORS = 'https://help-creators.insense.pro/';
export const WHITELISTING_FAQ =
  'https://insense.notion.site/How-to-use-the-Creator-Licensing-feature-previously-known-as-Influencer-Whitelisting-27d53e288c2246e2b148b8941f442fea';
export const DOWNLOAD_INSENSE_APP = 'https://link.insense.pro/download-the-app';
export const INSENSE_PRIVACY_POLICY = 'https://insense.pro/privacy-policy';

export const PARTNERSHIP_ADS_FAQ = 'https://help.insense.pro/article/4299';
export const REFER_A_BUSINESS_URL = 'https://affiliate.insense.pro/';
export const MANAGED_SERVICE = 'https://insense.pro/managed-service';
export const PLATFORM_CREATOR_LICENSING = 'https://insense.pro/platform/creator-licensing?no-cache';
export const TIKTOK_SPARK_ADS = 'https://insense.pro/platform/tiktok-spark-ads';
export const TERMS_OF_SERVICE = 'https://insense.pro/terms-of-service';
export const PARTERSHIP_ADS_SETUP_INFO = 'https://help.insense.pro/article/4299';
export const PARTERSHIP_ADS_INFO = 'https://help.insense.pro/article/4299';
export const WHITELISTING_INFO = 'https://help.insense.pro/article/4218';
export const CREATOR_LONG_TERM_INFO = 'https://help-creators.insense.pro/article/4849';
export const ADVERTISER_LONG_TERM_INFO = 'https://help.insense.pro/article/4250';
export const CONTENT_CREATION_BRIEF_TEMPLATE = 'https://help.insense.pro/article/4230';
export const ORGANIC_POSTING_BRIEF_TEMPLATE = 'https://help.insense.pro/article/4229';
export const CREATOR_ADS_BRIEF_TEMPLATE = 'https://help.insense.pro/article/4228';
export const PRODUCT_SEEDING_BRIEF_TEMPLATE = 'https://help.insense.pro/article/4235';
export const CONTENT_CREATION_ONBOARDING_VIDEO =
  'https://www.youtube.com/embed/w6ww2vwm_lU?fs=0&modestbranding=1&playsinline=1&rel=0&showinfo=0';
export const CONTENT_CREATION_ONBOARDING_VIDEO_POSTER =
  'https://i3.ytimg.com/vi/w6ww2vwm_lU/maxresdefault.jpg';
export const ORGANIC_POSTING_ONBOARDING_VIDEO =
  'https://www.youtube.com/embed/xZR0mUERa7A?fs=0&modestbranding=1&playsinline=1&rel=0&showinfo=0';
export const ORGANIC_POSTING_ONBOARDING_VIDEO_POSTER =
  'https://i3.ytimg.com/vi/xZR0mUERa7A/maxresdefault.jpg';
export const CREATOR_ADS_ONBOARDING_VIDEO =
  'https://www.youtube.com/embed/Jm3CpRpg7qE?fs=0&modestbranding=1&playsinline=1&rel=0&showinfo=0';
export const CREATOR_ADS_ONBOARDING_VIDEO_POSTER =
  'https://i3.ytimg.com/vi/Jm3CpRpg7qE/maxresdefault.jpg';
export const PRODUCT_SEEDING_ONBOARDING_VIDEO =
  'https://www.youtube.com/embed/ynx8iep8ekk?fs=0&modestbranding=1&playsinline=1&rel=0&showinfo=0';
export const PRODUCT_SEEDING_ONBOARDING_VIDEO_POSTER =
  'https://i3.ytimg.com/vi/fdDJ1bS9sVM/maxresdefault.jpg';

export const UGC_ONBOARDING_VIDEO =
  'https://www.youtube.com/embed/xispd2GanEk?fs=0&modestbranding=1&playsinline=1&rel=0&showinfo=0';
export const INFLUENCER_ONBOARDING_VIDEO =
  'https://www.youtube.com/embed/xkeORVzXLnw?fs=0&modestbranding=1&playsinline=1&rel=0&showinfo=0';
export const SEEDING_ONBOARDING_VIDEO =
  'https://www.youtube.com/embed/p3NRkOX6STo?fs=0&modestbranding=1&playsinline=1&rel=0&showinfo=0';
export const CONVERSIONS_SPARK_ADS_ONBOARDING_VIDEO =
  'https://www.youtube.com/embed/sdpNW-_ZGdg?fs=0&modestbranding=1&playsinline=1&rel=0&showinfo=0';
export const REACH_SPARK_ADS_ONBOARDING_VIDEO =
  'https://www.youtube.com/embed/JIwH-xmfmFY?fs=0&modestbranding=1&playsinline=1&rel=0&showinfo=0';
export const CONVERSIONS_META_ADS_ONBOARDING_VIDEO =
  'https://www.youtube.com/embed/XbvJ546CsJ4?fs=0&modestbranding=1&playsinline=1&rel=0&showinfo=0';
export const REACH_META_ADS_ONBOARDING_VIDEO =
  'https://www.youtube.com/embed/e06U4xijAdM?fs=0&modestbranding=1&playsinline=1&rel=0&showinfo=0';
export const AFFILATE_ONBOARDING_VIDEO =
  'https://www.youtube.com/embed/oJjCcO9Y6sc?fs=0&modestbranding=1&playsinline=1&rel=0&showinfo=0';
export const TIKTOK_SHOP_ONBOARDING_VIDEO =
  'https://www.youtube.com/embed/t5DYLMatwos?fs=0&modestbranding=1&playsinline=1&rel=0&showinfo=0';

export const USER_GENERATED_CONTENT_SAMPLE_LINK = 'https://help.insense.pro/article/4230';
export const INFLUENCER_POSTS_SAMPLE_LINK = 'https://help.insense.pro/article/4229';
export const PRODUCT_SEEDING_SAMPLE_LINK = 'https://help.insense.pro/article/5041';
export const TIKTOK_SPARK_ADS_SAMPLE_LINK = 'https://help.insense.pro/article/5043';
export const META_PARTNERSHIP_ADS_SAMPLE_LINK = 'https://help.insense.pro/article/5044';
export const AFFILIATE_CAMPAIGN_SAMPLE_LINK = 'https://help.insense.pro/article/5042';
export const TIKTOK_SHOP_SAMPLE_LINK = 'https://help.insense.pro/article/4908';

export const TIKTOK_SHOP_INFO_LINK = 'https://help.insense.pro/article/5587';

export const OUTREACH_SETUP_GUIDE = 'https://help.insense.pro/article/5315';

// API

export const FB_API_VERSION = 'v12.0';
export const FB_OAUTH_API = `https://www.facebook.com/${FB_API_VERSION}/dialog/oauth`;
export const OAUTH_PROXY_URL = `https://oauth.insense.tech/facebook/paid-social`;

export const TIKTOK_OAUTH_API = `https://ads.tiktok.com/marketing_api/auth`;

// CHAT EVENTS

export const PRICE_CHANGE_EVENT = 'PriceChange';
export const DEADLINE_EVENT = 'Deadline';

// AUTH

export const OAUTH = '/oauth/facebook/paid-social';
export const SNAPCHAT_OAUTH = '/oauth/snapchat/start';
export const YOUTUBE_OAUTH = '/oauth/youtube/start';
export const SHOPIFY_OAUTH = '/oauth/shopify';
export const SHOPIFY_OAUTH_VERIFY = '/oauth/shopify/install/verify';
export const TIKTOK_OAUTH = '/oauth/tiktok';
export const GMAIL_OAUTH = '/oauth/gmail/start';

// PLANS

export const PREMIUM_PLUS = 'PREMIUM_PLUS';
export const PREMIUM_PLAN = 'PREMIUM';
export const ENTERPRISE_PLAN = 'ENTERPRISE';
export const FREE_PLAN = 'FREE';
export const FREE_TRIAL_PLAN = 'FREE_TRIAL';
export const CONTENT_CREATION_PLAN = 'CONTENT_CREATION';
export const GROW_PLAN = 'GROW';
export const SCALE_PLAN = 'SCALE';
export const SCALE_2021_06_PLAN = 'SCALE_2021_06';
export const SCALE_2021_10_PLAN = 'SCALE_2021_10';
export const ADVANCED_PLAN = 'ADVANCED';
export const ADVANCED_2021_10_PLAN = 'ADVANCED_2021_10';
export const ADVANCED_2022_11_PLAN = 'ADVANCED_2022_11';
export const ADVANCED_DEMO_PLAN = 'ADVANCED_DEMO';
export const TRIAL_MONTH_PLAN = 'TRIAL_MONTH';
export const TRIAL_2022_08_PLAN = 'TRIAL_2024_03';
export const UGC_2022_08_PLAN = 'UGC_2022_08';
export const UGC_ADS_2022_08_PLAN = 'UGC_ADS_2022_08';
export const FULLY_MANAGED_PLAN = 'FULLY_MANAGED';
export const FULLY_MANAGED_TTCX_MMA_PLAN = 'FULLY_MANAGED_TTCX_MMA';
export const FULLY_MANAGED_1_PLAN = 'FULLY_MANAGED_1';
export const FULLY_MANAGED_2_PLAN = 'FULLY_MANAGED_2';
export const BRAND_2024_03_PLAN = 'BRAND_2024_03';
export const AGENCY_2024_03_PLAN = 'AGENCY_2024_03';

export const SMARTLY_ENTERPRISE_2023_06_PLAN = 'SMARTLY_ENTERPRISE_2023_06';

export const SMARTLY_ENTERPRISE_TRIAL_2023_06_PLAN = 'SMARTLY_ENTERPRISE_TRIAL_2023_06';

export const CURRENT_PLANS = [
  FREE_PLAN,
  FREE_TRIAL_PLAN,
  TRIAL_2022_08_PLAN,
  BRAND_2024_03_PLAN,
  AGENCY_2024_03_PLAN,
];

// activation

export const ACTIVATION_FEE = 'ACTIVATION_FEE';

// CURRENCY

export const RUB = 'RUB';
export const USD = 'USD';
export const CAD = 'CAD';

//LOCALES

export const RU = 'ru';
export const EN = 'en';

// NEW CAMPAIGN STAGES

export const BRIEF = 'brief';
export const RESULTS = 'results';
export const CREATORS = 'creators';
export const COMPLETED = 'completed';
export const MEDIAPLAN = 'mediaplan';
export const APPLICANTS = 'applicants';
export const AWAITING_LIST = 'awaiting-list';
export const OUTREACH = 'outreach';
export const ARCHIVED = 'archived';
export const LONGLIST = 'longlist';
export const SHIPMENT = 'shipment';

export const PERFORMANCE = 'performance';

// BRIEF VERSIONS

export const V1Brief = 'V1Brief';
export const V2Brief = 'V2Brief';

// FILE TYPE

export const IMAGE = 'IMAGE';
export const VIDEO = 'VIDEO';
export const OTHER = 'OTHER';
export const MEDIA = 'MEDIA';

// VIDEO FILE PROCESSING STATE

export const PROCESSING = 'PROCESSING';
export const FAILED = 'FAILED';
export const PROCESSED = 'PROCESSED';

// CONTRACTOR_PROJECT_STAGE

export const STAGE_NEW = 'NEW';
export const STAGE_PENDING = 'ACCEPTED';
export const STAGE_SHIPMENT = 'PRODUCT_SHIPMENT_STARTED';
export const STAGE_LAUNCHED = 'LAUNCHED';
export const STAGE_COMPLETED = 'COMPLETED';
export const STAGE_ARCHIVED = 'ARCHIVED';
export const LISTINGS = 'LISTINGS';
export const PUBLISHING_STARTED = 'PUBLISHING_STARTED';
export const CONTENT_SUBMITTED = 'CONTENT_SUBMITTED';

// CONTRACTOR_PROJECT_STAGE_LABLES

export const MATCHED_STAGE_LABEL_STAGE_LABEL = 'matched';
export const PUBLIC_STAGE_LABEL = 'public';
export const PENDING_STAGE_LABEL = 'pending';
export const SHIPMENT_STAGE_LABEL = 'shipment';
export const APPLIED_STAGE_LABEL = 'applied';
export const COMPLETED_STAGE_LABEL = 'completed';
export const ARCHIVED_STAGE_LABEL = 'archived';

// CONTENT TYPES

export const IMAGE_TYPE = 'IMAGE';
export const BOOMERANG = 'BOOMERANG';
export const SQUARE_VIDEO = 'SQUARE_VIDEO';
export const VERTICAL_VIDEO = 'VERTICAL_VIDEO';
export const HORIZONTAL_VIDEO = 'HORIZONTAL_VIDEO';

// CONTENT PLACEMENT

export const FEED = 'FEED';
export const STORY = 'STORY';
export const REEL = 'REEL';
export const PREROLL = 'PREROLL';
export const POSTROLL = 'POSTROLL';
export const MIDDLEROLL = 'MIDDLEROLL';

// PLATFORM + CREATOR TYPE

export const INFLUENCER = 'INFLUENCER';
export const PAYOUT = 'PAYOUT';
export const YOUTUBE = 'YOUTUBE';
export const CONTENT = 'CONTENT';
export const INSTAGRAM = 'INSTAGRAM';
export const TIKTOK = 'TIKTOK';
export const SNAPCHAT = 'SNAPCHAT';
export const FACEBOOK = 'FACEBOOK';
export const CONTENT_CREATION = 'CONTENT_CREATION';
export const LONG_TERM = 'LONG_TERM';
export const PRODUCT_SEEDING = 'PRODUCT_SEEDING';

export const ORGANIC_POSTING = 'ORGANIC_POSTING';
export const CREATOR_ADS = 'CREATOR_ADS';

// USER TYPES

export const ADVERTISER = 'ADVERTISER';
export const CONTRACTOR = 'CONTRACTOR';
export const UNKNOWN = 'UNKNOWN';

// CREATOR TYPENAME

export const BRAND_CREATOR = 'Brand';
export const CONTENT_CREATOR = 'ContentCreator';
export const YOUTUBE_CREATOR = 'YoutubeCreator';
export const INSTAGRAM_CREATOR = 'InstagramCreator';

// ACCOUNT TYPE

export const BRAND = 'BRAND';
export const BLOGGER = 'BLOGGER';
export const COMMUNITY = 'COMMUNITY';
export const CELEBRITY = 'CELEBRITY';

// REFERRER

export const GROWSUMO = 'growsumo';
export const BARTER = 'barter';
export const CONTENT_CREATION_REF = 'content-creation';

// SIZES

export const LARGE = 'large';
export const MIDDLE = 'middle';
export const SMALL = 'small';

// VALUE TYPE

export const PERCENT = 'percent';

//  CELL FORMAT TYPES

export const TEXT = 'TEXT';
export const TWO_ROW_TEXT = 'TWO_ROW_TEXT';
export const IMAGE_ELEMENT = 'IMAGE_ELEMENT';
export const CUSTOM_ELEMENT = 'CUSTOM_ELEMENT';
export const LINK_TEXT_WITH_TOOLTIP = 'LINK_TEXT_WITH_TOOLTIP';
export const TWO_ROW_TEXT_WITH_LINK = 'TWO_ROW_TEXT_WITH_LINK';
export const COLORIZED_TEXT = 'COLORIZED_TEXT';

// TRANSACTIONS DATA

export const INVOICE = 'INVOICE';

export const DEPOSIT = 'DEPOSIT';
export const SUBSCRIPTION = 'SUBSCRIPTION';
export const CUSTOM_SERVICE = 'CUSTOM_SERVICE';
export const SALES_PEAK_REWARD = 'SALES_PEAK_REWARD';
export const REFERRAL_REWARD = 'REFERRAL_REWARD';
export const RESERVATION = 'RESERVATION';
export const REFUND = 'REFUND';
export const CREATOR_EXPENSE = 'CREATOR_EXPENSE';
export const FULLY_MANAGED_ZEROING = 'FULLY_MANAGED_ZEROING';

export const PAID = 'PAID';
export const NOT_PAID = 'NOT_PAID';
export const REFUNDED = 'REFUNDED';

export const ISSUED = 'ISSUED';
export const NOT_ISSUED = 'NOT_ISSUED';

export const NOT_REQUIRED = 'NOT_REQUIRED';

export const PSP_PAYMENT_METHOD = 'PSP';
export const PSP_INVOICE_PAYMENT_METHOD = 'PSP_INVOICE';
export const INVOICE_PAYMENT_METHOD = 'INVOICE';
export const MANUAL_PAYMENT_METHOD = 'MANUAL';

// SEARCH PARAMS TYPE

export const BOOLEAN = 'boolean';
export const TOGGLE_BOOLEAN = 'TOGGLE_BOOLEAN';
export const ARRAY = 'array';
export const RANGE = 'range';
export const SINGLE_VALUE = 'single';

// SEARCH FILTER TYPE

export const DATE = 'date';
export const RADIO = 'radio';
export const SELECT = 'select';
export const ADDITION = 'addition';
export const CHECKBOX = 'checkbox';
export const TEXT_INPUT = 'textInput';
export const AUTOCOMPLETE = 'autocomplete';
export const CUSTOM = 'custom';

// SEARCH GROUP TYPE

export const AUDIENCE_GROUP = 'audience';
export const CREATOR_GROUP = 'creator';

// ONBOARDING STEPS

export const COUNTRY = 'country';
export const HASHTAGS = 'hashtags';
export const BUDGET = 'budget';
export const ORGANIZATION = 'organization';
export const MATCHING_SEARCH = 'matchingSearch';

// CAMPAIGN PAYMENT TYPE

export const MONEY_TYPE = 'MONEY';
export const BARTER_TYPE = 'BARTER';

//COMMISSION TYPE
export const COMMISSION_ENABLED_TYPE = 'COMMISSION_ENABLED';
export const COMMISSION_DISABLED_TYPE = 'COMMISSION_DISABLED';

// RESULT

export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';
export const PENDING = 'PENDING';
export const HOLD = 'HOLD';

// TRANSACTIONS

export const MIN_AMOUNT_RU = process.env.DEPLOY_ENV === 'rc' ? 10 : 2000;
export const MAX_AMOUNT_RU = 75000;
export const MIN_AMOUNT_EN = 20;
export const MAX_AMOUNT_EN = 2999;

// TOPUP

export const MIN_AMOUNT_FOR_TOPUP = 50;
export const DEFAULT_TOPUP_AMOUNT = 500;
export const MAX_AMOUNT_FOR_TOPUP = 999999;

// ORDER DIRECTIONS

export const ASC = 'ASC';
export const DESC = 'DESC';

// STRIPE

export const SUCCEEDED = 'succeeded';
export const REQUIRES_PAYMENT_METHOD = 'REQUIRES_PAYMENT_METHOD';
export const REQUIRES_ACTION = 'REQUIRES_ACTION';
export const ORGANIZATION_DEPOSIT = 'ORGANIZATION_DEPOSIT';
export const SETUP_INTENT = 'setup_intent';
export const PAYMENT_INTENT = 'payment_intent';

// CONTACT EMAIL

export const HELLO_EMAIL = 'hello@insense.pro';

// TIKTOK OPEN API

export const TIKTOK_OPEN_API = {
  APP_ID: '7176831614456971266',
  CONNECT_URL: 'https://open-api.tiktok.com/platform/oauth/connect',
  REDIRECT_URI: 'https://oauth.insense.tech/tiktok/ads/contractor',
  PERMISSIONS: 'user.info.basic,user.insights.creator,video.list,tcm.order.update',
  RID: 'xhmh5rbg59',
};

export const TIKTOK_SHOP_AUTH_API = {
  CONNECT_URL: 'https://services.tiktokshops.us/open/authorize',
  CONNECT_CREATOR_URL: 'https://shop.tiktok.com/alliance/creator/auth',
};

export const COMMON_ERROR_MESSAGE = 'Something went wrong';

export const SHOPIFY_APP_STORE_URL = 'https://apps.shopify.com/inapp';

export const PRODUCT_FRUITS_WORKSPACE_CODE = 'nTybTWdOqAWARkrL';

export const MAX_CREATORS_PER_CUSTOM_LIST = 2000;
